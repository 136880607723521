import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//components
import ContactSupport from './ContactSupport'

// slices
import { sendEmailToResetPassword, updateLoginInfoState } from '../../../slices/LoginSlice'

// icons
import logo from '../../../images/logo.png'
import mailBoxGif from '../../../images/login/gif-email.gif'
import happyFaceIcon from '../../../images/login/happy-face.png'

const EmailSentComponent = ({ email }) => {

  // redux variales
  const dispatch = useDispatch()
  const loginInfo = useSelector(state => state.loginInfo)

  // state variables
  const [otpSentMsg, setOtpSentMsg] = useState(null)

  // local variables
  const { isLoading } = loginInfo

  const handleOpenEmail = (e) => {
    e.preventDefault()
    let emailLink
    if (email.includes("gmail") || email.includes("rekord")) {
      emailLink = `https://mail.google.com/`
    } else if (email.includes("yahoo")) {
      emailLink = `https://mail.yahoo.com/`;
    } else if (email.includes("hotmail") || email.includes("outlook")) {
      emailLink = `https://outlook.live.com/owa/`;
    } else {
      emailLink = `mailto:${email}`
    }
    window.open(emailLink)
  }

  const onEmailResend = async (e) => {
    e.preventDefault();
    await dispatch(sendEmailToResetPassword({ emailId: email }))
    setOtpSentMsg(true)
    setTimeout(() => {
      setOtpSentMsg(false)
    }, 4000)
  }

  return (
    <div className="fixed inset-0 bg-white p-8 h-full flex flex-col justify-between items-center text-center overflow-auto no-scrollbar" style={{ backgroundColor: '#F8F9FA' }}>

      <div className="flex justify-start w-full items-center absolute -mt-4 sm:mt-0 pl-8">
        <img src={logo} onClick={() => window.location.reload()} className='h-8 ml-0 sm:ml-5 cursor-pointer' loading='lazy' />
      </div>

      <div className='font-bold text-3xl flex  mt-8' style={{ color: '#22252A' }}>Headed your way...<img src={happyFaceIcon} className='h-8' /></div>

      <div className='font-semibold' style={{ color: '#6C757D' }}>
        We have sent an email to <span className='text-black'>{email}</span>
        <div className='mt-2'>Click on the link to reset your password. <span onClick={handleOpenEmail} className='cursor-pointer' style={{ color: '#0B98FF' }}>Open Email</span></div>
      </div>

      <img src={mailBoxGif} loading='lazy' className='my-5' style={{ height: '15rem', maxHeight: '40vh' }} />

      <div className='font-semibold text-black'>
        <div className='mb-2'>Did you not receive an email?</div>
        <div>Have you checked your spam folder?</div>
      </div>

      <div onClick={onEmailResend} className='font-medium text-white rounded-xl' style={{ backgroundColor: '#ACB5BD' }}>
        <button className='h-8 w-36 rounded-xl hover:bg-gray-500 transition-colors delay-50'>
          {
            isLoading ? (
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-100 m-auto "></div>
            ) : (
              <span>Resend Email</span>
            )
          }
        </button>
      </div>

      {
        otpSentMsg && <div className="font-medium" style={{ fontSize: '15px', color: '#35A600' }}>Email has been sent again, please check your email</div>
      }

      <div className='mb-5'><ContactSupport /></div>

    </div>
  )
}

export default EmailSentComponent