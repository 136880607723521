import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getTemplateList = createAsyncThunk('/templateList', async ({ businessType }) => {
	const response = await axios.get('/v3/business/templateList', { params: { businessType } })
	return response.data.data;
})

export const getTemplateDetail = createAsyncThunk('/templateDetail', async ({ templateId }) => {
	const response = await axios.get('/v3/business/templateDetail', { params: { templateId } })
	return response.data.data;
})

export const registerTemplateSlice = createSlice({
	name: 'registerTemplate',
	initialState: {
		isLoading: false,
		templateList: [],
		templateDetails: {}
	},
	reducers: {
		updateRegisterTemplateState: (state, action) => {
			action.payload.forEach((el) => {
				const key = el.key
				const value = el.value
				state[key] = value
			})
		},
	},
	extraReducers: {
		[getTemplateList.pending]: state => {
			state.isLoading = true
		},
		[getTemplateList.fulfilled]: (state, action) => {
			state.templateList = action.payload.templates
			state.isLoading = false
		},
		[getTemplateList.rejected]: state => {
			state.isLoading = false
		},
		[getTemplateDetail.pending]: state => {
			state.isLoading = true
		},
		[getTemplateDetail.fulfilled]: (state, action) => {
			state.templateDetails = action.payload
			state.isLoading = false
		},
		[getTemplateDetail.rejected]: state => {
			state.isLoading = false
		},
	}
})

export const { updateRegisterTemplateState } = registerTemplateSlice.actions;
export default registerTemplateSlice.reducer;