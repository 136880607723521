import React, { useEffect, useState, useRef, createRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { deleteSelectOneOption, renameSelectOneOption, addSelectOneOption, fetchRegisterData, fetchAllRegisterData } from '../../../../../../slices/RegisterSlice'
import Colors from './Colors'

// images
import deleteIcon from '../../../../../../images/dialog/selectOne/delete.png'
import editIcon from '../../../../../../images/dialog/selectOne/edit.png'
import dropdownIcon from '../../../../../../images/dialog/selectOne/colorDropdown.png'
import crossIcon from '../../../../../../images/dialog/selectOne/cross.png'
import searchIcon from '../../../../../../images/search-black.png'

const SelectOneDialog1 = ({ selectOneOptions, colors, rowProperties, saveCurrentRowData, colKey, colIdx }) => {

  const dispatch = useDispatch()

  const registerData = useSelector(state => state.register)

  const [editedOptionIndex, setEditedOptionIndex] = useState(-1)
  const [editedOptionName, setEditedOptionName] = useState('')
  const [editedOptionColor, setEditedOptionColor] = useState(null)
  const [newOptionName, setNewOptionName] = useState('')
  const [newOptionColor, setNewOptionColor] = useState('#FAFCFF')
  const [showLoader, setShowLoader] = useState(false)
  const [showColorsForNewOption, setShowColorsForNewOption] = useState(false)
  const [showColorsForEditedOption, setShowColorForEditedOption] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const columnId = colKey  //for api calls
  //const rowIndex = rowProperties.rowIdx
  //const value = rowProperties.row? rowProperties.row[colIdx - 1] : rowProperties.value

  const { sheetId, registerId, sortedColKey, sortDirection } = registerData

  const [options, setOptions] = useState(selectOneOptions.map((option, i) => {
    return ({
      'value': option.value,
      'color': option.color ? option.color : '#fff',
      'index': i
    })
  }))

  const [optionsRefs, setOptionsRefs] = useState([])

  // close on click outside, remove edit mode
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (editedOptionIndex == -1 || !optionsRefs[editedOptionIndex].current || showColorsForEditedOption || showColorsForNewOption) return;
      if (optionsRefs[editedOptionIndex].current.contains(target)) return;
      setEditedOptionIndex(-1)
      setEditedOptionColor(null)
      setEditedOptionName('')
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  })


  //const saveData = (option) => {
  //  saveCurrentRowData(option, colKey, rowIndex, registerData.rows)
  //}

  // assign refs to options whenever initialised or the array is updated
  const appointRefs = (optionsArray) => {
    let refs = optionsArray.reduce((acc, val, i) => {
      acc[i] = React.createRef();
      return acc;
    }, []);
    setOptionsRefs(refs)
  }

  useEffect(() => {
    appointRefs(options)
  }, [])

  const onEditButtonClick = (index, name, color) => {
    //setShowColorForEditedOption(false)
    //setEditedOptionColor(null)
    setEditedOptionName(name)
    setEditedOptionIndex(index)
    setEditedOptionColor(color)
  }

  const onSaveButtonClick = async (i) => {
    if (editedOptionName.trim(' ').length === 0) {
      alert("Cannot be saved empty.")
      return
    }
    setShowLoader(true)
    const color = editedOptionColor
    const oldOption = options[i].value
    const option = editedOptionName == '' ? null : editedOptionName
    const newOptions = [...options]
    newOptions[i].value = editedOptionName
    newOptions[i].color = color
    const newOptionsForStateUpdate = newOptions.map((option) => {
      return { value: option.value, color: option.color }
    })
    const response = await dispatch(renameSelectOneOption({ sheetId, columnId, oldOption, option, color, optionsArray: newOptionsForStateUpdate }))
    if (response.meta.requestStatus == "fulfilled") {
      if (sortDirection != 0) {
        dispatch(fetchAllRegisterData({ registerId, sheetId }))
      }
      setOptions(newOptions)
      //if(oldOption == value){
      //  saveData(editedOptionName)
      //}
    }
    setEditedOptionColor(null)
    setEditedOptionIndex(-1)
    setEditedOptionName('')
    setShowLoader(false)
  }

  const onOptionDelete = async (name, index) => {
    const option = name
    const newOptions = [...options]
    newOptions.splice(index, 1)
    newOptions.forEach((option, i) => {
      option.index = i
    })
    const newOptionsForStateUpdate = newOptions.map((option) => {
      return { value: option.value, color: option.color }
    })
    const response = await dispatch(deleteSelectOneOption({ sheetId, columnId, option, optionsArray: newOptionsForStateUpdate }))
    if (response.meta.requestStatus == "fulfilled" && index !== undefined) {
      if (sortDirection != 0) {
        dispatch(fetchAllRegisterData({ registerId, sheetId }))
      }
      setOptions(newOptions)
      //if(option === value){
      //  saveData('')
      //}
    }
  }

  const onAddButtonClick = async () => {
    // empty option validation
    if (newOptionName.length === 0) {
      alert("Option name is Mandatory")
      return
    }
    // duplicate option validation
    const isDuplicateEntry = options.find(el => el.color == newOptionColor && el.value == newOptionName)
    if (isDuplicateEntry) {
      alert("This option with same color property exists already.")
      return
    }
    setEditedOptionIndex(-1)
    setShowLoader(true)
    const option = {
      value: newOptionName,
      color: newOptionColor
    }
    const newOptions = [...options]
    option.index = options.length
    newOptions.push(option)
    const newOptionsForStateUpdate = newOptions.map((option) => {
      return { value: option.value, color: option.color }
    })

    const response = await dispatch(addSelectOneOption({ sheetId, columnId, option, optionsArray: newOptionsForStateUpdate }))
    if (response.meta.requestStatus == "fulfilled") {
      if (sortDirection != 0) {
        dispatch(fetchAllRegisterData({ registerId, sheetId }))
      }
      setOptions(newOptions)
      appointRefs(newOptions)
    }
    setNewOptionName('')
    setNewOptionColor('#FAFCFF')
    setShowColorsForNewOption(false)
    setShowLoader(false)
  }

  const onNewOptionColorSelect = (color) => {
    setNewOptionColor(color)
    setShowColorsForNewOption(false)
  }

  const onEditedOptionColorSelect = (color) => {
    setEditedOptionColor(color)
    setShowColorForEditedOption(false)
  }

  const loader = () => {
    return (
      <div className="animate-spin rounded-full h-7 w-7 border-b-2 border-gray-100 m-auto "></div>
    )
  }

  return (
    <div className="w-full flex flex-col justify-between rounded-lg rounded-t-none" style={{ height: '25rem', maxHeight: '80vh', width: '40rem', maxWidth: '95vw', backgroundColor: '#E4F0FE' }}>

      {/*Backdrop*/}
      <div onClick={() => { setShowColorForEditedOption(false); setShowColorsForNewOption(false) }} className={`fixed inset-0 z-30 opacity-100 ${showColorsForEditedOption || showColorsForNewOption ? '' : 'hidden'}`} style={{ background: "rgba(0,0,0,0.4)", zIndex: '100' }}>
      </div>
      {/*Backdrop*/}

      <div className="overflow-y-auto p-3" style={{ borderColor: '#9F9F9F', maxHeight: '80%' }}>

        {/*Search Input*/}
        <div className="flex items-center relative mb-2">
          <img className="absolute h-5 ml-6" src={searchIcon} />
          <input
            className="grayPlaceholder pl-12 w-full m-2 h-10 focus:outline-none focus:ring"
            style={{ fontFamily: "roboto", fontWeight: "400", fontSize: "14px", border: '1px solid rgba(152, 171, 201, 0.3)', borderRadius: '21px', backgroundColor: '#FAFCFF' }}
            placeholder="Search Name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {searchQuery && <img onClick={() => { setSearchQuery('') }} className="absolute w-5 h-5 right-5 cursor-pointer" src={crossIcon} />}
        </div>

        {/*Select one options with add/delete functionalities*/}
        {options.filter((option) => {
          if (searchQuery == "") {
            return option
          } else if (option.value.toLowerCase().includes(searchQuery.toLowerCase())) {
            return option
          }
        })
          .map((o, i) => {
            return (
              <div key={i} className="flex justify-center items-center w-full" >
                <div ref={optionsRefs[o.index]} className="flex h-12 mx-3 my-2 w-full rounded-lg bg-white" style={{ minWidth: '0', border: '1px solid rgba(152, 171, 201, 0.3)', backgroundColor: '#FAFCFF'  }}>
                  {editedOptionIndex === o.index &&
                    <div
                      className="h-full w-12 sm:w-16 border-r rounded-sm flex justify-center items-center"
                      style={{ borderColor: 'rgba(152, 171, 201, 0.3)', flexShrink: '0' }}
                    >
                      <img onClick={() => setShowColorForEditedOption(!showColorsForEditedOption)} className="h-3 cursor-pointer" src={dropdownIcon} />
                    </div>
                  }
                  {/* background: editedOptionColor && editedOptionIndex === o.index ? editedOptionColor : o.color, */}

                  {/* <div
                    className="h-full w-12 sm:w-16 rounded-sm flex justify-center items-center"
                    style={{ background: editedOptionColor && editedOptionIndex === o.index ? editedOptionColor : o.color, borderColor: '#9F9F9F', flexShrink: '0' }}
                  >
                    {editedOptionIndex === o.index && <img onClick={() => setShowColorForEditedOption(!showColorsForEditedOption)} className="h-3 cursor-pointer" src={dropdownIcon} />}
                  </div> */}
                  <div className="flex justify-between items-center text-sm font-normal w-full" style={{ borderColor: '#9F9F9F', minWidth: '0', color: '#174184'}}>
                    {
                      editedOptionIndex === o.index ?
                        <input value={editedOptionName} onChange={(e) => { setEditedOptionName(e.target.value) }} autoFocus="autofocus" className='w-full h-full pl-8 blueFocusOutline transition-colors delay-50' style={{ background: '#FAFCFF' }} />
                        :
                        <div className="pl-2 xs:pl-4 sm:pl-6 md:pl-8 pr-2 truncate">{o.value}</div>
                    }
                    {
                      editedOptionIndex !== o.index ? (
                        <div className="flex justify-center items-center gap-4 mr-1 sm:mr-4" style={{ flexShrink: '0' }}>
                          <img className="cursor-pointer h-6" onClick={() => onEditButtonClick(o.index, o.value, o.color)} src={editIcon} />
                          <img className="cursor-pointer h-6" onClick={() => onOptionDelete(o.value, o.index)} src={deleteIcon} />
                        </div>
                      ) :
                        (
                          <div className="h-full">
                            <button onClick={() => onSaveButtonClick(o.index)} className="px-5 py-2 text-white h-full rounded-r-lg w-20 " style={{ background: '#174184' }}>
                              {showLoader ? loader() : 'Save'}
                            </button>
                          </div>
                        )
                    }

                  </div>
                </div>

                {/*Colors for edited options */}
                <div className={`absolute top-1/3 p-4 bg-white border border-gray-400 transition-all delay-100 ${showColorsForEditedOption && editedOptionIndex === o.index ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} style={{ zIndex: '101' }}>
                  <Colors colors={colors} onColorSelect={onEditedOptionColorSelect} />
                </div>
                {/*Colors for edited options */}

              </div>
            )
          })}
        {/*Select one options with add/delete functionalities*/}

      </div>

      <div className="border-t mx-5 child-in-center mt-5" style={{ height: '20%', borderColor: 'rgba(23, 65, 132, 0.2)' }}>
        <div className="h-12 my-5 w-full">

          {/*Add new option portion */}
          <div className="flex h-12 rounded-lg" style={{ border: '1px solid rgba(152, 171, 201, 0.3)' }}>
            <button onClick={() => setShowColorsForNewOption(!showColorsForNewOption)} className="h-full rounded-l-lg w-16 border-r flex justify-center items-center" style={{ background: newOptionColor }}><img className="h-3" src={dropdownIcon} /></button>
            <div className="flex justify-between items-center  w-full" style={{ borderColor: '#9F9F9F' }}>
              <input value={newOptionName} onChange={(e) => setNewOptionName(e.target.value)} className="pl-8 w-full h-full blueFocusOutline transition-colors delay-50" style={{background: '#FAFCFF'}} />
              <button onClick={() => onAddButtonClick()} className="rounded-r-lg h-12 px-6 sm:px-8 md:px-12 text-white text-xl" style={{ background: '#174184' }}>
                {showLoader && editedOptionIndex === -1 ? loader() : 'Add'}
              </button>
            </div>
          </div>
          {/*Add new option portion */}

          {/*Colors for new option */}
          <div className="relative">
            <div className={`absolute mt-1 p-4 bg-white border border-gray-400 transition-all delay-100 ${showColorsForNewOption ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-2 pointer-events-none'}`} style={{ zIndex: '101' }}>
              <Colors colors={colors} onColorSelect={onNewOptionColorSelect} />
            </div>
          </div>
          {/*Colors for new option */}

        </div>

      </div>

    </div>
  )
}

export default SelectOneDialog1