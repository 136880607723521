import { useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom';
import { useDropzone } from 'react-dropzone'

import CustomProgressBar from '../../../../../../partials/CustomProgressBar'

import imageIcon from '../../../../../../images/dialog/image/image.png'
import removeImageIcon from '../../../../../../images/dialog/image/removeImage.png'
import attachmentIcon from '../../../../../../images/dialog/attachment.png'
import downloadIcon from '../../../../../../images/dialog/attachment/download.png'
import deleteIcon from '../../../../../../images/dialog/attachment/delete.png'

const AttachmentDialog2 = ({ attachments, onAttachmentChange, onAttachmentDelete }) => {
  // route variables
  const location = useLocation()

  // state variables
  const [droppedAttachments, setDroppedAttachments] = useState([])
  const [uploadingAttachments, setUploadingAttachments] = useState(false)
  const [uploadedAttchsCount, setUploadedAttchsCount] = useState(0)
  const [attachmentsList, setAttachmentsList] = useState(attachments)

  // local variables
  const { pathname } = location;
  const communityId = pathname.split('/')[2];

  const onDrop = useCallback(acceptedFiles => {
    setDroppedAttachments((curr) => [...curr, ...acceptedFiles])
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const removeSelectedAttachment = (i) => {
    const currentAttachments = [...droppedAttachments]
    currentAttachments.splice(i, 1)
    setDroppedAttachments([...currentAttachments])
  }

  const onUploadClick = () => {
    setUploadingAttachments(true)
  }

  const getUploadedAttachmentsClbk = (uploadedFiles) => {
    let newFilesArray = []
    attachmentsList.forEach((el) => {
      newFilesArray.push({ url: el.value })
    })
    uploadedFiles.forEach((el) => {
      newFilesArray.push({ url: el.url })
    })
    onAttachmentChange(newFilesArray)
  }

  const deleteAttachment = (index) => {
    let newFilesArray = []
    attachmentsList.forEach((el) => {
      newFilesArray.push({ url: el.value })
    })
    newFilesArray.splice(index, 1)

    const newAttachmentList = [...attachmentsList]
    newAttachmentList.splice(index, 1)
    setAttachmentsList(newAttachmentList)
    onAttachmentDelete(newFilesArray)
  }

  const liveUploadCountClbk = (count) => {
    setUploadedAttchsCount(count)
  }

  const GetFilename = (url) => {
    if (url) {
      let m = url.toString().match(/.*\/(.+?)\./);
      if (m && m.length > 1) {
        return m[1];
      }
    }
    return "";
  }

  return (
    <div className="w-full flex flex-col justify-between" style={{ height: '55vh', width: '40rem', maxWidth: '90vw' }}>
      <div className="px-4 text-center py-2 text-2xl border-b border-gray-300">{attachmentsList.length} Files Attached</div>
      <div className="h-full overflow-auto py-2">

        <ul className="py-2">
          {
            attachmentsList.map((attch, i) => {
              return (
                <li key={i} className="px-4 my-2 mx-4 py-2 rounded flex items-center justify-between" style={{ background: '#F4F4F4' }}>
                  <div className="flex gap-2 items-center">
                    <img src={attachmentIcon} />
                    {GetFilename(attch.value)}
                  </div>
                  <div className="flex justify-center items-center gap-4">
                    <a href={attch.value} target="_blank" className=""><img className="h-5 cursor-pointer" src={downloadIcon} /></a>
                    <img onClick={() => { deleteAttachment(i) }} className="h-5 cursor-pointer" src={deleteIcon} />
                  </div>
                </li>
              )

            })
          }
        </ul>

        {droppedAttachments.length !== 0 && <div className="text-center p-4 text-xl">{uploadedAttchsCount}/{droppedAttachments.length} Selected Attachments</div>}

        <ul className="overflow-auto py-2" >
          {!uploadingAttachments && droppedAttachments.map((attch, i) => {
            return (
              <li key={i} className="px-4 my-2 mx-4 py-2 rounded flex items-center justify-between" style={{ background: '#F4F4F4' }}>
                <div className="flex gap-2 items-center">
                  <img src={imageIcon} />
                  {attch.name}
                </div>
                <button onClick={() => { removeSelectedAttachment(i) }}><img className="h-5 w-5" src={removeImageIcon} /></button>
              </li>
            )
          })}
          {
            uploadingAttachments &&
            <CustomProgressBar
              url={'otherservice/uploadFile'}
              files={droppedAttachments}
              communityId={communityId}
              getUploadedFilesClbk={getUploadedAttachmentsClbk}
              progressDirection='horizontal'
              liveUploadCountClbk={liveUploadCountClbk}
              showFileInfo={true}
              outerContainerStyle={{ height: '3rem', width: '100%' }}
              outerContainerClasses={'px-2  py-1 rounded flex '}
              progressBarStyle={{ backgroundColor: '#586C9E' }}
            />
          }
        </ul>
      </div>


      <div className="w-full px-4 py-2 flex justify-end border-t border-gray-300">
        <div className="text-center cursor-pointer rounded border p-2 mx-2 shadow-md w-28" style={{ color: '#586C9E', borderColor: '#586C9E' }} {...getRootProps()} ><input {...getInputProps()} />Add More</div>
        {
          !uploadingAttachments && droppedAttachments.length > 0 && (
            <button className="rounded border p-2 mx-2 shadow-md w-28" style={{ color: 'white', background: '#586C9E' }} onClick={onUploadClick} >Upload</button>
          )
        }
      </div>

    </div>
  )

}

export default AttachmentDialog2