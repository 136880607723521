import { useDispatch, useSelector } from 'react-redux'
import { addEmptyRowToSheet } from '../../../../../slices/RegisterSlice'

import addIcon from '../../../../../images/plusWhite.png'


const SnoSummaryCell = ({ canAdd }) => {

  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  // local variables
  const sheetId = registerData.sheetId
  const topRowId = registerData.rows[registerData.rows.length - 2] ? registerData.rows[registerData.rows.length - 2].rowId : ''

  // function triggered on + icon
  const handleClick = async () => {
    if (!canAdd) {
      alert("Permission Denied")
      return
    }
    dispatch(addEmptyRowToSheet({ sheetId, topRowId, count: 5, idxToBeScrolled: registerData.rows.length - 1 }))
  }

  // show normal S.No value
  return (
    <div
      className="absolute w-full h-full left-0 flex justify-center items-center"
      style={{ backgroundColor: "#044089" }}
    >
      <button onClick={(e) => { e.preventDefault(); handleClick(); }} className="w-full h-full flex justify-center items-center">
        <img src={addIcon} className="h-3/4" />
      </button>
    </div>
  )
};

export default SnoSummaryCell