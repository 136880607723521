import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setuserShouldBeLoggedOut, getManifestJsonDetails } from '../slices/LoginSlice';

// pages
import SignUp from '../pages/SignUp'
import Login from '../pages/Login'
import Layout from '../features/Layout'
import CreatePassword from '../pages/SignUp/CreatePassword'
import ResetPassword from '../pages/Login/ResetPassword'

// utils
import { ProtectedLoginRoute, ProtectedRoute, ProtectedSignUpPasswordRoute } from '../utils/ProtectedRoutes'
import { Mixpanel } from '../partials/Mixpanel'

// component
const AuthProvider = () => {
  const dispatch = useDispatch();
  const loginInfo = useSelector(state => state.loginInfo);

  // Here the user info condition is put so that when refresh happens
  // and local storage had some value being granted to userInfo in the store in its initialState
  // then login component should never be rendered.
  // If purely set to false then login component gets rendered atleast once
  // from <Redirect to='/login' /> of ProtetedRoute as isLoggedIn is false initially
  // before useEffect sets it to true based upon user info value from the store 
  // obtained from local storage which happens after ProtectedRoute's render cycle 1.

  const [isLoggedIn, setIsLoggedIn] = useState(loginInfo.userInfo ? true : false);

  // local
  const { manifestJsonDetail, userInfo } = loginInfo

  // informing mix panel when user comes back to the web platform to resume its work
  useEffect(() => {
    if (loginInfo.userInfo) {
      // initially when a user information is detected in local storage then this is called
      const hostName = window.location.hostname.split('.')[0]
      const appName = hostName ? hostName : 'localhost'
      dispatch(getManifestJsonDetails(appName))
      Mixpanel.identify(loginInfo.userInfo.userId);
    }
  }, [])

  // CASE 1: whenever user is not signed in
  // CASE 2: On sign out STORE is cleared totally hence we need to again make a call
  useEffect(() => {
    if (!userInfo) {
      const hostName = window.location.hostname.split('.')[0]
      const appName = hostName ? hostName : 'localhost'
      dispatch(getManifestJsonDetails(appName))
    }
  }, [userInfo])

  useEffect(() => {
    // if user info changes to an object : is the case 1
    // now if in the whole application again the userinfo changes then we wouldnt want this to be rendered again
    // so we check if the user's info is not updated which means it is just an initial login.
    // Where ever the userInfo will be updated we will set this userInfoUpdated to true
    // so that   setIsLoggedIn is not called again.
    // To set userInfoUpdated back to false only on logout as this setIsLoggedIn is required only once
    // if the login was not from refresh based upon local storage.
    if (loginInfo.userInfo && !loginInfo.userInfoUpdated) {
      setIsLoggedIn(true)
    }
  }, [loginInfo.userInfo])

  useEffect(() => {
    if (loginInfo.shouldUserBeLoggedOut) {
      setIsLoggedIn(false)

      // here there were few cases were formed for logout
      // initially shouldUserBeLoggedOut is set to false when app is fresh.
      // it can only be made true from logout.
      // But what if a person loggs out and shouldUserBeLoggedOut is true ?
      // we need to reset it.
      dispatch(setuserShouldBeLoggedOut(false))
    }
  }, [loginInfo.shouldUserBeLoggedOut])

  useEffect(() => {
    const manifestElement = document.getElementById("my-manifest-placeholder");
    if (manifestJsonDetail) {
      console.log("manifestJsonDetail ", manifestJsonDetail)
      const manifestString = JSON.stringify({ ...manifestJsonDetail, display: "standalone", orientation: "portrait" });
      manifestElement?.setAttribute(
        "href",
        "data:application/json;charset=utf-8," + encodeURIComponent(manifestString)
      );
      document.title = manifestJsonDetail.name
      document.querySelector("link[rel~='icon']").setAttribute(
        "href",
        manifestJsonDetail.icons[0].src
      )
      document.querySelector("link[rel='shortcut icon']").setAttribute(
        "href",
        manifestJsonDetail.icons[0].src
      )
      document.querySelector("link[rel~='apple-touch-icon']").setAttribute(
        "href",
        manifestJsonDetail.icons[0].src
      )
    } else {
      document.title = 'Record Book'
    }
  }, [manifestJsonDetail])

  return (
    <Router>
      <Switch>
        <ProtectedLoginRoute exact path='/login' component={Login} isAuth={isLoggedIn} />
        <ProtectedLoginRoute exact path='/signUp' component={SignUp} isAuth={isLoggedIn} />
        <ProtectedSignUpPasswordRoute exact path='/signUp/create-password' component={CreatePassword} isAuth={isLoggedIn}/>
        <ProtectedSignUpPasswordRoute exact path='/login/reset-password' component={ResetPassword} isAuth={isLoggedIn}/>
        <ProtectedRoute path='/' component={Layout} isAuth={isLoggedIn} />
        <Route exact path='/notFound' component={() => (<div>Not Found</div>)} />
      </Switch>
    </Router>
  )
}
export default AuthProvider