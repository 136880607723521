import { createSlice } from '@reduxjs/toolkit'

export const routerSlice = createSlice({

  name: 'routerSlice',

  initialState: {
    popUpNavigationHistory: []
  },

  reducers: {
    updatePopNavigationHistory: (state, action) => {
      const type = action.payload.type
      const path = action.payload.path
      if (type === 'pop') {
        state.popUpNavigationHistory.pop()
      } else if (type === 'push') {
        state.popUpNavigationHistory.push(path)
      } else if(type === 'empty'){
        state.popUpNavigationHistory = []
      }
    }
  },

  extraReducers: {

  }

})

export const { updatePopNavigationHistory } = routerSlice.actions

export default routerSlice.reducer