import { useEffect, useState } from "react"

// third party
import moment from 'moment'

// components
import DateRange from './Components/DateRange'
import TimeRange from './Components/TimeRange'

const DateRangeFilter = ({ changeFilterSettings, selectedfilterData, selectedCol, errorColumns }) => {

  //state variables
  const [startRange, setStartRange] = useState(null)
  const [endRange, setEndRange] = useState(null)
  const [rangeType, setRangeType] = useState(null)

  useEffect(() => {
    // range is date or time type
    if (['date', 'reminder', 'createdAt'].indexOf(selectedCol.dataType) > -1) {
      setRangeType('date') // set range type date
      const prefillStartRange = selectedfilterData[1] ? (moment(selectedfilterData[1], 'DD-MM-YYYY').isValid() ? moment(selectedfilterData[1], 'DD-MM-YYYY').toDate() : null) : null
      const prefillEndRange = selectedfilterData[2] ? (moment(selectedfilterData[2], 'DD-MM-YYYY').isValid() ? moment(selectedfilterData[2], 'DD-MM-YYYY').toDate() : null) : null
      setStartRange(prefillStartRange)
      setEndRange(prefillEndRange)
    }
    else if (['time'].indexOf(selectedCol.dataType) > -1) {
      setRangeType('time')
      const prefillStartRange = selectedfilterData[1] ? (moment(selectedfilterData[1], 'h:mm a').isValid() ? moment(selectedfilterData[1], 'h:mm a').format('h:mm a') : null) : null
      const prefillEndRange = selectedfilterData[2] ? (moment(selectedfilterData[2], 'h:mm a').isValid() ? moment(selectedfilterData[2], 'h:mm a').format('h:mm a') : null) : null
      setStartRange(prefillStartRange)
      setEndRange(prefillEndRange)
    } // set range type time

  }, [selectedfilterData])

  useEffect(() => {
    if (errorColumns.length > 0 && errorColumns.indexOf(selectedCol.key) > -1) {

    }
  }, [errorColumns])

  const getChecked = (type) => {
    if (selectedfilterData.indexOf(type) > -1) return true
    else return false
  }

  const selectValue = (val) => {
    if (selectedfilterData.includes(val)) changeFilterSettings([]) // remove the filter
    else changeFilterSettings([val]) // add the filter
  }

  const onRangeChange = (value, type) => {
    let selectedfilterData_copy = [...selectedfilterData]
    if (rangeType === 'date') { // if this filter is for date, reminder or createdAt
      if (type === 'startRange') { // set first date value
        if (value) selectedfilterData_copy[1] = moment(value).format('DD-MM-YYYY')
        else selectedfilterData_copy[1] = null
      } else if ('endRange') { // set second date value
        if (value) selectedfilterData_copy[2] = moment(value).format('DD-MM-YYYY')
        else selectedfilterData_copy[2] = null
      }
    } else if (rangeType === 'time') { // if this filter is for time
      if (type === 'startRange') { // set first date value
        if (value) selectedfilterData_copy[1] = value
        else selectedfilterData_copy[1] = null
      } else if ('endRange') { // set second date value
        if (value) selectedfilterData_copy[2] = value
        else selectedfilterData_copy[2] = null
      }
    }
    changeFilterSettings(selectedfilterData_copy)
  }

  const selectCheck = () =>{
    if(!getChecked('BETWEEN')) selectValue('BETWEEN')
  }

  const styles = {
    formCheckInput: { border: '1.5px solid #35A600', borderRadius: '3px', height: '1.3rem', width: '1.3rem', outline: 'none', cursor: 'pointer' }
  }

  return (
    <div className="h-full pl-2 pr-0 sm:pr-2 w-80">

      <div style={{ height: '4rem' }}></div>

      <div className="px-2">

        {/* 'Date range' label and checkbox */}
        <div class="flex justify-between items-center pb-1 cursor-pointer pb-2" onClick={() => selectValue('BETWEEN')}>
          <label for='date-range' className="cursor-pointer">
            {rangeType === 'date' ? 'Date' : 'Time'} Range
          </label>
          <input
            type="checkbox"
            id='date-range'
            readOnly
            style={{...styles.formCheckInput, backgroundColor: getChecked('BETWEEN') ? '#35A600' : '#fff'}}
            checked={getChecked('BETWEEN')} />
        </div>
        {/* 'Date range' label and checkbox */}

        {/* From and to Date inputs */}
        <div onClick={selectCheck}>
        {
          rangeType && rangeType === 'date' ?
            <DateRange
              startRange={startRange}
              endRange={endRange}
              onRangeChange={onRangeChange}
              getChecked={getChecked}
              showError={errorColumns.indexOf(selectedCol.key) > -1}
            />
            :
            <TimeRange
              startRange={startRange}
              endRange={endRange}
              onRangeChange={onRangeChange}
              showError={errorColumns.indexOf(selectedCol.key) > -1}
              selectedCol={selectedCol}
              getChecked={getChecked}
              selectCheck={selectCheck}
            />
        }
        </div>
        {/* Error field */}
        {errorColumns.indexOf(selectedCol.key) > -1 && <span style={{color:'#D81205'}}>Field required</span>}

        {/* Blank Value */}
        <div class="flex justify-between items-center pb-1 cursor-pointer py-2" onClick={() => selectValue('NONE')}>
          <label for='date-range-blank-value' className="cursor-pointer">
            Blank Value
          </label>
          <input
            type="checkbox"
            id='date-range-blank-value'
            readOnly
            style={{...styles.formCheckInput, backgroundColor: getChecked('NONE') ? '#35A600' : '#fff'}}
            checked={getChecked('NONE')} />
        </div>
        {/* Blank Value */}
      </div>

    </div>
  )
}

export default DateRangeFilter

const DateSpecific = () => {

}