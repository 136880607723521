import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

// images
import activePage from '../../../../../images/activePage.png'
import activePageBlue from '../../../../../images/activePageBlue.png'
import downArrowGray from '../../../../../images/downArrowGray.png'

const PagesTabMobile = ({
  editActiveSheetName,
  sheetNameBeingEditd,
  setSheetNameBeingEditd,
  saveNewSheetName,
  toggleEditSheetName,
  onSheetNameClick,
}) => {

  // redux variables
  const registerData = useSelector(state => state.register)

  // state variables 
  const [showPagesMenu, setShowPagesMenu] = useState(false)

  // local variables
  const { pages, sheetId, activeSheetName } = registerData
  let activeSheetIndex
  const activeSheet = pages.find((p, i) => {
    activeSheetIndex = i
    return p.sheetId == sheetId
  })

  useEffect(() => {
    const clickHandler = () => {
      if (showPagesMenu) setShowPagesMenu(false)
    }
    if (showPagesMenu) {
      document.addEventListener('click', clickHandler)
    }
    return () => document.removeEventListener('click', clickHandler)
  }, [showPagesMenu])

  return (
    <div
      className={`relative flex items-center bg-white h-full cursor-pointer pr-1 ${editActiveSheetName ? 'pl-1' : 'pl-4'}`}
      style={{ minWidth: '90px', maxWidth: '160px', borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
    >
      {
        editActiveSheetName ?

          // Page edit on
          <input
            type="text"
            autoFocus
            style={{ fontSize: 12, height: 20, minWidth: 'inherit', maxWidth: "140px" }}
            value={sheetNameBeingEditd}
            onChange={(e) => setSheetNameBeingEditd(e.target.value)}
            onBlur={() => saveNewSheetName(activeSheet, activeSheetIndex)}
            size={sheetNameBeingEditd.length}
            onKeyDown={(e) => {
              if (e.key === "Enter") saveNewSheetName(activeSheet, activeSheetIndex)
              if (e.key === "Tab") toggleEditSheetName()
            }}
          />
          // Page edit on

          :

          // Page edit off
          <>
            <img src={activePage} className="mr-1 h-3.5" alt="Active Page" />
            <p className="relative truncate pr-2 text-sm font-medium" style={{ color: "#545454" }}>{activeSheetName}</p>
            <img
              src={downArrowGray}
              onClick={(e) => { e.stopPropagation(); setShowPagesMenu(!showPagesMenu) }}
              className={`h-5 mr-1 transform transition-transform delay-50 ${showPagesMenu ? 'rotate-180' : ''}`}
            />
          </>
          // Page edit off

      }

      {
        // Pages Dropwdown menu starts
        showPagesMenu &&
        <div className='bg-white left-0 slide_down_2 top-full absolute w-full overflow-y-auto' style={{ maxHeight: '60vh', borderRadius: '4px', padding: '0 0.5rem', boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.16)' }}>
          {
            pages && pages.map((p, i) => {
              return (
                <div
                  style={{ color: p.sheetId === sheetId ? '#174184' : '#545454' }}
                  className='flex items-center gap-2 p-1 hover:bg-gray-200 border-b transition-all delay-50 text-sm font-medium'
                  onClick={(e) => { onSheetNameClick(p) }}
                >
                  <div className='h-3.5 w-3.5 child-in-center' style={{ flexShrink: '0' }}><img src={p.sheetId === sheetId ? activePageBlue : activePage} loading='lazy' className='h-full' /></div>
                  <p className='truncate'>{p.sheetName}</p>
                </div>
              )
            })
          }
        </div>
        // Pages Dropwdown menu ends
      }

    </div>
  )
}

export default PagesTabMobile

