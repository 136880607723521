import React, { useEffect, useRef, useState } from 'react';
import ReactDOM, { createPortal } from 'react-dom';
import { useSelector } from 'react-redux'

// css 
import './rowEditors.css';

// utils
import ErrorBoundary from '../../../../../utils/ErrorBoundary'
import { getValidatedUrlValue } from '../../Utils'

// icons
import editIcon from '../../../../../images/ic_edit_icon.png'
import deleteIcon from '../../../../../images/ic_trash_icon.png'

const UrlEditor = ({ rowProperties, saveCurrentRowData, colIdx, columnEl, statusOptions }) => {
  // redux variables
  const registerData = useSelector(state => state.register)

  // local variables
  const { isAdmin, hasBasicEditPermission, editPermissions } = registerData
  const colKey = columnEl.key
  const onClose = rowProperties.onClose
  const rowIndex = rowProperties.rowIdx
  const rowId = rowProperties.row.rowId
  const value = rowProperties.row[colKey]
  const urlValue = getValidatedUrlValue(rowProperties.row[`${colKey}_details`])
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colKey) === -1

  // Empty row work for add permission
  const hasAddPermission = registerData.permissions.includes('add')
  const row = rowProperties.row
  const isRowEmpty = !Object.keys(row).some((key) => {
    if (key != 'rowId' && key != -1) {
      return row[key].length > 0
    }
  })
  const canAdd = hasAddPermission && isRowEmpty
  // Empty row work for add permission

  // state variables
  const [pos, setPos] = useState({})
  const [url, setUrl] = useState(urlValue)
  const [isUrlEditorOpen, setIsUrlEditorOpen] = useState(urlValue ? false : true)
  const [caption, setCaption] = useState(value)
  const [showUrlError, setShowUrlError] = useState(false)
  const [showCaptionError, setShowCaptionError] = useState(false)

  // ref variables
  const urlEditorContainerRef = useRef(null)
  const urlEditorDataCellRef = useRef(null)

  useEffect(() => {
    if (!canEdit && !canAdd && url === '' && caption === '') onClose(true) // dont allow the view if nothing exists

    // as soon as the editor is rendered urlEditorContainerRef should open
    // setIsUrlEditorOpen(true)
    // listening to table scroll represented by .rdg-light class
    document.querySelector(".rdg-light").addEventListener("scroll", onScroll)
    document.addEventListener('keydown', keyHandler);
    return () => {
      document.removeEventListener("scroll", onScroll)
      document.removeEventListener('keydown', keyHandler);
    }
  }, []);

  useEffect(() => {
    onScroll()
  }, [isUrlEditorOpen])

  const keyHandler = (e) => {
    // tab key press
    if (e.keyCode == 9) {
      if (document.activeElement.classList.contains('rdg-focus-sink') || document.activeElement.classList.contains('sheetItem2')) {
        document.getElementById(`url-editor-portal-container`).focus();
      }

      if (document.activeElement.id === 'dataCell-urlEditor-save-btn' || document.activeElement.id === 'dataCell-urlEditor-delete-icon-btn') {
        document.getElementById(`url-editor-portal-container`).focus();
      }
    }
    if (e.keyCode !== 27) return;
    onClose(true);
  };

  const onScroll = () => {
    if (urlEditorDataCellRef.current) {
      let positionTop = 0
      let leftShift = isUrlEditorOpen ? 0 : 8
      // here 130 is the height of url Editor Container and 35 of the show Url container
      if (urlEditorDataCellRef.current.getBoundingClientRect().bottom + 130 >= window.innerHeight) {
        positionTop = isUrlEditorOpen ? positionTop - 130 : positionTop - 35
      } else {
        positionTop = 40
      }
      const refValue = ReactDOM.findDOMNode(urlEditorDataCellRef.current)
      if (refValue) {
        setPos({
          right: refValue
            .getBoundingClientRect().right,
          left: refValue
            .getBoundingClientRect().left + leftShift,
          top: refValue
            .getBoundingClientRect().top + positionTop,
          bottom: refValue
            .getBoundingClientRect().bottom,
        })
      }
    }

  }

  const handleClick = (e) => {
    e.preventDefault()
    onClose(true)
  }

  const saveUrlData = (clearUrlData) => {
    if (clearUrlData) {
      const additionalKeys = { [`${colKey}_details`]: [] }
      saveCurrentRowData('', colKey, rowId, registerData.rows, false, false, additionalKeys)
    } else {
      const allowSave = allowSaving()
      if (!allowSave) return
      const additionalKeys = { [`${colKey}_details`]: [{ value: url }] }
      saveCurrentRowData(caption, colKey, rowId, registerData.rows, false, false, additionalKeys)
    }
    onClose(true)
  }

  // handles key events of the editor container div
  const handleContainerDivKeyDown = (e) => {
    // enter key press
    if (e.keyCode == 13) {
      // Trigger click of the active element on which the focus is.
      const allowSave = allowSaving()
      if (!allowSave && document.activeElement.id === 'dataCell-urlEditor-save-btn') {
        e.preventDefault()
        e.stopPropagation()
      } else {
        document.activeElement.click();
        onClose(true)
      }
    }
  }

  const editKeyDown = (e) => {
    // To stop handleContainerDivKeyDown from getting triggered
    // as except this, if "Enter" is pressed on "delete", "save" or "cancel" then
    // handleContainerDivKeyDown gets called and editor gets closed. But here we need it opened.
    e.stopPropagation()
    if (e.key === 'Enter') setIsUrlEditorOpen(true)
  }

  const inputTextKeyDown = (e) => {
    // To stop handleContainerDivKeyDown from getting triggered
    // as except this, if "Enter" is pressed on "delete", "save" or "cancel" then
    // handleContainerDivKeyDown gets called and editor gets closed. But here we need it opened.
    e.stopPropagation()
    if (e.key === 'Enter') saveUrlData()
  }

  const onUrlChange = (e) => {
    setUrl(e.target.value)
    if (showUrlError) setShowUrlError(false)
  }

  const onCaptionChange = (e) => {
    setCaption(e.target.value)
    if (showCaptionError) setShowCaptionError(false)
  }

  const allowSaving = () => {
    let allow = true
    const isUrlEmpty = url.trim().length === 0
    const isCaptionEmpty = caption.trim().length === 0
    if (isUrlEmpty || isCaptionEmpty) {
      if (isUrlEmpty) setShowUrlError(true)
      if (isCaptionEmpty) setShowCaptionError(true)
      allow = false
    }
    return allow
  }

  return (
    <ErrorBoundary>
      <div className="w-full h-full" tabIndex="-1" id="url-editor-container-div" onKeyDown={handleContainerDivKeyDown}>
        {/*Pre select value containing div starts here*/}
        <div ref={urlEditorDataCellRef} tabIndex="0" onClick={handleClick} className="sheetItem1 focus:bg-gray-600">
          <span className="px-2 text-blue-500" >
            {value || ''}
          </span>
        </div>
        {/*Pre select value containing div ends here*/}

        {/*Portal starts here*/}
        {createPortal(
          <div
            ref={urlEditorContainerRef}
            className="flex justify-center items-center rounded-lg shadow-xl"
            id="url-editor-portal-container"
            tabIndex="-1"
            style={{
              boxShadow: ' 0px 4px 4px rgb(0 0 0 / 25%)',
              top: pos.top,
              bottom: pos.bottom,
              right: pos.right,
              left: pos.left,
              fontSize: '12px',
              position: 'fixed',
              width: isUrlEditorOpen ? '10rem' : '13rem',
              height: isUrlEditorOpen ? '130px' : '35px',
              backgroundColor: '#EEF3F5'
            }}
          >
            {
              isUrlEditorOpen && canEdit ?
                <div className="flex flex-col gap-2 w-full h-full px-2 py-2" >
                  <div className="flex flex-col grid-cols-1 space-y-2 justify-center w-full">
                    <input tabIndex="0" className="rounded placeholder-grey-500" style={{ ...styles.inputTxt, border: showUrlError ? '1px solid red' : '' }} type="text" placeholder="Enter Url here" value={url} onKeyDown={inputTextKeyDown} onChange={onUrlChange} />
                    <input tabIndex="0" className="rounded placeholder-grey-500" style={{ ...styles.inputTxt, border: showCaptionError ? '1px solid red' : '' }} type="text" placeholder="Caption" value={caption} onKeyDown={inputTextKeyDown} onChange={onCaptionChange} />
                  </div>
                  <div className="flex grid-cols-1 justify-end items-center space-x-3" >
                    <div tabIndex="0" id="dataCell-urlEditor-cancel-btn" style={styles.cancelBtn} className="cursor-pointer" onClick={() => onClose(true)}>Cancel</div>
                    <div tabIndex="0" id="dataCell-urlEditor-save-btn" style={styles.saveBtn} className="cursor-pointer text-white px-3  rounded" onClick={() => saveUrlData(false)}>Save</div>
                  </div>
                </div>
                :
                url && url.includes('https://') ?
                  <div className="flex justify-center w-full px-4" >
                    <div className="truncate w-4/6">
                      <a
                        tabIndex="0"
                        className="text-blue-500"
                        target="_blank"
                        href={url && url.includes('https://') ? url : `https://${url}`}>
                        {url}
                      </a>
                    </div>
                    {
                      canEdit &&
                      <div className="flex w-2/6 items-stretch place-content-end space-x-2.5">
                        <div tabIndex="0" className="cursor-pointer" onKeyDown={editKeyDown} onClick={() => setIsUrlEditorOpen(true)}><img src={editIcon} className="w-3.5 h-3.5" alt="" /></div>
                        <div tabIndex="0" id="dataCell-urlEditor-delete-icon-btn" className="cursor-pointer" onClick={() => saveUrlData(true)}><img src={deleteIcon} className="w-3.5 h-3.5" alt="" /></div>
                      </div>
                    }
                  </div>
                  :
                  <div>No url</div>
            }
          </div>,
          document.body)}
        {/*Portal ends here*/}
      </div>
    </ErrorBoundary>
  )
}

export default UrlEditor;

const styles = {
  cancelBtn: { fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 'normal', fontSize: '12px', lineHeight: '24px', letterSpacing: '-0.006em', color: '#454545' },
  saveBtn: { backgroundColor: '#586C9E', fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 'normal', fontSize: '12px', lineHeight: '24px', letterSpacing: '-0.006em' },
  inputTxt: { height: '40%', fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 'normal', fontSize: '14px', lineHeight: '24px' }
}