import { useSelector } from 'react-redux'

import textIcon from '../../../../../../images/datatype_icons/light/text.png'
import { useEffect, useState, useRef } from 'react'

const Text = ({ colkey, column, handleChange, formData, rowIndex, shouldAllowNewEntry }) => {

  // redux variables
  const registerData = useSelector(state => state.register)

  //ref variables
  const textAreaRef = useRef(null)

  // local variables
  let textarea = null;
  const { isAdmin, hasBasicEditPermission, editPermissions } = registerData
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colkey) === -1

  useEffect(() => {
    textarea = document.querySelector(`#textarea${colkey}`)
    if (textarea !== null) {
      let scHeight = textarea.scrollHeight
      textarea.style.height = `${scHeight}px`
    }
  }, [formData[colkey], colkey])

  return (
    <div className="mb-6">
      <div className="colName">
        <img src={textIcon} className="field_icon" />
        <label htmlFor={column.name}>{column.name}</label>
      </div>

      {
        !canEdit && !shouldAllowNewEntry &&
        <div className="field">{formData[colkey]}</div>
      }

      {
        (shouldAllowNewEntry || canEdit) &&
        <textarea
          id={`textarea${colkey}`}
          ref={textAreaRef}
          rows={1}
          name={colkey}
          type="text"
          // placeholder="Enter text"
          value={formData[colkey]}
          onChange={(e) => handleChange({ colkey, value: e.target.value })}
          className="field flex focus:ring-transparent"
          style={{ resize: 'none', overflow: 'hidden', minHeight: '40px' }}
        />
      }
    </div>
  )
}

export default Text