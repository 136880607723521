import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import LastRowCellEditor from './LastRowCellEditor'

const FeatureNotAvailableEditor = ({ rowProperties, saveCurrentRowData, colIdx, columnEl }) => {

   // redux variables 
   const registerData = useSelector(state => state.register)

   // local variables
   const colKey = columnEl.key
   const rowIndex = rowProperties.rowIdx
   const filteredRows = registerData.filteredRows
   const isLastRow = filteredRows.length - 1 === rowIndex

  useEffect(() => {
    if (isLastRow) return
    alert("Feature not available.")
    rowProperties.onClose(true)
  }, []);

  if (isLastRow) {
    return <LastRowCellEditor
      rowProperties={rowProperties}
      saveCurrentRowData={saveCurrentRowData}
      colIdx={colIdx}
      columnEl={columnEl}
    />
  }
  return (
    <div className=" w-full h-full"></div>

  )
}

export default FeatureNotAvailableEditor