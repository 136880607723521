import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

// components
import CustomThreeDotMenu from '../../../../../../../partials/CustomThreeDotMenu'

// icons
import deleteIcon from '../../../../../../../images/add-edit row dialog/form icons/delete.png'
import goToLinkRegister from '../../../../../../../images/goToLinkRegister.png'
import edit from '../../../../../../../images/add-edit row dialog/form icons/edit.png'

const ImageList = ({ linkedDataType, colkey, formData, setShowLinkedColPopup, closeDropdown, deleteEntry, value, showMenu, linkedRowId, linkedRegisterRows, column }) => {

  //redux variables
  const leftSidebarData = useSelector(state => state.leftSidebar)

  // router variables
  const history = useHistory()

  // local variables
  const { activeBusinessId } = leftSidebarData
  const { linkedColumnId, linkedRegisterId } = column

  const goToLinkedRecord = (e) => {
    let rowid
    linkedRegisterRows.forEach((row) => {
      if (row[linkedColumnId] == value) {
        rowid = row['rowId']
      }
    })
    history.push(`/community/${activeBusinessId}/register/${linkedRegisterId}?rowId=${rowid}`)
    if (linkedRowId) {
      history.push(`/community/${activeBusinessId}/register/${linkedRegisterId}?rowId=${linkedRowId}`)
    } else if (rowid) {
      history.push(`/community/${activeBusinessId}/register/${linkedRegisterId}?rowId=${rowid}`)
    }
  }

  const onDeleteClick = () => {
    deleteEntry(colkey + '_details')
    closeDropdown()
  }

  const listData = [
    { icon: edit, label: 'Edit', onClick: () => { setShowLinkedColPopup(true); closeDropdown() }, showItem: true, style: { color: '#666666', fontSize: '12px' }, iconClass: `h-3`, showLoader: false },
    { icon: goToLinkRegister, label: 'Go To Linked Record', onClick: goToLinkedRecord, showItem: true, style: { color: '#666666', fontSize: '12px' }, iconClass: `h-3`, showLoader: false },
    { icon: deleteIcon, label: 'Delete', onClick: onDeleteClick, showItem: true, style: { color: '#FC1055', fontSize: '12px' }, iconClass: `h-3`, showLoader: false }
  ]

  return (
    <>
      {
        linkedDataType === 'image' && formData[colkey + '_details'] && formData[colkey + '_details'].length > 0 &&
        <div className="flex">
          <div className="flex items-center overflow-x-auto w-11/12">
            {
              formData[colkey + '_details'].map((image, i) => {
                if (Object.keys(image).includes('linkedRowId') || image.value == '') return
                return (
                  <div key={`image-${i}`} className="flex my-2 mr-4" style={{ flexShrink: '0' }}>
                    <img
                      className="h-full w-full"
                      style={{ borderRadius: '10.6328px', height: '4.5rem', width: '4.5rem' }}
                      src={image.value}
                      alt={image.value}
                    />
                  </div>
                )
              })
            }
          </div>
          {
            showMenu &&
            <div className='w-1/12 child-in-center'>
              <CustomThreeDotMenu showDropdown={true} color={'gray'} listData={listData} alignment={`vertical`} />
            </div>
          }

        </div>
      }
    </>
  )
}

export default ImageList
