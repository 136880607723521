import { useState } from "react"

// components
import LinkedColumnValues from "./Components/LinkedColumnValues"
import PortalPopup from "../../../../../partials/PortalPopup"

// icons
import NoResults from "./Components/NoResult"
import AddNewEntryButton from "./Components/AddNewEntryButton"
import SearchAndClose from "./Components/SearchAndClose"

const LinkedColumnPopUp = ({ onClose, columnEl, saveRowData, value, linkedColRegContent, loading }) => {

  // state variables
  const [searchQuery, setSearchQuery] = useState(value)
  const [queryForFilter, setQueryForFilter] = useState('')
  // separate variable is used in filteredRows function - helps in case of fast typing -
  // delay of 0.5s in updating state(prevents excessive re-rendering)
  // also, to not filter the rows when initially setting 'searchQuery' to 'value'
  // rows are filtered when queryForFilter is changed - when searchQuery is changed but after delay of 0.5s

  // local variables
  const { linkedColumnId, linkedDataType, linkedRegisterId, linkedSheetId } = columnEl
  const { rows } = linkedColRegContent
  const linkedColumnName = linkedColRegContent && linkedColRegContent.columns && linkedColRegContent.columns.filter((col)=>{
    return col.key == linkedColumnId
  })[0].name

  const filteredRows = rows && rows.filter((row) => {
    if (row['rowId'] == 'CALCULATE_ROW' || (!row[linkedColumnId] && linkedDataType != 'image')) return
    if (linkedDataType == 'image') {
      if (row[linkedColumnId + '_details'] && row[linkedColumnId + '_details'].length > 0) return true
      else return false
    }
    return Object.keys(row).some((key) => {
      if (key != '-1' && key != 'rowId' && !key.includes('_details')) {
        return row[key].toString().toLowerCase().includes(queryForFilter.toLowerCase())
      }
    })
  })

  return (
    <PortalPopup
      onClose={onClose}
      containerClass={`rounded-2xl overflow-hidden bg-white flex flex-col`}
      containerStyle={popupContainerStyle}
    >

      <SearchAndClose onClose={onClose} searchQuery={searchQuery} setSearchQuery={setSearchQuery} setQueryForFilter={setQueryForFilter} />

      {/* loader */}
      {
        loading && <div className="child-in-center h-full"><div className="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-600"></div></div>
      }
      {/* loader */}

      {
        !loading &&
        <div className="my-4 mx-6 h-full flex flex-col relative overflow-hidden">

          {/* Header with linked register name */}
          <div className="font-medium px-5 py-1 flex items-center" style={{ backgroundColor: '#E4F0FF' }}>
            Result Showing from ‘{linkedColRegContent.name}’, column ‘{linkedColumnName}’
          </div>
          {/* Header with linked register name */}

          {
            filteredRows && filteredRows.length == 0 ?
              <NoResults />
              :
              <>

                {/* Linked column value and row values */}
                <div className="overflow-y-auto">
                  <LinkedColumnValues
                    linkedColRegContent={linkedColRegContent}
                    linkedColumnId={linkedColumnId}
                    linkedDataType={linkedDataType}
                    searchQuery={searchQuery}
                    filteredRows={filteredRows}
                    saveRowData={saveRowData}
                    onClose={onClose}
                  />
                </div>
                {/* Linked column value and row values */}

                <AddNewEntryButton linkedRegisterId={linkedRegisterId} />

              </>
          }
        </div>
      }
    </PortalPopup>
  )
}

const popupContainerStyle = {
  width: '40rem', maxWidth: '95vw', height: '87vh', color: '#174184'
}

export default LinkedColumnPopUp