import { useSelector } from 'react-redux'

// components
import AddEntry from './AddEntry'
import Filter from './Filter'
import Members from './Members'
import Modify from './Modify'
import SearchEntry from './SearchEntry'
import ScannerInput from './ScannerInput'

const RegisterTools = () => {

  //redux variables
  const registerData = useSelector(state => state.register)

  //local variables
  const { groupDataBy, dataAddition } = registerData

  return (
    <>
      {dataAddition == 'scanner' ? <ScannerInput /> : <AddEntry />}
      <Filter />
      {/* <View />
      <Members />
      <Modify /> */}
      <SearchEntry />
    </>
  )
}

export default RegisterTools