import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//css
import './layoutSetting.css'

//slices
import { saveRowDetailsViewType } from '../../../../../../slices/RegisterSlice'

//images
import oneColLayout from '../../../../../../images/right-sidebar/col-layout/1-col.png'
import twoColLayout from '../../../../../../images/right-sidebar/col-layout/2-col.png'

const LayoutSetting = () => {

  //redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  // local variables
  const { sheetId, rowDetailsViewType } = registerData

  const layoutSettingsData = [
    {
      type: 'one',
      imagePlacement: 'left',
      src: oneColLayout,
      text: '1 Column Layout',
      subText: 'One column layout with a record model being fixed to 720px width',
      class: ``,
      style: { border: rowDetailsViewType == 'one' ? '2px solid #80ABEA' : '2px solid #F5F9FF' }
    },
    {
      type: 'two',
      imagePlacement: 'right',
      src: twoColLayout,
      text: '2 Columns Layout',
      subText: 'Two equal width columns layout with a record model being fixed to 1080px width',
      class: `flex-row-reverse`,
      style: { border: rowDetailsViewType == 'two' ? '2px solid #80ABEA' : '2px solid #F5F9FF' }
    }
  ]

  const onSettingOptionClick = (type) => {
    dispatch(saveRowDetailsViewType({ sheetId, rowDetailsViewType: type }))
  }

  //styles
  const container = { height: '90vh' }

  //classes
  const containerClass = `bg-white p-5`
  const commonLayoutOptionClass = `settingOption mt-4 rounded-xl p-5 child-in-center gap-4 h-32 cursor-pointer transition-all delay-50`
  const layoutOptionTextClass = `text-base font-bold`
  const layoutOptionSubTextClass = `font-medium text-xs`

  return (
    <div style={container} className={containerClass}>

      <div className='text-sm font-medium' style={{ color: '#666666' }}>Please select the layout from below</div>

      {
        layoutSettingsData.map((setting, i) => {
          return (
            <div
              key={`${setting.text}-${i}`}
              onClick={() => onSettingOptionClick(setting.type)}
              style={setting.style}
              className={`${commonLayoutOptionClass} ${setting.class}`}
            >
              <img src={setting.src} className='h-16' />
              <div>
                <div style={{ color: '#385379' }} className={layoutOptionTextClass}>{setting.text}</div>
                <div style={{ color: '#8199BB' }} className={layoutOptionSubTextClass}>{setting.subText}</div>
              </div>
            </div>
          )
        })
      }

    </div>
  )
}

export default LayoutSetting