import { useEffect, useCallback, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"

// components
import RegisterDetails from './Components/RegisterDetails'
import Notifications from './Components/Notifications'
import ColumnsLayoutSetting from './Components/ColumnsLayoutSetting'

// slices
import { getNotifications, toggleRightSidebarOpen } from '../../../slices/RightSidebarSlice'

const RightSidebar = () => {

  // redux variables
  const dispatch = useDispatch()

  //state variables
  const leftSidebarData = useSelector(state => state.leftSidebar)
  const rightSidebarData = useSelector(state => state.rightSidebar)

  // local varibles
  const { activeBusinessName, activeBusinessId } = leftSidebarData
  const { rightSidebarOpen, body } = rightSidebarData

  //ref variables
  const sidebarContainerRef = useRef(null)

  const clickHandler = useCallback(({ target }) => {
    if (rightSidebarOpen) {
      if (sidebarContainerRef.current && sidebarContainerRef.current.contains(target)) return;
      dispatch(toggleRightSidebarOpen({ rightSidebarOpen: false, body: null }))
      dispatch(getNotifications({ businessId: activeBusinessId }))
    }
  }, [rightSidebarOpen])

  const keyHandler = ({ keyCode }) => {
    if (rightSidebarOpen && keyCode !== 27) return;
    dispatch(toggleRightSidebarOpen(false));
  };

  //close sidebar when active business is changed
  useEffect(() => {
    dispatch(toggleRightSidebarOpen({ rightSidebarOpen: false, body: null }))
    dispatch(getNotifications({ businessId: activeBusinessId }))
  }, [activeBusinessName])

  // close on click outside
  useEffect(() => {
    if (rightSidebarOpen) {
      document.addEventListener('click', clickHandler);
      document.addEventListener('keydown', keyHandler);
      return () => {
        document.removeEventListener('click', clickHandler)
        document.removeEventListener('keydown', keyHandler);
      }
    }
  }, [rightSidebarOpen]);

  const classes = {
    sidebar: `absolute shadow-lg flex flex-col font-sans overflow-hidden right-0 top-0 h-screen transform transition-all duration-200 ease-in-out ${rightSidebarOpen ? 'translate-x-0 opacity-100' : 'opacity-0 translate-x-full pointer-events-none'} ${body == 'notification' ? 'w-96' : 'w-80'}`,
    backdrop: `fixed inset-0 bg-gray-900 bg-opacity-30 z-30 transition-opacity duration-200 ${rightSidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`
  }

  const styles = {
    sidebar: { background: '#EEF3F5', zIndex: '200' }
  }

  return (
    <div className="relative">

      {/* Backdrop */}
      <div className={classes.backdrop} aria-hidden="true"></div>

      {/* Right Sidebar */}
      <div className={classes.sidebar} style={styles.sidebar} ref={sidebarContainerRef}>
        {body == 'registerDetail' && <RegisterDetails />}
        {body == 'notification' && <Notifications />}
        {body == 'columnsLayoutSetting' && <ColumnsLayoutSetting />}
      </div>

    </div>
  )
}

export default RightSidebar