// general modules
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import axios from 'axios'
import ReactGA from 'react-ga4';

// bugsnag
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
Bugsnag.start({
  apiKey: '7943308535097c3edae82fb9022f1f80',
  plugins: [new BugsnagPluginReact()]
})
const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

// axios.defaults.baseURL = 'http://localhost:3000/'
// axios.defaults.baseURL = 'https://dev.rekord.in/'
axios.defaults.baseURL = 'https://load.rekord.in/'

const token = localStorage.getItem("token")
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  axios.defaults.params = {}
  axios.defaults.params['platform'] = "web"
}

if (axios.defaults.baseURL == "https://load.rekord.in/") {
  const trackingId = "G-R4G5DH10L5";
  let t = ReactGA.initialize(trackingId);
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
