//components
import AttachmentsList from "./AttachmentsList"
import ImagesList from "./ImagesList"

const EditHistoryItem = ({ item }) => {

  // local variables
  const { columnName, userName, userProfilePic, time, value, oldValue, detailedValue, oldDetailedValue, dataType } = item

  const getvaluesSection = (type) => {
    if (dataType == 'image') {
      return (
        <div className={`${valueSectionContainer} ${type=='old'? 'mt-2' : 'mb-2 relative bottom-2'}`}>
          <div className={from_to_class}>{type == 'old'? 'From' : 'To'}</div>
          <div className="pl-3 overflow-x-auto no-scrollbar w-full">
            <ImagesList imagesArray={type=='old'? detailedValue : oldDetailedValue} />
          </div>
        </div>
      )
    }

    if (dataType == 'attachment') {
      return (
        <div className={`${valueSectionContainer} ${type=='old'? 'mt-2' : 'relative bottom-2'}`}>
          <div className={from_to_class}>{type == 'old'? 'From' : 'To'}</div>
          <div className="pl-3 overflow-x-auto no-scrollbar w-full">
            <AttachmentsList attachmentsArray={type=='old'? detailedValue : oldDetailedValue} />
          </div>
        </div>
      )
    }

    return (
      <div className={`${valueSectionContainer} ${type=='old'? 'mt-4' : 'pt-4 mb-5'}`}>
        <div className={from_to_class}>{type == 'old'? 'From' : 'To'}</div>
        <div className={value_class} style={value_styles}>
          {type=='old'? oldValue : value}
        </div>
      </div>
    )

  }

  //classes and styles
  const valueSectionContainer = `truncate flex items-center`
  const from_to_class = `w-4/12 md:w-3/12 lg:w-2/12`
  const value_class = `truncate px-3 mr-3 rounded-md py-2 text-center w-8/12 md:w-9/12 lg:w-10/12`
  const value_styles = { fontWeight: '600', color: '#586C9D', backgroundColor: '#EEF3F6' }

  return (
    <div className="flex pt-5" style={{ borderBottom: '0.5px solid rgba(182, 182, 182, 0.6)' }}>

      {/* User profile Pic */}
      <div className="w-2/12 flex justify-center">
        <img src={userProfilePic} style={{ height: '2rem', width: '2rem' }} className='rounded-full' />
      </div>
      {/* User profile Pic */}

      <div className="w-10/12 pl-2">
        <div style={{ color: '#454545', fontSize: '0.93rem', fontWeight: '600' }}>
          {userName} made changes in <span style={{ color: '#044089' }}>{columnName}</span>
        </div>
        <div style={{ color: '#999999', fontSize: '0.85rem' }}>
          <div className=''>{time}</div>
          {getvaluesSection('old')}
          {getvaluesSection('new')}
        </div>
      </div>
    </div>
  )
}

export default EditHistoryItem