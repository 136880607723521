
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import DataGrid, { Row } from 'react-data-grid'

import './index.css';

import AddrowForm from './Form'
import CustomContextMenu from '../../partials/CustomContextMenu'
import Carousel from '../../partials/Carousel'
import { getTableConfigWithCellFormat } from './Table/Utils/tableConfig'
import { focusHighlightedCell, getEmptyRowIdx } from './Table/Utils'

// slices
import { fetchRegisterData, fetchAllRegisterData, fetchRegisterPages, updateWidth, setRowIndex, updateRegisterData, updateRegisterLocally, updateRegisterState, saveFilters } from '../../slices/RegisterSlice'
import { fetchRegisterDetails } from '../../slices/RightSidebarSlice'
import { setToastDetails } from '../../slices/ToastSlice'

//utils
import { computeFormulaColVal, getSumCntAvgVal, isRowEmpty } from '../../utils/Utils'
import ErrorBoundary from '../../utils/ErrorBoundary'
import DeviceMeta from '../../utils/DeviceDetector'

//icons
import addEntryIcon from '../../images/plusBlue.png'

const moment = require('moment')

function Register() {

  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)
  const isCarouselOpen = useSelector(state => state.carousel.isCarouselOpen)

  // router variables
  const history = useHistory()
  const location = useLocation()
  const { search } = location

  // state variables
  let { columns } = registerData
  const { filters, sheetId, deletedColumnInfo } = registerData
  const [data, setData] = useState({ rows: [], columns: [] })
  const [addrowForm, setAddrowForm] = useState(false)
  const [unfilteredRowsData, setUnfilteredRowsData] = useState(registerData.rows)
  const [shouldAllowNewEntry, setShouldAllowNewEntry] = useState(false)
  const [selectedDataCell, setSelectedDataCell] = useState({}) // to keep a track of currently highlighted data cell

  // ref variables
  const dataGridRef = useRef(null)

  //local variables 
  let { registerId } = useParams()
  {/*
    permissions
    Have added isAdmin check with other entities so that if
    the array of permissions just has 'admin' text then directly
    the respective permissions are evoked and code redundancy is monitered.
  */}
  const isAdmin = registerData.permissions.includes('admin')
  const canAdd = registerData.permissions.includes('add') || isAdmin
  let widthThrottler;
  const rowAddCompletedInfo = registerData.rowAddCompletedInfo
  const userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

  // close if the esc key is pressed- add row form modal
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!addrowForm || keyCode !== 27) return;
      setAddrowForm(false)
    };
    document.addEventListener('keydown', keyHandler)
    return () => {
      document.removeEventListener('keydown', keyHandler)
    }
  }, []);

  // fetch register data and register pages
  useEffect(() => {
    dispatch(fetchAllRegisterData({ registerId, sheetId: '' }))
    dispatch(fetchRegisterPages({ registerId }))
    dispatch(fetchRegisterDetails({ registerId }))
    // delete
    // dispatch(fetchAllRegisterData({ registerId, sheetId: '' }))
    // delete
  }, [registerId])

  // set row data locally whenever changes
  // open add new entry or existing new entry form if url has rowId search param
  useEffect(() => {
    const originalRowData = registerData.rows
    if (unfilteredRowsData.length !== originalRowData.length && registerData.sheetId) {
      setUnfilteredRowsData(originalRowData)
      const query = new URLSearchParams(search)
      const rowIdFromUrl = query.get('rowId')
      if (rowIdFromUrl && originalRowData.length > 1) { // greater than 1 because Caclculate row always exists
        const actualRowIdx = originalRowData.findIndex(el => el.rowId === rowIdFromUrl)
        if (rowIdFromUrl === 'newEntry') addNewEntry()
        else if (actualRowIdx > -1) {
          dispatch(setRowIndex(actualRowIdx))
          setAddrowForm(true)
        } else {
          history.replace(location.pathname) // so that if rowId is not fitting any scenario then search params should be removed from url
        }
      }
    }
  }, [registerData.rows])

  // recalculate last row values whenever rows are filtered on search
  // recalculate last row values whenever rows are updated
  // calculateLastRow is updated to true when register is updated somewhere in the rows
  useEffect(() => {
    const originalRowData = registerData.rows
    if (originalRowData.length > 0) { // original row data required
      refreshLastRow()
    }
  }, [registerData.searchQuery, registerData.calculateLastRow, registerData.filters])

  // refresh columns configurations 
  useEffect(() => {
    refreshRegisterColumnConfig()
  }, [registerData.columns])

  useEffect(() => {
    if (deletedColumnInfo.idx >= 0) {
      // 1. If column being deleted is before or is the column with focussed data cell, then to keep it as it is we need to reduce the selected column index by 1.
      //    Further if a column with focussed cell is just next to S.No cell then there is no change in the value of selectedCell idx.
      // 2. If the column deleted is after a column with focussed data cell,  then to keep it as there is no change in the value of selectedCell idx.
      if (deletedColumnInfo.idx <= selectedDataCell.idx) {
        if (selectedDataCell.idx - 1 === 0) activateTabOnCell(selectedDataCell.idx, selectedDataCell.rowIdx, true)
        else activateTabOnCell(selectedDataCell.idx - 1, selectedDataCell.rowIdx, true)
      } else if (deletedColumnInfo.idx > selectedDataCell.idx) {
        activateTabOnCell(selectedDataCell.idx, selectedDataCell.rowIdx, true)
      }
      updateFiltersOnColDeletion(deletedColumnInfo.columnId)
      dispatch(updateRegisterState([{ key: 'deletedColumnInfo', value: {} }]))
    }
  }, [deletedColumnInfo])

  useEffect(() => {
    if (registerData.preOpenColMenu) {
      const rowIdx = selectedDataCell.rowIdx >= 0 ? selectedDataCell.rowIdx : 0
      activateTabOnCell(registerData.preOpenColMenu.colIdx, rowIdx, true)
      setTimeout(() => {
        let idxToBeScrolled = registerData.preOpenColMenu.colIdx
        const lastDataColIdx = registerData.columns.length - 2 // -2 because + column is also there
        if (idxToBeScrolled === lastDataColIdx) idxToBeScrolled++ // shift the scroll to + button column so that visibility is clear
        dataGridRef.current.scrollToColumn(idxToBeScrolled)
      }, 50)
    }
  }, [registerData.preOpenColMenu])

  useEffect(() => {
    if (rowAddCompletedInfo) {
      const lastDataRowIdx = registerData.rows.length - 2
      let emptyRowIdx = rowAddCompletedInfo.idxToBeScrolled > -1 ?
        rowAddCompletedInfo.idxToBeScrolled : lastDataRowIdx
      // timeout used because of rendering delay after search is cleared
      setTimeout(() => {
        activateTabOnCell(1, emptyRowIdx, true)
        dataGridRef.current.scrollToRow(emptyRowIdx)
      }, 50)
      dispatch(updateRegisterState([{ key: 'rowAddCompletedInfo', value: null }]))
    }
  }, [rowAddCompletedInfo])

  useEffect(() => {
    if (registerData.successMessage) {
      dispatch(setToastDetails({ message: registerData.successMessage, type: 'success' }))
      dispatch(updateRegisterState([{ key: 'successMessage', value: null }]))
    }
  }, [registerData.successMessage])

  const updateFiltersOnColDeletion = (columnId) => {
    let filtersCopy = [...filters]
    filtersCopy = filters.filter(el => el.columnId !== columnId)
    dispatch(saveFilters({ sheetId, filters: filtersCopy, rows: registerData.rows }))
  }

  // Helps in opening the cells without fast double click.
  // The timer allows fast adjacent click ( assumption till the time adjacent double click )
  // are optimised.
  const openEditorAfterSelect = ({ isCellSelected, rowIdx, colIdx }) => {
    if (ios) { // ios specific as we require it there to hack there keyboard limitation of "focus"
      triggerMobileKeyboard(columns[colIdx])
    }
    if (isCellSelected) {
      setTimeout(() => {
        dataGridRef.current.selectCell({ idx: colIdx, rowIdx }, true)
      }, 5)
    }
  }

  const triggerMobileKeyboard = (col) => {
    if (col) {
      const { dataType } = col
      if (['String', 'rupee', 'phoneNumber', 'number', 'mail', 'unit'].indexOf(dataType) > -1) {
        var inputElement = document.getElementById('hiddenInput');
        inputElement.style.visibility = 'visible'; // unhide the input
        inputElement.focus(); // focus on it so keyboard pops
        inputElement.style.visibility = 'hidden'; // hide it again
      }
    }
  }

  const refreshRegisterColumnConfig = () => {
    let columns = getTableConfigWithCellFormat(
      registerData.columns,
      canAdd,
      openEditorAfterSelect,
      saveCurrentRowData
    )
    setData({ columns })
  }

  const activateTabOnCell = (idx, rowIdx, informRegister = true) => {
    dataGridRef.current.selectCell({ idx, rowIdx })
    if (informRegister) setSelectedDataCell({ idx, rowIdx })
  }

  const closeAddrowForm = (newRowIndex) => {
    if (selectedDataCell.rowIdx === registerData.filteredRows.length - 1) {
      // if the focus was on last row data cell
      // then focus should shift to the row just above "Calculate" if the row being deleted
      // had one of its data cell focused maintaining column which was focused.
      activateTabOnCell(selectedDataCell.idx, registerData.filteredRows.length - 2, false)
    } else if (newRowIndex >= 0) {
      // if new row is added then focus should shift on that row
      activateTabOnCell(1, newRowIndex)
    } else {
      focusHighlightedCell()
    }
    // normal closing
    setAddrowForm(false)
    dispatch(setRowIndex(-1))
    if (shouldAllowNewEntry) setShouldAllowNewEntry(false)
    history.replace(location.pathname) // to change the url back to what it was
  }

  const onRowClick = (rowIndex, rowDetails, columnDetails) => {
    const serialNoCell = document.getElementById(`serialNumbCell/-1/${rowDetails.rowId}`)
    const isLastRow = rowIndex === registerData.filteredRows.length - 1
    if (isLastRow && columnDetails.idx !== 0) { // open last row cells on single click
      setTimeout(() => {
        dataGridRef.current.selectCell({
          idx: columnDetails.idx,
          rowIdx: rowIndex
        }, true)
      }, 5)
    }
    // checks that 
    // if the column clicked is S.No column
    // if the S.No column of last row ( showing count details ) is clicked then modal should not open 
    if (columnDetails.key === "-1" && !isLastRow) {
      if (serialNoCell) { // this check ensures avoiding this functionality on the click of '+' button
        const actualRowIdx = parseInt(serialNoCell.innerText) - 1
        const selectedCellColIdx = selectedDataCell.idx >= 0 ? selectedDataCell.idx : 1
        dispatch(setRowIndex(actualRowIdx))
        setAddrowForm(true)
        history.replace(location.pathname + `?rowId=${rowDetails.rowId}`)
        // maintain existing focus when S.No cell is clicked as table is pre programmed to 
        // give focus to that cell which is clicked and we dont want focus on S.No cell
        const isElementInScreen = document.querySelector('[aria-selected="true"]')
        if (isElementInScreen) {
          activateTabOnCell(selectedCellColIdx, rowIndex)
        }
      }
    }
    // if rows are added then the last recent row's first data cell should get the focus 
    if (columnDetails.key === "-1" && isLastRow) {
      activateTabOnCell(1, unfilteredRowsData.length - 1)
    }

    // particularly for mobile apps
    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
    if (DeviceMeta.isMobileOrTablet()) {
      setTimeout(() => {
        dataGridRef.current.selectCell({ idx: columnDetails.idx, rowIdx: rowIndex }, true)
      }, 5)
    }

  }

  const openFirstEmptyRow = () => {
    const originalRowData = registerData.rows
    let emptyRowIndex = getEmptyRowIdx(originalRowData)
    dispatch(setRowIndex(emptyRowIndex === originalRowData.length - 1 ? -1 : emptyRowIndex))
    setAddrowForm(true)
  }

  const onColumnResize = (colIdx, width) => {
    if (widthThrottler) clearTimeout(widthThrottler)
    widthThrottler = setTimeout(() => {
      let freezedColumns = []
      let columnsInDisplayOrder = []
      registerData.columns.forEach((el, index) => {
        if (el.isFreezed && el.key !== '-1' && el.key !== 'addColPlusButton') {
          freezedColumns.push(el)
        } else {
          columnsInDisplayOrder.push(el) // push unfreezed columns considering S.No and plus button columns as well
        }
      })
      columnsInDisplayOrder.splice(1, 0, ...freezedColumns) // insert frozen columns
      const columnId = columnsInDisplayOrder[colIdx].key
      dispatch(updateWidth({ sheetId: registerData.sheetId, columnWebLength: width, columnId }))
    }, 300)
  }

  const saveCurrentRowData = (
    updatedValue,
    colKeyForWhichValueIsUpdated,
    rowId,
    childScopeRegisterDataRows,
    shouldUpdateLocally = false,
    isLastRowOfTable = false,
    additionalKeys,
    keyEvent,
    linkedRowId
  ) => {

    const currentRowData = childScopeRegisterDataRows
    let formulaObj = {}
    let evaluatedColValsForFormula = {} // => { colNumber_1: value_after_calc, colNumber_2: value_after_calc }
    let columnsToBeUpdatedLocally = {} // like createdAt, createdBy
    // - actualRowIdx directly picks row index from S.No column because after filtering we need that idx for update at BE
    // - it is subtracted by 1 because its actual position is in an array 
    // - serialNoCell check ensures that if "+" button element is picked then simple "tableRowIdx" is used
    let actualRowIdx;

    //serialNoCell always gives the original index of the row being updated in the original register data
    if (isLastRowOfTable) { // if '+' image is there indicating its the last row so we need original last row index
      actualRowIdx = currentRowData.length - 1
    } else { // if not last row
      actualRowIdx = parseInt(document.getElementById(`serialNumbCell/-1/${rowId}`).innerText) - 1
    }
    let newRowDataForLocalChange
    let newEntry = isRowEmpty(currentRowData[actualRowIdx])

    newRowDataForLocalChange = getUpdatedCopyOfRow(currentRowData[actualRowIdx], { [colKeyForWhichValueIsUpdated]: updatedValue }) // this holds the row updated data which might later come from api but its currently done locally
    if (additionalKeys) {
      newRowDataForLocalChange = getUpdatedCopyOfRow(newRowDataForLocalChange, additionalKeys)
    }

    if (shouldUpdateLocally) {
      dispatch(updateRegisterLocally({ rowIndex: actualRowIdx, formData: newRowDataForLocalChange }))
      return
    }
    registerData.columns.forEach((colEl, index) => {
      if (colEl.dataType === "formula") {// cumulate all formulas
        formulaObj[colEl.key] = colEl.formula // ==> { colNumber_1: ['A','*','B'], colNumber_2: ['c','+','B'] } // index-1 to ignore S.No column
      }
      if (colEl.dataType === "createdAt") { // needs a local update if doesnt exists 
        if (!currentRowData[actualRowIdx][colEl.key] || currentRowData[actualRowIdx][colEl.key].length === 0) {
          columnsToBeUpdatedLocally[colEl.key] = moment(new Date()).format("DD MMM YYYY, h:mm a");
        }
      }
      if (colEl.dataType === "createdBy") { // needs a local update if doesnt exists 
        if (!currentRowData[actualRowIdx][colEl.key] || currentRowData[actualRowIdx][colEl.key].length === 0) {
          columnsToBeUpdatedLocally[colEl.key] = localStorage.getItem('name')
        }
      }
    })
    // {-----------------formula based work starts here --------------}
    if (Object.keys(formulaObj).length > 0) {
      // Calculate for each column so that inter dependent cells upon new values, 
      // should always consider new row in the next loop eg., first 'hr min' calculation then wage calculation based upon
      // new cell value of.
      Object.keys(formulaObj).forEach((formulaColKey) => {
        evaluatedColValsForFormula = computeFormulaColVal(
          formulaColKey,
          formulaObj[formulaColKey],
          newRowDataForLocalChange,
          currentRowData[actualRowIdx - 1],
          registerData.columns
        )
        newRowDataForLocalChange = getUpdatedCopyOfRow(
          newRowDataForLocalChange,
          evaluatedColValsForFormula
        )
      })
    }

    // {-----------------formula based work ends here --------------}
    // {-----------------any column needing a local update work starts here --------------}
    if (Object.keys(columnsToBeUpdatedLocally).length > 0) {
      newRowDataForLocalChange = getUpdatedCopyOfRow(
        newRowDataForLocalChange,
        columnsToBeUpdatedLocally
      )
    }
    // {-----------------any column needing a local update work ends here --------------}

    // For cell having key='colKeyForWhichValueIsUpdated', i.e., selected cell that was edited, detail value of that cell is handled here
    const colKey = colKeyForWhichValueIsUpdated.includes('_details') ? colKeyForWhichValueIsUpdated.split('_details')[0] : colKeyForWhichValueIsUpdated
    let newRowDataForApi = [{
      columnId: colKey,
      rowId: newRowDataForLocalChange.rowId,
      detailedValue: newRowDataForLocalChange[colKey + '_details'],
      value: newRowDataForLocalChange[colKey],
      sheetId: registerData.sheetId,
      newEntry,
      linkedRowId: linkedRowId
    }]

    // For updating cells in the row other than the selected cell - linked column work
    if (additionalKeys) {
      Object.keys(additionalKeys).forEach((additionalColKey) => {
        if (additionalColKey == colKey || additionalColKey.includes('_details')) return
        newRowDataForApi.push({
          columnId: additionalColKey,
          rowId: newRowDataForLocalChange.rowId,
          detailedValue: newRowDataForLocalChange[additionalColKey + '_details'],
          value: newRowDataForLocalChange[additionalColKey],
          sheetId: registerData.sheetId,
          newEntry,
          linkedRowId
        })
      })
    }
    // For updating cells in the row other than the selected cell - linked column work

    dispatch(updateRegisterData({
      data: newRowDataForApi,
      rowIndex: actualRowIdx,
      formData: newRowDataForLocalChange,
      entryFrom: colKeyForWhichValueIsUpdated ? 'cell' : ''
    }))
    if (keyEvent) {
      handleUserInputKeyEvent(keyEvent, colKeyForWhichValueIsUpdated, childScopeRegisterDataRows, actualRowIdx)
    }
  }

  const handleUserInputKeyEvent = (keyEvent, colKeyForWhichValueIsUpdated, childScopeRegisterDataRows, actualRowIdx) => {
    // if user presses enter then the focus should shift on the bottom cell
    if (keyEvent.keyCode === 13) {
      const colIdxToBeShiftedTo = registerData.columns.findIndex(el => el.key === colKeyForWhichValueIsUpdated)
      // enter on last row cell should not trigger the activateTabOnCell for next down cell highlight
      const rowIdxToBeShiftedTo = childScopeRegisterDataRows.length === actualRowIdx + 1 ? null : actualRowIdx + 1
      if (rowIdxToBeShiftedTo) activateTabOnCell(colIdxToBeShiftedTo, actualRowIdx + 1, false)
    }
    if (keyEvent.keyCode === 9) {
      const colIdxOfUpdatedCol = registerData.columns.findIndex(el => el.key === colKeyForWhichValueIsUpdated)
      activateTabOnCell(colIdxOfUpdatedCol + 1, actualRowIdx, false)
    }
  }

  const getUpdatedCopyOfRow = (prevsRow, updateObj) => {
    let copyOfPrevsRow = JSON.parse(JSON.stringify(prevsRow))
    // Insert new value corresponding to the keys present in the updateObj ( also containing the corresponding values )
    // into prevsRow.
    // STRUCTURE: copyOfPrevsRow ==> { 1: 'old value'}, updateObj ==> { 1: 'new value'}
    Object.keys(updateObj).forEach((key) => {
      copyOfPrevsRow[key] = updateObj[key]
      if (updateObj[key] === '') {
        copyOfPrevsRow[key + '_details'] = [] // done just for the case of status as some status details had array values whcih we dont need
      }
    })
    return copyOfPrevsRow
  }

  const addNewEntry = () => {
    if (!canAdd) {
      alert("Permission Denied")
      return;
    }
    history.replace(location.pathname + `?rowId=newEntry`)
    setShouldAllowNewEntry(true)
    openFirstEmptyRow()
  }

  const refreshLastRow = () => {
    let columnsToBeUpdated = {}
    const visibleRowData = registerData.filteredRows
    const originalRowData = registerData.rows
    columns.forEach(function (colEl) {
      const columnKey = colEl.key
      if (columnKey !== "-1") { // ignore S.No column
        const lastRowIdx = visibleRowData.length - 1
        const lastRowCellVal = visibleRowData[lastRowIdx][columnKey] ? visibleRowData[lastRowIdx][columnKey] : ''
        let calculationType = ''
        if (lastRowCellVal.includes('Count') || lastRowCellVal.includes('Yes')) {
          calculationType = 'Count'
        } else if (lastRowCellVal.includes('Sum')) {
          calculationType = 'Sum'
        } else if (lastRowCellVal.includes('Average')) {
          calculationType = 'Average'
        } else {
          return
        }
        columnsToBeUpdated[columnKey] = getSumCntAvgVal(calculationType, colEl, visibleRowData)
      }
    })
    // originalRowData is used as we need to update the main registerData whcich again syncs the filteredRows
    const lastRowIndex = originalRowData.length - 1
    const lastRowData = originalRowData[originalRowData.length - 1]
    const newCalculatedRow = getUpdatedCopyOfRow(lastRowData, columnsToBeUpdated)
    dispatch(updateRegisterLocally({ rowIndex: lastRowIndex, formData: newCalculatedRow }))
  }

  const summaryRows = useMemo(() => {
    const summaryRow = registerData.rows[registerData.rows.length - 1]
    return [summaryRow];
  }, [registerData.columns, registerData.rows]);

  const getRows = () => {
    return registerData.filteredRows.filter((el, index) => {
      if (index < registerData.filteredRows.length - 1) return el
    })
  }

  return (
    <ErrorBoundary>
      <div className="h-full">
        {
          ios && <input id="hiddenInput" style={{ visibility: 'hidden', position: 'fixed' }} />
        }
        {!registerData.rows.length && <div className="text-center mx-auto w-full">No results found</div>}
        <div id='data-grid-div' className="w-full h-5/6 md:h-full">
          <DataGrid
            ref={dataGridRef}
            className="rdg-light"
            columns={data.columns}
            rows={getRows()}
            rowHeight={40}
            enableCellSelect={true}
            style={{ height: "100%", backgroundColor: 'rgb(245, 245, 245)' }}
            onRowClick={onRowClick}
            onColumnResize={onColumnResize}
            onSelectedCellChange={(el) => {
              // if the focus shifts to + column data cell while tabbing then it should be,
              // redirected to first data cell of next row and its track should be maintained
              if (el.idx === registerData.columns.length - 1) {
                activateTabOnCell(1, el.rowIdx + 1)
              } else if (selectedDataCell.idx === 1 && el.idx === 0) {
                // if the focus shifts to S.No while shift+tabbing then it should be,
                // redirected to last data cell of next row and its tracked by 
                // a callback of onSelectedCellChange when the below statement does its work
                // then if (el.idx !== 0) tracks it.
                activateTabOnCell(registerData.columns.length - 2, el.rowIdx - 1, false)
              } else if (el.idx !== 0) {
                // we just want to keep a track of data cells other than S.No cell
                // while normal tabbing or shift+tabbing.
                setSelectedDataCell(el)
              }
            }}
            rowRenderer={useCallback((props) => <Row  {...props} className={`${DeviceMeta.isMobileOrTablet() ? '' : "edit_hover_class"}`} />, [])}
            summaryRows={summaryRows}
          />
        </div>

        <div tabIndex="-1" className="h-1/6 md:hidden child-in-center" style={{ backgroundColor: '#EFF6FF' }}>
          <button
            onClick={addNewEntry}
            id='date-picker-portal-server'
            className="h-10 rounded-lg focus:outline-none"
            style={{ backgroundColor: "#B9D9FF", color: '#174184', maxWidth: '95%' }}
          >
            <div className='child-in-center gap-2 hover:bg-blue-200 transition-colors delay-50 h-full w-full rounded-lg w-80 max-w-full'>
              <img src={addEntryIcon} className='h-3.5' />
              Add New Entry
            </div>
          </button>
        </div>

      </div>

      {
        addrowForm &&
        <AddrowForm
          isOpen={addrowForm}
          closeAddrowForm={closeAddrowForm}
          shouldAllowNewEntry={shouldAllowNewEntry}
        />
      }

      {isCarouselOpen && <Carousel saveCurrentRowData={saveCurrentRowData} />}
      <CustomContextMenu />
    </ErrorBoundary>
  )
}

export default Register;
{/*
  NOTES:
  *sheet tabbing is handlled from 3 places :  onSelectedCellChange, onRowClick and closeAddRowForm. 
*/}

