import loader from '../images/full-screen-loader.gif'

const FullScreenLoader = () => {

  const classes = {
    container: `flex justify-center items-center fixed h-screen top-0 bottom-0 left-0 w-full ${true ? 'opacity-100' : 'opacity-0 pointer-events-none'}`,
    backdropDiv: `flex justify-center items-center absolute h-screen top-0 bottom-0 left-0 w-full ${true ? 'opacity-100' : 'opacity-0 pointer-events-none'}`,
    subContainer: `flex justify-center items-center rounded transform transition-all duration-200 ease-out ${true ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0 pointer-events-none'} `,
  }

  return (
    <div className={classes.container} style={{ zIndex: '100' }} >
      {/* backdrop container starts */}
      <div
        id="fullScreenLoader/backdrop"
        className={classes.backdropDiv}
        style={{ background: 'rgba(0,0,0,0.4)' }}
      >
      </div>
      {/* backdrop container ends */}

      {/* FullScreenLoader content starts */}
      <div className={classes.subContainer}>
        {/* <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-black" /> */}
        <img src={loader} className="h-60 opacity-50"/>
      </div>
      {/* FullScreenLoader content ends */}

    </div>
  )
}

export default FullScreenLoader