const CustomButton = ({ containerStyle, btnTextStyle, onClick, text, disabled }) => {
	const cStyle = containerStyle ? containerStyle : { width: '90%', height: '70%', borderRadius: 3 }
	const bTextStyle = btnTextStyle ? btnTextStyle : { color: '#fff' }
	return (
		<div onClick={onClick}
			className={`flex justify-center items-center ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
			style={{ ...cStyle }}
		>
			<span style={{ ...bTextStyle }}>{text}</span>
		</div>
	)
}

export default CustomButton