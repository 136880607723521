import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import CustomInputDropdown from '../../../../../../../partials/CustomInputDropdown'

//icons
import durationIcon from '../../../../../../../images/datatype_icons/light/duration.png'
import DropdownBody from './DropdownBody'

const Duration = ({ colkey, column, handleChange, formData, shouldAllowNewEntry, deleteEntry }) => {

  // redux variables
  const registerData = useSelector(state => state.register)

  //state variables
  const [componentDurationValue, setComponentDurationValue] = useState('')
  const [showDropDownMenu, setShowDropDownMenu] = useState(false)

  // local variables
  const { isAdmin, hasBasicEditPermission, editPermissions } = registerData
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colkey) === -1
  let formValue = formData[colkey]

  const onSaveButtonClick = () => {
    if (componentDurationValue == '') return
    if (componentDurationValue == formData[colkey]) {
      setShowDropDownMenu(false)
    } else if (componentDurationValue != '') {
      handleChange({ colkey, value: componentDurationValue })
      setShowDropDownMenu(false)
    }
  }

  const onRemoveIconClick = () => {
    handleChange({ colkey, value: '' })
  }

  return (
    <div className="mb-6">

      <div className="colName">
        <img src={durationIcon} className="field_icon" />
        <label htmlFor={column.name}>{column.name}</label>
      </div>

      {
        !canEdit && !shouldAllowNewEntry &&
        <div className="field truncate">{formData[colkey]}</div>
      }

      {
        (shouldAllowNewEntry || canEdit) &&
        <>
          <CustomInputDropdown
            fieldValue={formValue}
            inputType={`div`}
            container={`form`}
            inputClasses={`field`}
            inputStyles={{}}
            dropdownClasses={``}
            dropdownStyles={{ marginTop: 8, minWidth: '15rem' }}
            buttonsInfo={{ showButtonsSection: true, showCancelButton: true, showSaveButton: true, onSaveButtonClick }}
            showRemoveIcon={!showDropDownMenu}
            onRemoveIconClick={onRemoveIconClick}
            showDropDownMenu={showDropDownMenu}
            setShowDropDownMenu={setShowDropDownMenu}
            onFieldClick={()=>{}}
          >
            <DropdownBody
              setComponentDurationValue={setComponentDurationValue}
              initialFormValue={formData[colkey]}
              saveDurationData={onSaveButtonClick}
            />
          </CustomInputDropdown>
        </>
      }
    </div >
  )
}

export default Duration
