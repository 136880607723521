import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// slices
import { postComment, getComments, setEditCommentIdx } from '../../../../../../../../slices/RowDetailSlice'

// images
import lightGreyAtTheRate from '../../../../../../../../images/add-edit row dialog/greyAtTheRate.png'
import lightGreyAttachment from '../../../../../../../../images/add-edit row dialog/greyAttachment.png'
import sendIcon from '../../../../../../../../images/whiteSendIcon.png'

// css
import '../index.css'

const CommentBox = ({ comment, setComment, textAreaRef }) => {
  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)
  const rowDetailData = useSelector(state => state.rowDetail)
  const theme = useSelector(state => state.theme)

  // ref variables


  // local variables
  const { sheetId, rowIndex, rows } = registerData
  const { comments, editCommentIdx } = rowDetailData
  const rowId = rowIndex > -1 ? rows[rowIndex].rowId : ''
  const { primary1 } = theme

  useEffect(()=>{
    return () => {
      dispatch(setEditCommentIdx(-1))  // to reset as when this component is unmounted to avoid errors
    }
  },[])

  const onCommentChange = (e) => {
    setComment(e.target.value)
  }

  const onSend = async () => {
    if (rowIndex === -1) {
      alert("Its a new entry.")
      return
    }
    if (comment) {
      await dispatch(postComment({ text: comment, rowId, sheetId }))
      setComment('')
      dispatch(getComments({ sheetId, rowId }))
    }
  }

  const onSave = async () => {
    const commentId = comments[editCommentIdx].commentId
    dispatch(postComment({ text: comment, rowId, sheetId, commentId, editCommentIdx }))
    setComment('')
  }

  const onCancel = () => {
    dispatch(setEditCommentIdx(-1))
    setComment('')
  }

  const textAreaOnFocus = (e) => {
    e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
    document.getElementById('addCommentToolsDiv').classList.remove('addCommentToolsDivGreyStyling');
    document.getElementById('addCommentToolsDiv').classList.add('addCommentToolsDivFocusStyling');
  }

  const textAreaOnBlur = () => {
    document.getElementById('addCommentToolsDiv').classList.remove('addCommentToolsDivFocusStyling');
    document.getElementById('addCommentToolsDiv').classList.add('addCommentToolsDivGreyStyling');
  }

  return (
    <div className="flex flex-col pt-2 pb-2 w-full h-full">
      <textarea
        ref={textAreaRef}
        onFocus={textAreaOnFocus}
        onBlur={textAreaOnBlur}
        type="search"
        style={{ height: '73%', lineHeight: 1 }}
        className="w-full no-outline textAreaDefaultStyle no-scrollbar"
        rows="3"
        placeholder="Your comment"
        value={comment}
        onChange={onCommentChange}
      />
      <div style={{ height: '27%' }} id="addCommentToolsDiv" className="flex relative addCommentToolsDivDefaultStyling addCommentToolsDivGreyStyling w-full px-2 pb-2">
        <div className="flex items-end w-1/2 h-full">
          {/*----------------attachment button starts here ----------------*/}
          <img className="cursor-pointer h-10" style={{maxHeight: '100%'}} src={lightGreyAttachment} alt="light Grey Attachment" />
          {/*----------------attachment button ends here ----------------*/}

          {/*----------------at the rate button starts here ----------------*/}
          <img className="cursor-pointer ml-2 h-10" style={{maxHeight: '100%'}} src={lightGreyAtTheRate} alt="light Grey at the rate" />
          {/*----------------at the rate button ends here ----------------*/}

          {/*----------------Send button starts here ----------------*/}
        </div>
        <div className="w-1/2 h-full flex justify-end items-end">
          {
            editCommentIdx === -1 ?
              <button
                onClick={onSend}
                className="px-4 py-1 primary_btn transition-all delay-50"
                style={{maxHeight: '90%'}}
              >
                <img src={sendIcon} className="" alt="white send button" />
                  Send
              </button>
              :
              <>
                <button
                  onClick={onSave}
                  className="flex justify-center items-center px-4 text-white sendButton"
                >
                  Save
                </button>
                <button
                  onClick={onCancel}
                  className="flex justify-center items-center px-4 cancelButton"
                >
                  Cancel
                </button>
              </>
          }

        </div>
        {/*----------------Send button ends here ----------------*/}
      </div>
    </div>
  )
}

export default CommentBox