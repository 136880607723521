import { useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'

// components
import SheetTabs from './SheetTabs'
import AddSheetPlusBtn from './AddSheetPlusBtn'
import CalendarTab from './CalendarTab'

// cyanLeftScroll
// greyRightScroll
// greyLeftScroll
// cyanRightScroll

const PagesHeader = () => {
  // ref variables
  const sheetTabsDivRef = useRef(null)

  // redux variables
  const registerData = useSelector(state => state.register)

  // state variables
  const [showScrollArrows, setShowScrollArrows] = useState(false)

  // local variables
  const { isLoading, isLoadingPdf, isLoadingExcel, fetchRegisterDataLoader, rowCrudLoader, columnCrudLoader, pages, groupDataBy } = registerData
  let sizeCheckThrottler;

  useEffect(() => {
    const checkArrowVisibility = () => {
      clearTimeout(sizeCheckThrottler)
      sizeCheckThrottler = setTimeout(() => {
        if (sheetTabsDivRef.current && sheetTabsDivRef.current.scrollWidth > sheetTabsDivRef.current.clientWidth) {
          setShowScrollArrows(true)
        } else {
          setShowScrollArrows(false)
        }
      }, 100)
    }
    window.addEventListener('resize', checkArrowVisibility);
    return () => window.removeEventListener('resize', checkArrowVisibility);
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (sheetTabsDivRef.current && sheetTabsDivRef.current.scrollWidth > sheetTabsDivRef.current.clientWidth) {
        setShowScrollArrows(true)
      } else {
        setShowScrollArrows(false)
      }
    }, 100) // time out used here because we need to check the measurements after a page is added or removed from ui
  }, [pages])

  return (
    <div className="flex pl-9 w-full pr-2">
      
      {(isLoading || isLoadingPdf || isLoadingExcel || fetchRegisterDataLoader || rowCrudLoader || columnCrudLoader) && <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white m-auto absolute left-2 "></div>}
      
      {
        groupDataBy == 'day' ?
          <CalendarTab /> :
          <>
            <div className="flex justify-center items-center" style={{ maxWidth: '95%' }}>
              <SheetTabs showScrollArrows={showScrollArrows} sheetTabsDivRef={sheetTabsDivRef} />
            </div>
            <AddSheetPlusBtn />
          </>
      }

    </div>
  )
}

export default PagesHeader