import React, { useState, useEffect, useRef } from 'react';
import DatePicker from "react-datepicker";
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux'

// css 
import "react-datepicker/dist/react-datepicker.css";
import './date.css'

// utils
import ErrorBoundary from '../../../../../utils/ErrorBoundary'
import { getValidatedDate } from '../../Utils'

const DateEditor = ({ rowProperties, saveCurrentRowData, colIdx, columnEl }) => {
  // redux variables
  const registerData = useSelector(state => state.register)

  // local variables
  const colKey = columnEl.key
  const rowIndex = rowProperties.rowIdx
  const rowId = rowProperties.row.rowId
  const value = getValidatedDate(rowProperties.row[colKey])

  // state variables
  const [startDate, setStartDate] = useState()
  const [autoOpenDatePicker, setAutoOpenDatePicker] = useState(true)

  // ref variables
  const datePickerRef = useRef()

  //Set initial date
  useEffect(() => {
    if (value) {
      const day = value.split('-')[0]
      const month = value.split('-')[1] - 1
      const year = value.split('-')[2]
      setStartDate(new Date(year, month, day))
    } else {
      setStartDate(null)
    }
  }, [value])

  //Update formData on date change
  const handleDateChange = (date) => {
    if (date) {
      //convert to 'dd-mm-yyyy'
      let day = date.getDate()
      if (day >= 0 && day < 10) {
        day = `0${day}`
      }
      let month = date.getMonth() + 1
      if (month >= 0 && month < 10) {
        month = `0${month}`
      }
      const year = date.getFullYear()
      const date3 = []
      date3.push(day)
      date3.push(month)
      date3.push(year)
      const date4 = date3.join('-')
      //change date value on formData(row)
      saveCurrentRowData(date4, colKey, rowId, registerData.rows)
      setAutoOpenDatePicker(false)
    }
    else {
      setStartDate('')
      datePickerRef.current.setFocus()
      saveCurrentRowData('', colKey, rowId, registerData.rows)
    }

  }

  const onKeyDown = (event) => {
    if (event.keyCode == 9 && document.activeElement.classList[0] === 'date-picker') {
      document.getElementsByClassName('react-datepicker-popper')[0].tabIndex = "-1"
      document.getElementsByClassName('react-datepicker-popper')[0].focus()
    }
  }

  return (
    <ErrorBoundary>
      <DatePicker
        ref={datePickerRef}
        className="date-picker"
        selected={startDate}
        onChange={handleDateChange}
        dateFormat="dd-MM-yyyy"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        open={autoOpenDatePicker}
        popperContainer={({ children }) => {
          return createPortal(children, document.body)
        }}
        onKeyDown={(ev) => onKeyDown(ev)}
        isClearable />
    </ErrorBoundary>
  )
}

export default DateEditor