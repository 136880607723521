import { useSelector, useDispatch } from "react-redux"

// components
import RegisterElement from './RegisterElement'
import FolderElement from './FolderElement'

// slices
import { toggleRightSidebarOpen, fetchRegisterDetails } from '../../../../../slices/RightSidebarSlice'
import { toggleExpandableFolder, fetchFolderContent } from "../../../../../slices/LeftSidebarSlice"

const Registers = ({
  sidebarInput,
  history,
  page,
  selectedRegisterId,
  setSelectedRegisterId
}) => {

  // redux variables
  const dispatch = useDispatch()
  const leftSidebarData = useSelector(state => state.leftSidebar)
  const rightSidebarData = useSelector(state => state.rightSidebar)

  // local variables
  const { activeBusinessId, registers } = leftSidebarData
  const { rightSidebarOpen, showNotifications } = rightSidebarData
  const filteredRegistersAndFolders = registers.filter((item) => {
    if (sidebarInput === '') {
      return item
    } else if (item.name.toLowerCase().includes(sidebarInput.toLowerCase())) {
      return item
    }
  });

  const onRegisterFolderNameClick = (filterDataEl, filterDataIdx) => {
    if (filterDataEl.type === "FOLDER") {
      dispatch(toggleExpandableFolder({ index: filterDataIdx }))
      if (filterDataEl.registers.length === 0) dispatch(fetchFolderContent({ businessId: activeBusinessId, folderId: filterDataEl.folderId }))
    } else {
      dispatch(fetchRegisterDetails({ registerId: filterDataEl.registerId })) // so that everytime name is clicked its data is reflected in right sidebar 
      setSelectedRegisterId(filterDataEl.registerId)
      history.push(`/community/${activeBusinessId}/register/${filterDataEl.registerId}`)
    }
  }

  const isOneOfTheChildSelected = (registers) => {
    let isSelected = false;
    registers.forEach((registerEl) => {
      if (registerEl.registerId === selectedRegisterId) isSelected = true
    })
    return isSelected
  }

  const onEditIconClick = (id) => {
    dispatch(fetchRegisterDetails({ registerId: id })) // so that everytime a register's 3-dots is selected its data changes / is reflected in right sidebar
    dispatch(toggleRightSidebarOpen({ rightSidebarOpen: !rightSidebarOpen, body: 'registerDetail' }))
  }

  return (
    <div className="w-full overflow-y-scroll no-scrollbar bg-white h-full">
      <ul className="cursor-pointer">
        {!filteredRegistersAndFolders.length && <div className="text-center mx-auto w-full">No results found</div>}
        {
          filteredRegistersAndFolders.map(function (element, index) {
            return (
              <div key={`div/sidebar/registersList/${index}`}>
                {/*Registers list item */}
                <li key={`li/sidebar/registersList/${index}`} className={`rounded-sm last:mb-0`}>
                  {
                    element.type == "FOLDER" ?
                      <FolderElement
                        folderEl={element}
                        folderIdx={index}
                        selectedRegisterId={selectedRegisterId}
                        onEditIconClick={onEditIconClick}
                        onRegisterFolderNameClick={onRegisterFolderNameClick}
                      />
                      :
                      <RegisterElement
                        registerEl={element}
                        selectedRegisterId={selectedRegisterId}
                        onEditIconClick={onEditIconClick}
                        onRegisterFolderNameClick={onRegisterFolderNameClick}
                      />
                  }
                </li>
                {/*Registers list item */}
              </div>
            )
          })
        }

      </ul>
    </div>
  )
}

export default Registers