import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//images
import editIcon from '../../../../../images/right-sidebar/edit1.png'
import tickMarkBlue from '../../../../../images/right-sidebar/tickMarkBlue.png'
import { renameRegisterDescription } from '../../../../../slices/RightSidebarSlice'

const About = () => {

  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)
  const rightSidebarData = useSelector(state => state.rightSidebar)

  //state variables
  const [editedDescription, setEditedDescription] = useState(null)
  const [editMode, setEditMode] = useState(false)

  // local variables
  const { registerId, permissions } = registerData
  const { registerDescription, rightSidebarOpen } = rightSidebarData
  const isAdmin = permissions.includes("admin")

  useEffect(() => {
    if (!rightSidebarOpen) {
      setEditedDescription(null)
      setEditMode(false)
    }
  }, [rightSidebarOpen])

  const onEditIconClick = () => {
    if (!isAdmin) {
      alert("Permission Denied")
      return
    }
    setEditMode(true)
    setEditedDescription(registerDescription)
  }

  const saveDescription = () => {
    dispatch(renameRegisterDescription({ registerId, description: editedDescription }))
    setEditedDescription(null)
    setEditMode(false)
  }

  const onDescriptionInputKeydown = (e) => {
    if (e.code === 'Enter') {
      saveDescription()
    }
  }

  return (
    <div className="pt-4 ml-0.5" style={{ height: '11.6666667%' }}>

      <div className='px-4 bg-white h-full group'>
        {
          editMode ?
            (
              <div className='flex justify-between items-center h-full'>
                <div className='w-full'>
                  <div className='text-xl'>About</div>
                  <input
                    id="register-description-input"
                    value={editedDescription}
                    onChange={(e) => setEditedDescription(e.target.value)}
                    className='text-sm pr-2 w-full h-6 focus:outline-none flex'
                    onKeyDown={onDescriptionInputKeydown}
                    autoFocus />
                </div>
                <div>
                  <img onClick={saveDescription} src={tickMarkBlue} className='w-5 cursor-pointer' />
                </div>
              </div>
            ) :
            (
              <div className='flex justify-between items-center h-full'>
                <div className='w-full'>
                  <span className='text-xl '>About</span>
                  <div className='text-sm h-6 truncate flex items-center'>{registerDescription}</div>
                </div>
                <div>
                  <img onClick={onEditIconClick} src={editIcon} className='h-5 scale-75 opacity-0 transform transition-all delay-50 hover:scale-100 group-hover:opacity-100 cursor-pointer' />
                </div>
              </div>
            )
        }

      </div>

    </div>
  )
}

export default About