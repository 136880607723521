import React, { useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { useHistory, useLocation } from 'react-router-dom';

// slices
import { updatePopNavigationHistory } from '../../slices/RouterSlice'

// components
import WelcomeBanner from './Components/banner/WelcomeBanner';
import EmptyRegisterBody from '../../features/Register/PopUps/AddRegister/Components/EmptyRegisterBody'

function Welcome() {
  // redux variables
  const dispatch = useDispatch()
  const leftSidebarData = useSelector(state => state.leftSidebar)

  // route variables
  const history = useHistory()
  const location = useLocation()

  // local variables
  const { registers, homePageDataFetched } = leftSidebarData
  const showAddRegisterGrid = registers.length === 0

  const selectCategory = (category) => {
    dispatch(updatePopNavigationHistory({
      type: 'push',
      path: location.pathname + '?registerCategoryType=' + category.type
    }))
    history.replace(location.pathname + '?registerCategoryType=' + category.type)
  }

  const goToCreateRegister = () => {
    history.replace(location.pathname + '?popUp=createRegister')
  }
  
  if (homePageDataFetched && showAddRegisterGrid) {
    return <EmptyRegisterBody
      selectCategory={selectCategory}
      goToCreateRegister={goToCreateRegister}
    />
  } else {
    return <WelcomeBanner />
  }
}

export default Welcome
