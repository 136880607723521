import { useSelector } from 'react-redux'

//icons and images
import loader from '../../../../images/header/loader.gif'

const RegisterInfo = () => {

  // redux variables
  const registerData = useSelector(state => state.register)
  const rightSidebarData = useSelector(state => state.rightSidebar)

  // local variable
  const { registerName } = rightSidebarData
  const { registerAvatar, isLoading, isLoadingPdf, isLoadingExcel, fetchRegisterDataLoader, rowCrudLoader, columnCrudLoader } = registerData

  return (
    <div className="flex justify-start items-center pl-8 lg:pl-2">
      <img className="ml-1" src={registerAvatar} alt="register icon" style={{ width: 27, height: 27 }} />
      <p className="truncate pl-2 text-white font-semibold" style={{ fontSize: 15 }}>{registerName}</p>
      {/* {(isLoading || isLoadingPdf || isLoadingExcel || fetchRegisterDataLoader || rowCrudLoader || columnCrudLoader) && <img src={loader} className="h-6 ml-2" />} */}
    </div>
  )
}

export default RegisterInfo