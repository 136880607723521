// general modules
import { Route, Redirect, useLocation } from 'react-router-dom';

// customised routes
export const ProtectedLoginRoute = ({ component: Component, isAuth: isLoggedIn, ...rest }) => {
  const location = useLocation()
  const isNotFound = location.pathname === '/notFound'
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isNotFound) return (<div>Not Found</div>)
        if (isLoggedIn) {
          return (
            <Redirect to='/' />
          )
        } else {
          return (
            <Component />
          )
        }
      }}
    />
  )
}

export const ProtectedSignUpPasswordRoute = ({ component: Component, isAuth: isLoggedIn, ...rest }) => {
  const location = useLocation()
  const isNotFound = location.pathname === '/notFound'
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isNotFound) return (<div>Not Found</div>)
        if (isLoggedIn) {
          return (
            <Redirect to='/' />
          )
        } else {
          return (
            <Component />
          )
        }
      }}
    />
  )
}

export const ProtectedRoute = ({ component: Component, isAuth: isLoggedIn, ...rest }) => {
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={(props) => {
        const isNotFound = location.pathname === '/notFound'
        if (isNotFound) return (<div>Not Found</div>)
        if (isLoggedIn) {
          return (<Component />)
        } else {
          return (
            <Redirect to='/login' />
          )
        }
      }}
    />
  )
}