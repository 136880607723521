import { useState} from 'react'

//icons and image
import qnMark from '../../../../images/qnMark.png'
import closeIcon from '../../../../images/close.png'
import qrIcon from '../../../../images/qrIcon.png'

const QrInfoMenu = () => {

  //state variables
  const [showQRInfoMenu, setShowQRInfoMenu] = useState(false)

  //local variables
  const text1 = `Login with QR Code`
  const menuPoints = [
    <div>1. Open my Record Book moblie App</div>,
    <div>2. Click on <b>Profile</b> and select <b>Desktop option</b></div>,
    <div>3. Click on <b>Scan QR code</b> option and scan</div>
  ]

  const classes = {
    qrIcon: `h-5 cursor-pointer`,
    qrInfoMenu: `w-56 sm:w-80 p-2 sm:p-4 absolute bg-white border shadow-lg transform transition-all origin-top-right delay-50 ease-in-out right-full top-0 z-30 ${showQRInfoMenu ? 'opacity-100 scale-100' : 'opacity-0 scale-0'}`,
    closeIcon: `absolute right-4 h-3 cursor-pointer`,
    text1: `text-lg md:text-xl lg:text-2xl`,
    qrMenuPointsContainer: `flex flex-col gap-2 sm:gap-1 sm:flex-row items-center justify-center py-2`,
    qrMenuPoint: `text:xs sm:text-sm text-left pt-1`
  }

  return (
    <div className="relative" onMouseLeave={() => setShowQRInfoMenu(false)}>
      <img src={qnMark} className={classes.qrIcon} onClick={() => { setShowQRInfoMenu(!showQRInfoMenu) }} onMouseEnter={() => setShowQRInfoMenu(true)} />
      <div className={classes.qrInfoMenu}>
        <div onClick={() => { setShowQRInfoMenu(false) }}><img src={closeIcon} className={classes.closeIcon} /></div>
        <div className={classes.text1}>{text1}</div>
        <div className={classes.qrMenuPointsContainer}>
          <div className="w-10/12">
            {menuPoints && menuPoints.map((point) => {
              return (
                <div className={classes.qrMenuPoint}>{point}</div>
              )
            })}
          </div>
          <div className="sm:w-2/12">
            <img src={qrIcon} className="h-12" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default QrInfoMenu