import { useDispatch, useSelector } from "react-redux"

import { updateLoginInfoState } from "../../../slices/LoginSlice"

const RedirectToSignUp = ({ setRedirectToSignup }) => {

  const dispatch = useDispatch()
  const loginInfo = useSelector(state => state.loginInfo)
  const { error } = loginInfo

  const onClick = () => {
    if (error) {
      dispatch(updateLoginInfoState([{ key: 'error', value: false }, { key: 'errorMessage', value: null }]))
    }
    setRedirectToSignup(true)
  }

  return (
    <div className="font-medium mt-4" style={{ color: '#6C757D' }}>
      Are you new here?&nbsp;
      <span onClick={onClick} style={{ color: '#0B98FF' }} className='cursor-pointer'>Signup</span>
    </div>
  )
}

export default RedirectToSignUp