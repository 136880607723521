import { useEffect, useState } from 'react'

// components
import CustomButton from '../../../../../partials/CustomButton'
import SelectColumnForFormula from './SelectColumnForFormula'

//icons
import calcBackspaceIcon from '../../../../../images/calcBackspaceIcon.png'

const ColumnCalculator = ({ formulaStrArr, changeFormulaStrArr, saveFormula, selectedColIdx }) => {

  // state variables
  const [formulaStr, setFormulaStr] = useState('')


  useEffect(() => {
    let newFormulaStr = ''
    if (formulaStrArr.length > 0) {
      formulaStrArr.forEach((el) => {
        if (el) newFormulaStr = newFormulaStr + el.string
      })
    }
    setFormulaStr(newFormulaStr)
  }, [formulaStrArr])

  const onChange = (e) => {
    setFormulaStr(e.target.value)
  }

  const onCalcBtnClick = (val, type) => {
    if (type === 'clearAll') {
      changeFormulaStrArr({ type })
    } else if (type === 'backSpace') {
      if (formulaStrArr.length > 0) changeFormulaStrArr({ type })
    } else if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'].includes(val)) {
      changeFormulaStrArr({ string: val, type: 'number' })
    } else if (['+', 'x', '-', '/'].includes(val)) {
      changeFormulaStrArr({ string: val, type: 'operator' })
    } else if (['(', ')'].includes(val)) {
      changeFormulaStrArr({ string: val, type: 'bracket' })
    }
  }

  return (
    <>
      {/* --------------- label starts ---------------  */}
      <div className="flex items-center pt-4" style={{ height: '3%', color: '#576D9E', fontWeight: '400' }}>
        &nbsp;
      </div>
      {/* ---------------  label ends  --------------- */}

      {/* ---------------  text area for user input starts ---------------  */}
      <div className="flex relative pt-2" style={{ height: '31%' }}>
        <textarea disabled value={formulaStr} type="text" onChange={onChange} className="w-full h-20 sm:h-full" placeholder="Type a Formula&#10;Eg: Column A + Column B" style={{ border: '1px solid #B6B6B6', borderRadius: 6, fontSize: 12, paddingLeft: 10, resize: 'none' }} />
      </div>
      {/*  --------------- text area for user input ends  --------------- */}

      {/*  */}
      <div className='sm:hidden'>
        <SelectColumnForFormula
          selectedColIdx={selectedColIdx}
          formulaStrArr={formulaStrArr}
          changeFormulaStrArr={changeFormulaStrArr}
        />
      </div>

      {/*  --------------- calculator keyboard starts  --------------- */}
      <div className="flex flex-col p-4 justify-center" style={{ height: '61%' }}>
        <div className="flex gap-2">
          <CalcBtn text="1" onClick={onCalcBtnClick} />
          <CalcBtn text="2" onClick={onCalcBtnClick} />
          <CalcBtn text="3" onClick={onCalcBtnClick} />
          <CalcBtn text="+" onClick={onCalcBtnClick} />
          <CalcBtn text="x" onClick={onCalcBtnClick} />
        </div>
        <div className="flex gap-2 pt-2">
          <CalcBtn text="4" onClick={onCalcBtnClick} />
          <CalcBtn text="5" onClick={onCalcBtnClick} />
          <CalcBtn text="6" onClick={onCalcBtnClick} />
          <CalcBtn text="-" onClick={onCalcBtnClick} />
          <CalcBtn text="/" onClick={onCalcBtnClick} />
        </div>
        <div className="flex gap-2 pt-2">
          <CalcBtn text="7" onClick={onCalcBtnClick} />
          <CalcBtn text="8" onClick={onCalcBtnClick} />
          <CalcBtn text="9" onClick={onCalcBtnClick} />
          <CalcBtn text="(" onClick={onCalcBtnClick} />
          <CalcBtn text=")" onClick={onCalcBtnClick} />
        </div>
        <div className="flex gap-2 pt-2">
          <CalcBtn text="C" type="clearAll" onClick={onCalcBtnClick} />
          <CalcBtn text="0" onClick={onCalcBtnClick} />
          <div text="" style={{ backgroundColor: 'transparent', height: '32px', width: '32px' }} />
          <CalcBtn text="." onClick={onCalcBtnClick} />
          <CalcBtn icon={calcBackspaceIcon} type="backSpace" onClick={onCalcBtnClick} />
        </div>
      </div>
      {/* ---------------  calculator keyboard ends ---------------  */}

      {/*  --------------- Save btn starts ---------------  */}
      <div className="flex w-full justify-center items-end" style={{ height: '15%' }}>
        <CustomButton
          text={'Save'}
          containerStyle={{ backgroundColor: '#4DB14F', width: '100%', height: '80%', borderRadius: 8 }}
          btnTextStyle={{ color: '#fff', fontSize: 19, fontWeight: '600' }}
          onClick={saveFormula}
        />
      </div>
      {/*  --------------- Save btn ends ---------------  */}
    </>
  )
}

export default ColumnCalculator

const CalcBtn = ({ text, icon, type, onClick }) => {
  return (
    <div
      className="flex justify-center items-center shadow cursor-pointer w-1/2"
      style={{ backgroundColor: '#F9F9F9', height: '32px', color: '#576D9E', borderRadius: 6 }}
      onClick={() => onClick(text, type)}
    >
      {text && text}
      {
        icon &&
        <img src={icon} width="20" height="20" />
      }
    </div>
  )
}