import { useSelector, useDispatch } from 'react-redux'

// slices
import { toggleDownloadButtonLoader, setActiveActivityTab } from '../../../../slices/RowDetailSlice'
import { pdfDownload, deleteRow } from '../../../../slices/RegisterSlice'
import { toggleDialogOpen, updateBody, updateHeaderText, hideHeader } from '../../../../slices/DialogSlice'
import { toggleRightSidebarOpen } from '../../../../slices/RightSidebarSlice'

// components
import CustomThreeDotMenu from '../../../../partials/CustomThreeDotMenu'

//css
import '../index.css'

import commentIcon from '../../../../images/add-edit row dialog/commentIcon.png'
import historyIcon from '../../../../images/add-edit row dialog/historyIcon.png'
import whiteHistoryIcon from '../../../../images/add-edit row dialog/whiteHistoryIcon.png'
import whiteCommentIcon from '../../../../images/add-edit row dialog/whiteCommentIcon.png'
import activeHistoryIcon from '../../../../images/add-edit row dialog/activeHistoryIcon.png'
import activeCommentIcon from '../../../../images/add-edit row dialog/activeCommentIcon.png'
import closeIcon from '../../../../images/add-edit row dialog/close-new.png'
import downloadIcon from '../../../../images/add-edit row dialog/download-new.png'
import columnsLayoutIcon from '../../../../images/columnsLayoutIcon.png'
import deleteIcon from '../../../../images/add-edit row dialog/delete-new.png'
import deleteIcon2 from '../../../../images/add-edit row dialog/delete-row.png'
import popUpCloseIcon from '../../../../images/add-edit row dialog/popUpCloseIcon.png'
import mobileBackIcon from '../../../../images/arrowLeftWhite.png'
import { useEffect } from 'react'

const Header = ({ rowId, closeAddrowForm, shouldAllowNewEntry }) => {
  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)
  const leftSidebarData = useSelector(state => state.leftSidebar)
  const rowDetailData = useSelector(state => state.rowDetail)
  const theme = useSelector(state => state.theme)

  // local variables
  const { rowIndex, sheetId, rowCrudLoader, permissions } = registerData
  const originalRowData = registerData.rows
  const communityId = leftSidebarData.activeBusinessId
  const { isLoadingDownload, activeActivityTab } = rowDetailData
  const { primary1, grey, dark_grey, red } = theme
  const deleteText = `Are you sure you want to Delete this Entry?`

  // ------------ use effects --------------
  useEffect(() => {
    {/* reset store value of active tab to null as when pop up is 
      opened next time we dont want the last active tab to be opened. */}
    return () => dispatch(setActiveActivityTab(null))
  }, [])

  // --------------- functions
  const fetchRowData = async () => {
    dispatch(toggleDownloadButtonLoader())
    if (rowIndex !== -1) {
      const selectedRowIds = [registerData.rows[rowIndex].rowId]
      await dispatch(pdfDownload({ sheetId, communityId, name: 'row', selectedRowIds }))
    }
    dispatch(toggleDownloadButtonLoader())
  }

  const handleDeleteButtonClick = () => {
    if (permissions.includes("admin")) {
      dispatch(hideHeader())
      dispatch(toggleDialogOpen(true))
      dispatch(updateBody(getDeleteBody()))
      dispatch(updateHeaderText('Delete Row?'))
    } else {
      alert("Permission Denied")
    }
  }

  const openColumnsLayoutSetting = () => {
    dispatch(toggleRightSidebarOpen({ rightSidebarOpen: true, body: 'columnsLayoutSetting' }))
  }

  const deleteCurrentRow = () => {
    dispatch(deleteRow({ sheetId, rowId }))
    dispatch(toggleDialogOpen(false))
  }

  const onHistoryCommentClick = (tabToBeActivated) => {
    if (activeActivityTab && activeActivityTab === tabToBeActivated) dispatch(setActiveActivityTab(null))
    else dispatch(setActiveActivityTab(tabToBeActivated))
  }

  const getDeleteBody = () => {
    return (
      <div className="bg-white flex flex-col justify-center rounded-lg px-6" style={{ width: '25rem', maxWidth: '95vw' }}>
        <div className='child-in-center'><img src={deleteIcon2} className='h-16 mt-6' /></div>
        <div className="text-lg text-center mt-6 mb-3" style={{ color: dark_grey }}>{deleteText}</div>
        {/* Buttons */}
        <div className="flex justify-center sm:justify-end items-center gap-4 mt-3 mb-6">
          <button onClick={(e) => { e.preventDefault(); dispatch(toggleDialogOpen(false)) }} className="py-1 px-4 secondary_btn w-1/2 sm:w-auto" style={{ color: grey }}>Cancel</button>
          <button onClick={(e) => { e.preventDefault(); deleteCurrentRow() }} className="text-white rounded-lg py-1 px-4 w-1/2 sm:w-auto hover:bg-red-700 bg-red-600 transition-colors delay-50">Delete</button>
        </div>
        {/* Buttons */}
      </div>
    )
  }

  const listData = [
    { icon: downloadIcon, label: 'Download', onClick: fetchRowData, showItem: rowIndex === -1 ? false : true, style: { color: dark_grey }, iconClass: `h-4`, showLoader: isLoadingDownload },
    { icon: columnsLayoutIcon, label: 'Page layout', onClick: openColumnsLayoutSetting, showItem: true, style: { color: dark_grey }, iconClass: `h-4`, showLoader: false },
    { icon: deleteIcon, label: 'Delete', onClick: handleDeleteButtonClick, showItem: !shouldAllowNewEntry || rowIndex !== -1, style: { color: red }, iconClass: `h-4`, showLoader: rowCrudLoader }
  ]

  return (
    <div className="px-8 relative sm:rounded-lg sm:rounded-b-none flex justify-between shadow-md z-10 w-full h-full" style={{ backgroundColor: "#174184" }}>

      <div style={{ color: '#fff' }} className="flex items-center text-xl font-bold">
        <img src={mobileBackIcon} className='sm:hidden h-4 mr-4 cursor-pointer' onClick={() => { closeAddrowForm() }} />
        <div>
          <span className='whitespace-nowrap'>{rowIndex === -1 ? 'Add row' : 'Entry Details'}</span>
          {/* mobile */}
          <div className="font-normal text-base sm:hidden text-white">
            <span>S.No. </span>
            <span>&nbsp;{rowIndex === -1 ? originalRowData.length : rowIndex + 1}</span>
          </div>
          {/* mobile */}
        </div>
      </div>

      <div className="h-full items-center hidden sm:flex" style={{ color: "#fff", fontSize: '1.1rem' }}>
        <span>S.No. </span>
        <span>&nbsp;{rowIndex === -1 ? originalRowData.length : rowIndex + 1}</span>
      </div>

      <div className="flex justify-center items-center">

        {/* History Icon */}
        <div className={`hidden sm:block rounded-lg mr-4 p-1 headerIcon transition-colors delay-50 ${activeActivityTab == 'history' ? 'activeHeaderIcon' : ''}`}>
          <img
            src={historyIcon}
            onClick={() => onHistoryCommentClick('history')}
            className='h-5 cursor-pointer'
          />
        </div>
        {/* History Icon */}

        {/* Comment Icon */}
        <div className={`hidden sm:block rounded-lg mr-4 p-1 headerIcon transition-colors delay-50 ${activeActivityTab == 'comments' ? 'activeHeaderIcon' : ''}`}>
          <img
            src={commentIcon}
            onClick={() => onHistoryCommentClick('comments')}
            className='h-5 cursor-pointer'
          />
        </div>
        {/* Comment Icon */}

        <CustomThreeDotMenu showDropdown={true} color={'white'} colorWhenOpen={'blue'} listData={listData} />

        {/*Close button */}
        <button
          onClick={() => { closeAddrowForm() }}
          className='hidden sm:block'
        >
          <img className="h-6 absolute" style={{ bottom: '75%', right: '-1.2%', }} src={popUpCloseIcon} alt="Close" />
        </button>
        {/*Close button */}

      </div>
    </div>
  )
}

export default Header