import { useState } from 'react'
import { useSelector } from 'react-redux'
import LastRowCellFormatter from './LastRowCellFormatter'

// images
import addNewLabel from '../../../../../images/addNewLabel.png'
import addMoreLabels from '../../../../../images/addMoreLabels.png'

// utils
import { getValidatedFormatterVal } from '../../Utils'

const Label = ({ rowProperties, openEditorAfterSelect, columnEl }) => {
  // redux variables
  const registerData = useSelector(state => state.register)

  // local variables
  const colKey = columnEl.key
  const colIdx = rowProperties.column.idx
  const isCellSelected = rowProperties.isCellSelected
  const rowIdx = rowProperties.rowIdx
  const rowId = rowProperties.row.rowId
  const labels = rowProperties.row[colKey + '_details']
  const firstLabel = rowProperties.row[colKey + '_details'] && rowProperties.row[colKey + '_details'][0] ? rowProperties.row[colKey + '_details'][0] : null
  const filteredRows = registerData.filteredRows
  const isLastRow = filteredRows.length - 1 === rowIdx
  const searchQuery = registerData.searchQuery
  const shouldHighlight = searchQuery !== '' && firstLabel && (firstLabel.value.toString().toLowerCase()).includes(searchQuery.toLowerCase())

  const handleOpenEditor = () => {
    openEditorAfterSelect({ isCellSelected, rowIdx, colIdx })
  }

  if (isLastRow) {
    return <LastRowCellFormatter
      rowProperties={rowProperties}
      openEditorAfterSelect={openEditorAfterSelect}
      colIdx={colIdx}
      colKey={colKey}
    />
  }
  if (firstLabel) {
    return (
      <div
        id={`dataCell/${colKey}/${rowId}`}
        onClick={handleOpenEditor}
        className="flex items-center h-full w-full"
        style={{ backgroundColor: shouldHighlight ? '#ffd66e' : 'transparent', fontSize: 12 }}
      >
        {/* + icon to add more  */}
        <img className="cursor-pointer px-1" src={addMoreLabels} alt="" />

        {/* first label */}
        <span
          className="flex items-center text-black rounded"
          style={{ backgroundColor: firstLabel.color, height: '50%', maxWidth: '50%' }} >
          <p className="px-1 truncate">{firstLabel.value}</p>
        </span>&nbsp;

        {/* more labels  */}
        {labels.length > 1 ? `& ${labels.length - 1} more` : ''}
      </div>
    )
  } else {
    return (
      <div
        id={`dataCell/${colKey}/${rowId}`}
        onClick={handleOpenEditor}
        className="sheetItem1 opacity-0 hover:opacity-100"
      >
        <img className="cursor-pointer" src={addNewLabel} alt="" />
      </div>
    )
  }

}

export default Label