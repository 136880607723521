import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';
import { openCarousel, loadStaticImages, loadSelectedRowInfo } from '../../../../../../slices/CarouselSlice';
import CustomProgressBar from '../../../../../../partials/CustomProgressBar'

import image_drag_n_drop from '../../../../../../images/add-edit row dialog/image_drag_n_drop.png'
import image_cross_icon from '../../../../../../images/add-edit row dialog/image_cross.png'
import imageIcon from '../../../../../../images/datatype_icons/light/image.png'

const Image = ({ colkey, column, handleChange, formData, edited, shouldAllowNewEntry }) => {

  // route variables
  const location = useLocation()

  // redux variables 
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  // state variables
  const [uploadedImagesArrExp, setUploadedImagesArrExp] = useState([])

  // local variables
  const index = `${colkey}_details`
  const imagesArray = formData[index]
  const { pathname } = location;
  const communityId = pathname.split('/')[2];
  const { isAdmin, hasBasicEditPermission, editPermissions } = registerData
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colkey) === -1

  // ref variables
  const imageInputRef = useRef(null)
  const drop = useRef(null)

  // "inputHasSomeFiles" needs to be checked as when "Cancel" is pressed as we need to
  // clear the input type='file' as it wont allow repeated file select.
  useEffect(() => {
    const inputHasSomeFiles = imageInputRef.current && imageInputRef.current.value.length > 0
    if (!edited && inputHasSomeFiles) {
      imageInputRef.current.value = ''
    }
  }, [edited])

  useEffect(() => {
    if (uploadedImagesArrExp && uploadedImagesArrExp.length === 0) {
      drop.current && drop.current.addEventListener('dragover', handleDragOver);
      drop.current && drop.current.addEventListener('drop', onImageChange);
      return () => {
        drop.current && drop.current.removeEventListener('dragover', handleDragOver);
        drop.current && drop.current.removeEventListener('drop', onImageChange);
      };
    }
  }, [imagesArray])

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // click on the image to open carousel
  const onPhotoClick = (e) => {
    e.preventDefault()
    dispatch(openCarousel(true))
    dispatch(loadSelectedRowInfo({ selectedRow: { row: formData }, selectedColKey: colkey }))
    dispatch(loadStaticImages({ staticImages: imagesArray }))
  }

  // array of uploaded images is reveived from CustomProgressBar through the below callback
  const progressCallback = (uploadedFiles) => {
    setUploadedImagesArrExp([])
    let newImagesArray = [...imagesArray]
    uploadedFiles.forEach((el) => {
      newImagesArray.push({ value: el.url })
    })
    handleChange({ colkeyDetail: `${colkey}_details`, valueDetail: newImagesArray })
  }

  // triggers when images are selected from file picker
  const onImageChange = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let { files } = e.dataTransfer || e.target
    setUploadedImagesArrExp(files)
  }

  // when image delete icon is pressed
  const onDeleteClick = (imgIndex) => {
    let newImagesArray = [...imagesArray]
    newImagesArray.splice(imgIndex, 1)
    handleChange({ colkeyDetail: `${colkey}_details`, valueDetail: newImagesArray })
  }

  if (formData && imagesArray) return (
    <div className="mb-6 h-full w-full">

      {/* hidden file selector starts  */}
      <input ref={imageInputRef} type="file" onChange={onImageChange} id={`actual-btn-${colkey}`} hidden multiple />
      {/* hidden file selector ends  */}

      {/* ColName and Photo count starts */}
      <div className="flex justify-between items-center">
        <div className='colName'>
          <img src={imageIcon} className="field_icon" />
          <label htmlFor={column.name}>{column.name}</label>
        </div>
        {
          imagesArray.length > 0 && <span style={{ color: '#878787' }}>{imagesArray.length} Photo{imagesArray.length > 1 ? 's' : ''}</span>
        }
      </div>
      {/* ColName and Photo count ends */}

      <div className="flex items-center gap-2 overflow-x-auto w-full">
        {
          imagesArray && imagesArray.length > 0 ?

            // images
            imagesArray.map((image, i) => {
              return (
                <div key={i} className="relative cursor-pointer my-2 ml-0 mr-4 group">
                  {
                    (canEdit || shouldAllowNewEntry) &&
                    <div onClick={() => onDeleteClick(i)} className='absolute text-white z-20 right-1 top-1 opacity-0 group-hover:opacity-100 transition-opacity delay-50' style={{ height: '1rem' }}>
                      <img src={image_cross_icon} className='h-4' />
                    </div>
                  }
                  <div onClick={onPhotoClick} className='flex' style={{ height: '7.5rem', width: '7.5rem' }}>
                    <img className="h-full w-full" style={{ borderRadius: '10.6328px' }} src={image.value} />
                    <div className='absolute pointer-events-none group-hover:bg-black opacity-20 z-10 transition-all ease-in delay-50' style={{ height: '7.5rem', width: '7.5rem', borderRadius: '10.6328px' }}></div>
                  </div>
                </div>
              )
            })
            // images
            :
            // Drag N Drop image
            (canEdit || shouldAllowNewEntry) ?
              <>
                <label htmlFor={`actual-btn-${colkey}`} className="block cursor-pointer w-full"><img ref={drop} src={image_drag_n_drop} /></label>
              </>
              :
              <div className="field truncate">No Images</div>
          // Drag N Drop image
        }

        {
          uploadedImagesArrExp.length > 0 &&
          <CustomProgressBar
            url={'otherservice/uploadImage'}
            files={uploadedImagesArrExp}
            communityId={communityId}
            getUploadedFilesClbk={progressCallback}
            progressDirection='vertical'
            outerContainerStyle={{ height: '4rem', width: '4rem' }}
            progressBarStyle={{ backgroundColor: '#C4C4C4' }}
          />
        }
      </div>

      {/* Add New Image button */}
      {
        (canEdit || shouldAllowNewEntry) && imagesArray.length > 0 &&
        <div className='flex justify-center pt-6'>
          <button
            onClick={(e) => { e.preventDefault(); imageInputRef.current.click() }}
            className='secondary_btn transition-all delay-50 px-4 py-1'
          >
            + Add Photos
          </button>
        </div>
      }
      {/* Add New Image button */}

    </div>
  )

  return <div></div>
}

export default Image