import { configureStore, combineReducers } from '@reduxjs/toolkit'
import leftSidebarReducer from '../slices/LeftSidebarSlice'
import registerReducer from '../slices/RegisterSlice'
import rowDetailReducer from '../slices/RowDetailSlice'
import dialogReducer from '../slices/DialogSlice'
import carouselReducer from '../slices/CarouselSlice'
import loginSlice from '../slices/LoginSlice'
import routerSlice from '../slices/RouterSlice'
import rightSidebarReducer from '../slices/RightSidebarSlice'
import registerTemplateSlice from '../slices/RegisterTemplateSlice'
import themeSlice from '../slices/ThemeSlice'
import toastSlice from '../slices/ToastSlice'
import dummyRowSlice from '../slices/DummyRowSlice'

const combinedReducer = combineReducers({
  loginInfo: loginSlice,
  leftSidebar: leftSidebarReducer,
  register: registerReducer,
  rowDetail: rowDetailReducer,
  dialog: dialogReducer,
  carousel: carouselReducer,
  rightSidebar: rightSidebarReducer,
  routerStore: routerSlice,
  registerTemplateStore: registerTemplateSlice,
  theme: themeSlice,
  toast: toastSlice,
  dummyRow: dummyRowSlice
})

const rootReducer = (state, action) => {
  if (action.type === 'login/logout') { // so that state is reset of te whole application
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }) // done to remove serialiable errors but this is temporary
})
