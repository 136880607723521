import moment from 'moment'

import textIcon from '../../../../images/datatype_icons/dark/text.png'
import timeIcon from '../../../../images/datatype_icons/dark/time.png'
import formulaIcon from '../../../../images/datatype_icons/dark/formula.png'
import numberIcon from '../../../../images/datatype_icons/dark/number.png'
import dateIcon from '../../../../images/datatype_icons/dark/date.png'
import phoneIcon from '../../../../images/datatype_icons/dark/phone.png'
import dropdownIcon from '../../../../images/datatype_icons/dark/dropdown.png'
import photoIcon from '../../../../images/datatype_icons/dark/image.png'
import switchIcon from '../../../../images/datatype_icons/dark/switch.png'
import mapIcon from '../../../../images/datatype_icons/dark/location.png'
import mailIcon from '../../../../images/datatype_icons/dark/mail.png'
import rupeeIcon from '../../../../images/datatype_icons/dark/rupee.png'
import urlIcon from '../../../../images/datatype_icons/dark/url.png'
import attachmentIcon from '../../../../images/datatype_icons/dark/attachment.png'
import signatureIcon from '../../../../images/datatype_icons/dark/signature.png'
import statusIcon from '../../../../images/datatype_icons/dark/status.png'
import unitIcon from '../../../../images/datatype_icons/dark/unit.png'
import createdByIcon from '../../../../images/datatype_icons/dark/created-by.png'
import createdAtIcon from '../../../../images/datatype_icons/dark/created-at.png'
import reminderIcon from '../../../../images/datatype_icons/dark/reminder.png'
import scannerIcon from '../../../../images/datatype_icons/dark/scanner.png'
import durationIcon from '../../../../images/datatype_icons/dark/duration.png'
import labelIcon from '../../../../images/datatype_icons/dark/label.png'
import memberIcon from '../../../../images/datatype_icons/dark/member.png'
import editIcon from '../../../../images/column-icons/header-menu/edit-column.png'
import insert1LeftIcon from '../../../../images/column-icons/header-menu/insert-1-left.png'
import insert1RightIcon from '../../../../images/column-icons/header-menu/insert-1-right.png'
import sortAtoZIcon from '../../../../images/column-icons/header-menu/sortAtoZ.png'
import sortAtoZSortedIcon from '../../../../images/column-icons/header-menu/sortAtoZSorted.png'
import sortZtoAIcon from '../../../../images/column-icons/header-menu/sortZtoA.png'
import sortZtoASortedIcon from '../../../../images/column-icons/header-menu/sortZtoASorted.png'
import freezeIcon from '../../../../images/column-icons/header-menu/freeze.png'
import deleteIcon from '../../../../images/column-icons/header-menu/delete.png'
import linkedSheetIcon from '../../../../images/column-icons/header-menu/linkedSheet.png'

export const DataTypes = [{ name: 'Text', icon: textIcon, dataType: "String" },
{ name: 'Date', icon: dateIcon, dataType: "date" },
{ name: 'Reminder', icon: reminderIcon, dataType: "reminder" },
{ name: 'Rupee', icon: rupeeIcon, dataType: "rupee" },
{ name: 'Photo', icon: photoIcon, dataType: "image" },
{ name: 'Phone Number', icon: phoneIcon, dataType: "phoneNumber" },
{ name: 'Number', icon: numberIcon, dataType: "number" },
{ name: 'Formula', icon: formulaIcon, dataType: "formula" },
{ name: 'Location', icon: mapIcon, dataType: "location" },
{ name: 'Drop Down', icon: dropdownIcon, dataType: "dropDown" },
{ name: 'Yes/No', icon: switchIcon, dataType: "switch" },
{ name: 'Time', icon: timeIcon, dataType: "time" },
{ name: 'Email', icon: mailIcon, dataType: "mail" },
{ name: 'Signature', icon: signatureIcon, dataType: "signature" },
{ name: 'Attachment', icon: attachmentIcon, dataType: "attachment" },
{ name: 'Website/URL', icon: urlIcon, dataType: "url" },
{ name: 'Status', icon: statusIcon, dataType: "status" },
{ name: 'Scanner', icon: scannerIcon, dataType: "scanner" },
{ name: 'CreatedBy', icon: createdByIcon, dataType: "createdBy" },
{ name: 'CreatedAt', icon: createdAtIcon, dataType: "createdAt" },
{ name: 'Unit', icon: unitIcon, dataType: "unit" },
{ name: 'Duration', icon: durationIcon, dataType: "duration" },
{ name: 'Link To Another Register', icon: linkedSheetIcon, dataType: "linkedSheet" },
{ name: 'Label', icon: labelIcon, dataType: "label" },]

export const getColumnIcon = (dataType) => {
  let icon;
  if (dataType === "date") {
    icon = dateIcon
  } else if (dataType === "String") {
    icon = textIcon
  } else if (dataType === "number") {
    icon = numberIcon
  } else if (dataType === "phoneNumber") {
    icon = phoneIcon
  } else if (dataType === "dropDown") {
    icon = dropdownIcon
  } else if (dataType === "formula") {
    icon = formulaIcon
  } else if (dataType === "location") {
    icon = mapIcon
  } else if (dataType === "time") {
    icon = timeIcon
  } else if (dataType === "mail") {
    icon = mailIcon
  } else if (dataType === "switch") {
    icon = switchIcon
  } else if (dataType === "image") {
    icon = photoIcon
  } else if (dataType === "rupee") {
    icon = rupeeIcon
  } else if (dataType === "url") {
    icon = urlIcon
  } else if (dataType === "attachment") {
    icon = attachmentIcon
  } else if (dataType === "signature") {
    icon = signatureIcon
  } else if (dataType === "status") {
    icon = statusIcon
  } else if (dataType === "unit") {
    icon = unitIcon
  } else if (dataType === "createdBy") {
    icon = createdByIcon
  } else if (dataType === "createdAt") {
    icon = createdAtIcon
  } else if (dataType === "reminder") {
    icon = reminderIcon
  } else if (dataType === "scanner") {
    icon = scannerIcon
  } else if (dataType === "duration") {
    icon = durationIcon
  } else if (dataType === "label") {
    icon = labelIcon
  } else if (dataType === "member") {
    icon = memberIcon
  } else if (dataType === "linkedSheet") {
    icon = linkedSheetIcon
  }
  return icon
}

export const ColMenuOptions = [{ name: 'Edit Column', icon: editIcon, index: 1 },
{ name: 'Insert 1 Left', icon: insert1LeftIcon, index: 2 },
{ name: 'Insert 1 Right', icon: insert1RightIcon, index: 3 },
{ name: ['Sort A>Z', 'Sort 1 to 9'], icon: sortAtoZIcon, iconSorted: sortAtoZSortedIcon, index: 4 },
{ name: ['Sort Z>A', 'Sort 9 to 1'], icon: sortZtoAIcon, iconSorted: sortZtoASortedIcon, index: 5 },
{ name: ['Freeze Column', 'Unfreeze Column'], icon: freezeIcon, index: 6 },
{ name: 'Delete Column', icon: deleteIcon, index: 7 },]

export const getDataTypeName = (dataType) => {
  let dataTypeName = '';
  switch (dataType) {
    case "String":
      dataTypeName = 'Text'
      break;
    case "date":
      dataTypeName = 'Date'
      break;
    case "rupee":
      dataTypeName = 'Rupee'
      break;
    case "reminder":
      dataTypeName = 'Reminder'
      break;
    case "image":
      dataTypeName = 'Photo'
      break;
    case "phoneNumber":
      dataTypeName = 'Phone Number'
      break;
    case "number":
      dataTypeName = 'Number'
      break;
    case "formula":
      dataTypeName = 'Formula'
      break;
    case "location":
      dataTypeName = 'Location'
      break;
    case "dropDown":
      dataTypeName = 'Drop Down'
      break;
    case "switch":
      dataTypeName = 'Yes/No'
      break;
    case "time":
      dataTypeName = 'Time'
      break;
    case "mail":
      dataTypeName = 'Email'
      break;
    case "signature":
      dataTypeName = 'Signature'
      break;
    case "attachment":
      dataTypeName = 'Attachment'
      break;
    case "url":
      dataTypeName = 'Website/URL'
      break;
    case "status":
      dataTypeName = 'Status'
      break;
    case "scanner":
      dataTypeName = 'Scanner'
      break;
    case "createdBy":
      dataTypeName = 'CreatedBy'
      break;
    case "createdAt":
      dataTypeName = 'CreatedAt'
      break;
    case "unit":
      dataTypeName = 'Unit'
      break;
    case "duration":
      dataTypeName = 'Duration'
      break;
    case "label":
      dataTypeName = 'Label'
      break;
      case "linkedSheet":
        dataTypeName = 'Link To Another Register'
        break;
    default:
      break;
  }
  return dataTypeName
}

export const getValidatedFormatterVal = (val) => {
  return val ? val : ''
}

export const getValidatedDate = (val) => {
  return val && val.split('-').length === 3 ? val : ''
}

export const getValidatedTime = (val) => {
  return val && val.includes(':') && (val.toLowerCase().includes('am') || val.toLowerCase().includes('pm')) ? val.trim() : ''
}

export const getValidatedNumber = (val) => {
  return val && Number(val) ? val : ''
}

export const getValidatedPhoneNumber = (val) => {
  return val && (/^[0-9+()]+$/).test(val) ? val : ''
}

export const getValidatedEmail = (val) => {
  return val ? val : ''
}

export const getValidatedUrlValue = (val) => {
  return val && val[0] && val[0].value ? val[0].value : ''
}

export const getValidatedReminderValue = (val) => {
  return moment(val).isValid() && !Number(parseInt(val)) ? val : ''
}

export const getEmptyRow = (columns) => {
  let emptyRow = {}
  columns.forEach(col => {
    emptyRow[col.key] = ""
    if (col.key !== 'addColPlusButton' && col.key !== '-1') {
      emptyRow[col.key + '_details'] = []
    }
  })
  return emptyRow
}

export const focusHighlightedCell = () => {
  const focusedEl = document.getElementsByClassName("rdg-focus-sink f7ly7s700-canary49")
  const isElementInScreen = document.querySelector('[aria-selected="true"]')
  if (isElementInScreen && focusedEl && focusedEl[0]) focusedEl[0].focus()
}

export const getEmptyRowIdx = (rows) => {
  let emptyRowIndex = null
  let reverseSearchRowIdx = rows.length - 2
  let isCurrentRowEmpty = true;

  while (reverseSearchRowIdx > -1 && isCurrentRowEmpty) {
    emptyRowIndex = reverseSearchRowIdx
    const rowEl = rows[reverseSearchRowIdx]
    Object.keys(rowEl).forEach(key => { // search all cells in the row
      if (rowEl[key] && key != -1 && key != 'rowId') { // ignore S.No and rowId
        if (rowEl[key].length > 0) {
          isCurrentRowEmpty = false
        }
      }
    })
    if (!isCurrentRowEmpty) {
      emptyRowIndex = parseInt(JSON.parse(JSON.stringify(reverseSearchRowIdx + 1)))
      reverseSearchRowIdx = -1
    } else {
      reverseSearchRowIdx--
    }
  }
  return emptyRowIndex
}
