import { useSelector } from 'react-redux'
import LastRowCellFormatter from './LastRowCellFormatter'

// utils

// icons
import addImageIcon from '../../../../../images/column-icons/plus.png'
import attachmentIcon from '../../../../../images/datatype_icons/dark/attachment.png'


const AttachmentFormatter = ({ rowProperties, openEditorAfterSelect, colIdx, columnEl }) => {
  // redux variables
  const registerData = useSelector(state => state.register)

  // local variables
  const colKey = columnEl.key
  const isCellSelected = rowProperties.isCellSelected
  const rowIdx = rowProperties.rowIdx
  const rowId = rowProperties.row.rowId
  const id = `dataCell/${colKey}/${rowId}`
  const filteredRows = registerData.filteredRows
  const isLastRow = filteredRows.length - 1 === rowIdx
  const atch = rowProperties.row[colKey + "_details"]
  const noOfAttchs = atch && atch.length

  const handleOpenEditor = () => {
    openEditorAfterSelect({ isCellSelected, rowIdx, colIdx: rowProperties.column.idx })
  }

  if (isLastRow) {
    return <LastRowCellFormatter
      rowProperties={rowProperties}
      openEditorAfterSelect={openEditorAfterSelect}
      colIdx={colIdx}
      colKey={colKey}
    />
  }

  if (atch && atch.length > 0) {
    return (
      <div
        id={id}
        onClick={handleOpenEditor}
        className="sheetItem1"
        style={{ fontSize: '0.8rem' }}>
        <img className="h-5" src={attachmentIcon} />
        {noOfAttchs} Attachments
      </div>
    )
  }
  else return (
    <div id={id} onClick={handleOpenEditor} style={{ color: "#586C9E" }} className="flex justify-center items-center px-1 transition-opacity delay-50 opacity-0 hover:opacity-100">
      <div className="flex items-center gap-2">
        <img src={addImageIcon} alt="" />
            Click to Upload
          </div>
    </div>
  )
};

export default AttachmentFormatter