

const CustomInputText = ({ containerStyle, labelStyle, inputTextStyle, onChange, value, autoFocus }) => {
	return (
		<div className="flex flex-col"
			style={{ ...containerStyle, width: '90%' }}
		>
			<span
				style={{ ...labelStyle, color: '#586C9E', fontSize: 10, position: 'relative', bottom: 3 }}
			>
				Register Name
								</span>
			<input
				value={value}
				style={{ ...inputTextStyle, border: '1px solid #586C9E', boxSizing: 'border-box', borderRadius: 5 }}
				type="text"
				onChange={onChange}
				autoFocus={autoFocus}
			/>
		</div>
	)
}

export default CustomInputText