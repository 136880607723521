// icons
import scannerIcon from '../../../../../../images/datatype_icons/light/scanner.png'

const Scanner = ({ colkey, column, handleChange, formData }) => {

  // local variables
  const scannerValue = formData[colkey]

  return(
    <div className="mb-6">
      <div className="colName">
        <img className="field_icon" style={{height: '1.5rem'}} src={scannerIcon} alt="" />
        <label htmlFor={column.name}>{column.name}</label>
      </div>
      <div className='field non_editable cursor-not-allowed'>{scannerValue}</div>
    </div>
  )
   
}

export default Scanner