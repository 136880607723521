import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// slices
import { createRegisterFromExcel } from '../../../../slices/LeftSidebarSlice'
import { toggleDialogOpen } from '../../../../slices/DialogSlice'

// components
import CustomDropdown from '../../../../partials/CustomDropdown'

// icons
import importExcelIcon from '../../../../images/importExcelIcon.png'

const CreateRegisterFromExcel = ({ sheetsList, files, closeAddRegisterPopUp }) => {
  // redux variables 
  const dispatch = useDispatch()
  const leftSidebarData = useSelector(state => state.leftSidebar)

  // state variables
  const [registerName, setRegisterName] = useState(sheetsList[0].excelFileName)
  const [includeTopRowAsColumn, setIncludeTopRowAsColumn] = useState(false)
  const [excelSheetName, setExcelSheetName] = useState('')

  // local variables
  const { activeBusinessId } = leftSidebarData

  const onDropdownSelect = (item) => {
    setRegisterName(`${item.excelFileName}(${item.name})`)
    setExcelSheetName(item.name)
  }

  const allowSheetCreate = () => {
    return excelSheetName.length > 0 && registerName.length > 0
  }

  const uploadExcel = () => {
    if (!allowSheetCreate()) return

    dispatch(createRegisterFromExcel({
      file: files[0],
      businessId: activeBusinessId,
      excelSheetName,
      registerName,
      includeTopRowAsColumn: includeTopRowAsColumn == true ? '1' : '0'
    }))
    // close self
    dispatch(toggleDialogOpen(false))

    // conditional because when no register exists then this pop is not open
    if (closeAddRegisterPopUp) closeAddRegisterPopUp()
  }

  const classes = {
    container: "flex flex-col px-5 xs:px-10 sm:px-20",
    registerNameContainer: "relative flex flex-col h-1/3 justify-center",
    registerNameInputText: "flex pl-12",
    registerNameSpan: "flex pb-1",
  }

  const styles = {
    container: { backgroundColor: '#EEF3F5', height: '55vh', width: '40rem', maxWidth: '95vw' },
    registerNameSpan: { fontSize: 12, color: '#586C9E', fontWeight: '400', fontFamily: 'Arial, Helvetica, sans-serif' },
    registerNameInputText: { border: '1px solid #586C9E', borderRadius: 5, color: '#454545', fontSize: 20 }
  }

  return (
    <div className={classes.container} style={styles.container}>
      {/* Register Name container starts */}
      <div className="w-full h-full relative flex flex-col justify-center">
        <span
          className={classes.registerNameSpan}
          style={styles.registerNameSpan}>
          Register Name
        </span>
        <input
          className={classes.registerNameInputText}
          type="text"
          value={registerName}
          style={styles.registerNameInputText}
          onChange={(e) => { setRegisterName(e.target.value) }}
          autoFocus
        />
        <img className="flex w-6 h-6 absolute" style={{ top: '50%', left: 10 }} src={importExcelIcon} />
      </div>
      {/* Register Name container ends */}
      <div className="w-full h-full flex relative" >
        <CustomDropdown
          label={'Copy Data ( from sheet )'}
          labelStyle={{ top: 0 }}
          items={sheetsList}
          displayKey={'name'}
          onSelect={onDropdownSelect}
          menuContainerStyle={{ top: '75%', height: '8rem' }}
          dropdownContainerStyle={{ height: '50%' }}
          noneText="Select Sheet"
        />
      </div>
      <div className="w-full flex">
        <div onClick={() => setIncludeTopRowAsColumn(!includeTopRowAsColumn)} className="flex items-center cursor-pointer">
          <input checked={includeTopRowAsColumn} type="checkbox" className="mr-1 cursor-pointer" style={{ width: '18px', height: '18px', border: '1px solid #35A600', borderRadius: 3 }} readOnly />
          <span style={{ color: '#454545', fontSize: 18 }}>Use First Row as Header</span>
        </div>
      </div>
      <div className="w-full h-full flex items-center">
        <div tabIndex="0" onClick={uploadExcel} className={`flex h-2/5 w-full justify-center items-center ${allowSheetCreate() ? 'cursor-pointer' : 'cursor-not-allowed'}`} style={{ borderRadius: 5, backgroundColor: allowSheetCreate() ? '#2AB200' : '#7b9d7b' }}>
          <span className="text-white" style={{ fontWeight: '400', fontSize: 20 }}>UPLOAD</span>
        </div>
      </div>
    </div>
  )
}

export default CreateRegisterFromExcel