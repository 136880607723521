import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { getUpdatedRegisters } from '../../utils/Utils'
import { renameRegister } from '../RightSidebarSlice'

export const fetchAllBusiness = createAsyncThunk('business/allBusiness', async () => {
  const response = await axios.get('/v3/business/allBusiness')
  return response.data.data
})

export const fetchHomePageData = createAsyncThunk('business/homePage', async ({ businessId }) => {
  const response = await axios.get('/v3/business/homePage', { params: { businessId } })
  if (response.data.success) return response.data.data
  else return { error: response.data }
})

export const fetchFolderContent = createAsyncThunk('business/folderContent', async ({ businessId, folderId }) => {
  const response = await axios.get('/v3/business/folderContent', { params: { businessId, folderId } })
  return { data: response.data.data, folderId }
})

export const fetchFolderContentCopy = createAsyncThunk('business/folderContent/copy', async ({ businessId, folderId }) => {
  const response = await axios.get('/v3/business/folderContent', { params: { businessId, folderId } })
  return { data: response.data.data, folderId }
})

export const createRegister = createAsyncThunk('createRegister', async ({ registerName, businessId, templateId }) => {
  const response = await axios.post('/v3/register/create', { registerName, businessId, templateId })
  if (response.data.success) {
    return { data: response.data.data }
  }
  return { data: response.data.data }
})

export const createRegisterFromExcel = createAsyncThunk('createRegister', async ({ file, businessId, excelSheetName, registerName, includeTopRowAsColumn }) => {
  let formaData = new FormData();
  formaData.append('file', file)
  formaData.append('businessId', businessId)
  formaData.append('excelSheetName', excelSheetName)
  formaData.append('registerName', registerName)
  formaData.append('includeTopRowAsColumn', includeTopRowAsColumn)
  const response = await axios.post('/v3/upload/excelAsRegister', formaData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  if (response.data.success) {
    return { data: response.data.data }
  }
  return { data: response.data.data }
})

export const leftSidebarSlice = createSlice({
  name: 'leftSidebar',
  initialState: {
    isLoading: false,
    homePageDataFetched: false,
    showFullScreenLoader: false,
    businessList: [],
    registers: [],
    activeBusinessId: null,
    activeBusinessName: '',
    editProfileImage: '',
    notificationCount: 0,
    newRegisterInfo: null,
    leftSidebarOpen: true,
    error: null
  },
  reducers: {
    toggleExpandableFolder: (state, action) => {
      state.registers[action.payload.index].isExpanded = !state.registers[action.payload.index].isExpanded
    },
    removeRegister: (state, action) => {
      const deleteIdx = state.registers.findIndex(el => el.registerId === action.payload.registerId)
      state.registers.splice(deleteIdx, 1)
    },
    updateSidebarState: (state, action) => {
      action.payload.forEach((el) => {
        const key = el.key
        const value = el.value
        state[key] = value
      })
    },
    toggleLeftSidebarOpen: (state, action) => {
      state.leftSidebarOpen = action.payload
    },
    updateLeftSidebarState: (state, action) => {
      action.payload.forEach((el) => {
        const key = el.key
        const value = el.value
        state[key] = value
      })
    },
    toggleFullScreenLoader: (state,action) => {
      state.showFullScreenLoader = action.payload
    }
  },
  extraReducers: {
    [renameRegister.fulfilled]: (state, action) => {
      const { name, oldName, registers } = action.payload
      state.registers = getUpdatedRegisters({ name, oldName, registers })
    },
    [createRegister.pending]: (state, action) => {
      state.showFullScreenLoader = true
    },
    [createRegister.fulfilled]: (state, action) => {
      state.showFullScreenLoader = false
      state.newRegisterInfo = action.payload.data
    },
    [createRegister.rejected]: (state, action) => {
      state.showFullScreenLoader = false
    },
    [createRegisterFromExcel.pending]: (state, action) => {
      state.showFullScreenLoader = true
    },
    [createRegisterFromExcel.fulfilled]: (state, action) => {
      state.showFullScreenLoader = false
      state.newRegisterInfo = action.payload.data
    },
    [createRegisterFromExcel.rejected]: (state, action) => {
      state.showFullScreenLoader = false
    },
    [fetchHomePageData.pending]: (state, action) => {
      state.showFullScreenLoader = true
      state.isLoading = true
    },
    [fetchHomePageData.fulfilled]: (state, action) => {
      state.showFullScreenLoader = false
      state.isLoading = false
      state.homePageDataFetched = true
      if (!action.payload.error) {
        state.registers = addIsExpandedproperty(action.payload.registers)
        state.activeBusinessId = action.payload.businessId
        localStorage.setItem('lastUsedBusinessId', action.payload.businessId)
        state.activeBusinessName = action.payload.businessName
        state.notificationCount = action.payload.notificationCount
      } else {
        state.error = action.payload.error
      }
    },
    [fetchHomePageData.rejected]: (state, action) => {
      state.showFullScreenLoader = false
      state.isLoading = false
    },
    [fetchAllBusiness.pending]: (state, action) => {
      state.showFullScreenLoader = true
      state.isLoading = true
    },
    [fetchAllBusiness.fulfilled]: (state, action) => {
      state.showFullScreenLoader = false
      state.isLoading = false
      state.businessList = action.payload.businessList
    },
    [fetchAllBusiness.rejected]: (state, action) => {
      state.showFullScreenLoader = false
      state.isLoading = false
    },
    [fetchFolderContent.pending]: (state, action) => {
      state.showFullScreenLoader = true
      state.isLoading = true
    },
    [fetchFolderContent.fulfilled]: (state, action) => {
      state.showFullScreenLoader = false
      const registers = action.payload.data.registers
      const folderId = action.payload.folderId
      state.isLoading = false
      const idx = state.registers.findIndex(el => el.folderId === folderId)
      state.registers[idx].registers = registers
    },
    [fetchFolderContent.rejected]: (state, action) => {
      state.showFullScreenLoader = false
      state.isLoading = false
    }
  }
})

const addIsExpandedproperty = (registers) => {
  registers.forEach((el) => {
    if (el.type === "FOLDER") {
      el.registers = []
      el.isExpanded = false
    }

  })
  return registers
}

export const { toggleExpandableFolder, removeRegister, updateSidebarState, toggleLeftSidebarOpen, updateLeftSidebarState, toggleFullScreenLoader } = leftSidebarSlice.actions

export default leftSidebarSlice.reducer

