import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import './style.css'
import unitIcon from '../../../../../../images/datatype_icons/light/unit.png'

const Unit = ({ colkey, column, handleChange, formData, shouldAllowNewEntry }) => {

  // redux variables
  const registerData = useSelector(state => state.register)

  // local variables
  const unit = column.multiOptions[0] ? column.multiOptions[0].value : ''
  const { isAdmin, hasBasicEditPermission, editPermissions } = registerData
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colkey) === -1

  // useEffect(() => {
  //   setWidth()
  // }, [formData[colkey]])

  const setWidth = () => {
    const a = document.getElementById(`add-form-unit-input-${colkey}`)
    if (a && a.value) {
      const length = a.value.length
      const width = (length + 10) * 10 + 'px'
      a.style.width = width
    }
    if (a && !a.value) {
      a.style.width = '100%'
    }
  }

  const handleClick = () => {
    const a = document.getElementById(`add-form-unit-input-${colkey}`)
    a.select()
  }

  return (
    <div className="mb-6">
      <div className="colName">
        <img src={unitIcon} className="field_icon" />
        <label htmlFor={column.name}>{column.name}</label>
      </div>

      {
        !canEdit && !shouldAllowNewEntry &&
        <div className="field truncate">{formData[colkey]} {unit}</div>
      }

      {
        (shouldAllowNewEntry || canEdit) &&
        <div className="relative flex items-center" onClick={(e) => { e.preventDefault(); handleClick() }}>
          <input
            // onKeyPress={setWidth}
            className="unitInput field pr-6 focus:ring-transparent"
            name={colkey}
            id={`add-form-unit-input-${colkey}`}
            type="number"
            value={formData[colkey]}
            onChange={(e) => handleChange({ colkey, value: e.target.value })}
            style={{ maxWidth: '100%' }}
          />
          <div className='absolute right-2'>{formData[colkey] && unit}</div>
        </div>
      }
    </div>
  )

}

export default Unit