import { useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect } from "react-router-dom"

// slices
import { getOtp, verifyOtp, updateLoginInfoState } from "../../../../slices/LoginSlice"

//libraries
import PhoneInput from 'react-phone-number-input'
import {
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

// components
import HeaderTexts from "../HeaderTexts"
import Divider from "../Divider"
import RedirectToLogin from "../RedirectToLogin"
import ContactSupport from "../ContactSupport"
import ProgressBar from "../ProgressBar"
import Terms from '../Terms'

// icons
import googleIcon from '../../../../images/login/google.png'
import emailIcon from '../../../../images/login/email.png'
import dropdownIcon from '../../../../images/login/dropdownGray.png'

const SignUpUsingPhoneNumber = ({ handleSignUpMethodChange, SIGN_UP_METHODS }) => {

  const { executeRecaptcha } = useGoogleReCaptcha();

  //redux variables
  const dispatch = useDispatch()
  const loginInfo = useSelector(state => state.loginInfo)

  // state variables
  const [phoneNumber, setPhoneNumber] = useState('')
  const [value, setValue] = useState('+91')
  const [countryCode, setCountryCode] = useState('')
  const [number, setNumber] = useState('')
  const [otpInput1, setOtpInput1] = useState('')
  const [otpInput2, setOtpInput2] = useState('')
  const [otpInput3, setOtpInput3] = useState('')
  const [otpInput4, setOtpInput4] = useState('')
  const [resendTimer, setResendTimer] = useState(0)
  const [redirectToLogin, setRedirectToLogin] = useState(false)
  const [step, setStep] = useState(2)
  const [otpSentMsg, setOtpSentMsg] = useState(null)

  // ref variables
  const otpInputRef = useRef(null)

  //local variables
  const { otpSent } = loginInfo
  let PhInputInput
  let phoneInputArrow
  let inputSelectDropdown = document.querySelector('.PhoneInputCountrySelect')
  let inputSelectDropdownContainer = document.querySelector('.PhoneInput')
  const isDisabled = loginInfo.otpSent ? !otpInput1 || !otpInput2 || !otpInput3 || !otpInput4 : !number

  useEffect(() => {
    if (otpSent) {
      otpInputRef.current.focus()
      setResendTimer(60);
      const timer = setInterval(() => {
        setResendTimer(prevTimer => {
          if (prevTimer > 0) return prevTimer - 1
          else {
            clearInterval(timer)
            return prevTimer
          }
        }
        )
      }, 1000)
      return () => clearInterval(timer);
    }
  }, [otpSent])

  useEffect(() => {
    let n = parseInt(`${countryCode}${number}`, 10)
    if (number) {
      setPhoneNumber(n.toString())
    }
  }, [countryCode, number])

  useEffect(() => {
    PhInputInput = document.querySelector('.PhoneInputInput')
    phoneInputArrow = document.querySelector('.PhoneInputCountrySelectArrow')
    if (PhInputInput) {
      PhInputInput.style.display = 'none'
      setCountryCode(PhInputInput.value)
    }
    if (phoneInputArrow) {
      phoneInputArrow.style.display = 'none'
    }
    if (inputSelectDropdownContainer) {
      inputSelectDropdownContainer.style.height = '100%'
    }
    if (inputSelectDropdown) {
      inputSelectDropdown.style.width = '6rem'
      inputSelectDropdown.style.height = '100%'
    }
  })

  // Resend OTP function
  const resendOtp = async () => {
    if (resendTimer === 0) {
      setOtpInput1('')
      setOtpInput2('')
      setOtpInput3('')
      setOtpInput4('')
      const token = await executeRecaptcha();
      dispatch(getOtp({ phoneNumber, token }))
      setOtpSentMsg(true)
      setTimeout(() => {
        setOtpSentMsg(false)
      }, 4000)
      setResendTimer(60)
    }
  }

  const handleReCaptchaVerify = async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }
    const token = await executeRecaptcha();
    getOtpDispatch(token)
    setStep(3)
  }

  const getOtpDispatch = (token) => {
    if (number.length === 0) {
      dispatch(updateLoginInfoState([
        {
          key: 'error',
          value: true
        },
        {
          key: 'errorMessage',
          value: 'Please enter phone number'
        }
      ]))
      return
    }
    if (value == '+91' && number.length != 10) {
      dispatch(updateLoginInfoState([
        {
          key: 'error',
          value: true
        },
        {
          key: 'errorMessage',
          value: 'Please enter a valid Phone Number'
        }
      ]))
      return
    }
    dispatch(getOtp({ phoneNumber, token }))
  }

  const verifyOtpDispatch = () => {
    let otp = otpInput1 + otpInput2 + otpInput3 + otpInput4
    if (isNaN(parseInt(otpInput1)) || isNaN(parseInt(otpInput2)) || isNaN(parseInt(otpInput3)) || isNaN(parseInt(otpInput4))) {
      dispatch(updateLoginInfoState([
        {
          key: 'error',
          value: true
        },
        {
          key: 'errorMessage',
          value: 'The OTP entered is invalid'
        }
      ]))
    } else if (otp.length) {
      dispatch(verifyOtp({ phoneNumber, otp }))
    }
  }

  const handleInputKeyUp = (e) => {
    if (e.key === 'Enter' && !isDisabled) {
      loginInfo.otpSent ? verifyOtpDispatch() : handleReCaptchaVerify()
    }
  }

  const onPhNoInputChange = (e) => {
    setNumber(e.target.value);
    removeError()
  }

  const handleOtpInputKeyUp = (e, current, next, previous) => {
    if (e.key === 'Enter') {
      loginInfo.otpSent ? verifyOtpDispatch() : handleReCaptchaVerify()
    }
    if (e.key === 'Backspace' && previous.length > 0) {
      document.getElementById(previous).focus()
    }

    let currentOtpInput = document.getElementById(current)
    if (currentOtpInput.value && next.length > 0) {
      document.getElementById(next).focus()
    }
  }

  const modifyPhNoIconClick = () => {
    setStep(2)
    dispatch(updateLoginInfoState([
      {
        key: 'otpSent',
        value: false
      },
      {
        key: 'error',
        value: false
      },
      {
        key: 'errorMessage',
        value: null
      }
    ]))
    setOtpInput1('')
    setOtpInput2('')
    setOtpInput3('')
    setOtpInput4('')
  }

  const removeError = () => {
    if (loginInfo.error) {
      dispatch(updateLoginInfoState([{ key: 'error', value: false }, { key: 'errorMessage', value: null }]))
    }
  }

  // classes
  const buttonClass = `child-in-center mx-auto rounded-2xl max-w-full gap-2 my-3 font-medium`
  const inputSection = `flex items-center flex-col relative`
  const inputContainer = `my-3 max-w-full flex items-start rounded-2xl border`
  const phoneInputDropdown = `pl-4 flex justify-center items-center rounded-l-2xl`
  const countryCodeClass = `pl-2 flex items-center border-r`
  const inputPhNo = `phNoInput text-lg h-full pl-4 w-full border-none`
  const otpInputClass = `rounded-2xl border text-center text-2xl text-black font-medium`

  // styles
  const otpInput = { height: 56, width: 56, backgroundColor: '#F8F9FA', borderColor: '#ACB5BD' }
  const buttonInputCommonStyles = { width: '26rem', height: '4.5rem', fontSize: '1.125rem' }

  if (redirectToLogin) {
    return <Redirect to='/Login' />
  }

  return (
    <div className="h-full">

      <div className="" style={{ height: '10%' }}>
        <ProgressBar step={step} />
      </div>

      <div className={`flex flex-col ${loginInfo.otpSent ? 'justify-center' : 'justify-around'} `} style={{ height: '80%' }}>

        {
          loginInfo.otpSent ?
            <div className="font-medium mb-16" style={{ color: '#6C757D' }}>
              <div>Please enter the OTP received on your mobile number</div>
              <div>{countryCode} {number} <span onClick={modifyPhNoIconClick} className='cursor-pointer underline' style={{ color: '#0B98FF', textUnderlineOffset: '4px' }}>(change)</span></div>
            </div> :
            <div className="relative bottom-4">
              <HeaderTexts />
            </div>
        }

        <div className={inputSection}>
          {
            loginInfo.otpSent ?
              <>
                {/* otp input */}
                <div className="flex gap-3 mb-4">
                  <input ref={otpInputRef} id="firstOtp" value={otpInput1} onChange={(e) => { removeError(); setOtpInput1(e.target.value) }} className={otpInputClass} style={otpInput} maxLength={1} onKeyUp={(e) => handleOtpInputKeyUp(e, 'firstOtp', 'secondOtp', '')} />
                  <input id="secondOtp" value={otpInput2} onChange={(e) => { removeError(); setOtpInput2(e.target.value) }} className={otpInputClass} style={otpInput} maxLength={1} onKeyUp={(e) => handleOtpInputKeyUp(e, 'secondOtp', 'thirdOtp', 'firstOtp')} />
                  <input id="thirdOtp" value={otpInput3} onChange={(e) => { removeError(); setOtpInput3(e.target.value) }} className={otpInputClass} style={otpInput} maxLength={1} onKeyUp={(e) => handleOtpInputKeyUp(e, 'thirdOtp', 'fourthOtp', 'secondOtp')} />
                  <input id="fourthOtp" value={otpInput4} onChange={(e) => { removeError(); setOtpInput4(e.target.value) }} className={otpInputClass} style={otpInput} maxLength={1} onKeyUp={(e) => handleOtpInputKeyUp(e, 'fourthOtp', '', 'thirdOtp')} />
                </div>
                {/* otp input */}
                <div className="mt-4 mb-10 font-medium" style={{ color: '#6C757D' }}>
                  <span
                    onClick={resendOtp}
                    className={`${resendTimer == 0 ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                    style={{ color: resendTimer == '0' ? '#0B98FF' : '#ADB5BD' }}
                  >
                    Resend OTP&nbsp;
                  </span>
                  {
                    resendTimer != 0 && <span>in {resendTimer}s</span>
                  }
                </div>
                {
                  otpSentMsg && <div className="font-medium" style={{ fontSize: '15px', color: '#35A600' }}>A new OTP has been sent.</div>
                }
              </>
              :
              // phone number input
              <>
                <div className={inputContainer} style={{ border: '1px solid #ACB5BD', ...buttonInputCommonStyles }}>
                  <div className={phoneInputDropdown} style={{ width: '2.5rem', height: '4.5rem' }}>
                    <PhoneInput
                      international
                      defaultCountry="IN"
                      placeholder="Enter phone number"
                      value={value}
                      onChange={setValue}
                    />
                  </div>
                  <div className={countryCodeClass} style={{ height: '4.5rem', minWidth: '4.5rem', borderColor: '#ACB5BD', color: '#6C757D' }}>
                    {countryCode}
                    <img src={dropdownIcon} className="w-4 ml-1" />
                  </div>
                  <input
                    value={number}
                    placeholder="Phone number"
                    onKeyUp={handleInputKeyUp}
                    onChange={onPhNoInputChange}
                    type="number"
                    className={inputPhNo}
                    style={{ backgroundColor: '#F8F9FA', color: '#22252A', borderTopRightRadius: '1rem', borderBottomRightRadius: '1rem' }}
                  />
                </div>
              </>
            // phone number input
          }

          {/* error msg */}
          {loginInfo.error && <p className="my-3 font-medium pl-3" style={{ color: '#EE0F0F', fontSize: '15px' }}><span> {loginInfo.errorMessage} </span></p>}
          {/* error msg */}

          <button
            onClick={loginInfo.otpSent ? verifyOtpDispatch : handleReCaptchaVerify}
            className={`${buttonClass} text-white ${isDisabled ? 'cursor-not-allowed' : ''}`}
            style={{ backgroundColor: isDisabled ? '#DDDDDD' : '#35A600', ...buttonInputCommonStyles }}
            type="submit"
          >
            {
              loginInfo.isLoading ? (
                <div className="animate-spin rounded-full h-7 w-7 border-b-2 border-gray-100 m-auto "></div>
              ) : (
                <span className="text-lg font-semibold">{loginInfo.otpSent ? 'Verify' : 'Get OTP'}</span>
              )
            }
          </button>

          {
            !loginInfo.otpSent && number && <div className="py-2"><Terms /></div>
          }
        </div>

        {
          loginInfo.otpSent ?
            <></> :
            <>
              <div className="my-2"><Divider /></div>
              <div>
                {/* <button className={buttonClass} style={{ border: '1px solid #ACB5BD', }}>
                  <img src={googleIcon} className='h-6' loading='lazy' />
                  <span className='font-semibold'>Sign Up with Google</span>
                </button> */}
                <button onClick={() => { removeError(); handleSignUpMethodChange(SIGN_UP_METHODS.EMAIL) }} className={buttonClass} style={{ border: '1px solid #ACB5BD', ...buttonInputCommonStyles }}>
                  <img src={emailIcon} className='h-6' loading='lazy' />
                  <span className='font-semibold'>Sign up with Email</span>
                </button>
              </div>
            </>

        }
      </div>

      <div className="flex items-end justify-center" style={{ height: '10%' }}>
        {
          loginInfo.otpSent ?
            <ContactSupport /> :
            <RedirectToLogin setRedirectToLogin={setRedirectToLogin} />
        }
      </div>

    </div >
  )
}

export default SignUpUsingPhoneNumber