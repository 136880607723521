import React, { useEffect, useRef, useState } from 'react';
import ReactDOM, { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux'

import { updateBody, updateHeaderText, toggleDialogOpen } from '../../../../../../slices/DialogSlice'
import MapDialog from './MapDialog'

// utils
import ErrorBoundary from '../../../../../../utils/ErrorBoundary'

//images
import editIcon from '../../../../../../images/add-edit row dialog/form icons/edit.png'
import directionIcon from '../../../../../../images/add-edit row dialog/form icons/direction.png'



const LocationEditor = ({ rowProperties, saveCurrentRowData, colIdx, columnEl }) => {

  // redux variables
  const registerData = useSelector(state => state.register)
  const dispatch = useDispatch()

  // local variables
  const colKey = columnEl.key
  const onClose = rowProperties.onClose
  const rowId = rowProperties.row.rowId
  const location = rowProperties.row[colKey] && rowProperties.row[colKey + '_details'][0] ? (rowProperties.row[colKey + '_details'][0].value ? rowProperties.row[colKey + '_details'][0].value : '') : ''
  const geoLocationObject = rowProperties.row[`${colKey}_details`] ? (rowProperties.row[`${colKey}_details`][0] && rowProperties.row[`${colKey}_details`][0].geoLocation ? rowProperties.row[`${colKey}_details`][0].geoLocation : { lat: '', lng: '' }) : { lat: '', lng: '' }
  const columnName = registerData.columns[colIdx] ? registerData.columns[colIdx].name : ''
  const { isAdmin, hasBasicEditPermission, editPermissions } = registerData
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colKey) === -1

  // Empty row work for add permission
  const hasAddPermission = registerData.permissions.includes('add')
  const row = rowProperties.row
  const isRowEmpty = !Object.keys(row).some((key) => {
    if (key != 'rowId' && key != -1) {
      return row[key].length > 0
    }
  })
  const canAdd = hasAddPermission && isRowEmpty
  // Empty row work for add permission

  // state variables
  const [pos, setPos] = useState({})
  const [menuOpen, setMenuOpen] = useState(false)

  // ref variables
  const menu = useRef(null)
  const menuTriggerBtnRef = useRef(null)

  useEffect(() => {
    // as soon as the editor is rendered dropdown should open
    if (location) {
      setMenuOpen(true);
    } else if (canEdit || canAdd) {
      dispatch(toggleDialogOpen(true))
      dispatch(updateHeaderText(columnName))
      dispatch(updateBody(<MapDialog saveLocationData={saveLocationData} />))
    }
    // Sets position of the dropdown in portal w.r.t the drop down trigger button
    // using its ref.
    //onScroll()
    // listeneing to table scroll represented by .rdg-light class
    document.querySelector(".rdg-light").addEventListener("scroll", onScroll)
    //document.addEventListener('keydown', keyHandler);
    return () => {
      document.removeEventListener("scroll", onScroll)
      //document.removeEventListener('keydown', keyHandler);
    }
  }, []);

  useEffect(() => {
    if (menuOpen) onScroll()
  }, [menuOpen])

  const handleClick = (e) => {
    e.preventDefault()
    if (location) {
      setMenuOpen(!menuOpen)
      if (menuOpen === true) onClose(true)
    }
  }

  const onScroll = () => {
    if (menu.current) {
      let totalMenuHeight = menu.current.getBoundingClientRect().height
      let positionTop = 0
      if (menuTriggerBtnRef.current.getBoundingClientRect().bottom + totalMenuHeight >= window.innerHeight) {
        positionTop = positionTop - totalMenuHeight
      } else {
        positionTop = 40
      }
      const refValue = ReactDOM.findDOMNode(menuTriggerBtnRef.current)
      if (refValue) {
        setPos({
          right: refValue
            .getBoundingClientRect().right,
          left: refValue
            .getBoundingClientRect().left,
          top: refValue
            .getBoundingClientRect().top + positionTop,
          bottom: refValue
            .getBoundingClientRect().bottom,
        })
      }
    }
  }

  const saveLocationData = ({ clearEntry, latLng, address }) => {
    if (clearEntry) {
      const additionalKeys = { [`${colKey}_details`]: [] }
      saveCurrentRowData('', colKey, rowId, registerData.rows, false, false, additionalKeys)
    } else {
      const additionalKeys = { [`${colKey}_details`]: [{ value: address, geoLocation: latLng }] }
      saveCurrentRowData(address, colKey, rowId, registerData.rows, false, false, additionalKeys)
    }
  }

  const onEditOptionClick = () => {
    const lat = parseFloat(geoLocationObject.lat)
    const lng = parseFloat(geoLocationObject.lng)
    const center = { lat, lng }
    dispatch(toggleDialogOpen(true))
    dispatch(updateHeaderText(columnName))
    dispatch(updateBody(<MapDialog location={location} center={center} saveLocationData={saveLocationData} />))
  }

  const handleContainerDivKeyDown = () => {

  }

  return (
    <ErrorBoundary>
      <div className="w-full h-full" tabIndex="-1" id="select-one-editor-container-div" onKeyDown={handleContainerDivKeyDown}>

        {location &&
          <div
            ref={menuTriggerBtnRef}
            tabIndex="0"
            onClick={handleClick}
            className="sheetItem2"
          >
            <span className="" style={{ minWidth: '3rem', minHeight: '1.5rem' }}>{location}</span>
          </div>
        }


        {/*Portal starts here*/}
        {menuOpen && createPortal(
          <div ref={menu} id="location-dropdown-portal-container" tabIndex="-1" className="shadow-lg bg-white border border-gray-400" style={{ top: pos.top, right: pos.right, left: pos.left, fontSize: '12px', position: 'fixed', width: menuTriggerBtnRef.current.clientWidth, minWidth: '12rem' }} >

            <ul>
              <li className="transition-colors delay-50 cursor-pointer hover:bg-gray-200">
                <a
                  href={`https://maps.google.com/?q=${geoLocationObject.lat},${geoLocationObject.lng}`}
                  target="_blank"
                  className="flex items-center gap-3 pl-3 p-2 "
                >
                  <img src={directionIcon} className="h-5" alt="" />
                  Get Direction
                </a>
              </li>
              {
                (canEdit || canAdd) &&
                <li
                  className="flex items-center gap-3 pl-3 p-2 transition-colors delay-50 cursor-pointer hover:bg-gray-200"
                  onClick={onEditOptionClick}
                >
                  <img src={editIcon} className="h-5" alt="" />
                  Edit Location
                </li>
              }
            </ul>

            {
              location !== '' && (canEdit || canAdd) &&
              <a tabIndex="0" id={`location-remove-entry-anchor`} key="location-remove-entry-anchor" className="flex items-center cursor-pointer border-t border-gray-300" onClick={(e) => { e.preventDefault(); saveLocationData({ clearEntry: true }); setMenuOpen(false) }}>
                <button tabIndex={-1} className="bg-white font-semibold w-full flex justify-between p-2 pl-5 items-center transition-colors delay-50 ease-out hover:bg-gray-200" style={{ color: '#FC1055' }} onClick={() => { }}>
                  Remove Entry
                </button>
              </a>
            }

          </div>,
          document.body)}
        {/*Portal ends here*/}

      </div>
    </ErrorBoundary>
  )
}

export default LocationEditor