import AddHistoryItem from './AddHistoryItem'
import EditHistoryItem from './EditHistoryItem'
import DeleteHistoryItem from './DeleteHistoryItem'

// slices
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getHistory, updateRowDetailState } from '../../../../../../../slices/RowDetailSlice'
import { useState, useRef } from 'react';

//images
import noHistory from '../../../../../../../images/noComments.png'

const HistorySection = () => {

  // redux variables
  const dispatch = useDispatch();
  const rowDetailData = useSelector(state => state.rowDetail)
  const registerData = useSelector(state => state.register)

  // ref variables
  const history_list_container_div = useRef(null)

  // local variables
  const { sheetId, rowIndex, rows } = registerData
  const { history } = rowDetailData
  const rowId = rowIndex > -1 ? rows[rowIndex].rowId : null

  useEffect(() => {
    if (rowId) dispatch(getHistory({ rowId }))
    return () => {
      dispatch(updateRowDetailState([{ key: "history", value: [] }]))
    }
  }, [])

  useEffect(() => {
    if (history_list_container_div.current) {
      let scrollHeight = history_list_container_div.current.scrollHeight
      history_list_container_div.current.scrollTo({ top: scrollHeight })
    }
  }, [history])

  const getHistoryElement = (el, index) => {
    let editType = el.editType
    let historyElement
    switch (editType) {
      case 'add':
        historyElement = <AddHistoryItem item={el} />
        break
      case 'edit':
        historyElement = <EditHistoryItem item={el} />
        break
      case 'delete':
        historyElement = <DeleteHistoryItem item={el} />
        break
    }
    return historyElement
  }

  return (
    <>
      <div ref={history_list_container_div} className="overflow-y-scroll pb-2 h-full overflow-x-hidden">
        {
          history.length === 0 ?
            <div className="child-in-center h-full">
              <img style={{ width: "8rem", height: "8rem" }} src={noHistory} alt="no comments" loading='lazy' />
            </div>
            :
            <ul>
              {
                history.map((el, i) =>
                  <li key={`history/${el.time}/${el.value}/${el.dataType}/${i}`}>
                    {
                      getHistoryElement(el, i)
                    }
                  </li>
                )
              }
            </ul>

        }
      </div>
    </>
  )
}

export default HistorySection