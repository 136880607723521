import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// components
import ContactSupport from '../ContactSupport'
import BackButton from '../BackButton'

// slices
import { sendEmailToResetPassword, updateLoginInfoState } from '../../../../slices/LoginSlice'

// icons
import logo from '../../../../images/logo.png'
import happyFace from '../../../../images/login/happy-face.png'

const ForgotPasswordSection = ({ email, setEmail, setShowForgotPasswordSection }) => {

  // redux variales
  const dispatch = useDispatch()
  const loginInfo = useSelector(state => state.loginInfo)

  // local variables
  const { isLoading, error, errorMessage, emailSentToUser } = loginInfo
  const isDisabled = !email

  const handleEmailChange = (e) => {
    removeError()
    setEmail(e.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const result = await dispatch(sendEmailToResetPassword({ emailId: email }))
    if (result.payload.success) setShowForgotPasswordSection(false)
  }

  const onLogoClick = () => {
    window.location.reload()
  }

  const removeError = () => {
    if (loginInfo.error) {
      dispatch(updateLoginInfoState([{ key: 'error', value: false }, { key: 'errorMessage', value: null }]))
    }
  }

  // classes
  const buttonContainer = `child-in-center mx-auto rounded-2xl h-14 w-80 max-w-full gap-2 my-2 font-medium`
  const inputClass = `rounded-2xl h-14 w-80 max-w-full pl-5 my-1 bg-transparent grayPlaceholder font-medium`

  return (
    <div className="h-full flex flex-col justify-between text-center py-16 px-8 overflow-auto no-scrollbar">

      <div className='relative'>
        <BackButton onClick={() => setShowForgotPasswordSection(false)} />
        <img src={logo} onClick={onLogoClick} className='mx-auto h-8 cursor-pointer' /></div>

      <div>
        <div className="font-bold text-xl sm:text-2xl lg:text-3xl mb-4" style={{ color: '#22252A' }}>
          Let’s get you logged in
          <img src={happyFace} className='h-6 inline ml-2' />
        </div>
        <div className="font-medium px-0 sm:px-4 md:px-16" style={{ color: '#6C757D' }}>
          Enter the email associated with your RecordBook account and we’ll send you a link to reset password.
        </div>

        <form onSubmit={handleSubmit} className='my-16'>
          <input
            type="email" value={email} onChange={handleEmailChange}
            required className={inputClass} style={{ border: '1px solid #ACB5BD' }} placeholder='Enter your Email'
          />

          {/* error msg */}
          {error && <p className="my-3 font-medium" style={{ color: '#F5291C', fontSize: '15px' }}><span>{errorMessage}</span></p>}
          {/* error msg */}

          <button type="submit" className={`${buttonContainer} text-white my-6 ${isDisabled ? 'cursor-not-allowed' : ''}`} style={{ backgroundColor: isDisabled ? '#DDDDDD' : '#35A600' }}>
            {
              isLoading ? (
                <div className="animate-spin rounded-full h-7 w-7 border-b-2 border-gray-100 m-auto "></div>
              ) : (
                <span>Reset Password</span>
              )
            }
          </button>

        </form>
      </div>

      <ContactSupport />

    </div>
  )
}

export default ForgotPasswordSection