import React, { useEffect, useRef, useState } from 'react';
import ReactDOM, { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux'
import EditStatusDialog from '../../../../PopUps/EditStatus';

import { updateBody, updateHeaderText, toggleDialogOpen } from '../../../../../../slices/DialogSlice'

// css 
import '../rowEditors.css';

//icons and images
import dropdownIcon from '../../../../../../images/dialog/status/dropdown_icon.png'

// utils
import ErrorBoundary from '../../../../../../utils/ErrorBoundary'
import { saturate } from 'tailwindcss/defaultTheme';

const StatusEditor = ({ rowProperties, saveCurrentRowData, colIdx, columnEl, statusOptions, statusColor }) => {

  const dispatch = useDispatch()

  // redux variables
  const registerData = useSelector(state => state.register)

  // local variables
  const colKey = columnEl.key
  const onClose = rowProperties.onClose
  const rowIndex = rowProperties.rowIdx
  const rowId = rowProperties.row.rowId
  const value = rowProperties.row[colKey]
  const columnId = rowProperties.column.key

  // state variables
  const [pos, setPos] = useState({})
  const [dropdownOpen, setDropdownOpen] = useState(false)

  // ref variables
  const dropdown = useRef(null)
  const dropdownTriggerBtnRef = useRef(null)

  useEffect(() => {
    // as soon as the editor is rendered dropdown should open
    //if (statusOptions.length === 0) {
    //  onClose(true)
    // alert("No Options available")
    // return
    //}
    setDropdownOpen(true);
    // listening to table scroll represented by .rdg-light class
    document.querySelector(".rdg-light").addEventListener("scroll", onScroll)
    document.addEventListener('keydown', keyHandler);
    return () => {
      document.removeEventListener("scroll", onScroll)
      document.removeEventListener('keydown', keyHandler);
    }
  }, []);

  useEffect(() => {
    if (dropdownOpen) onScroll()
  }, [dropdownOpen])

  const keyHandler = (e) => {
    // tab key press
    if (e.keyCode == 9) {

      // 1) Initially when the cell is opened then the focus is on the cell having the class rdg-focus-sink.
      // 2) After tabbing on the last element of status dropdown we bring focus back to status-editor-container-div,
      //    Hence as it has tabIndex value is -1 thus, automatically the focus shifs to the very next element having tabIndex 0,1 etc.
      //    Here the div to which the focus shifts is dropdownTriggerBtnRef having class sheetItem1 which consists the pre selected option.
      //
      //    So now when tab is pressed again the focus should shift to the first status option and for that we press tab.
      //    Now the focus shifts to status-dropdown-portal-container div which has tabIndex -1 so now, automatically the focus gets removed from it
      //    and shifts to the very next element with positive tabIndex ie., status-dropdown-option-1 anchor tag.
      if (document.activeElement.classList.contains('rdg-focus-sink') || document.activeElement.classList.contains('sheetItem1')) {
        document.getElementById(`status-dropdown-portal-container`).focus();
      }

      // When the last element is focused by tabbing and we press tab again,
      // the focus should return back to the pre selected cell.
      // We shift the focus on status-editor-container-div as it has tabIndex -1.
      // Due to this value of tab index the focus automatically moves to the very next element with a positive
      // tabIndex.
      if (document.activeElement.id === `status-dropdown-option-${statusOptions.length - 1}`) {
        document.getElementById(`status-editor-container-div`).focus();
      }
    }
    if (!dropdownOpen || e.keyCode !== 27) return;
    setDropdownOpen(false);
  };

  const onScroll = () => {
    if (dropdown.current) {
      let totalDropdownHeight = dropdown.current.getBoundingClientRect().height
      let positionTop = 0
      if (dropdownTriggerBtnRef.current.getBoundingClientRect().bottom + totalDropdownHeight >= window.innerHeight) {
        positionTop = positionTop - totalDropdownHeight
      } else {
        positionTop = 40
      }
      const refValue = ReactDOM.findDOMNode(dropdownTriggerBtnRef.current)
      if (refValue) {
        setPos({
          right: refValue
            .getBoundingClientRect().right,
          left: refValue
            .getBoundingClientRect().left,
          top: refValue
            .getBoundingClientRect().top + positionTop,
          bottom: refValue
            .getBoundingClientRect().bottom,
        })
      }
    }

  }

  const handleClick = (e) => {
    e.preventDefault()

    // If a person clicks pre seleceted option, 
    // then we should close the dropdown (setDropdownOpen) and thus the editor ( onClose )
    setDropdownOpen(!dropdownOpen)
    if (dropdownOpen === true) onClose(true)
  }

  const handleOptionClick = (option) => {
    saveCurrentRowData(option, colKey, rowId, registerData.rows)
    setDropdownOpen(false)
  }

  // handles key events of the editor container div
  const handleContainerDivKeyDown = (e) => {

    // enter key press
    if (e.keyCode == 13) {

      // Trigger click of the active element on which the focus is.
      document.activeElement.click();
      onClose(true)
    }
  }

  const editStatusClick = () => {
    dispatch(toggleDialogOpen(true));
    dispatch(updateBody(<EditStatusDialog statusOptions={statusOptions} columnId={columnId} />))
    dispatch(updateHeaderText('Configure Statuses'))
    onClose(true)
  }

  const removeStatusClick = (e) => {
    e.preventDefault()
    saveCurrentRowData('', colKey, rowId, registerData.rows)
    onClose(true)
  }

  const classes = {
    optionCommon: `p-2 w-full text-center text-white cursor-pointer`,
    listOption: `transition-colors delay-50 hover:bg-gray-600 ease-out`,
    editStatusBtn: `truncate underline hover:bg-blue-800`,
    removeStatusOption: `bg-white transition-colors delay-50 hover:bg-gray-200 ease-out`
  }

  const styles = {
    removeStatusOption: { color: '#FC1055' }
  }

  return (
    <ErrorBoundary>
      <div className="w-full h-full" tabIndex="-1" id="status-editor-container-div" onKeyDown={handleContainerDivKeyDown}>

        {/*Pre select value containing div starts here*/}
        <div ref={dropdownTriggerBtnRef} tabIndex="0" onClick={handleClick} className="sheetItem1">
          {
            value ?
              <div
                className={`px-2 rounded-full truncate text-white`}
                style={{ backgroundColor: statusColor }}
              >
                {value}
              </div> :
              <div className="px-2 truncate w-full h-5/6 m-0.5 flex justify-center items-center gap-2" style={{ color: '#576D9E', backgroundColor: '#E3F0FF' }}>
                Status
                <img src={dropdownIcon} className='h-2 relative mt-0.5' />
              </div>
          }

        </div>
        {/*Pre select value containing div ends here*/}

        {/*Portal starts here*/}
        {dropdownOpen && createPortal(
          <div ref={dropdown} id="status-dropdown-portal-container" className="slide_down_2 overflow-hidden overflow-y-auto border border-gray-300" tabIndex="-1" style={{ top: pos.top, right: pos.right, left: pos.left, fontSize: '12px', position: 'fixed', width: dropdownTriggerBtnRef.current.clientWidth, minWidth: '12rem' }} >

            <div className='max-h-40 overflow-auto'>
              {/*Options in the form of anchor and non active button starts here*/}
              {statusOptions && statusOptions.map((c, i) => {
                return (
                  <a tabIndex="0" id={`status-dropdown-option-${i + 1}`} key={i} className="flex items-center gap-4 leading-8" style={{ backgroundColor: c.color }} onClick={(e) => { e.preventDefault(); handleOptionClick(c.value) }}>
                    <button tabIndex={-1} className={`${classes.optionCommon} ${classes.listOption}`} onClick={() => { }}>
                      <p className="px-1 truncate">{c.value}</p>
                    </button>
                  </a>
                )
              })}
              {/*Options in the form of anchor and non active button ends here*/}
            </div>

            <div onClick={editStatusClick} style={{ background: '#586C9E' }}>
              <p className={`${classes.editStatusBtn} ${classes.optionCommon}`}>Edit Status</p>
            </div>

            {
              value &&
              <div onClick={removeStatusClick} className={`${classes.optionCommon} ${classes.removeStatusOption}`} style={styles.removeStatusOption}>
                Remove Status
              </div>
            }


          </div>,
          document.body)}
        {/*Portal ends here*/}

      </div>
    </ErrorBoundary>
  )
}

export default StatusEditor;