import { useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom';
import { useDropzone } from 'react-dropzone'

import TakePhoto from './TakePhoto'
import CustomProgressBar from '../../../../../../partials/CustomProgressBar'

import dragAndDropIcon from '../../../../../../images/dialog/image/drag.png'
import imageIcon from '../../../../../../images/dialog/image/image.png'
import removeImageIcon from '../../../../../../images/dialog/image/removeImage.png'

const RE_ImageDialog = ({ onImageChange, cellProps }) => {

  // route variables
  const location = useLocation()

  // state variables
  const [droppedImages, setDroppedImages] = useState([])
  const [uploadingImages, setUploadingImages] = useState(false)
  const [uploadedImgsCount, setUploadedImgsCount] = useState(0)
  const [showCamera, setShowCamera] = useState(false)

  // local variables
  const { pathname } = location;
  const communityId = pathname.split('/')[2]

  const onDrop = useCallback(acceptedFiles => {
    setDroppedImages((curr) => [...curr, ...acceptedFiles])
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const removeSelectedImage = (i) => {
    const currentImages = [...droppedImages]
    currentImages.splice(i, 1)
    setDroppedImages([...currentImages])
  }

  const onUploadClick = () => {
    setUploadingImages(true)
  }

  const getUploadedImagesClbk = (uploadedFiles) => {
    onImageChange(uploadedFiles)
  }

  const liveUploadCountClbk = (count) => {
    setUploadedImgsCount(count)
  }

  const onTakePhotoIconClick = () => {
    if(!navigator.mediaDevices){
      alert('Camera is not accessible over http origins')
      return
    }
    setShowCamera(true)
  }

  if (showCamera) {
    return (
      <TakePhoto cellProps={cellProps} showCamera={showCamera} setShowCamera={setShowCamera} droppedImages={droppedImages} setDroppedImages={setDroppedImages} />
    )
  } else if (droppedImages.length === 0) {
    return ( // drag drop view
      <div className="w-full p-4 h-full flex flex-col justify-center bg-white" style={{ width: '40rem', maxWidth: '90vw' }}>
        <div className="text-center p-2 my-2 relative flex items-center justify-start md:justify-center">
          <div className='text-lg md:text-xl lg:text-2xl font-bold'>Upload your Image</div>
          <div
            className='absolute right-0 px-2 md:px-4 rounded bg-gray-200 cursor-pointer py-1 md:py-2 border border-gray-400 transition-all delay-50 hover:bg-gray-300'
            onClick={onTakePhotoIconClick}
          >
            Take Photo
          </div>
        </div>
        <div
          className="flex flex-col gap-4 justify-center items-center w-full bg-gray-200 rounded py-16 border-dashed border-gray-400 border-2 cursor-pointer"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <img src={dragAndDropIcon} />
          <p>Drag and drop or browse to choose file</p>
        </div>
      </div>
    )
  } else { // images selected view
    return (
      <div className="w-full bg-white" style={{ width: '40rem', maxWidth: '90vw' }}>
        <div className="text-center p-4 text-xl">{uploadedImgsCount}/{droppedImages.length} Selected Images</div>
        <ul className="py-2 overflow-auto h-60" >
          {!uploadingImages && droppedImages.map((img, i) => {
            return (
              <li key={i} className="px-4 my-2 mx-4 py-2 rounded flex items-center justify-between" style={{ background: '#F4F4F4' }}>
                <div className="flex gap-2 items-center">
                  <img src={imageIcon} />
                  {img.name}
                </div>
                <button onClick={() => { removeSelectedImage(i) }}><img className="h-5 w-5" src={removeImageIcon} /></button>
              </li>
            )
          })}
          {
            uploadingImages &&
            <CustomProgressBar
              url={'otherservice/uploadImage'}
              files={droppedImages}
              communityId={communityId}
              getUploadedFilesClbk={getUploadedImagesClbk}
              progressDirection='horizontal'
              liveUploadCountClbk={liveUploadCountClbk}
              showFileInfo={true}
              outerContainerStyle={{ height: '3rem', width: '100%' }}
              outerContainerClasses={'px-2  py-1 rounded flex '}
              progressBarStyle={{ backgroundColor: '#586C9E' }}
            />
          }
        </ul>
        {
          !uploadingImages && droppedImages.length > 0 && (
            <div className="w-full px-4 py-2 flex justify-end">
              <div
                className='px-4 rounded bg-gray-200 cursor-pointer py-2 border border-gray-400 transition-all delay-50 hover:bg-gray-300'
                onClick={() => setShowCamera(true)}
              >
                Take Photo
              </div>
              <div className="text-center cursor-pointer rounded border p-2 mx-2 shadow-md w-28" style={{ color: '#586C9E', borderColor: '#586C9E' }} {...getRootProps()} ><input {...getInputProps()} />Add More</div>
              <button className="rounded border p-2 mx-2 shadow-md w-28" style={{ color: 'white', background: '#586C9E' }} onClick={onUploadClick} >Upload</button>
            </div>
          )
        }
      </div>
    )
  }
}

export default RE_ImageDialog