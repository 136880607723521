import { useEffect, useState, useRef } from 'react'
import ReactDOM, { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'

// utils
import ErrorBoundary from '../../../../../utils/ErrorBoundary'
import { getValidatedNumber } from '../../Utils'
import DeviceMeta from '../../../../../utils/DeviceDetector'

const UnitEditor = ({ rowProperties, saveCurrentRowData, colIdx, columnEl }) => {
  // redux variables 
  const registerData = useSelector(state => state.register)

  // local variables
  const colKey = columnEl.key
  const onClose = rowProperties.onClose
  const rowIndex = rowProperties.rowIdx
  const rowId = rowProperties.row.rowId
  const value = getValidatedNumber(rowProperties.row[colKey])

  // state variables
  const [unitText, setUnitText] = useState(value || '')
  const [pos, setPos] = useState({})
  const [dynamicWidth, setDynamicWidth] = useState('')

  // ref variables
  const unitEditorDivRef = useRef(null)
  const unitEditorInputRef = useRef(null)

  useEffect(() => {
    setDynamicWidth(unitEditorDivRef.current.clientWidth)
    if (!DeviceMeta.isMobileOrTablet())
      // Sets position of the unitText box in portal w.r.t unitEditorDivRef
      // using its ref.
      onScroll()
    // listeneing to table scroll represented by .rdg-light class
    document.querySelector(".rdg-light").addEventListener("scroll", onScroll)
    return () => {
      document.removeEventListener("scroll", onScroll)
    }
  }, []);

  useEffect(() => {
    let clientWidth = unitEditorDivRef.current.clientWidth
    if (unitText.length * 7 > clientWidth) {
      setDynamicWidth(unitText.length + 'ch')
    } else {
      setDynamicWidth(unitEditorDivRef.current.clientWidth)
    }
  }, [unitText, unitEditorDivRef.current && unitEditorDivRef.current.clientWidth || ''])

  const onScroll = () => {
    if (ReactDOM.findDOMNode(unitEditorDivRef.current)) {
      setPos({
        right: ReactDOM.findDOMNode(unitEditorDivRef.current)
          .getBoundingClientRect().right,
        left: ReactDOM.findDOMNode(unitEditorDivRef.current)
          .getBoundingClientRect().left,
        top: ReactDOM.findDOMNode(unitEditorDivRef.current)
          .getBoundingClientRect().top,
        bottom: ReactDOM.findDOMNode(unitEditorDivRef.current)
          .getBoundingClientRect().bottom,
      })
    }
  }

  const clickHandler = () => {
    saveCurrentRowData(unitEditorInputRef.current.value, colKey, rowId, registerData.rows)
  };

  const handleContainerDivKeyDown = (e) => {
    if (e.keyCode == 13 || e.keyCode === 9) {
      e.preventDefault()
      saveCurrentRowData(unitText, colKey, rowId, registerData.rows, false, false, null, e)
      onClose(true)
    }
  }

  return (
    <ErrorBoundary>
      <div ref={unitEditorDivRef} className=" w-full h-full bg-gray-800">
        {
          DeviceMeta.isMobileOrTablet() ?
            <div onKeyDown={handleContainerDivKeyDown} >
              <input
                ref={unitEditorInputRef}
                name={colKey}
                type="number"
                placeholder="Enter Number"
                value={unitText}
                onChange={(e) => setUnitText(e.target.value)}
                className="bg-white "
                style={{ fontSize: '12px', width: dynamicWidth }}
                autoFocus
                onBlur={clickHandler}
              />
            </div>
            :
            createPortal(
              <div onKeyDown={handleContainerDivKeyDown} >
                <input
                  ref={unitEditorInputRef}
                  name={colKey}
                  type="number"
                  placeholder="Enter Number"
                  value={unitText}
                  onChange={(e) => setUnitText(e.target.value)}
                  className="bg-white "
                  style={{ top: pos.top, bottom: pos.bottom, right: pos.right, left: pos.left, fontSize: '12px', position: 'fixed', width: dynamicWidth, height: unitEditorDivRef.current && unitEditorDivRef.current.clientHeight }}
                  autoFocus
                  onBlur={clickHandler}
                />
              </div>,
              document.body
            )}
      </div>
    </ErrorBoundary>
  )
}

export default UnitEditor