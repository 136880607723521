import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// components
import ProgressBar from './ProgressBar'

// slices
import { updateLoginInfoState, sendEmailToUser } from '../../../slices/LoginSlice'

// icons
import logo from '../../../images/logo.png'
import mailBoxGif from '../../../images/login/gif-email.gif'

const EmailSentComponent = ({ email, setStep }) => {

  // redux variales
  const dispatch = useDispatch()
  const loginInfo = useSelector(state => state.loginInfo)

  // state variables
  const [otpSentMsg, setOtpSentMsg] = useState(null)

  // local variables
  const { isLoading } = loginInfo

  const handleOpenEmail = (e) => {
    e.preventDefault()
    let emailLink
    if (email.includes("gmail") || email.includes("rekord")) {
      emailLink = `https://mail.google.com/`
    } else if (email.includes("yahoo")) {
      emailLink = `https://mail.yahoo.com/`;
    } else if (email.includes("hotmail") || email.includes("outlook")) {
      emailLink = `https://outlook.live.com/owa/`;
    } else {
      emailLink = `mailto:${email}`
    }
    window.open(emailLink)
  }

  const onEmailResend = async (e) => {
    e.preventDefault();
    await dispatch(sendEmailToUser({ emailId: email }))
    setOtpSentMsg(true)
    setTimeout(() => {
      setOtpSentMsg(false)
    }, 4000)
  }

  return (
    <div className="fixed inset-0 bg-white p-8 h-full flex flex-col justify-between items-center" style={{ backgroundColor: '#F8F9FA' }}>

      <div className="flex justify-between items-center absolute pl-8 pr-16 w-full">
        <img src={logo} onClick={() => window.location.reload()} className='h-8 cursor-pointer' loading='lazy' />
        <ProgressBar step={2} />
      </div>

      <div className='font-bold text-3xl text-black mt-8'>Please, verify your email</div>

      <div className='font-semibold' style={{ color: '#000000A1' }}>
        We have sent an email to <span className='text-black'>{email}</span>. Check your Inbox to verify your account. <span onClick={handleOpenEmail} className='cursor-pointer' style={{ color: '#0B99FF' }}>Open Email</span>
      </div>

      <img src={mailBoxGif} loading='lazy' className='my-5' style={{ height: '15rem', maxHeight: '40vh' }} />

      <div className='font-semibold text-black'>
        <div className='mb-2'>Did you not receive an email?</div>
        <div>Have you checked your spam folder?</div>
      </div>

      <div onClick={onEmailResend} className='font-medium text-white rounded-xl' style={{ backgroundColor: '#ACB5BD' }}>
        <button className='w-36 h-8 rounded-xl hover:bg-gray-500 transition-colors delay-50'>
          {
            isLoading ? (
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-100 m-auto "></div>
            ) : (
              <span>Resend Email</span>
            )
          }
        </button>
      </div>

      {
        otpSentMsg && <div className="font-medium" style={{ fontSize: '15px', color: '#35A600' }}>Email has been sent again, please check your email</div>
      }

      <div onClick={() => { dispatch(updateLoginInfoState([{ key: 'emailSentToUser', value: false }])); setStep(1) }} className='font-medium cursor-pointer mb-8' style={{ color: '#0B99FF' }}>
        Use a different email to signup
      </div>

    </div>
  )
}

export default EmailSentComponent