import NotificationsHeader from "./Components/NotificationsHeader"
import NotificationsList from "./Components/NotificationsList"

const Notifications = () => {
  return (
    <>
      <NotificationsHeader />
      <NotificationsList />
    </>
  )
}

export default Notifications