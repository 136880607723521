import { useSelector } from 'react-redux'
import React, { useEffect, useState, useRef } from "react";

import moment from 'moment';

//components
import { getValidatedTime } from '../../../../../Table/Utils'
import Dropdown from '../../../../../../../partials/Dropdown'
import TimeUnitSelector from './TimeUnitSelector'
import CustomInputDropdown from '../../../../../../../partials/CustomInputDropdown';

//icons
import timeIcon from '../../../../../../../images/datatype_icons/light/time.png'
import dropDownIcon from '../../../../../../../images/add-edit row dialog/drop-down.png'
import colonIcon from '../../../../../../../images/add-edit row dialog/time_colon.png'
import crossIcon from '../../../../../../../images/add-edit row dialog/cross-gray.png'


const Time = ({ colkey, column, handleChange, formData, shouldAllowNewEntry }) => {

  // redux variables
  const registerData = useSelector(state => state.register)
  const rowIndex = useSelector(state => state.register.rowIndex)

  // state variables
  const [time, setTime] = useState('')
  const [dropdownOpen, setDropdownOpen] = useState(false)

  // local variables
  const { isAdmin, hasBasicEditPermission, editPermissions } = registerData
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colkey) === -1
  const minsTenToSixty = []
  for (let i = 10; i < 60; i++) {
    minsTenToSixty.push(String(i))
  }
  const hoursArray = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
  const minutesArray = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', ...minsTenToSixty]
  const meridiemArray = ['AM', 'PM']

  useEffect(() => {
    if (dropdownOpen) {
      if (!formData[colkey]) {
        let currentTime = moment().format('hh:mm A')
        setTimeInDropdown(currentTime)
      }
      else {
        let timeInCell = moment(time, 'hh:mm a').format('hh:mm A')
        setTimeInDropdown(timeInCell)
      }
    }
  }, [dropdownOpen])

  // useEffect(() => {
  //   if (time && time != formData[colkey]) {
  //     handleChange({ colkey, value: time.toUpperCase() })
  //   }
  // }, [time])

  useEffect(() => {
    if (!formData[colkey]) {
      setTime(null)
    }
    else {
      setTime(getValidatedTime(formData[colkey]))
    }
  }, [formData[colkey], rowIndex])

  const setTimeInDropdown = (time) => {
    let hour = moment(time, 'hh:mm A').format('h')
    let min = moment(time, 'hh:mm A').format('m')
    let meridiemValue = moment(time, 'hh:mm A').format('A')
    let meridiem = meridiemValue == 'AM' ? 0 : 1
    scrollTo('hour', hour - 1)
    scrollTo('min', min)
    scrollTo('meridiem', meridiem)
  }

  const onTimeFieldClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDropdownOpen(!dropdownOpen)
    if (!time) {
      setTime(moment().format('hh:mm A'))
    }
  }

  // to scroll to an hour/min/meridiem in dropdown
  const scrollTo = (type, value) => {
    let myElement = document.getElementById(`${type}_${value}_${colkey}`)
    let topPos = myElement.offsetTop
    document.getElementById(`${type}_container_div_${colkey}`).scrollTo({ top: topPos - 48, behavior: 'smooth' })
  }

  const closeDropdown = () => {
    setDropdownOpen(false)
  }

  // const onCancelClick = () => {
  //   closeDropdown()
  // }

  const onSaveClick = () => {
    let hourContainerDiv = document.getElementById(`hour_container_div_${colkey}`)
    let minContainerDiv = document.getElementById(`min_container_div_${colkey}`)
    let meridiemContainerDiv = document.getElementById(`meridiem_container_div_${colkey}`)
    let hour = hourContainerDiv.scrollTop / 48 + 1
    let min = minContainerDiv.scrollTop / 48
    let mer = meridiemContainerDiv.scrollTop / 48 == 0 ? 'AM' : 'PM'
    let time = `${hour}:${min} ${mer}`
    setTime(moment(time, 'hh:mm a').format('h:mm A'))
    closeDropdown()
    if (time && time != formData[colkey]) {
      handleChange({ colkey, value: time.toUpperCase() })
    }
  }

  return (
    <div className="mb-6">

      <div className="colName">
        <img src={timeIcon} className="field_icon" />
        <label htmlFor={column.name}>{column.name}</label>
      </div>

      {
        !canEdit && !shouldAllowNewEntry &&
        <div className="field truncate">{getValidatedTime(formData[colkey])}</div>
      }

      {
        (shouldAllowNewEntry || canEdit) &&
        <CustomInputDropdown
          fieldValue={time}
          inputType={`div`}
          container={`form`}
          inputClasses={`field`}
          inputStyles={{}}
          dropdownClasses={``}
          dropdownStyles={{ marginTop: 8, minWidth: '15rem' }}
          buttonsInfo={{ showButtonsSection: true, showCancelButton: true, showSaveButton: true, onSaveButtonClick: onSaveClick }}
          showRemoveIcon={!dropdownOpen}
          onRemoveIconClick={() => { setTime(null); handleChange({ colkey, value: '' }) }}
          showDropDownMenu={dropdownOpen}
          setShowDropDownMenu={setDropdownOpen}
          onFieldClick={() => {
            if (!time) {
              setTime(moment().format('hh:mm A'))
            }
          }}
        >
          <div className='relative mx-5 flex justify-around' style={{ height: '9rem', color: '#061119' }}>

            <div className='absolute w-full top-1/3 rounded' style={{ height: '3rem', backgroundColor: '#E4EFF0' }}></div>

            {/* Hours */}
            <TimeUnitSelector type={'hour'} timeUnitArray={hoursArray} colkey={colkey} scrollTo={scrollTo} />

            {/* Colon */}
            <div className='h-full flex items-center px-3'>
              <img src={colonIcon} className='w-28 relative bottom-0.5' />
            </div>

            {/* Minutes */}
            <TimeUnitSelector type={'min'} timeUnitArray={minutesArray} colkey={colkey} scrollTo={scrollTo} />

            {/* Meridiem */}
            <TimeUnitSelector type={'meridiem'} timeUnitArray={meridiemArray} colkey={colkey} scrollTo={scrollTo} />

          </div>
        </CustomInputDropdown>
      }
    </div>
  )
}

export default Time


//   < div className = 'flex relative items-center' >

//     <button onClick={onTimeFieldClick} className="field">
//       <div>{time}</div>
//     </button>

// {
//   time ?
//     <img
//       onClick={() => { setTime(null); handleChange({ colkey, value: '' }) }}
//       src={crossIcon}
//       className='cross_icon z-10'
//     />
//     :
//     <img className='drop-down-icon' src={dropDownIcon} />
// }

// {/* Time picker */ }
// {
//   dropdownOpen &&
//     <Dropdown showDropdown={dropdownOpen} closeDropdownCallback={closeDropdown} containerClasses={`mt-2 w-full overflow-hidden`}>
//       <div className='relative mx-5 flex justify-around' style={{ height: '9rem', color: '#061119' }}>

//         <div className='absolute w-full top-1/3 rounded' style={{ height: '3rem', backgroundColor: '#E4EFF0' }}></div>

//         {/* Hours */}
//         <TimeUnitSelector type={'hour'} timeUnitArray={hoursArray} colkey={colkey} scrollTo={scrollTo} />

//         {/* Colon */}
//         <div className='h-full flex items-center px-3'>
//           <img src={colonIcon} className='w-28 relative bottom-0.5' />
//         </div>

//         {/* Minutes */}
//         <TimeUnitSelector type={'min'} timeUnitArray={minutesArray} colkey={colkey} scrollTo={scrollTo} />

//         {/* Meridiem */}
//         <TimeUnitSelector type={'meridiem'} timeUnitArray={meridiemArray} colkey={colkey} scrollTo={scrollTo} />

//       </div>

//       {/* Save and cancel */}
//       <div className='py-2 px-4 flex gap-3 justify-end' style={{ backgroundColor: '#F9F9F9' }}>
//         <button onClick={onCancelClick} className="tertiary_btn transition-all delay-50 px-4 py-1">Cancel</button>
//         <button onClick={onSaveClick} className="secondary_btn transition-all delay-50 px-5 py-1">Save</button>
//       </div>

//     </Dropdown>
// }
// {/* Time picker */ }
//         </div >