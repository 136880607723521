import { createSlice } from '@reduxjs/toolkit'

export const toastSlice = createSlice({
  name: 'toast',
  initialState: {
    showToast: false,
    message: null,
    type: null,
    duration: 4000
  },
  reducers: {
    setToastDetails: (state, action) => {
      state.showToast = true
      state.type = action.payload.type
      state.message = action.payload.message
      if (state.duration) state.duration = action.payload.duration
    },
    resetToastDetails: state => {
      state.showToast = false
      state.type = null
      state.message = null
      state.duration = 4000
    }
  }
})

export const { setToastDetails, resetToastDetails } = toastSlice.actions

export default toastSlice.reducer

