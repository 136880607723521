import { useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'

// slices
import { toggleDialogOpen, updateBody, updateHeaderText } from '../../../../../../../../slices/DialogSlice'

// components
import FormulaConfiguration from '../../../../../../PopUps/FormulaConfiguration'

const FormulaEditMenu = ({ formulaEditMenuToggler, colIdx, allowedToEdit, preSetFormulaVal, onCancelClick }) => {

  // redux variables
  const dispatch = useDispatch()

  // ref variables
  const formulaContainerRef = useRef()

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (formulaContainerRef.current && (formulaContainerRef.current.contains(target) || formulaEditMenuToggler.current.contains(target))) return;
      onCancelClick()
    }
    const keyHandler = (e) => {
      // tab key press
      if (e.keyCode == 9) {
        if (document.activeElement.id === "formula-editor-cancel-btn") {
          document.getElementById(`formula-editor-container`).focus();
        }
      }
    };
    document.addEventListener('click', clickHandler);
    document.addEventListener('keydown', keyHandler);
    return () => {
      document.removeEventListener('click', clickHandler)
      document.removeEventListener('keydown', keyHandler);
    }
  }, [])

  const onEditClick = () => {
    dispatch(toggleDialogOpen(true))
    dispatch(updateBody(
      <FormulaConfiguration
        preSetFormulaArr={preSetFormulaVal.arr}
        selectedColIdx={colIdx}
        closeDialog={() => dispatch(toggleDialogOpen(false))}
      />
    ))
    dispatch(updateHeaderText('Add Formula'))
  }

  return (
    <div
      ref={formulaContainerRef}
      className="flex flex-col justify-center items-center rounded-lg shadow-xl pl-1 pr-1 z-20 top-full left-0"
      tabIndex="-1"
      id="formula-editor-container"
      style={{
        boxShadow: ' 0px 4px 4px rgb(0 0 0 / 25%)',
        fontSize: '12px',
        position: 'absolute',
        width: '13rem',
        height: '130px',
        backgroundColor: '#fff'
      }}
    >
      <div className="w-full h-1/5 border-b-2" style={{ fontSize: 14 }}>
        Formula Already Added
      </div>
      <div className="flex w-full h-3/5 items-center justify-center border-b-2" style={{ fontSize: 13 }}>
        {preSetFormulaVal.string}
      </div>
      <div className="flex w-full h-1/5 cursor-pointer">
        {allowedToEdit && <div tabIndex="0" className="flex w-1/2 items-center justify-center border-r-2" onClick={onEditClick}>Edit</div>}
        <div tabIndex="0" id="formula-editor-cancel-btn" className="flex w-1/2 items-center justify-center" onClick={onCancelClick}>Cancel</div>
      </div>
    </div>
  )
}

export default FormulaEditMenu