import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect } from "react-router-dom"

// components
import Divider from "../Divider"
import HeaderTexts from "../HeaderTexts"
import RedirectToLogin from "../RedirectToLogin"
import EmailSentComponent from "../EmailSentComponent"
import Terms from "../Terms"

// slices
import { sendEmailToUser, updateLoginInfoState } from "../../../../slices/LoginSlice"

// icons
import googleIcon from '../../../../images/login/google.png'
import phNoIcon from '../../../../images/login/phNo.png'
import ProgressBar from "../ProgressBar"

const SignUpUsingEmail = ({ handleSignUpMethodChange, SIGN_UP_METHODS }) => {

  // redux variales
  const dispatch = useDispatch()
  const loginInfo = useSelector(state => state.loginInfo)

  // state variables
  const [email, setEmail] = useState('')
  const [redirectToLogin, setRedirectToLogin] = useState(false)
  const [step, setStep] = useState(1)

  // local variables
  const { emailSentToUser, isLoading, error, errorMessage } = loginInfo
  const isDisabled = !email

  // classes
  const inputClass = `rounded-2xl max-w-full pl-5 bg-transparent grayPlaceholder font-medium my-3`
  const buttonClass = `child-in-center mx-auto rounded-2xl max-w-full gap-2 my-3 font-medium`

  // styles
  const inputStyle = { border: '1px solid #ACB5BD', width: '26rem', height: '4.5rem', fontSize: '1.125rem' }
  const buttonStyle = { width: '26rem', height: '4.5rem', fontSize: '1.125rem' }

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
  }

  const OnEmailSubmit = (e) => {
    e.preventDefault()
    if (isDisabled) return
    if (!validateEmail(email)) {
      dispatch(updateLoginInfoState([{ key: 'error', value: true }, { key: 'errorMessage', value: 'Please enter a valid email address' }]))
    } else dispatch(sendEmailToUser({ emailId: email }))
  }

  const removeError = () => {
    if (error) {
      dispatch(updateLoginInfoState([{ key: 'error', value: false }, { key: 'errorMessage', value: null }]))
    }
  }

  if (redirectToLogin) {
    return <Redirect to='/Login' />
  }

  if (emailSentToUser) { return <EmailSentComponent email={email} setStep={setStep} /> }
  else return (
    <div className="h-full">

      <div className="" style={{ height: '10%' }}>
        <ProgressBar step={step} />
      </div>

      <div className="flex flex-col justify-around" style={{ height: '80%' }}>

        <HeaderTexts />

        <form onSubmit={OnEmailSubmit}>
          <input type='email' value={email} onChange={e => { removeError(); setEmail(e.target.value) }} className={inputClass} style={inputStyle} placeholder="Enter your email" />

          {/* error msg */}
          {error && <p className="pl-3 font-medium" style={{ color: '#F5291C', fontSize: '15px' }}><span> {errorMessage} </span></p>}
          {/* error msg */}

          <button type="submit" className={`${buttonClass} text-white ${isDisabled ? 'cursor-not-allowed' : ''}`} style={{ backgroundColor: isDisabled ? '#DDDDDD' : '#35A600', ...buttonStyle }}>
            {
              isLoading ? (
                <div className="animate-spin rounded-full h-7 w-7 border-b-2 border-gray-100 m-auto "></div>
              ) : (
                <span>Try For Free</span>
              )
            }
          </button>

          {
            email && <div className="py-2"><Terms /></div>
          }
        </form>

        <div className=""><Divider /></div>

        {/* Other sign up options */}
        <div>
          {/* <button className={buttonClass} style={{ border: '1px solid #ACB5BD' }}>
            <img src={googleIcon} className='h-6' loading='lazy' />
            <span className='font-semibold'>Sign Up with Google</span>
          </button> */}
          <button onClick={() => { removeError(); handleSignUpMethodChange(SIGN_UP_METHODS.PHONE_NUMBER) }} className={buttonClass} style={{ border: '1px solid #ACB5BD', ...buttonStyle }}>
            <img src={phNoIcon} className='h-6' loading='lazy' />
            <span className='font-semibold'>Sign up with Phone Number</span>
          </button>
        </div>
        {/* Other sign up options */}
      </div>

      <div className="flex items-end justify-center" style={{ height: '10%' }}>
        <RedirectToLogin setRedirectToLogin={setRedirectToLogin} />
      </div>

    </div>
  )
}

export default SignUpUsingEmail