const AttachmentsList = ({ attachmentsArray }) => {

  const attachmentNamesArray = attachmentsArray.map((attch, i) => {
    let name = attch.value.split('/').pop()
    return name
  })

  return (
    <div style={{ color: '#586C9D', fontSize: '0.85rem', fontWeight: '600' }} className='my-4'>
      {attachmentNamesArray.map((name, i) => {
        return (
          <li className="w-full truncate">{name}</li>
        )
      })}
    </div>
  )
}

export default AttachmentsList