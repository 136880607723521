import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const ThemeSlice = createSlice({
  name: 'theme',
  initialState: {
    primary: '#576D9E',
    red: '#D81205',
    green: '#4DB14F',
    secondary: '#EAF1F8',
    secondary_text: '#3B4C72',
    light_grey: '#B6B6B6',
    grey: '#878787',
    dark_grey: '#545454',
    primary1: '#044089',
    sky_blue: '#E0EEFF'
  },
  reducers: {

  }
})

export default ThemeSlice.reducer