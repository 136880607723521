const Divider = () => {

  const dividerText = `absolute bottom-0 translate-y-1/2 transform text-center font-medium px-4 child-in-center h-4`

  return (
    <div className='relative border-b mx-auto text-center child-in-center max-w-full' style={{ borderColor: '#ACB5BD', width: '26rem' }}>
      <div className={dividerText} style={{ backgroundColor: '#F8F9FA', color: '#ACB5BD' }}>Or</div>
    </div >
  )
}

export default Divider