import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import Dropdown from '../../../../../../partials/Dropdown'

//icons
import switchIcon from '../../../../../../images/datatype_icons/light/switch.png'
import deleteIcon from '../../../../../../images/add-edit row dialog/form icons/delete.png'
import dropDownIcon from '../../../../../../images/add-edit row dialog/drop-down.png'
import yesIcon from '../../../../../../images/add-edit row dialog/yes-switch.png'
import noIcon from '../../../../../../images/add-edit row dialog/no-switch.png'

const Switch = ({ colkey, column, handleChange, formData, deleteEntry, shouldAllowNewEntry }) => {

  // redux variables
  const registerData = useSelector(state => state.register)

  // state variables
  const [dropdownOpen, setDropdownOpen] = useState(false)

  // ref variables
  

  // local variables
  const { isAdmin, hasBasicEditPermission, editPermissions } = registerData
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colkey) === -1

  const onFieldClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDropdownOpen(!dropdownOpen)
  }

  const handleOptionClick = (option) => {
    handleChange({ colkey, value: option })
    closeDropdown()
  }

  const closeDropdown = () => {
    setDropdownOpen(false)
  }

  //classes
  const list_item_class = `list_item gap-1 md:gap-2 lg:gap-4 whitespace-nowrap font-semibold w-full transition-all delay-50 ease-in cursor-pointer px-1 md:px-2 lg:px-5 py-2 rounded`

  return (
    <div className="mb-6">
      <div className="colName">
        <img src={switchIcon} className="field_icon" />
        <label htmlFor={column.name}>{column.name}</label>
      </div>

      {
        !canEdit && !shouldAllowNewEntry &&
        <div className="field">{formData[colkey]}</div>
      }

      {
        (shouldAllowNewEntry || canEdit) &&
        <div className='relative flex items-center'>
          <button onClick={onFieldClick} className="field">{formData[colkey]}</button>
          <img className={`drop-down-icon transform transition-transform delay-50 ${dropdownOpen ? 'rotate-180' : 'rotate-0'}`} src={dropDownIcon} />
          {
            dropdownOpen &&
            <Dropdown closeDropdownCallback={closeDropdown} containerClasses={`mt-2 w-full`}>
              <ul className="py-2" style={{ fontSize: '14px', fontWeight: '600' }}>
                <li className={list_item_class} onClick={(e) => { e.preventDefault(); handleOptionClick('Yes') }}>
                  <img src={yesIcon} className='h-6' alt="" />
                  Yes
                </li>
                <li className={list_item_class} onClick={(e) => { e.preventDefault(); handleOptionClick('No') }}>
                  <img src={noIcon} className='h-6' alt="" />
                  No
                </li>
                <li className={list_item_class} style={{ color: '#D81205' }} onClick={(e) => { e.preventDefault(); deleteEntry(colkey); setDropdownOpen(false) }}>
                  <img src={deleteIcon} className='h-6' alt="" />Remove Entry
                </li>
              </ul>
            </Dropdown>
          }
        </div>
      }

    </div>
  )
}

export default Switch