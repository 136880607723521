import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

// slices
import { getTemplateDetail } from '../../../../slices/RegisterTemplateSlice'
import { createRegister } from '../../../../slices/LeftSidebarSlice'

// components
import PopUp from '../../../../partials/Dialog/PopUp'

//icons
import closeIcon from '../../../../images/dialog/close.png'
import blackEyeIcon from '../../../../images/blackEyeIcon.png'

// style
import './index.css'
const RegisterTemplatePreviewPopUp = () => {

	// redux variables
	const dispatch = useDispatch()
	const registerTemplateData = useSelector(state => state.registerTemplateStore)
	const leftSidebarData = useSelector(state => state.leftSidebar)
	const routerData = useSelector(state => state.routerStore)

	// router variables
	const history = useHistory()
	const location = useLocation()
	const { search } = location

	// state variables
	const [isInternallyOpen, setIsInternallyOpen] = useState(true)
	const [templateDetailsInternal, setTemplateDetailsInternal] = useState({})

	// ref variables
	const dialog = useRef(null)

	// local variables
	const { popUpNavigationHistory } = routerData
	const { templateDetails } = registerTemplateData
	const { activeBusinessId } = leftSidebarData

	useEffect(() => {
		const query = new URLSearchParams(search)
		const templateId = query.get('templateId')
		dispatch(getTemplateDetail({ templateId }))
	}, [])

	// close if the esc key is pressed- sidebar menu
	useEffect(() => {
		const keyHandler = ({ keyCode }) => {
			if (!isInternallyOpen || keyCode !== 27) return;
			closePopUp()
		};
		document.addEventListener('keydown', keyHandler);
		return () => document.removeEventListener('keydown', keyHandler);
	}, []);

	useEffect(() => {
		setTemplateDetailsInternal(templateDetails)
	}, [templateDetails])

	const closePopUp = () => {
		setIsInternallyOpen(false)
		// const routeHistoryLength = popUpNavigationHistory.length
		history.push(location.pathname)
	}

	const useThisTemplate = () => {
		dispatch(createRegister({
			registerName: templateDetailsInternal.name,
			businessId: activeBusinessId,
			templateId: templateDetailsInternal.templateId
		}))
		closePopUp()
	}

	const onCancelClick = () => {
		if (popUpNavigationHistory.length > 0) history.goBack()
		else history.push(location.pathname)
	}

	const classes = {
		container: `flex justify-center items-center fixed h-screen top-0 bottom-0 left-0 w-full ${isInternallyOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`,
		backdropDiv: `flex justify-center items-center absolute h-screen top-0 bottom-0 left-0 w-full ${isInternallyOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`,
		subContainer: `flex flex-col items-center rounded bg-white shadow-md transform transition-all duration-200 ease-out ${isInternallyOpen ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0 pointer-events-none'} `,
		header: "w-full flex items-center justify-between rounded-t px-5 text-white",
		body: "w-full flex flex-col justify-center items-center",
		footer: "w-full flex justify-end items-center border-t"
	}

	const styles = {
		body: { width: '32rem', height: '25rem' }
	}

	if (isInternallyOpen) {
		return (
			<PopUp
				onClose={closePopUp}
				popUpContainerStyle={styles.body}
				body={(
					<div className="w-full h-full flex flex-col">
						{/* header container starts */}
						<div
							className={classes.header}
							style={{ height: '18%', background: '#586C9E', fontSize: 22, fontStyle: 'normal', fontFamily: 'Sans-serif' }}
						>
							<div className="flex w-9/12">
								<img className="w-12 h-12 rounded-full" src={templateDetailsInternal.avatar} width="32" height="32" />
								<div className="flex flex-col ml-2 justify-center">
									<span style={{ fontSize: 15, fontWeight: '600' }}>{templateDetailsInternal.name}</span>
									<span style={{ fontSize: 11 }}>{templateDetailsInternal.description}</span>
								</div>
							</div>
							<div onClick={closePopUp} className="w-3/12 flex justify-end">
								<img className="h-6 w-6" src={closeIcon} alt="close dialog" />
							</div>
						</div>
						{/* header container ends */}

						{/* body container starts */}
						{
							templateDetailsInternal.columns &&
							<div className={classes.body} style={{ height: '82%', backgroundColor: '#EEF3F5' }} >
								<div className="w-full flex pt-4 overflow-y-auto" style={{ height: '40%' }}>
									<table className="sampleTableClass">
										<tr>
											<th>S.no</th>
											{
												templateDetailsInternal.columns && templateDetailsInternal.columns.map(el => <th className="truncate">{el.value}</th>)
											}
										</tr>
										{
											templateDetailsInternal.rows && templateDetailsInternal.rows.map((rowEl, rowIdx) => {
												return (
													<tr>
														<td>{rowIdx + 1}</td>
														{
															rowEl.map(cellEl => <td className="truncate">{cellEl.value}</td>)
														}
													</tr>
												)
											})
										}
									</table>
								</div>
								<div className="w-full flex flex-col items-center justify-center" style={{ height: '20%' }}>
									<span className="flex items-center" style={{ fontSize: 13 }}>
										<img className="h-2 w-3 mr-1 cursor-pointer" src={blackEyeIcon} alt="white eye icon" />
                			You're previewing the "Blank Register"
              		</span>
									<span style={{ fontSize: 10 }}>Click on the button below to use.</span>
								</div>
								<div className="w-full flex pt-2 pb-2 pl-8 pr-8" style={{ height: '20%' }}>
									<div onClick={useThisTemplate} className="w-full flex text-white justify-center items-center cursor-pointer" style={{ backgroundColor: '#2AB200', fontSize: 18, fontWeight: '600', borderRadius: 3 }}>
										Use This Template
                  </div>
								</div>
								<div className="w-full flex pt-2 pb-3 pl-8 pr-8" style={{ height: '20%' }}>
									<div onClick={onCancelClick} className="w-full flex text-white justify-center items-center cursor-pointer" style={{ borderWidth: 1, borderColor: '#586C9E', fontSize: 18, fontWeight: '600', borderRadius: 3, color: '#586C9E' }}>
										Cancel
                  </div>
								</div>
							</div>
						}

						{/* body container ends */}
					</div>
				)}
			/>
		)
	} else {
		return <></>
	}

}

export default RegisterTemplatePreviewPopUp