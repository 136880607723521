import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

// components
import TextList from './Components/TextList'

const TextFilter = ({ changeFilterSettings, selectedfilterData, selectedCol }) => {
  // redux 
  const registerData = useSelector(state => state.register)

  // state
  const [uniqueTextList, setUniqueTextList] = useState([])
  const [searchQuery, setSearchQuery] = useState('')

  // local
  const { rows, filteredRows, filters } = registerData

  useEffect(() => {
    let uniqueVals = []
    
    if (selectedCol.dataType === 'label') { // where values are picked from details key
      rows.forEach((rowEl, i) => {
        if (Array.isArray(rowEl[selectedCol.key + '_details'])) {
          rowEl[selectedCol.key + '_details'].forEach(el => {
            if (uniqueVals.indexOf(el.value) === -1) uniqueVals.push(el.value)
          })
        }
      })
    } else { // where values are picked from non details key
      rows.forEach((rowEl) => {
        const value = rowEl[selectedCol.key]
        if (uniqueVals.indexOf(value) === -1 && rowEl.rowId !== 'CALCULATE_ROW') { // second check ensures that the cell value from calculate rows are not picked
          if (!!value) uniqueVals.push(value) // only defined value are pushed
        }
      })
    }
    // use case is: if user updates filter value in the row then we atleast need to show it
    const existingFilter = filters.find(el => el.columnId === selectedCol.key)
    if (existingFilter) {
      uniqueVals = uniqueVals.concat(existingFilter.filters) // merge
      uniqueVals = uniqueVals.filter((item, pos) => uniqueVals.indexOf(item) === pos) // remove duplicates
    }
    // blank value has its postion at the last in the list hence its pushed at the last only
    if (uniqueVals.indexOf('NONE') === -1) { // ensures that if "Select All" was selected then again NONE is not pushed, which is a use case of prefill. It was sent already as a unique value to BE in prior unique list and its received back
      uniqueVals.push('NONE')
    }
    setUniqueTextList(uniqueVals)
  }, [selectedCol, filteredRows])

  const selectValue = (val) => {
    let selectedfilterDataCopy = [...selectedfilterData]
    if (val === 'selectAll') {
      if (selectedfilterDataCopy.length === uniqueTextList.length) selectedfilterDataCopy = [] // deselect all
      else selectedfilterDataCopy = [...uniqueTextList] // select all
    } else {
      const idxOfVal = selectedfilterData.indexOf(val)
      if (idxOfVal === -1) {
        selectedfilterDataCopy = [...selectedfilterDataCopy, val] // include a single value
      } else {
        selectedfilterDataCopy = selectedfilterDataCopy.filter(el => el !== val) // exclude a single value
      }
    }
    changeFilterSettings(selectedfilterDataCopy)
  }

  const searchText = ({ target }) => {
    setSearchQuery(target.value)
  }

  return (
    <div className="pl-2 pr-0 sm:pr-2 w-60 flex flex-col">

      {/* search input container */}
      <div className='child-in-center flex-shrink-0' style={{ height: '4rem' }}>
        <input className="inputCommon w-full" placeholder="Search Text" onChange={searchText} value={searchQuery} />
      </div>
      {/* search input container */}

      {/* checklist container */}
      <TextList
        selectValue={selectValue}
        selectedfilterData={selectedfilterData}
        uniqueTextList={uniqueTextList}
        searchQuery={searchQuery}
      />
      {/* checklist container */}

    </div>
  )
}

export default TextFilter
