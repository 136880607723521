// icons
import { useEffect, useState } from 'react'
import filterAppliedIndicatorTick from '../../../../../../../../../images/filterAppliedIndicatorTick.png'

const AppliedIndicator = ({ colEl, updatedFilters }) => {

  // state
  const [indicatorInfo, setIndicatorInfo] = useState({ type: null, count: 0 })

  useEffect(() => {
    let appliedFilter = updatedFilters.find(el => {
      if (el.columnId === colEl.key) return el
      else if (!el.columnId && colEl.key === '-1') return el // HANDLED SNO pre selection
    })
    if (appliedFilter) {
      // requirement is to show "tick" icon if its a NUMBER filter or a DATE RANGE filter
      // and "count" if its a TEXT filter
      const showTickIndicator = appliedFilter.filters.indexOf('BETWEEN') > -1 || appliedFilter.filters.indexOf('NONE') > -1 && appliedFilter.filters.length === 1 || appliedFilter.filters.indexOf('CUSTOM') > -1 || appliedFilter.filters.indexOf('>') > -1 || appliedFilter.filters.indexOf('<') > -1
      if (showTickIndicator) {
        setIndicatorInfo({ type: 'tick', count: 0 })
      } else {
        const count = appliedFilter.filters.length
        setIndicatorInfo({ type: 'count', count })
      }
    } else {
      setIndicatorInfo({ type: null, count: 0 }) // if no filter is applied
    }
  }, [updatedFilters])

  if (!indicatorInfo.type) { // if no filter has been applied so no indication needed
    return <>{indicatorInfo.type}</>
  } else {
    return (
      indicatorInfo.type === 'tick' ?
        <img className="absolute" src={filterAppliedIndicatorTick} style={{ right: '4px', width: '1rem', height: '1rem' }} />
        :
        <div className="flex justify-center items-center text-white absolute rounded-lg" style={{ right: '4px', width: '1rem', height: '1rem', backgroundColor: "#545454", fontSize: 10 }}>
          {indicatorInfo.count}
        </div>
    )
  }
}

export default AppliedIndicator