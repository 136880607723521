
// components
import { useEffect } from 'react'
import DateRangeFilter from './Components/DateRangeFilter'
import NumberFilter from './Components/NumberFilter'
import TextFilter from './Components/TextFilter'

const Filters = ({ selectedCol, changeFilterSettings, selectedfilterData, errorColumns }) => {

  // local
  const isSerialNumberCol = selectedCol.key === '-1'
  if (['date', 'reminder', 'createdAt', 'time'].indexOf(selectedCol.dataType) > -1) {
    return (
      <DateRangeFilter
        selectedfilterData={selectedfilterData}
        changeFilterSettings={changeFilterSettings}
        selectedCol={selectedCol}
        errorColumns={errorColumns}
      />
    )
  } else if (['rupee', 'number', 'formula', 'unit', 'duration'].indexOf(selectedCol.dataType) > -1 || isSerialNumberCol) { // HANDLED SNO 
    return (
      <NumberFilter
        selectedfilterData={selectedfilterData}
        changeFilterSettings={changeFilterSettings}
        selectedCol={selectedCol}
        errorColumns={errorColumns}
      />
    )
  } else if (['String', 'dropDown', 'phoneNumber', 'status', 'label', 'switch', 'createdBy', 'member', 'linkedSheet','scanner', 'mail','url'].indexOf(selectedCol.dataType) > -1) {
    return (
      <TextFilter
        changeFilterSettings={changeFilterSettings}
        selectedfilterData={selectedfilterData}
        selectedCol={selectedCol}
      />
    )
  } else {
    return (
      <div className='w-60 text-center my-5 font-medium text-lg'>Not Available</div>
    )
  }
}

export default Filters;