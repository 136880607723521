import { useState, useEffect } from "react"
import { useSelector } from 'react-redux';

const DropdownBody = ({ setComponentDurationValue, initialFormValue, saveDurationData }) => {

  //redux variables
  const registerData = useSelector(state => state.register)

  // state variables
  const [hour, setHour] = useState(0)
  const [minute, setMinute] = useState(0)

  //local variables
  const { rowDetailsViewType } = registerData

  // set initial values of hour and minute
  useEffect(() => {
    if (initialFormValue && initialFormValue.length > 0) {
      let durationArray = initialFormValue.split(' ')
      if (durationArray.length == 2 && durationArray[1] == 'hr') {
        let hour = durationArray[0]
        setHour(hour)
      } else if (durationArray.length == 2 && durationArray[1] == 'mins') {
        let min = durationArray[0]
        setMinute(min)
      } else if (durationArray.length == 4) {
        let hour = durationArray[0]
        let min = durationArray[2]
        setHour(hour)
        setMinute(min)
      }
    }
  }, [])

  useEffect(() => {
    updateComponentDurationValue()
  }, [hour, minute])

  const onHourValueChange = (e) => {
    let value = e.target.value
    if (value >= 0 && value <= 99) {
      setHour(value)
    }
  }

  const onMinuteValueChange = (e) => {
    let value = e.target.value
    if (value >= 0 && value <= 59) {
      setMinute(value)
    }
  }

  const updateComponentDurationValue = () => {
    let value
    if (hour > 0 && minute > 0) {
      value = `${hour} hr ${minute} mins`
    } else if (hour == 0 && minute > 0) {
      value = `${minute} mins`
    } else if (hour > 0 && minute == 0) {
      value = `${hour} hr`
    } else {
      value = ''
    }
    setComponentDurationValue(value)
  }

  const onInputKeydown = (e) => {
    // To stop handleContainerDivKeyDown from getting triggered
    // as except this, if "Enter" is pressed on "delete", "save" or "cancel" then
    // handleContainerDivKeyDown gets called and editor gets closed. But here we need it opened.
    e.stopPropagation()
    if (e.key === 'Enter') saveDurationData()
  }

  // classes
  const container = `lg:flex ${rowDetailsViewType == 'two' ? 'flex-col' : ''} px-2 md:px-3 lg:px-5 py-8`
  const hrMinContainer = `flex items-center justify-between gap-2 my-2 ${rowDetailsViewType == 'two' ? 'w-full' : 'lg:w-1/2 lg:pr-2'}`
  const hrMinInput = `field focus:ring-transparent w-1/2 ${rowDetailsViewType == 'two' ? '' : 'lg:w-auto'}`
  const hrMinText = `w-1/2 ${rowDetailsViewType == 'two' ? '' : 'lg:w-auto'}`

  return (
    <div className={container} tabIndex="-1">

      {/* Hours */}
      <div className={hrMinContainer}>
        <input tabIndex="0" className={hrMinInput} style={{ paddingRight: 2 }} value={hour}
          onChange={onHourValueChange} onKeyDown={onInputKeydown} min="0" max="99" type="number"
        />
        <span className={hrMinText}>Hours</span>
      </div>
      {/* Hours */}

      {/* Minutes */}
      <div className={hrMinContainer}>
        <input tabIndex="0" className={hrMinInput} style={{ paddingRight: 2 }} value={minute}
          onChange={onMinuteValueChange} onKeyDown={onInputKeydown} min="0" max="59" type="number"
        />
        <span className={hrMinText}>Minutes</span>
      </div>
      {/* Minutes */}
    </div>
  )
}

export default DropdownBody