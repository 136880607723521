// react internal
import React, { useEffect, useState, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useParams } from "react-router-dom";

// redux
import { useDispatch, useSelector } from 'react-redux'
import { openCarousel, uploadImages, resetUploadedImages } from '../../slices/CarouselSlice';

// css
import './image.css'

// icons
import closeIcon from '../../images/image gallery/close.png'
import leftIcon from '../../images/image gallery/left.png'
import rightIcon from '../../images/image gallery/right.png'
import downloadIcon from '../../images/image gallery/download.png'
import deleteIcon from '../../images/image gallery/delete.png'
import cameraIcon from '../../images/image gallery/camera.png'

// utils
import { focusHighlightedCell } from '../../features/Register/Table/Utils'

// component
const Carousel = ({ saveCurrentRowData }) => {

  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)
  const carouselData = useSelector(state => state.carousel)
  const leftSidebarData = useSelector(state => state.leftSidebar)

  // local variables
  const { isCarouselOpen, selectedRow, selectedColKey, staticImages } = carouselData
  const { isAdmin, hasBasicEditPermission, editPermissions } = registerData
  const { activeBusinessId } = leftSidebarData
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(selectedColKey) === -1
  const rowId = selectedRow.row.rowId
  const selectedRowIdx = selectedRow && rowId ? parseInt(document.getElementById(`serialNumbCell/-1/${rowId}`).innerText) - 1 : null;

  // ref variables
  const imageInputRef = useRef(null)

  // state variables
  const [images, setImages] = useState([])
  const [imageRefs, setImageRefs] = useState([])
  const [currentImage, setCurrentImage] = React.useState(0)
  const [b64, setB64] = useState('')

  // close if the esc key is pressed
  useEffect(() => {
    // timeout to resolve all possible factors which can take away Carousel's focus while it is initially being rendered eg., double click on cell
    setTimeout(() => {
      document.getElementById('data-grid-carousel').focus()
    }, 100)
  }, []);

  // When existing/new row data is received, 
  // set them locally and create its refs
  useEffect(() => {
    let existingImages;
    if (staticImages.length > 0) {  // if static images are there
      existingImages = staticImages
    } else { // pick the dynamic images from the register data
      existingImages = registerData.rows[selectedRowIdx][`${selectedColKey}_details`]
    }
    const imagesUrlArray = (existingImages.map(img => {
      return img.value
    }))
    // This makes sure that if the last image is deleted then,
    // currentImage should be reduced by 1 as the index which it is holding
    // is of the image which was the last element in the array and is deleted. 
    if (currentImage === imagesUrlArray.length) {
      setCurrentImage(currentImage - 1)
    }
    setImages(imagesUrlArray)
    appointRefs(imagesUrlArray)

  }, [registerData.rows, staticImages])

  // When the url of all the images is recieved from the server when user clicked Add More Images.
  useEffect(() => {
    if (carouselData.uploadedImages.length > 0) {
      const existingImages = registerData.rows[selectedRowIdx][`${selectedColKey}_details`]
      let newImagesArray = [...existingImages]
      carouselData.uploadedImages.forEach((el) => {
        if (el.success) newImagesArray.push({ value: el.message })
      })
      saveCurrentRowData(newImagesArray, `${selectedColKey}_details`, rowId, registerData.rows)
      dispatch(resetUploadedImages())
    }
  }, [carouselData.uploadedImages])

  // assign refs to images whenever initialised or they array is updated
  const appointRefs = (imagesUrlArray) => {
    let refs = imagesUrlArray.reduce((acc, val, i) => {
      acc[i] = React.createRef();
      return acc;
    }, {});
    setImageRefs(refs)
  }

  // a simple image scroll function using ref
  const scrollToImage = i => {
    setCurrentImage(i);
    setTimeout(() => {
      imageRefs[i].current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }, 10) // as arrow keys were not working without this timer
  };

  const nextImage = () => {
    if (currentImage >= images.length - 1) {
      scrollToImage(0);
    } else {
      scrollToImage(currentImage + 1);
    }
  };

  const previousImage = () => {
    if (currentImage === 0) {
      scrollToImage(images.length - 1);
    } else {
      scrollToImage(currentImage - 1);
    }
  };

  // left and right buttons
  const sliderControl = isLeft => (
    <button
      type="button"
      onClick={isLeft ? previousImage : nextImage}
      className={`absolute text-2xl z-10 rounded-full flex items-center justify-center ${isLeft ? 'left-12' : 'right-12'}`}
      style={{ top: '47%' }}
      id={isLeft ? 'left-arrow-data-grid-carousel' : 'right-arrow-data-grid-carousel'}
    >
      <span role="img" aria-label={`Arrow ${isLeft ? 'left' : 'right'}`}>
        {isLeft ? <img src={leftIcon} alt="left" /> : <img src={rightIcon} alt="right" />}
      </span>
    </button>
  );

  // close carousel on any closing button
  const closeCarousel = () => {
    focusHighlightedCell()
    dispatch(openCarousel(false))
  }

  // delete images
  const onDeleteClick = () => {
    let imgsCopy = [...registerData.rows[selectedRowIdx][`${selectedColKey}_details`]]
    imgsCopy.splice(currentImage, 1)
    saveCurrentRowData(imgsCopy, `${selectedColKey}_details`, rowId, registerData.rows)
    if (imgsCopy.length === 0) { // the catousel should close after the last image is deleted
      dispatch(openCarousel(false))
    }
  }

  // triggers when files are selected by user from Add Image btn
  const onAddImageChange = (e) => {
    let files = e.target.files
    dispatch(uploadImages({ files, communityId: activeBusinessId }))
  }

  const keyHandler = ({ keyCode }) => {
    if (!isCarouselOpen) return;
    if (keyCode === 37) {
      previousImage()
      return
    } else if (keyCode === 39) {
      nextImage()
      return
    } else if (keyCode === 27) {
      closeCarousel()
    }
  };

  return (
    <>
      {isCarouselOpen && createPortal(
        <div tabIndex={1} onKeyDown={keyHandler} id='data-grid-carousel' className="flex justify-center">

          {/*Backdrop*/}
          <div className={`fixed inset-0 z-30  'opacity-100'`} style={{ background: "rgba(0,0,0,0.4)", zIndex: '102' }}></div>

          <div className="fixed bg-white p-6 rounded" style={{ zIndex: "103", top: "5vh", bottom: "5vh", left: "5vh", right: "5vh" }}>

            {/*Close button*/}
            <div className="absolute z-10 top-2 right-2"><button onClick={closeCarousel}><img src={closeIcon} alt="close" /></button></div>

            {/*buttons and text*/}
            <div className="flex justify-between items-center mx-10" style={{ height: "10%" }}>
              <div style={{ fontSize: "36px" }}>Photos</div>
              <div className="flex gap-4" style={{ fontSize: "18px" }}>
                <a href={images[currentImage]} style={{ border: "1px solid #E3F0FF" }}>
                  <div className="flex gap-2 items-center justify-center rounded-sm py-2 px-3 text-black w-36">
                    <img src={downloadIcon} alt="" />Download
                  </div>
                </a>
                {
                  canEdit && staticImages.length === 0 &&
                  <button onClick={onDeleteClick} className="flex gap-2 items-center justify-center rounded-sm py-2 px-3 w-36" style={{ border: "1px solid #E3F0FF", color: "#FC1055" }}>
                    <img src={deleteIcon} alt="" />
                    Delete
                  </button>
                }
              </div>
            </div>

            {/*Images*/}
            <div className="h-4/5 flex flex-col gap-4 justify-center items-center">
              <div className="carousel object-contain" style={{ height: "80%", maxWidth: "70%" }}>
                {sliderControl(true)}
                {images.map((img, i) => (
                  <div className="w-full h-full flex-shrink-0 flex justify-center items-center" key={`images-carousel-${i}`} ref={imageRefs[i]}>
                    <img src={img} className="h-full w-auto max-w-full" />
                  </div>
                ))}
                {sliderControl()}
              </div>
              <div className="flex gap-2">
                {
                  images.map((img, i) => {
                    return (
                      <img onClick={() => { scrollToImage(i) }} className="h-8 w-10 cursor-pointer rounded-sm" src={img} />
                    )
                  })
                }
              </div>
            </div>

            {/*Add image button*/}
            {
              canEdit && staticImages.length === 0 &&
              <div className="flex justify-center items-center" style={{ height: "7%", fontSize: "1.2rem" }}>
                <input ref={imageInputRef} type="file" onChange={onAddImageChange} id={`add-images-btn-carousel-${selectedColKey}`} hidden multiple />
                <label htmlFor={`add-images-btn-carousel-${selectedColKey}`} className="flex gap-2 items-center justify-center px-8 py-2 rounded text-black relative" style={{ background: "#E3F0FF" }}>
                  {
                    carouselData.isLoading || registerData.isLoading ?
                      <div className="animate-spin rounded-full h-7 w-7 border-b-2 border-black m-auto absolute left-0"></div>
                      :
                      <img src={cameraIcon} alt="" />
                  }
                Add Image
              </label>
              </div>
            }


          </div>
        </div>, document.body
      )
      }
    </>

  )
};

export default Carousel
