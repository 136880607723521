import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

// components
import CustomInputDropdown from "../../../../../../partials/CustomInputDropdown"

// slices
import { fetchRegisterDataAncestry } from "../../../../../../slices/RegisterSlice"

// utils
import { getColumnIcon } from "../../../../Table/Utils"

const SelectColumnToLink = ({
  selectedRegister,
  selectedPage,
  selectedCol,
  setSelectedCol,
  columnInfo
}) => {

  // redux variables
  const dispatch = useDispatch()

  // state variables
  const [showColMenu, setShowColMenu] = useState(false)
  const [cols, setCols] = useState([])
  const [colsLoading, setColsLoading] = useState(false)
  const [colsInitiallyLoaded, setColsInitiallyLoaded] = useState(false)

  // local variables
  const datatypesAllowedToLink = ['String', 'date', 'rupee', 'image', 'phoneNumber', 'number', 'formula', 'location', 'dropDown', 'mail', 'status', 'createdAt', 'createdBy', 'unit']
  // signature to be added later -  14 datatypes allowed now

  useEffect(() => {
    setSelectedCol('')
    if (selectedPage == '') {
      setCols([])
      return
    }
    const fetchColumnsData = async () => {
      setColsLoading(true)
      try {
        const result = await dispatch(fetchRegisterDataAncestry({ registerId: selectedRegister.registerId, sheetId: selectedPage.sheetId }))
        const columns = result.payload.columns.filter((col) => {
          return col.key != '-1'
        })
        setCols(columns)
      } catch (error) {
        console.error(error)
        // handle error
      } finally {
        setColsLoading(false)
      }
    }
    fetchColumnsData()
  }, [selectedPage])

  // pre fill columns select menu if only one column exists
  useEffect(() => {
    if (cols.length == 1 && columnInfo.dataType != 'linkedSheet') {
      setSelectedCol(cols[0])
    }
    if (!colsInitiallyLoaded && cols.length > 0) {
      setColsInitiallyLoaded(true)
    }
  }, [cols])

  // Pre-fill selected col if linked col is updated to linked col. Columns is set to columns of previous linked col.
  // This is done only once, intially.
  useEffect(() => {
    if (colsInitiallyLoaded && columnInfo.dataType == 'linkedSheet') {
      cols.forEach((col) => {
        if (col.key == columnInfo.linkedColumnId) {
          setSelectedCol(col)
        }
      })
    }
  }, [colsInitiallyLoaded])

  const getColFieldValue = () => {
    return selectedCol ?
      <div className="flex items-center gap-3">
        <div className="w-10 child-in-center"><img src={getColumnIcon(selectedCol.dataType)} className='h-5' /></div>
        {selectedCol.name}
      </div>
      : 'Select Column'
  }

  return (
    <div className="m-6">

      <div className='text-lg font-semibold mb-3 flex gap-3 items-center'>
        Select Column
        {colsLoading && <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-600"></div>}
      </div>
      {
        selectedCol == '' && <div className='text-sm font-light mb-3'>You can select the column from below</div>
      }

      {/* Columns menu */}
      <CustomInputDropdown
        fieldValue={getColFieldValue()}
        inputType={`div`}
        container={`screen`}
        inputClasses={`field ${selectedCol ? 'font-semibold' : ''} ${cols.length == 0 ? 'cursor-not-allowed' : ''}`}
        inputStyles={{ fontSize: '1rem' }}
        buttonsInfo={{ showButtonsSection: false }}
        showRemoveIcon={false}
        showDropDownMenu={showColMenu}
        setShowDropDownMenu={setShowColMenu}
        onFieldClick={() => { if (cols.length == 0) return }}
        isDisabled={cols.length == 0}
      >
        <ul style={{ maxHeight: '30vh' }} className='overflow-auto'>
          {
            cols && cols.map((col, i) => {
              if (!datatypesAllowedToLink.includes(col.dataType)) return
              return (
                <li
                  key={`${col.name} ${i}`}
                  onClick={() => { setSelectedCol(col); setShowColMenu(false) }}
                  className='list-none px-6 cursor-pointer hover:bg-gray-100 transition-colors delay-50'

                  style={{ color: '#174184' }}>
                  <div className="border-b py-3 flex items-center gap-3">
                    <img src={getColumnIcon(col.dataType)} className='h-5' />
                    {col.name}
                  </div>
                </li>
              )
            })
          }
        </ul>
      </CustomInputDropdown>
      {/* Columns menu */}

    </div>
  )
}

export default SelectColumnToLink