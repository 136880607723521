import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux'

// slices
import { updateRegisterData, setRowDeleteCompleted } from '../../../slices/RegisterSlice'
import { updateRowDetailState } from '../../../slices/RowDetailSlice'

// utils
import { computeFormulaColVal, isRowEmpty } from '../../../utils/Utils';
import DeviceMeta from '../../../utils/DeviceDetector'
import { getEmptyRow } from '../Table/Utils'

// components
import Header from './Components/Header'
import CancelButton from './Components/CancelButton'
import SaveButton from './Components/SaveButton'
import RowDetailForm from './Components/RowDetailForm'
import Activities from './Components/Activities'
import Navbar from "./Components/Navbar"

// css 
import './index.css'

// third party
const moment = require('moment');

const AddrowForm = ({ isOpen, closeAddrowForm, shouldAllowNewEntry }) => {

  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)
  const rowDetailData = useSelector(state => state.rowDetail)

  // local variables
  const { columns, sheetId, rowIndex, permissions, rowDeleteCompleted } = registerData
  const originalRowData = registerData.rows
  const initRow = getEmptyRow(columns)
  const { activeActivityTab } = rowDetailData
  const row = rowIndex > -1 ? originalRowData[rowIndex] : initRow
  let scrollableDataForm

  // state variables
  const [edited, setEdited] = useState(false)
  const [formData, setFormData] = useState(initRow)
  const isNewEntry = isRowEmpty(row) ? true : false
  // Initialize state with the current window height
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  // Web View: when we open the row and if it is not empty then the comment section should open by default
  // Mobile View: Row details should open
  useEffect(() => {
    //if (!isNewEntry && !DeviceMeta.isMobileOrTablet()){
    dispatch(updateRowDetailState([{ key: "activeActivityTab", value: 'comments' }]))
    //} 
    // update window height property
    // Handler to call on window resize
    function handleResize() {
      // Set window height to the innerHeight of the browser
      setWindowHeight(window.innerHeight);
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  // extracting form data for Add Row Form Popup
  useEffect(async () => {
    scrollableDataForm = document.getElementById("form-data")
    if (rowIndex !== -1) {
      setFormData(JSON.parse(JSON.stringify(row)))
    }
    scrollableDataForm.scrollTop = 0
  }, [rowIndex, sheetId])

  // reset rowDeleteCompleted to false and close AddRowForm
  useEffect(() => {
    if (rowDeleteCompleted) {
      dispatch(setRowDeleteCompleted(false))
      closeAddrowForm()
    }
  }, [rowDeleteCompleted])

  // Changes selected row data ( being displayed in the Add Row Form pop up)
  // locally if any value change is received from it.
  const handleAddRowFormChange = ({ colkey, value, colkeyDetail, valueDetail }) => {
    const key = colkey
    const keyDetail = colkeyDetail
    let newCellValue = value === null ? '' : value
    let newCellDetailValue = valueDetail === null ? '' : valueDetail
    setEdited(true)
    if (colkey && colkeyDetail) {
      setFormData({ ...formData, [key]: newCellValue, [keyDetail]: newCellDetailValue })
    } else if (!colkey && colkeyDetail) {
      setFormData({ ...formData, [keyDetail]: newCellDetailValue })
    } else if (colkey && !colkeyDetail) {
      setFormData({ ...formData, [key]: newCellValue })
    }
  }

  const handleAddRowFormChangeNew = (keysAndValues) => {
    // keysAndValues = {'key1': '1', 'key1_details': 'details1', 'key2': '2', 'key2_details': 'details2'}
    const copyOfFormData = { ...formData }
    Object.keys(keysAndValues).forEach((key) => {
      copyOfFormData[key] = keysAndValues[key]
    })
    setEdited(true)
    setFormData({ ...copyOfFormData })
  }

  const saveCurrentRowData = async () => {
    let newRowDataForLocalChange = JSON.parse(JSON.stringify(formData)) // this holds the row updated data which might later come from api but its currently done locally
    let formulaObj = {};
    let evaluatedColValsForFormula = {}; // => { colNumber_1: value_after_calc, colNumber_2: value_after_calc }
    let columnsToBeUpdatedLocally = {} // like createdAt, createdBy
    let rowIdxToBeFocused;

    if (!newRowDataForLocalChange.rowId) newRowDataForLocalChange.rowId = 'LAST_0'
    columns.forEach((colEl, index) => {
      if (colEl.dataType === "formula") { // cumulate all formulas
        formulaObj[colEl.key] = colEl.formula // index-1 to ignore S.No column
      } // ==> { colNumber_1: ['A','*','B'], colNumber_2: ['c','+','B'] }
      if (colEl.dataType === "createdAt") { // needs a local update if doesnt exists 
        if (newRowDataForLocalChange[colEl.key].length === 0) {
          columnsToBeUpdatedLocally[colEl.key] = moment(new Date()).format("DD MMM YYYY, h:mm a");
        }
      }
      if (colEl.dataType === "createdBy") { // needs a local update if doesnt exists 
        if (newRowDataForLocalChange[colEl.key].length === 0) {
          columnsToBeUpdatedLocally[colEl.key] = localStorage.getItem('name')
        }
      }
    })
    // {-----------------formula based work starts here --------------}
    if (Object.keys(formulaObj).length > 0) {
      // Calculate for each column so that inter dependent cells upon new values, 
      // should always consider new row in the next loop eg., first 'hr min' calculation then wage calculation based upon
      // new cell value of.
      const prevsRow = originalRowData[rowIndex - 1]
      Object.keys(formulaObj).forEach((key) => {
        evaluatedColValsForFormula = computeFormulaColVal(key, formulaObj[key], newRowDataForLocalChange, prevsRow, columns)
        newRowDataForLocalChange = getUpdatedCopyOfRow(newRowDataForLocalChange, evaluatedColValsForFormula)
      })
    }
    // {-----------------formula based work ends here --------------}
    // {-----------------any column needing a local update work starts here --------------}
    if (Object.keys(columnsToBeUpdatedLocally).length > 0) {
      newRowDataForLocalChange = getUpdatedCopyOfRow(newRowDataForLocalChange, columnsToBeUpdatedLocally)
    }

    let newRowDataForApi = []
    columns.forEach(function (colEl) {
      const columnKey = colEl.key
      if (columnKey !== "-1") {
        if (
          JSON.stringify(newRowDataForLocalChange[colEl.key + "_details"]) !== JSON.stringify(row[colEl.key + "_details"])
          || String(newRowDataForLocalChange[colEl.key]) !== String(row[colEl.key])
        ) {
          newRowDataForApi.push({
            columnId: columnKey,
            rowId: newRowDataForLocalChange.rowId,
            detailedValue: newRowDataForLocalChange[colEl.key + "_details"],
            value: newRowDataForLocalChange[colEl.key],
            sheetId: registerData.sheetId,
            newEntry: isNewEntry
          })
        }
      }
    })
    if (newRowDataForApi.length == 0) {
      setEdited(false)
    } else {
      dispatch(updateRegisterData({
        data: newRowDataForApi,
        rowIndex: rowIndex,
        formData: newRowDataForLocalChange
      }))
    }
    if (shouldAllowNewEntry) {
      const newEntryRowId = newRowDataForLocalChange.rowId
      rowIdxToBeFocused = newEntryRowId !== 'LAST_0' ? rowIndex : originalRowData.length - 1
    }
    closeAddrowForm(rowIdxToBeFocused)
  }

  const getUpdatedCopyOfRow = (prevsRow, updateObj) => {
    let copyOfPrevsRow = JSON.parse(JSON.stringify(prevsRow))
    // Insert new value corresponding to the keys present in the updateObj ( also containing the corresponding values )
    // into prevsRow.
    // STRUCTURE: copyOfPrevsRow ==> { 1: 'old value'}, updateObj ==> { 1: 'new value'}
    Object.keys(updateObj).forEach((key) => {
      copyOfPrevsRow[key] = updateObj[key]
      if (updateObj[key] === '') {
        copyOfPrevsRow[key + '_details'] = []
      }
    })
    return copyOfPrevsRow
  }

  const deleteEntry = (colkey, detailValObj = {}) => {
    setFormData({ ...formData, [colkey]: '', ...detailValObj })
    setEdited(true)
  }

  return (
    <div
      className={`flex justify-center items-center fixed h-screen top-0 bottom-0 left-0 w-full`}
      style={{ zIndex: '100' }}
    >
      <div
        className={`flex justify-center items-center absolute h-screen top-0 bottom-0 left-0 w-full`}
        style={{ background: 'rgba(0,0,0,0.4)' }}
        onClick={() => { closeAddrowForm() }}
      >
      </div>
      <div
        className={`slide_down mt-1 w-full sm:w-11/12 h-full sm:h-5/6 flex flex-col items-center shadow-md transform transition-all duration-200 ease-out ${activeActivityTab ? 'md:w-9/12' : 'md:w-7/12'}`}
        style={{ height: 0.98 * windowHeight }}
      >
        {/*header*/}
        <div
          style={{ height: "10%" }}
          className="flex justify-center items-center rounded-lg rounded-t-none w-full"
        >
          <Header
            rowId={formData.rowId}
            closeAddrowForm={closeAddrowForm}
            shouldAllowNewEntry={shouldAllowNewEntry}
          />
        </div>

        {/* navbar for mobile and tablet */}
        <div style={{ height: '5%' }} className='w-full sm:hidden'>
          <Navbar />
        </div>
        {/* navbar for mobile and tablet */}

        {/*body*/}
        <div
          className="flex flex-col justify-center h85p sm:h-9/10 items-center sm:rounded-lg sm:rounded-t-none w-full bg-white"
        >
          {/* ---------------- Form and CommentHistory section starts here ----------------- */}
          <div className="w-full flex h-full">
            {/* ---------------- Form starts here ----------------- */}
            <div className={`rowDetail overflow-y-auto px-6 pt-2 pb-4 ${activeActivityTab ? 'hidden sm:block sm:w-7/12' : 'w-full'}`} id="form-data">
              <RowDetailForm
                formData={formData}
                shouldAllowNewEntry={shouldAllowNewEntry}
                rowIndex={rowIndex}
                rowsLength={originalRowData.length}
                edited={edited}
                handleChange={handleAddRowFormChange}
                handleChangeNew={handleAddRowFormChangeNew}
                setEdited={setEdited}
                deleteEntry={deleteEntry}
              />
              {edited &&
                (
                  <div className={`absolute bottom-0 left-0 flex items-center justify-between h-12 gap-5 text-base px-10 bg-white ${activeActivityTab ? 'w-3/5' : 'w-full'}`}>
                    <CancelButton row={row} setFormData={setFormData} setEdited={setEdited} />
                    {
                      (rowIndex === -1 && permissions[0] === "add") ?
                        (<SaveButton text="Add" saveCurrentRowData={saveCurrentRowData} />) :
                        (<SaveButton text="Save" saveCurrentRowData={saveCurrentRowData} />)
                    }
                  </div>
                )
              }
            </div>
            {/* ---------------- Form ends here ----------------- */}
            {/* ---------------- CommentHistory starts here ----------------- */}
            {
              activeActivityTab &&
              <div id="add-new-entry/outer-container/activities" className={` w-full sm:w-5/12`} style={{ height: "100%" }}>
                <div id="add-new-entry/inner-container/activities" className="h-full rounded-br-lg" style={{ border: "1px solid #D4D9E6" }}>
                  <Activities />
                </div>
              </div>
            }
            {/* ---------------- CommentHistory ends here ----------------- */}
          </div>
          {/* ---------------- Form and CommentHistory section ends here ----------------- */}
        </div>
      </div>
    </div>
  )
}

export default AddrowForm



