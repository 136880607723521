import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchRegisterDetails = createAsyncThunk('/registerDetails', async ({ registerId }) => {
  const response = await axios.get('/v3/register/registerInfo', { params: { registerId } })
  return response.data.data
})

export const renameRegister = createAsyncThunk('register/rename', async ({ registerId, name, oldName, registers }) => {
  const response = await axios.post('/v3/register/rename', { registerId, name })
  if (response.data.success) return { name, oldName, registers }
})

export const renameRegisterDescription = createAsyncThunk('register/description', async ({ registerId, description }) => {
  const response = await axios.post('/v3/register/updateDescription', { registerId, description })
  if (response.data.success) return { description }
})

export const getNotifications = createAsyncThunk('register/notifications', async ({ businessId }) => {
  const response = await axios.get('/v3/business/notifications', { params: { businessId } })
  return response.data.data
})

export const rightSidebarSlice = createSlice({
  name: 'rightSidebar',
  initialState: {
    registerName: '',
    businessId: '',
    registerId: '',
    registerDescription: '',
    teamMembers: '',
    isAdmin: '',
    noOfMembers: '',
    avatar: '',
    themeCode: '',
    notifications: null,
    noOfNewNotifications: 0,
    error: '',
    rightSidebarOpen: false,
    body: null,
    isLoading: false
  },
  reducers: {
    toggleRightSidebarOpen: (state, action) => {
      state.rightSidebarOpen = action.payload.rightSidebarOpen
      state.body = action.payload.body
    }
  },
  extraReducers: {
    [fetchRegisterDetails.pending]: (state, action) => {
      state.isLoading = true
    },
    [fetchRegisterDetails.fulfilled]: (state, action) => {
      let p = action.payload
      if (!p) return
      state.registerName = p.name
      state.businessId = p.businessId
      state.registerId = p.registerId
      state.registerDescription = p.description
      state.teamMembers = p.teamMembers
      state.isAdmin = p.isAdmin
      state.noOfMembers = p.teamMembers.length
      state.avatar = p.avatar
      state.themeCode = p.themeCode
      state.error = ''
      state.isLoading = false
    },
    [fetchRegisterDetails.rejected]: (state, action) => {
      state.error = action.error.message
      state.isLoading = false
    },
    [renameRegister.fulfilled]: (state, action) => {
      let p = action.payload
      state.registerName = p.name
    },
    [renameRegisterDescription.fulfilled]: (state, action) => {
      let p = action.payload
      state.registerDescription = p.description
    },
    [getNotifications.fulfilled]: (state, action) => {
      state.notifications = action.payload
      let number = 0
      action.payload.forEach(notification => {
        if (!notification.isSeen) {
          number = number + 1
        }
      })
      state.noOfNewNotifications = number

    }
  }
})

export const { toggleRightSidebarOpen } = rightSidebarSlice.actions;
export default rightSidebarSlice.reducer;