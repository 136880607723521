import { useState } from 'react'

// third party
import TimeKeeper from 'react-timekeeper'
import moment from 'moment';

//components
import CustomInputDropdown from '../../../../../../../../../../../partials/CustomInputDropdown.js'
import TimeUnitSelector from '../../../../../../../../../../Register/Form/Components/RowDetailForm/Components/Time/TimeUnitSelector.js'

// icons 
import crossIcon from '../../../../../../../../../../../images/add-edit row dialog/cross-gray.png'
import colonIcon from '../../../../../../../../../../../images/add-edit row dialog/time_colon.png'
import dropDownIcon from '../../../../../../../../../../../images/add-edit row dialog/drop-down.png'

const TimeRange = ({ startRange, endRange, onRangeChange, selectedCol, getChecked, selectCheck }) => {

  // state variables
  const [showStartTimeDialog, setShowStartTimeDialog] = useState(false)
  const [showEndTimeDialog, setShowEndTimeDialog] = useState(false)

  // local variables
  const minsTenToSixty = []
  for (let i = 10; i < 60; i++) {
    minsTenToSixty.push(String(i))
  }
  const hoursArray = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
  const minutesArray = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', ...minsTenToSixty]
  const meridiemArray = ['AM', 'PM']

  const onSaveButtonClick = (i) => {
    let hourContainerDiv = document.getElementById(`filter_time_input${i}_hour_container_div_${selectedCol.key}`)
    let minContainerDiv = document.getElementById(`filter_time_input${i}_min_container_div_${selectedCol.key}`)
    let meridiemContainerDiv = document.getElementById(`filter_time_input${i}_meridiem_container_div_${selectedCol.key}`)
    let hour = hourContainerDiv.scrollTop / 48 + 1
    let min = minContainerDiv.scrollTop / 48
    let mer = meridiemContainerDiv.scrollTop / 48 == 0 ? 'AM' : 'PM'
    let time = `${hour}:${min} ${mer}`
    onRangeChange(moment(time, 'hh:mm a').format('h:mm A'), i == '1' ? 'startRange' : 'endRange')
    closeDropdown(i)
  }

  const closeDropdown = (i) => {
    if(i == '1') setShowStartTimeDialog(false)
    else setShowEndTimeDialog(false)
  }

  const onRemoveIconClick = (i) => {
    onRangeChange(null, i == '1' ? 'startRange' : 'endRange')
  }

  // to scroll to an hour/min/meridiem in dropdown
  const scrollTo = (type, value) => {
    let myElement = document.getElementById(`${type}_${value}_${selectedCol.key}`)
    let topPos = myElement.offsetTop
    document.getElementById(`${type}_container_div_${selectedCol.key}`).scrollTo({ top: topPos - 48, behavior: 'smooth' })
  }

  return (
    <div class='child-in-center flex-col md:flex-row pb-2'>

      <div className='w-full md:w-1/2 md:pr-2 mb-2 md:mb-0'>
        <div>From</div>
        <CustomInputDropdown
          fieldValue={startRange ? startRange.toUpperCase() : 'Select'}
          inputType={`div`}
          container={`grid`}
          inputClasses={`field w-full`}
          inputStyles={{}}
          dropdownClasses={``}
          dropdownStyles={{ marginTop: 8, minWidth: '15rem' }}
          outerDivClasses={`w-full h-full`}
          buttonsInfo={{ showButtonsSection: true, showCancelButton: true, showSaveButton: true, onSaveButtonClick: () => onSaveButtonClick('1') }}
          showRemoveIcon={startRange}
          onRemoveIconClick={() => onRemoveIconClick('1')}
          showDropDownMenu={showStartTimeDialog}
          setShowDropDownMenu={setShowStartTimeDialog}
          onFieldClick={selectCheck}
        >
          <div className='relative mx-5 flex justify-around' style={{ height: '9rem', color: '#061119' }}>

            <div className='absolute w-full top-1/3 rounded' style={{ height: '3rem', backgroundColor: '#E4EFF0' }}></div>

            {/* Hours */}
            <TimeUnitSelector type={'filter_time_input1_hour'} timeUnitArray={hoursArray} colkey={selectedCol.key} scrollTo={scrollTo} />

            {/* Colon */}
            <div className='h-full flex items-center px-3'>
              <img src={colonIcon} className='w-28 relative bottom-0.5' />
            </div>

            {/* Minutes */}
            <TimeUnitSelector type={'filter_time_input1_min'} timeUnitArray={minutesArray} colkey={selectedCol.key} scrollTo={scrollTo} />

            {/* Meridiem */}
            <TimeUnitSelector type={'filter_time_input1_meridiem'} timeUnitArray={meridiemArray} colkey={selectedCol.key} scrollTo={scrollTo} />

          </div>
        </CustomInputDropdown>
      </div>

      <div className='w-full md:w-1/2 md:pl-2'>
        <div>To</div>
        <CustomInputDropdown
          fieldValue={endRange ? endRange.toUpperCase() : 'Select'}
          inputType={`div`}
          container={`grid`}
          inputClasses={`field w-full`}
          inputStyles={{}}
          dropdownClasses={``}
          dropdownStyles={{ marginTop: 8, minWidth: '15rem' }}
          outerDivClasses={`w-full h-full`}
          buttonsInfo={{ showButtonsSection: true, showCancelButton: true, showSaveButton: true, onSaveButtonClick: () => onSaveButtonClick('2') }}
          showRemoveIcon={endRange}
          onRemoveIconClick={() => onRemoveIconClick('2')}
          showDropDownMenu={showEndTimeDialog}
          setShowDropDownMenu={setShowEndTimeDialog}
          onFieldClick={selectCheck}
        >
          <div className='relative mx-5 flex justify-around' style={{ height: '9rem', color: '#061119' }}>

            <div className='absolute w-full top-1/3 rounded' style={{ height: '3rem', backgroundColor: '#E4EFF0' }}></div>

            {/* Hours */}
            <TimeUnitSelector type={'filter_time_input2_hour'} timeUnitArray={hoursArray} colkey={selectedCol.key} scrollTo={scrollTo} />

            {/* Colon */}
            <div className='h-full flex items-center px-3'>
              <img src={colonIcon} className='w-28 relative bottom-0.5' />
            </div>

            {/* Minutes */}
            <TimeUnitSelector type={'filter_time_input2_min'} timeUnitArray={minutesArray} colkey={selectedCol.key} scrollTo={scrollTo} />

            {/* Meridiem */}
            <TimeUnitSelector type={'filter_time_input2_meridiem'} timeUnitArray={meridiemArray} colkey={selectedCol.key} scrollTo={scrollTo} />

          </div>
        </CustomInputDropdown>
      </div>
    </div>
  )
}

export default TimeRange