//components
import ImagesList from "./ImagesList"
import AttachmentsList from "./AttachmentsList"

const DeleteHistoryItem = ({ item }) => {

  // local variables
  const { columnName, userName, userProfilePic, time, oldValue, dataType, oldDetailedValue } = item

  const getDeletedItems = () => {

    if (dataType == 'image') {
      return (
        <div className="mb-3 mt-1 overflow-x-auto no-scrollbar w-full">
          <ImagesList imagesArray={oldDetailedValue} />
        </div>
      )
    }

    if (dataType == 'attachment') {
      return (
        <div className="pb-1">
          <AttachmentsList attachmentsArray={oldDetailedValue} />
        </div>
      )
    }

    return (
      <div style={{ color: '#586C9D', fontSize: '0.85rem', fontWeight: '600' }} className='truncate mt-4 mb-5'>
        {oldValue}
      </div>
    )

  }

  return (
    <div className="flex pt-5" style={{ borderBottom: '0.5px solid rgba(182, 182, 182, 0.6)' }}>

      {/* User profile Pic */}
      <div className="w-2/12 flex justify-center">
        <img src={userProfilePic} style={{ height: '2rem', width: '2rem' }} className='rounded-full' />
      </div>
      {/* User profile Pic */}

      <div className="w-10/12 pl-2">

        <div style={{ color: '#454545', fontSize: '0.93rem', fontWeight: '600' }}>
          {userName} removed from <span style={{ color: '#044089' }}>{columnName}</span>
        </div>

        <div style={{ color: '#999999', fontSize: '0.85rem' }}>{time}</div>
        {getDeletedItems()}

      </div>
    </div>
  )
}

export default DeleteHistoryItem