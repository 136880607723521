// components
import Header from "./components/Header"
import LayoutSetting from "./components/LayoutSetting"

const ColumnsLayoutSetting = () => {
  return (
    <div>
      <Header />
      <LayoutSetting />
    </div>
  )
}

export default ColumnsLayoutSetting