import { useDispatch, useSelector } from 'react-redux'
import { addEmptyRowToSheet } from '../../../../../slices/RegisterSlice'

import addIcon from '../../../../../images/plusWhite.png'
import rowExpandIcon from '../../../../../images/rowExpandIcon.png'

// utils
import DeviceMeta from '../../../../../utils/DeviceDetector'

const Col1Formatter = ({ rowProperties, columnEl, canAdd }) => {

  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  // local variables
  const colKey = columnEl.key
  // const value = rowProperties.row[colKey]
  const rowId = rowProperties.row.rowId
  const rowIdx = rowProperties.rowIdx
  const sheetId = registerData.sheetId
  const filteredRows = registerData.filteredRows
  const rows = registerData.rows
  const isLastRow = rowIdx == filteredRows.length - 1
  const value = rows.findIndex(row => row.rowId == rowId) + 1
  const topRowId = registerData.rows[registerData.rows.length - 2] ? registerData.rows[registerData.rows.length - 2].rowId : ''

  // function triggered on + icon
  const handleClick = async () => {
    if (!canAdd) {
      alert("Permission Denied")
      return
    }
    dispatch(addEmptyRowToSheet({ sheetId, topRowId, count: 5, idxToBeScrolled: registerData.rows.length - 1 }))
  }

  // show + icon
  if (isLastRow) {
    return (
      <div
        className="absolute w-full h-full left-0 flex justify-center items-center"
        style={{ backgroundColor: "#586C9E" }}
      >
        <button onClick={(e) => { e.preventDefault(); handleClick(); }} className="w-full h-full flex justify-center items-center">
          <img src={addIcon} />
        </button>
      </div>
    )
  }

  // show normal S.No value
  return (
    <div
      className="w-full h-full left-0 flex justify-center items-center relative"
      style={{ backgroundColor: "#F3F3F3", fontSize: '0.95rem', color: '#545454' }}
      id={`serialNumbCell/${colKey}/${rowId}`}
    >
      <button className="w-full">
        {value}
      </button>
      {
        !DeviceMeta.isMobile() &&
        <span id={`serialNumbCell/${colKey}/${rowId}/expandIcon`} className="expandIconSpan absolute right-0.5 bottom-0.5">
          <img className="w-4 cursor-pointer" src={rowExpandIcon} />
        </span>
      }
    </div>
  )
};

export default Col1Formatter