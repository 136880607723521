import React, { useEffect } from 'react';

const StaticBorderlessCell = ({ rowProperties, columnEl, canAdd }) => {
  const rowIdx = rowProperties.rowIdx
  useEffect(() => {
    document.getElementById(`${rowIdx}borderLessCell`).parentNode.classList.add("borderLessCell")
  })

  return (
    <div
      id={`${rowIdx}borderLessCell`}
      className="absolute w-full h-full left-0 flex justify-center items-center borderLessCell"
      style={{backgroundColor: 'rgb(245, 245, 245)'}}
    >
    </div>
  )
};

export default StaticBorderlessCell