import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

// components
import PortalPopup from "../../../../../partials/PortalPopup"
import Header from "../../../../../partials/PortalPopup/Header"
import SelectColumnToLink from "./Components/SelectColumnToLink"
import SelectRegisterToLink from "./Components/SelectRegisterToLink"

// slices
import { fetchRegisterPagesAncestry, updateColumn } from "../../../../../slices/RegisterSlice"

const LinkToAnotherRegisterPopup = ({ setShowLinkToAnotherRegisterPopup, colKey, colName, columnInfo }) => {

  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  // state variables
  const [selectedRegister, setSelectedRegister] = useState('')
  const [pages, setPages] = useState([])
  const [selectedPage, setSelectedPage] = useState('')
  const [selectedCol, setSelectedCol] = useState('')
  const [pagesLoading, setPagesLoading] = useState(false)

  // local variables
  const { sheetId } = registerData

  useEffect(() => {
    if (selectedRegister == '') return
    setSelectedPage('')
    fetchPagesData()
  }, [selectedRegister])

  const fetchPagesData = async () => {
    setPagesLoading(true)
    try {
      const result = await dispatch(fetchRegisterPagesAncestry({ registerId: selectedRegister.registerId }))
      setPages(result.payload.sheets)
    } catch (error) {
      console.error(error)
      // handle error
    } finally {
      setPagesLoading(false)
    }
  }

  const onSaveBtnClick = () => {
    if (!allowSave()) return
    dispatch(updateColumn(
      {
        sheetId,
        columnId: colKey,
        columnName: colName,
        dataType: 'linkedSheet',
        linkedDataType: selectedCol.dataType,
        linkedSheetId: selectedPage.sheetId,
        linkedColumnId: selectedCol.key,
        linkedRegisterId: selectedRegister.registerId,
        columnDescription: `${selectedRegister.name} -> ${selectedPage.sheetName} -> ${selectedCol.name}`
      }))
  }

  const closePopup = () => {
    setShowLinkToAnotherRegisterPopup(false)
  }

  const allowSave = () => {
    return selectedCol != ''
  }

  return (
    <PortalPopup
      onClose={closePopup}
      containerClass={`rounded-2xl overflow-hidden bg-white`}
      containerStyle={{ width: '40rem', maxWidth: '95vw', height: '80vh', color: '#174184' }}
    >

      <div className="flex items-between justify-between flex-col h-full overflow-y-auto">
        <div>
          <Header text={`Link to Another Register`} closePopup={closePopup} />
          <SelectRegisterToLink
            selectedRegister={selectedRegister}
            setSelectedRegister={setSelectedRegister}
            pages={pages}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            pagesLoading={pagesLoading}
            columnInfo={columnInfo}
          />

          <SelectColumnToLink
            selectedRegister={selectedRegister}
            selectedPage={selectedPage}
            selectedCol={selectedCol}
            setSelectedCol={setSelectedCol}
            columnInfo={columnInfo}
          />
        </div>

        <div className="mx-6 mb-6">
          <button onClick={onSaveBtnClick} className="primary_btn w-full py-3">Save</button>
        </div>
      </div>


    </PortalPopup>
  )
}

export default LinkToAnotherRegisterPopup