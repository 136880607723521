import moment from 'moment'

// components
import CustomInputDropdown from '../../../../../../../../partials/CustomInputDropdown'
import TimeUnitSelector from './TimeUnitSelector'

// icon
import timeIcon from '../../../../../../../../images/datatype_icons/light/time.png'
import colonIcon from '../../../../../../../../images/add-edit row dialog/time_colon.png'

const TimeSection = ({
  time,
  setTime,
  setHasChanged,
  closeTimeDropdown,
  onTimeDelete,
  showTimeDropdown,
  setShowTimeDropdown,
  colkey
}) => {

  // local variables
  const minsTenToSixty = []
  for (let i = 10; i < 60; i++) {
    minsTenToSixty.push(String(i))
  }
  const hoursArray = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
  const minutesArray = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', ...minsTenToSixty]
  const meridiemArray = ['AM', 'PM']

  // to scroll to an hour/min/meridiem in dropdown
  const scrollTo = (type, value) => {
    let myElement = document.getElementById(`${type}_${value}_${colkey}`)
    let topPos = myElement.offsetTop
    document.getElementById(`${type}_container_div_${colkey}`).scrollTo({ top: topPos - 48, behavior: 'smooth' })
  }

  const onSaveClick = () => {
    setHasChanged(true)
    let hourContainerDiv = document.getElementById(`formViewHour_container_div_${colkey}`)
    let minContainerDiv = document.getElementById(`formViewMin_container_div_${colkey}`)
    let meridiemContainerDiv = document.getElementById(`formViewMeridiem_container_div_${colkey}`)
    let hour = hourContainerDiv.scrollTop / 48 + 1
    let min = minContainerDiv.scrollTop / 48
    let mer = meridiemContainerDiv.scrollTop / 48 == 0 ? 'AM' : 'PM'
    let time = `${hour}:${min} ${mer}`
    setTime(moment(time, 'hh:mm a').format('h:mm A'))
    closeTimeDropdown()
  }

  return (
    <div className='flex items-center gap-1 md:gap-2 lg:gap-4 my-3 px-1 md:px-2 lg:px-5' >
      <img src={timeIcon} className='h-5' />
      <span style={{ color: '#545454' }}>Time</span>
      <div style={{ width: '12rem', maxWidth: '100%' }} className='overflow-hidden'>
        <CustomInputDropdown
          fieldValue={time}
          inputType={`div`}
          container={`form`}
          inputClasses={`field`}
          inputStyles={{}}
          dropdownClasses={``}
          dropdownStyles={{ marginTop: 8, minWidth: '15rem' }}
          buttonsInfo={{ showButtonsSection: true, showCancelButton: true, showSaveButton: true, onSaveButtonClick: onSaveClick }}
          showRemoveIcon={!showTimeDropdown}
          onRemoveIconClick={onTimeDelete}
          showDropDownMenu={showTimeDropdown}
          setShowDropDownMenu={setShowTimeDropdown}
          onFieldClick={() => { }}
        >
          <div className='relative mx-5 flex justify-around' style={{ height: '9rem', color: '#061119' }}>

            <div className='absolute w-full top-1/3 rounded' style={{ height: '3rem', backgroundColor: '#E4EFF0' }}></div>

            {/* Hours */}
            <TimeUnitSelector type={'formViewHour'} timeUnitArray={hoursArray} colkey={colkey} scrollTo={scrollTo} />

            {/* Colon */}
            <div className='h-full flex items-center px-3'>
              <img src={colonIcon} className='w-28 relative bottom-0.5' />
            </div>

            {/* Minutes */}
            <TimeUnitSelector type={'formViewMin'} timeUnitArray={minutesArray} colkey={colkey} scrollTo={scrollTo} />

            {/* Meridiem */}
            <TimeUnitSelector type={'formViewMeridiem'} timeUnitArray={meridiemArray} colkey={colkey} scrollTo={scrollTo} />

          </div>
        </CustomInputDropdown>
      </div>


    </div>
  )
}

export default TimeSection