import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { updateBody, updateHeaderText, toggleDialogOpen } from '../../../../../../../slices/DialogSlice'
import SelectOneDialog1 from '../../../../../Table/Editors/DataCell/SelectOne/SelectOneDialog1'
import Dropdown from '../../../../../../../partials/Dropdown'

//icons
import labelIcon from '../../../../../../../images/column-icons/label.png'
import editIcon from '../../../../../../../images/add-edit row dialog/form icons/edit.png'
import drop_down from '../../../../../../../images/add-edit row dialog/drop-down.png'

const Label = ({ colkey, column, colIdx, handleChange, formData, deleteEntry, shouldAllowNewEntry, saveCurrentRowDataRegister }) => {

  //redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  // state variables
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [colOpns_WithCheckedProp, setColOpns_WithCheckedProp] = useState([])

  //ref variables
  const detailValue_ref_tracker = useRef([])

  // local variables
  const colKey = column.key
  const colMultiOptions = column.multiOptions
  const selected_options = formData[colkey + '_details'] ? formData[colkey + '_details'] : []
  const display_value_info = selected_options && selected_options.length > 0 ? selected_options[0] : { value: '', color: 'transparent' }
  const { isAdmin, hasBasicEditPermission, editPermissions, rowIndex } = registerData
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colkey) === -1
  const rowProperties = {
    value: formData[colKey],
    rowIdx: registerData.rowIndex
  }
  const colors = ["#FFFFFF", "#B6E5FF", "#FFE7B6", "#DBB6FF", "#FFBAB6", "#FFB6FC", "#B6FFE9", "#CFF9C4"]

  useEffect(() => {
    if (dropdownOpen) prepareSelectedList()
  }, [dropdownOpen, colMultiOptions])

  const prepareSelectedList = () => {
    let colMultiOptions_copy = JSON.parse(JSON.stringify(colMultiOptions))
    colMultiOptions_copy.forEach((multiColOpn) => {
      if (selected_options.some((selected_option) => multiColOpn.color === selected_option.color && multiColOpn.value === selected_option.value)) {
        multiColOpn.checked = true
      }
    })
    setColOpns_WithCheckedProp(colMultiOptions_copy)
    detailValue_ref_tracker.current = colMultiOptions_copy
  }

  const handleSelectedOptionClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDropdownOpen(!dropdownOpen)
  }

  const closeDropdown = () => {
    if (searchQuery.length > 0) setSearchQuery('')
    setDropdownOpen(false)
  }

  const onOptionClick = (e, option) => {
    let copy = JSON.parse(JSON.stringify(colOpns_WithCheckedProp))
    copy.forEach(el => {
      if (option.color === el.color && option.value === el.value) {
        el.checked = !el.checked
      }
    })
    setColOpns_WithCheckedProp(copy)
    detailValue_ref_tracker.current = copy
    let selectedOptions_to_be_sent = detailValue_ref_tracker.current.filter(el => el.checked === true)
    handleChange({ colkey, value: '', colkeyDetail: colkey + '_details', valueDetail: selectedOptions_to_be_sent })
  }

  const onEditButtonClick = (e) => {
    e.preventDefault()
    dispatch(toggleDialogOpen(true));
    dispatch(updateBody(
      <SelectOneDialog1
        colors={colors}
        selectOneOptions={column.multiOptions}
        rowProperties={rowProperties}
        saveCurrentRowData={saveCurrentRowDataRegister}
        colKey={colkey}
        colIdx={colIdx}
      />))
    dispatch(updateHeaderText('Add/Delete'))
  }

  return (
    <div className="mb-6">
      <div className="colName">
        <img src={labelIcon} style={{ height: '0.9rem', width: '1.04rem' }} />
        <label htmlFor={column.name}>{column.name}</label>
      </div>
      {
        !canEdit && !shouldAllowNewEntry &&
        <div className="field">{display_value_info.value}</div>
      }
      {
        (shouldAllowNewEntry || canEdit) &&
        <div className="relative flex items-center">

          <button onClick={handleSelectedOptionClick} className="field cursor-pointer">
            <span style={{ backgroundColor: display_value_info.color }} className='px-1 rounded-sm truncate'>{display_value_info.value}</span>
            &nbsp;{selected_options.length > 1 && <span className='whitespace-nowrap text-xs lg:text-base'>{`& ${selected_options.length - 1} more`}</span>}
          </button>
          <img className={`drop-down-icon transform transition-transform delay-50 ${dropdownOpen ? 'rotate-180' : 'rotate-0'}`} src={drop_down} />

          {
            dropdownOpen &&
            <Dropdown closeDropdownCallback={closeDropdown} containerClasses={`mt-2 w-full`}>
              <div className="flex mt-3 px-3" style={{ fontSize: '0.9rem', height: '30%' }}>
                <input type="text" placeholder="Search" className="w-full field focus:ring-transparent" style={{ height: '2rem' }} onChange={e => setSearchQuery(e.target.value)} />
              </div>
              <ul className="py-2" style={{ fontSize: '0.9rem' }}>
                <div className='overflow-y-auto max-h-40'>
                  {
                    colOpns_WithCheckedProp && colOpns_WithCheckedProp.filter((option) => {
                      if (searchQuery == "") {
                        return option
                      } else if (option.value.toLowerCase().includes(searchQuery.toLowerCase())) {
                        return option
                      }
                    })
                      .map((option, i) => {
                        return (
                          <li
                            key={`${option.name} ${i}`}
                            onClick={(e) => onOptionClick(e, option)}
                            className="w-full cursor-pointer truncate px-4 py-2 list_item gap-1 md:gap-2 lg:gap-4 transition-all delay-50"
                          >
                            <span className="flex items-center text-black w-5/6 p-1 mr-2 rounded-sm" style={{ backgroundColor: option.color }}>
                              <p className="truncate">{option.value}</p>
                            </span>
                            <span className="flex items-center justify-center text-black w-1/6">
                              <input tabIndex="-1" style={{ border: '1px solid #35A600', borderRadius: 3 }} type="checkbox" checked={option.checked} />
                            </span>
                          </li>
                        )
                      })
                  }
                </div>
              </ul>

              <div className='py-2 px-4 flex gap-4 justify-end' style={{ backgroundColor: '#F9F9F9' }}>
                <button onClick={onEditButtonClick} className="secondary_btn transition-all delay-50 px-5 py-1"><img src={editIcon} className='h-4' />Edit</button>
              </div>

            </Dropdown>
          }

        </div>
      }
    </div>
  )
}

export default Label