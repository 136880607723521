import { useSelector } from 'react-redux'
import LastRowCellFormatter from './LastRowCellFormatter'
import moment from 'moment'

// utils
import { getValidatedReminderValue } from '../../Utils'

const TextFormatter = ({ rowProperties, openEditorAfterSelect, colIdx, columnEl }) => {
  // redux variables
  const registerData = useSelector(state => state.register)

  // local variables
  const colKey = columnEl.key
  const rowIdx = rowProperties.rowIdx
  const value = getValidatedReminderValue(rowProperties.row[colKey])
  const filteredRows = registerData.filteredRows
  const isCellSelected = rowProperties.isCellSelected
  const rowId = rowProperties.row.rowId
  const isLastRow = filteredRows.length - 1 === rowIdx
  const searchQuery = registerData.searchQuery
  const shouldHighlight = searchQuery !== '' && (value.toString().toLowerCase()).includes(searchQuery.toLowerCase())

  const getSheetReminderValue = (value) => {
    const date = moment(value).format('DD MMM')
    const time = moment(value).format('LT')
    const reminderSheetValue = `${date} ${(time)}`
    return reminderSheetValue
  }

  const handleOpenEditor = () => {
    openEditorAfterSelect({ isCellSelected, rowIdx, colIdx: rowProperties.column.idx })
  }

  if (isLastRow) {
    return <LastRowCellFormatter
      rowProperties={rowProperties}
      openEditorAfterSelect={openEditorAfterSelect}
      colIdx={colIdx}
      colKey={colKey}
    />
  }

  return (
    <div
      id={`dataCell/${colKey}/${rowId}`}
      onClick={handleOpenEditor}
      className="sheetItem2"
      style={{ backgroundColor: shouldHighlight ? '#ffd66e' : 'transparent' }}
    >
      { value ? getSheetReminderValue(value) : ''}
    </div>
  )

};

export default TextFormatter