//icons
import closeIcon from '../../../../../images/dialog/close.png'
import whiteBackArrow from '../../../../../images/whiteBackArrow.png'

const Header = ({ categorySelected, popUpNavigationHistory, goBack, closePopUp, registersExists }) => {
	return (
		<div className="w-full flex">
			{
				categorySelected ?
					<div className="flex w-5/6 items-center">
						{popUpNavigationHistory.length > 0 && registersExists && <img onClick={goBack} className="h-6 w-6 mr-1 cursor-pointer" src={whiteBackArrow} alt="white back arrow" />}
						<span>{categorySelected.name}</span>
					</div>
					:
					<div className="flex w-5/6">
						<span>Add Register</span>
					</div>
			}
			<div
				className="w-1/6 flex justify-end"
			>
				<img onClick={closePopUp} className="h-6 w-6 cursor-pointer" src={closeIcon} alt="close dialog" />
			</div>
		</div>
	)
}

export default Header;