import { useEffect, useState, useRef } from "react";
import ReactDOM, { createPortal } from 'react-dom';
import { useSelector, useDispatch } from "react-redux";
import { sheetColumnDefaultFormula } from '../../../../../slices/RegisterSlice'

// utils
import { getSumCntAvgVal } from '../../../../../utils/Utils';

// icons
import dropdownIcon from '../../../../../images/header popup icons/drop-down-gray.png'
import calculateIcon from '../../../../../images/calculate.png'

const DataSummaryCellFormatter = ({ rowInfo, columnInfo, saveCurrentRowData }) => {

  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  // state variables
  const [pos, setPos] = useState({})
  const [showCalcOptns, setShowCalcOptns] = useState(false)
  const [selectedOptn, setSelectedOptn] = useState('');

  // ref variables 
  const dropdown = useRef(null)
  const cellRef = useRef(null)

  // local variables
  const sheetId = registerData.sheetId
  const colKey = columnInfo ? columnInfo.key : ''
  const value = rowInfo && rowInfo[colKey] && rowInfo[colKey].length > 0 ? rowInfo[colKey] : 'Calculate'
  const rowId = ''
  const countDataTypes = ['number', 'phoneNumber', 'String', 'image', 'createdAt', 'date', 'rupee', 'formula', 'location', 'dropDown', 'switch', 'time', 'mail', 'attachment', 'url', 'signature', 'status', 'reminder', 'scanner', 'unit', 'createdBy', 'duration', 'label', 'linkedSheet']
  const averageDataTypes = ['number', 'phoneNumber', 'String', 'formula', 'rupee', 'unit', 'duration']
  const sumDataTypes = ['number', 'phoneNumber', 'String', 'formula', 'rupee', 'unit', 'duration']
  let calculateOprnsArr = [
    { type: 'Count', dataTypesAllowed: countDataTypes },
    { type: 'Average', dataTypesAllowed: averageDataTypes },
    { type: 'Sum', dataTypesAllowed: sumDataTypes },
    { type: 'Remove', dataTypesAllowed: ['all'] },
  ]

  //console.log('register rows:', registerData.rows, rowInfo)

  useEffect(() => {
    const clickHandlerSummaryCell = () => setShowCalcOptns(false)
    if (showCalcOptns) {
      onScroll()
      document.addEventListener('click', clickHandlerSummaryCell)
    }
    return () => document.removeEventListener('click', clickHandlerSummaryCell)
  }, [showCalcOptns])

  const onScroll = () => {
    if (dropdown.current) {
      let totalDropdownHeight = (dropdown.current.lastElementChild.getBoundingClientRect().height * dropdown.current.childElementCount)
      const refValue = ReactDOM.findDOMNode(cellRef.current)
      if (refValue) {
        setPos({
          right: refValue
            .getBoundingClientRect().right,
          left: refValue
            .getBoundingClientRect().left,
          top: refValue
            .getBoundingClientRect().top - totalDropdownHeight,
          bottom: refValue
            .getBoundingClientRect().bottom,
        })
      }
    }
  }

  const onOptionClick = (value) => {
    setShowCalcOptns(false)
    const selectedVal = value
    setSelectedOptn(selectedVal)
    const visibleRows = registerData.filteredRows
    let newDataCellVal = getSumCntAvgVal(selectedVal, columnInfo, visibleRows)
    saveCurrentRowData(newDataCellVal, colKey, rowId, registerData.rows, true, true)
    dispatch(sheetColumnDefaultFormula({
      sheetId: sheetId,
      columnId: colKey,
      defaultRowFormula: selectedVal.toLowerCase()
    }))
  }

  const showOption = (el) => {
    const isRemoveOptn = el.dataTypesAllowed.includes('all')
    const showRemoveOptn = value !== 'Calculate' && isRemoveOptn
    let datatype = columnInfo.dataType == 'linkedSheet' ? columnInfo.linkedDataType : columnInfo.dataType
    return el.dataTypesAllowed.includes(datatype) || showRemoveOptn
  }

  const onCellClick = (e) => {
    e.stopPropagation() // to stop clickHandlerSummaryCell from getting triggered
    setShowCalcOptns(!showCalcOptns)
  }

  return (
    <>
      <div
        id={`calculatorCell/${colKey}/${rowId}`}
        onClick={onCellClick}
        ref={cellRef}
        className="flex justify-between items-center relative px-3 cursor-pointer"
        style={{ backgroundColor: '#F5F9FE', color: '#545454', fontSize: '0.95rem' }}
      >
        <span className="flex gap-2 items-center w-11/12">
          <img src={calculateIcon} className='h-5' />
          <div className="w-full truncate">{value}</div>
        </span>
        <img className={`absolute right-3 ml-2 h-1.5 mb-1 transform transition-transform delay-50 ${showCalcOptns ? 'rotate-180' : ''}`} src={dropdownIcon} />
      </div>
      {
        showCalcOptns &&
        createPortal(
          <div onClick={e => e.stopPropagation()} ref={dropdown} style={{ width: cellRef.current && cellRef.current.clientWidth, position: 'absolute', top: pos.top, bottom: pos.bottom, right: pos.right, left: pos.left }}>
            <div tabIndex="-1" className="truncate border border-gray-300 bg-white" style={{ fontSize: '12px', fontWeight: '400', background: '#EEF3F5', boxShadow: 'rgba(0, 0, 0, 0.24) 0px -1px 8px' }}>
              {
                calculateOprnsArr.map((el) => {
                  if (showOption(el)) {
                    return (
                      <a
                        id={`dataSummaryCell/${colKey}/${rowId}/${el.type}/a`}
                        key={`dataSummaryCell/${colKey}/${rowId}/${el.type}/a`}
                        tabIndex="0"
                        className={`flex items-center gap-4 justify-center m-2 p-2 transition-colors delay-50 cursor-pointer bg-white hover:bg-gray-200 border border-gray-300 rounded ${el.dataTypesAllowed.includes('all') ? "text-red-500" : "text-black"}`}
                        onKeyDown={(e) => {
                          if (e.code === 'Enter')
                            onOptionClick(el.type)
                        }}
                        onClick={() => onOptionClick(el.type)}
                      >
                        {el.type}
                      </a>
                    )
                  }
                })
              }
            </div>
          </div>,
          document.body)
      }
    </>


  )
};

export default DataSummaryCellFormatter