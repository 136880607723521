import { useDispatch } from "react-redux"

//slices
import { toggleDialogOpen } from "../../../../../slices/DialogSlice"

const Buttons = ({ onSubmit }) => {

  const dispatch = useDispatch()

  const onCancelButtonClick = () => {
    dispatch(toggleDialogOpen(false))
  }

  const onSubmitButtonClick = () => {
    onSubmit()
    dispatch(toggleDialogOpen(false))
  }

  const classes = {
    container: `flex gap-5 items-start`,
    button1Container: `w-4/12`,
    button2Container: `w-8/12`,
    buttonContainerCommon: `flex justify-center items-center`,
    buttonsCommon: `border w-full rounded py-3`,
    button1: ``,
    button2: `text-white`
  }

  const styles = {
    container: { height: '20%' },
    button1: { color: '#F5291C', borderColor: '#F5291C' },
    button2: { background: '#4DB14F' }
  }

  return (
    <div className={classes.container} style={styles.container}>
      
      <div className={`${classes.button1Container} ${classes.buttonContainerCommon}`}>
        <button
          onClick={onCancelButtonClick}
          className={`${classes.button1} ${classes.buttonsCommon}`}
          style={styles.button1}
        >
          Cancel
        </button>
      </div>
      
      <div className={`${classes.button2Container} ${classes.buttonContainerCommon}`}>
        <button
          onClick={onSubmitButtonClick}
          className={`${classes.button2} ${classes.buttonsCommon}`}
          style={styles.button2}
        >
          Submit
        </button>
      </div>
      
    </div>
  )
}

export default Buttons