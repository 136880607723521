import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { generateQRcode } from '../../slices/LoginSlice';

//components
import 'react-phone-number-input/style.css'
import LoginSection from './components/LoginSection'
import LoginContainer from '../../features/Login';
import CarouselSection from './components/CarouselSection'
import {
  GoogleReCaptchaProvider
} from 'react-google-recaptcha-v3';

const Login = () => {

  // redux variables
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(generateQRcode())
  }, [])

  const classes = {
    container: 'child-in-center h-screen w-full'
  }

  return (
    <div className={classes.container} style={{ backgroundColor: '#EAECEF' }}>
      {/* <CarouselSection /> */}
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY_PRODUCTION}>
        {/* <LoginSection /> */}
        <LoginContainer />
      </GoogleReCaptchaProvider>
    </div>
  )
}

export default Login
