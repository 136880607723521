import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'

// slices
import { updateSearchQuery } from '../../../../../slices/RegisterSlice'

// images
import searchBlackIcon from "../../../../../images/search-black.png"
import clearSearchWhite from "../../../../../images/clearSearchWhite.png"

const SearchEntry = () => {
  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  // ref variables
  const searchInput = useRef(null)

  // state variables
  const [searchQueryLocally, setSearchQueryLocally] = useState('')
  const [showSearchLoader, setShowSearchLoader] = useState(false)

  // local variables
  let searchThrottler;
  const { searchQuery } = registerData

  // this is done here as searchQuery value is also being cleared from register slice when new rows are added
  useEffect(() => {
    if (searchQuery === '') {
      setSearchQueryLocally('')
    }
  }, [searchQuery])

  const searchOnChange = useCallback((e) => {
    const value = e.target.value
    setSearchQueryLocally(value)
    setShowSearchLoader(true)
    clearTimeout(searchThrottler)
    searchThrottler = setTimeout(() => {
      setShowSearchLoader(false)
      dispatch(updateSearchQuery(value))
    }, 300)
  }, [])

  const clearSearch = () => {
    searchOnChange({ target: { value: '' } })
    searchInput.current.focus()
  }

  return (
    <div className="flex relative md:absolute md:right-6 md:top-0 justify-center items-center h-full w-1/2 xs:w-auto md:w-3/12 ml-1 md:ml-0">
      {/* Search record starts */}
      <div className="flex items-center justify-center h-full w-full"  >
        <img src={searchBlackIcon} style={{ height: 18, position: 'absolute', left: 8 }} />
        <input
          ref={searchInput}
          type="text"
          placeholder="Search Entry"
          className="w-full h-full md:h-4/6 grayPlaceholder"
          style={{ paddingLeft: 30, borderRadius: 48, border: '1px solid #E1E1E1', color: '#505050', fontSize: 13 }}
          value={searchQueryLocally}
          onChange={searchOnChange}
        />
        {showSearchLoader ?
          <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-black m-auto absolute right-2"></div>
          :
          searchQueryLocally.trim().length > 0 && <img src={clearSearchWhite} onClick={clearSearch} className='cursor-pointer' style={{ height: 16, width: 16, position: 'absolute', right: 5 }} />
        }
      </div>
      {/* Search record starts */}
    </div>
  )
}

export default SearchEntry