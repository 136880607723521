import { useEffect, useState } from 'react';
import dropdownIcon from '../images/header popup icons/drop down.png'

{/********************************Notes*************************************
 * USAGE:
  <div className="relative flex w-9/12 flex-col justify-center items-center">
    <CustomMultiSelect
      label={'Copy Data ( from current page )'}
      menuContainerStyle={{ top: '55%', right: '-42%', height: '17rem', width: '70%', border: '1px solid #C4C4C4', borderRadius: 5 }}
      items={currentPageColumns}
      displayKey={'name'}
      selectedItemSingularTxt='Column'
      selectedItemPluralTxt='Columns'
      onConfirmation={onColMultiSelectConfirmation}
    />
  </div>
***********************************************************************/}

const createCopy = (json) => {
  return JSON.parse(JSON.stringify(json))
}

const CustomMultiSelect = ({
  label, items, displayKey, onConfirmation, containerStyle, menuContainerStyle, selectedItemSingularTxt, selectedItemPluralTxt }) => {

  // state variables
  const [show, setShow] = useState(false)
  const [itemsDuplicate, setItemsDuplicate] = useState([])
  const [checkedAll, setCheckedAll] = useState(false)
  const [selectedItemsCount, setSelectedItemsCount] = useState(0)

  useEffect(() => {
    if (show) {
      document.addEventListener('click', clickHandler)
    }
    return () => document.removeEventListener('click', clickHandler)
  }, [show, itemsDuplicate])

  // add checked property
  useEffect(() => {
    let itemsCopy = createCopy(items)
    itemsCopy.forEach((el) => {
      el.checked = false
    })
    setItemsDuplicate(itemsCopy)
  }, [items])

  const clickHandler = () => { if (show) onConfirmationClick() }

  const toggleCheck = (index) => {
    let updatedDuplicateItems = createCopy(itemsDuplicate)
    updatedDuplicateItems[index].checked = !updatedDuplicateItems[index].checked
    setItemsDuplicate(updatedDuplicateItems)

    const areAllItemsChecked = updatedDuplicateItems.find(el => el.checked === false) ? false : true
    setCheckedAll(areAllItemsChecked)

    const confirmedItems = updatedDuplicateItems.filter(el => el.checked === true)
    setSelectedItemsCount(confirmedItems.length)
  }

  const toggleAll = (clearAll = false) => {
    const newToggleValue = clearAll ? false : !checkedAll
    setCheckedAll(newToggleValue)

    let itemsCopy = createCopy(itemsDuplicate)
    itemsCopy.forEach(el => el.checked = newToggleValue)
    setItemsDuplicate(itemsCopy)

    setSelectedItemsCount(newToggleValue ? items.length : 0)
  }

  const onConfirmationClick = () => {
    const confirmedItems = itemsDuplicate.filter(el => el.checked === true)
    onConfirmation(confirmedItems)

    setShow(false)
  }

  const getSelectedItemText = () => {
    const singularText = selectedItemSingularTxt ? selectedItemSingularTxt : 'Item'
    const pluralText = selectedItemPluralTxt ? selectedItemPluralTxt : 'Items'
    return `${selectedItemsCount} ${selectedItemsCount > 1 ? pluralText : singularText} Selected`
  }

  return (
    <div className="relative flex w-full h-full items-center" style={{ ...containerStyle }}>
      {/* label container starts here */}
      {
        label && <span className="flex pb-1" style={{ position: 'absolute', top: 10, fontSize: 12, color: '#586C9E', fontWeight: '400', fontFamily: 'Arial, Helvetica, sans-serif' }}>{label}</span>
      }
      {/* label container ends here */}

      {/* selected items container starts here */}
      <div tabIndex="0" onClick={() => setShow(!show)} className="flex h-2/5 bg-white cursor-pointer w-full" style={{ border: '1px solid #C4C4C4', borderRadius: 5 }} >
        <div className="flex w-4/5 items-center">
          <span className="pl-3 text-clip whitespace-nowrap" style={{ fontSize: '1.2rem', color: '#454545', fontWeight: '400', fontFamily: 'Arial, Helvetica, sans-serif' }}>
            {selectedItemsCount > 0 ? getSelectedItemText() : 'None'}
          </span>
        </div>
        <div className="flex w-1/5 justify-center items-center">
          <img className="w-6 h-6" src={dropdownIcon} />
        </div>
      </div>
      {/* selected items container ends here */}
      {
        show &&
        <div onClick={(e) => e.stopPropagation()} className="absolute flex flex-col bg-white p-3" style={{ ...menuContainerStyle }}>
          {/* all check container starts*/}
          <div onClick={() => toggleAll()} className="flex justify-end items-top" style={{ height: '10%' }} >
            <input checked={checkedAll} type="checkbox" className="mr-1 cursor-pointer" style={{ width: '14px', height: '14px', border: '1px solid #35A600', borderRadius: 3 }} readOnly />
            <span className="cursor-pointer" style={{ fontSize: 10 }}>All</span>
          </div>
          {/* all check container ends*/}

          {/* items container starts*/}
          <ul className="flex flex-col overflow-y-auto" style={{ height: '76%' }} >
            {
              itemsDuplicate.map((itemsEl, itemIdx) =>
                <li key={`customMultiSelectItem/${itemIdx}`} onClick={() => toggleCheck(itemIdx)} className="flex w-full justify-center items-center pb-1 pt-1 mb-1 mt-1 cursor-pointer" style={{ height: '1.8rem', borderRadius: 3, border: '1px solid #586C9E' }} >
                  <div className="flex w-5/6 pl-2 items-center">
                    <span className="truncate" style={{ color: '#454545', fontSize: 13 }}>{itemsEl[displayKey]}</span>
                  </div>
                  <div className="flex w-1/6 justify-end pr-1">
                    <input checked={itemsEl.checked} type="checkbox" className="mr-1 cursor-pointer" style={{ width: '18px', height: '18px', border: '1px solid #35A600', borderRadius: 3 }} readOnly />
                  </div>
                </li>
              )
            }
          </ul>
          {/* items container ends*/}

          {/* confirmation button container starts*/}
          <div className="flex items-center" style={{ height: '14%' }} >
            <div onClick={onConfirmationClick} className="flex h-5/6 w-full justify-center items-center cursor-pointer" style={{ backgroundColor: '#586C9E', borderRadius: 3 }} >
              <span className="text-white">Done</span>
            </div>
          </div>
          {/* confirmation button container ends*/}

        </div>
      }
    </div>

  )
}

export default CustomMultiSelect