import { useDispatch, useSelector } from "react-redux"

//slices
import { toggleRightSidebarOpen } from "../../../../slices/RightSidebarSlice"

//icons and images
import notificationIcon from '../../../../images/left-sidebar/notification.png'
import notificationIconDot from '../../../../images/left-sidebar/notification-dot.png'
import activityIcon from '../../../../images/left-sidebar/activity.png'
import searchIcon from '../../../../images/search-black.png'
import crossIcon from '../../../../images/close.png'

const SearchAndAddRegister = ({ sidebarInput, setSidebarInput, goToAddRegisterPopUp }) => {

  //redux variables
  const dispatch = useDispatch()

  //state variables
  const rightSidebarData = useSelector(state => state.rightSidebar)

  //local variables
  const { rightSidebarOpen, noOfNewNotifications, body } = rightSidebarData

  const onNotificationIconClick = (e) => {
    e.stopPropagation() // temporary fix
    dispatch(toggleRightSidebarOpen({ rightSidebarOpen: !rightSidebarOpen, body: 'notification' }))
  }

  // classes
  const container = `bg-white px-5 py-2 flex justify-center items-center flex-col h-full`
  const searchContainer = `flex items-center justify-between w-full h-1/2`
  const form = `w-10/12 relative flex items-center h-full`
  const searchInput = `input1 inputCommon w-full pr-10`
  const removeSearchText = `inputCommonCrossIcon h-3 mr-2 ${sidebarInput === "" ? 'hidden' : ''}`
  const addRegisterBtnContainer = `h-1/2 w-full flex items-center`
  const addRegisterBtn = `button button2 py-2`

  return (
    <div className={container}>
      
      <div className={searchContainer} style={{ maxHeight: '45%' }}>
        <form className={form}>
          <img src={searchIcon} className='input1SearchIcon h-5' style={{maxHeight: '90%'}} />
          <input value={sidebarInput} onChange={(e) => setSidebarInput(e.target.value)} className={searchInput} style={{borderRadius: '48px'}} placeholder='Search Register' />
          <img onClick={(e) => setSidebarInput("")} src={crossIcon} className={removeSearchText} />
        </form>
        {/* <img src={activityIcon} className='cursor-pointer h-5' /> */}
        <img onClick={onNotificationIconClick} src={noOfNewNotifications ? notificationIconDot : notificationIcon} className='cursor-pointer h-6' />
      </div>

      <div className={addRegisterBtnContainer}>
        <div onClick={goToAddRegisterPopUp} className={addRegisterBtn} style={{ maxHeight: '90%' }}>+ Add New Register</div>
      </div>
      
    </div>
  )
}

export default SearchAndAddRegister