import { useSelector } from 'react-redux';

import Mail from './Components/Mail'
import Text from './Components/Text'
import Rupee from './Components/Rupee'
import PhoneNumber from './Components/PhoneNumber'
import Number from './Components/Number'
import Date1 from './Components/Date'
import Time from './Components/Time/index.js'
import Url from './Components/Url/index.js'
import Switch from './Components/Switch'
import SelectOne from './Components/SelectOne/index.js'
import Col1 from './Components/Col1'
import Location from './Components/Location'
import Attachment from './Components/Attachment'
import Signature from './Components/Signature'
import Formula from './Components/Formula'
import Image from './Components/Image'
import Status from './Components/Status'
import Unit from './Components/Unit'
import CreatedAt from './Components/CreatedAt'
import CreatedBy from './Components/CreatedBy'
import Scanner from './Components/Scanner'
import Reminder from './Components/Reminder/index.js'
import Duration from './Components/Duration'
import Label from './Components/Label'
import LinkedSheet from './Components/LinkedSheet';
import Default from './Components/Default'

const RowDetailForm = ({ formData, shouldAllowNewEntry, rowIndex, rowsLength, edited, handleChange, handleChangeNew, setEdited, deleteEntry }) => {

  //redux variables
  const registerData = useSelector(state => state.register)

  //local variables
  const { columns, rowDetailsViewType } = registerData
  const fullWidthColumnTypeArray = ['String', 'image', 'attachment', 'scanner', 'label']

  const getRow = ({ colkey, name, dataType, column, colIdx }) => {
    if (dataType === 'mail')
      return <Mail colkey={colkey} column={column} handleChange={handleChange} formData={formData} shouldAllowNewEntry={shouldAllowNewEntry} />

    if (dataType === 'String')
      return <Text colkey={colkey} column={column} handleChange={handleChange} formData={formData} rowIndex={rowIndex} shouldAllowNewEntry={shouldAllowNewEntry} />

    if (dataType === 'rupee')
      return <Rupee colkey={colkey} column={column} handleChange={handleChange} formData={formData} shouldAllowNewEntry={shouldAllowNewEntry} />

    if (dataType === 'phoneNumber')
      return <PhoneNumber colkey={colkey} column={column} handleChange={handleChange} formData={formData} shouldAllowNewEntry={shouldAllowNewEntry} />

    if (dataType === 'number')
      return <Number colkey={colkey} column={column} handleChange={handleChange} formData={formData} shouldAllowNewEntry={shouldAllowNewEntry} />

    if (dataType === 'formula')
      return <Formula rowIndex={rowIndex} colIdx={colIdx} column={column} handleChange={handleChange} formData={formData} shouldAllowNewEntry={shouldAllowNewEntry} />

    if (dataType === 'image')
      return <Image colkey={colkey} column={column} handleChange={handleChange} formData={formData} edited={edited} shouldAllowNewEntry={shouldAllowNewEntry} />

    if (dataType === 'date')
      return <Date1 colkey={colkey} column={column} handleChange={handleChange} formData={formData} shouldAllowNewEntry={shouldAllowNewEntry} />

    if (dataType === 'time')
      return <Time colkey={colkey} column={column} handleChange={handleChange} formData={formData} shouldAllowNewEntry={shouldAllowNewEntry} />

    if (dataType === 'url')
      return <Url colkey={colkey} column={column} handleChange={handleChange} formData={formData} deleteEntry={deleteEntry} edited={edited} shouldAllowNewEntry={shouldAllowNewEntry} />

    if (dataType === 'switch')
      return <Switch colkey={colkey} column={column} handleChange={handleChange} formData={formData} deleteEntry={deleteEntry} shouldAllowNewEntry={shouldAllowNewEntry} />

    if (dataType === 'dropDown')
      return <SelectOne colkey={colkey} column={column} colIdx={colIdx} handleChange={handleChange} formData={formData} deleteEntry={deleteEntry} shouldAllowNewEntry={shouldAllowNewEntry} />

    if (dataType === 'location')
      return <Location colkey={colkey} column={column} handleChange={handleChange} formData={formData} deleteEntry={deleteEntry} shouldAllowNewEntry={shouldAllowNewEntry} />

    if (dataType === 'attachment')
      return <Attachment colkey={colkey} column={column} handleChange={handleChange} formData={formData} shouldAllowNewEntry={shouldAllowNewEntry} edited={edited} />

    if (dataType === 'signature')
      return <Signature colkey={colkey} column={column} handleChange={handleChange} formData={formData} shouldAllowNewEntry={shouldAllowNewEntry} />

    if (dataType === 'status')
      return <Status colkey={colkey} column={column} handleChange={handleChange} formData={formData} deleteEntry={deleteEntry} shouldAllowNewEntry={shouldAllowNewEntry} />

    if (dataType === 'unit')
      return <Unit colkey={colkey} column={column} handleChange={handleChange} formData={formData} shouldAllowNewEntry={shouldAllowNewEntry} />

    if (dataType === 'createdAt')
      return <CreatedAt colkey={colkey} column={column} handleChange={handleChange} formData={formData} shouldAllowNewEntry={shouldAllowNewEntry} />

    if (dataType === 'createdBy')
      return <CreatedBy colkey={colkey} column={column} handleChange={handleChange} formData={formData} shouldAllowNewEntry={shouldAllowNewEntry} />

    if (dataType === 'scanner')
      return <Scanner colkey={colkey} column={column} handleChange={handleChange} formData={formData} shouldAllowNewEntry={shouldAllowNewEntry} />

    if (dataType === 'reminder')
      return <Reminder colkey={colkey} column={column} handleChange={handleChange} formData={formData} deleteEntry={deleteEntry} shouldAllowNewEntry={shouldAllowNewEntry} />

    if (dataType === 'duration')
      return <Duration colkey={colkey} column={column} handleChange={handleChange} formData={formData} deleteEntry={deleteEntry} shouldAllowNewEntry={shouldAllowNewEntry} />

    if (dataType === 'label')
      return <Label colkey={colkey} column={column} handleChange={handleChange} formData={formData} deleteEntry={deleteEntry} shouldAllowNewEntry={shouldAllowNewEntry} />

    if (dataType === 'linkedSheet')
      return <LinkedSheet colkey={colkey} column={column} handleChange={handleChangeNew} formData={formData} deleteEntry={deleteEntry} shouldAllowNewEntry={shouldAllowNewEntry} />

    if (name === "S No.")
      return
    //  return <Col1 colkey={colkey} column={column} handleChange={handleChange} formData={formData} rowsLength={rowsLength} rowIndex={rowIndex} shouldAllowNewEntry={shouldAllowNewEntry} />
    if (colkey === "addColPlusButton")
      return <></>
    return <Default colkey={colkey} column={column} handleChange={handleChange} formData={formData} shouldAllowNewEntry={shouldAllowNewEntry} />
  }

  return (
    <div className="w-full">
      <form className="">
        <ul className="flex flex-wrap">
          {
            columns.map((c, index) => {
              return (
                c.name !== 'S No.' &&
                <li key={`add-row-form-fields-${c.name}-${index}`} className={`last:pb-4 px-2 ${rowDetailsViewType == 'two' ? 'w-1/2' : 'w-full'}`}>
                  {getRow({ name: c.name, colkey: c.key, dataType: c.dataType, column: c, colIdx: index })}
                </li>
              )
            })
          }
        </ul>
      </form>
    </div>
  )
}

export default RowDetailForm