import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Mixpanel } from '../../partials/Mixpanel'

export const getOtp = createAsyncThunk('/getOtp', async ({ phoneNumber, token }) => {
  const response = await axios.post('/v3/user/generate/otpWeb', { phoneNumber, token })
  return response.data;
})

export const verifyOtp = createAsyncThunk('/verifyOtp', async ({ phoneNumber, otp }) => {
  const response = await axios.post('/user/loginWithOtpv2', { phoneNumber, otp })
  return response.data;
})

export const generateQRcode = createAsyncThunk('/generateQR', async () => {
  const response = await axios.get('/v3/user/generate/QRCode')
  return response.data.data
})

export const loginWithQR = createAsyncThunk('/loginWithQr', async ({ qrToken }) => {
  const response = await axios.post('/v3/user/login/withQRCode', { qrToken })
  return response.data
})

export const getManifestJsonDetails = createAsyncThunk('/getManifestJsonDetails', async (appName) => {
  const response = await axios.get('/v3/public/manifestJsonDetail?appName=' + appName)
  return response.data
})

// To send email to user
export const sendEmailToUser = createAsyncThunk('/user/sendEmail', async ({ emailId }) => {
  const response = await axios.post('/v3/user/generate/email', { emailId })
  return response.data
})

// To verify password
export const verifyPassword = createAsyncThunk('/user/password/verify', async ({ password, confirmPassword, token, emailId }) => {
  const response = await axios.post('/v3/user/verifyPassword', { password, confirmPassword, token, emailId })
  return response.data
})

// To login using email and password
export const loginUsingEmail = createAsyncThunk('/user/login/email', async ({ emailId, password }) => {
  const response = await axios.post('/v3/user/login/withEmail', { emailId, password })
  return response.data
})

// To reset password
export const sendEmailToResetPassword = createAsyncThunk('/user/password/reset', async ({ emailId }) => {
  const response = await axios.post('/v3/user/forgotPassword', { emailId })
  return response.data
})

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    otpSent: false,
    isLoading: false,
    userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null,
    shouldUserBeLoggedOut: false,
    isUserInfoUpdated: false,
    error: false,
    errorMessage: null,
    qrToken: null,
    manifestJsonDetail: null,
    isBrandLoading: false,
    emailSentToUser: false
  },
  reducers: {
    setuserShouldBeLoggedOut: (state, action) => {
      state.shouldUserBeLoggedOut = action.payload;

      // the reason to clear the variable here is because of the route
      // we want to clear everything from the store only if its a successful logout
      // and login page has been rendered so that app doesnt break as its using the user info
      // in the ProtectedRoute while being logged out.
      if (!state.shouldUserBeLoggedOut) {
        state.userInfo = null;
        localStorage.clear();
      }
    },
    logout: (state, action) => {
      state.shouldUserBeLoggedOut = true
      state.isUserInfoUpdated = false
    },
    updateLoginInfoState: (state, action) => {
      action.payload.forEach((el) => {
        const key = el.key
        const value = el.value
        state[key] = value
      })
    },
  },
  extraReducers: {
    [getOtp.pending]: (state, action) => {
      state.otpSent = false
      state.isLoading = true
      state.error = false
      state.errorMessage = null
    },
    [getOtp.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.otpSent = action.payload.success;
      } else {
        state.errorMessage = action.payload.message;
        state.error = true;
      }
      state.isLoading = false;
    },
    [getOtp.rejected]: (state, action) => {
      state.otpSent = false
      state.isLoading = false
    },
    [verifyOtp.pending]: (state, action) => {
      state.isLoading = true
      state.error = false
      state.errorMessage = null
    },
    [verifyOtp.fulfilled]: (state, action) => {
      if (action.payload.success) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${action.payload.data.token}`
        axios.defaults.params = {}
        axios.defaults.params['platform'] = "web"
        localStorage.setItem("userInfo", JSON.stringify(action.payload.data))
        localStorage.setItem("name", action.payload.data.name)
        localStorage.setItem("profilePic", action.payload.data.profilePic)
        localStorage.setItem("token", action.payload.data.token)
        Mixpanel.identify(action.payload.data.userId);
        state.userInfo = action.payload.data;
        state.otpSent = false;
      } else {
        state.errorMessage = action.payload.message;
        state.error = true;
      }
      state.isLoading = false

    },
    [verifyOtp.rejected]: (state, action) => {
      state.isLoading = false
    },
    [generateQRcode.fulfilled]: (state, action) => {
      state.qrToken = action.payload.qrToken
    },
    [loginWithQR.fulfilled]: (state, action) => {
      if (action.payload.success) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${action.payload.data.token}`
        axios.defaults.params = {}
        axios.defaults.params['platform'] = "web"
        localStorage.setItem("userInfo", JSON.stringify(action.payload.data))
        localStorage.setItem("name", action.payload.data.name)
        localStorage.setItem("profilePic", action.payload.data.profilePic)
        localStorage.setItem("token", action.payload.data.token)
        state.userInfo = action.payload.data
        state.otpSent = false
        state.qrToken = null
      }
    },
    [getManifestJsonDetails.pending]: (state, action) => {
      state.isBrandLoading = true
      state.error = false
      state.errorMessage = null
    },
    [getManifestJsonDetails.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.manifestJsonDetail = action.payload.data;
      } else {
        console.log("getManifestJsonDetails: ", action.payload.message)
      }
      state.isBrandLoading = false;
    },
    [getManifestJsonDetails.rejected]: (state, action) => {
      state.isBrandLoading = false
    },
    [sendEmailToUser.pending]: (state, action) => {
      state.isLoading = true
      state.error = false
      state.errorMessage = null
    },
    [sendEmailToUser.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.emailSentToUser = true
      } else {
        console.log("yo slice:", action.payload)
        state.errorMessage = action.payload.message
        state.error = true
      }
      state.isLoading = false
    },
    [sendEmailToUser.rejected]: (state, action) => {
      state.isLoading = false
      state.error = true
      state.errorMessage = 'Something went wrong!'
    },
    [verifyPassword.pending]: (state, action) => {
      state.isLoading = true
      state.error = false
      state.errorMessage = null
    },
    [verifyPassword.fulfilled]: (state, action) => {
      state.isLoading = false
      if (action.payload.success) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${action.payload.data.token}`
        axios.defaults.params = {}
        axios.defaults.params['platform'] = "web"
        localStorage.setItem("userInfo", JSON.stringify(action.payload.data))
        localStorage.setItem("name", action.payload.data.name)
        localStorage.setItem("profilePic", action.payload.data.profilePic)
        localStorage.setItem("token", action.payload.data.token)
        Mixpanel.identify(action.payload.data.userId);
        state.userInfo = action.payload.data;
        state.otpSent = false;
      } else {
        state.errorMessage = action.payload.message;
        state.error = true;
      }
    },
    [verifyPassword.rejected]: (state, action) => {
      state.isLoading = false
      state.error = true
      state.errorMessage = 'Something went wrong!'
    },
    [loginUsingEmail.pending]: (state, action) => {
      state.isLoading = true
      state.error = false
      state.errorMessage = null
    },
    [loginUsingEmail.fulfilled]: (state, action) => {
      if (action.payload.success) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${action.payload.data.token}`
        axios.defaults.params = {}
        axios.defaults.params['platform'] = "web"
        localStorage.setItem("userInfo", JSON.stringify(action.payload.data))
        localStorage.setItem("name", action.payload.data.name)
        localStorage.setItem("profilePic", action.payload.data.profilePic)
        localStorage.setItem("token", action.payload.data.token)
        Mixpanel.identify(action.payload.data.userId);
        state.userInfo = action.payload.data;
        state.otpSent = false;
      } else {
        state.errorMessage = action.payload.message;
        state.error = true;
      }
      state.isLoading = false
    },
    [loginUsingEmail.rejected]: (state, action) => {
      state.isLoading = false
      state.error = true
      state.errorMessage = 'Something went wrong!'
    },
    [sendEmailToResetPassword.pending]: (state, action) => {
      state.isLoading = true
      state.error = false
      state.errorMessage = null
    },
    [sendEmailToResetPassword.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.emailSentToUser = true
      } else {
        state.errorMessage = action.payload.message
        state.error = true
      }
      state.isLoading = false
    },
    [sendEmailToResetPassword.rejected]: (state, action) => {
      state.isLoading = false
      state.error = true
      state.errorMessage = 'Something went wrong!'
    },
  }
})

export const { setuserShouldBeLoggedOut, logout, updateLoginInfoState, manifestJsonDetail } = loginSlice.actions
export default loginSlice.reducer