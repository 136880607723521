// componets 
import RegisterElement from './RegisterElement'

const FolderElement = ({ folderEl, folderIdx, selectedRegisterId, onEditIconClick, onRegisterFolderNameClick }) => {

  const classes = {
    container: "flex flex-col registerElement",
    folderContainer: "flex truncate items-center p-5",
    avatar: "w-9 h-9 border rounded-full border-gray-300 bg-gray-300",
    folderName: "whitespace-nowrap truncate font-semibold text-black pl-4 text-sm w-full",
    registerElementContainer: `overflow-hidden transition-all`
  }

  const styles = {
    folderName: { fontSize: '1rem' }
  }

  return (
    <div className={classes.container}>
      {/* Folder container starts */}
      <div onClick={() => onRegisterFolderNameClick(folderEl, folderIdx)} className={classes.folderContainer}>
        <img className={classes.avatar} src={folderEl.avatar} />
        <h1 className={classes.folderName} style={styles.folderName}>{folderEl.name}</h1>
      </div>
      {/* Folder container starts ends */}
      <ul className={classes.registerElementContainer}>
        {
          folderEl.isExpanded && folderEl.registers.map((r, i) =>
            <RegisterElement
              registerEl={r}
              selectedRegisterId={selectedRegisterId}
              onEditIconClick={onEditIconClick}
              onRegisterFolderNameClick={onRegisterFolderNameClick}
              hasParent={true}
            />
          )
        }
      </ul>
    </div>
  )
}

export default FolderElement