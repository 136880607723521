//icons
import deleteIcon from '../../../../../../../images/add-edit row dialog/form icons/delete.png'
import editIcon from '../../../../../../../images/add-edit row dialog/form icons/edit.png'

const DropdownBody = ({
  column,
  searchQuery,
  setSearchQuery,
  colors,
  onOptionClick,
  formData,
  colkey,
  deleteEntry,
  closeDropdown
}) => {

  // local variable
  const optionsArray = column.multiOptions.map((option, i) => {
    return ({
      'value': option.value,
      'color': option.color ? option.color : (colors[i] ? colors[i] : '#fff'),
      'index': i
    })
  })

  return (
    <>
      <div className="flex mt-3 px-3" style={{ fontSize: '0.9rem', height: '30%' }}>
        <input type="text" placeholder="Search" className="w-full field focus:ring-transparent" style={{ height: '2rem' }} onChange={e => setSearchQuery(e.target.value)} />
      </div>
      <ul className="pt-2" style={{ fontSize: '0.9rem' }}>
        <div className='overflow-y-auto max-h-40'>
          {
            optionsArray && optionsArray.filter((option) => {
              if (searchQuery == "") {
                return option
              } else if (option.value.toLowerCase().includes(searchQuery.toLowerCase())) {
                return option
              }
            })
              .map((filteredOption, i) => {
                return (
                  <li
                    key={`${filteredOption.name} ${i}`}
                    onClick={(e) => onOptionClick(e, filteredOption)}
                    className='truncate px-4 py-2 w-full cursor-pointer'
                    style={{ backgroundColor: filteredOption.color ? filteredOption.color : '#fff' }}
                  >
                    {filteredOption.value}
                  </li>
                )
              })
          }
        </div>
      </ul>
    </>
  )
}

export default DropdownBody