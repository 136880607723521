import { useSelector } from 'react-redux'
import LastRowCellEditor from '../DataCell/LastRowCellEditor'
import PhoneNumberEditor from '../DataCell/PhoneNumberEditor'

const PhoneNumberEditorLayer = (props) => {
    // redux variables 
    const registerData = useSelector(state => state.register)

    // local variables
    const { isAdmin, hasBasicEditPermission, editPermissions, filteredRows } = registerData
    const { onClose, rowIdx, row } = props.rowProperties
    const { key: colKey } = props.columnEl
    const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colKey) === -1
    const isLastRow = filteredRows.length - 1 === rowIdx

    // Empty row work for add permission
    const hasAddPermission = registerData.permissions.includes('add')
    const isRowEmpty = !Object.keys(row).some((key) => {
        if (key != 'rowId' && key != -1) {
            return row[key].length > 0
        }
    })
    const canAdd = hasAddPermission && isRowEmpty
    // Empty row work for add permission

    if (isLastRow) return <LastRowCellEditor {...props} />
    else if (canEdit || canAdd) return <PhoneNumberEditor {...props} />
    else if (!canEdit) {
        onClose(true)
        return <></>
    }
}

export default PhoneNumberEditorLayer