import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

// components
import ColumnName from './ColumnName'
import EditContainer from './EditContainer'
import DisplayContainer from './DisplayContainer'

//icons
import urlIcon from '../../../../../../../images/datatype_icons/light/url.png'
import dropDownIcon from '../../../../../../../images/add-edit row dialog/drop-down.png'

const Url = ({ colkey, column, handleChange, formData, deleteEntry, edited, shouldAllowNewEntry }) => {

  // redux variables
  const registerData = useSelector(state => state.register)

  // local variables
  const { isAdmin, hasBasicEditPermission, editPermissions } = registerData
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colkey) === -1


  // state variables
  const [caption, setCaption] = useState('')
  const [url, setUrl] = useState('')
  const [showUrlContainer, setShowUrlContainer] = useState(false)
  const [isUrlEditorOpen, setIsUrlEditorOpen] = useState(false)
  const [showUrlError, setShowUrlError] = useState(false)
  const [showCaptionError, setShowCaptionError] = useState(false)

  // ref variables
  const dropdown = useRef(null)
  const trigger = useRef(null)

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current || !trigger.current) return;
      if (!showUrlContainer || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setShowUrlContainer(false);
      setIsUrlEditorOpen(false)
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // set caption adn url from formData
  useEffect(() => {
    if (formData[colkey] !== caption) setCaption(formData[colkey])
    if (
      formData[colkey + '_details'] &&
      formData[colkey + '_details'][0] &&
      formData[colkey + '_details'][0].value !== url
    ) {
      setUrl(formData[colkey + '_details'][0].value)
    }
  }, [formData[colkey], formData[colkey + '_details']])

  useEffect(() => {
    if (!edited) {
      setShowUrlContainer(false)
    }
  }, [edited])

  const openUrlContainer = (e) => {
    e.preventDefault()
    e.stopPropagation()
    // if allowed to edit 
    console.log(" canEdit ", canEdit)
    if (shouldAllowNewEntry || canEdit) {
      setShowUrlContainer(true)
      if (caption === '' && url === '') setIsUrlEditorOpen(true)
    } else if (!canEdit) { // if just view
      if (caption === '' || url === '') return
      else setShowUrlContainer(true)
    }
  }

  const editKeyDown = (e) => {
    e.stopPropagation()
    if (e.key === 'Enter') setIsUrlEditorOpen(true)
  }

  const inputTextKeyDown = (e) => {
    e.stopPropagation()
    if (e.key === 'Enter') {
      e.preventDefault()
      const isUrlEmpty = url.trim().length === 0
      const isCaptionEmpty = caption.trim().length === 0
      if (isUrlEmpty || isCaptionEmpty) {
        if (isUrlEmpty) setShowUrlError(true)
        if (isCaptionEmpty) setShowCaptionError(true)
        return
      }
      setShowUrlContainer(false)
      setIsUrlEditorOpen(false)
    }
  }

  const urlOnchange = (e) => {
    const value = e.target.value
    setUrl(value)
    if (showUrlError) setShowUrlError(false)
    handleChange({ colkeyDetail: colkey + '_details', valueDetail: [{ value }] })
  }

  const captionOnChange = (e) => {
    const value = e.target.value
    setCaption(value)
    if (showCaptionError) setShowCaptionError(false)
    handleChange({ colkey, value })
  }

  const onEditIconClick = (e) => {
    e.stopPropagation();
    setIsUrlEditorOpen(true)
  }

  const onDeleteIconClick = (e) => {
    setShowUrlContainer(false)
    setIsUrlEditorOpen(false)
    deleteEntry(colkey, { [colkey + '_details']: [{ value: '' }] });
  }

  return (
    <div className="mb-6">
      <ColumnName name={column.name} icon={urlIcon} />
      <div className='relative flex items-center'>
        {/*---- Button to open url container starts ---------*/}
        <button
          ref={trigger}
          onClick={openUrlContainer}
          className='text-blue-500 field'
        >
          {caption}
        </button>
        <img className='drop-down-icon' src={dropDownIcon} />
        {/*---- Button to open url container ends ---------*/}

        {
          showUrlContainer && (
            <div ref={dropdown} className={"absolute slide_down_2 top-full bg-white w-full border border-gray-400 rounded shadow-lg transform transition-all duration-200 ease-in z-50 translate-y-0  opacity-100"}>
              {
                isUrlEditorOpen ?
                  <EditContainer
                    url={url}
                    caption={caption}
                    inputTextKeyDown={inputTextKeyDown}
                    urlOnchange={urlOnchange}
                    captionOnChange={captionOnChange}
                    showUrlError={showUrlError}
                    showCaptionError={showCaptionError}
                  />
                  :
                  <DisplayContainer
                    url={url}
                    canEdit={canEdit}
                    editKeyDown={editKeyDown}
                    onEditIconClick={onEditIconClick}
                    onDeleteIconClick={onDeleteIconClick}
                  />
              }
            </div>
          )
        }
      </div>
    </div>
  )
}

export default Url

