import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import Map from './Map'
import { toggleDialogOpen } from '../../../../../../slices/DialogSlice'

//images
import locationIcon from '../../../../../../images/dialog/location/location.png'
import locateMeIcon from '../../../../../../images/dialog/location/locateMe.png'

const MapDialog = ({ location, center = { lat: 0, lng: 0 }, saveLocationData }) => {
  // 28.7 , 77.1
  const dispatch = useDispatch()

  //state variables
  const [markerPosition, setMarkerPosition] = useState(center)
  const [address, setAddress] = useState(location)
  const [city, setCity] = useState(null)
  const [showRequestLocanPermsn, setShowRequestLocanPermsn] = useState(false)

  //ref variables
  const mapRef = useRef(null)

  // called first to get user locaton if its a new entry
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map
    if (!location) {
      onLocateMeIconClick()
    } else {
      getAdress({ pos: markerPosition, updateAddress: false, updateCity: true })
    }
  }, [])

  const onLocateMeIconClick = () => {
    navigator.geolocation.getCurrentPosition(
      // success
      (position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        setMarkerPosition(pos)
        panTo(pos)
        getAdress({ pos, updateAddress: true, updateCity: true })
      },
      // error
      (error) => setShowRequestLocanPermsn(true)
    )
  }

  const getAdress = async ({ pos, updateAddress, updateCity }) => {
    const geocoder = await new window.google.maps.Geocoder()
    const geocodeResults = await geocoder.geocode({
      location: { lat: pos.lat, lng: pos.lng },
    })
    if (geocodeResults && geocodeResults.results) {
      let city = ''
      const address = geocodeResults.results[0].formatted_address
      for (let result of geocodeResults.results[0].address_components) {
        for (let j of result.types) {
          switch (j) {
            case "locality":
              city = result.long_name;
              break;
          }
        }
      }
      if (updateCity) setCity(city)
      if (updateAddress) setAddress(address)
    }
  }

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng })
  }, [])

  const onSaveButtonClick = () => {
    const latLng = {
      lat: String(markerPosition.lat),
      lng: String(markerPosition.lng)
    }
    saveLocationData({ latLng, address })
    dispatch(toggleDialogOpen(false))
  }

  return (
    <div className="md:flex justify-center items-center overflow-auto bg-white" style={{ height: '90vh', width: '90vw' }}>

      {/*Map*/}
      <div className="md:w-9/12 md:h-full h-4/6">
        <div className="absolute left-5 top-28 z-50 cursor-pointer" onClick={onLocateMeIconClick}><img src={locateMeIcon} /></div>
        <Map markerPosition={markerPosition} setMarkerPosition={setMarkerPosition} panTo={panTo} getAdress={getAdress} onMapLoad={onMapLoad} />
      </div>
      {/*Map*/}

      {/*Sidebar*/}
      <div className="md:w-3/12 md:h-full h-2/6 flex md:flex-col p-4 border-l-0 md:border-l border-t md:border-top-0 shadow-lg">
        <div className="md:h-5/6 w-8/12 md:w-full flex justify-center items-start gap-4  overflow-auto">
          <img className='h-8 relative' src={locationIcon} />
          <div className='break-words w-3/4 h-full'>
            {
              showRequestLocanPermsn ?
                <>
                  <div className='font-bold text-xl'>Location Permission Denied !</div>
                  <div>Allow Permission in your Chrome settings and open pop up again.</div>
                </>
                :
                <>
                  <div className='font-bold text-xl'>{city ? city : 'Loading City..'}</div>
                  <div>{address ? address : 'Loading Address..'}</div>
                </>
            }
          </div>
        </div>
        <div className="md:h-1/6 w-4/12 md:w-full md:border-t border-l md:border-l-0 pl-4 md:pl-0 flex justify-center items-center">
          <div
            className="w-full py-3 text-white rounded cursor-pointer text-center"
            style={{ background: '#586C9E' }}
            onClick={onSaveButtonClick}
          >
            Save Location
          </div>
        </div>
      </div>
      {/*Sidebar*/}

    </div>
  )
}

export default MapDialog