import { useSelector } from 'react-redux'

import rupee from '../../../../../../images/datatype_icons/light/rupee.png'

const Rupee = ({ colkey, column, handleChange, formData, shouldAllowNewEntry }) => {

  // redux variables
  const registerData = useSelector(state => state.register)

  // local variables
  const { isAdmin, hasBasicEditPermission, editPermissions } = registerData
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colkey) === -1
  const rupeeUnit = column.multiOptions[0] && column.multiOptions[0].value || <img src={rupee} className="field_icon" />

  return (
    <div className="mb-6">
      <div className="colName">
        {column.multiOptions[0] && <div className='field_icon'>{column.multiOptions[0].value}</div> || <img src={rupee} className="field_icon" />}
        <label htmlFor={column.name}>{column.name}</label>
      </div>

      {
        !canEdit && !shouldAllowNewEntry &&
        <div className="field">{formData[colkey]}</div>
      }

      {
        (shouldAllowNewEntry || canEdit) &&
        <input
          name={colkey}
          type="number"
          // placeholder="Enter Rupee"
          value={formData[colkey]}
          onChange={(e) => handleChange({ colkey, value: e.target.value })}
          className="field focus:ring-transparent"
        />
      }
    </div>
  )
}

export default Rupee