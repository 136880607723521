import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation, withRouter, useParams, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'

// slices
import { updateSidebarState, fetchHomePageData } from '../../../slices/LeftSidebarSlice'
import { updateSearchQuery } from '../../../slices/RegisterSlice'
import { updatePopNavigationHistory } from '../../../slices/RouterSlice'
import { toggleLeftSidebarOpen, updateLeftSidebarState, fetchAllBusiness } from '../../../slices/LeftSidebarSlice'

//components
import Businesses from './Components/Businesses';
import SearchAndAddRegister from './Components/SearchAndAddRegister';
import Registers from './Components/Registers/index.js';
import Profile from './Components/Profile';
import EmptyRegisterBody from '../../Register/PopUps/AddRegister/Components/EmptyRegisterBody'

// utils
import DeviceMeta from '../../../utils/DeviceDetector'

//css
import './index.css'

// icons
import welcomeImage from '../../../images/left-sidebar/welcome_image.png'
import welcomeText from '../../../images/left-sidebar/welcome_text.png'

const LeftSidebar = () => {

	// redux variables
	const dispatch = useDispatch()
	const leftSidebarData = useSelector(state => state.leftSidebar)
	const loginInfo = useSelector(state => state.loginInfo)

	// route variables
	const history = useHistory()
	const location = useLocation()

	// state variables
	const [sidebarInput, setSidebarInput] = useState("");
	const [selectedRegisterId, setSelectedRegisterId] = useState('')

	// community dropdown toggle variable is made here as e.stopPropogation on left sidebar was obstructing 
	// community dropdown closing if it was made inside the component SidebarHeader
	const [businessDropdownOpen, setBusinessDropdownOpen] = useState(false);

	// ref variables
	const sidebar = useRef(null);

	// local variables
	const { pathname } = location;
	const page = pathname.split('/')[1];
	const { activeBusinessId, registers, leftSidebarOpen, error, businessList, homePageDataFetched } = leftSidebarData
	const { shouldUserBeLoggedOut } = loginInfo
	const isRegistersListEmpty = registers.length === 0
	const doesBusinessExist = businessList.length !== 0

	useEffect(() => {
		if (businessList.length === 0) dispatch(fetchAllBusiness())
	}, [])

	// close communityDropdown on click outside 
	useEffect(() => {
		const clickHandler = () => setBusinessDropdownOpen(false);
		if (businessDropdownOpen) {
			document.addEventListener('click', clickHandler);
			return () => document.removeEventListener('click', clickHandler);
		}
	}, [businessDropdownOpen]);

	useEffect(() => {
		// so that when error exists and a redirection is made then the below logic should not be called ( just to avoid redundancy )
		// if its a logout then also this logic should not be called ( calling api during logout was creating data discrepency in the store after its reset )
		if (error || shouldUserBeLoggedOut) return
		const lastUsedBusinessId = localStorage.getItem('lastUsedBusinessId')
		const businessIdFromUrl = pathname.includes('community') ? pathname.split('/')[2] : lastUsedBusinessId
		const registerIdFromUrl = pathname.includes('register') ? pathname.split('/')[4] : null
		if (registerIdFromUrl) setSelectedRegisterId(registerIdFromUrl)
		else setSelectedRegisterId('')
		if (
			(activeBusinessId !== businessIdFromUrl) || // to avoid calling api if business id is same from url during a path change
			!(activeBusinessId && businessIdFromUrl) // initial loading if both are null
		) {
			dispatch(fetchHomePageData({ businessId: businessIdFromUrl }))
		}
		dispatch(updateSearchQuery(''))
	}, [location])

	useEffect(() => {
		if (leftSidebarData.newRegisterInfo) {
			const businessId = leftSidebarData.newRegisterInfo.businessId
			const registerId = leftSidebarData.newRegisterInfo.registerId
			setSelectedRegisterId(registerId)
			dispatch(fetchHomePageData({ businessId }))
			history.push(`/community/${businessId}/register/${registerId}`)
			dispatch(updateSidebarState([{ key: 'newRegisterInfo', value: null }]))
			dispatch(updateSearchQuery(''))
		}
	}, [leftSidebarData.newRegisterInfo])

	useEffect(() => {
		if (!error) return
		if (error.message === "Invalid Business") history.push('/' + location.search)
		dispatch(updateLeftSidebarState([{ key: 'error', value: null }]))
	}, [error])

	const goToAddRegisterPopUp = () => {
		dispatch(updatePopNavigationHistory({
			type: 'push',
			path: pathname + '?popUp=addRegister'
		}))
		history.push(`${pathname}?popUp=addRegister`)
	}

	const onRegisterClick = (id) => {
		setSelectedRegisterId(id)
		if (window.innerWidth <= 1028) { // in mobile, tablet and medium screens the register side bar should close on register select
			dispatch(toggleLeftSidebarOpen(false))
		}
	}

	const selectCategory = (category) => {
		dispatch(updatePopNavigationHistory({
			type: 'push',
			path: location.pathname + '?registerCategoryType=' + category.type
		}))
		history.replace(location.pathname + '?registerCategoryType=' + category.type)
	}

	const goToCreateRegister = () => {
		history.replace(location.pathname + '?popUp=createRegister')
	}

	const classes = {
		leftSidebar: `absolute z-40 left-0 top-0 overflow-hidden lg:static lg:left-auto w-full xs:w-72 lg:translate-x-0 transform flex-shrink-0 transition-transform duration-200 ease-in-out ${leftSidebarOpen ? 'translate-x-0' : '-translate-x-full xs:-translate-x-72'}`,
		backdropMobile: `fixed inset-0 bg-gray-900 bg-opacity-30 z-30 lg:hidden lg:z-auto transition-opacity duration-200 ${leftSidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`
	}

	const styles = {
		leftSidebar: { background: "#F6F8F9", borderRight: "0.5px solid rgba(209, 213, 219, 0.6)", height: '100vh' }
	}

	return (
		<div className="flex bg-white">

			{/* LeftSidebar backdrop (mobile only) */}
			<div className={classes.backdropMobile} onClick={() => dispatch(toggleLeftSidebarOpen(false))} aria-hidden="true"></div>

			{/* LeftSidebar container starts */}
			<div
				id="sidebar"
				ref={sidebar}
				className={classes.leftSidebar}
				style={styles.leftSidebar}
				onClick={(e) => {
					if (businessDropdownOpen) setBusinessDropdownOpen(false)
				}}
			>

				{
					homePageDataFetched && isRegistersListEmpty ?
						<div style={{ height: '93%', paddingTop: DeviceMeta.isMobile() && doesBusinessExist && isRegistersListEmpty ? '10%' : '' }} className="flex flex-col justify-center items-center relative gap-12 bg-white">
							{
								doesBusinessExist && // also when in mobile view, we need to show create register with empty business then paddintTop 10% is added
								<div style={{ height: '10%' }} className="absolute top-0 w-full">
									<Businesses
										history={history}
										businessDropdownOpen={businessDropdownOpen}
										setBusinessDropdownOpen={setBusinessDropdownOpen}
									/>
								</div>
							}
							{
								DeviceMeta.isMobile() ? // need to hide welcome text in mobile view
									<EmptyRegisterBody
										selectCategory={selectCategory}
										goToCreateRegister={goToCreateRegister}
									/> :
									<>
										<img src={welcomeImage} className="h-24" />
										<img src={welcomeText} className="" />
									</>
							}
						</div>
						:
						<>
							<div style={{ height: '10%' }}>
								<Businesses
									history={history}
									businessDropdownOpen={businessDropdownOpen}
									setBusinessDropdownOpen={setBusinessDropdownOpen}
								/>
							</div>

							<div style={{ height: '18%' }}>
								<SearchAndAddRegister
									sidebarInput={sidebarInput}
									setSidebarInput={setSidebarInput}
									goToAddRegisterPopUp={goToAddRegisterPopUp}
								/>
							</div>

							<div style={{ height: '64%' }}>
								<Registers
									sidebarInput={sidebarInput}
									history={history}
									page={page}
									selectedRegisterId={selectedRegisterId}
									setSelectedRegisterId={onRegisterClick}
								/>
							</div>
						</>
				}

				<div style={{ height: '8%' }}>
					<Profile />
				</div>

			</div>
			{/* LeftSidebar container ends*/}

		</div>
	)
}

export default withRouter(LeftSidebar);
