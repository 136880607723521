const ImagesList = ({ imagesArray }) => {
  return (
    <div className="pr-3">
      <div className="py-3 flex no-scrollbar gap-3" >
        {
          imagesArray.length > 0 && imagesArray.map((image, i) => {
            return (
              <img
                src={image.value}
                style={{ height: '2.4rem', width: '2.4rem', filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))', borderRadius: '5px' }}
              />
            )
          })
        }
      </div>
    </div>

  )
}

export default ImagesList