import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//components
import CustomInputDropdown from '../../../../../../partials/CustomInputDropdown'

// slices
import { toggleDialogOpen, updateBody, updateHeaderText } from '../../../../../../slices/DialogSlice'
import EditStatusDialog from '../../../../PopUps/EditStatus'

//icons
import statusIcon from '../../../../../../images/datatype_icons/light/status.png'

const Status = ({ colkey, column, handleChange, formData, deleteEntry, shouldAllowNewEntry }) => {

  const dispatch = useDispatch()

  // redux variables
  const registerData = useSelector(state => state.register)

  // local variables
  const { isAdmin, hasBasicEditPermission, editPermissions, columns } = registerData
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colkey) === -1

  // state variables
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [statusColor, setStatusColor] = useState('')

  useEffect(() => {
    let optionFound = false
    column.multiOptions.forEach(multiOpnEl => {
      if (multiOpnEl.value === formData[colkey]) {
        setStatusColor(multiOpnEl.color)
        optionFound = true
      }
    });
    if (!optionFound) setStatusColor('')
  }, [formData[colkey], columns])

  const handleOptionClick = (option) => {
    handleChange({ colkey, value: String(option) })
    setDropdownOpen(false)
  }

  const onEditButtonClick = (e) => {
    e.preventDefault()
    closeDropdown()
    dispatch(toggleDialogOpen(true))
    dispatch(updateHeaderText('Configure Statuses'))
    dispatch(updateBody(<EditStatusDialog statusOptions={column.multiOptions} columnId={colkey} />))
  }

  const onDeleteButtonClick = (e) => {
    e.preventDefault()
    deleteEntry(colkey)
    closeDropdown()
  }

  const closeDropdown = () => {
    setDropdownOpen(false)
  }

  return (
    <div className="mb-6">

      <div className="colName">
        <img src={statusIcon} className="field_icon" />
        <label htmlFor={column.name}>{column.name}</label>
      </div>

      {
        !canEdit && !shouldAllowNewEntry &&
        <div className="field">{formData[colkey]}</div>
      }

      {
        (shouldAllowNewEntry || canEdit) &&
        <CustomInputDropdown
          fieldValue={formData[colkey] ? formData[colkey] : 'Select Status'}
          inputType={`div`}
          container={`form`}
          inputClasses={`field ${statusColor == 'white' ? '' : 'text-white'}`}
          inputStyles={{ color: formData[colkey] ? '' : '#B6B6B6', backgroundColor: statusColor }}
          dropdownClasses={``}
          dropdownStyles={{ marginTop: 8, minWidth: '15rem' }}
          buttonsInfo={{ showButtonsSection: true, showDeleteButton: formData[colkey], onDeleteButtonClick, showEditButton: true, onEditButtonClick }}
          showRemoveIcon={false}
          showDropDownMenu={dropdownOpen}
          setShowDropDownMenu={setDropdownOpen}
          onFieldClick={() => { }}
        >
          <ul className="px-2 pt-3 overflow-auto max-h-40" style={{ fontSize: '0.9rem' }}>
            <div className="flex flex-col gap-3">
              {column.multiOptions && column.multiOptions.map((c, i) => {
                return (
                  <li
                    onClick={(e) => { e.preventDefault(); handleOptionClick(c.value) }}
                    key={i}
                    className="text-white truncate px-4 py-2 rounded-lg cursor-pointer" style={{ background: c.color }}
                  >
                    {c.value}
                  </li>
                )
              })}
            </div>
          </ul>
        </CustomInputDropdown>
      }
    </div>
  )
}

export default Status