
// third party
import DatePicker from "react-datepicker"

// icons 
import crossIcon from '../../../../../../../../../../../images/add-edit row dialog/cross-gray.png'
import dropDownIcon from '../../../../../../../../../../../images/add-edit row dialog/drop-down.png'

const DateRange = ({startRange, endRange, onRangeChange, getChecked}) => {
  return (
    <div class='child-in-center flex-col md:flex-row pb-2'>

      <div className='w-full md:w-1/2 md:pr-2 mb-2 md:mb-0' style={{ color: '#878787' }}>
        <div>From</div>
        <div className='relative flex items-center'>
          <DatePicker
            className="reminder-datetime-picker-form cursor-pointer focus:ring-transparent"
            selected={startRange}
            onChange={(date) => onRangeChange(date, 'startRange')}
            dateFormat="dd-MM-yyyy"
          />
          {
            startRange ?
              <img
                onClick={() => onRangeChange(null, 'startRange')}
                src={crossIcon}
                className='cross_icon z-10'
              /> :
              <img className='drop-down-icon transform transition-transform delay-50' src={dropDownIcon} />
          }
        </div>
      </div>

      <div className='w-full md:w-1/2 md:pl-2'>
        <div>To</div>
        <div className='relative flex items-center'>
          <DatePicker
            className="reminder-datetime-picker-form cursor-pointer focus:ring-transparent"
            selected={endRange}
            onChange={(date) => onRangeChange(date, 'endRange')}
            dateFormat="dd-MM-yyyy"
          />
          {
            endRange ?
              <img
                onClick={() => onRangeChange(null, 'endRange')}
                src={crossIcon}
                className='cross_icon z-10'
              /> :
              <img className='drop-down-icon transform transition-transform delay-50' src={dropDownIcon} />
          }
        </div>
      </div>
    </div>
  )
}

export default DateRange