import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

// components
import RedirectToSignUp from '../RedirectToSignup'
import ForgotPasswordSection from './ForgotPasswordSection'
import EmailSentComponent from '../EmailSentComponent'

// slices
import { loginUsingEmail, updateLoginInfoState } from '../../../../slices/LoginSlice'

// icons
import logo from '../../../../images/logo.png'
import googleIcon from '../../../../images/login/google.png'
import phNoIcon from '../../../../images/login/phNo.png'
import qrIcon from '../../../../images/login/qr.png'
import waveIcon from '../../../../images/login/wave.png'
import closeEye from '../../../../images/login/close-eye.png'
import openEye from '../../../../images/login/open-eye.png'

const LoginByEmail = ({ handleLoginMethodChange, LOGIN_METHODS }) => {

  // redux variales
  const dispatch = useDispatch()
  const loginInfo = useSelector(state => state.loginInfo)

  // state variables
  const [redirectToSignup, setRedirectToSignup] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showForgotPasswordSection, setShowForgotPasswordSection] = useState(false)

  // local variables
  const { isLoading, error, errorMessage, emailSentToUser, manifestJsonDetail } = loginInfo
  const isDisabled = !email || !password

  // console.log('manifest details:', manifestJsonDetail)
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    removeError()
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
    removeError()
  }

  const removeError = () => {
    if (error) {
      dispatch(updateLoginInfoState([{ key: 'error', value: false }, { key: 'errorMessage', value: null }]))
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword)
  }

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      dispatch(updateLoginInfoState([{ key: 'error', value: true }, { key: 'errorMessage', value: 'Please enter a valid email address' }]))
    } else if (password.length < 8) {
      dispatch(updateLoginInfoState([{ key: 'error', value: true }, { key: 'errorMessage', value: 'The password should be at least 8 characters long' }]))
    } else dispatch(loginUsingEmail({ emailId: email, password }));
  }

  const onForgotPasswordClick = () => {
    dispatch(updateLoginInfoState([{ key: 'error', value: 'false' }, { key: 'errorMessage', value: null }]))
    setShowForgotPasswordSection(true)
  }

  // classes
  const buttonContainer = `child-in-center mx-auto rounded-2xl h-14 w-80 max-w-full gap-2 font-medium relative my-4`
  const inputContainer = `rounded-2xl h-14 w-80 max-w-full my-4 mx-auto`
  const inputClass = `rounded-2xl h-full w-full pl-5 bg-transparent grayPlaceholder font-medium`
  const dividerText = `absolute bottom-0 translate-y-1/2 transform text-center font-medium px-4 child-in-center h-4`
  const eyeClass = `absolute right-4 w-5 cursor-pointer`

  // styles
  const inputStyle = { border: '1px solid #ACB5BD' }

  if (redirectToSignup) {
    return <Redirect to='/SignUp' />
  }

  if (showForgotPasswordSection) {
    return <ForgotPasswordSection email={email} setEmail={setEmail} setShowForgotPasswordSection={setShowForgotPasswordSection} />
  }

  if (emailSentToUser) { return <EmailSentComponent email={email} /> }

  return (
    <div className="overflow-auto h-full no-scrollbar text-center py-8">
      <div className='mb-6'><img src={logo} className='mx-auto h-8' /></div>

      <div>
        <div className="font-bold text-3xl mb-2 flex items-center justify-center" style={{ color: '#22252A' }}>
          <span className='relative'>Welcome back<img src={waveIcon} className='h-6 inline ml-3 absolute left-full top-1' /></span>
        </div>
        <div className="font-medium mb-8" style={{ color: '#6C757D' }}>Glad to see you, how would you like to proceed?</div>
      </div>

      <div>
        <form onSubmit={handleSubmit}>

          <div className={inputContainer}>
            <input
              type="email" value={email} onChange={handleEmailChange}
              required className={inputClass} style={inputStyle} placeholder='Enter your Email'
            />
          </div>

          <div className={`relative flex items-center ${inputContainer}`}>
            <input
              type={showPassword ? 'text' : 'password'} value={password} onChange={handlePasswordChange}
              required className={inputClass} style={inputStyle} placeholder='Enter your Password'
            />
            <img
              onClick={togglePasswordVisibility}
              src={showPassword ? openEye : closeEye}
              className={eyeClass}
              loading="lazy"
            />
          </div>

          {/* error msg */}
          {error && <p className="font-medium" style={{ color: '#F5291C', fontSize: '15px' }}><span>{errorMessage}</span></p>}
          {/* error msg */}

          <button type="submit" disabled={isDisabled} className={`${buttonContainer} text-white mt-6 ${isDisabled ? 'cursor-not-allowed' : ''}`} style={{ backgroundColor: isDisabled ? '#DDDDDD' : '#35A600' }}>
            {
              isLoading ? (
                <div className="animate-spin rounded-full h-7 w-7 border-b-2 border-gray-100 m-auto "></div>
              ) : (
                <span>Login</span>
              )
            }
          </button>
        </form>
        <div onClick={onForgotPasswordClick} className='font-semibold cursor-pointer text-sm' style={{ color: '#0B99FF' }} >Forgot Password?</div>
      </div>

      {/* divider */}
      <div className='relative border-b my-8 w-80 max-w-full mx-auto text-center child-in-center' style={{ borderColor: '#ACB5BD' }}>
        <div className={dividerText} style={{ backgroundColor: '#F8F9FA', color: '#ACB5BD' }}>Or</div>
      </div>
      {/* divider */}

      {/* Other login options */}
      <div>
        {/* <button className={buttonContainer} style={{ border: '1px solid #ACB5BD', }}>
          <img src={googleIcon} className='h-6' loading='lazy' />
          <span className='font-semibold'>Login using Google</span>
        </button> */}
        <button onClick={() => { handleLoginMethodChange(LOGIN_METHODS.PHONE_NUMBER); removeError() }} className={`${buttonContainer}`} style={{ border: '1px solid #ACB5BD', }}>
          <div className='flex gap-3 justify-left px-8 w-full'>
            <img src={phNoIcon} className='h-6' loading='lazy' />
            <span className='font-semibold'>Login using Phone Number</span>
          </div>
        </button>
        <button onClick={() => { handleLoginMethodChange(LOGIN_METHODS.QR_CODE); removeError() }} className={buttonContainer} style={{ border: '1px solid #ACB5BD' }}>
          <div className='flex gap-3 justify-left px-8 w-full'>
            <img src={qrIcon} className='h-6' loading='lazy' />
            <span className='font-semibold'>Scan QR code to Login</span>
          </div>
        </button>

      </div>
      {/* Other login options */}

      <div className='mt-8'><RedirectToSignUp setRedirectToSignup={setRedirectToSignup} /></div>

    </div >
  )
}

export default LoginByEmail