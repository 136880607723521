import React, { useEffect, useState, useRef } from "react"
import { useSelector } from 'react-redux'
import ReactDOM, { createPortal } from 'react-dom'

// utils
import TimeKeeper from 'react-timekeeper'
import ErrorBoundary from '../../../../../utils/ErrorBoundary'
import { getValidatedTime } from '../../Utils'

const TimeEditor = ({ rowProperties, saveCurrentRowData, colIdx, columnEl }) => {
  // redux variables 
  const registerData = useSelector(state => state.register)

  // local variables
  const colKey = columnEl.key
  const onClose = rowProperties.onClose
  const rowIndex = rowProperties.rowIdx
  const rowId = rowProperties.row.rowId
  const value = getValidatedTime(rowProperties.row[colKey])

  // state variables
  const [time, setTime] = useState(value)
  //const [pos, setPos] = useState({})

  // ref variables
  const timeKeeperRef = useRef(null)
  const timeTriggerBtnRef = useRef()
  const timeBox = useRef(null)

  useEffect(() => {
    setTime(value ? value : getCurrentTime())
    // Sets position of the time keeper in portal w.r.t the trigger button
    // using its ref.
    //onScroll()
    // listening to table scroll represented by .rdg-light class
    //document.querySelector(".rdg-light").addEventListener("scroll", onScroll)
    document.addEventListener('keydown', keyHandler);
    return () => {
      //document.removeEventListener("scroll", onScroll)
      document.removeEventListener('keydown', keyHandler);
    }
  }, [])

  const keyHandler = (event) => {
    if (event.keyCode == 9) {
      if (document.activeElement.classList.contains('rdg-focus-sink') ||
        document.activeElement.classList.contains('sheetItem1') ||
        document.activeElement.id === 'time-editor-cross-icon-btn') {
        document.getElementById('time-editor-portal-container').focus();
      }
      if (document.activeElement.innerText === 'PM') {
        document.getElementById('time-editor-container-div').focus();
      }
    }
  }

  const getCurrentTime = () => {
    const date = new Date()
    const timeArray = []
    const hour = date.getHours()
    const minute = date.getMinutes()
    let meridiem
    let hour1
    if (hour < 12) {
      meridiem = 'AM'
      hour1 = hour
    } else {
      meridiem = 'PM'
      hour1 = hour - 12
    }
    if (hour1 < 10) {
      timeArray[0] = `0${hour1}`
    } else {
      timeArray[0] = hour1
    }
    timeArray[1] = ':'
    if (minute < 10) {
      timeArray[2] = `0${minute}`
    } else {
      timeArray[2] = minute
    }
    timeArray[3] = ' '
    timeArray[4] = meridiem
    let finalTimeStr = timeArray.join('')
    return finalTimeStr
  }

  const onTimeTriggerBtnClick = (e) => {
    e.preventDefault();
    if (!time) setTime(getCurrentTime())
  }

  // handles key events of the editor container div
  const handleContainerDivKeyDown = (e) => {
    // enter key press
    if (e.keyCode == 13) {
      // Trigger click of the active element on which the focus is.
      document.activeElement.click();
      onClose(true)
    }
  }

  return (
    <ErrorBoundary>
      <div id="time-editor-container-div" tabIndex='-1' className="bg-white flex items-center justify-between pr-4" onKeyDown={handleContainerDivKeyDown}>
        <button tabIndex='0' id={'time-editor-trigr-btn'} ref={timeTriggerBtnRef} onClick={onTimeTriggerBtnClick} className="w-full h-full pl-5 flex items-center mr-4">
          <div>{time}</div>
        </button>
      </div>
      {/*Portal starts here*/}
      {time && createPortal(
        <div ref={timeBox} tabIndex={-1} id="time-editor-portal-container" className="fixed inset-0 w-screen h-full flex justify-center items-center" tabIndex="-1" style={{ fontSize: '12px', zIndex: '205' }} >
          <div onClick={(e) => { e.preventDefault(); onClose(true) }} className='fixed flex justify-center items-center w-full h-full left-0 right-0 top-0 bottom-0' style={{ backgroundColor: "rgba(0,0,0,0.4)", zIndex: '205' }}>
          </div>
          {/*Options in the form of anchor and non active button starts here*/}
          <div className='text-white p-1 cursor-pointer rounded-full' style={{ zIndex: '206', position: 'relative' }}>
            <span
              onClick={(e) => { e.preventDefault(); onClose(true) }}
              className="text-sm w-5 h-5 rounded-full flex items-center justify-center"
              style={{ zIndex: '207', position: 'absolute', right: 0, backgroundColor: '#586C9E', top: -1 }}
              tabIndex='0'
              id={'time-editor-cross-icon-btn'}>
              x
            </span>
            <TimeKeeper
              ref={timeKeeperRef}
              time={time}
              onChange={(newTime) => {
                setTime(newTime.formatted12.toUpperCase())
              }}
              doneButton={() => (
                <div className="flex h-full w-full">
                  <div
                    className="w-1/2"
                    style={{ textAlign: 'center', padding: '10px 0', color: '#ff4d4d' }}
                    onClick={() => { setTime(null); saveCurrentRowData('', colKey, rowId, registerData.rows) }}
                  >
                    Clear Entry
                </div>
                  <div
                    className="w-1/2"
                    style={{ textAlign: 'center', padding: '10px 0', backgroundColor: '#586C9E' }}
                    onClick={() => saveCurrentRowData(time, colKey, rowId, registerData.rows)}
                  >
                    Done
                </div>
                </div>
              )
              }
              switchToMinuteOnHourSelect
            />
          </div>
          {/*Options in the form of anchor and non active button ends here*/}

        </div>,
        document.body)}
      {/*Portal ends here*/}

    </ErrorBoundary>
  )
}

export default TimeEditor