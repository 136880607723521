import { useState } from 'react'

//images and icons
import editIcon from '../../../../../images/dialog/status/edit.png'

const StatusListItem = ({
  option,
  index,
  isEditStatusDialogOpen,
  openEditStatusDialog,
  setStatusInEditMode,
  noOfStatuses,
  deleteStatus
}) => {

  //state variables
  const [indexOfEditMenu, setIndexOfEditMenu] = useState(-1)

  const clickEditIcon = (index) => {
    if (indexOfEditMenu != -1) closeEditMenu()
    else if (!isEditStatusDialogOpen) setIndexOfEditMenu(index)
  }

  const onEditOptionClick = () => {
    setStatusInEditMode({ ...option })
    openEditStatusDialog()
    closeEditMenu()
  }

  const onDeleteOptionClick = () => {
    deleteStatus(index)
    closeEditMenu()
  }

  const closeEditMenu = () => {
    setIndexOfEditMenu(-1)
  }

  const classes = {
    flex: `flex justify-between items-center`,
    listItem: `p-3 mb-4 rounded text-white `,
    editIcon: `h-4 transform scale-100 transition-transform delay-50 ease-in ${isEditStatusDialogOpen ? '' : 'hover:scale-125 cursor-pointer'}`,
    editIconContainer: `relative`,
    editMenu: `absolute bg-white border border-gray-400 text-black right-full -top-1/4 mr-0.5 shadow-lg rounded-sm transition-all delay-50 ease-in`,
    editMenuItem: `w-20 text-sm pl-4 py-0.5 hover:bg-gray-200 cursor-pointer trasition-colors delay-50 ease-in-out`
  }

  if (!option) return <div></div>
  return (
    <div
      className={`${classes.listItem} ${classes.flex}`}
      style={{ background: option.color }}
    >
      {option.value}
      
      <div className={classes.editIconContainer} onMouseLeave={() => closeEditMenu()}>

        {/* Edit icon */}
        <img
          onClick={() => clickEditIcon(index)}
          onMouseEnter={() => clickEditIcon(index)}
          src={editIcon}
          className={classes.editIcon}
        />
        {/* Edit icon */}

        {/* Edit menu */}
        <div className={`${classes.editMenu} ${indexOfEditMenu == index ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-2 pointer-events-none'}`}>
          <div
            className={classes.editMenuItem}
            onClick={onEditOptionClick}
          >
            Edit
          </div>
          {/* Delete option in edit menu is not shown for 1st and last status */}
          {
            (index != 0 && index != noOfStatuses - 1) &&
            <div onClick={onDeleteOptionClick} className={classes.editMenuItem}>Delete</div>
          }
        </div>
        {/* Edit menu */}

      </div>
      
    </div>
  )
}

export default StatusListItem