import PortalPopup from "../../../partials/PortalPopup"

// icons
import deletePopupIcon from '../../../images/add-edit row dialog/delete-row.png'

const DeletePopup = ({
  closePopup,
  headerText,
  subHeaderText,
  deleteBtnText,
  onConfirm
}) => {
  return (
    <PortalPopup
      onClose={closePopup}
      containerClass={`w-full bg-white flex flex-col justify-center px-6 xs:w-auto rounded-lg m-2`}
    >
      <div className='child-in-center'><img src={deletePopupIcon} className='h-16 mt-6' /></div>
      <div className="text-lg text-center mt-4 font-semibold" style={{ color: '#545454' }}>{headerText}</div>
      <div className="text-sm text-center mt-2 mb-4 mx-auto" style={{ color: '#545454', maxWidth: '20rem' }}>
        {subHeaderText}
      </div>
      {/* Buttons */}
      <div className="flex justify-center sm:justify-end items-center gap-4 mt-3 mb-6">
        <button onClick={(e) => { e.preventDefault(); closePopup() }} className="py-1 px-4 secondary_btn w-1/2 sm:w-auto">Cancel</button>
        <button onClick={(e) => { e.preventDefault(); onConfirm(); closePopup() }} className="text-white rounded-lg py-1 px-4 w-1/2 sm:w-auto hover:bg-red-700 bg-red-600 transition-colors delay-50">{deleteBtnText}</button>
      </div>
      {/* Buttons */}
    </PortalPopup>
  )
}

export default DeletePopup