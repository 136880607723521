import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

//icons
import grayIcon from '../images/3-dots-gray.png'
import whiteIcon from '../images/3-dots-white.png'

const CustomThreeDotMenu = ({ showDropdown, color, colorWhenOpen, alignment, direction, listData, itemIdx }) => {

  //redux variables
  const theme = useSelector(state => state.theme)

  //state variables
  const [show, setShow] = useState(false)

  //local variables
  const { secondary } = theme

  useEffect(() => {
    const clickHandler = () => {
      if (show) setShow(false)
    }
    if (show) {
      document.addEventListener('click', clickHandler)
    }
    return () => document.removeEventListener('click', clickHandler)
  }, [show])

  const getIconSrc = () => {
    // if(show){
    //   if(colorWhenOpen == 'blue') return blueIcon
    // }
    if (color == 'gray') return grayIcon
    if (color == 'white') return whiteIcon
    return
  }

  // classes
  const container = `relative rounded transition-colors delay-50 p-0.5`
  const icon = `transform cursor-pointer ${alignment == 'vertical' ? 'rotate-90 h-5' : 'w-4'}`
  const menuContainer = `
    absolute slide_down_50 rounded w-40 overflow-hidden
    ${alignment == 'vertical' ?
      `top-1/2 ${direction == 'right' ? 'left-3/4' : 'right-3/4'}` :
      `top-3/4 ${direction == 'right' ? 'left-1/2' : 'right-1/2'}`
    }
  `
  const listItemContainer = `bg-white h-12 flex items-center`
  const listItem = `flex px-4 h-full w-full gap-2 items-center truncate bg-white cursor-pointer`
  const loader = `animate-spin rounded-full h-6 w-6 border-b-2 border-gray-600 m-auto`

  //styles
  const containerStyle = { backgroundColor: show ? 'rgba(255, 255, 255, 0.24)' : '' }
  const menuContainerStyle = { boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', zIndex: '100' }

  return (
    <div className={container} style={containerStyle}>

      {/* 3 dots */}
      <img src={getIconSrc()} onClick={(e) => { e.stopPropagation(); setShow(!show) }} className={icon} />
      {/* 3 dots */}

      {/* dropdown menu starts */}
      {
        show && showDropdown &&
        <div className={menuContainer} style={menuContainerStyle} onClick={(e) => e.stopPropagation()}>
          {
            listData && listData.map((item, i) => {
              return (
                item.showItem && (
                  <div className={listItemContainer}>
                    {
                      item.showLoader ?
                        <div className={loader}></div> :
                        <li key={`${item.label}-${i}`} className={listItem} onClick={() => {item.onClick(itemIdx); setShow(false)}}>
                          <img className={item.iconClass} src={item.icon} />
                          <span style={item.style}>{item.label}</span>
                        </li>
                    }
                  </div>
                )
              )
            })
          }
        </div>
      }
      {/* dropdown menu ends */}

    </div>
  )
}

export default CustomThreeDotMenu