import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfigFile from '../css/tailwind.config';
import { evaluate, filter, parse } from 'mathjs';

// icons
import constructionCategoryIcon from '../images/categoryIcons/constructionCategoryIcon.png'
import factoryCategoryIcon from '../images/categoryIcons/factoryCategoryIcon.png'
import homeServicesIcon from '../images/categoryIcons/homeServicesIcon.png'
import microFinanceCategoryIcon from '../images/categoryIcons/microFinanceCategoryIcon.png'
import personalCategoryIcon from '../images/categoryIcons/personalCategoryIcon.png'
import propertyCategoryIcon from '../images/categoryIcons/propertyCategoryIcon.png'
import schoolCategoryIcon from '../images/categoryIcons/schoolCategoryIcon.png'
import shopCategoryIcon from '../images/categoryIcons/shopCategoryIcon.png'
import transportCategoryIcon from '../images/categoryIcons/transportCategoryIcon.png'
import wholeSalerCategoryIcon from '../images/categoryIcons/wholeSalerCategoryIcon.png'
import eventManagementIcon from '../images/categoryIcons/eventManagementIcon.png'
import healthFitnessIcon from '../images/categoryIcons/healthFitnessIcon.png'
import studentIcon from '../images/categoryIcons/studentIcon.png'
import insuranceAgentIcon from '../images/categoryIcons/insuranceAgentIcon.png'
import farmingIcon from '../images/categoryIcons/farmingIcon.png'
import travelIcon from '../images/categoryIcons/travelIcon.png'
import othersIcon from '../images/categoryIcons/othersIcon.png'

const moment = require('moment');

export const allBusinessCategories = [
  { name: 'Construction', icon: constructionCategoryIcon, type: 'construction', id: 1 },
  { name: 'Factory', icon: factoryCategoryIcon, type: 'factories', id: 2 },
  { name: 'Home Services', icon: homeServicesIcon, type: 'home_services', id: 3 },
  { name: 'Micro Finance', icon: microFinanceCategoryIcon, type: 'micro_finance', id: 4 },
  { name: 'Personal', icon: personalCategoryIcon, type: 'personal', id: 5 },
  { name: 'Property', icon: propertyCategoryIcon, type: 'real_estate', id: 6 },
  { name: 'School', icon: schoolCategoryIcon, type: 'schools', id: 7 },
  { name: 'Shop', icon: shopCategoryIcon, type: 'shop', id: 8 },
  { name: 'Transport', icon: transportCategoryIcon, type: 'transport', id: 9 },
  { name: 'Whole Saler', icon: wholeSalerCategoryIcon, type: 'wholeSaler', id: 10 },
  { name: 'Distributors', icon: othersIcon, type: 'distributors', id: 11 },
  { name: 'Event Management', icon: eventManagementIcon, type: 'event_management', id: 12 },
  { name: 'Hospitals', icon: othersIcon, type: 'hospitals', id: 13 },
  { name: 'Restaurant Canteen', icon: othersIcon, type: 'restaurant_canteen', id: 14 },
  { name: 'Health Fitness', icon: healthFitnessIcon, type: 'health_fitness', id: 15 },
  { name: 'Apartment', icon: othersIcon, type: 'apartment', id: 16 },
  { name: 'Student', icon: studentIcon, type: 'student', id: 17 },
  { name: 'Insurance Agent', icon: insuranceAgentIcon, type: 'insurance_agent', id: 18 },
  { name: 'Farming', icon: farmingIcon, type: 'farming', id: 19 },
  { name: 'Travel', icon: travelIcon, type: 'travel', id: 20 },
]


export const registerInitialStateArr = [
  { key: 'registerId', value: '' },
  { key: 'activeSheetName', value: '' },
  { key: 'registerName', value: '' },
  { key: 'noOfMembers', value: '' },
  { key: 'registerAvatar', value: '' },
  { key: 'name', value: '' },
  { key: 'rows', value: [] },
  { key: 'filteredRows', value: [] },
  { key: 'sheetId', value: '' },
  { key: 'columns', value: [] },
  { key: 'sortedColKey', value: '' },
  { key: 'sortDirection', value: 0 }
]

export const registerInitialStateObj = {
  activeSheetId: "",
  activeSheetName: "",
  columns: [],
  dataAddition: "",
  defaultView: 1,
  deleteRegisterEnabled: true,
  editPermissions: [{ permissionType: "COLUMN_BASED", detail: [] }],
  filters: [],
  folderId: null,
  groupDataBy: "",
  groupId: "",
  isAdvanceViewPermissionsPresent: false,
  isLocked: false,
  name: "",
  newSheetAdditionEnabled: true,
  noOfMembers: '',
  permissions: [],
  registerAvatar: "",
  registerId: "",
  registerPublicLink: { link: null, settings: null },
  rowDetailsViewType: "one",
  rowLinkShared: false,
  rows: [],
  sheetId: "",
  sorts: [],
  timestamp: '',
  viewOnly: false,
  // FE specific variables
  registerName: '',
  filteredRows: [],
  sortedColKey: '',
  sortDirection: 0,
}
export const tailwindConfig = () => {
  // Tailwind config
  return resolveConfig(tailwindConfigFile)
}

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const formatValue = (value) => Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumSignificantDigits: 3,
  notation: 'compact',
}).format(value);

export const alphabetSet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AT", "AU", "AV", "AW", "AX", "AY", "AZ", "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BK", "BL", "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU", "BV", "BW", "BX", "BY", "BZ", "CA", "CB", "CC", "CD", "CE", "CF", "CG", "CH", "CI", "CJ", "CK", "CL", "CM", "CN", "CO", "CP", "CQ", "CR", "CS", "CT", "CU", "CV", "CW", "CX", "CY", "CZ", "DA", "DB", "DC", "DD", "DE", "DF", "DG", "DH", "DI", "DJ", "DK", "DL", "DM", "DN", "DO", "DP", "DQ", "DR", "DS", "DT", "DU", "DV", "DW", "DX", "DY", "DZ", "EA", "EB", "EC", "ED", "EE", "EF", "EG", "EH", "EI", "EJ", "EK", "EL", "EM", "EN", "EO", "EP", "EQ", "ER", "ES", "ET", "EU", "EV", "EW", "EX", "EY", "EZ"]

Number.prototype.countDecimals = function () {
  if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
  return (this.toString().split(".")[1] && this.toString().split(".")[1].length) || 0;
}

const getComputedValue = (objToBeComputed) => {
  let string = ''
  const arrayOfExpressions = objToBeComputed.arr
  // 1. Here an expression means the 'A' or '+' or 'B' in a formula array ['A','+','B']
  // 2. Which we recieve here in converted form like A =>  {dataType:'numerator', value: 2} as one of the object
  //    in arrayOfExpressions.arr
  const typeOfCalculation = objToBeComputed.calculationType.type
  if (!typeOfCalculation) return string = 'Invalid Formula'
  // {--------------maths---------------} 
  {/*
    // supporting n number of expressions in an array
  */}
  if (typeOfCalculation === 'maths') {
    let value = 0
    arrayOfExpressions.forEach((el) => {
      string = `${string} ${el.value}`
    })
    value = evaluate(string)
    return !!value && value.countDecimals() > 2 ? value.toFixed(2) : value
  }
  // {--------------date---------------} 
  {/*
    Case 1: operation of ADDITION and SUBTRACTION between two dates ['date','+','date'], ['date','-','date']
    Case 2: operation of ADDITION between a date and a numerator ['date','+','numerator'],  ['numerator','+','date']
    // supporting just three expressions in an array
  */}
  if (typeOfCalculation === 'date') {
    if (arrayOfExpressions.length > 3 || arrayOfExpressions.length < 3) return string = 'Invalid Formula'
    const operator = arrayOfExpressions[1].value
    const isDateDate = checkDateDate(arrayOfExpressions)
    const expression1 = arrayOfExpressions[0].value
    const expression2 = arrayOfExpressions[2].value
    const atleast_one_date_exists = /-/.test(expression1) || /-/.test(expression2)
    if (isDateDate) {
      const date1 = moment(expression1, 'DD-MM-YYYY')
      const date2 = moment(expression2, 'DD-MM-YYYY')
      if (operator === '+') { // ['date','+','date']
        const days_in_date1 = parseInt(date1.format('D'))
        const days_in_date2 = parseInt(date2.format('D'))
        string = days_in_date1 + days_in_date2
      } else if (operator === '-') { // ['date','-','date']
        string = Math.abs(date2.diff(date1, 'days')) + 1
      }
    } else if (atleast_one_date_exists) { // either one of the expression should contain date
      const is_expression1_date = /-/.test(expression1)
      const numeratorExpression = !is_expression1_date ? expression1 : expression2
      const dateExpression = is_expression1_date ? expression1 : expression2
      const requiredDateFormat = moment(dateExpression, 'DD-MM-YYYY')
      const date_operatedBy_numerator = requiredDateFormat.add(parseFloat(numeratorExpression), 'days')
      string = date_operatedBy_numerator.format('D')
    }
  }

  // {--------------time---------------} 
  {/*
    Case 1: operation of SUBTRACTION between two times ['time','-','time']
    Case 2: operation of ADDITION between a time and a numerator ['time','+','numerator'],  ['numerator','+','time']
    // supporting just three expressions in an array
  */}
  if (typeOfCalculation === 'time') {
    if (arrayOfExpressions > 3 || arrayOfExpressions < 3) return string = 'Invalid Formula'
    const operator = arrayOfExpressions[1].value
    const expression1 = arrayOfExpressions[0].value
    const expression2 = arrayOfExpressions[2].value
    const atleast_one_time_exists = /:/.test(expression1) || /:/.test(expression2)
    // ['time','-','time']
    if (arrayOfExpressions[0].dataType === 'time' && arrayOfExpressions[2].dataType === 'time' && operator === '-') {
      const start = new Date("2020 01 01 " + expression1).getTime()
      const end = new Date("2020 01 01 " + expression2).getTime()
      const diff = moment.duration(Math.abs(start - end))
      const hours = diff.hours() > -1 ? diff.hours() : 0
      const mins = diff.minutes() > -1 ? diff.minutes() : 0
      string = `${hours} hr ${mins} mins`
    } else if (operator === '+' && atleast_one_time_exists) { // either one of the expression should contain time
      let timeExpression = /:/.test(expression1) ? moment.utc(expression1, "h:mm A") : moment.utc(expression2, "h:mm A")
      const numeratorExpression = !/:/.test(expression1) ? expression1 : expression2
      const timeAfterAddition = timeExpression.add(numeratorExpression, 'hours')
      string = `${moment.utc(timeAfterAddition).format('hh:mm A')}`
    }
  }

  // {--------------Formula---------------} 
  if (typeOfCalculation === 'amountAccTohrs') {
    {/*
      Case1: operation of MULTIPLICATION between x hr x min & someNumber in ['x hr x min', '*', 'someNumber'] or ['someNumber', '*', 'x hr x min']
      // supporting just three expressions in an array
    */}

    // Check if any element in arrayOfExpressions has "Invalid Formula" value
    const hasInvalidFormula = arrayOfExpressions.some(el => el.value === 'Invalid Formula')

    if (hasInvalidFormula) {
      // Handle invalid formula case here
      return 'Invalid Formula';
    } else if (arrayOfExpressions === 3) {
      const operator = arrayOfExpressions[1].value
      const expression1 = arrayOfExpressions[0].value
      const expression2 = arrayOfExpressions[2].value
      const alteast_one_hr_min_exists = /hr/.test(expression1) || /hr/.test(expression2)
      if (operator === '*' && alteast_one_hr_min_exists) { // specifically for hr and min based formula and either one of the expression should contain hr mins
        const hrMin_expression = /hr/.test(expression1) || /mins/.test(expression1) ? expression1 : expression2
        const numerator = !(/hr/.test(expression1) || /mins/.test(expression1)) ? expression1 : expression2
        let hourTime = parseInt(hrMin_expression.split(' ')[0])
        let minute = parseFloat((parseInt(hrMin_expression.split(' ')[2]) / 60).toFixed(2))
        const value = evaluate((hourTime + minute) * numerator)
        string = value.countDecimals() > 1 ? value.toFixed(1) : value
      }
    } else { // new case 
      let value = 0
      arrayOfExpressions.forEach((el) => {
        string = `${string} ${/hr/.test(el.value) || /mins/.test(el.value) ? 0 : el.value}`
      })
      value = evaluate(string)
      return !!value && value.countDecimals() > 2 ? value.toFixed(2) : value
    }
    // return string = 'Invalid Formula'
  }

  // {----------Duration--------------------}
  if (objToBeComputed.calculationType.type === 'durationCalcuation') {
    {/*
      Formula when duration use (+,×,÷,-) with number
      Case 1: D1 and D2 - D1 + D2, D1 - D2, D1 × D2, D1 ÷ D2
      Case 2: D1 and Numerator - D1 + Numerator, D1 - Numerator, D1 × Numerator, D1 ÷ Numerator
      // supporting just three expressions in an array
    */}

    if (arrayOfExpressions.length > 3 || arrayOfExpressions.length < 3) return string = 'Invalid Formula'
    const operator = objToBeComputed.arr[1].value
    const isDurationDuration = checkDurationDuration(arrayOfExpressions)
    const expression1 = arrayOfExpressions[0].value
    const expression2 = arrayOfExpressions[2].value
    const alteast_one_hr_min_exists = /hr/.test(expression1) || /hr/.test(expression2) || /mins/.test(expression1) || /mins/.test(expression2)
    if (isDurationDuration) {
      const hrTime_expression1 = getHrMinStrings(expression1, 'hr')
      const hrTime_expression2 = getHrMinStrings(expression2, 'hr')
      const hrTime_expression1_toMinutes = hrTime_expression1 * 60
      const hrTime_expression2_toMinutes = hrTime_expression2 * 60
      const minTime_expression1 = getHrMinStrings(expression1, 'mins')
      const minTime_expression2 = getHrMinStrings(expression2, 'mins')
      const totalMinutes_expression1 = hrTime_expression1_toMinutes + minTime_expression1
      const totalMinutes_expression2 = hrTime_expression2_toMinutes + minTime_expression2
      let operatedDuration = ''
      if (operator === '-') operatedDuration = totalMinutes_expression1 - totalMinutes_expression2
      else if (operator === '+') operatedDuration = totalMinutes_expression1 + totalMinutes_expression2
      else if (operator === '/') operatedDuration = (totalMinutes_expression1 / totalMinutes_expression2) * 60
      else if (operator === '*') operatedDuration = (totalMinutes_expression1 * totalMinutes_expression2) / 60
      string = getHrMinFormat(operatedDuration)
    } else if (alteast_one_hr_min_exists) { // either one of the expression should contain hr mins
      const is_expression1_hrMin = /hr/.test(expression1) || /mins/.test(expression1)
      let numerator = !is_expression1_hrMin ? expression1 : expression2
      const hrMin_Expression = is_expression1_hrMin ? expression1 : expression2
      const hrTime = getHrMinStrings(hrMin_Expression, 'hr')
      const minTime = getHrMinStrings(hrMin_Expression, 'mins')
      const minTime_toHrs = round(parseFloat(minTime / 60).toFixed(2), 2)
      numerator = numerator !== '' ? numerator : 0
      let operatedDuration;
      if (operator === '-') operatedDuration = evaluate((hrTime + minTime_toHrs) - numerator)
      else if (operator === '+') operatedDuration = evaluate((hrTime + minTime_toHrs) + numerator)
      else if (operator === '/') operatedDuration = evaluate((hrTime + minTime_toHrs) / numerator)
      else if (operator === '*') operatedDuration = evaluate((hrTime + minTime_toHrs) * numerator)
      string = operatedDuration.countDecimals() > 2 ? operatedDuration.toFixed(2) : operatedDuration
    }
  }
  // {----------Duration--------------------}

  // {----------Switch--------------------}
  if (objToBeComputed.calculationType.type === 'switch') {
    if (arrayOfExpressions.length > 3 || arrayOfExpressions.length < 3) return string = 'Invalid Formula'
    const operator = objToBeComputed.arr[1].value
    const expression1 = arrayOfExpressions[0].value === 'Yes' ? 1 : (!parseInt(arrayOfExpressions[0].value) ? 0 : parseInt(arrayOfExpressions[0].value)) // Yes is considered 1 and No is considered as 0
    const expression2 = arrayOfExpressions[2].value === 'Yes' ? 1 : (!parseInt(arrayOfExpressions[2].value) ? 0 : parseInt(arrayOfExpressions[2].value)) // Yes is considered 1 and No is considered as 0
    if (operator === '-') string = Math.abs(expression1 - expression2)
    else if (operator === '+') string = expression1 + expression2
    else if (operator === '/') string = (expression1 / expression2)
    else if (operator === '*') string = (expression1 * expression2)
  }
  return string
}

const checkDateDate = (arrayOfExpressions) => {
  const expression1_type = arrayOfExpressions[0].dataType
  const expression2_type = arrayOfExpressions[2].dataType
  const isExpression1_DateType = expression1_type === 'date'
  const isExpression2_DateType = expression2_type === 'date'
  if (isExpression1_DateType && isExpression2_DateType) return true
  return false
}

const checkDurationDuration = (arrayOfExpressions) => {
  const expression1_type = arrayOfExpressions[0].dataType
  const expression2_type = arrayOfExpressions[2].dataType
  const isExpression1_DurationType = expression1_type === 'duration'
  const isExpression2_DurationType = expression2_type === 'duration'
  if (isExpression1_DurationType && isExpression2_DurationType) return true
  return false
}

const getHrMinFormat = (totalMinutes) => {
  var hours = Math.floor(totalMinutes / 60);
  var minutes = totalMinutes % 60;
  // if the minute is some decimal number
  if (minutes % 1 != 0) minutes = parseInt(minutes)
  return `${hours} hr ${minutes} mins`
}

const getHrMinStrings = (string, type) => {
  let type_value = 0
  const hrMinsArr = string.split(' ')
  if (hrMinsArr.length === 4) {
    if (type === 'hr') type_value = parseInt(hrMinsArr[0])
    if (type === 'mins') type_value = parseInt(hrMinsArr[2])
  } else if (hrMinsArr.length === 2) {
    if (hrMinsArr[1] === 'hr' && type === 'hr') type_value = parseInt(hrMinsArr[0])
    if (hrMinsArr[1] === 'mins' && type === 'mins') type_value = parseInt(hrMinsArr[0])
  }
  return type_value;
}

const round = (value, precision) => {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

const getRowVal = (useValueFromPrevsRow, prevsRow, colKey, currentRow, dataType) => {
  let rowVal = 0;
  if (dataType === 'formula' || dataType === 'duration') { // set default value
    rowVal = '0 hr 0 mins'
  }
  if (useValueFromPrevsRow) {
    if (prevsRow) { // ensures prevsRow is not undefined which simply means current row is not 1st
      rowVal = parseFloat(prevsRow[colKey])
    }
  } else if (currentRow[colKey] && currentRow[colKey].length > 0) {
    if (dataType === 'date' || dataType === 'time' || dataType === 'formula' || dataType === 'duration' || dataType === 'switch') {
      rowVal = currentRow[colKey]
    } else {
      rowVal = parseFloat(currentRow[colKey])
    }
  }
  return rowVal
}

export const isNumeric = (value) => {
  if (value === '') return false
  return /^-?\d+$/.test(value) || /^\d+\.\d+$/.test(value) || /^-?\d*\.?\d*$/.test(value);
}


{/*
  1- key => the column ( a formula type ) which is affected by the change in data cell
  2- keyValue => formula applied on this column eg: ['A','*','B']
  3- currentRow => the row on which updation has been done on a data cell which belongs to 'A' or 'B' column
  4- prevsRow => row just prior to currentRow 
  5- columns => all columns of the table
*/}
export const computeFormulaColVal = (key, keyValue, currentRow, prevsRow, columns) => {
  //console.log(" key ", key)
  //console.log(" keyValue ", keyValue)

  let formulaObjWithTypes = {}
  let formulaObjWithValues = {}

  formulaObjWithTypes[key] = { arr: [], calculationType: { type: null, followBodmas: null } }
  let hasNumber = false
  let hasRupee = false
  let hasSwitch = false
  let hasMail = false
  let hasString = false
  let hasLocation = false
  let hasImage = false
  let hasSignature = false
  let hasStatus = false
  let hasUrl = false
  let hasReminder = false
  let hasUnit = false
  let hasDropdown = false
  let hasPhoneNumber = false
  let hasDate = false
  let hasTime = false
  let hasNumerator = false
  let hasBracket = false
  let hasSubtraction = false
  let hasAddition = false
  let hasMultiplication = false
  let hasDivision = false
  let hasFormula = false
  let hasDuration = false


  keyValue.forEach((alphabetEl) => { // keyValue strctr eg: ['A', '*', 'B'] 
    let useValueFromPrevsRow = false;
    let colIdx = alphabetSet.indexOf(alphabetEl)
    if (colIdx === -1) {
      // check if its only a numerator in the raw array of formula like ['A', +, 2]
      if (Number.isFinite(parseFloat(alphabetEl)) && !alphabetEl.includes('-') && !alphabetEl.includes('PM') && !alphabetEl.includes('AM')) {
        hasNumerator = true
        formulaObjWithTypes[key].arr.push({
          dataType: 'numerator',
          value: parseFloat(alphabetEl)
        })
        return
      }
      // check if its an operator
      if (alphabetEl === '(' || alphabetEl === ')' || alphabetEl === '/' || alphabetEl === '*' || alphabetEl === '+' || alphabetEl === '-') {
        if (alphabetEl === '(' || alphabetEl === ')') {
          hasBracket = true
        } else if (alphabetEl === '-') {
          hasSubtraction = true
        } else if (alphabetEl === '+') {
          hasAddition = true
        } else if (alphabetEl === '*') {
          hasMultiplication = true
        } else if (alphabetEl === '/') {
          hasDivision = true
        }
        formulaObjWithTypes[key].arr.push({
          dataType: 'operator',
          value: alphabetEl
        })
        return
      }
    }
    // setting previous row's column picking from getRowValue
    if (alphabetEl.includes('$')) {
      useValueFromPrevsRow = true;
      colIdx = alphabetSet.indexOf(alphabetEl.replace('$', ''))
    }
    if (colIdx > -1) {
      formulaObjWithTypes[key].arr.push({
        dataType: columns[colIdx + 1].dataType,
        value: getRowVal(useValueFromPrevsRow, prevsRow, columns[colIdx + 1].key, currentRow, columns[colIdx + 1].dataType)
      })
    }
    // The below checks are done ( to not allow passing the values of dataTypes like switch etc to the calculating logic) 
    //  as currently we are supporting: 
    //  1) CALCULATOR for numbers, 
    //  2) DATE ( difference between two dates, addition of a number to a date ),
    //  3) TIME ( difference between two times, adding a number to time to increase hours ) and
    //  4) FORMULA ( wage calculation like 3hrs 4min multiplied by a number to get money )
    if (columns[colIdx + 1].dataType === 'number') {
      hasNumber = true
    }
    if (columns[colIdx + 1].dataType === 'rupee') {
      hasRupee = true
    }
    if (columns[colIdx + 1].dataType === 'switch') {
      hasSwitch = true
    }
    if (columns[colIdx + 1].dataType === 'mail') {
      hasMail = true
    }
    if (columns[colIdx + 1].dataType === 'String') {
      hasString = true
    }
    if (columns[colIdx + 1].dataType === 'location') {
      hasLocation = true
    }
    if (columns[colIdx + 1].dataType === 'image') {
      hasImage = true
    }
    if (columns[colIdx + 1].dataType === 'signature') {
      hasSignature = true
    }
    if (columns[colIdx + 1].dataType === 'status') {
      hasStatus = true
    }
    if (columns[colIdx + 1].dataType === 'url') {
      hasUrl = true
    }
    if (columns[colIdx + 1].dataType === 'reminder') {
      hasReminder = true
    }
    if (columns[colIdx + 1].dataType === 'unit') {
      hasUnit = true
    }
    if (columns[colIdx + 1].dataType === 'dropDown') {
      hasDropdown = true
    }
    if (columns[colIdx + 1].dataType === 'phoneNumber') {
      hasPhoneNumber = true
    }
    if (columns[colIdx + 1].dataType === 'time') {
      hasTime = true
    }
    if (columns[colIdx + 1].dataType === 'date') {
      hasDate = true
    }
    if (columns[colIdx + 1].dataType === 'formula') {
      hasFormula = true
    }
    if (columns[colIdx + 1].dataType === 'duration') {
      hasDuration = true
    }
  })

  // Below checks the object formed [{dataType:'', value:''},{dataType:'', value:''}..] above
  // has what all data types and we need to do that as we allow operations only on few combinations of dataTypes.

  // for normal calc
  if ((hasNumerator || hasNumber || hasUnit || hasRupee || hasString || hasDropdown) && !hasTime && !hasDate && !hasFormula && !hasSwitch) {
    formulaObjWithTypes[key].calculationType.type = 'maths'
    if (hasBracket) { // dont follow BODMAS
      formulaObjWithTypes[key].calculationType.followBodmas = true
    }
  }

  // for date with numerator calculation ( numerator addition to get further date )
  if (hasDate && ((hasNumerator || hasNumber || hasString || hasDropdown) && hasAddition) && !hasSwitch && !hasMail && !hasLocation && !hasImage && !hasSignature && !hasStatus && !hasUrl && !hasReminder && !hasPhoneNumber && !hasTime && !hasFormula) {
    formulaObjWithTypes[key].calculationType.type = 'date'
  }

  // for date with date calculation ( date subtraction to get difference in days )
  if (hasDate && (hasNumber || hasString || hasDropdown || hasSubtraction || hasAddition) && !hasSwitch && !hasMail && !hasLocation && !hasImage && !hasSignature && !hasStatus && !hasUrl && !hasReminder && !hasPhoneNumber && !hasTime && !hasFormula) {
    formulaObjWithTypes[key].calculationType.type = 'date'
  }

  // for time calculation
  if (hasTime && (hasNumerator || hasSubtraction || hasRupee || hasAddition || hasNumber || hasString || hasDropdown) && !hasMultiplication && !hasSwitch && !hasMail && !hasLocation && !hasImage && !hasSignature && !hasStatus && !hasUrl && !hasReminder && !hasPhoneNumber && !hasDate && !hasFormula) {
    formulaObjWithTypes[key].calculationType.type = 'time'
  }

  // for formula calculation for wage or some amount according to hours
  if (hasFormula && (hasMultiplication || hasNumerator || hasRupee || hasNumber || hasString || hasDropdown) && !hasSwitch && !hasMail && !hasLocation && !hasImage && !hasSignature && !hasStatus && !hasUrl && !hasReminder && !hasPhoneNumber && !hasDate && !hasTime) {
    formulaObjWithTypes[key].calculationType.type = 'amountAccTohrs'
  }

  // for duration
  if (hasDuration && (hasNumerator || hasMultiplication || hasSubtraction || hasAddition || hasDivision || hasRupee || hasNumber || hasString || hasDropdown) && !hasSwitch && !hasMail && !hasLocation && !hasImage && !hasSignature && !hasStatus && !hasUrl && !hasReminder && !hasPhoneNumber && !hasDate && !hasTime) {
    formulaObjWithTypes[key].calculationType.type = 'durationCalcuation'
  }

  // for switch
  if (hasSwitch && (hasNumerator || hasMultiplication || hasSubtraction || hasAddition || hasDivision || hasRupee || hasNumber || hasString || hasDropdown) && !hasMail && !hasLocation && !hasImage && !hasSignature && !hasStatus && !hasUrl && !hasReminder && !hasPhoneNumber && !hasDate && !hasTime && !hasDuration) {
    formulaObjWithTypes[key].calculationType.type = 'switch'
  }

  formulaObjWithValues[key] = getComputedValue(formulaObjWithTypes[key])
  // reset 
  hasNumber = false
  hasRupee = false
  hasSwitch = false
  hasMail = false
  hasString = false
  hasLocation = false
  hasImage = false
  hasSignature = false
  hasStatus = false
  hasUrl = false
  hasReminder = false
  hasUnit = false
  hasDropdown = false
  hasPhoneNumber = false
  hasDate = false
  hasTime = false
  hasNumerator = false
  hasBracket = false
  hasSubtraction = false
  hasAddition = false
  hasMultiplication = false
  hasFormula = false
  //console.log(" formulaObjWithTypes ", formulaObjWithTypes);
  //console.log(" formulaObjWithValues ", formulaObjWithValues);
  return formulaObjWithValues
}

// selectedVal can be 'Count'/'Average'/'Sum'

export const getSumCntAvgVal = (selectedVal, columnEl, rows) => {
  const colKey = columnEl.key
  let totalCount = 0
  let sum = 0
  let result = ''
  let lastIndex = rows.length - 1
  let hrsSum = 0;
  let minsSum = 0;
  let isItTimeCalcn = false
  let yesCount = 0;
  let noCount = 0;
  const isItSwitch = columnEl.dataType === 'switch'
  const isItImage = columnEl.dataType === 'image' || (columnEl.linkedDataType == 'image')
  const isItAttachment = columnEl.dataType === 'attachment'
  const isItLabel = columnEl.dataType === 'label'

  switch (selectedVal) {
    case 'Count':
      {/* Here the row loop helps in traversing all the dataCells in a column*/ }
      rows.forEach((el, index) => {
        if (index < lastIndex) { // to ignore its ownself or the last row data cell of the column
          const value = el[colKey] ? el[colKey] : ''
          if (value !== undefined && value !== null && value.toString().trim() !== '' && !isItSwitch && !isItImage && !isItAttachment && !isItLabel) { // number type
            totalCount++
          } else if (isItSwitch) { // switch type
            if (value.includes('Yes')) {
              yesCount++
            } else if (value.includes('No')) {
              noCount++
            }
          } else if (isItImage || isItAttachment || isItLabel) {
            const detailVal = el[colKey + '_details'] ? el[colKey + '_details'] : []
            if (detailVal.length > 0) totalCount++
          }
        }
      })
      result = isItSwitch ? `Yes: ${yesCount} / No: ${noCount}` : `Count: ${totalCount}`
      break;
    case 'Average':
      {/* Here the row loop helps in traversing all the dataCells in a column*/ }
      rows.forEach((el, index) => {
        if (index < lastIndex) { // to ignore its ownself or the last row data cell of the column
          const value = el[colKey] ? el[colKey] : ''
          if (isNumeric(value)) {
            sum = sum + parseFloat(value)
            totalCount++
          } else if (value.includes('hr') || value.includes('mins')) { // if its a sum of 'hr mins'
            if (!isItTimeCalcn) isItTimeCalcn = true
            const timeStringArr = value.split(' ')
            let hr = 0
            let mins = 0
            if (value.includes('hr') && value.includes('mins')) { // if both hr and mins are there
              hr = parseInt(timeStringArr[0] ? timeStringArr[0] : 0)
              mins = parseInt(timeStringArr[2] ? timeStringArr[2] : 0)
            } else if (value.includes('hr')) { // if just hr is there postion of it in split array matters
              hr = parseInt(timeStringArr[0] ? timeStringArr[0] : 0)
            } else if (value.includes('mins')) { // if just mins is there postion of it in split array matters
              mins = parseInt(timeStringArr[0] ? timeStringArr[0] : 0)
            }
            hrsSum = hrsSum + hr
            minsSum = minsSum + mins
            totalCount++
          }
        }

      })
      if (isItTimeCalcn) {
        const totalMinutes = (hrsSum * 60) + minsSum // 1380
        const avgMinutes = Math.round(totalMinutes / totalCount) // 197
        const onePlaceDecimalVal = parseFloat((avgMinutes / 60).toFixed(1)) // 3.2
        const avgHrs = parseInt(onePlaceDecimalVal) // 3
        const avgMins = Math.round((onePlaceDecimalVal - avgHrs) * 60) // 12
        result = `Average: ${avgHrs} hr ${avgMins} mins`
      } else {
        const avg = sum / totalCount
        result = avg > 0 ? `Average: ${avg.toFixed(2)}` : `Average: 0`
      }

      break;
    case 'Sum':
      {/* Here the row loop helps in traversing all the dataCells in a column*/ }
      rows.forEach((el, index) => {
        if (index < lastIndex) { // to ignore its ownself or the last row data cell of the column
          const value = el[colKey] ? el[colKey] : ''
          if (value) { // as some cells can have Nan, undefined value like formula cells
            if (isNumeric(value)) {
              sum = sum + parseFloat(value)
            } else if (value.includes('hr') || value.includes('mins')) { // if its a sum of 'hr mins'
              if (!isItTimeCalcn) isItTimeCalcn = true
              const timeStringArr = value.split(' ')
              let hr = 0
              let mins = 0
              if (value.includes('hr') && value.includes('mins')) { // if both hr and mins are there
                hr = parseInt(timeStringArr[0] ? timeStringArr[0] : 0)
                mins = parseInt(timeStringArr[2] ? timeStringArr[2] : 0)
              } else if (value.includes('hr')) { // if just hr is there postion of it in split array matters
                hr = parseInt(timeStringArr[0] ? timeStringArr[0] : 0)
              } else if (value.includes('mins')) { // if just mins is there postion of it in split array matters
                mins = parseInt(timeStringArr[0] ? timeStringArr[0] : 0)
              }
              hrsSum = hrsSum + hr
              minsSum = minsSum + mins
            }
          }
        }

      })
      if (isItTimeCalcn) {
        while (minsSum > 60) { // case for handling minutes sum greater than 60
          hrsSum++
          minsSum = minsSum - 60
        }
        result = `Sum: ${hrsSum} hr ${minsSum} mins`
      } else {
        result = `Sum: ${sum.countDecimals() > 2 ? sum.toFixed(2) : sum}`
      }
      break;
    default:
      result = '' // when selectedVal is Remove
      break;
  }
  return result
}

export const getFilteredRows = (searchQuery, rows, filters, columns) => {
  let filteredRows = []
  // rows.forEach((row, i) => {
  //   row['-1'] = i + 1
  // })
  const rowsCopy = rows.map((row, i) => ({ ...row, '-1': i + 1 }))

  console.log('rows copy:', rowsCopy)

  if (searchQuery.length > 0) {
    rowsCopy.forEach((rowEl, index) => {
      let found = false
      const isLastRow = rowsCopy.length - 1 === index
      Object.keys(rowEl).forEach((key) => {
        if (rowEl[key] && (rowEl[key].toString().toLowerCase()).includes(searchQuery.toLowerCase())) {
          found = true
        }
      })
      if (found && !isLastRow) filteredRows.push(rowEl) // as we dont want the last row to be searchedfor query
    })
    filteredRows.push(rowsCopy[rowsCopy.length - 1]) // count row push 
  } else {
    if (rowsCopy.length) {
      filteredRows = [...rowsCopy]
    }
  }
  if (filters && filters.length > 0) { // filter logic
    let entriesToBeKept = []
    filteredRows.forEach(rowEl => {
      let rowFitsFilterCriteria = true
      Object.keys(rowEl).forEach((currentColId) => { // loop on the row elements

        // if even one of the filter condition doesnt full fill then remove that row
        filters.every(el => {
          const value = rowEl[el.columnId ? el.columnId : '-1'] // HANDLED SNO as for s.no the column id doesnt exists, also its not required for details so didnt do for "detailsValue"
          const detailsValue = Array.isArray(rowEl[el.columnId + '_details']) ? rowEl[el.columnId + '_details'] : []
          const onlyNoneInColFilter = el.filters.length === 1 && el.filters.includes('NONE')
          const idxOfNone = el.filters.indexOf('NONE')
          let colFilters = JSON.parse(JSON.stringify(el.filters))
          if (idxOfNone > -1) colFilters[idxOfNone] = ''
          if (colFilters.includes('>')) { // amount, formula, number, unit, duration
            const first = parseFloat(colFilters[1])
            if (parseFloat(value) <= first || value === '') { rowFitsFilterCriteria = false }
          } else if (colFilters.includes('<')) { // amount, formula, number, unit, duration
            const first = parseFloat(colFilters[1])
            if (parseFloat(value) >= first || value === '') { rowFitsFilterCriteria = false }
          } else if (colFilters.includes('BETWEEN')) { // date, reminder, createdAt, amount, formula, number, unit, duration
            const first = colFilters[1]
            const second = colFilters[2]
            if (first?.includes('-') || (first?.includes(':') && value?.includes('GMT')) || (first?.includes(':') && value?.includes(','))) { // date, reminder, createdAt types respectively
              var compareDate = ''
              if (value?.includes('GMT') || value?.includes(',')) { // reminder and createdAt specific
                compareDate = moment(new Date(value), "DD-MM-YYYY")
              } else { // simple date with '-'
                compareDate = moment(value, "DD-MM-YYYY");
              }
              var startDate = moment(first, "DD-MM-YYYY");
              var endDate = moment(second, "DD-MM-YYYY");
              if (!compareDate.isBetween(startDate, endDate, 'days', '[]') || value === '') { rowFitsFilterCriteria = false }
            } else if (first?.includes(':')) { // time type
              var compareTime = moment(value, 'h:mm a');
              var startTime = moment(first, 'h:mm a');
              var endTime = moment(second, 'h:mm a');
              if (!compareTime.isBetween(startTime, endTime, undefined, []) || value === '') { rowFitsFilterCriteria = false }
            } else if (!el.columnId) { // SNO HANDLED
              if (!(parseInt(first) <= parseInt(rowEl['-1']) && parseInt(rowEl['-1']) <= parseInt(second)) || value === '')
                rowFitsFilterCriteria = false
            } else { // number, duration (hr mins) type
              if (!(parseInt(first) <= parseInt(value) && parseInt(value) <= parseInt(second)) || value === '') { rowFitsFilterCriteria = false }
            }
          } else if (onlyNoneInColFilter && value !== '') { // just for ['NONE]
            rowFitsFilterCriteria = false
          } else if (colFilters.includes('CUSTOM')) {

            const first = colFilters[1]
            if (String(value) !== first) { rowFitsFilterCriteria = false }
          } else {
            const doesDetailsArrExists = detailsValue.length > 0
            if (!doesDetailsArrExists && colFilters.indexOf(value) === -1) { // only value exists, here value!=='' is not added as we need blank value checks as well
              rowFitsFilterCriteria = false
            } else if (doesDetailsArrExists && value === '') { // for detailsValue
              rowFitsFilterCriteria = detailsValue.some(detailsValEl => colFilters.indexOf(detailsValEl.value) > -1)
            } else if (doesDetailsArrExists && value !== '' && colFilters.indexOf(value) === -1) { // url as both exists
              rowFitsFilterCriteria = false
            }
          }
          return rowFitsFilterCriteria // to break the loop as if its false then row should be removed without the need to check further filters criteria on next columns in this row
        })
      })
      if (rowFitsFilterCriteria && rowEl.rowId !== 'CALCULATE_ROW') { //CALCULATE_ROW check ensures no pushing of calculate row
        entriesToBeKept.push(rowEl)
      }
    })
    entriesToBeKept.push(rowsCopy[rowsCopy.length - 1])
    return entriesToBeKept
  }
  return filteredRows
}

const getSlNoUpdatedSortedRows = (sortedRows, lastRow) => {
  const updatedRow = sortedRows.map((row, i) => {
    const updatedRow = { ...row, '-1': i + 1 }
    return updatedRow
  })
  updatedRow.push(lastRow)
  return updatedRow
}

export const getSortedRows = (rows, colId, direction, dataType, cols) => {
  if (!dataType) { // done when dataType doesnt exists if we call the sort function. ( this willl be later omitted )
    cols.forEach((el) => {
      if (colId === el.key) dataType = el.dataType
    })
  }
  const rowsArray = [...rows]
  const lastRow = rowsArray.pop()
  // datatype = "String", "location", "dropDown", "switch", "mail", "url", "status", "scanner", "createdBy"
  if (
    dataType == "String" || dataType == "location" || dataType == "dropDown" || dataType == "switch" || dataType == "mail" ||
    dataType == "url" || dataType == "status" || dataType == "scanner" || dataType == "createdBy" || dataType == "linkedSheet"
  ) {
    const sortedRows = rowsArray.sort(function (x, y) {
      if (direction == 1) {
        let value1 = x[colId] ? x[colId].toLowerCase() : 'zzzzzzzzzzzzzzz'
        let value2 = y[colId] ? y[colId].toLowerCase() : 'zzzzzzzzzzzzzzz'
        if (value1 < value2) { return -1 };
        if (value1 > value2) { return 1 };
      } else if (direction == 2) {
        let value1 = x[colId].toLowerCase()
        let value2 = y[colId].toLowerCase()
        if (value1 < value2) { return 1 };
        if (value1 > value2) { return -1 };
      }
      return 0;
    })
    const slNoUpdatedSortedRows = getSlNoUpdatedSortedRows(sortedRows, lastRow)
    return slNoUpdatedSortedRows
  } else if (dataType === 'label') {
    const sortedRows = rowsArray.sort(function (x, y) {
      if (direction == 1) {
        let value1 = x[colId + '_details'].length > 0 ? x[colId + '_details'][0].value.toLowerCase() : 'zzzzzzzzzzzzzzz'
        let value2 = y[colId + '_details'].length > 0 ? y[colId + '_details'][0].value.toLowerCase() : 'zzzzzzzzzzzzzzz'
        if (value1 < value2) { return -1 };
        if (value1 > value2) { return 1 };
      } else if (direction == 2) {
        let value1 = x[colId + '_details'].length > 0 ? x[colId + '_details'][0].value.toLowerCase() : ''
        let value2 = y[colId + '_details'].length > 0 ? y[colId + '_details'][0].value.toLowerCase() : ''
        if (value1 < value2) { return 1 };
        if (value1 > value2) { return -1 };
      }
      return 0;
    })
    const slNoUpdatedSortedRows = getSlNoUpdatedSortedRows(sortedRows, lastRow)
    return slNoUpdatedSortedRows
  }
  // datatype = "rupee", "phoneNumber", "number", "formula", "unit"
  else if (dataType == "rupee" || dataType == "phoneNumber" || dataType == "number" || dataType == "formula" || dataType == "unit") {
    const sortedRows = rowsArray.sort(function (x, y) {
      if (direction == 1) {
        let value1 = x[colId] != '' ? parseFloat(x[colId]) : 9999999999999
        let value2 = y[colId] != '' ? parseFloat(y[colId]) : 9999999999999
        if (value1 < value2) { return -1 };
        if (value1 > value2) { return 1 };
      } else if (direction == 2) {
        let value1 = x[colId] != '' ? parseFloat(x[colId]) : -9999999999999
        let value2 = y[colId] != '' ? parseFloat(y[colId]) : -9999999999999
        if (value1 < value2) { return 1 };
        if (value1 > value2) { return -1 };
      }
      return 0;
    })
    const slNoUpdatedSortedRows = getSlNoUpdatedSortedRows(sortedRows, lastRow)
    return slNoUpdatedSortedRows
  }
  else if (dataType == "date") {   // datatype = "date"
    const sortedRows = rowsArray.sort(function (x, y) {
      if (direction == 1) {
        let value1 = x[colId] ? moment(x[colId], 'DD-MM-YYYY') : new Date(8640000000000000)
        let value2 = y[colId] ? moment(y[colId], 'DD-MM-YYYY') : new Date(8640000000000000)
        if (value1 < value2) { return -1 };
        if (value1 > value2) { return 1 };
      } else if (direction == 2) {
        let value1 = x[colId] ? moment(x[colId], 'DD-MM-YYYY') : new Date(null)
        let value2 = y[colId] ? moment(y[colId], 'DD-MM-YYYY') : new Date(null)
        if (value1 < value2) { return 1 };
        if (value1 > value2) { return -1 };
      }
      return 0;
    })
    const slNoUpdatedSortedRows = getSlNoUpdatedSortedRows(sortedRows, lastRow)
    return slNoUpdatedSortedRows
  }
  else if (dataType == "createdAt" || dataType == "reminder") {  // datatype = "createdAt", "reminder"
    const sortedRows = rowsArray.sort(function (x, y) {
      if (direction == 1) {
        let Value1 = x[colId] ? new Date(x[colId]) : new Date(8640000000000000)
        let Value2 = y[colId] ? new Date(y[colId]) : new Date(8640000000000000)
        if (Value1 < Value2) { return -1 };
        if (Value1 > Value2) { return 1 };
      } else if (direction == 2) {
        let Value1 = x[colId] ? new Date(x[colId]) : new Date(null)
        let Value2 = y[colId] ? new Date(y[colId]) : new Date(null)
        if (Value1 < Value2) { return 1 };
        if (Value1 > Value2) { return -1 };
      }
      return 0;
    })
    const slNoUpdatedSortedRows = getSlNoUpdatedSortedRows(sortedRows, lastRow)
    return slNoUpdatedSortedRows
  }
  else if (dataType == "time") {    // datatype = "time"
    const AMrows = rowsArray.filter((row) => {
      if (row[colId].includes('AM')) return row
    })
    const PMrows = rowsArray.filter((row) => {
      if (row[colId].includes('PM')) return row
    })
    const emptyCellRows = rowsArray.filter((row) => {
      if (!row[colId]) return row
    })
    const sortedAMRows = AMrows.sort(function (x, y) {
      let Value1 = parseInt(x[colId].split(' ')[0].split(':').join(''))
      let Value2 = parseInt(y[colId].split(' ')[0].split(':').join(''))
      if (direction == 1) {
        if (Value1 < Value2) { return -1 };
        if (Value1 > Value2) { return 1 };
      } else if (direction == 2) {
        if (Value1 < Value2) { return 1 };
        if (Value1 > Value2) { return -1 };
      }
      return 0;
    })
    const sortedPMRows = PMrows.sort(function (x, y) {
      let Value1 = parseInt(x[colId].split(' ')[0].split(':').join(''))
      let Value2 = parseInt(y[colId].split(' ')[0].split(':').join(''))
      if (direction == 1) {
        if (Value1 < Value2) { return -1 };
        if (Value1 > Value2) { return 1 };
      } else if (direction == 2) {
        if (Value1 < Value2) { return 1 };
        if (Value1 > Value2) { return -1 };
      }
      return 0;
    })
    if (direction == 1) {
      const sortedRows = [...sortedAMRows, ...sortedPMRows, ...emptyCellRows]
      const slNoUpdatedSortedRows = getSlNoUpdatedSortedRows(sortedRows, lastRow)
      return slNoUpdatedSortedRows
    } else if (direction == 2) {
      const sortedRows = [...sortedPMRows, ...sortedAMRows, ...emptyCellRows]
      const slNoUpdatedSortedRows = getSlNoUpdatedSortedRows(sortedRows, lastRow)
      return slNoUpdatedSortedRows
    }
  } else if (dataType == "image" || dataType == "attachment" || dataType == "signature") {  // datatype = "image", "attachment", "signature"
    const sortedRows = rowsArray.sort(function (x, y) {
      if (direction == 1) {
        let Value1 = x[colId + "_details"].length ? x[colId + "_details"].length : 999999999
        let Value2 = y[colId + "_details"].length ? y[colId + "_details"].length : 999999999
        if (Value1 < Value2) { return -1 };
        if (Value1 > Value2) { return 1 };
      } else if (direction == 2) {
        let Value1 = x[colId + "_details"].length
        let Value2 = y[colId + "_details"].length
        if (Value1 < Value2) { return 1 };
        if (Value1 > Value2) { return -1 };
      }
      return 0;
    })
    const slNoUpdatedSortedRows = getSlNoUpdatedSortedRows(sortedRows, lastRow)
    return slNoUpdatedSortedRows
  }
  // datatype = "duration"
  else if (dataType == 'duration') {
    const getValueInMinutes = (value, emptyCellRows) => {
      let emptyCellVal = direction == 1 ? 9999999999999 : -1
      if (value == '') return emptyCellVal
      const durationArr = value.split(' ')
      if (durationArr.includes('hr')) {
        let hours = Number(durationArr[0])
        let minutes = 0
        if (durationArr.includes('mins')) {
          minutes = Number(durationArr[2])
        }
        return (hours * 60 + minutes)
      } else if (durationArr.includes('mins')) {
        let minutes = Number(durationArr[0])
        return minutes
      }
    }
    const sortedRows = rowsArray.sort((x, y) => {
      let value1 = getValueInMinutes(x[colId])
      let value2 = getValueInMinutes(y[colId])
      if (direction == 1) {
        if (value1 < value2) return -1
        if (value2 < value1) return 1
      } else if (direction == 2) {
        if (value1 < value2) return 1
        if (value2 < value1) return -1
      }
      return 0
    })
    const slNoUpdatedSortedRows = getSlNoUpdatedSortedRows(sortedRows, lastRow)
    return slNoUpdatedSortedRows
  } else { // for unsupported data type
    return rows
  }
}

export const appendLastRow = (rows) => {
  let lastRow = {}
  Object.keys(rows[0]).forEach((key) => {
    if (key.includes('_details')) {
      lastRow[key] = []
    } else {
      lastRow[key] = ''
    }
  })
  rows.push(lastRow)
  return rows
}

export const getRowsAfterDeletion = (rows, rowId) => {
  let rowIdx = rows.findIndex(el => el.rowId === rowId)
  rows.splice(rowIdx, 1)
  const reserialisedRows = getReserialisedRows(rows)
  return reserialisedRows
}

export const getReserialisedRows = (rows) => {
  const calculateRowIdx = rows.length - 1
  rows.forEach((el, index) => {
    if (index < calculateRowIdx) el['-1'] = index + 1
  })
  return rows
}

export const addPlusBtnCol = (cols) => {
  const plusBtnCol = {
    dataType: "staticBorderlessCell",
    defaultRowFormula: "none",
    formula: [],
    isFreezed: false,
    isRequired: false,
    key: "addColPlusButton",
    multiOptions: [],
    name: "+",
    properties: [],
    width: 5,
  }
  cols.splice(cols.length, 1, plusBtnCol)
  return cols
}

// if some value is found in any data cell of the row being updated then it means, this is not an empty row
export const isRowEmpty = (row) => {
  let dataFound = false;
  Object.keys(row).forEach(key => {
    // dataFound check ensures that if data is found then this condition doesnt runs further
    if (!dataFound && ['addColPlusButton', '-1', 'rowId'].indexOf(key) === -1) {
      const rowEl = row[key]
      if (!!rowEl && rowEl.length > 0) dataFound = true
    }
  })
  // if some data is found then its not an empty row
  return !dataFound
}

export const addNewRows = (currentRows, newRowIds, topRowId, columns) => {
  let topRowIdx = currentRows.findIndex((el) => el.rowId === topRowId)
  const isTopRowIdxLastRowIdx = topRowIdx === currentRows.length - 1
  const shouldReserialiseRows = isTopRowIdxLastRowIdx ? false : true
  newRowIds.forEach((newRowId, index) => {
    const nextRowIdxForInsertion = topRowIdx + index + 1
    let newEmptyRow = {}
    newEmptyRow['rowId'] = newRowId
    newEmptyRow['-1'] = nextRowIdxForInsertion // display index creation
    columns.forEach((colEl) => {
      if (colEl.key !== '-1') { // ignore S.No cell clonning as done above
        const columnId = colEl.key
        newEmptyRow[columnId] = ""
        newEmptyRow[columnId + "_details"] = []
      }
    })
    currentRows.splice(nextRowIdxForInsertion, 0, newEmptyRow)
  })
  if (shouldReserialiseRows) {
    currentRows = getReserialisedRows(currentRows)
  }
  return currentRows
}

export const getNextSheetId = (sheetBeingDeltdIdx, pages) => {
  let sheetId;
  if (pages.length > 0) { // -1 because we need to pick the prior sheet/page
    sheetId = pages[sheetBeingDeltdIdx - 1] ? pages[sheetBeingDeltdIdx - 1].sheetId : pages[0].sheetId
  }
  return sheetId
}

export const getUpdatedRegisters = ({ name, oldName, registers }) => {
  const registersArray = [...registers]
  const updatedArray = registersArray.map((register, i) => {
    if (oldName == register?.name) {
      return { ...register, name }
    }
    else return register
  })
  return updatedArray
}

export const formulaToCalculatorFormat = (formula, columns) => {
  let formulaStr = ''
  const formattedFormulaArr = formula.map(el => {
    // return column name
    if (alphabetSet.indexOf(el) > -1) {
      formulaStr = formulaStr + ' ' + columns[alphabetSet.indexOf(el) + 1]?.name
      return {
        string: columns[alphabetSet.indexOf(el) + 1]?.name,
        type: 'column',
        key: columns[alphabetSet.indexOf(el) + 1].key,
      }
    }
    // convert * to x for display
    else if (el === '*') {
      formulaStr = formulaStr + ' x'
      return {
        string: 'x',
        type: 'operator'
      }
    }
    // add previous row string to column name
    else if (el.includes('$')) {
      const columnAlphabet = el.replace('$', '')
      formulaStr = formulaStr + `${columns[alphabetSet.indexOf(columnAlphabet) + 1]?.name}(previous row)`
      return {
        string: columns[alphabetSet.indexOf(columnAlphabet) + 1]?.name,
        type: 'column',
        key: '$' + columns[alphabetSet.indexOf(columnAlphabet) + 1].key,
      }
    }
    else {
      formulaStr = formulaStr + ' ' + el
      // add type bracket
      if (['(', ')'].indexOf(el) > -1) {
        return {
          string: el,
          type: 'bracket',
        }
      } else if (['+', '/', '-'].indexOf(el) > -1) { // add type operator
        return {
          string: el,
          type: 'operator',
        }
      } else if (parseInt(el) > -1 || parseFloat(el) > -1) { // add type number
        return {
          string: el,
          type: 'number',
        }
      }
    }
  })
  return {
    string: formulaStr,
    arr: formattedFormulaArr
  }
}