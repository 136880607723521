import { useSelector } from 'react-redux'

import { getValidatedEmail } from '../../../../Table/Utils'

import mailIcon from '../../../../../../images/datatype_icons/light/mail.png'

const Mail = ({ colkey, column, handleChange, formData, shouldAllowNewEntry }) => {

  // redux variables
  const registerData = useSelector(state => state.register)

  // local variables
  const value = getValidatedEmail(formData[colkey])
  const { isAdmin, hasBasicEditPermission, editPermissions } = registerData
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colkey) === -1

  return (
    <div className="mb-6">
      <div className="colName">
        <img src={mailIcon} className="field_icon" />
        <label htmlFor={column.name}>{column.name}</label>
      </div>

      {
        !canEdit && !shouldAllowNewEntry &&
        <div className="field truncate">{value}</div>
      }

      {
        (shouldAllowNewEntry || canEdit) &&
        <input
          name={colkey}
          type="email"
          value={value}
          onChange={(e) => handleChange({ colkey, value: e.target.value })}
          autoComplete="off"
          className="field focus:ring-transparent"
        />
      }
    </div>
  )
}

export default Mail