import { useState, useRef, useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux'

// slices
import { updateColumn, addNewColumn, sheetColumnDelete, sheetColumnFreeze, setPreOpenColMenu, sortRows, fetchRegisterData, fetchAllRegisterData, setSortedColKey, setSortDirection, saveSorting } from '../../../../../slices/RegisterSlice'
import { toggleDialogOpen, updateBody, updateHeaderText } from '../../../../../slices/DialogSlice'

// components
import FormulaConfiguration from '../../../PopUps/FormulaConfiguration'
import EditStatusDialog from '../../../PopUps/EditStatus'
import ColumnMenuOptions from "./components/ColumnMenuOptions"

//images
import showMenuIcon from '../../../../../images/column-icons/showMenu.png'

// utils
import { DataTypes } from '../../Utils'

const HeaderMenu = ({
  colHeaderContainerRef,
  colMenuContainerRef,
  showColMenu,
  colMenuPos,
  dataTypeSelected,
  setShowColMenu,
  colIdx,
  preOpenColMenu,
  setShowLinkToAnotherRegisterPopup,
  colName,
  setColumnName,
  columnInfo
}) => {

  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  //state variables
  const [showOptionsBox, setShowOptionsBox] = useState(true)
  const [showEditBox, setShowEditBox] = useState(false)
  const [showDatatypesDropdown, setShowDatatypesDropdown] = useState(false)
  const [selectedDataType, setSelectedDataType] = useState({})
  const [isColNameVirgin, setIsColNameVirgin] = useState(true)
  const [colMenuOptnSelected, setColMenuOptnSelected] = useState('')     // 0-no sort   1- A to Z   2- Z to A

  //ref variables
  const columnNameInputRef = useRef(null)

  // local variables
  const { rows, columns, sheetId, registerId, sortedColKey, sortDirection, permissions } = registerData
  const isAdmin = permissions.includes('admin')
  const { key: colKey, isFreezed } = columnInfo

  //close on click outside - column-header-menu and pre open option menu configurations
  useEffect(() => {
    // open automatically, the required option of column menu
    if (preOpenColMenu && preOpenColMenu.colIdx !== null) {
      // special case is when left or right insertion is done then we dont want to open update menu
      // and when we do these ( left/right ) operations preOpenColMenu is only passed with colIdx
      // menu opens only when + column button is clicked
      if (preOpenColMenu.option !== null) {
        onOptionClick(preOpenColMenu.option)
      }
      dispatch(setPreOpenColMenu(null))
    }
    const clickHandler = ({ target }) => {
      if (
        colHeaderContainerRef.current.contains(target) || // when the click is on header then further close menu code is not called as this click function gets initially called when menu opens
        colMenuContainerRef.current && colMenuContainerRef.current.contains(target) || // when the click is on inside column menu then menu is not closed
        target.innerText === 'Yes' || // when the click is made on delete modal for option no then column menu should not be closed
        target.innerText === 'No') return;
      setShowColMenu(false)
      // reset preOpenColMenu if it was set after addition of column from + button
      if (preOpenColMenu) dispatch(setPreOpenColMenu(null))
    }
    document.addEventListener('click', clickHandler)
    return () => {
      document.removeEventListener('click', clickHandler)
    };
  }, []);

  // inser left and right automatic save button call
  useEffect(() => {
    if (colMenuOptnSelected === 2 || colMenuOptnSelected === 3) {
      onSaveButtonClick()
    }
  }, [colMenuOptnSelected])

  //when an option from column-header-menu is clicked
  const onOptionClick = async (index) => {
    if (!isAdmin) {
      alert("Permission Denied")
      setShowColMenu(false)
      return
    }
    if (index == 1) { // edit column
      setSelectedDataType(dataTypeSelected)
      setColumnName(dataTypeSelected.columnName)
      setShowOptionsBox(false)
      setShowEditBox(true)
      setColMenuOptnSelected(1)
    } else if (index == 2) { // insert left
      setSelectedDataType({})
      setColumnName('')
      setShowOptionsBox(false)
      setColMenuOptnSelected(2)
    } else if (index == 3) { // insert right
      setSelectedDataType({})
      setShowOptionsBox(false)
      setColMenuOptnSelected(3)
    } else if (index == 4 || index == 5) { // sort
      // const direction = index == 4 ? 1 : 2
      // dispatch(sortRows({ rows: rows, columnId: colKey, direction, dataType })) //state.rows
      let sortDirection = index == 4 ? 'ASCENDING' : 'DESCENDING'
      dispatch(saveSorting({ registerId, sheetId, sorts: [{ columnId: colKey, sortBy: sortDirection }] }))
      setShowColMenu(false)
    } else if (index == 6) { // freeze
      const isFreezed = columnInfo.isFreezed ? false : true
      dispatch(sheetColumnFreeze({ sheetId, columnId: colKey, isFreezed }))
      setShowColMenu(false)
    } else if (index == 7) { // delete 
      dispatch(toggleDialogOpen(true))
      dispatch(updateBody(getDeleteBody()))
      dispatch(updateHeaderText('Delete Column?'))
      //setColMenuOptnSelected(7)
    } else if (index == 8) { // Unsort
      await dispatch(saveSorting({ registerId, sheetId, sorts: [] }))
      dispatch(fetchAllRegisterData({ registerId, sheetId }))
      // dispatch(setSortedColKey(''))
      // dispatch(setSortDirection(0))
      setShowColMenu(false)
    }
  }

  const onCancelButtonClick = () => {
    // directly close the menu if it was a new column added with edit box opened 
    if (preOpenColMenu) {
      setShowColMenu(false)
      dispatch(setPreOpenColMenu(null))
    } else {
      setShowEditBox(false)
      setShowOptionsBox(true)
      setIsColNameVirgin(true)
    }
  }

  const onSaveButtonClick = () => {
    setShowColMenu(false)
    if (colMenuOptnSelected === 1) { // update column
      let params = { sheetId, columnId: colKey, columnName: colName, dataType: selectedDataType.dataType }
      if (selectedDataType.dataType == 'linkedSheet') {
        params = {
          ...params,
          linkedSheetId: columnInfo.linkedSheetId,
          linkedColumnId: columnInfo.linkedColumnId,
          linkedRegisterId: columnInfo.linkedRegisterId,
          linkedDataType: columnInfo.linkedDataType,
          description: columnInfo.description
        }
      }
      dispatch(updateColumn(params))
    } else if (colMenuOptnSelected === 2) { // insert left
      let leftColumnId = '-1'
      registerData.columns.forEach((colEl, index) => {
        if (colEl.key === colKey) {
          leftColumnId = registerData.columns[index - 1].key
        }
      });
      dispatch(addNewColumn({
        sheetId,
        leftColumnId,
        columnName: 'New Column',
        dataType: selectedDataType.dataType,
        preOpenColMenu: { colIdx, option: 1 } // colIdx is used because the newly added column occupies the current column index
      }))
    } else if (colMenuOptnSelected === 3) { // insert right
      dispatch(addNewColumn({
        sheetId,
        leftColumnId: colKey,
        columnName: 'New Column',
        dataType: selectedDataType.dataType,
        preOpenColMenu: { colIdx: colIdx + 1, option: 1 } // colIdx + 1 is used because the newly added column occupies the position just after the current column
      }))
    }
    // reset preOpenColMenu if it was set after addition of column from + button
    if (preOpenColMenu) dispatch(setPreOpenColMenu(null))
  }

  const onDataTypeOpnClick = (el) => {
    if (statusDatatypeExists(el)) {
      alert('Status Datatype already exists in a column')
      return
    }
    setShowDatatypesDropdown(false)
    setSelectedDataType(el)
    const shouldColNameBeChanged = DataTypes.findIndex(dataTypeEl => dataTypeEl.name == colName) === -1 ? false : true
    // 1-column name matches to name property of a dataType
    // 2-columnName has never been changed
    if (shouldColNameBeChanged && isColNameVirgin) setColumnName(el.name)

    // open formula/status pop up if selected
    if (el.dataType === "formula") {
      dispatch(toggleDialogOpen(true))
      dispatch(updateBody(<FormulaConfiguration selectedColIdx={colIdx} closeDialog={() => dispatch(toggleDialogOpen(false))} />))
      dispatch(updateHeaderText('Add Formula'))
    } else if (el.dataType === "status") {
      let statusOptions = []
      dispatch(toggleDialogOpen(true))
      dispatch(updateBody(<EditStatusDialog statusOptions={statusOptions} columnId={colKey} />))
      dispatch(updateHeaderText('Configure Statuses'))
    } else if (el.dataType == "linkedSheet") {
      setShowLinkToAnotherRegisterPopup(true)
    }
  }

  const statusDatatypeExists = (el) => {
    let statusColumnExists = false
    columns.forEach(column => {
      if (column.dataType == 'status') statusColumnExists = true
    })
    return el.dataType == 'status' && statusColumnExists
  }

  const deleteColumn = () => {
    setSelectedDataType({})
    setColumnName('')
    setShowOptionsBox(false)
    setShowEditBox(false)
    dispatch(toggleDialogOpen(false))
    dispatch(sheetColumnDelete({ sheetId, columnId: colKey }))
  }

  const getDeleteBody = () => {
    return (
      <div className="bg-white" style={{ minWidth: '20rem' }}>
        <div className="text-lg text-center p-4">CONFIRM DELETE</div>
        <div className="flex justify-center items-center gap-6 my-5">
          <button onClick={(e) => { e.preventDefault(); deleteColumn() }} className="bg-gray-700 text-white rounded py-1 px-2">Yes</button>
          <button onClick={(e) => { e.preventDefault(); dispatch(toggleDialogOpen(false)) }} className="bg-gray-700 text-white rounded py-1 px-2">No</button>
        </div>
      </div>
    )
  }

  const onColumnNameChange = (e) => {
    setColumnName(e.target.value)
    if (!isColNameVirgin) setIsColNameVirgin(false)
  }

  const onColumnNameInputKeyDown = (e) => {
    if (e.key === 'Enter') onSaveButtonClick()
  }

  return (
    <div className={`slide_down transition-all delay-50 ease-out ${showColMenu ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>

      {/*
        * options menu
        * preOpenColMenu check ensures that edit box is directly opened without the display of optns box 
      */}
      {
        !preOpenColMenu &&
        <ColumnMenuOptions
          showOptionsBox={showOptionsBox}
          colMenuPos={colMenuPos}
          colKey={colKey}
          isFreezed={isFreezed}
          dataTypeSelected={dataTypeSelected}
          onOptionClick={onOptionClick}
        />
      }
      {/*column-header-menu -- main menu */}

      {/*edit column menu / edit box */}
      <div
        className={`absolute p-2 mt-1.5 flex flex-col gap-1 rounded-lg border border-gray-300 transition-all delay-100 ease-in ${showEditBox ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
        style={{ top: colMenuPos.top + 40, left: colMenuPos.left, width: '204px', background: '#EEF3F5', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
      >

        <div style={{ fontSize: '0.8rem', color: '#999999', fontWeight: '400' }} className="px-2">Column Name</div>
        {/*column name input */}
        <input
          className="border h-8 mb-3 pl-2 border border-gray-400 rounded"
          style={{ fontSize: '1.2rem' }}
          value={colName}
          onClick={() => columnNameInputRef.current.focus()}
          ref={columnNameInputRef}
          onChange={onColumnNameChange}
          onKeyDown={onColumnNameInputKeyDown}
        />

        <div style={{ fontSize: '0.8rem', color: '#999999', fontWeight: '400' }} className="px-2">Data Type</div>
        {/*column datatype input */}
        <div
          onClick={() => { setShowDatatypesDropdown(!showDatatypesDropdown) }}
          className="h-8 p-2 bg-white border border-gray-400 rounded flex justify-between items-center cursor-pointer"
        >
          <img className="inline-block mr-2 w-4" src={selectedDataType.icon} />
          <div className="truncate">{selectedDataType.name}</div>
          <img src={showMenuIcon} className="w-3 " />
        </div>

        {/*datatypes dropdown menu */}
        <div className="relative">
          <div
            className={`overflow-y-scroll absolute w-full rounded border border-gray-300 shadow p-2 transition-all delay-100 ease-out ${showDatatypesDropdown ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-1 pointer-events-none'}`}
            style={{ background: '#EEF3F5', maxHeight: '200px' }}
          >
            <div className="w-full h-full bg-white rounded-sm border border-gray-300" >
              <ul>
                {
                  DataTypes.map((el) => {
                    return (
                      <>
                        <li onClick={() => onDataTypeOpnClick(el)} className="border-b py-1 hover:bg-gray-200 px-2 cursor-pointer">
                          <img className="inline-block mr-2 w-5" src={el.icon} />{el.name}
                          {
                            el.dataType == 'linkedSheet' && columnInfo.description &&
                            <div className="border border-gray-300 rounded p-1 text-xs text-gray-400">{columnInfo.description}</div>
                          }
                        </li>
                      </>
                    )
                  })
                }
              </ul>
            </div>
          </div>
        </div>
        {/*datatypes dropdown */}

        {/*buttons */}
        <div className="flex items-center justify-end gap-4 mt-3">
          <button onClick={onCancelButtonClick}>Cancel</button>
          <button onClick={onSaveButtonClick} className="text-white px-3 py-1 rounded-sm" style={{ background: '#586C9E' }}>Save</button>
        </div>
        {/*buttons */}

      </div>
      {/*edit column menu */}

    </div>
  )
}

export default HeaderMenu