import React, { useEffect, useRef, useState } from 'react';
import ReactDOM, { createPortal } from 'react-dom';
import { useSelector } from 'react-redux'
import moment from 'moment'
import DatePicker from "react-datepicker"
import TimeKeeper from 'react-timekeeper'

// utils
import ErrorBoundary from '../../../../../utils/ErrorBoundary'
import { getValidatedReminderValue } from '../../Utils';

// icons
import dateIcon from '../../../../../images/datatype_icons/dark/date.png'
import timeIcon from '../../../../../images/datatype_icons/dark/time.png'
import editIcon from '../../../../../images/ic_edit_icon.png'
import deleteIcon from '../../../../../images/delete.png'

const ReminderEditor = ({ rowProperties, saveCurrentRowData, colIdx, columnEl }) => {
  // redux variables
  const registerData = useSelector(state => state.register)

  // local variables
  const colKey = columnEl.key
  const onClose = rowProperties.onClose
  const rowIndex = rowProperties.rowIdx
  const rowId = rowProperties.row.rowId
  const value = getValidatedReminderValue(rowProperties.row[colKey])
  const message = rowProperties.row[`${colKey}_details`]? ( rowProperties.row[`${colKey}_details`].length > 0 ? rowProperties.row[`${colKey}_details`][0].value : '' ) : ''
  const reminderDate = value ? moment(value).format('DD/MM/YYYY') : ''
  const reminderTime = value ? moment(value).format('LT') : ''
  let textarea = null

  // state variables
  const [pos, setPos] = useState({})
  const [isReminderEditorOpen, setIsReminderEditorOpen] = useState(value ? false : true)
  const [startDate, setStartDate] = useState(value ? new Date(moment(value).format('MM-DD-YYYY')) : null /*new Date()*/)
  const [time, setTime] = useState(value ? reminderTime : null /*moment().format('LT')*/)
  const [showTimeDialog, setShowTimeDialog] = useState(false)
  const [reminderMessage, setReminderMessage] = useState(message)
  const [showTimeError, setShowTimeError] = useState(false)
  const [showDateError, setShowDateError] = useState(false)

  // ref variables
  const reminderEditorContainerRef = useRef(null)
  const reminderEditorDataCellRef = useRef(null)
  const reminderInfoRef = useRef(null)

  useEffect(() => {
    // as soon as the editor is rendered reminderEditorContainerRef should open
    //setIsReminderEditorOpen(true)
    // listening to table scroll represented by .rdg-light class
    document.querySelector(".rdg-light").addEventListener("scroll", onScroll)
    document.addEventListener('keydown', keyHandler);
    return () => {
      document.removeEventListener("scroll", onScroll)
      document.removeEventListener('keydown', keyHandler);
    }
  }, [])

  useEffect(() => {
    onScroll()
  }, [isReminderEditorOpen])

  useEffect(()=>{
    focusSaveButton()
  }, [startDate, isReminderEditorOpen])

  const focusSaveButton = () => {
    if(startDate && time){
      const saveButton = document.getElementById(`dataCell-reminderEditor-save-btn`)
      if(saveButton){
        saveButton.focus()
      }
    }
  }

  const keyHandler = (e) => {

    //tab keypress
    if (e.keyCode == 9) {
      if (document.activeElement.classList.contains('rdg-focus-sink') || document.activeElement.classList.contains('sheetItem2')) {
        document.getElementById(`reminder-editor-portal-container`).focus();
      }
      if (document.activeElement.classList.contains('dataCell-reminderEditor-datepicker-container')) {
        document.getElementById('dataCell-reminderEditor-timepicker-container').focus()
      }
    }
    if (e.keyCode !== 27) return;
    onClose(true);
  }

  const onScroll = () => {
    if (reminderEditorDataCellRef.current) {
      let positionTop = 0
      let leftShift = isReminderEditorOpen ? 0 : 8
      const reminderInfoContainerHeight = reminderInfoRef.current ? reminderInfoRef.current.clientHeight : 185.4
      // here 272.8 is the height of reminder Editor Container and 185.4 of the show reminder container
      if (isReminderEditorOpen) {
        if (reminderEditorDataCellRef.current.getBoundingClientRect().bottom + 220 >= window.innerHeight) {
          positionTop = positionTop - 220
        } else positionTop = 40
      } else {
        if (reminderEditorDataCellRef.current.getBoundingClientRect().bottom + reminderInfoContainerHeight + 5 >= window.innerHeight) {
          positionTop = positionTop - reminderInfoContainerHeight
        } else positionTop = 40
      }
      const refValue = ReactDOM.findDOMNode(reminderEditorDataCellRef.current)
      if (refValue) {
        setPos({
          right: refValue
            .getBoundingClientRect().right,
          left: refValue
            .getBoundingClientRect().left + leftShift,
          top: refValue
            .getBoundingClientRect().top + positionTop,
          bottom: refValue
            .getBoundingClientRect().bottom,
        })
      }
    }
  }

  useEffect(() => {
    textarea = document.querySelector(`#textarea${rowId}`)
    if (textarea !== null) {
      textarea.style.height = "auto"
      let scHeight = textarea.scrollHeight
      textarea.style.height = `${scHeight}px`
    }
  }, [reminderMessage])

  const handleClick = (e) => {
    e.preventDefault()
    onClose(true)
  }

  const saveReminderData = (clearReminderData) => {
    if (clearReminderData) {
      const additionalKeys = { [`${colKey}_details`]: [] }
      saveCurrentRowData('', colKey, rowId, registerData.rows, false, false, additionalKeys)
    } else {
      if (time && startDate) {
        const date = moment(startDate).format('DD-MM-YYYY')
        const reminder = moment(`${date} ${time}`, 'DD-MM-YYYY LT')
        const formattedReminder = getFormattedReminder(reminder)
        if (reminderMessage) {
          const additionalKeys = { [`${colKey}_details`]: [{ value: reminderMessage }] }
          saveCurrentRowData(formattedReminder, colKey, rowId, registerData.rows, false, false, additionalKeys)
        } else {
          const additionalKeys = { [`${colKey}_details`]: [] }
          saveCurrentRowData(formattedReminder, colKey, rowId, registerData.rows, false, false, additionalKeys)
        }
      } else if (!time || !startDate) {
        if (!time) setShowTimeError(true)
        if (!startDate) setShowDateError(true)
        return
      }
    }
    onClose(true)
  }

  const getFormattedReminder = (reminder) => {
    //To make the reminder format the same as that in the app: 
    //eg: "Wed Jan 05 18:09:00 GMT+05:30 2022" from "Wed Dec 29 2021 15:26:00 GMT+0530 (India Standard Time)"
    const reminderString = String(reminder._d)
    const reminderArray = reminderString.split(' ')
    reminderArray.splice(6, 3)
    const year = reminderArray.splice(3, 1)
    const GMT = reminderArray.splice(4, 1)
    const GMTarray = GMT[0].split('')
    const newGMT = [...GMTarray.slice(0, 6), ':', ...GMTarray.slice(-2)].join('')
    const updatedReminder = [...reminderArray, newGMT, ...year].join(' ')
    return updatedReminder
  }

  const getSheetReminderValue = (date, time) => {
    if (value && startDate && time) {
      const sheetDate = moment(date).format('DD MMM')
      const reminderSheetValue = `${sheetDate} ${(time)}`
      return reminderSheetValue
    }
  }

  // handles key events of the editor container div
  const handleContainerDivKeyDown = (e) => {
    // enter key press
    if (e.keyCode == 13) {
      e.preventDefault()
      e.stopPropagation()
      saveReminderData(false)
    }
  }

  return (
    <ErrorBoundary>
      <div className="w-full h-full" tabIndex="-1" id="reminder-editor-container-div" onKeyDown={handleContainerDivKeyDown}>

        {/*Pre select value containing div starts here*/}
        <div ref={reminderEditorDataCellRef} onClick={handleClick} tabIndex="0" className="sheetItem1 focus:bg-gray-600">
          {value ? getSheetReminderValue(startDate, time) : ''}
        </div>
        {/*Pre select value containing div ends here*/}

        {/*Portal starts here*/}
        {createPortal(
          <div
            ref={reminderEditorContainerRef}
            id="reminder-editor-portal-container"
            tabIndex="-1"
            style={{
              boxShadow: ' 0px 4px 4px rgb(0 0 0 / 25%)',
              top: pos.top,
              right: pos.right,
              left: pos.left,
              fontSize: '12px',
              position: 'fixed',
              width: '13rem',
              backgroundColor: '#EEF3F5'
            }}
            className="slide_down_2"
          >
            {isReminderEditorOpen ?
              <div className=''>
                <div className="w-full px-3 pt-2 mt-1 text-gray-500 overflow-auto" style={{height: '80%'}}>
                  Select Date
                  <div id="dataCell-reminderEditor-datepicker-container">
                    <DatePicker
                      className={`date-picker-reminder ${showDateError ? 'active' : 'normal'} h-8`}
                      selected={startDate}
                      onChange={(date) => { setStartDate(date); if (showDateError) { setShowDateError(false) }; }}
                      dateFormat="dd-MM-yyyy"
                      showMonthDropdown
                      showYearDropdown
                      disabledKeyboardNavigation
                      minDate={new Date()}
                      isClearable
                    />
                  </div>
                  Select Time
                  <div
                    className="pr-1 bg-white mb-3 h-8 cursor-pointer flex items-center justify-between"
                    style={{  border: showTimeError ? '1px solid red' : '1px solid #A9A9A9', borderRadius: '0.3rem' }}
                  >
                    <div
                      tabIndex="0"
                      id="dataCell-reminderEditor-timepicker-container"
                      className='pl-3 mr-3 w-full h-full flex items-center'
                      onClick={e => { e.preventDefault(); if (!time) { setTime(moment().format('LT')) }; setShowTimeDialog(true); if (showTimeError) { setShowTimeError(false) } }}
                      style={{ fontSize: '1rem' }}
                    >
                      {time && time.toUpperCase()}
                    </div>
                    {time && <span onClick={() => setTime(null)} className="text-white text-sm w-5 h-4 rounded-full flex items-center justify-center text-xs" style={{ background: '#216ba5', fontSize: '0.7rem' }}>x</span>}
                  </div>
                  {
                    showTimeDialog && createPortal(
                      <div className="flex justify-center items-center fixed w-full h-full inset-0" style={{ zIndex: '205' }}>
                        <div onClick={(e) => { e.preventDefault(); setShowTimeDialog(false); }} className='fixed flex justify-center items-center w-full h-full left-0 right-0 top-0 bottom-0' style={{ backgroundColor: "rgba(0,0,0,0.4)", zIndex: '205' }}>
                        </div>
                        <div style={{ zIndex: '206', }}>
                          <TimeKeeper
                            time={time}
                            onChange={(newTime) => { setTime(newTime.formatted12); if (showTimeError) { setShowTimeError(false) } }}
                            onDoneClick={() => { setShowTimeDialog(false); focusSaveButton()}}
                            switchToMinuteOnHourSelect
                          />
                        </div>
                      </div>, document.body)
                  }
                  <textarea
                    tabIndex="0"
                    value={reminderMessage}
                    onChange={e => { setReminderMessage(e.target.value) }}
                    placeholder='Message(Optional)'
                    className="w-full p-2"
                    id={`textarea${rowId}`}
                    style={{ border: '1px solid #A9A9A9', borderRadius: '0.3rem', overflow: 'hidden' }}
                    rows={1}
                  />
                </div>
                <div className="py-2 flex justify-end px-3 gap-3" style={{height: ''}}>
                  <div tabIndex="0" className="py-2 w-16 flex justify-center rounded cursor-pointer" onClick={() => { if (value) { setIsReminderEditorOpen(false) } else { onClose(true) } }}>Cancel</div>
                  <div tabIndex="0" id="dataCell-reminderEditor-save-btn" className="py-2 w-16 flex justify-center rounded text-white cursor-pointer" onClick={(e) => { e.preventDefault(); saveReminderData(false); }} style={{ backgroundColor: '#586C9E' }}>Save</div>
                </div>
              </div>
              :
              <div ref={reminderInfoRef} className='pt-2'>
                <div className="flex gap-4 items-center py-1 px-2">
                  <div className="flex gap-1 items-center"><img src={dateIcon} className="h-4" />{reminderDate}</div>
                  <div className="flex gap-1 items-center"><img src={timeIcon} className="h-4" />{reminderTime}</div>
                </div>
                <div className="py-1 px-4 italic" style={{ fontSize: '0.7rem' }}>{reminderMessage}</div>
                <ul className="pt-3 divide-y">
                  <li tabIndex="0" className="flex gap-2 hover:bg-gray-300 px-2 cursor-pointer py-3" onClick={() => { setIsReminderEditorOpen(true) }}><img src={editIcon} className="h-4" />Edit Reminder</li>
                  <li tabIndex="0" className="flex gap-2 hover:bg-gray-300 px-2 cursor-pointer py-3" style={{ color: '#FC1055' }} onClick={(e) => { e.preventDefault(); saveReminderData(true); }}><img src={deleteIcon} className="h-4" />Remove Reminder</li>
                </ul>
              </div>
            }
          </div>
          , document.body
        )}
        {/*Portal ends here*/}
      </div>
    </ErrorBoundary>
  )
}

export default ReminderEditor