// column formatters
import RupeeFormatter from '../Formatters/DataCell/Rupee'
import TextFormatter from '../Formatters/DataCell/Text'
import ImageFormatter from '../Formatters/DataCell/Image'
import StringFormatter from '../Formatters/DataCell/String'
import SwitchFormatter from '../Formatters/DataCell/Switch'
import MailFormatter from '../Formatters/DataCell/Mail'
import TimeFormatter from '../Formatters/DataCell/Time'
import LocationFormatter from '../Formatters/DataCell/Location'
import DateFormatter from '../Formatters/DataCell/Date'
import SignatureFormatter from '../Formatters/DataCell/Signature'
import StatusFormatter from '../Formatters/DataCell/Status'
import UrlFormatter from '../Formatters/DataCell/Url'
import ReminderFormatter from '../Formatters/DataCell/Reminder'
import UnitFormatter from '../Formatters/DataCell/Unit'
import PhoneNumberFormatter from '../Formatters/DataCell/PhoneNumber'
import NumberFormatter from '../Formatters/DataCell/Number'
import SelectOneFormatter from '../Formatters/DataCell/SelectOne'
import AttachmentFormatter from '../Formatters/DataCell/Attachment'
import DurationFormatter from '../Formatters/DataCell/Duration'
import LabelFormatter from '../Formatters/DataCell/Label'
import Col1Formatter from '../Formatters/DataCell/Col1'
import LinkedSheetFormatter from '../Formatters/DataCell/LinkedSheet'
import StaticBorderlessCell from '../Formatters/DataCell/StaticBorderlessCell'

// summary cells formatters
import SnoSummaryCell from '../Formatters/SummaryCell/SnoSummaryCell'
import DataSummaryCellFormatter from '../Formatters/SummaryCell/DataSummaryCellFormatter'

// row editors
import DateEditorLayer from '../Editors/DataCellLayer/DateEditorLayer'
import StatusEditorLayer from '../Editors/DataCellLayer/StatusEditorLayer'
import SelectOneEditorLayer from '../Editors/DataCellLayer/SelectOneEditorLayer'
import SwitchEditorLayer from '../Editors/DataCellLayer/SwitchEditorLayer'
import TimeEditorLayer from '../Editors/DataCellLayer/TimeEditorLayer'
import TextEditorLayer from '../Editors/DataCellLayer/TextEditorLayer'
import UnitEditorLayer from '../Editors/DataCellLayer/UnitEditorLayer'
import CurrencyEditorLayer from '../Editors/DataCellLayer/CurrencyEditorLayer'
import PhoneNumberEditorLayer from '../Editors/DataCellLayer/PhoneNumberEditorLayer'
import NumberEditorLayer from '../Editors/DataCellLayer/NumberEditorLayer'
import MailEditorLayer from '../Editors/DataCellLayer/MailEditorLayer'
import ImageEditorLayer from '../Editors/DataCellLayer/ImageEditorLayer'
import AttachmentEditorLayer from '../Editors/DataCellLayer/AttachmentEditorLayer'
import UrlEditorLayer from '../Editors/DataCellLayer/UrlEditorLayer'
import ReminderEditorLayer from '../Editors/DataCellLayer/ReminderEditorLayer'
import LocationEditorLayer from '../Editors/DataCellLayer/LocationEditorLayer'
import FormulaEditorLayer from '../Editors/DataCellLayer/FormulaEditorLayer'
import DurationEditorLayer from '../Editors/DataCellLayer/DurationEditorLayer'
import LabelEditorLayer from '../Editors/DataCellLayer/LabelEditorLayer'
import LinkedSheetEditorLayer from '../Editors/DataCellLayer/LinkedSheetEditorLayer'
import FeatureNotAvailableEditor from '../Editors/DataCell/FeatureNotAvailableEditor'

// icons getter
import ColumnHeader from '../Formatters/HeaderCell/ColumnHeader'

export const getTableConfigWithCellFormat = (columnsData, canAdd, openEditorAfterSelect, saveCurrentRowData) => {
  let columns = columnsData.map((c, k) => {

    let columnName = <ColumnHeader columnInfo={c} isLastCol={k === columnsData.length - 1} colIdx={k} />

    {/*
      Open last row cells on single click is implemented from onRowClick
      otherwise the default funcionality is open editor on double click.
    */}

    // common configuration for every column
    let columnConfig = {
      ...c,
      resizable: k == 0 ? false : true,
      frozen: c.isFreezed,
      headerRenderer: () => columnName,
      formatter: {},
      editor: {},
      editable: true,
    };
    let formatterProps = {
      openEditorAfterSelect: openEditorAfterSelect,
      colIdx: k,
      columnEl: c
    }
    let editorProps = {
      saveCurrentRowData,
      colIdx: k,
      colKey: c.key,
      columnEl: c
    }
    // seperate configuarations of formatter and editor
    if (k == 0) {
      columnConfig = {
        ...columnConfig,
        editable: false,
        formatter: (el) => {
          return (
            <Col1Formatter {...formatterProps} rowProperties={el} canAdd={canAdd} />
          )
        },
        summaryFormatter: () => <SnoSummaryCell canAdd={canAdd} />
      }
      return columnConfig
    }
    switch (c.dataType) {
      case "switch":
        columnConfig = {
          ...columnConfig,
          formatter: (el) => <SwitchFormatter {...formatterProps} rowProperties={el} />,
          editor: (el) => <SwitchEditorLayer {...editorProps} rowProperties={el} />,
          summaryFormatter: ({ column, row }) => <DataSummaryCellFormatter
            saveCurrentRowData={saveCurrentRowData}
            columnInfo={column}
            rowInfo={row}
            openEditorAfterSelect={openEditorAfterSelect}
          />
        }
        break;
      case "mail":
        columnConfig = {
          ...columnConfig,
          formatter: (el) => <MailFormatter {...formatterProps} rowProperties={el} />,
          editor: (el) => <MailEditorLayer {...editorProps} rowProperties={el} />,
          summaryFormatter: ({ column, row }) => <DataSummaryCellFormatter
            saveCurrentRowData={saveCurrentRowData}
            columnInfo={column}
            rowInfo={row}
            openEditorAfterSelect={openEditorAfterSelect}
          />
        }
        break;

      case "String":
        columnConfig = {
          ...columnConfig,
          formatter: (el) => <StringFormatter {...formatterProps} rowProperties={el} />,
          editor: (el) => <TextEditorLayer {...editorProps} rowProperties={el} />,
          summaryFormatter: ({ column, row }) => <DataSummaryCellFormatter
            saveCurrentRowData={saveCurrentRowData}
            columnInfo={column}
            rowInfo={row}
            openEditorAfterSelect={openEditorAfterSelect}
          />
        }
        break;

      case "location":
        columnConfig = {
          ...columnConfig,
          formatter: (el) => {
            return (
              <LocationFormatter {...formatterProps} rowProperties={el} />
            )
          },
          editor: (el) => <LocationEditorLayer {...editorProps} rowProperties={el} />,
          summaryFormatter: ({ column, row }) => <DataSummaryCellFormatter
            saveCurrentRowData={saveCurrentRowData}
            columnInfo={column}
            rowInfo={row}
            openEditorAfterSelect={openEditorAfterSelect}
          />
        }
        break;

      case "rupee":
        columnConfig = {
          ...columnConfig,
          formatter: (el) => <RupeeFormatter {...formatterProps} rowProperties={el} rupeeUnit={c.multiOptions[0] && c.multiOptions[0].value || '₹'} />,
          editor: (el) => <CurrencyEditorLayer {...editorProps} rowProperties={el} />,
          summaryFormatter: ({ column, row }) => <DataSummaryCellFormatter
            saveCurrentRowData={saveCurrentRowData}
            columnInfo={column}
            rowInfo={row}
            openEditorAfterSelect={openEditorAfterSelect}
          />
        }
        break;

      case "image":
        columnConfig = {
          ...columnConfig,
          formatter: (el) => <ImageFormatter {...formatterProps} rowProperties={el} />,
          editor: (el) => <ImageEditorLayer {...editorProps} rowProperties={el} />,
          summaryFormatter: ({ column, row }) => <DataSummaryCellFormatter
            saveCurrentRowData={saveCurrentRowData}
            columnInfo={column}
            rowInfo={row}
            openEditorAfterSelect={openEditorAfterSelect}
          />
        }
        break;


      case "attachment":
        columnConfig = {
          ...columnConfig,
          formatter: (el) => <AttachmentFormatter {...formatterProps} rowProperties={el} />,
          editor: (el) => <AttachmentEditorLayer {...editorProps} rowProperties={el} />,
          summaryFormatter: ({ column, row }) => <DataSummaryCellFormatter
            saveCurrentRowData={saveCurrentRowData}
            columnInfo={column}
            rowInfo={row}
            openEditorAfterSelect={openEditorAfterSelect}
          />
        }
        break;

      case "time":
        columnConfig = {
          ...columnConfig,
          formatter: (el) => <TimeFormatter {...formatterProps} rowProperties={el} />,
          editor: (el) => <TimeEditorLayer {...editorProps} rowProperties={el} />,
          summaryFormatter: ({ column, row }) => <DataSummaryCellFormatter
            saveCurrentRowData={saveCurrentRowData}
            columnInfo={column}
            rowInfo={row}
            openEditorAfterSelect={openEditorAfterSelect}
          />
        }
        break;

      case "date":
        columnConfig = {
          ...columnConfig,
          formatter: (el) => <DateFormatter {...formatterProps} rowProperties={el} />,
          editor: (el) => <DateEditorLayer {...editorProps} rowProperties={el} />,
          summaryFormatter: ({ column, row }) => <DataSummaryCellFormatter
            saveCurrentRowData={saveCurrentRowData}
            columnInfo={column}
            rowInfo={row}
            openEditorAfterSelect={openEditorAfterSelect}
          />
        }
        break;

      case "signature":
        columnConfig = {
          ...columnConfig,
          formatter: (el) => {
            return (
              <SignatureFormatter {...formatterProps} rowProperties={el} />
            )
          },
          editor: (el) => <FeatureNotAvailableEditor {...editorProps} rowProperties={el} />,
          summaryFormatter: ({ column, row }) => <DataSummaryCellFormatter
            saveCurrentRowData={saveCurrentRowData}
            columnInfo={column}
            rowInfo={row}
            openEditorAfterSelect={openEditorAfterSelect}
          />
        }
        break;

      case "status":
        columnConfig = {
          ...columnConfig,
          formatter: (el) => {
            // Done here as setting the color inside the component
            // from column multioptions required calling a set method which was causing re rendering and flickering.
            // Formatter is suppose to have static contents passed from here.
            let statusColor = ''
            c.multiOptions.forEach(multiOpnEl => {
              if (multiOpnEl.value === el.row[c.key]) {
                statusColor = multiOpnEl.color
              }
            });
            return <StatusFormatter {...formatterProps} rowProperties={el} colData={c.multiOptions} statusColor={statusColor} />
          },
          editor: (el) => {
            let statusColor = ''
            c.multiOptions.forEach(multiOpnEl => {
              if (multiOpnEl.value === el.row[c.key]) {
                statusColor = multiOpnEl.color
              }
            });
            return <StatusEditorLayer {...editorProps} rowProperties={el} statusOptions={c.multiOptions} statusColor={statusColor} />
          },
          summaryFormatter: ({ column, row }) => <DataSummaryCellFormatter
            saveCurrentRowData={saveCurrentRowData}
            columnInfo={column}
            rowInfo={row}
            openEditorAfterSelect={openEditorAfterSelect}
          />
        }
        break;

      case "url":
        columnConfig = {
          ...columnConfig,
          formatter: (el) => <UrlFormatter {...formatterProps} rowProperties={el} />,
          editor: (el) => <UrlEditorLayer {...editorProps} rowProperties={el} />,
          summaryFormatter: ({ column, row }) => <DataSummaryCellFormatter
            saveCurrentRowData={saveCurrentRowData}
            columnInfo={column}
            rowInfo={row}
            openEditorAfterSelect={openEditorAfterSelect}
          />
        }
        break;

      case "reminder":
        columnConfig = {
          ...columnConfig,
          formatter: (el) => {
            return (
              <ReminderFormatter {...formatterProps} rowProperties={el} />
            )
          },
          editor: (el) => <ReminderEditorLayer {...editorProps} rowProperties={el} />,
          summaryFormatter: ({ column, row }) => <DataSummaryCellFormatter
            saveCurrentRowData={saveCurrentRowData}
            columnInfo={column}
            rowInfo={row}
            openEditorAfterSelect={openEditorAfterSelect}
          />
        }
        break;

      case "unit":
        columnConfig = {
          ...columnConfig,
          formatter: (el) => <UnitFormatter {...formatterProps} rowProperties={el} unit={c.multiOptions[0] ? c.multiOptions[0].value : ''} />,
          editor: (el) => <UnitEditorLayer {...editorProps} rowProperties={el} />,
          summaryFormatter: ({ column, row }) => <DataSummaryCellFormatter
            saveCurrentRowData={saveCurrentRowData}
            columnInfo={column}
            rowInfo={row}
            openEditorAfterSelect={openEditorAfterSelect}
          />
        }
        break;

      case "number":
        columnConfig = {
          ...columnConfig,
          formatter: (el) => <NumberFormatter {...formatterProps} rowProperties={el} numberUnit={c.multiOptions[0] && c.multiOptions[0].value || ''} />,
          editor: (el) => <NumberEditorLayer {...editorProps} rowProperties={el} />,
          summaryFormatter: ({ column, row }) => <DataSummaryCellFormatter
            saveCurrentRowData={saveCurrentRowData}
            columnInfo={column}
            rowInfo={row}
            openEditorAfterSelect={openEditorAfterSelect}
          />
        }
        break;

      case "dropDown":
        columnConfig = {
          ...columnConfig,
          formatter: (el) => {
            let optionColor = el.row[`${c.key}_details`] && el.row[`${c.key}_details`][0] ? el.row[`${c.key}_details`][0].value : null
            if (!CSS.supports('color', optionColor)) {
              c.multiOptions.forEach(multiOpnEl => {
                if (multiOpnEl.value === el.row[c.key]) {
                  optionColor = multiOpnEl.color
                }
              })
            }
            return <SelectOneFormatter {...formatterProps} rowProperties={el} colData={c.multiOptions} optionColor={optionColor} />
          },
          editor: (el) => {
            const colorIndex = el.row[`${c.key}_details`] && el.row[`${c.key}_details`][0] ? el.row[`${c.key}_details`][0].value : ''
            let optionColor = c.multiOptions[colorIndex] ? (c.multiOptions[colorIndex].color) : null

            if (!CSS.supports('color', optionColor)) {
              c.multiOptions.forEach(multiOpnEl => {
                if (multiOpnEl.value === el.row[c.key]) {
                  optionColor = multiOpnEl.color
                }
              })
            }
            return <SelectOneEditorLayer {...editorProps} rowProperties={el} selectOneOptions={c.multiOptions} optionColor={optionColor} />
          },
          summaryFormatter: ({ column, row }) => <DataSummaryCellFormatter
            saveCurrentRowData={saveCurrentRowData}
            columnInfo={column}
            rowInfo={row}
            openEditorAfterSelect={openEditorAfterSelect}
          />
        }
        break;

      case "phoneNumber":
        columnConfig = {
          ...columnConfig,
          formatter: (el) => <PhoneNumberFormatter {...formatterProps} rowProperties={el} />,
          editor: (el) => <PhoneNumberEditorLayer {...editorProps} rowProperties={el} />,
          summaryFormatter: ({ column, row }) => <DataSummaryCellFormatter
            saveCurrentRowData={saveCurrentRowData}
            columnInfo={column}
            rowInfo={row}
            openEditorAfterSelect={openEditorAfterSelect}
          />
        }
        break;
      case "formula":
        columnConfig = {
          ...columnConfig,
          formatter: (el) => <StringFormatter {...formatterProps} rowProperties={el} />,
          editor: (el) => <FormulaEditorLayer {...editorProps} rowProperties={el} />,
          summaryFormatter: ({ column, row }) => <DataSummaryCellFormatter
            saveCurrentRowData={saveCurrentRowData}
            columnInfo={column}
            rowInfo={row}
            openEditorAfterSelect={openEditorAfterSelect}
          />
        }
        break;

      case "duration":
        columnConfig = {
          ...columnConfig,
          formatter: (el) => <DurationFormatter {...formatterProps} rowProperties={el} />,
          editor: (el) => <DurationEditorLayer {...editorProps} rowProperties={el} />,
          summaryFormatter: ({ column, row }) => <DataSummaryCellFormatter
            saveCurrentRowData={saveCurrentRowData}
            columnInfo={column}
            rowInfo={row}
            openEditorAfterSelect={openEditorAfterSelect}
          />
        }
        break;

      case "label":
        columnConfig = {
          ...columnConfig,
          formatter: (el) => {
            return <LabelFormatter {...formatterProps} rowProperties={el} colInfo={c} />
          },
          editor: (el) => {
            return <LabelEditorLayer {...editorProps} rowProperties={el} />
          },
          summaryFormatter: ({ column, row }) => <DataSummaryCellFormatter
            saveCurrentRowData={saveCurrentRowData}
            columnInfo={column}
            rowInfo={row}
            openEditorAfterSelect={openEditorAfterSelect}
          />
        }
        break;
      case "linkedSheet":
        columnConfig = {
          ...columnConfig,
          formatter: (el) => {
            return <LinkedSheetFormatter {...formatterProps} rowProperties={el} colInfo={c} />
          },
          editor: (el) => {
            return <LinkedSheetEditorLayer {...editorProps} rowProperties={el} />
          },
          summaryFormatter: ({ column, row }) => <DataSummaryCellFormatter
            saveCurrentRowData={saveCurrentRowData}
            columnInfo={column}
            rowInfo={row}
            openEditorAfterSelect={openEditorAfterSelect}
          />
        }
        break;

      case "staticBorderlessCell":
        columnConfig = {
          ...columnConfig,
          editable: false,
          formatter: (el) => <StaticBorderlessCell {...formatterProps} rowProperties={el} />,
          summaryFormatter: ({ column, row }) => <StaticBorderlessCell rowProperties={{ rowIdx: '' }} />
        }
        break;

      default:
        columnConfig = {
          ...columnConfig,
          formatter: (el) => <TextFormatter {...formatterProps} rowProperties={el} />,
          editor: (el) => <FeatureNotAvailableEditor {...editorProps} rowProperties={el} />,
          summaryFormatter: ({ column, row }) => <DataSummaryCellFormatter
            saveCurrentRowData={saveCurrentRowData}
            columnInfo={column}
            rowInfo={row}
            openEditorAfterSelect={openEditorAfterSelect}
          />
        }
        break;
    }
    return columnConfig
  })
  return columns
}