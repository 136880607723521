import { useState } from 'react'
import { useDispatch, useSelector } from "react-redux"

// slices
import { updateLoginInfoState } from '../../../slices/LoginSlice'

// css
import './Animation.css'

// icons
import logo from '../../../images/login/RBIcon.png'
import textWhite from '../../../images/login/RBTextWhite.png'
import { Redirect } from 'react-router-dom'

const AnimationSection = () => {

  // router variables
  const searchParams = new URLSearchParams(window.location.search)

  // redux variables
  const dispatch = useDispatch()
  const loginInfo = useSelector(state => state.loginInfo)
  const { error } = loginInfo

  // state variables
  const [goToMainPage, setGoToMainPage] = useState(false)

  // local variables
  const emailId = searchParams.get('emailId')
  const token = searchParams.get('token')

  const onLogoClick = () => {
    if (emailId && token) {
      removeError()
      setGoToMainPage(true)
    } else {
      window.location.reload()
    }
  }

  const removeError = () => {
    if (error) {
      dispatch(updateLoginInfoState([{ key: 'error', value: false }, { key: 'errorMessage', value: null }]))
    }
  }

  // classes
  const commonItemClasses = `absolute flex justify-center items-center px-1 sm:px-2 lg:px-6 gap-1 sm:gap-2 text-lg lg:gap-6 `

  // styles
  const commonItemStyles = { backgroundColor: 'rgba(217, 217, 217, 0.1)', border: '1px solid rgba(146, 146, 146, 0.46)', borderRadius: '5px', height: '136px', width: '75%', color: '#DFDFDF' }

  if (goToMainPage) {
    return <Redirect to='/signUp' />
  }

  return (
    <div className="relative w-full md:w-5/12 h-5/6 md:h-full child-in-center text-white overflow-hidden" style={{ flexShrink: '0', background: 'linear-gradient(133.48deg, #485057 2.19%, #22252A 77.71%)' }}>

      {/* Logo */}
      <div onClick={onLogoClick} className='child-in-center gap-1 absolute cursor-pointer' style={{ left: '57px', top: '50px' }}>
        <img src={logo} className='h-8' />
        <img src={textWhite} className='h-8' />
      </div>
      {/* Logo */}

      <div className="relative h-3/4 w-full transform" style={{ translate: '50%' }}>

        <div className={`animation_item_1 ${commonItemClasses}`} style={commonItemStyles}>
          <div className='font-semibold' style={{ fontSize: '65px', color: '#00CD15' }}>300%</div>
          <div className='description_item_1 font-normal'>
            Improvement in lead<br />conversion rates
          </div>
        </div>

        <div className={`animation_item_2 ${commonItemClasses}`} style={commonItemStyles}>
          <div className='font-semibold' style={{ fontSize: '65px', color: '#2F87E9' }}>23%</div>
          <div className='description_item_2 font-normal'>
            Decreased sales and<br />marketing costs
          </div>
        </div>

        <div className={`animation_item_3 ${commonItemClasses}`} style={commonItemStyles}>
          <div className='font-semibold' style={{ fontSize: '65px', color: '#F64135' }}>41%</div>
          <div className='description_item_3 font-normal'>
            Revenue increase per<br />sales person
          </div>
        </div>

        <div className={`animation_item_4 ${commonItemClasses}`} style={commonItemStyles}>
          <div className='font-semibold' style={{ fontSize: '65px', color: '#FEBF21' }}>24%</div>
          <div className='description_item_4 font-normal'>
            Shorter sales cycles
          </div>
        </div>

      </div>
    </div>
  )
}

export default AnimationSection