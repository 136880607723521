import { useEffect } from 'react'
import useContextMenu from './useContextMenu'
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux'
import { addEmptyRowToSheet, deleteRow, setRowDeleteCompleted } from '../../slices/RegisterSlice'
import { toggleDialogOpen, updateBody, updateHeaderText } from '../../slices/DialogSlice'

import { focusHighlightedCell } from '../../features/Register/Table/Utils'

const CustomContextMenu = () => {

  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  // local variables
  const { anchorPoints, show, cellInfo } = useContextMenu()
  const rowDeleteCompleted = registerData.rowDeleteCompleted
  const permissions = registerData.permissions

  const addRowAbove = () => {
    if (permissions.includes("admin") || permissions.includes("add")) {
      let rowIndex = registerData.rows.findIndex((el) => el.rowId === cellInfo.rowId)

      // does previous row exists
      let prevsRowIdx = rowIndex - 1
      const topRowId = prevsRowIdx >= 0 ? registerData.rows[prevsRowIdx].rowId : -1
      // bottom row id
      const bottomRowId = cellInfo.rowId
      dispatch(addEmptyRowToSheet({
        sheetId: registerData.sheetId,
        topRowId,
        bottomRowId,
        count: 1,
        idxToBeScrolled: rowIndex
      }))
      focusHighlightedCell()
    } else {
      alert("Permission Denied")
    }
  }

  const addRowBelow = () => {
    if (permissions.includes("admin") || permissions.includes("add")) {
      let rowIndex = registerData.rows.findIndex((el) => el.rowId === cellInfo.rowId)

      // previous row id
      const topRowId = cellInfo.rowId
      // does bottom row exists
      const bottomRowIdx = rowIndex + 1
      const bottomRowId = bottomRowIdx === registerData.rows.length - 1 ? -1 : registerData.rows[bottomRowIdx].rowId

      console.log(" bottomRowIdx ", bottomRowIdx)
      console.log(" registerData.rows ", registerData.rows.length - 1)
      dispatch(addEmptyRowToSheet({
        sheetId: registerData.sheetId,
        topRowId,
        bottomRowId,
        count: 1,
        idxToBeScrolled: rowIndex + 1
      }))
      focusHighlightedCell()
    } else {
      alert("Permission Denied")
    }
  }

  const deleteRowClick = () => {
    if (permissions.includes("admin")) {
      dispatch(toggleDialogOpen(true))
      dispatch(updateBody(getDeleteBody()))
      dispatch(updateHeaderText('Delete Row?'))
    } else {
      alert("Permission Denied")
    }
  }

  const deleteCurrentRow = () => {
    dispatch(deleteRow({ rowId: cellInfo.rowId, sheetId: registerData.sheetId }))
    dispatch(toggleDialogOpen(false))
    focusHighlightedCell()
  }

  const getDeleteBody = () => {
    return (
      <div className="bg-white">
        <div className="text-lg text-center p-4">CONFIRM DELETE</div>
        <div className="flex justify-center items-center gap-6 my-5">
          <button onClick={(e) => { e.preventDefault(); deleteCurrentRow() }} className="bg-gray-700 text-white rounded py-1 px-2">Yes</button>
          <button id='delete_row_modal_no_btn' onClick={(e) => { e.preventDefault(); dispatch(toggleDialogOpen(false)) }} className="bg-gray-700 text-white rounded py-1 px-2">No</button>
        </div>
      </div>
    )
  }

  // reset rowDeleteCompleted to false and close AddRowForm
  useEffect(() => {
    if (rowDeleteCompleted) {
      dispatch(setRowDeleteCompleted(false))
    }
  }, [rowDeleteCompleted])

  if (show) {
    return (
      <>
        {
          createPortal(
            <ul className={`rounded border border-gray-300 shadow  transition-all delay-100 ease-out ${show ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-1 pointer-events-none'}`}
              style={{ backgroundColor: '#EEF3F5', borderWidth: '1px', width: '120px', position: 'fixed', top: anchorPoints.y, left: anchorPoints.x }}>
              <li className="grid place-content-center border-b py-2 hover:bg-gray-200 cursor-pointer text-sm" onClick={addRowAbove}>Add row above</li>
              <li className="grid place-content-center  border-b py-2 align-center hover:bg-gray-200 cursor-pointer text-sm" onClick={addRowBelow}>Add row below</li>
              <li id='delete_row_oprn' className="grid place-content-center border-b py-2 align-center hover:bg-gray-200 cursor-pointer text-sm" onClick={deleteRowClick}>Delete row</li>
            </ul>, document.body
          )
        }
      </>
    )
  } else {
    return <></>
  }
}

export default CustomContextMenu;