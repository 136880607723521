import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// slices
import { updateColumnFormula, updateRegisterState, fetchRegisterData, fetchAllRegisterData } from '../../../../slices/RegisterSlice'

// components
import SelectColumnForFormula from './Components/SelectColumnForFormula'
import ColumnCalculator from './Components/ColumnCalculator'

const FormulaConfiguration = ({ preSetFormulaArr, selectedColIdx, closeDialog }) => {

  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  // state variables
  const [formulaStrArr, setFormulaStrArr] = useState(preSetFormulaArr ? preSetFormulaArr : [])

  // local variables
  const { sheetId, columns, columnFormulaUpdated, registerId } = registerData

  useEffect(() => {
    // console.log(" formulaStrArr ", formulaStrArr)
  }, [formulaStrArr])

  // close dialog after formula is updated and reset columnFormulaUpdated in store
  useEffect(() => {
    if (columnFormulaUpdated) {
      dispatch(fetchAllRegisterData({ registerId, sheetId })) // so that colum refreshes
      closeDialog()
      dispatch(updateRegisterState([{
        key: 'columnFormulaUpdated',
        value: false
      }]))
    }
  }, [columnFormulaUpdated])

  const changeFormulaStrArr = (input) => {
    let formulaStrArrCopy = JSON.parse(JSON.stringify(formulaStrArr))
    if (formulaStrArrCopy.length > 0) {
      const lastEl = formulaStrArrCopy[formulaStrArrCopy.length - 1]
      const secondLastEl = formulaStrArrCopy[formulaStrArrCopy.length - 2]
      {/*
        Functionalities:
        1. if new input is a { type: backspace } and the last was
            - number then trim the number and while trimming the if length becomes 0 then pop the element
            - any other type then just pop the element
        2. if new input is a { type: number } and the last was 
            - number, then these both should be appended
        3. if new input is a { string: ')' } then it should be allowed 
            - till total open and total closed are not equal
        
        Conditions:
        Do not allow user's new input from calculator
        1. if new input is { string: '(' }  and the last input was 
            - number ==> to not allow 'number('
            - column ==> to not allow 'column('
            - ')' ==> to not allow ')('
        2. if new input is a { type: column }  and the last input was 
            - column ==> to not allow 'columncolumn'
            - number ==> to not allow 'numbercolumn'
        3. if new input is a { type: number }  and the last input was 
            - column ==> to not allow 'columnnumber'
        4. if new input is a { type: operator }  and the last input was 
            - operator ==> to not allow 'operatoroperator'
            - open bracket '(' ==> to not allow '(operator'
        5. if new input is a closed bracket ')'  and the last input was 
            - '(' ==> () is not allowed
            - operator ==> to not allow 'operator)'
        6. if new input is '.'  and the last input string if was a number type, already had '.'
        - to not allow multiple decimals in a number Eg: 9.6.7 or 9..4 etc
      */}
      if (input.type === 'clearAll') {
        setFormulaStrArr([])
        return
      }
      if (input.type === 'backSpace') {  // F1
        if (lastEl.type === 'number') {
          let str = formulaStrArrCopy[formulaStrArrCopy.length - 1].string
          formulaStrArrCopy[formulaStrArrCopy.length - 1].string = str.slice(0, str.length - 1)
          if (formulaStrArrCopy[formulaStrArrCopy.length - 1].string.length === 0) {
            formulaStrArrCopy.pop()
          }
        } else {
          formulaStrArrCopy.pop()
        }
        setFormulaStrArr(formulaStrArrCopy)
        return
      }
      if (input.type === 'number' && lastEl.type === 'number') { // F2
        // C6
        if (input.string === '.' && lastEl.string.includes('.')) return
        formulaStrArrCopy[formulaStrArrCopy.length - 1].string = `${lastEl.string}${input.string}`
        setFormulaStrArr(formulaStrArrCopy)
        return
      }
      if (input.string === ')') { // F3
        let countOfOpenBracket = 0
        let countOfCloseBracket = 0
        formulaStrArrCopy.forEach((el) => {
          if (el.string === '(') countOfOpenBracket++
          if (el.string === ')') countOfCloseBracket++
        })
        if (countOfOpenBracket === countOfCloseBracket) return
        if (secondLastEl && secondLastEl.string === '(') return
      }
      // C1
      if (input.string === '(' && (lastEl.type === 'number' || lastEl.type === 'column' || lastEl.string === ')')) return
      // C2
      if (input.type === 'column' && (lastEl.type === 'column' || lastEl.type === 'number')) return
      // C3
      if (input.type === 'number' && lastEl.type === 'column') return
      // C4
      if (input.type === 'operator' && (lastEl.type === 'operator' || lastEl.string === '(')) return
      // C5
      if (input.string === ')' && (lastEl.string === '(' || lastEl.type === 'operator')) return


    }
    setFormulaStrArr([...formulaStrArrCopy, input])
  }

  const saveFormula = () => {
    // return if there is no formula
    if (formulaStrArr.length === 0) {
      alert("Please enter the formula.")
      return
    }

    let BEFormulaStrArr = []

    // check if all brackets are closed
    let countOfOpenBracket = 0
    let countOfCloseBracket = 0

    formulaStrArr.forEach((el) => {
      if (el.type === 'column') {
        BEFormulaStrArr.push(el.key)
      } else {
        if (el.string == "x") { // replace x ==> *
          BEFormulaStrArr.push('*')
        } else {
          BEFormulaStrArr.push(el.string)
        }

      }
      if (el.string === '(') countOfOpenBracket++
      if (el.string === ')') countOfCloseBracket++
    })
    if (countOfOpenBracket !== countOfCloseBracket) {
      alert("All brackets are not closed")
      return
    }

    // check if formula string is complete
    const lastEl = formulaStrArr[formulaStrArr.length - 1]
    if (lastEl.type === "operator") {
      alert("Formula cannot end by an operator")
      return
    }
    if (lastEl.type === 'number' && lastEl.string[lastEl.string.length - 1] === '.') {
      alert("Formula cannot end by a decimal point")
      return
    }
    dispatch(updateColumnFormula({
      columnId: columns[selectedColIdx].key,
      sheetId,
      formulaArray: BEFormulaStrArr
    }))
  }

  return (
    <div className="flex pl-4 pr-4 pb-7" style={{ backgroundColor: '#E3F0FF', height: '30rem', maxHeight: '80vh', width: '35rem', maxWidth: '95vw', }}>
      <div className="flex flex-col w-full sm:w-1/2 pr-2">
        <ColumnCalculator
          selectedColIdx={selectedColIdx}
          formulaStrArr={formulaStrArr}
          changeFormulaStrArr={changeFormulaStrArr}
          saveFormula={saveFormula}
        />
      </div>
      <div className="hidden flex-col sm:flex w-1/2 pl-3">
        <SelectColumnForFormula
          selectedColIdx={selectedColIdx}
          formulaStrArr={formulaStrArr}
          changeFormulaStrArr={changeFormulaStrArr}
        />
      </div>
    </div>
  )
}

export default FormulaConfiguration