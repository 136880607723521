import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

// slices
import { updatePopNavigationHistory } from '../../../../slices/RouterSlice'

//compnents
import PopUp from '../../../../partials/Dialog/PopUp'
import Header from './Components/Header'
import EmptyRegisterBody from './Components/EmptyRegisterBody'
import RegisterTemplatesListBody from './Components/RegisterTemplatesListBody'

// utils
import { allBusinessCategories } from '../../../../utils/Utils'

const AddRegisterPopUp = ({ isPopView }) => {

	// redux variables
	const dispatch = useDispatch()
	const routerData = useSelector(state => state.routerStore)
	const leftSidebarData = useSelector(state => state.leftSidebar)

	// router variables
	const history = useHistory()
	const location = useLocation()


	// state variables
	const [isInternallyOpen, setIsInternallyOpen] = useState(true)
	const [categorySelected, setCategorySelected] = useState(null)

	// local variables
	const { popUpNavigationHistory } = routerData
	const { search } = location
	const { registers } = leftSidebarData

	useEffect(() => {
		const query = new URLSearchParams(search)
		if (query.get('popUp') === 'addRegister') {
			setCategorySelected(null)
		} else if (query.get('registerCategoryType')) {
			const requiredBusinessCategory = allBusinessCategories.find(el => query.get('registerCategoryType') === el.type)
			setCategorySelected({
				id: query.get('registerCategoryType'),
				name: requiredBusinessCategory.name
			})
		}
	}, [location.search])

	// close if the esc key is pressed- sidebar menu
	useEffect(() => {
		const keyHandler = ({ keyCode }) => {
			if (!isInternallyOpen || keyCode !== 27) return;
			closePopUp()
		};
		document.addEventListener('keydown', keyHandler);
		return () => document.removeEventListener('keydown', keyHandler);
	}, []);

	const closePopUp = () => {
		setIsInternallyOpen(false)
		// const routeHistoryLength = popUpNavigationHistory.length
		// if (routeHistoryLength > 0 && popUpNavigationHistory[routeHistoryLength - 1].includes('addRegister')) history.goBack()
		// else {
		// 	history.push(location.pathname)
		// }
		history.push(location.pathname)
	}

	const selectCategory = (category) => {
		dispatch(updatePopNavigationHistory({
			type: 'push',
			path: location.pathname + '?registerCategoryType=' + category.type
		}))
		history.replace(location.pathname + '?registerCategoryType=' + category.type)
	}

	const goBack = () => {
		setCategorySelected(null)
		history.replace(location.pathname + '?popUp=addRegister')
	}

	const goToTemplatePreview = (templateId) => {
		history.push(location.pathname + '?templateId=' + templateId)
	}

	const goToCreateRegister = () => {
		history.replace(location.pathname + '?popUp=createRegister')
	}

	// ensures there is no flicker of first add register body replaced by templates list body
	const getConditionalBody = () => {
		const query = new URLSearchParams(search)
		if (query.get('popUp') === 'addRegister' && !categorySelected) {
			return <EmptyRegisterBody selectCategory={selectCategory} goToCreateRegister={goToCreateRegister} closeAddRegisterPopUp={closePopUp} />
		} else if (query.get('registerCategoryType') && categorySelected) {
			return <RegisterTemplatesListBody goToTemplatePreview={goToTemplatePreview} />
		}
	}

	const classes = {
		container: `flex justify-center items-center fixed h-screen top-0 bottom-0 left-0 w-full ${isInternallyOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`,
		backdropDiv: `flex justify-center items-center absolute h-screen top-0 bottom-0 left-0 w-full ${isInternallyOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`,
		subContainer: `flex flex-col items-center rounded bg-white shadow-md transform transition-all duration-200 ease-out ${isInternallyOpen ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0 pointer-events-none'} `,
		header: "w-full flex items-center justify-between rounded-t px-5 text-white",
		body: "w-full flex flex-col px-5 rounded-lg",
		footer: "w-full flex justify-end items-center border-t"
	}

	const styles = {
		header: { height: '12%', background: '#586C9E', fontSize: 22, fontStyle: 'normal', fontFamily: 'Sans-serif' },
		body: { height: '88%', backgroundColor: '#EEF3F5' }
	}

	if (isInternallyOpen) {
		return (
			<PopUp
				onClose={closePopUp}
				popUpContainerStyle={{ width: '42rem', maxWidth: '95%', maxHeight: '90%' }}
				popUpContainerClass={`h-full md:h-4/6 `}
				body={(
					<div className="w-full h-full flex flex-col">
						{/* header container starts */}
						<div
							className={classes.header}
							style={styles.header}
						>
							<Header
								categorySelected={categorySelected}
								popUpNavigationHistory={popUpNavigationHistory}
								goBack={goBack}
								closePopUp={closePopUp}
								registersExists={registers.length > 0}
							/>
						</div>
						{/* header container ends */}

						{/* body container starts */}
						<div className={classes.body} style={styles.body} >
							{
								getConditionalBody()
							}
						</div>
						{/* body container ends */}
					</div>
				)}
			/>
		)
	} else {
		return <></>
	}

}

export default AddRegisterPopUp