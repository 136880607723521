import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

//api call to add more images
export const uploadImages = createAsyncThunk('otherservice/uploadImage', async ({ files, communityId }) => {
  let imageUploadApiCalls = []
  let uploadedImages = []
  // collect all upload image calls in "imageUploadApiCalls".
  Array.from(files).forEach(fileEl => {
    let formaData = new FormData();
    formaData.append('image', fileEl)
    formaData.append('communityId', JSON.stringify(communityId))
    const axiosCall = axios.post('otherservice/uploadImage', formaData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    imageUploadApiCalls.push(axiosCall)
  })
  // send the collective results to action payload in "uploadedImages".
  return Promise.all(imageUploadApiCalls).then(function (values) {
    values.forEach((valEl) => {
      uploadedImages.push(valEl.data)
    })
    return uploadedImages
  });
})

export const carouselSlice = createSlice({
  name: 'carousel',
  initialState: {
    isCarouselOpen: false, // needs reset
    selectedRow: null, // needs reset
    selectedColKey: null, // needs reset
    staticImages: [], // needs reset
    isLoading: false,
    uploadedImages: [],
  },
  reducers: {
    openCarousel: (state, action) => {
      state.isCarouselOpen = action.payload
      // Needs state restoration as once slice is loaded its last values
      // are always persisted.
      if (!action.payload) { 
        state.selectedRow = null
        state.selectedColKey = null
        state.staticImages = []
      }
    },
    loadSelectedRowInfo: (state, action) => {
      state.selectedRow = action.payload.selectedRow
      state.selectedColKey = action.payload.selectedColKey
    },
    loadStaticImages: (state, action) => {
      state.staticImages =  action.payload.staticImages
    },
    resetUploadedImages: state => {
      state.uploadedImages = []
    }
  },
  extraReducers: {
    [uploadImages.pending]: (state, action) => {
      state.isLoading = true
    },
    [uploadImages.fulfilled]: (state, action) => {
      state.isLoading = false
      state.uploadedImages = action.payload
    },
    [uploadImages.rejected]: (state, action) => {
      state.isLoading = false
      state.uploadedImages = []
    },
  }
})

export const { openCarousel, loadSelectedRowInfo, loadStaticImages, resetUploadedImages } = carouselSlice.actions

export default carouselSlice.reducer

