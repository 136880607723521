import React, { useEffect, useRef, useState } from 'react';
import ReactDOM, { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux'

// slices 
import { toggleDialogOpen, updateBody, updateHeaderText } from '../../../../../slices/DialogSlice';

// components
import FormulaConfiguration from '../../../../Register/PopUps/FormulaConfiguration'

// css 
import './rowEditors.css';

// utils
import ErrorBoundary from '../../../../../utils/ErrorBoundary'
import { formulaToCalculatorFormat } from '../../../../../utils/Utils'

const FormulaEditor = ({ rowProperties, colIdx, columnEl }) => {
  // redux variables
  const registerData = useSelector(state => state.register)
  const dispatch = useDispatch()

  // local variables
  const { isAdmin, hasBasicEditPermission, editPermissions, columns } = registerData
  const { key: colKey } = columnEl
  const { onClose, row } = rowProperties
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colKey) === -1
  const value = row[colKey]

  // Empty row work for add permission
  const hasAddPermission = registerData.permissions.includes('add')
  const isRowEmpty = !Object.keys(row).some((key) => {
    if (key != 'rowId' && key != -1) {
      return row[key].length > 0
    }
  })
  const canAdd = hasAddPermission && isRowEmpty
  // Empty row work for add permission

  // returns { string, arr }
  const preSetFormulaVal = formulaToCalculatorFormat(columnEl.formula, columns)

  // state variables
  const [pos, setPos] = useState({})

  // ref variables
  const formulaEditorContainerRef = useRef(null)
  const formulaEditorDataCellRef = useRef(null)

  useEffect(() => {
    // as soon as the editor is rendered formulaEditorContainerRef should open
    // listening to table scroll represented by .rdg-light class
    onScroll()
    document.querySelector(".rdg-light").addEventListener("scroll", onScroll)
    document.addEventListener('keydown', keyHandler);
    return () => {
      document.removeEventListener("scroll", onScroll)
      document.removeEventListener('keydown', keyHandler);
    }
  }, []);

  const keyHandler = (e) => {
    // tab key press
    if (e.keyCode == 9) {
      if (
        document.activeElement.classList.contains('rdg-focus-sink') ||
        document.activeElement.classList.contains('sheetItem2') ||
        document.activeElement.id === "formula-editor-cancel-btn"
      ) {
        document.getElementById(`formula-editor-portal-container`).focus();
      }

    }
    if (e.keyCode !== 27) return;
    onClose(true);
  };

  const onScroll = () => {
    if (formulaEditorDataCellRef.current) {
      let positionTop = 0
      let leftShift = 0
      // here 130 is the height of url Editor Container and 35 of the show Url container
      if (formulaEditorDataCellRef.current.getBoundingClientRect().bottom + 130 >= window.innerHeight) {
        positionTop = positionTop - 130
      } else {
        positionTop = 40
      }
      const refValue = ReactDOM.findDOMNode(formulaEditorDataCellRef.current)
      if (refValue) {
        setPos({
          right: refValue
            .getBoundingClientRect().right,
          left: refValue
            .getBoundingClientRect().left + leftShift,
          top: refValue
            .getBoundingClientRect().top + positionTop,
          bottom: refValue
            .getBoundingClientRect().bottom,
        })
      }
    }

  }

  const handleClick = (e) => {
    e.preventDefault()
    onClose(true)
  }

  // handles key events of the editor container div
  const handleContainerDivKeyDown = (e) => {
    // enter key press
    if (e.keyCode == 13) {
      // Trigger click of the active element on which the focus is.
      if (document.activeElement.id === 'dataCell-urlEditor-save-btn') {
        e.preventDefault()
        e.stopPropagation()
      } else {
        document.activeElement.click();
        onClose(true)
      }
    }
  }

  const onEditClick = () => {
    dispatch(toggleDialogOpen(true))
    dispatch(updateBody(
      <FormulaConfiguration
        preSetFormulaArr={preSetFormulaVal.arr}
        selectedColIdx={colIdx}
        closeDialog={() => {
          dispatch(toggleDialogOpen(false))
          onClose(true)
        }}
      />
    ))
    dispatch(updateHeaderText('Add Formula'))
  }

  return (
    <ErrorBoundary>
      <div className="w-full h-full" tabIndex="-1" id="url-editor-container-div" onKeyDown={handleContainerDivKeyDown}>
        {/*Pre select value containing div starts here*/}
        <div ref={formulaEditorDataCellRef} tabIndex="0" onClick={handleClick} className="sheetItem1 focus:bg-gray-600">
          <span className="px-2" >
            {value || ''}
          </span>
        </div>
        {/*Pre select value containing div ends here*/}

        {/*Portal starts here*/}
        {createPortal(
          <div
            ref={formulaEditorContainerRef}
            className="flex flex-col justify-center items-center rounded-lg shadow-xl pl-1 pr-1"
            id="formula-editor-portal-container"
            tabIndex="-1"
            style={{
              boxShadow: ' 0px 4px 4px rgb(0 0 0 / 25%)',
              top: pos.top,
              bottom: pos.bottom,
              right: pos.right,
              left: pos.left,
              fontSize: '12px',
              position: 'fixed',
              width: '13rem',
              height: '130px',
              backgroundColor: '#fff'
            }}
          >
            <div className="w-full h-1/5 border-b-2" style={{ fontSize: 14 }}>
              Formula Already Added
            </div>
            <div className="flex w-full h-3/5 items-center justify-center border-b-2" style={{ fontSize: 13 }}>
              {preSetFormulaVal.string}
            </div>
            <div className="flex w-full h-1/5 cursor-pointer">
              {(canEdit || canAdd) && <div tabIndex="0" className="flex w-1/2 items-center justify-center border-r-2" onClick={onEditClick}>Edit</div>}
              <div tabIndex="0" id="formula-editor-cancel-btn" className="flex w-1/2 items-center justify-center" onClick={() => onClose(true)}>Cancel</div>
            </div>

          </div>,
          document.body)}
        {/*Portal ends here*/}
      </div>
    </ErrorBoundary>
  )
}

export default FormulaEditor;

const styles = {
  cancelBtn: { fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 'normal', fontSize: '12px', lineHeight: '24px', letterSpacing: '-0.006em', color: '#454545' },
  saveBtn: { backgroundColor: '#586C9E', fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 'normal', fontSize: '12px', lineHeight: '24px', letterSpacing: '-0.006em' },
  inputTxt: { height: '40%', fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 'normal', fontSize: '14px', lineHeight: '24px' }
}