import dropdownIcon from '../../../../../images/header popup icons/drop down.png'

const LastRowCellFormatter = ({ rowProperties, openEditorAfterSelect, colIdx, colKey }) => {

  // local variables
  const isCellSelected = rowProperties.isCellSelected
  const rowIdx = rowProperties.rowIdx
  const value = rowProperties.row[colKey]
  const rowId = rowProperties.row.rowId

  const handleOpenEditor = () => {
    openEditorAfterSelect({ isCellSelected, rowIdx, colIdx: rowProperties.column.idx })
  }

  return (
    <div
      id={`calculatorCell/${colKey}/${rowId}`}
      onClick={handleOpenEditor}
      className="sheetItem2" >
      {value}
      <img className="inline-block mr-2" src={dropdownIcon} />
    </div>
  )
};

export default LastRowCellFormatter