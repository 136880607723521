// genreal modules
import React from 'react';
import { Provider } from 'react-redux';
import './css/style.scss';
import './charts/ChartjsConfig';
import store from './app/store';

// auth provider
import AuthProvider from './partials/AuthProvider';

const App = () => {
  return (
    <Provider store={store}>
      <AuthProvider />
    </Provider>
  );
}

export default App;