import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getTemplateList } from '../../../../../slices/RegisterTemplateSlice'

import whiteEyeIcon from '../../../../../images/whiteEyeIcon.png'


const classes = {
	container: "w-full h-full overflow-hidden overflow-y-auto",
	gridContainer: "w-full h-full grid grid-cols-3 gap-3 items-top",
	cardContainer: "flex cursor-pointer justify-center pb-2",
	cardSubContainer: "flex flex-col justify-center items-center",
	previewBtn: "flex justify-center items-center text-white mt-2"
}

const styles = {
	cardConatainer: { marginTop: '10%' },
	cardSubContainer: { height: '10rem', width: '80%', backgroundColor: 'white', borderRadius: 8, boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)' },
	previewBtn: { backgroundColor: '#2AB200', fontSize: 10, width: '80%', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)', borderRadius: 2 }
}

const RegisterTemplatesListBody = ({ goToTemplatePreview }) => {
	// redux variables
	const dispatch = useDispatch()
	const registerTemplateData = useSelector(state => state.registerTemplateStore)

	// router variables
	const location = useLocation()
	const { search } = location

	// local variables
	const { templateList, isLoading } = registerTemplateData
	const query = new URLSearchParams(search)
	const registerCategoryType = query.get('registerCategoryType')

	useEffect(() => {
		dispatch(getTemplateList({ businessType: registerCategoryType }))
	}, [registerCategoryType])

	return (
		<div className={classes.container}>
			{
				!isLoading && templateList.length === 0 && <div className="w-full h-full flex justify-center items-center">No Results</div>
			}
			{
				isLoading && <div className="w-full h-full flex justify-center items-center">Loading...</div>
			}
			<div className={classes.gridContainer}>
				{
					!isLoading && templateList.map(templateEl =>
						<div onClick={() => goToTemplatePreview(templateEl.templateId)} key={`registerTemplateItem/${templateEl.templateId}`} className={classes.cardContainer} style={styles.cardConatainer} >
							<div className={classes.cardSubContainer} style={styles.cardSubContainer} >
								<img className="w-14 h-14 rounded-full" src={templateEl.avatar} width="32" height="32" />
								<span style={{ fontSize: 10, paddingTop: 2 }}>{templateEl.name}</span>
								<span style={{ fontSize: 8, paddingTop: 2 }}>{templateEl.description}</span>
								<div className={classes.previewBtn} style={styles.previewBtn}>
									<img className="h-2 w-3 mr-1 cursor-pointer" src={whiteEyeIcon} alt="white eye icon" />
									Preview
								</div>
							</div>
						</div>
					)
				}
			</div>
		</div>
	)
}

export default RegisterTemplatesListBody