import { useDispatch } from 'react-redux'

// slices
import { updateLoginInfoState } from '../../../slices/LoginSlice'

// icons
import leftArrowIcon from '../../../images/rightGreyArrow.png'

const BackButton = ({ onClick }) => {

  const dispatch = useDispatch()

  const onBackButtonClick = () => {
    dispatch(updateLoginInfoState([{key: 'error', value: false}, { key: 'errorMessage', value: null}]))
    onClick()
  }

  return (
    <div onClick={onBackButtonClick} className="absolute hover:bg-gray-200 rounded-full w-10 h-10 child-in-center cursor-pointer transition-colors delay-50">
      <img src={leftArrowIcon} className='transform rotate-180 relative' style={{ width: '9px' }} />
    </div>
  )
}

export default BackButton