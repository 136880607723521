import React from "react"
import { useSelector, useDispatch } from 'react-redux'

// 3rd party
import moment from "moment"

// slices
import { pdfDownload, excelDownload, togglePdfButtonLoader, toggleExcelButtonLoader } from '../../../../slices/RegisterSlice';

// components
import PortalPopup from "../../../../partials/PortalPopup/index.js"

// icons
import closeIcon from '../../../../images/close.png'
import downloadIcon from '../../../../images/header popup icons/download/download.png'
import excelIcon from '../../../../images/header popup icons/download/excel.png'
import pdfIcon from '../../../../images/header popup icons/download/pdf.png'


const Download = (props) => {

  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)
  const leftSidebarData = useSelector(state => state.leftSidebar)

  // local variables
  const { name, sheetId, activeBusinessId: communityId, isLoadingPdf, isLoadingExcel, filteredRows, searchQuery, groupDataBy, activeSheetName, filters } = registerData

  const fetchPdfData = async () => {
    let selectedRowIds = getSerialNoCollection()
    dispatch(togglePdfButtonLoader())
    await dispatch(pdfDownload({
      sheetId,
      communityId,
      name,
      selectedRowIds,
      date: groupDataBy === 'day' ? moment(activeSheetName, 'DD MMM YYYY').format('YYYY-MM-DD') : null
    }))
    dispatch(togglePdfButtonLoader())
  }

  const fetchExcelData = async () => {
    let selectedRowIds = getSerialNoCollection()
    dispatch(toggleExcelButtonLoader())
    await dispatch(excelDownload({
      sheetId,
      communityId,
      name,
      selectedRowIds,
      date: groupDataBy === 'day' ? moment(activeSheetName, 'DD MMM YYYY').format('YYYY-MM-DD') : null
    }))
    dispatch(toggleExcelButtonLoader())
  }

  const getSerialNoCollection = () => {
    let arr = []
    if (searchQuery.length > 0 || filters.length > 0) {
      filteredRows.forEach((el, index) => {
        if (el.rowId !== 'CALCULATE_ROW') { // to avoid calculate row
          arr.push(el.rowId)
        }
      })
    }
    return arr
  }

  return (
    <PortalPopup
      onClose={props.closePopup}
      containerClass={`w-full xs:w-auto rounded-lg m-2 p-4 bg-white text-center`}
      containerStyle={{}}
    >

      <div className="flex justify-end"><img onClick={props.closePopup} src={closeIcon} className='h-5 cursor-pointer' /></div>
      <div className="p-4">
        <div className="child-in-center py-2"><img src={downloadIcon} className='h-20' /></div>
        <div className='text-lg font-semibold py-2' style={{ color: '#545454' }}>Download Register</div>
        <div className='text-sm font-normal text-wrap py-2' style={{ color: '#666666' }}>Choose the format you want to download your Register</div>
        <div>
          <button
            className="w-full focus:outline-none h-12 rounded-lg mt-5 mb-2"
            style={{ border: '1px solid #C80A0A' }}
            onClick={fetchPdfData}
          >
            {
              !isLoadingPdf && (
                <div className="flex items-center justify-center gap-x-4 bg-white font-medium">
                  <img src={pdfIcon} loading='lazy' className="h-8" />
                  <div style={{ color: '#C80A0A' }}>Download PDF File</div>
                </div>
              )
            }
            {isLoadingPdf && <div className="flex items-center bg-white"><div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-600 m-auto"></div></div>}
          </button>
        </div>

        <div>
          <button
            className="w-full focus:outline-none h-12 rounded-lg mt-2 mb-5"
            style={{ border: '1px solid #21A366' }}
            onClick={fetchExcelData}
          >
            {
              !isLoadingExcel && (
                <div className="flex items-center justify-center gap-x-4 bg-white font-medium">
                  <img src={excelIcon} loading='lazy' className="h-8" />
                  <div style={{ color: '#21A366' }}>Download Excel File</div>
                </div>
              )
            }
            {isLoadingExcel && <div className="flex items-center bg-white"><div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-600 m-auto"></div></div>}
          </button>
        </div>
      </div>
      
    </PortalPopup>
  )
}

export default Download


{/* <div className='fixed w-full h-full inset-0 m-auto flex justify-center items-center' style={{ backgroundColor: "rgba(0,0,0,0.4)", fontSize: "24px", zIndex: 3 }}>
      <div className="absolute">
        <div style={{ backgroundColor: "rgba(255,255,255)", minWidth: "339px" }}>

          <button className="absolute top-3 right-3 h-6 w-6" onClick={props.closePopup}><img src={closeIcon} /></button>

          <div className="flex justify-center p-4 px-36 items-center bg-white w-full" style={{ color: "#C98643" }}>Download Register</div>

          <div className="px-20 py-4 flex justify-center flex-col" style={{ backgroundColor: "#EEF3F5" }}>

            <button onClick={fetchPdfData}>
              {
                !isLoadingPdf && (
                  <div className="flex items-center justify-center h-16 gap-x-4 my-4 p-2 bg-white rounded">
                    <img src={pdfIcon} />
                    <div>Download Pdf</div>
                  </div>
                )
              }
              {isLoadingPdf && <div className="flex items-center h-16 my-4 bg-white rounded"><div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-600 m-auto"></div></div>}
            </button>

            <button onClick={fetchExcelData}>
              {
                !isLoadingExcel && (
                  <div className="flex items-center justify-center gap-x-4 my-4 p-2 bg-white rounded">
                    <img src={excelIcon} />
                    <div>Download Excel</div>
                  </div>
                )
              }
              {isLoadingExcel && <div className="flex items-center h-16 my-4 bg-white rounded"><div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-600 m-auto"></div></div>}
            </button>

          </div>
        </div>
      </div>
    </div> */}