import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { toggleDialogOpen } from '../../slices/DialogSlice'

import closeIcon from '../../images/dialog/close-new.png'

const Dialog = () => {

  //redux variables
  const dispatch = useDispatch()
  const dialogData = useSelector(state => state.dialog)
  const themeData = useSelector(state => state.theme)

  //ref variables
  const dialog = useRef(null)

  //local variables
  const { dialogOpen, showHeader } = dialogData
  const { primary1, secondary } = themeData

  // close on click outside
  /* useEffect(() => {
     const clickHandler = (e) => {
       if (!dialog.current) return; // if the modal is clicked
       if (!dialogOpen || dialog.current.contains(e.target)) return; // if the modal is clicked
       dispatch(toggleDialogOpen(false)) // if backdrop is clicked
     }
     document.getElementById('dialog-backdrop').addEventListener('click', clickHandler);
     return () => {
       const dialogBackdropEl = document.getElementById('dialog-backdrop')
       if (dialogBackdropEl) dialogBackdropEl.removeEventListener('click', clickHandler)
     };
   });*/

  // close if the esc key is pressed- sidebar menu
  useEffect(() => {
    if (dialogOpen) {
      const keyHandler = ({ keyCode }) => {
        if (!dialogOpen || keyCode !== 27) return;
        dispatch(toggleDialogOpen(false))
      };
      document.addEventListener('keydown', keyHandler);
      return () => document.removeEventListener('keydown', keyHandler);
    }
  }, [dialogOpen]);

  return (
    <div
      className={`flex justify-center items-center absolute h-screen top-0 bottom-0 left-0 w-full ${dialogOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
      style={{ zIndex: '100' }}
    >
      <div
        id="dialog-backdrop"
        className={`flex justify-center items-center absolute h-screen top-0 bottom-0 left-0 w-full ${dialogOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
        style={{ background: 'rgba(0,0,0,0.4)' }}
        onClick={() => dispatch(toggleDialogOpen(false))}
      >
      </div>
      <div
        ref={dialog}
        className={`slide_down flex flex-col items-center shadow-md transform transition-all duration-200 ease-out ${dialogOpen ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0 pointer-events-none'} `}
      >
        {/*header*/}
        {
          showHeader &&
          <div
            className="text-white flex items-center justify-between rounded-lg rounded-b-none shadow-md z-10 px-5 text-xl font-bold w-full"
            style={{ minHeight: '3.5rem', background: '#FFF', color: primary1 }}
          >
            {dialogData.headerText}
            <button onClick={() => { dispatch(toggleDialogOpen(false)) }}><img src={closeIcon} alt="close dialog" /></button>
          </div>
        }

        {/*body*/}
        <div
          className="flex justify-center items-center rounded-lg w-full h-full"
          style={{ background: secondary }}
        >
          {dialogData.body}
        </div>

        {/*footer*/}
        {
          dialogData.footer &&
          <div
            className="border-t w-full flex justify-end items-center p-2"
          >
            {dialogData.footer}
          </div>
        }

      </div>
    </div>
  )
}

export default Dialog