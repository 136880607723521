import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

import DateSection from './components/DateSection'
import TimeSection from './components/TimeSection'
import CustomInputDropdown from '../../../../../../../partials/CustomInputDropdown'

// icons
import reminderIcon from '../../../../../../../images/datatype_icons/light/reminder.png'

const Reminder = ({ colkey, column, handleChange, formData, deleteEntry, shouldAllowNewEntry }) => {

  // redux variables
  const registerData = useSelector(state => state.register)

  //state variables
  const [showReminderDropdown, setShowReminderDropdown] = useState(false)
  const [showTimeDropdown, setShowTimeDropdown] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [time, setTime] = useState('')
  const [message, setMessage] = useState('')
  //'hasChanged' is used to call 'handleChange' method whenever time,date or message changes. 
  //It updates to 'true' when any of the 3 values are updated.
  const [hasChanged, setHasChanged] = useState(false)

  // local variables
  const value = moment(formData[colkey]).isValid() && !Number(parseInt(formData[colkey])) ? formData[colkey] : null
  const reminderDate = value ? moment(value).format('DD/MM/YYYY') : ''
  const reminderTime = value ? moment(value).format('LT') : ''
  const reminderMessage = formData[`${colkey}_details`] ? (formData[`${colkey}_details`].length > 0 ? formData[`${colkey}_details`][0].value : '') : ''
  let textarea = null
  const { isAdmin, hasBasicEditPermission, editPermissions } = registerData
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colkey) === -1

  //(!value || hasChanged) means: call handleChange when there's no value(case of empty sheet value) or if there is a value,
  // then, when the value is changed (after reminderEditor opens).
  useEffect(() => {
    if (showReminderDropdown && (!value || hasChanged)) {
      // saveReminderFormData()
      setHasChanged(false)
    }
  }, [showReminderDropdown, reminderMessage, time, startDate, hasChanged])

  //State values (startDate, time, message) doesn't update when cancel button is clicked after formData update, without the 3 useEffect calls. 
  //reminderDate, reminderTime, reminderMessage comes from formData.
  //After updates in the form, if cancel is clicked, formdata is updated, which also updates the local date, time, and message variables.
  useEffect(() => {
    if (value) setStartDate(new Date(moment(value).format('MM-DD-YYYY')))
  }, [reminderDate])

  useEffect(() => {
    if (value) setTime(reminderTime)
  }, [reminderTime])

  useEffect(() => {
    if (value) setMessage(reminderMessage)
  }, [reminderMessage])

  //to automatically increase the height of the textarea
  useEffect(() => {
    textarea = document.querySelector(`#textarea${colkey}-form`)
    if (textarea !== null) {
      textarea.style.height = "auto"
      let scHeight = textarea.scrollHeight
      textarea.style.height = `${scHeight}px`
    }
  }, [message])

  /*useEffect(()=>{
    if(isReminderEditorOpen && !startDate) { setStartDate(new Date()) }
    if(isReminderEditorOpen && !time) { setTime(moment().format('LT')) }
  }, [isReminderEditorOpen])
*/

  const onMessageChange = (e) => {
    if (!hasChanged) setHasChanged(true)
    const msg = e.target.value
    setMessage(msg)
  }

  //Reminder value to show on the form.
  const getSheetReminderValue = () => {
    const sheetDate = moment(reminderDate, 'DD/MM/YYYY').format('DD MMM')
    const reminderSheetValue = `${sheetDate} ${(reminderTime)}`
    return reminderSheetValue
  }

  //update formData
  const saveReminderFormData = () => {
    if (time && startDate) {
      const date = moment(startDate).format('DD-MM-YYYY')
      const reminder = moment(`${date} ${time}`, 'DD-MM-YYYY LT')
      const formattedReminder = getFormattedReminder(reminder)
      if (message) {
        handleChange({ colkey, value: formattedReminder, colkeyDetail: colkey + '_details', valueDetail: [{ value: message }] })
      } else {
        handleChange({ colkey, value: formattedReminder })
      }
      closeReminderDropdown()
    }
  }

  //To make the reminder format the same as that in the app: 
  //eg: "Wed Jan 05 18:09:00 GMT+05:30 2022" from "Wed Dec 29 2021 15:26:00 GMT+0530 (India Standard Time)"
  const getFormattedReminder = (reminder) => {
    const reminderString = String(reminder._d)
    const reminderArray = reminderString.split(' ')
    reminderArray.splice(6, 3)
    const year = reminderArray.splice(3, 1)
    const GMT = reminderArray.splice(4, 1)
    const GMTarray = GMT[0].split('')
    const newGMT = [...GMTarray.slice(0, 6), ':', ...GMTarray.slice(-2)].join('')
    const updatedReminder = [...reminderArray, newGMT, ...year].join(' ')
    return updatedReminder
  }

  const onDateChange = (date) => {
    setStartDate(date);
    if (!hasChanged) { setHasChanged(true) }
    if (!date) {
      handleChange({ colkey, value: "", colkeyDetail: colkey + '_details', valueDetail: [] })
    }
  }

  const onTimeDelete = () => {
    setTime(null)
    handleChange({ colkey, value: "", colkeyDetail: colkey + '_details', valueDetail: [] })
  }

  const closeReminderDropdown = () => {
    setShowReminderDropdown(false)
    closeTimeDropdown()
  }

  const closeTimeDropdown = () => {
    setShowTimeDropdown(false)
  }

  return (
    <div className="mb-6">

      <div className="colName">
        <img src={reminderIcon} className="field_icon" />
        <label htmlFor={column.name}>{column.name}</label>
      </div>

      {
        !canEdit && !shouldAllowNewEntry &&
        <div className="field">{value && time && startDate ? getSheetReminderValue() : ''}</div>
      }

      {
        (shouldAllowNewEntry || canEdit) &&
        <CustomInputDropdown
          fieldValue={value && time && startDate ? getSheetReminderValue() : ''}
          inputType={`div`}
          container={`form`}
          inputClasses={`field`}
          inputStyles={{}}
          dropdownClasses={``}
          dropdownStyles={{ marginTop: 8, minWidth: '17rem' }}
          buttonsInfo={{ showButtonsSection: true, showCancelButton: true, showSaveButton: true, onSaveButtonClick: saveReminderFormData }}
          showRemoveIcon={false}
          showDropDownMenu={showReminderDropdown}
          setShowDropDownMenu={setShowReminderDropdown}
          onFieldClick={() => { }}
          onDropdownMenuClick={() => { if (showTimeDropdown) closeTimeDropdown() }}
        >

          <DateSection startDate={startDate} onDateChange={onDateChange} />

          <TimeSection
            time={time}
            setTime={setTime}
            setHasChanged={setHasChanged}
            closeTimeDropdown={closeTimeDropdown}
            onTimeDelete={onTimeDelete}
            showTimeDropdown={showTimeDropdown}
            setShowTimeDropdown={setShowTimeDropdown}
            colkey={colkey}
          />

        </CustomInputDropdown>
      }

    </div>
  )
}

export default Reminder