import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

//slices
import { deletePage } from "../../../../../slices/RegisterSlice"

// components
import CustomThreeDotMenu from "../../../../../partials/CustomThreeDotMenu"
import PortalPopup from "../../../../../partials/PortalPopup"

//icons and images
import editBlack from '../../../../../images/header/edit.png'
import deleteRed from '../../../../../images/header/delete.png'
import deletePopupIcon from '../../../../../images/add-edit row dialog/delete-row.png'

const SelectedSheetMenuMobile = ({
  toggleEditSheetName,

}) => {

  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  // state variables
  const [showDeletePopup, setShowDeletePopup] = useState(false)

  // local variables
  const { sheetId, pages, permissions, registerId } = registerData
  const isAdmin = permissions.includes('admin')
  const canEdit = permissions.includes('edit')
  let activeSheetIndex
  const activeSheet = pages.find((p, i) => {
    activeSheetIndex = i
    return p.sheetId == sheetId
  })

  const sheetDeleteClick = (e) => {
    if (!(isAdmin || canEdit)) {
      alert("Permission Denied")
      return
    }
    setShowDeletePopup(true)
  }

  const confirmDeleteSheet = (sheetIdx, sheetInfo) => {
    dispatch(deletePage({
      sheetId: sheetInfo.sheetId,
      sheetBeingDeltdIdx: sheetIdx,
      registerId
    }))
  }

  const closeDeletePopup = () => {
    setShowDeletePopup(false)
  }

  const listData = [
    { icon: editBlack, label: 'Edit', onClick: () => toggleEditSheetName(activeSheet), showItem: true, style: { color: '#666666' }, iconClass: `h-5`, showLoader: false },
    { icon: deleteRed, label: 'Delete', onClick: sheetDeleteClick, showItem: true, style: { color: '#C60202' }, iconClass: `h-5`, showLoader: false }
  ]

  return (
    <div className='absolute right-2 h-full sm:hidden'>
      <CustomThreeDotMenu showDropdown={true} color={'white'} listData={listData} alignment={`vertical`} />
      {
        showDeletePopup &&
        <PortalPopup
          onClose={closeDeletePopup}
          containerClass={`w-full bg-white flex flex-col justify-center px-6 xs:w-auto rounded-lg m-2`}
        >
          <div className='child-in-center'><img src={deletePopupIcon} className='h-16 mt-6' /></div>
          <div className="text-lg text-center mt-4 font-semibold" style={{ color: '#545454' }}>Delete Page</div>
          <div className="text-sm text-center mt-2 mb-4 mx-auto" style={{ color: '#545454', maxWidth: '20rem' }}>
            {pages.length === 1 && 'Deleting this page will delete the Register as well. '}
            <span>Are you sure you want to delete this page?</span>
          </div>
          {/* Buttons */}
          <div className="flex justify-center sm:justify-end items-center gap-4 mt-3 mb-6">
            <button onClick={(e) => { e.preventDefault(); closeDeletePopup() }} className="py-1 px-4 secondary_btn w-1/2 sm:w-auto">Cancel</button>
            <button onClick={(e) => { e.preventDefault(); confirmDeleteSheet(activeSheetIndex, activeSheet); closeDeletePopup() }} className="text-white rounded-lg py-1 px-4 w-1/2 sm:w-auto hover:bg-red-700 bg-red-600 transition-colors delay-50">Confirm</button>
          </div>
          {/* Buttons */}
        </PortalPopup>
      }
    </div>
  )
}

export default SelectedSheetMenuMobile