import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// slices
import { renameRegister } from "../../../../../slices/RightSidebarSlice"
import { toggleRightSidebarOpen } from '../../../../../slices/RightSidebarSlice'

//images
import closeIcon from '../../../../../images/right-sidebar/close.png'
import editIcon from '../../../../../images/right-sidebar/edit.png'

import tickMarkWhite from '../../../../../images/right-sidebar/tickMarkWhite.png'

const Header = () => {

  //redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)
  const rightSidebarData = useSelector(state => state.rightSidebar)
  const leftSidebarData = useSelector(state => state.leftSidebar)

  //state variables
  const [editedRegisterName, setEditedRegisterName] = useState(null)
  const [editMode, setEditMode] = useState(false)

  // local variables
  const { registerId, permissions } = registerData
  const { registerName, avatar, rightSidebarOpen, isLoading } = rightSidebarData
  const { registers } = leftSidebarData
  const isAdmin = permissions.includes("admin")

  useEffect(() => {
    if (!rightSidebarOpen) {
      setEditedRegisterName(null)
      setEditMode(false)
    }
  }, [rightSidebarOpen])

  const onEditIconClick = () => {
    if (!isAdmin) {
      alert("Permission Denied")
      return
    }
    setEditMode(true)
    setEditedRegisterName(registerName)
  }

  const saveRegisterName = () => {
    dispatch(renameRegister({ registerId, name: editedRegisterName, oldName: registerName, registers }))
    setEditedRegisterName(null)
    setEditMode(false)
  }

  const onRegisterNameInputKeydown = (e) => {
    if (e.code === 'Enter') {
      saveRegisterName()
    }
  }

  return (
    <div className="px-4 py-3 pt-0 group" style={{ background: '#586C9D', height: '30%' }}>
      <div onClick={() => dispatch(toggleRightSidebarOpen({ rightSidebarOpen: false, body: null }))} className="h-1/6 flex justify-end items-center"><img className='h-5 scale-75 transform transition-transform delay-50 hover:scale-100 cursor-pointer relative top-2' src={closeIcon} /></div>
      <div className="h-4/6 flex justify-center items-center"><img className='h-full' src={avatar} /></div>
      <div className="h-1/6">
        {
          editMode ?
            (
              <div className="flex justify-between items-center h-full">
                <input
                  id="register-name-edit-input"
                  value={editedRegisterName}
                  onChange={(e) => setEditedRegisterName(e.target.value)}
                  className='text-xl pr-2 w-full focus:outline-none text-white'
                  style={{ background: '#586C9D' }}
                  onKeyDown={onRegisterNameInputKeydown}
                  autoFocus />
                <img onClick={saveRegisterName} className='w-5 cursor-pointer' src={tickMarkWhite} />
              </div>
            )
            :
            (
              <div className="flex justify-between items-center h-full">
                {
                  isLoading ?
                    <div className="animate-spin rounded-full h-7 w-7 border-b-2 border-white-100 relative" />
                    :
                    <span className='text-white text-xl truncate'>{registerName || ''}</span>
                }
                <img onClick={onEditIconClick} className='h-5 scale-75 opacity-0 transform transition-all delay-50 hover:scale-100 group-hover:opacity-100 cursor-pointer' src={editIcon} />
              </div>
            )
        }
      </div>
    </div>
  )
}

export default Header