// icons
import deleteIcon from '../../../../../../../images/ic_trash_icon.png'
import editIcon from '../../../../../../../images/ic_edit_icon.png'

const DisplayContainer = ({ url, editKeyDown, onEditIconClick, onDeleteIconClick, canEdit }) => {
  return (
    <div className="flex justify-center w-full px-4" >
      <div className="truncate w-4/6">
        <a tabIndex="0" className="text-blue-500" target="_blank" href={url}>{url}</a>
      </div>
      {
        canEdit &&
        <div className="flex w-2/6 place-content-end items-center space-x-2.5">
          <div tabIndex="0" className="cursor-pointer" onKeyDown={editKeyDown} onClick={onEditIconClick}>
            <img src={editIcon} className="w-3.5 h-3.5" alt="" />
          </div>
          <div tabIndex="0" id="dataCell-urlEditor-delete-icon-btn" className="cursor-pointer" onClick={onDeleteIconClick}>
            <img src={deleteIcon} className="w-3.5 h-3.5" alt="" />
          </div>
        </div>
      }
    </div>
  )
}

export default DisplayContainer