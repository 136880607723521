import { useState, useRef, useEffect } from "react"

const TakePhoto = ({ cellProps, showCamera, setShowCamera, setDroppedImages, droppedImages }) => {

  // state variables
  const [showPhoto, setShowPhoto] = useState(false)
  const [cameraErrorMessage, setCameraErrorMessage] = useState(null)
  const [activeStream, setActiveStream] = useState(null)

  // ref variables
  const videoRef = useRef(null)
  const photoRef = useRef(null)

  // whene component unmounts eg:if the person clicks outside and the pop up closes then the camera should be stopped
  useEffect(() => {
    return () => {
      if (activeStream) {
        activeStream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
    }
  }, [activeStream])

  useEffect(() => {
    if (!showPhoto && showCamera) {
      getVideo()
    }
  }, [videoRef, showPhoto])

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: { width: 640, height: 480 }
      })
      .then(stream => {
        if (cameraErrorMessage) setCameraErrorMessage(null)
        let video = videoRef.current
        video.srcObject = stream
        video.play()
        setActiveStream(stream)
      })
      .catch(err => {
        setCameraErrorMessage('You need to allow access to your camera')
      })
  }

  const onCancelBackBtnClick = () => {
    if (showPhoto) { // on cancel click
      let photo = photoRef.current
      let video = videoRef.current
      let width = 640
      const height = video.clientHeight
      let ctx = photo.getContext('2d')
      ctx.clearRect(0, 0, width, height)
      setShowPhoto(false)
    } else { // on back click
      setShowCamera(false)
    }
  }

  const onUploadCaptureBtnClick = () => {
    if (showPhoto) { // on upload click
      let photo = photoRef.current
      let dataURL = photo.toDataURL()
      const blob = dataURItoBlob(dataURL)
      const resultFile = new File([blob], `${cellProps.columnName}-row${cellProps.rowNo}-${droppedImages.length + 1}.jpg`)
      setDroppedImages([...droppedImages, resultFile])
      setShowPhoto(false)
      setShowCamera(false)
      setActiveStream(null) // so that when a new video stream starts it is ensured that old one is removed
    } else { // on capture click
      let video = videoRef.current
      let photo = photoRef.current
      const width = 640
      const height = video.clientHeight
      let ctx = photo.getContext('2d')
      ctx.drawImage(video, 0, 0, width, height)
      setShowPhoto(true)
      activeStream.getTracks().forEach(function (track) {
        track.stop();
      });
    }
  }

  const dataURItoBlob = (dataURL) => {
    // convert base64 to raw binary data held in a string
    let byteString = atob(dataURL.split(',')[1]);
    // separate out the mime component
    let mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to an ArrayBuffer
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  const classes = {
    container: `flex flex-col justify-around items-center p-5`,
    cameraContainer: `h-5/6 overflow-hidden relative`,
    video: ``,
    photo: `absolute ${showPhoto ? 'top-0' : 'hidden'}`,
    buttonsContainer: `flex gap-3`,
    buttonCommon: `text-center py-2 rounded border cursor-pointer w-40 shadow transition-all delay-50 `,
    button1: `hover:bg-gray-200`,
    button2: `bg-blue-500 text-white hover:bg-blue-600`,
  }

  const styles = {
    container: { width: '80rem', maxWidth: '90vw', height: '80vh' },
    video: { height: '640', width: '480' },
    photo: { height: '640', width: '480' }
  }

  return (
    <div className="bg-white">
      {
        cameraErrorMessage ?
          <div style={styles.container} className={classes.container}>{cameraErrorMessage}</div>
          :
          /* Video/Image */
          <div style={styles.container} className={classes.container}>
            < div className={classes.cameraContainer}>
              <video ref={videoRef} className={classes.video}></video>
              <canvas ref={photoRef} className={classes.photo} width="640" height="480"></canvas>
            </div>
            {/* Buttons */}
            <div className={classes.buttonsContainer}>
              <div onClick={onCancelBackBtnClick} className={`${classes.button1} ${classes.buttonCommon}`}>{showPhoto ? 'Cancel' : 'Back'}</div>
              <div onClick={onUploadCaptureBtnClick} className={`${classes.button2} ${classes.buttonCommon}`}>{showPhoto ? 'Upload' : 'Capture'}</div>
            </div>
          </div>
      }
    </div >
  )
}

export default TakePhoto