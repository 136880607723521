import { useEffect, useState, useRef } from 'react'
import ReactDOM, { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'

// utils
import ErrorBoundary from '../../../../../utils/ErrorBoundary'
import { getValidatedPhoneNumber } from '../../Utils'
import DeviceMeta from '../../../../../utils/DeviceDetector'

const PhoneNumberEditor = ({ rowProperties, saveCurrentRowData, colIdx, columnEl }) => {
  // redux variables 
  const registerData = useSelector(state => state.register)

  // local variables
  const colKey = columnEl.key
  const onClose = rowProperties.onClose
  const rowId = rowProperties.row.rowId
  const value = rowProperties.row[colKey]

  // state variables
  const [phoneNumberText, setphoneNumberText] = useState(value || '')
  const [pos, setPos] = useState({})
  const [dynamicWidth, setDynamicWidth] = useState('')

  // ref variables
  const phoneNumberEditorDivRef = useRef(null)
  const phoneNumberEditorInputRef = useRef(null)

  useEffect(() => {
    setDynamicWidth(phoneNumberEditorDivRef.current.clientWidth)
    if (!DeviceMeta.isMobileOrTablet())
    // Sets position of the phoneNumberText box in portal w.r.t phoneNumberEditorDivRef
    // using its ref.
    onScroll()
    // listeneing to table scroll represented by .rdg-light class
    document.querySelector(".rdg-light").addEventListener("scroll", onScroll)
    return () => {
      document.removeEventListener("scroll", onScroll)
    }
  }, []);

  useEffect(() => {
    let clientWidth = phoneNumberEditorDivRef.current.clientWidth
    if (phoneNumberText.length * 7 > clientWidth) {
      setDynamicWidth(phoneNumberText.length + 'ch')
    } else {
      setDynamicWidth(phoneNumberEditorDivRef.current.clientWidth)
    }
  }, [phoneNumberText, phoneNumberEditorDivRef.current && phoneNumberEditorDivRef.current.clientWidth || ''])

  const onScroll = () => {
    if (ReactDOM.findDOMNode(phoneNumberEditorDivRef.current)) {
      setPos({
        right: ReactDOM.findDOMNode(phoneNumberEditorDivRef.current)
          .getBoundingClientRect().right,
        left: ReactDOM.findDOMNode(phoneNumberEditorDivRef.current)
          .getBoundingClientRect().left,
        top: ReactDOM.findDOMNode(phoneNumberEditorDivRef.current)
          .getBoundingClientRect().top,
        bottom: ReactDOM.findDOMNode(phoneNumberEditorDivRef.current)
          .getBoundingClientRect().bottom,
      })
    }
  }

  const clickHandler = () => {
    saveCurrentRowData(phoneNumberEditorInputRef.current.value, colKey, rowId, registerData.rows)
  };

  const handleContainerDivKeyDown = (e) => {
    if (e.keyCode == 13 || e.keyCode === 9) {
      e.preventDefault()
      saveCurrentRowData(phoneNumberText, colKey, rowId, registerData.rows, false, false, null, e)
      onClose(true)
    }
  }

  return (
    <ErrorBoundary>
      <div ref={phoneNumberEditorDivRef} className="w-full h-full">

        {
          DeviceMeta.isMobileOrTablet() ?
            <div onKeyDown={handleContainerDivKeyDown} >
              <input
                ref={phoneNumberEditorInputRef}
                name={colKey}
                type="text"
                placeholder="Enter Phone Number"
                value={phoneNumberText}
                onChange={(e) => {
                  const userProducedVal = e.target.value
                  if ((/^[0-9+()]+$/).test(userProducedVal) || userProducedVal.trim().length === 0)
                    setphoneNumberText(userProducedVal)
                }}


                className="bg-white "
                style={{ fontSize: '12px', width: dynamicWidth }}
                autoFocus
                onBlur={clickHandler}
              />
            </div>
            :
            createPortal(
              <div onKeyDown={handleContainerDivKeyDown} >
                <input
                  ref={phoneNumberEditorInputRef}
                  name={colKey}
                  type="text"
                  placeholder="Enter Phone Number"
                  value={phoneNumberText}
                  onChange={(e) => {
                    const userProducedVal = e.target.value
                    if ((/^[0-9+()]+$/).test(userProducedVal) || userProducedVal.trim().length === 0)
                      setphoneNumberText(userProducedVal)
                  }}


                  className="bg-white "
                  style={{ top: pos.top, bottom: pos.bottom, right: pos.right, left: pos.left, fontSize: '12px', position: 'fixed', width: dynamicWidth, height: phoneNumberEditorDivRef.current && phoneNumberEditorDivRef.current.clientHeight }}
                  autoFocus
                  onBlur={clickHandler}
                />
              </div>,
              document.body
            )}

      </div>
    </ErrorBoundary>
  )
}

export default PhoneNumberEditor