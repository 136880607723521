import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

const NumberFilter = ({ changeFilterSettings, selectedfilterData, selectedCol, errorColumns }) => {

  // redux 
  const registerData = useSelector(state => state.register)

  //state variables
  const [checkedIndex, setCheckedIndex] = useState(-1)

  //local variables
  const isSno = selectedCol.key === '-1'

  // Number filter form data
  let settings = [
    {
      showField: !isSno,
      index: 0,
      label: 'By Greater Than',
      showInput: true,
      id: 'number-filter-greater-than',
      value: '>'
    },
    {
      showField: !isSno,
      index: 1,
      label: 'By Less Than',
      showInput: true,
      id: 'number-filter-less-than',
      value: '<'
    },
    {
      showField: true,
      index: 2,
      label: 'In between',
      showInput: true,
      id: 'number-filter-between',
      value: 'BETWEEN'
    },
    {
      showField: !isSno,
      index: 3,
      label: 'Blank value',
      showInput: false,
      id: 'number-filter-blank',
      value: 'NONE'
    },
    {
      showField: isSno,
      index: 4,
      label: 'Custom',
      showInput: true,
      id: 'number-filter-custom',
      value: 'CUSTOM'
    },
  ]

  const getChecked = (data) => {
    let isChecked = false;
    if (data.id === 'number-filter-greater-than' && selectedfilterData.indexOf('>') > -1) isChecked = true
    else if (data.id === 'number-filter-less-than' && selectedfilterData.indexOf('<') > -1) isChecked = true
    else if (data.id === 'number-filter-between' && selectedfilterData.indexOf('BETWEEN') > -1) isChecked = true
    else if (data.id === 'number-filter-blank' && selectedfilterData.indexOf('NONE') > -1) isChecked = true
    else if (data.id === 'number-filter-custom' && selectedfilterData.indexOf('CUSTOM') > -1) isChecked = true
    return isChecked
  }

  const selectValue = (val) => {
    if (selectedfilterData.includes(val)) changeFilterSettings([]) // remove the filter
    else changeFilterSettings([val]) // add the filter

  }

  const onGreaterLessOnChange = ({ target }) => {
    let arr = [...selectedfilterData]
    arr[1] = target.value
    changeFilterSettings(arr)
  }

  const onFromToChange = ({ target }, position) => {
    let arr = [...selectedfilterData]
    arr[position] = target.value
    changeFilterSettings(arr)
  }

  const selectFilterOnClick = (settingsEl) => {
    if (!getChecked(settingsEl)) selectValue(settingsEl.value)
  }

  const styles = {
    formCheckInput: { border: '1.5px solid #35A600', borderRadius: '3px', height: '1.3rem', width: '1.3rem', outline: 'none' }
  }

  return (
    <div className="flex flex-col pl-2 pr-0 sm:pr-2 w-60 overflow-y-auto">

      <div style={{ height: '4rem', flexShrink: '0' }}></div>

      <div className="overfont-medium">
        {
          settings && settings.map((settingsEl, i) => {
            if (!settingsEl.showField) return
            return (
              <li key={`${settingsEl.text}-${i}`} className='list-none py-3 first:pt-1 border-b last:border-0'>

                {/* label and checkbox */}
                <div class="flex justify-between items-center pb-1 cursor-pointer pr-1" onClick={() => selectValue(settingsEl.value)}>
                  <label htmlFor={`${settingsEl.id}`} className='cursor-pointer'>
                    {settingsEl.label}
                  </label>
                  <input
                    className="cursor-pointer"
                    type="checkbox" style={{ ...styles.formCheckInput, backgroundColor: getChecked(settingsEl) ? '#35A600' : '#fff' }} id={`${settingsEl.id}`} readOnly
                    checked={getChecked(settingsEl)} />
                </div>
                {/* label and checkbox */}

                {/* input */}
                <div onClick={() => selectFilterOnClick(settingsEl)}>
                  {
                    settingsEl.showInput ? // as for blank value we dont show input text
                      settingsEl.index == 2 ? // in between is for 2
                        <div className='child-in-center flex-col sm:flex-row' style={{ color: '#878787' }}>
                          <div className='w-full sm:w-1/2 sm:pr-2 mb-2 md:mb-0'>
                            <div>From</div>
                            <input

                              onChange={(e) => onFromToChange(e, 1)}
                              className="field focus:ring-transparent"
                              style={{ height: '2rem', paddingRight: '0px' }}
                              type="number"
                              value={selectedfilterData[1] && getChecked(settingsEl) ? selectedfilterData[1] : ''}
                            />
                          </div>
                          <div className='w-full sm:w-1/2 sm:pl-2'>
                            <div>To</div>
                            <input

                              onChange={(e) => onFromToChange(e, 2)}
                              className="field focus:ring-transparent"
                              style={{ height: '2rem', paddingRight: '0px' }}
                              type="number"
                              value={selectedfilterData[2] && getChecked(settingsEl) ? selectedfilterData[2] : ''}
                            />
                          </div>
                        </div>
                        :
                        <input

                          onChange={onGreaterLessOnChange}
                          className="field focus:ring-transparent"
                          style={{ height: '2rem', paddingRight: '0px' }}
                          type="number"
                          value={selectedfilterData[1] && getChecked(settingsEl) ? selectedfilterData[1] : ''}
                        />
                      :
                      <></>
                  }
                </div>
                {/* input */}
                {settingsEl.value !== 'NONE' && errorColumns.indexOf(selectedCol.key) > -1 && selectedfilterData.indexOf(settingsEl.value) > -1 && <span style={{ color: '#D81205' }}>Field required</span>}
              </li>
            )
          })
        }
      </div>

    </div>
  )
}

export default NumberFilter