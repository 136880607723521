import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

//slices
import { addDataByQr, updateRegisterData, updateRegisterState } from "../../../../../slices/RegisterSlice"
import { setToastDetails } from '../../../../../slices/ToastSlice'

//icons and images
import clearSearchWhite from "../../../../../images/clearSearchWhite.png"

const ScannerInput = () => {

  //redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  // refs
  const scannerInputRef = useRef(null)

  //state variables
  const [scannerValue, setScannerValue] = useState('')

  //local variables
  const { sheetId, qrNewEntryData, rowIndex, errorMessage } = registerData

  useEffect(() => {
    if (scannerValue.length > 10) {
      dispatch(addDataByQr({ sheetId, qrValue: scannerValue }))
    }
  }, [scannerValue])

  useEffect(() => {
    if (qrNewEntryData && qrNewEntryData.length > 0) {
      let FEformatObj = {}
      // BE sends an array which needs to be converted according to FE format
      qrNewEntryData.forEach(el => {
        FEformatObj[el.columnId] = el.value
        FEformatObj[el.columnId + '_details'] = el.detailedValue
      })
      FEformatObj['-1'] = "10"
      FEformatObj['addColPlusButton'] = ""
      FEformatObj['rowId'] = "LAST_0"
      dispatch(updateRegisterData({ data: qrNewEntryData, rowIndex, formData: FEformatObj }))
      dispatch(updateRegisterState([{ key: 'qrNewEntryData', value: null }]))
      // empty the scanner empty text and refocus it again
      setScannerValue("")
      setTimeout(() => {
        scannerInputRef.current.focus()
      }, 1000) // time value added because the table cell is focused first so we need to bring back the focus on input text
    }
  }, [qrNewEntryData])

  useEffect(() => {
    if (errorMessage) {
      dispatch(setToastDetails({ message: errorMessage, type: 'error' }))
      dispatch(updateRegisterState([{ key: 'errorMessage', value: null }]))
      // empty the scanner empty text and refocus it again
      setScannerValue("")
      scannerInputRef.current.focus()
    }
  }, [errorMessage])

  const onScannerValueChange = (e) => {
    e.preventDefault()
    if (e.target.value.length < 10) {
      setScannerValue('')
      return
    }
    setScannerValue(e.target.value)
  }

  const onPaste = (e) => {
    // e.preventDefault()
    // return false
  }

  return (
    <form className="ml-4 relative flex items-center overflow-auto no-scrollbar" style={{ maxWidth: '30%' }}>
      <input
        ref={scannerInputRef}
        value={scannerValue}
        onChange={onScannerValueChange}
        onPaste={onPaste}
        className="inputCommon h-full w-full" placeholder="Scanner Input"
        style={{ border: '1px solid #7795B8' }}
      />
      <img src={clearSearchWhite} onClick={() => setScannerValue('')} className={`inputCommonCrossIcon h-4 max-h-full ${scannerValue ? '' : 'hidden'}`} />
    </form>
  )
}

export default ScannerInput