import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// components
import CustomInputDropdown from "../../../../../../partials/CustomInputDropdown"

// icons
import pageBlue from '../../../../../../images/activePageBlue.png'
import { useEffect } from "react"
import FolderElement from "./FolderElement"

const SelectRegisterToLink = ({
  selectedRegister,
  setSelectedRegister,
  pages,
  selectedPage,
  setSelectedPage,
  pagesLoading,
  columnInfo
}) => {

  // redux variables
  const leftSidebarData = useSelector(state => state.leftSidebar)
  const registerData = useSelector(state => state.register)

  // state variables
  const [showRegisterMenu, setShowRegisterMenu] = useState(false)
  const [showPagesMenu, setShowPagesMenu] = useState(false)
  const [pagesInitiallyLoaded, setPagesInitiallyLoaded] = useState(false)

  // local variables
  const { registers, activeBusinessId } = leftSidebarData
  const { registerId } = registerData

  // Remove the currently selected register
  let registersForMenu = registers.filter((reg) => {
    return (reg.registerId != registerId)
  })

  useEffect(() => {
    // pre fill register, page and column if linked column is edited, i.e., if previous col was of type 'linkedSheet'
    if (columnInfo.dataType == 'linkedSheet') {

      registersForMenu.forEach((reg) => {
        if (reg.registerId == columnInfo.linkedRegisterId) {
          setSelectedRegister(reg)
        }
      })
    }
    // pre fill registers select menu if only one register exists
    else if (registersForMenu.length == 1) {
      setSelectedRegister(registersForMenu[0])
    }
  }, [])

  // pre fill selected page if only one page exists and if a linked column is not updated. 
  // if linked col is updated to linked col, selected page should be pre filled only to the page the previous col was set to.
  // case when page is deleted is handled.
  useEffect(() => {
    if (pages.length == 1 && columnInfo.dataType != 'linkedSheet') {
      setSelectedPage(pages[0])
    }
    if (!pagesInitiallyLoaded && pages.length > 0) {
      setPagesInitiallyLoaded(true)
    }
  }, [pages])

  // pre fill selected page if linked col is updated to linked col. pages is set to pages of previous linked col.
  // This is done only once, intially.
  useEffect(() => {
    if (pagesInitiallyLoaded && columnInfo.dataType == 'linkedSheet') {
      pages.forEach((page) => {
        if (page.sheetId == columnInfo.linkedSheetId) {
          setSelectedPage(page)
        }
      })
    }
  }, [pagesInitiallyLoaded])

  const getFieldValue = (type) => {
    return (type == 'register' ? selectedRegister : selectedPage) ?
      <div className="flex items-center gap-3">
        <div className="w-10 child-in-center">
          <img src={type == 'register' ? selectedRegister.avatar : pageBlue} className={`${type == 'register' ? 'h-7' : 'h-5'}`} />
        </div>
        {type == 'register' ? selectedRegister.name : selectedPage.sheetName}
      </div>
      : type == 'register' ? 'Select Register' : 'Select Page'
  }

  return (
    <div className="m-6">

      <div className='text-lg font-semibold mb-3 flex items-center gap-3'>
        Select Register
        {pagesLoading && <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-600"></div>}
      </div>
      {
        (selectedRegister == '' || selectedPage == '') &&
        <div className='text-sm font-light mb-3'>You can select the register & page from below</div>
      }

      {/* Registers menu */}
      <CustomInputDropdown
        fieldValue={getFieldValue('register')}
        inputType={`div`}
        container={`screen`}
        inputClasses={`field ${selectedRegister ? 'font-semibold' : ''}`}
        inputStyles={{ color: selectedRegister ? '#000' : '', fontSize: '1rem' }}
        outerDivClasses={`mb-2`}
        buttonsInfo={{ showButtonsSection: false }}
        showRemoveIcon={false}
        showDropDownMenu={showRegisterMenu}
        setShowDropDownMenu={setShowRegisterMenu}
        onFieldClick={() => { }}
      >
        <ul style={{ maxHeight: '50vh' }} className='overflow-auto'>
          {
            registersForMenu && registersForMenu.map((reg, i) => {
              if (reg.type == 'FOLDER') {
                return (
                  <FolderElement folderEl={reg} index={i} setSelectedRegister={setSelectedRegister} setShowRegisterMenu={setShowRegisterMenu}/>
                )
              } else return (
                <li
                  key={`${reg.name} ${i}`}
                  onClick={() => { setSelectedRegister(reg); setShowRegisterMenu(false) }}
                  className='list-none px-6 cursor-pointer hover:bg-gray-100 transition-colors delay-50'

                  style={{ color: '#174184' }}>
                  <div className="border-b py-3 flex items-center gap-3 font-semibold">
                    <img src={reg.avatar} className='h-8' />
                    {reg.name}
                  </div>
                </li>
              )
            })
          }
        </ul>
      </CustomInputDropdown>
      {/* Registers menu */}

      {/* Pages menu */}
      <CustomInputDropdown
        fieldValue={getFieldValue('page')}
        inputType={`div`}
        container={`screen`}
        inputClasses={`field ${selectedPage ? 'font-semibold' : ''} ${pages.length == 0 ? 'cursor-not-allowed' : ''}`}
        inputStyles={{ fontSize: '1rem' }}
        buttonsInfo={{ showButtonsSection: false }}
        showRemoveIcon={false}
        showDropDownMenu={showPagesMenu}
        setShowDropDownMenu={setShowPagesMenu}
        onFieldClick={() => { if (pages.length == 0) return }}
        isDisabled={pages.length == 0}
      >
        <ul style={{ maxHeight: '40vh' }} className='overflow-auto'>
          {
            pages && pages.map((page, i) => {
              return (
                <li
                  key={`${page.sheetName} ${i}`}
                  onClick={() => { setSelectedPage(page); setShowPagesMenu(false) }}
                  className='list-none px-6 cursor-pointer hover:bg-gray-100 transition-colors delay-50'

                  style={{ color: '#174184' }}>
                  <div className="border-b py-3 flex items-center gap-3 font-semibold">
                    <img src={pageBlue} className='h-5' />
                    {page.sheetName}
                  </div>
                </li>
              )
            })
          }
        </ul>
      </CustomInputDropdown>
      {/* Pages menu */}
    </div>
  )
}

export default SelectRegisterToLink