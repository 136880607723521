
// components
import Comments from './Components/Comments'
import CommentBox from './Components/CommentBox'
import { useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

const CommentsSection = () => {
  // redux variables
  const rowDetailData = useSelector(state => state.rowDetail)

  // refs variables
  const commentBoxContainerRef = useRef(null)
  const commentsContainerRef = useRef(null)
  const textAreaRef = useRef(null)

  // state variables
  const [comment, setComment] = useState("")

  // local variables
  const { editCommentIdx, comments } = rowDetailData

  useDynamicHeightField(commentBoxContainerRef, comment, textAreaRef, commentsContainerRef)

  useEffect(() => {
    if (editCommentIdx > -1) {
      const commentText = comments[editCommentIdx].text
      setComment(commentText)
      textAreaRef.current.focus()
    }
  }, [editCommentIdx])

  return (
    <>
      <div ref={commentsContainerRef} style={{ height: "70%" }}>
        <Comments />
      </div>
      <div ref={commentBoxContainerRef} className="flex justify-center items-center" style={{ height: "30%" }}>
        <CommentBox comment={comment} setComment={setComment} textAreaRef={textAreaRef} />
      </div>
    </>
  )
}

export default CommentsSection

const useDynamicHeightField = (commentBoxContainer, value, textArea, commentsContainer) => {

  useEffect(() => {
    if (!textArea.current) return
    if (!commentBoxContainer) return

    var numberOfLines = Math.floor(textArea.current.scrollHeight / 16);
    // console.log(" textArea ", window.getComputedStyle(textArea.current, null).getPropertyValue("line-height"))
    // console.log(" commentBoxContainer height ", commentBoxContainer.current.getBoundingClientRect().height)

    const shouldExpand = textArea.current.getBoundingClientRect().height < textArea.current.scrollHeight
    if (numberOfLines === 5 || value.length === 0) {
      commentsContainer.current.style.height = '68%'
      commentBoxContainer.current.style.height = '32%'
    } else if (numberOfLines === 6 && shouldExpand) {
      commentsContainer.current.style.height = '66%'
      commentBoxContainer.current.style.height = '34%'
    } else if (numberOfLines === 7 && shouldExpand) {
      commentsContainer.current.style.height = '64%'
      commentBoxContainer.current.style.height = '36%'
    } else if (numberOfLines === 8 && shouldExpand) {
      commentsContainer.current.style.height = '61%'
      commentBoxContainer.current.style.height = '39%'
    } else if (numberOfLines === 9 && shouldExpand) {
      commentsContainer.current.style.height = '57%'
      commentBoxContainer.current.style.height = '43%'
    } else if (numberOfLines === 10 && shouldExpand) {
      commentsContainer.current.style.height = '55%'
      commentBoxContainer.current.style.height = '45%'
    }
  }, [commentBoxContainer, value]);
};