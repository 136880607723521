import { useSelector } from 'react-redux'
import LastRowCellEditor from '../DataCell/LastRowCellEditor'
import FormulaEditor from '../DataCell/FormulaEditor'

const FormulaEditorLayer = (props) => {
  // redux variables 
  const registerData = useSelector(state => state.register)

  // local variables
  const { filteredRows } = registerData
  const { rowIdx } = props.rowProperties
  const isLastRow = filteredRows.length - 1 === rowIdx

  if (isLastRow) return <LastRowCellEditor {...props} />
  else return <FormulaEditor {...props} />
}

export default FormulaEditorLayer