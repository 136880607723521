const CancelButton = ({text, saveCurrentRowData}) =>{
    return(
        <button 
        className="primary_btn w-1/2 h-10 transition-all delay-50" 
        onClick={saveCurrentRowData}
      >
        {text}
      </button>
    )
}

export default CancelButton