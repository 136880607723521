import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {
  Redirect,
  Route,
  Switch,
  useLocation
} from 'react-router-dom'
import { createPortal } from 'react-dom'

// pages
import Dashboard from '../../pages/Dashboard';
import Register from '../../pages/Register';
import Home from '../../pages/Home';

// components
import LeftSidebar from './LeftSidebar'
import RightSidebar from './RightSidebar'
import Header from './header'
import Banner from '../../pages/Home/Components/banner'
import AddRegisterPopUp from '../../features/Register/PopUps/AddRegister'
import CreateNewRegisterPopUp from '../../features/Register/PopUps/CreateNewRegister'
import RegisterTemplatePreviewPopUp from '../../features/Register/PopUps/RegisterTemplatePreview'
import Dialog from '../../partials/Dialog'
import FullScreenLoader from '../../partials/FullScreenLoader'
import Toast from '../../partials/Toast'
import DummyRow from '../../partials/DummyRow';

// css 
import { getTheme } from './themeVariables'

const Layout = ({ children }) => {
  // redux variables
  const dispatch = useDispatch()
  const dialogData = useSelector(state => state.dialog)
  const leftSidebarData = useSelector(state => state.leftSidebar)
  const registerData = useSelector(state => state.register)
  const toastData = useSelector(state => state.toast)
  const dummyRowData = useSelector(state => state.dummyRow)

  // router variables
  const location = useLocation()
  const { search } = location

  // local variables
  const { dialogOpen } = dialogData
  const { showFullScreenLoader } = leftSidebarData
  const { sheetId, showFullScreenRegisterLoader, sortedColKey } = registerData
  const { showToast } = toastData
  const { showDummyRow } = dummyRowData
  const colSorted = sortedColKey != ''

  const getRoutePopUps = (routerProps) => {
    const query = new URLSearchParams(search)
    const templateId = query.get('templateId')
    if (query.get('popUp') === 'addRegister' || query.get('registerCategoryType')) {
      return <AddRegisterPopUp />
    } else if (query.get('popUp') === 'createRegister') {
      return <CreateNewRegisterPopUp isOpen={true} />
    } else if (templateId) {
      return <RegisterTemplatePreviewPopUp />
    }
  }

  const getcommunityRegisterChildren = (routerProps) => {
    return routerProps.match && (<><Register />{getRoutePopUps(routerProps)}</>)
  }

  const getHomeChildren = (routerProps) => {
    return routerProps.match && (<><Home />{getRoutePopUps(routerProps)}</>)
  }

  return (
    <React.Fragment>
      <div className="flex h-screen overflow-hidden" style={{ backgroundColor: getTheme().appBackGroundColour }}>

        {/* LeftSidebar */}
        <LeftSidebar />
        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <div className="relative" style={{ height: sheetId !== "" && sheetId.length > 0 ? '18%' : '5%', zIndex: 1 }}>
            <Header />
          </div>
          <main className="w-full max-w-9xl mx-auto" style={{ height: sheetId !== "" && sheetId.length > 0 ? '82%' : '95%' }}>
            <Switch>
              <Route
                exact
                path={['/', '/home', '/community/:communityId/']}
                children={(props) => getHomeChildren(props)}
              />
              <Route exact path="/dashboard"><Dashboard /></Route>
              <Route
                exact
                path={['/community/:communityId/register/:registerId']}
                children={(props) => getcommunityRegisterChildren(props)}
              />
              <Route path="*" exact={true} render={() => <Redirect to='/notFound' />} />
            </Switch>
          </main>
          <Banner />
        </div>
        {/* RightSidebar */}
        <RightSidebar />
        {dialogOpen && createPortal(<Dialog />, document.body)}
        {(showFullScreenLoader || showFullScreenRegisterLoader) && <FullScreenLoader />}
        {showToast && <Toast />}
        {showDummyRow && colSorted && <DummyRow />}
      </div>
    </React.Fragment>
  );
}

export default Layout;
