import { useEffect, useState } from "react"

//images and icons
import crossIcon from '../../../../../images/dialog/status/cross.png'

const EditStatusDialog = ({
  isEditStatusDialogOpen,
  closeEditStatusDialog,
  statusInEditMode,
  editStatuses,
  addStatus,
  statusNamesArr
}) => {

  //state variables
  const [statusNameInput, setStatusNameInput] = useState('')
  const [editedStatusColor, setEditedStatusColor] = useState('')
  const [buttonText, setButtonText] = useState('')
  const [editedStatus, setEditedStatus] = useState(null)
  const [inputErr, setInputErr] = useState(false)
  const [errMsg, setErrMsg] = useState('')

  //local variables
  const name = statusInEditMode.value
  const color = statusInEditMode.color
  const colorsArray = ['#ABABAB', '#6764F6', '#E5D371', '#FF8585', '#D98BFE', '#4FC327', '#000000', '#750808', '#DD4949']

  //set state variables when edit-status-dialog open
  useEffect(() => {
    if (isEditStatusDialogOpen) {
      setStatusNameInput(name ? name : '')
      setEditedStatusColor(color ? color : '#DD4949')
      setEditedStatus({ ...statusInEditMode })
      setInputErr(false)
      setErrMsg('')
      document.getElementById('input_status').focus()
      if (!color && !name) { setButtonText('Add Status') }
      else { setButtonText('Done') }
    }
  }, [isEditStatusDialogOpen])

  useEffect(() => {
    setEditedStatus({ ...editedStatus, value: statusNameInput })
    if (statusNameInput) setInputErr(false)
  }, [statusNameInput])

  const onInputchange = (e) => {
    setStatusNameInput(e.target.value)
  }

  const onColorClick = (color) => {
    setEditedStatusColor(color)
    setEditedStatus({ ...editedStatus, color })
    document.getElementById('input_status').focus()
  }

  const onSubmit = () => {
    //handles empty status name
    if (!statusNameInput) {
      setInputErr(true)
      return
    }
    //handles case when same status name already exists
    let statusExists = false
    statusNamesArr.forEach((option, i) => {
      if (option.toLowerCase() == statusNameInput.toLowerCase()) statusExists = true
    })
    if (statusExists && statusNameInput != statusInEditMode.value) {
      setErrMsg(`Status '${statusNameInput}' already exists`)
      return
    }

    //no errors
    setErrMsg('')
    closeEditStatusDialog()
    if (buttonText == 'Add Status') {
      addStatus({ ...editedStatus, color: editedStatusColor })
    } else {
      editStatuses({ ...editedStatus })
    }
  }

  //handles enter key press
  const handleContainerDivKeyDown = (e) => {
    if (isEditStatusDialogOpen && e.keyCode == 13) {
      onSubmit()
    }
  }

  const onCrossIconClick = () => {
    setStatusNameInput('')
    document.getElementById('input_status').focus()
  }

  const styles = {
    button: { background: '#4DB14F' }
  }

  const classes = {
    container: `bg-white rounded-md p-4 transform transition-all delay-200 ease-in-out ${isEditStatusDialogOpen ? 'opacity-100 scale-100' : 'opacity-0 pointer-events-none scale-50'}`,
    inputContainer: `relative flex justify-start items-center`,
    inputColorSquare: `absolute left-1 h-6 w-6`,
    statusNameInput: `w-full h-10 rounded border-2 pl-12 my-3 placeholder:text-gray-200 focus:outline-none ${inputErr ? 'border-red-400' : 'border-gray-300'}`,
    colorsContainer: `flex gap-2 my-3`,
    colorSquare: `h-8 w-8 rounded border cursor-pointer `,
    colorSquareTransition: `transform scale-90 hover:scale-100 transition-transform delay-50 ease-out`,
    buttonContainer: `flex justify-center w-full`,
    button: `text-white my-3 rounded-lg py-1 px-8`,
    errMsg: `text-xs absolute -bottom-2 pl-12 left-1 text-red-300`,
    crossIcon: `absolute right-2 cursor-pointer h-5 ${statusNameInput? 'opacity-100' : 'opacity-0'}`
  }

  return (
    <div className={classes.container} onKeyDown={handleContainerDivKeyDown}>

      {/* input */}
      <div className={classes.inputContainer}>
        <div className={`${classes.colorSquare} ${classes.inputColorSquare}`} style={{ background: editedStatusColor }}></div>
        <input id="input_status" value={statusNameInput} onChange={onInputchange} className={classes.statusNameInput} placeholder="Add Status Name" autoFocus />
        <img src={crossIcon} className={classes.crossIcon} onClick={onCrossIconClick} />
        {errMsg && <div className={classes.errMsg}>{errMsg}</div>}
      </div>

      {/* colors */}
      <div className={classes.colorsContainer}>
        {
          colorsArray.map((color, i) => {
            return (
              <div key={`color_${color}_${i}`} onClick={() => onColorClick(color)} className={`${classes.colorSquare} ${classes.colorSquareTransition}`} style={{ background: color }}></div>
            )
          })
        }
      </div>

      {/* button */}
      <div className={classes.buttonContainer}>
        <button onClick={onSubmit} className={classes.button} style={styles.button}>{buttonText}</button>
      </div>
      
    </div>
  )
}

export default EditStatusDialog