import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

// icons
import deleteIcon from '../../../../../../../images/add-edit row dialog/form icons/delete.png'
import goToLinkRegister from '../../../../../../../images/goToLinkRegister.png'
import edit from '../../../../../../../images/add-edit row dialog/form icons/edit.png'

const EditMenu = ({deleteEntry, closeDropdown, colkey, setShowLinkedColPopup, linkedRegisterRows, column, value, linkedRowId}) => {

  //redux variables
  const registerData = useSelector(state => state.register)
  const leftSidebarData = useSelector(state => state.leftSidebar)

  // router variables
  const history = useHistory()

  // local variables
  const { activeBusinessId } = leftSidebarData
  const {linkedColumnId, linkedRegisterId} = column

  const goToLinkedRecord = (e) => {
    let rowid
    linkedRegisterRows.forEach((row) => {
      if (row[linkedColumnId] == value) {
        rowid = row['rowId']
      }
    })
    history.push(`/community/${activeBusinessId}/register/${linkedRegisterId}?rowId=${rowid}`)
    if (linkedRowId) {
      history.push(`/community/${activeBusinessId}/register/${linkedRegisterId}?rowId=${linkedRowId}`)
    } else if (rowid) {
      history.push(`/community/${activeBusinessId}/register/${linkedRegisterId}?rowId=${rowid}`)
    }
  }

  const onDeleteButtonClick = (e) => {
    e.preventDefault()
    deleteEntry(colkey)
    closeDropdown()
  }

  // classes
  const list_item_container = `px-2 hover:bg-gray-200 transition-colors delay-50 focus:outline-none`
  const list_item = `flex items-center py-2 pl-2 border-b gap-3`

  return (
    <div
      id="linked-sheet-drop-down-container-form"
      tabIndex="-1"
      className="slide_down_2 flex flex-col bg-white shadow-lg text-sm rounded overflow-hidden"
    >

      {/* edit */}
      <button className={list_item_container} onClick={(e) => { setShowLinkedColPopup(true); closeDropdown()}} >
        <div className={list_item}>
          <img className="h-3.5" src={edit} />
          Edit
        </div>
      </button>
      {/* edit */}

      {/* go to linked record */}
      <button className={list_item_container} onClick={goToLinkedRecord} >
        <div className={list_item}>
          <img className="h-3.5" src={goToLinkRegister} />
          Go To Linked Record
        </div>
      </button>
      {/* go to linked record */}

      {/* delete */}
      <button className={list_item_container} onClick={onDeleteButtonClick} >
        <div className={list_item}>
          <img className="h-3.5" src={deleteIcon} />
          <div style={{ color: '#FC1055' }}>
            Delete
          </div>
        </div>
      </button>
      {/* delete */}

    </div>
  )
}

export default EditMenu