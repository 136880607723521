import DatePicker from "react-datepicker"

// icons
import dateIcon from '../../../../../../../../images/datatype_icons/light/date.png'
import dropDownBlueIcon from '../../../../../../../../images/header popup icons/drop down.png'
import crossIcon from '../../../../../../../../images/add-edit row dialog/cross-gray.png'

const DateSection = ({ startDate, onDateChange }) => {
  return (
    <div className='relative flex items-center justify-start my-3 px-1 md:px-2 lg:px-5'>
      <img src={dateIcon} className='h-5 mr-1 md:mr-2 lg:mr-4' />
      <span style={{ color: '#545454' }} className='mr-1 md:mr-2 lg:mr-4 pr-0.5'>Date</span>
      <div style={{ width: '12rem', maxWidth: '100%' }} className='relative flex items-center'>
        <DatePicker
          className="field cursor-pointer focus:ring-transparent"
          selected={startDate}
          onChange={(date) => { onDateChange(date) }}
          dateFormat="dd-MM-yyyy"
          minDate={new Date()}
        />
        {startDate ?
          <img
            onClick={() => { onDateChange() }}
            src={crossIcon}
            className='cross_icon z-10'
          /> :
          <img className='drop-down-icon transform transition-transform delay-50' src={dropDownBlueIcon} />
        }
      </div>
    </div>
  )
}

export default DateSection