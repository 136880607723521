
import { useState, useRef } from 'react'
import { useSelector } from 'react-redux'

// components 
import FormulaEditMenu from './Components/FormulaEditMenu'

//icons
import formulaIcon from '../../../../../../../images/datatype_icons/light/formula.png'

//utils
import { formulaToCalculatorFormat, computeFormulaColVal } from '../../../../../../../utils/Utils'

const Formula = ({ rowIndex, colIdx, column, handleChange, formData }) => {

  // redux variables
  const registerData = useSelector(state => state.register)

  // ref variables
  const formulaEditMenuToggler = useRef()

  // state variables
  const [showEditMenu, setShowEditMenu] = useState(false)

  // local variables
  const colkey = column.key
  const { columns, filteredRows, isAdmin, hasBasicEditPermission, editPermissions } = registerData
  const preSetFormulaVal = formulaToCalculatorFormat(column.formula, columns)
  const prevsRow = filteredRows[rowIndex - 1] ? filteredRows[rowIndex - 1] : {}
  const value = computeFormulaColVal(colkey, column.formula, formData, prevsRow, columns)[colkey]
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colkey) === -1

  const handleContainerDivKeyDown = (e) => {
    // enter key press
    if (e.keyCode == 13) {
      // Trigger click of the active element on which the focus is.
      document.activeElement.click();
    }
  }

  return (
    <div className="relative mb-6" onKeyDown={handleContainerDivKeyDown}>
      <div className="colName">
        <img src={formulaIcon} className="field_icon" />
        <label htmlFor={column.name}>{column.name}</label>
      </div>
      <div ref={formulaEditMenuToggler} onClick={() => setShowEditMenu(!showEditMenu)}>
        <div className='field non_editable cursor-pointer'>{value}</div>
      </div>
      {
        showEditMenu &&
        <FormulaEditMenu
          formulaEditMenuToggler={formulaEditMenuToggler}
          colIdx={colIdx}
          allowedToEdit={canEdit}
          preSetFormulaVal={preSetFormulaVal}
          onCancelClick={() => setShowEditMenu(false)}
        />
      }
    </div>
  )
}

export default Formula