import { useEffect, useState, useRef } from "react";
import ReactDOM, { createPortal } from 'react-dom';
import { useSelector } from "react-redux";

// utils
import { getSumCntAvgVal } from '../../../../../utils/Utils';
import { getValidatedFormatterVal } from '../../Utils'

const LastRowCellEditor = ({ rowProperties, saveCurrentRowData, colIdx, columnEl }) => {

  // redux variables
  const registerData = useSelector(state => state.register)

  // local variables
  const colKey = columnEl.key
  const rowIdx = rowProperties.rowIdx
  const rowId = rowProperties.row.rowId
  const value = getValidatedFormatterVal(rowProperties.row[colKey])
  const onClose = rowProperties.onClose
  const countDataTypes = ['number', 'phoneNumber', 'String', 'image', 'createdAt', 'date', 'rupee', 'formula', 'location', 'dropDown', 'switch', 'time', 'mail', 'attachment', 'url', 'signature', 'status', 'reminder', 'scanner', 'unit', 'createdBy', 'duration']
  const averageDataTypes = ['number', 'phoneNumber', 'String', 'formula', 'rupee', 'unit']
  const sumDataTypes = ['number', 'phoneNumber', 'String', 'formula', 'rupee', 'unit']
  let calculateOprnsArr = [
    { type: 'Count', dataTypesAllowed: countDataTypes },
    { type: 'Average', dataTypesAllowed: averageDataTypes },
    { type: 'Sum', dataTypesAllowed: sumDataTypes },
  ]

  // state variables
  const [selectedOptn, setSelectedOptn] = useState('');
  const [pos, setPos] = useState({})

  // ref variables 
  const dropdown = useRef(null)
  const cellRef = useRef(null)

  useEffect(() => {
    document.querySelector(".rdg-light").addEventListener("scroll", onScroll)
    document.addEventListener('keydown', keyHandler);
    return () => {
      document.removeEventListener("scroll", onScroll)
      document.removeEventListener('keydown', keyHandler);
    }
  }, [])

  // set dropdown option according to the current value in the row
  useEffect(() => {
    // preset sum or 'hr mins'
    if (value.includes('Sum') || value.includes('hr') || value.includes('mins')) {
      setSelectedOptn('Sum')

      // preset count or 'Yes/No'
    } else if (value.includes('Count') || value.includes('Yes')) {
      setSelectedOptn('Count')

      // preset Average
    } else if (value.includes('Average')) {
      setSelectedOptn('Average')

      // preset "Select Any"
    } else {
      setSelectedOptn('Select Any')
    }
  }, [value])

  // trigger onScroll after option is selected at ( initial render ) and the element in
  // ref goes from "null" to some HTML
  useEffect(() => {
    onScroll()
  }, [setSelectedOptn])


  const keyHandler = (e) => {
    // tab key press
    if (e.keyCode == 9) {
      const optnsContainerDiv = document.getElementById(`calculatorCell/${colKey}/${rowId}/calculate-div`)
      const childElementCount = optnsContainerDiv.childElementCount
      // Condition 1: the calculatorCell has the focus
      // Condition 2: if the last child has the focus
      // In both the above cases the focus is again brought back to the container div having the child elements.
      if (
        (document.activeElement.classList.contains('rdg-focus-sink') || document.activeElement.classList.contains('sheetItem1')) ||
        (document.activeElement.id === optnsContainerDiv.children[childElementCount - 1].id)
      ) {
        optnsContainerDiv.focus();
      }
    }

    // escape key close
    if (e.keyCode !== 27) return;
    onClose(true)
  };

  const onScroll = () => {
    if (dropdown.current) {
      let totalDropdownHeight = (dropdown.current.lastElementChild.getBoundingClientRect().height * dropdown.current.childElementCount)
      let positionTop = 0
      if (cellRef.current.getBoundingClientRect().bottom + totalDropdownHeight >= window.innerHeight) {
        positionTop = positionTop - totalDropdownHeight
      } else {
        positionTop = 40
      }
      const refValue = ReactDOM.findDOMNode(cellRef.current)
      if (refValue) {
        setPos({
          right: refValue
            .getBoundingClientRect().right,
          left: refValue
            .getBoundingClientRect().left,
          top: refValue
            .getBoundingClientRect().top + positionTop,
          bottom: refValue
            .getBoundingClientRect().bottom,
        })
      }
    }
  }


  const onOptionClick = (value) => {
    const selectedVal = value
    setSelectedOptn(selectedVal)
    const visibleRows = registerData.filteredRows
    let newDataCellVal = getSumCntAvgVal(selectedVal, columnEl, visibleRows)
    saveCurrentRowData(newDataCellVal, colKey, rowId, registerData.rows, true, true)
    onClose(true)
  }

  return (
    <div ref={cellRef} id={`calculatorCell/${colKey}${rowId}`} className="w-full h-full sheetItem2">
      {
        createPortal(
          <div ref={dropdown} style={{ width: cellRef.current && cellRef.current.clientWidth, position: 'fixed', backgroundColor: 'white', top: pos.top, bottom: pos.bottom, right: pos.right, left: pos.left }}>
            <div tabIndex="-1" id={`calculatorCell/${colKey}/${rowId}/calculate-div`} className="truncate border-b border-gray-200" style={{ fontSize: '12px', fontWeight: '400' }}>
              {
                calculateOprnsArr.map((el) => {
                  if (el.dataTypesAllowed.includes(columnEl.dataType)) {
                    return (
                      <a
                        id={`calculatorCell/${colKey}/${rowId}/${el.type}/a`}
                        key={`calculatorCell/${colKey}/${rowId}/${el.type}/a`}
                        tabIndex="0"
                        className="flex items-center gap-4 justify-center leading-8 p-1 transition-colors delay-50 cursor-pointer hover:bg-gray-200 border-2 text-black bg-white"
                        onKeyDown={(e) => {
                          if (e.code === 'Enter')
                            onOptionClick(el.type)
                        }}
                        onClick={() => onOptionClick(el.type)}
                      >
                        {el.type}
                      </a>
                    )
                  }
                })
              }
            </div>
          </div>,
          document.body)
      }
    </div>
  )
};

export default LastRowCellEditor