import { useState, useEffect, useRef, useCallback } from 'react'

//components
import EditStatusDialog from './EditStatusDialog'

//images and icons
import plusIcon from '../../../../../images/dialog/status/plus.png'
import StatusListItem from './StatusList_Item'

const StatusList = ({ statuses, editStatuses, addStatus, deleteStatus }) => {

  //ref variables
  const editStatusDialogContainerRef = useRef(null)

  //state variables
  const [statusInEditMode, setStatusInEditMode] = useState({ value: '', color: '', status: '' })
  const [isEditStatusDialogOpen, setEditStatusDialogOpen] = useState(false)

  //local variables
  const noOfStatuses = statuses.length
  const optionsExceptLast = statuses.slice(0, noOfStatuses - 1)
  const lastOption = statuses[noOfStatuses - 1]
  const statusNamesArr = statuses.map((status) => {
    return status.value
  })
  const statusNumber = statuses.length

  //close edit status dialog onClick outside
  useEffect(() => {
    if (isEditStatusDialogOpen) {
      document.addEventListener('click', clickHandler);
      return () => {
        document.removeEventListener('click', clickHandler)
      }
    }
  }, [isEditStatusDialogOpen])

  //JSX for add status button in Status List
  const addStatusListItem = () => {
    return (
      <div onClick={openEditStatusDialog} className={`${classes.listItem} ${classes.addStatus}`} style={{ background: '#576D9E' }}>
        <img src={plusIcon} />
        Add New Status
      </div>
    )
  }

  const openEditStatusDialog = () => {
    setEditStatusDialogOpen(true)
  }

  const closeEditStatusDialog = () => {
    setStatusInEditMode({ value: '', color: '', status: '' })
    setEditStatusDialogOpen(false)
  }

  //close edit status dialog on outside click
  const clickHandler = useCallback(({ target }) => {
    if (!editStatusDialogContainerRef.current || editStatusDialogContainerRef.current.contains(target)) return;
    closeEditStatusDialog()
  }, [isEditStatusDialogOpen])

  //common props to be passed to <ListItem/>
  const listItemProps = {
    isEditStatusDialogOpen,
    openEditStatusDialog,
    setStatusInEditMode,
    noOfStatuses,
    deleteStatus
  }

  const classes = {
    container: `flex justify-center items-center relative`,
    addStatus: `flex gap-2 cursor-pointer`,
    listItem: `p-3 mb-4 rounded text-white `,
    editStatusDialogContainer: `absolute ${isEditStatusDialogOpen ? '' : 'pointer-events-none '}`,
    statusNumber: `mb-2 text-xl`
  }

  const styles = {
    container: { height: '80%' },
    statusNumber: { color: '#576D9E'}
  }

  return (
    <div className={classes.container} style={styles.container}>
      <div className="h-5/6 overflow-auto w-full">
        <div>

          <div className={classes.statusNumber} style={styles.statusNumber}>{statusNumber} Status</div>
          {/* List of statuses starts here */}

          {/* All status options except last */}
          {
            optionsExceptLast && optionsExceptLast.map((option, i) => {
              return (
                <div key={`status/${option.value}/i`}>
                  <StatusListItem
                    option={option}
                    index={i}
                    {...listItemProps}
                  />
                </div>
              )
            })
          }

          {/* Add Status button  */}
          {addStatusListItem()}

          {/* Last List Item  */}
          <StatusListItem
            option={lastOption}
            index={noOfStatuses - 1}
            {...listItemProps}
          />

          {/* List of statuses ends here */}

        </div>
      </div>

      {/* Edit Status Dialog */}
      <div ref={editStatusDialogContainerRef} className={classes.editStatusDialogContainer}>
        <EditStatusDialog
          statusInEditMode={statusInEditMode}
          isEditStatusDialogOpen={isEditStatusDialogOpen}
          closeEditStatusDialog={closeEditStatusDialog}
          editStatuses={editStatuses}
          addStatus={addStatus}
          statusNamesArr={statusNamesArr}
        />
      </div>

    </div>
  )
}

export default StatusList