import React, { useEffect, useRef, useState } from 'react';
import ReactDOM, { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux'

import { fetchRegisterDataAncestry } from '../../../../../../slices/RegisterSlice';

// components
import EditMenu from './Components/EditMenu'
import LinkedColumnPopUp from '../../../../PopUps/LinkedSheet/LinkedColumn'

// css
import '../rowEditors.css';

// utils
import ErrorBoundary from '../../../../../../utils/ErrorBoundary'

const LinkedSheet = ({ rowProperties, saveCurrentRowData, colIdx, columnEl }) => {

  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  // state variables
  const [pos, setPos] = useState({})
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [showLinkedColPopup, setShowLinkedColPopup] = useState(!(rowProperties.row[columnEl.key] || (!rowProperties.row[columnEl.key] && rowProperties.row[columnEl.key+'_details'] && rowProperties.row[columnEl.key+'_details'].length > 0)))
  const [linkedColRegContent, setLinkedColRegContent] = useState({})
  const [loading, setLoading] = useState(true)

  // ref variables
  const dropdown = useRef(null)
  const dataCellWidthTracker = useRef(null)

  // local variables
  const { linkedRegisterId, linkedSheetId, linkedColumnId, linkedDataType, key: colKey } = columnEl
  const onClose = rowProperties.onClose
  const rowIndex = rowProperties.rowIdx
  const rowId = rowProperties.row.rowId
  const value = rowProperties.row[colKey] ? rowProperties.row[colKey] : ''
  const imgs = rowProperties.row[colKey + "_details"] && rowProperties.row[colKey+'_details'].filter((el)=>{
    return !Object.keys(el).includes('linkedRowId') && el['value'] != ''
  })
  const linkedRowIdArray = rowProperties.row[colKey+'_details'] && rowProperties.row[colKey+'_details'].filter((el)=>{
    return Object.keys(el).includes('linkedRowId')
  })
  const linkedRowId = linkedRowIdArray && linkedRowIdArray[0] && linkedRowIdArray[0].linkedRowId

  useEffect(() => {
    // as soon as the editor is rendered dropdown should open
    setDropdownOpen(true);

    const fetchLinkedRegisterData = async () => {
      try {
        const { payload: result } = await dispatch(fetchRegisterDataAncestry({ registerId: linkedRegisterId, sheetId: linkedSheetId }))
        setLinkedColRegContent(result)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
    fetchLinkedRegisterData()

    // listeneing to table scroll represented by .rdg-light class
    document.querySelector(".rdg-light").addEventListener("scroll", onScroll)
    document.addEventListener('keydown', keyHandler);
    return () => {
      document.removeEventListener("scroll", onScroll)
      document.removeEventListener('keydown', keyHandler);
    }
  }, []);

  useEffect(() => {
    if (dropdownOpen) onScroll()
  }, [dropdownOpen])

  const keyHandler = (e) => {
    // tab key press
    if (e.keyCode == 9) {
      if (document.activeElement.classList.contains('rdg-focus-sink') || document.activeElement.classList.contains('sheetItem1')) {
        // document.getElementById(`select-one-dropdown-portal-container`).focus();
      }
    }
    if (!dropdownOpen || e.keyCode !== 27) return;
    setDropdownOpen(false);
  }

  const onScroll = () => {
    if (dropdown.current) {
      let totalDropdownHeight = dropdown.current.getBoundingClientRect().height
      let positionTop = 0
      if (dataCellWidthTracker.current.getBoundingClientRect().bottom + totalDropdownHeight >= window.innerHeight) {
        positionTop = positionTop - totalDropdownHeight
      } else {
        positionTop = 40
      }
      const refValue = ReactDOM.findDOMNode(dataCellWidthTracker.current)
      if (refValue) {
        setPos({
          right: refValue
            .getBoundingClientRect().right,
          left: refValue
            .getBoundingClientRect().left,
          top: refValue
            .getBoundingClientRect().top + positionTop,
          bottom: refValue
            .getBoundingClientRect().bottom,
        })
      }
    }
  }

  // handles key events of the editor container div
  const handleContainerDivKeyDown = (e) => {
    // enter key press
    if (e.keyCode == 13) {
      // Trigger click of the active element on which the focus is.
      document.activeElement.click();
      onClose(true)
    }
  }

  const handleClick = (e) => {
    e.preventDefault()
    // If a person clicks pre selected option, 
    // then we should close the dropdown (setDropdownOpen) and thus the editor ( onClose )
    setDropdownOpen(!dropdownOpen)
    if (dropdownOpen === true) onClose(true)
  }

  const extraImagesNumber = () => {
    const noOfImgs = imgs && imgs.length
    if (noOfImgs > 2) {
      return (
        <div>+ {noOfImgs - 2}</div>
      )
    }
  }

  const saveRowData = (row) => {
    let linkedRowIdForApi = row['rowId']
    let additionalKeys = { [`${colKey}_details`]: [...row[linkedColumnId + '_details'],{'linkedRowId': linkedRowIdForApi}] }
    // columns linked to same register
    const additionalColsToUpdate = registerData.columns.filter((col) => {
      return col.linkedSheetId == linkedSheetId && linkedColumnId != col.linkedColumnId
    })
    // update additional keys to include values of additional cols
    additionalColsToUpdate.forEach((col) => {
      additionalKeys = { ...additionalKeys, [`${col.key}`]: row[col.linkedColumnId], [`${col.key}_details`]: [...row[col.linkedColumnId + '_details'], {'linkedRowId': linkedRowIdForApi}] }
    })
    saveCurrentRowData(row[linkedColumnId], colKey, rowId, registerData.rows, false, false, additionalKeys, null, linkedRowIdForApi)
  }

  return (
    <ErrorBoundary>
      <div className="w-full h-full" tabIndex="-1" id="linked-sheet-editor-container-div" onKeyDown={handleContainerDivKeyDown}>
        <div ref={dataCellWidthTracker} tabIndex="0" onClick={handleClick} className="sheetItem1 focus:bg-gray-600" style={{ minWidth: '3rem', minHeight: '1.5rem' }} >
          {linkedDataType == 'image' ?
            <div>
              {
                imgs && imgs.length > 0 &&
                <div className="flex items-center justify-center gap-1 h-full pl-3 py-1" >
                  {
                    imgs.map((img, i) => {
                      if (i < 2 && img.value) {
                        return (
                          <div key={`data-cell-image-editor-${i}`} className="flex justify-center" style={{ height: '100%', width: '2rem' }}>
                            <img src={img.value} style={{ height: '100%', width: '100%' }} className="rounded border border-gray-600 shadow-md" />
                          </div>
                        )
                      }
                    })
                  }
                  {extraImagesNumber()}
                </div>
              }
            </div> :
            value
          }
        </div>
        {
          showLinkedColPopup ?

            <LinkedColumnPopUp
              columnEl={columnEl}
              onClose={onClose}
              saveRowData={saveRowData}
              value={value}
              linkedColRegContent={linkedColRegContent}
              loading={loading}
            />

            :
            <EditMenu
              dropdown={dropdown}
              dropdownOpen={dropdownOpen}
              dataCellWidthTracker={dataCellWidthTracker}
              pos={pos}
              setShowLinkedColPopup={setShowLinkedColPopup}
              rowId={rowId}
              colKey={colKey}
              saveCurrentRowData={saveCurrentRowData}
              linkedRegisterId={linkedRegisterId}
              linkedRegisterRows={linkedColRegContent.rows}
              linkedColumnId={linkedColumnId}
              value={value}
              linkedRowId={linkedRowId}
            />
        }
      </div>
    </ErrorBoundary>
  )
}

export default LinkedSheet