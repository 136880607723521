import React, { useEffect, useRef, useState } from 'react';
import ReactDOM, { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux'

import { updateBody, updateHeaderText, toggleDialogOpen } from '../../../../../../slices/DialogSlice'

// components
import SelectOneDialog1 from '../SelectOne/SelectOneDialog1';

// images
import searchIcon from '../../../../../../images/search.png'

// css
import '../rowEditors.css';

// utils
import ErrorBoundary from '../../../../../../utils/ErrorBoundary'

const Label = ({ rowProperties, saveCurrentRowData, colIdx, columnEl }) => {

  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  // state variables
  const [pos, setPos] = useState({})
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [colOpns_WithCheckedProp, setColOpns_WithCheckedProp] = useState([])

  // ref variables
  const dropdown = useRef(null)
  const dropdownTriggerBtnRef = useRef(null)
  const detailValue_ref_tracker = useRef([])
  const did_user_interact = useRef(false) // needed because of the old value edge case

  // local variables
  const colKey = columnEl.key
  const onClose = rowProperties.onClose
  const rowIndex = rowProperties.rowIdx
  const rowId = rowProperties.row.rowId
  const colMultiOptions = columnEl.multiOptions
  const selected_options = rowProperties.row[colKey + '_details'] ? rowProperties.row[colKey + '_details'] :[] 
  const colors = ["#FFFFFF", "#B6E5FF", "#FFE7B6", "#DBB6FF", "#FFBAB6", "#FFB6FC", "#B6FFE9", "#CFF9C4"]

  useEffect(() => {
    // as soon as the editor is rendered dropdown should open
    setDropdownOpen(true);
    prepareSelectedList()
    // listeneing to table scroll represented by .rdg-light class
    document.querySelector(".rdg-light").addEventListener("scroll", onScroll)
    document.addEventListener('keydown', keyHandler);
    return () => {
      document.removeEventListener("scroll", onScroll)
      document.removeEventListener('keydown', keyHandler);
      saveOn_dropdownClose()
    }
  }, []);

  useEffect(() => {
    // as soon as the editor is rendered dropdown should open
    setDropdownOpen(true);
    // Sets position of the dropdown in portal w.r.t the drop down trigger button
    // using its ref.
    //onScroll()
    // listeneing to table scroll represented by .rdg-light class
    document.querySelector(".rdg-light").addEventListener("scroll", onScroll)
    document.addEventListener('keydown', keyHandler);
    return () => {
      document.removeEventListener("scroll", onScroll)
      document.removeEventListener('keydown', keyHandler);
    }
  }, []);

  useEffect(() => {
    if (dropdownOpen) onScroll()
  }, [dropdownOpen])

  const keyHandler = (e) => {
    // tab key press
    if (e.keyCode == 9) {
      if (document.activeElement.classList.contains('rdg-focus-sink') || document.activeElement.classList.contains('sheetItem1')) {
        document.getElementById(`select-one-dropdown-portal-container`).focus();
      }
      if (document.activeElement.id === 'select-one-remove-entry-anchor') {
        document.getElementById('select-one-editor-container-div').focus();
      }
    }
    if (!dropdownOpen || e.keyCode !== 27) return;
    setDropdownOpen(false);
  }

  const onScroll = () => {
    if (dropdown.current) {
      let totalDropdownHeight = dropdown.current.getBoundingClientRect().height
      let positionTop = 0
      if (dropdownTriggerBtnRef.current.getBoundingClientRect().bottom + totalDropdownHeight >= window.innerHeight) {
        positionTop = positionTop - totalDropdownHeight
      } else {
        positionTop = 40
      }
      const refValue = ReactDOM.findDOMNode(dropdownTriggerBtnRef.current)
      if (refValue) {
        setPos({
          right: refValue
            .getBoundingClientRect().right,
          left: refValue
            .getBoundingClientRect().left,
          top: refValue
            .getBoundingClientRect().top + positionTop,
          bottom: refValue
            .getBoundingClientRect().bottom,
        })
      }
    }
  }

  const prepareSelectedList = () => {
    let colMultiOptions_copy = JSON.parse(JSON.stringify(colMultiOptions))
    colMultiOptions_copy.forEach((multiColOpn) => {
      if (selected_options.some((selected_option) => multiColOpn.color === selected_option.color && multiColOpn.value === selected_option.value)) {
        multiColOpn.checked = true
      }
    })
    setColOpns_WithCheckedProp(colMultiOptions_copy)
    detailValue_ref_tracker.current = colMultiOptions_copy
  }

  // handles key events of the editor container div
  const handleContainerDivKeyDown = (e) => {
    // enter key press
    if (e.keyCode == 13) {
      // Trigger click of the active element on which the focus is.
      document.activeElement.click();
      onClose(true)
    }
  }

  const handleClick = (e) => {
    e.preventDefault()
    // If a person clicks pre selected option, 
    // then we should close the dropdown (setDropdownOpen) and thus the editor ( onClose )
    setDropdownOpen(!dropdownOpen)
    if (dropdownOpen === true) onClose(true)
  }

  const onAddButtonClick = () => {
    dispatch(toggleDialogOpen(true));
    dispatch(updateBody(
      <SelectOneDialog1
        colors={colors}
        selectOneOptions={colOpns_WithCheckedProp}
        rowProperties={rowProperties}
        saveCurrentRowData={saveCurrentRowData}
        colKey={colKey}
        colIdx={colIdx}
      />))
    dispatch(updateHeaderText('Add/Delete'))
    onClose(true)
  }

  const toggleOption = (option) => {
    if(!did_user_interact.current) did_user_interact.current = true
    let copy = JSON.parse(JSON.stringify(colOpns_WithCheckedProp))
    copy.forEach(el => {
      if (option.color === el.color && option.value === el.value) {
        el.checked = !el.checked
      }
    })
    setColOpns_WithCheckedProp(copy)
    detailValue_ref_tracker.current = copy
  }

  const saveOn_dropdownClose = () => {
    // send data on unmount where here we are using detailValue_ref_tracker because of closure problem
    let selectedOptions_to_be_sent = detailValue_ref_tracker.current.filter(el => el.checked === true)
    const updated_details = { [colKey + '_details']: selectedOptions_to_be_sent }
    let updatedDisplayValue = selectedOptions_to_be_sent[0] ? selectedOptions_to_be_sent[0].value : ''
    if(did_user_interact.current)
    saveCurrentRowData('', colKey, rowId, registerData.rows, false, false, updated_details);
  }

  return (
    <ErrorBoundary>
      <div className="w-full h-full" tabIndex="-1" id="label-editor-container-div" onKeyDown={handleContainerDivKeyDown}>
        <div ref={dropdownTriggerBtnRef} tabIndex="0" onClick={handleClick} className="sheetItem1 focus:bg-gray-600" style={{ minWidth: '3rem', minHeight: '1.5rem' }} >&nbsp;</div>
        {/*Portal starts here*/}
        {dropdownOpen && createPortal(
          <div ref={dropdown} id="label-drop-down-portal-container" tabIndex="-1" className="slide_down_2 shadow-lg border border-gray-400" style={{ top: pos.top, right: pos.right, left: pos.left, fontSize: '12px', position: 'fixed', width: dropdownTriggerBtnRef.current.clientWidth, minWidth: '12rem' }} >

            <div className="bg-white px-2 py-1 text-right">
              <button onClick={onAddButtonClick} className="font-semibold" style={{ color: '#586C9D' }}>Add/Delete</button>
            </div>

            {/*Search Input*/}
            <div className="flex items-center bg-white border-b border-gray-300">
              <img className="absolute w-3 h-3 ml-5" src={searchIcon} />
              <input
                className="pl-10 w-full mx-2 my-1 h-8 border rounded focus:outline-none focus:ring"
                style={{ fontFamily: "roboto", fontWeight: "400", fontSize: "14px" }}
                placeholder="Search Name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            <div className="overflow-hidden overflow-y-auto delay-50 ease-out" style={{ maxHeight: '8rem' }}>
              {/*Options in the form of anchor and non active button starts here*/}
              {colOpns_WithCheckedProp && colOpns_WithCheckedProp.filter((option) => {
                if (searchQuery == "") {
                  return option
                } else if (option.value.toLowerCase().includes(searchQuery.toLowerCase())) {
                  return option
                }
              })
                .map((option, i) => {
                  return (
                    <div tabIndex="0" onClick={() => toggleOption(option)} id={`label-dropdown-option-${i}`} key={i} className="flex px-2 py-2 cursor-pointer border-t bg-white hover:bg-gray-200">
                      <span className="flex items-center text-black w-5/6 p-1 mr-2 rounded-sm" style={{ backgroundColor: option.color }}>
                        <p className="truncate">{option.value}</p>
                      </span>
                      <span className="flex items-center justify-center text-black w-1/6">
                        <input tabIndex="-1" style={{ border: '1px solid #35A600', borderRadius: 3 }} type="checkbox" checked={option.checked} />
                      </span>
                    </div>
                  )
                })}
            </div>

          </div>,
          document.body)}
        {/*Portal ends here*/}
      </div>
    </ErrorBoundary>
  )
}

export default Label