import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

//api call to post comment
export const postComment = createAsyncThunk('comment/post', async ({ text, rowId, sheetId, commentId, editCommentIdx }) => {
  const response = await axios.post('/v3/sheet/row/largeData/addComment', { text, rowId, sheetId, commentId })
  return { editCommentIdx, text }
})

//api call to get comments
export const getComments = createAsyncThunk('comments', async ({ sheetId, rowId }) => {
  const response = await axios.get('/loggedapiv2/sheetRowDetail', {
    params: {
      sheetId,
      rowId
    }
  })
  return response.data.data
})

//api call to delete comment
export const deleteComment = createAsyncThunk('comment/deleteComment', async ({ commentId, sheetId, rowId, commentIdx }) => {
  const response = await axios.post('/v3/sheet/row/deleteComment', { commentId, sheetId, rowId })
  if (response.data.success) {
    return commentIdx
  }
})

//api call to get history
export const getHistory = createAsyncThunk('history/get', async ({ rowId }) => {
  const response = await axios.get('/v3/sheet/row/editHistory?rowId=' + rowId)
  return response.data
})

export const rowDetailSlice = createSlice({
  name: 'rowDetail',
  initialState: {
    comments: [],
    editCommentIdx: -1,
    isLoadingDownload: false,
    history: [],
    activeActivityTab: null
  },
  reducers: {
    toggleDownloadButtonLoader: state => {
      state.isLoadingDownload = !state.isLoadingDownload
    },
    updateRowDetailState: (state, action) => {
      action.payload.forEach((el) => {
        const key = el.key
        const value = el.value
        state[key] = value
      })
    },
    setActiveActivityTab: (state, action) => {
      state.activeActivityTab = action.payload
    },
    setEditCommentIdx: (state, action) => {
      state.editCommentIdx = action.payload
    }
  },
  extraReducers: {
    [getComments.fulfilled]: (state, action) => {
      let p = action.payload
      state.comments = p.comments
    },
    [postComment.fulfilled]: (state, action) => {
      const { editCommentIdx, text } = action.payload
      if (editCommentIdx !== undefined && editCommentIdx !== null) {
        state.comments[editCommentIdx].text = text
        state.editCommentIdx = -1
      }
    },
    [deleteComment.fulfilled]: (state, action) => {
      let commentIdx = action.payload
      state.comments.splice(commentIdx, 1)
    },
    [getHistory.fulfilled]: (state, action) => {
      state.history = action.payload.data.history
    }
  }

})

export const { toggleDownloadButtonLoader, updateRowDetailState, setActiveActivityTab, setEditCommentIdx } = rowDetailSlice.actions

export default rowDetailSlice.reducer

