import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom';

//images and icons
import noNotification from '../../../../../../images/right-sidebar/notifications/no-notification.png'
import commentIcon from '../../../../../../images/right-sidebar/notifications/comment.png'
import reminderIcon from '../../../../../../images/right-sidebar/notifications/reminder.png'
import addParticipantIcon from '../../../../../../images/right-sidebar/notifications/add_participant.png'

const NotificationsList = () => {

  //route variables
  const history = useHistory()
  const location = useLocation()

  //state variables
  const rightSidebarData = useSelector(state => state.rightSidebar)
  const leftSidebarData = useSelector(state => state.leftSidebar)

  //local variables
  const { notifications } = rightSidebarData
  const { activeBusinessId } = leftSidebarData

  const onNotificationClick = ({type, sheetId, rowId}) => {
    if(type == 'register'){
      history.push(`/community/${activeBusinessId}/register/${sheetId}`)
    } else if(type == 'row_detail'){
      history.push(`/community/${activeBusinessId}/register/${sheetId}?rowId=${rowId}`)
    } else if(type == 're_invite'){

    } 
  }
  
  const getNotificationIcon = (icon) => {
    let iconSrc
    if(icon == 'comment') iconSrc = commentIcon
    else if(icon == 'reminder') iconSrc = reminderIcon
    else if(icon == 'newMember') iconSrc = addParticipantIcon
    return <img src={iconSrc} className={classes.item1} />
  }

  const classes = {
    container: `flex justify-center items-center gap-5 ml-0.5`,
    listContainer: 'h-full w-full overflow-auto bg-white',
    itemContainer: 'flex items-start gap-3 px-5 py-4 w-full border-b border-gray-300 cursor-pointer transition-color delay-50 ease-in',
    item1: 'h-5 relative top-1',
    item2: 'w-full',
    item2Child1: 'flex justify-between w-full',
    title: 'font-bold'
  }

  const styles = {
    container: { height: '90vh' },
    time: { color: '#999999', fontSize: '0.8rem' }
  }

  return (
    <div className={classes.container} style={styles.container}>

      {
        notifications && notifications.length > 0 ?

          <ul className={classes.listContainer}>

            {
              notifications && notifications.map((notification, i) => {
                return (
                  <li key={`notificationsItems/${notification.notificationId}`} style={{ background: notification.isSeen ? '' : '#E3F0FF' }}>
                    <div className={`${classes.itemContainer} hover:${notification.isSeen ? 'bg-blue-100' : 'bg-blue-300'}`}>

                      { getNotificationIcon(notification.icon) }
                      <div className={classes.item2}>
                        <div className={classes.item2Child1}>
                          <div className={classes.title}>{notification.title}</div>
                          <div style={styles.time} className={classes.time}>{notification.time}</div>
                        </div>
                        <div className='text-sm'>{notification.message}</div>
                      </div>

                    </div>
                  </li>

                )
              })}

          </ul>

          :

          <img src={noNotification} />

      }

    </div>
  )
}

export default NotificationsList