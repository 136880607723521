import { useDispatch, useSelector } from "react-redux"
import { setActiveActivityTab } from "../../../../slices/RowDetailSlice"

const Navbar = () => {

  // redux variables
  const dispatch = useDispatch()
  const rowDetailData = useSelector(state => state.rowDetail)

  // local variables
  const { activeActivityTab } = rowDetailData
  let nabvarElements = [
    {
      name: 'Overview',
      activeTabName: null
    },
    {
      name: 'Comments',
      activeTabName: 'comments'
    },
    {
      name: 'Edit History',
      activeTabName: 'history'
    },
  ]

  return (
    <div className="flex h-full bg-white">
      {
        nabvarElements.map((el, i) => {
          return (
            <div
              onClick={() => dispatch(setActiveActivityTab(el.activeTabName))}
              className={`w-1/3 text-base child-in-center cursor-pointer transition-colors delay-50 navbar ${activeActivityTab == el.activeTabName ? 'navbarActive font-medium' : 'font-normal'}`}
            >
              {el.name}
            </div>
          )
        })
      }
    </div>
  )
}

export default Navbar