import { useState, useCallback } from 'react'

//third party libraries
import { GoogleMap, LoadScript, Marker, Autocomplete } from '@react-google-maps/api'

//images
import searchIcon from '../../../../../../images/dialog/location/search.png'

const librariesArray = ["places"]

const Map = ({ markerPosition, setMarkerPosition, panTo, getAdress, onMapLoad}) => {

  const [autocomplete, setAutocomplete] = useState(null)

  const mapContainerStyle = {
    width: '100%',
    height: '100%'
  }

  const onMapClick = useCallback((mapClickEvent) => {
    const pos = {
      lat: mapClickEvent.latLng.lat(),
      lng: mapClickEvent.latLng.lng()
    }
    setMarkerPosition(pos)
    panTo(pos)
    getAdress({pos, updateAddress:true, updateCity: true})
  }, [])

  const onAutocompleteLoad = (autoC) => {
    setAutocomplete(autoC)
  }

  const onAutocompletePlaceChanged = () => {
    const pos = {
      lat: autocomplete.getPlace().geometry.location.lat(),
      lng: autocomplete.getPlace().geometry.location.lng()
    }
    setMarkerPosition(pos)
    panTo(pos)
    getAdress({pos, updateAddress:true, updateCity: true})
  }

  return (
    <div className='h-full w-full'>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY_PRODUCTION}
        libraries={librariesArray}
      >
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={markerPosition}
          zoom={13}
          onClick={onMapClick}
          onLoad={onMapLoad}
        >
          <Marker
            position={markerPosition}
          />
          <Autocomplete onLoad={onAutocompleteLoad} onPlaceChanged={onAutocompletePlaceChanged}>
            <div className="flex items-center relative" style={styles.autoCompleteDiv}>
              <img src={searchIcon} className="absolute h-4 ml-5" />
              <input
                type="text"
                className="pl-12"
                style={styles.autoCompleteInput}
              />
            </div>
          </Autocomplete>
        </GoogleMap>
      </LoadScript>
    </div>
  )
}

export default Map

const styles = {
  autoCompleteDiv: {position: "absolute", left: "50%", top: '2rem', marginLeft: "-9rem"},
  autoCompleteInput: { border: `1px solid transparent`, width: `18rem`, height: `2.5rem`, borderRadius: `1.5rem`, boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`, outline: `none`, textOverflow: `ellipses`,  }
}