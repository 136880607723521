import { useDispatch } from 'react-redux'

//slices
import { toggleRightSidebarOpen } from '../../../../../../slices/RightSidebarSlice'

//icons
import headerIcon from '../../../../../../images/right-sidebar/col-layout/header.png'
import closeIcon from '../../../../../../images/right-sidebar/col-layout/cross.png'

const Header = () => {

  //redux variables
  const dispatch = useDispatch()

  const onCloseButtonClick = () => {
    dispatch(toggleRightSidebarOpen({ rightSidebarOpen: false, body: null}))
  }

  //styles
  const container = { height: '10vh', backgroundColor: '#C7DEFF' }
  const headerText = { color: '#174184', }

  //classes
  const containerClass = `flex items-center justify-between px-5`
  const headerTextClass = `child-in-center gap-3 font-bold text-base`

  return (
    <div style={container} className={containerClass}>
      <div className={headerTextClass} style={headerText}>
        <img src={headerIcon} className='h-5' />
        Page Layout
      </div>
      <img src={closeIcon} onClick={onCloseButtonClick} className='h-4 cursor-pointer' />
    </div>
  )
}

export default Header