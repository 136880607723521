// icons
import closeIcon from '../../../../../images/dialog/close.png'

const Header = ({ closePopUp }) => {
	return (
		<div
			className="w-full h-full flex items-center justify-between"
		>
			Create New Register
			<button
				onClick={closePopUp}
			>
				<img className="h-6 w-6" src={closeIcon} alt="close dialog" />
			</button>
		</div>
	)
}

export default Header