import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// actions
import { uploadExcel, updateRegisterState, fetchRegisterData, fetchAllRegisterData, addNewPage, fetchRegisterPages } from '../../../../../slices/RegisterSlice'

// components
import CustomMultiSelect from '../../../../../partials/CustomMultiSelect'
import CustomDropdown from '../../../../../partials/CustomDropdown'

// icons
import importExcelIcon from '../../../../../images/importExcelIcon.png'

// thirdy party libraries
import XLSX from 'xlsx'

const AddPagePopUpBody = ({ closeDiaglog }) => {

  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)
  const leftSidebarData = useSelector(state => state.leftSidebar)

  // local variables
  const { columns, newSheetInfo, registerId, pages } = registerData
  const { activeBusinessId } = leftSidebarData

  // state variables
  const [newSheetName, setNewSheetName] = useState('Page ' + (pages.length + 1))
  const [currentPageColumns, setCurrentPageColumns] = useState([])
  const [columnsToBeCopied, setColumnsToBeCopied] = useState([])
  const [excelSheetsList, setExcelSheetsList] = useState([])
  const [excelSheetSelected, setExcelSheetSelected] = useState(null)
  const [excelFile, setExcelFile] = useState(null)

  useEffect(() => {
    const pageColumns = columns.filter(el => el.key !== '-1' && el.key !== 'addColPlusButton')
    setCurrentPageColumns(pageColumns)
  }, [])

  useEffect(() => {
    if (newSheetInfo) {
      dispatch(fetchRegisterPages({ registerId }))
      dispatch(fetchAllRegisterData({ registerId, sheetId: newSheetInfo.newSheetId }))
      dispatch(updateRegisterState([{ key: 'newSheetInfo', value: null }]))
      closeDiaglog()
    }
  }, [newSheetInfo])

  const onColMultiSelectConfirmation = (selectedItems) => {
    const colKeyArr = []
    selectedItems.forEach((el) => {
      colKeyArr.push(el.key)
    })
    setColumnsToBeCopied(colKeyArr)
  }

  const onImportExcel = async (e) => {
    let files = e.target.files
    const f = Array.from(files)[0]
    const excelFileName = f.name.split('.')[0]
    setExcelFile(f)
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString
    reader.onload = e => {
      /* Parse data */
      const bstr = e.target.result
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" })
      /* Get first worksheet  */
      let sheetsList = wb.SheetNames.map((el) => { return { name: el, excelFileName } })
      setExcelSheetsList(sheetsList)
      if (columnsToBeCopied.length > 0) setColumnsToBeCopied([])
    }
    if (rABS) reader.readAsBinaryString(f)
    else reader.readAsArrayBuffer(f)
  }

  const onDropdownSelect = (item) => {
    setNewSheetName(`${item.excelFileName}(${item.name})`)
    setExcelSheetSelected(item)
  }

  const createSheet = () => {
    if (!allowSheetCreate()) return
    if (excelFile) {
      dispatch(uploadExcel({
        file: excelFile,
        currentSheetId: registerData.sheetId,
        registerId,
        businessId: activeBusinessId,
        newSheetName,
        excelSheetName: excelSheetSelected.name
      }))
    } else {
      dispatch(addNewPage({
        currentSheetId: registerData.sheetId,
        registerId,
        businessId: activeBusinessId,
        newSheetName,
        retainColumns: columnsToBeCopied
      }))
    }
  }

  const removeExcel = () => {
    setNewSheetName('Page ' + (pages.length + 1)) // reset page name according to count
    // reset excel attributes to initial state
    setExcelSheetsList([])
    setExcelSheetSelected(null)
    setExcelFile(null)
  }

  const allowSheetCreate = () => {
    if (excelFile) {
      return newSheetName.trim().length > 0 && excelSheetSelected
    }
    return newSheetName.trim().length > 0
  }

  return (
    <div className="flex flex-col px-5 md:px-10 lg:px-20" style={{ backgroundColor: '#EEF3F5', height: '45vh', maxWidth: '95vw' }}>
      {/* Page Name container starts */}
      <div className="relative flex flex-col h-1/3 justify-center" >
        <span className="flex pb-1" style={{ fontSize: 12, color: '#586C9E', fontWeight: '400', fontFamily: 'Arial, Helvetica, sans-serif' }}>Page Name</span>
        <input className={`flex h-2/5 ${excelSheetSelected && "pl-12"}`} type="text" value={newSheetName} style={{ border: '1px solid #586C9E', borderRadius: 5, color: '#454545', fontSize: 20 }} onChange={(e) => { setNewSheetName(e.target.value) }} autoFocus />
        {
          excelSheetSelected &&
          <img className="flex w-6 h-6 absolute" style={{ top: '50%', left: 10 }} src={importExcelIcon} />
        }
      </div>
      {/* Page Name container ends */}

      {/* Copy data and import excel container starts */}
      <div className="relative flex h-1/3">
        {/* Copy data container starts */}
        <div className="relative flex w-9/12 flex-col justify-center items-center">
          {
            excelSheetsList.length > 0 ?
              <CustomDropdown
                label={'Copy Data ( from sheet )'}
                labelStyle={{ top: 8 }}
                items={excelSheetsList}
                displayKey={'name'}
                onSelect={onDropdownSelect}
                menuContainerStyle={{ top: '70%', height: '12rem' }}
                noneText="Select Sheet"
              />
              :
              <CustomMultiSelect
                label={'Copy Data ( from current page )'}
                menuContainerStyle={{ top: '55%', right: '-42%', height: '17rem', width: '70%', border: '1px solid #C4C4C4', borderRadius: 5 }}
                items={currentPageColumns}
                displayKey={'name'}
                selectedItemSingularTxt='Column'
                selectedItemPluralTxt='Columns'
                onConfirmation={onColMultiSelectConfirmation}
              />
          }
        </div>
        {/* Copy data container ends */}

        {/* Import excel container starts */}
        <div className="flex w-3/12 justify-end items-center">
          {
            excelFile ?
              <div onClick={removeExcel} className="flex w-4/5 h-3/5 bg-white rounded items-center justify-center cursor-pointer" style={{ border: '1px solid #C4C4C4', borderRadius: 5 }}>
                <span style={{ color: '#586C9E' }}>Remove</span>
              </div>
              :
              <>
                <input id="btn/import/excel/addPagePopUp" type="file" onChange={onImportExcel} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" hidden multiple />
                <label tabIndex="0" htmlFor="btn/import/excel/addPagePopUp" className="flex w-4/5 h-3/5 bg-white rounded items-center justify-center cursor-pointer" style={{ border: '1px solid #C4C4C4', borderRadius: 5 }}>
                  <img className="flex w-12 h-12" src={importExcelIcon} />
                </label>
              </>
          }
        </div>
        {/* Import excel container ends */}

      </div>
      {/* Copy data and import excel container ends */}

      {/* Create button container starts */}
      <div className="flex h-1/3 items-center">
        <div tabIndex="0" onClick={createSheet} className={`flex h-2/5 w-full justify-center items-center ${allowSheetCreate() ? 'cursor-pointer' : 'cursor-not-allowed'}`} style={{ borderRadius: 5, backgroundColor: allowSheetCreate() ? '#2AB200' : '#7b9d7b' }}>
          <span className="text-white" style={{ fontWeight: '400', fontSize: 20 }}>CREATE</span>
        </div>
      </div>
      {/* Create button container ends */}
    </div>
  )
}
export default AddPagePopUpBody