import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { getValidatedDate } from "../../../../Table/Utils";

//icons
import dateIcon from '../../../../../../images/datatype_icons/light/date.png'
import dropDownBlueIcon from '../../../../../../images/header popup icons/drop down.png'
import crossIcon from '../../../../../../images/add-edit row dialog/cross-gray.png'

const Date1 = ({ colkey, column, handleChange, formData, shouldAllowNewEntry }) => {

  // redux variables
  const registerData = useSelector(state => state.register)

  // state variables
  const [startDate, setStartDate] = useState()

  // local variables
  const start = getValidatedDate(formData[colkey])
  const { isAdmin, hasBasicEditPermission, editPermissions } = registerData
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colkey) === -1

  //Set initial date
  useEffect(() => {
    if (start) {
      const day = formData[colkey].split('-')[0]
      const month = formData[colkey].split('-')[1] - 1
      const year = formData[colkey].split('-')[2]
      setStartDate(new Date(year, month, day))
    } else {
      setStartDate(null)
    }
  }, [start])

  //Update formData on date change
  const handleDateChange = (date) => {
    if (date) {
      //convert to 'dd-mm-yyyy'
      let day = date.getDate()
      if (day >= 0 && day < 10) {
        day = `0${day}`
      }
      let month = date.getMonth() + 1
      if (month >= 0 && month < 10) {
        month = `0${month}`
      }
      const year = date.getFullYear()
      const date3 = []
      date3.push(day)
      date3.push(month)
      date3.push(year)
      const date4 = date3.join('-')
      //change date value on formData(row)
      handleChange({ colkey, value: date4 })
    }
    else {
      handleChange({ colkey, value: '' })
    }
  }

  const CustomContainer = ({ className, children }) => {
    return (
      <div className={`${className}`}>
        <div style={{ position: "relative", fontSize: '0.8rem' }}>{children}</div>
      </div>
    )
  }

  return (
    <div className="mb-6">

      <div className="colName">
        <img src={dateIcon} className="field_icon" />
        <label htmlFor={column.name}>{column.name}</label>
      </div>

      {
        !canEdit && !shouldAllowNewEntry &&
        <div className="field truncate">{start}</div>
      }

      {
        (shouldAllowNewEntry || canEdit) &&
        <div className="relative flex items-center">
          {start ? <img onClick={() => handleDateChange()} src={crossIcon} className='cross_icon z-10' /> : <img className='drop-down-icon z-20' src={dropDownBlueIcon} />}
          <DatePicker
            className="field date_picker_form cursor-pointer focus:ring-transparent"
            calendarContainer={CustomContainer}
            selected={startDate}
            onChange={(date) => { setStartDate(date); handleDateChange(date) }}
            dateFormat="dd-MM-yyyy"
          />
        </div>
      }
    </div>
  )
}

export default Date1