import { useSelector } from 'react-redux'

// icons
import signatureIcon from '../../../../../../images/datatype_icons/light/signature.png'

const Signature = ({ colkey, column, handleChange, formData, shouldAllowNewEntry }) => {

  // redux variables
  const registerData = useSelector(state => state.register)

  // local variables
  const { isAdmin, hasBasicEditPermission, editPermissions } = registerData
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colkey) === -1
  const index = `${colkey}_details`
  const imageSrc = formData[index] && formData[index].length > 0 ? formData[index][0].value : null

  return (
    <div className="mb-6">
      <div className="colName">
        <img src={signatureIcon} className="field_icon" style={{height: '1.5rem'}}/>
        <label htmlFor={column.name}>{column.name}</label>
      </div>

      {
        !canEdit && !shouldAllowNewEntry &&
        <div className="field">
          {imageSrc ? <img src={imageSrc} style={{ height: "80px" }} /> : <div className="field non_editable cursor-not-allowed"></div>}
        </div>
      }

      {
        (shouldAllowNewEntry || canEdit) &&
        <div className=''>
          {imageSrc ? <img src={imageSrc} className='border' style={{ height: "80px" }} /> : <div className="field non_editable cursor-not-allowed"></div>}
        </div>
      }
    </div>
  )
}

export default Signature