import { useEffect, useRef } from "react"

// where ever this component is used the parent should have RELATIVE positioning
// properties mandatory:
// 1). btnRef => the one triggering, 
// 2). closeDropContainer => function to close/hide this container, 
// 3). children => content
const DropContainer = ({ btnRef, arrowProperties, children, closeDropContainer, closeDropClosureUpdate }) => {

  // ref
  const dropContainerRef = useRef(null)

  const arrowHeight = arrowProperties && arrowProperties.height ? arrowProperties.height : 10
  const arrowWidth = arrowProperties && arrowProperties.width ? arrowProperties.width : 20
  const arrowLeft = arrowProperties && arrowProperties.left ? arrowProperties.left : 10

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        dropContainerRef.current &&
        dropContainerRef.current.contains(target) ||
        btnRef.current.contains(target) ||
        target.classList.contains('react-datepicker__day') || // as it should remain open on date selection
        target.classList.contains('react-timekeeper__done-button') // as it should remain open on time selection
      ) return;
      closeDropContainer()
    }
    document.addEventListener('click', clickHandler)
    return () => {
      document.removeEventListener('click', clickHandler)
    }
    // [closeDropClosureUpdate] is used because if we want to close the dropdown on outside click and 
    // send a save call to save any data then to update the clousre of function 
    // passed in closeDropContainer for saving was required
  }, [closeDropClosureUpdate ? closeDropClosureUpdate : ''])

  const triangleStyles = {
    border: 'transparent',
    borderTop: 0,
    borderBottom: `${arrowHeight}px solid white`, // is same as the height
    borderLeft: `${arrowWidth / 2}px solid transparent`, // is half of width
    borderRight: `${arrowWidth / 2}px solid transparent`, // is half of width
    top: '0px',
    filter: 'drop-shadow(0px 0px 0.1px rgba(0,0,0,.5))',
    zIndex: 10,
    left: arrowLeft,
    width: arrowWidth,
    height: arrowHeight,
  }

  return (
    <div ref={dropContainerRef} className="mt-8 transition-all delay-100 ease-in slide_down drop-container">
      {/*--------- Triangle Starts --------*/}
      <div
        className='relative' style={triangleStyles} >
      </div>
      {/*--------- Triangle Ends --------*/}
      {/*---------- Body Starts -----------*/}
      <div
        className='relative bg-white'
        style={{ borderRadius: 8, zIndex: 9, boxShadow: '0px 0px 6px -2px rgba(0, 0, 0, 0.5)', maxWidth: '90vw'  }}
      >
        {children}
      </div>
      {/*---------- Body Ends -----------*/}
    </div>
  )
}

export default DropContainer