
// components
// components
import TextItem from './TextItem'

const TextList = ({ selectValue, selectedfilterData, uniqueTextList, searchQuery }) => {
  return (
    <div className="overflow-y-auto grow-0 mb-2">
      <div
        className="form-check-select-all cursor-pointer font-medium py-1 pr-1" style={{ color: '#878787' }}
        onClick={() => selectValue('selectAll')}
      >
        Select All
        <input
          type="checkbox" checked={selectedfilterData.length === uniqueTextList.length}
          style={{ backgroundColor: selectedfilterData.length === uniqueTextList.length ? '#35A600' : '#fff', border: '1.5px solid #35A600', borderRadius: '3px', height: '1.3rem', width: '1.3rem', outline: 'none', cursor: 'pointer' }}
          readOnly
        />
      </div>

      <ul style={{ color: '#545454', fontSize: '0.75rem' }} className='font-semibold'>

        {/* unique text values checklist */}
        {
          uniqueTextList && uniqueTextList.map((text, i) => {
            if (searchQuery.trim(' ').length > 0) { // executes when search text is present
              if (text.toLowerCase().includes(searchQuery.toLowerCase()))
                return (
                  <TextItem
                    selectValue={selectValue}
                    text={text}
                    itemIndex={i}
                    selectedfilterData={selectedfilterData}
                  />
                )
            } else { // executes when all needs to be shown
              return (
                <TextItem
                  selectValue={selectValue}
                  text={text}
                  itemIndex={i}
                  selectedfilterData={selectedfilterData}
                />
              )
            }
          }
          )
        }
        {/* unique text values checklist */}
      </ul>

    </div>
  )
}

export default TextList