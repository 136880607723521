import { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// slices
import { deletePage } from '../../../../../slices/RegisterSlice'
import { toggleDialogOpen, updateBody, updateHeaderText } from '../../../../../slices/DialogSlice'

//icons and images
import edit from '../../../../../images/header/edit.png'
import deleteRed from '../../../../../images/header/delete.png'

const SelectedSheetMenu = ({ activeSheetTabRef, setShowSheetMenu, toggleEditSheetName, sheetIdx, sheetInfo }) => {
  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  // refs
  const sheetMenuRef = useRef(null)

  // local variables
  const { pages, registerId, permissions } = registerData
  const isAdmin = permissions.includes('admin')
  const canEdit = permissions.includes('edit')

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (sheetMenuRef.current && sheetMenuRef.current.contains(target)) return;
      if (target.alt !== "Cyan Drop Down Arrow") setShowSheetMenu(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  }, []);

  const sheetDeleteClick = (e) => {
    if (!(isAdmin || canEdit)) {
      alert("Permission Denied")
      return
    }
    dispatch(toggleDialogOpen(true))
    dispatch(updateBody(getDeleteBody()))
    dispatch(updateHeaderText('Delete Page'))
  }

  const getDeleteBody = () => {
    return (
      <div className="bg-white flex flex-col justify-center items-center" style={{ width: pages.length === 1 ? '35rem' : '25rem' }}>
        <div className="text-lg text-center p-6 w-4/5" style={{ color: '#454545' }}>
          {pages.length === 1 && 'Deleting this page will delete the Register as well. '}
          <span>Are you sure you want to delete this page?</span>
        </div>
        <div className="flex justify-center items-center gap-6 my-5 text-lg" style={{ fontFamily: '' }}>
          <button id='delete_sheet_modal_cancel_btn' onClick={(e) => { dispatch(toggleDialogOpen(false)) }} className="py-2 px-8" style={{ color: '#586C9E' }}>CANCEL</button>
          <button id='delete_sheet_modal_confirm_btn' onClick={(e) => { confirmDeleteSheet(sheetIdx, sheetInfo) }} className="rounded-sm text-white py-2 px-8" style={{ backgroundColor: '#586C9E' }}>CONFIRM</button>
        </div>
      </div>
    )
  }

  const confirmDeleteSheet = (sheetIdx, sheetInfo) => {
    dispatch(toggleDialogOpen(false))
    dispatch(deletePage({
      sheetId: sheetInfo.sheetId,
      sheetBeingDeltdIdx: sheetIdx,
      registerId
    }))
  }

  return (
    <div
      ref={sheetMenuRef}
      className="grid fixed bg-white shadow-xl slide_down_2 px-1"
      style={{
        border: '1px solid rgba(52, 52, 52, 0.1)', width: 100, height: 100,
        left: activeSheetTabRef.current.getBoundingClientRect().left + (activeSheetTabRef.current.getBoundingClientRect().width - 100),
        top: activeSheetTabRef.current.getBoundingClientRect().top + 25, borderRadius: 4
      }}
    >
      
      <div
        onClick={() => { toggleEditSheetName(sheetInfo); setShowSheetMenu(false) }}
        className="flex items-center justify-start gap-2 px-2 border-b"
        style={{ color: "#666666" }}
      >
        <img src={edit} className="h-5" />Edit
      </div>

      <div
        onClick={sheetDeleteClick}
        className="flex items-center justify-start gap-2 px-2"
        style={{ color: '#D81205' }}
      >
        <img src={deleteRed} className="h-5" />Delete
      </div>

    </div>
  )
}

export default SelectedSheetMenu