import { useState } from "react"

// components
import AnimationSection from "./Components/AnimationSection"
import SignUpUsingEmail from "./Components/SignUpUsingEmail"
import SignUpUsingPhoneNumber from "./Components/SignUpUsingPhoneNumber"

const SIGN_UP_METHODS = {
  EMAIL: "email",
  PHONE_NUMBER: "phone-number",
}

const SignUpContainer = () => {

  // state variables
  const [signUpMethod, setSignUpMethod] = useState(SIGN_UP_METHODS.EMAIL)
  const [loading, setLoading] = useState(false)

  const handleSignUpMethodChange = (method) => {
    setLoading(true)
    setTimeout(() => {
      setSignUpMethod(method)
      setLoading(false)
    }, 300)
  }

  //classes
  const container = `h-full w-full flex flex-col md:flex-row`
  const signUpContainer = `w-full md:w-7/12 md:h-full p-8 text-center`
  const containerStyles = {}

  const signUpProps = { handleSignUpMethodChange, SIGN_UP_METHODS }

  return (
    <div className={container} style={containerStyles}>

      <AnimationSection />

      <div className={signUpContainer} style={{ backgroundColor: '#F8F9FA' }}>

        {loading && (
          <div className="h-full child-in-center"><div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-700 m-auto "></div></div>
        )}

        {!loading && signUpMethod === SIGN_UP_METHODS.EMAIL && (
          <SignUpUsingEmail {...signUpProps} />
        )}

        {!loading && signUpMethod === SIGN_UP_METHODS.PHONE_NUMBER && (
          <SignUpUsingPhoneNumber {...signUpProps} />
        )}

      </div>

    </div>
  )
}

export default SignUpContainer