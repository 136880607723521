import { useState } from 'react'
import { useDispatch ,useSelector } from 'react-redux'

// components
import CustomInputDropdown from '../../../../../../../partials/CustomInputDropdown'

// slices
import { updateBody, updateHeaderText, toggleDialogOpen } from '../../../../../../../slices/DialogSlice'

// components
import SelectOneDialog1 from '../../../../../Table/Editors/DataCell/SelectOne/SelectOneDialog1'

//icons
import dropdownIcon from '../../../../../../../images/datatype_icons/light/dropdown.png'
import searchIcon from '../../../../../../../images/search.png'
import DropdownBody from './DropdownBody'

const SelectOne = ({ colkey, column, colIdx, handleChange, formData, deleteEntry, shouldAllowNewEntry, saveCurrentRowDataRegister }) => {

  //redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  // state variables
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  // local variables
  const colKey = column.key
  const colkeyDetailValue = formData[colkey + '_details']
  let selectedOptionColor = colkeyDetailValue ? (colkeyDetailValue[0] ? colkeyDetailValue[0].value : null) : null
  if (!CSS.supports('color', selectedOptionColor)) {
    column.multiOptions.forEach(multiOpnEl => {
      if (multiOpnEl.value === formData[colkey]) {
        selectedOptionColor = multiOpnEl.color
      }
    })
  }
  const colors = ["#FFFFFF", "#B6E5FF", "#FFE7B6", "#DBB6FF", "#FFBAB6", "#FFB6FC", "#B6FFE9", "#CFF9C4"]

  const { isAdmin, hasBasicEditPermission, editPermissions } = registerData
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colkey) === -1

  const rowProperties = {
    value: formData[colkey],
    rowIdx: registerData.rowIndex
  }

  const handleSelect = (option, color) => {
    handleChange({ colkey, value: option, colkeyDetail: colkey + '_details', valueDetail: [{ value: color }] })
  }

  const closeDropdown = () => {
    if (searchQuery.length > 0) setSearchQuery('')
    setDropdownOpen(false)
  }

  const onOptionClick = (e, filteredOption) => {
    e.preventDefault()
    handleSelect(filteredOption.value, column.multiOptions[filteredOption.index] ? column.multiOptions[filteredOption.index].color : '#fff')
    closeDropdown()
  }

  const onEditButtonClick = (e) => {
    e.preventDefault()
    setDropdownOpen(false)
    dispatch(toggleDialogOpen(true));
    dispatch(updateBody(
      <SelectOneDialog1
        colors={colors}
        selectOneOptions={column.multiOptions}
        rowProperties={rowProperties}
        saveCurrentRowData={saveCurrentRowDataRegister}
        colKey={colKey}
        colIdx={colIdx}
      />))
    dispatch(updateHeaderText('Add/Delete'))
  }

  const onDeleteButtonClick = (e) => {
    e.preventDefault()
    deleteEntry(colkey)
    closeDropdown()
  }

  return (
    <div className="mb-6">

      <div className="colName">
        <img src={dropdownIcon} className="field_icon" />
        <label htmlFor={column.name}>{column.name}</label>
      </div>

      {
        !canEdit && !shouldAllowNewEntry &&
        <div className="field">{formData[colkey]}</div>
      }

      {
        (shouldAllowNewEntry || canEdit) &&
        <CustomInputDropdown
          fieldValue={formData[colkey] ? formData[colkey] : 'Select one'}
          inputType={`div`}
          container={`form`}
          inputClasses={`field`}
          inputStyles={{ color: formData[colkey] ? '' : '#B6B6B6' }}
          dropdownClasses={``}
          dropdownStyles={{ marginTop: 8, minWidth: '15rem' }}
          buttonsInfo={{ showButtonsSection: true, showDeleteButton: formData[colkey], onDeleteButtonClick, showEditButton: true, onEditButtonClick }}
          showRemoveIcon={false}
          showDropDownMenu={dropdownOpen}
          setShowDropDownMenu={setDropdownOpen}
          onFieldClick={() => { }}
        >
          <DropdownBody
            column={column}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            colors={colors}
            onOptionClick={onOptionClick}
            formData={formData}
            colkey={colkey}
            deleteEntry={deleteEntry}
            closeDropdown={closeDropdown}
          />
        </CustomInputDropdown>
      }
    </div>
  )
}

export default SelectOne


{/* <div className="relative flex items-center">

<button onClick={handleSelectedOptionClick} className="field cursor-pointer">
  <span className='truncate'>{formData[colkey]}</span>
  {!formData[colkey] && <span style={{ color: '#B6B6B6' }}>Select one</span>}
</button>

<img className={`drop-down-icon transform transition-transform delay-50 ${dropdownOpen ? 'rotate-180' : 'rotate-0'}`} src={drop_down} />

{
  dropdownOpen &&
  <Dropdown closeDropdownCallback={closeDropdown} containerClasses={`mt-2 w-full`}>
    <DropdownBody
      column={column}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      colors={colors}
      onOptionClick={onOptionClick}
      formData={formData}
      colkey={colkey}
      deleteEntry={deleteEntry}
      closeDropdown={closeDropdown}
      colIdx={colIdx}
    />
  </Dropdown>
}
</div> */}