import React, { useEffect, useRef, useState } from 'react';
import ReactDOM, { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux'
import SelectOneDialog1 from './SelectOneDialog1';

import { updateBody, updateHeaderText, toggleDialogOpen } from '../../../../../../slices/DialogSlice'

import searchIcon from '../../../../../../images/search.png'

// css
import '../rowEditors.css';

// utils
import ErrorBoundary from '../../../../../../utils/ErrorBoundary'

const SelectOneEditor = ({ rowProperties, saveCurrentRowData, colIdx, columnEl, selectOneOptions, optionColor }) => {
  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  // local variables
  const colKey = columnEl.key
  const onClose = rowProperties.onClose
  const rowIndex = rowProperties.rowIdx
  const rowId = rowProperties.row.rowId
  const value = rowProperties.row[colKey]
  const colors = ["#FFFFFF", "#B6E5FF", "#FFE7B6", "#DBB6FF", "#FFBAB6", "#FFB6FC", "#B6FFE9", "#CFF9C4"]
  const optionsArray = selectOneOptions.map((option, i) => {
    return ({
      'value': option.value,
      'color': option.color ? option.color : (colors[i] ? colors[i] : '#fff'),
      'index': i
    })
  })

  // state variables
  const [pos, setPos] = useState({})
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  // ref variables
  const dropdown = useRef(null)
  const dropdownTriggerBtnRef = useRef(null)

  useEffect(() => {
    // as soon as the editor is rendered dropdown should open
    setDropdownOpen(true);
    // Sets position of the dropdown in portal w.r.t the drop down trigger button
    // using its ref.
    //onScroll()
    // listeneing to table scroll represented by .rdg-light class
    document.querySelector(".rdg-light").addEventListener("scroll", onScroll)
    document.addEventListener('keydown', keyHandler);
    return () => {
      document.removeEventListener("scroll", onScroll)
      document.removeEventListener('keydown', keyHandler);
    }
  }, []);

  useEffect(() => {
    if (dropdownOpen) onScroll()
  }, [dropdownOpen])

  const keyHandler = (e) => {
    // tab key press
    if (e.keyCode == 9) {
      // 1) Initially when the cell is opened then the focus is on the cell having the class rdg-focus-sink.
      // 2) After tabbing on the last element of status dropdown we bring focus back to select-one-editor-container-div,
      //    Hence as it has tabIndex value is -1 thus, automatically the focus shifs to the very next element having tabIndex 0,1 etc.
      //    Here the div to which the focus shifts is dropdownTriggerBtnRef having class sheetItem1 which consists the pre selected option.
      //
      //    So now when tab is pressed again the focus should shift to the first status option and for that we press tab.
      //    Now the focus shifts to select-one-dropdown-portal-container div which has tabIndex -1 so now, automatically the focus gets removed from it
      //    and shifts to the very next element with positive tabIndex ie., selection-one-dropdown-option-1 anchor tag.
      if (document.activeElement.classList.contains('rdg-focus-sink') || document.activeElement.classList.contains('sheetItem1')) {
        document.getElementById(`select-one-dropdown-portal-container`).focus();
      }
      // When the last element is focused by tabbing and we press tab again,
      // the focus should return back to the pre selected cell.
      // We shift the focus on select-one-editor-container-div as it has tabIndex -1.
      // Due to this value of tab index the focus automatically moves to the very next element with a positive
      // tabIndex.
      if (document.activeElement.id === 'select-one-remove-entry-anchor') {
        document.getElementById('select-one-editor-container-div').focus();
      }
    }
    if (!dropdownOpen || e.keyCode !== 27) return;
    setDropdownOpen(false);
  }

  const onScroll = () => {
    if (dropdown.current) {
      let totalDropdownHeight = dropdown.current.getBoundingClientRect().height
      let positionTop = 0
      if (dropdownTriggerBtnRef.current.getBoundingClientRect().bottom + totalDropdownHeight >= window.innerHeight) {
        positionTop = positionTop - totalDropdownHeight
      } else {
        positionTop = 40
      }
      const refValue = ReactDOM.findDOMNode(dropdownTriggerBtnRef.current)
      if (refValue) {
        setPos({
          right: refValue
            .getBoundingClientRect().right,
          left: refValue
            .getBoundingClientRect().left,
          top: refValue
            .getBoundingClientRect().top + positionTop,
          bottom: refValue
            .getBoundingClientRect().bottom,
        })
      }
    }
  }

  const handleClick = (e) => {
    e.preventDefault()
    // If a person clicks pre selected option, 
    // then we should close the dropdown (setDropdownOpen) and thus the editor ( onClose )
    setDropdownOpen(!dropdownOpen)
    if (dropdownOpen === true) onClose(true)
  }

  const handleOptionClick = (option, index, color) => {
    const additionalKeys = { [`${colKey}_details`]: [{ value: color }] }
    if (option == '') {
      saveCurrentRowData(null, colKey, rowId, registerData.rows, false, false, additionalKeys)
    } else {
      saveCurrentRowData(option, colKey, rowId, registerData.rows, false, false, additionalKeys)
    }
    setDropdownOpen(false)
  }

  // handles key events of the editor container div
  const handleContainerDivKeyDown = (e) => {
    // enter key press
    if (e.keyCode == 13) {
      // Trigger click of the active element on which the focus is.
      document.activeElement.click();
      onClose(true)
    }
  }

  const onAddButtonClick = () => {
    dispatch(toggleDialogOpen(true));
    dispatch(updateBody(
      <SelectOneDialog1
        colors={colors}
        selectOneOptions={selectOneOptions}
        rowProperties={rowProperties}
        saveCurrentRowData={saveCurrentRowData}
        colKey={colKey}
        colIdx={colIdx}
      />))
    dispatch(updateHeaderText('Add/Delete'))
    onClose(true)
  }

  return (
    <ErrorBoundary>
      <div className="w-full h-full" tabIndex="-1" id="select-one-editor-container-div" onKeyDown={handleContainerDivKeyDown}>

        {/*Pre select value containing div starts here*/}
        <div ref={dropdownTriggerBtnRef} tabIndex="0" onClick={handleClick} className="sheetItem1 focus:bg-gray-600"><p className="px-2 truncate" style={{ backgroundColor: optionColor, minWidth: '3rem', minHeight: '1.5rem' }}>{value || ''}</p></div>
        {/*Pre select value containing div ends here*/}

        {/*Portal starts here*/}
        {dropdownOpen && createPortal(
          <div ref={dropdown} id="select-one-dropdown-portal-container" tabIndex="-1" className="slide_down_2 shadow-lg border border-gray-400" style={{ top: pos.top, right: pos.right, left: pos.left, fontSize: '12px', position: 'fixed', width: dropdownTriggerBtnRef.current.clientWidth, minWidth: '12rem' }} >

            <div className="bg-white px-2 py-1 text-right">
              <button onClick={onAddButtonClick} className="font-semibold" style={{ color: '#586C9D' }}>Add/Delete</button>
            </div>

            {/*Search Input*/}
            <div className="flex items-center bg-white border-b border-gray-300">
              <img className="absolute w-3 h-3 ml-5" src={searchIcon} />
              <input
                className="pl-10 w-full mx-2 my-1 h-10 border rounded focus:outline-none focus:ring"
                style={{ fontFamily: "roboto", fontWeight: "400", fontSize: "14px" }}
                placeholder="Search Name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            <div className="overflow-hidden overflow-y-auto" style={{ maxHeight: '8rem' }}>
              {/*Options in the form of anchor and non active button starts here*/}
              {optionsArray && optionsArray.filter((option) => {
                if (searchQuery == "") {
                  return option
                } else if (option.value.toLowerCase().includes(searchQuery.toLowerCase())) {
                  return option
                }
              })
                .map((c, i) => {
                  return (
                    <a tabIndex="0" id={`selection-one-dropdown-option-${i}`} key={i} style={{ backgroundColor: selectOneOptions[c.index].color || '#fff' }} className="flex items-center cursor-pointer border-t" onClick={(e) => { e.preventDefault(); handleOptionClick(c.value, c.index, selectOneOptions[c.index].color) }}>
                      <span tabIndex={-1} className="flex items-center text-black w-full h-10 text-left pl-5 p-2 transition-colors delay-50 ease-out hover:bg-gray-200" >
                        <p className="px-1 truncate">{c.value}</p>
                      </span>
                    </a>
                  )
                })}
            </div>

            {value !== '' && <a tabIndex="0" id={`select-one-remove-entry-anchor`} key="select-one-remove-entry-anchor" className="flex items-center cursor-pointer border-t border-gray-300" onClick={(e) => { e.preventDefault(); saveCurrentRowData('', colKey, rowId, registerData.rows, false, false, { [`${colKey}_details`]: [{ value: '#fff' }] }); setDropdownOpen(false) }}>
              <button tabIndex={-1} className="bg-white font-semibold w-full flex justify-between p-2 pl-5 items-center transition-colors delay-50 ease-out hover:bg-gray-200" style={{ color: '#FC1055' }} onClick={() => { }}>
                Remove Entry
              </button>
            </a>
            }
            {/*Options in the form of anchor and non active button ends here*/}

          </div>,
          document.body)}
        {/*Portal ends here*/}

      </div>
    </ErrorBoundary>
  )
}

export default SelectOneEditor;