// icons
import closeIcon from '../../images/closeWhite.png'

const Header = ({
  text,
  closePopup
}) => {
  return (
    <div className="h-20 text-white text-xl flex items-center justify-between px-6 font-bold" style={{backgroundColor: '#174184'}}>
      {text}
      <img onClick={closePopup} src={closeIcon} className='h-8 cursor-pointer' alt="close popup"/>
    </div>
  )
}

export default Header