import { useState } from 'react'

// icons
import dropDownBlueIcon from '../../../../../../images/header popup icons/drop down.png'


const LinkedColumnValues = ({ linkedColRegContent, linkedColumnId, linkedDataType, searchQuery, filteredRows, saveRowData, onClose }) => {

  // state variables
  const [expanded, setExpanded] = useState(null)

  // local variables
  const { columns } = linkedColRegContent

  const getValue = (row) => {
    if (linkedDataType == 'image') {
      let noOfImages = row[linkedColumnId + '_details'].length
      return <div className='flex items-center gap-2 truncate'>
        {
          row[linkedColumnId + '_details'].map((item, i) => {
            if (i < 2) {
              return (
                <img key={`${item.value}-${i}`} src={item.value} className='h-8 rounded-sm mb-2 mt-1' />
              )
            }
          })
        }
        {
          noOfImages > 2 && `+ ${noOfImages - 2} more`
        }
      </div>
    }
    return row[linkedColumnId]
  }

  const getMatchedValueInRow = (row) => {
    const key = Object.keys(row).find((key) => {
      if (key != '-1' && key != 'rowId' && !key.includes('_details')) {
        return row[key].toString().toLowerCase().includes(searchQuery.toLowerCase())
      }
    })
    return row[key]
  }

  const onSelectBtnClick = (row) => {
    saveRowData(row)
    onClose()
  }

  return (
    <div className="space-y-4 my-4">

      {/* linked column value */}
      {filteredRows && filteredRows.map((row, index) => {
        return (
          <div key={row.rowId} className="px-2 rounded-lg" style={{ backgroundColor: '#F5F9FF' }}>
            <div className="px-3 py-2">

              <div className='flex justify-between items-center w-full'>

                {/* value, sl no */}
                <div className='grow overflow-hidden pr-2'>
                  <div className='font-semibold truncate'>{getValue(row)}</div>
                  <div className='flex items-center gap-5 cursor-pointer text-xs' onClick={() => setExpanded(expanded === row.rowId ? null : row.rowId)}>
                    <span className="font-medium underline" style={{ textUnderlineOffset: '4px' }}>Sl No. {row['-1']}</span>
                    <img src={dropDownBlueIcon} className={`h-4 transition-all delay-50 ${expanded === row.rowId ? 'rotate-180' : ''}`} />
                  </div>
                </div>
                {/* value, sl no */}

                {/* select button */}
                <button onClick={() => onSelectBtnClick(row)} className='secondary_btn px-4 py-1'>Select</button>
                {/* select button */}

              </div>

              {/* if searchQuery exists and matched value exists in a row, show matched result */}
              {
                searchQuery && getMatchedValueInRow(row) &&
                <div className='text-xs mt-2 truncate' style={{ color: '#C98643' }}>
                  <span>Matching Results: </span>
                  <span className='text-black'>{getMatchedValueInRow(row)}</span>
                </div>
              }

            </div>

            {/* Details of record in expanded view */}
            {expanded === row.rowId && (
              <div className="mt-2 slide_down_2 px-3 border-t border-gray-300 py-2" style={{}}>
                {columns && columns.map((column) => {
                  if (column.key === '-1' || column.key === linkedColumnId) {
                    return null;
                  }

                  return (
                    <div key={column.key} className="flex items-center my-2">
                      <div className="font-medium w-5/12 truncate">{column.name}</div>
                      <div className='w-7/12 text-black truncate'>{row[column.key]}</div>
                    </div>
                  );
                })}
              </div>
            )}
            {/* Details of record in expanded view */}

          </div>
        )
      })}
    </div>
  )
}

export default LinkedColumnValues