import { useEffect, useState, useRef } from 'react'
import ReactDOM, { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'

// utils
import ErrorBoundary from '../../../../../utils/ErrorBoundary'
import DeviceMeta from '../../../../../utils/DeviceDetector'

const TextEditor = ({ rowProperties, saveCurrentRowData, colIdx, columnEl }) => {
  // redux variables
  const registerData = useSelector(state => state.register)

  // local variables
  const colKey = columnEl.key
  const onClose = rowProperties.onClose
  const rowId = rowProperties.row.rowId
  const value = rowProperties.row[colKey]

  // state variables
  const [text, setText] = useState(value || '')
  const [pos, setPos] = useState({})
  const [dynamicWidth, setDynamicWidth] = useState('')

  // ref variables
  const textEditorDivRef = useRef(null)
  const textEditorInputRef = useRef(null)

  useEffect(() => {
    setDynamicWidth(textEditorDivRef.current.clientWidth)
    if (!DeviceMeta.isMobileOrTablet())
      // Sets position of the text box in portal w.r.t textEditorDivRef
      // using its ref.
      onScroll()
    // listeneing to table scroll represented by .rdg-light class
    document.querySelector(".rdg-light").addEventListener("scroll", onScroll)
    return () => {
      document.removeEventListener("scroll", onScroll)
    }
  }, []);

  useEffect(() => {
    let clientWidth = textEditorDivRef.current.clientWidth
    if (text.length * 7 > clientWidth) {
      setDynamicWidth(text.length + 'ch')
    } else {
      setDynamicWidth(textEditorDivRef.current.clientWidth)
    }
  }, [text, textEditorDivRef.current && textEditorDivRef.current.clientWidth || ''])

  const onScroll = () => {
    if (ReactDOM.findDOMNode(textEditorDivRef.current)) {
      setPos({
        right: ReactDOM.findDOMNode(textEditorDivRef.current)
          .getBoundingClientRect().right,
        left: ReactDOM.findDOMNode(textEditorDivRef.current)
          .getBoundingClientRect().left,
        top: ReactDOM.findDOMNode(textEditorDivRef.current)
          .getBoundingClientRect().top,
        bottom: ReactDOM.findDOMNode(textEditorDivRef.current)
          .getBoundingClientRect().bottom,
      })
    }
  }

  const clickHandler = () => {
    saveCurrentRowData(textEditorInputRef.current.value, colKey, rowId, registerData.rows)
  };

  const handleContainerDivKeyDown = (e) => {
    if (e.keyCode == 13 || e.keyCode === 9) {
      e.preventDefault()
      saveCurrentRowData(text, colKey, rowId, registerData.rows, false, false, null, e)
      onClose(true)
    }
  }

  return (
    <ErrorBoundary>
      <div ref={textEditorDivRef} className=" w-full h-full">
        {
          DeviceMeta.isMobileOrTablet() ?
            <div onKeyDown={handleContainerDivKeyDown} >
              <input
                ref={textEditorInputRef}
                name={colKey}
                type="text"
                placeholder="Enter text"
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="bg-white "
                style={{ fontSize: '12px', width: dynamicWidth }}
                autoFocus
                onBlur={clickHandler}
              />
            </div>
            :
            createPortal(
              <div onKeyDown={handleContainerDivKeyDown} >
                <input
                  ref={textEditorInputRef}
                  name={colKey}
                  type="text"
                  placeholder="Enter text"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  className="bg-white "
                  style={{ top: pos.top, bottom: pos.bottom, right: pos.right, left: pos.left, fontSize: '12px', position: 'fixed', width: dynamicWidth, height: textEditorDivRef.current && textEditorDivRef.current.clientHeight }}
                  autoFocus
                  onBlur={clickHandler}
                />
              </div>,
              document.body
            )}
      </div>
    </ErrorBoundary>
  )
}

export default TextEditor