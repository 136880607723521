import { useSelector } from 'react-redux'

// utils
import { ColMenuOptions } from '../../../Utils'

// icons
import cancelIcon from '../../../../../../images/header/cancel.png'

const ColumnMenuOptions = ({ showOptionsBox, colMenuPos, colKey, isFreezed, dataTypeSelected, onOptionClick }) => {

  // redux variables
  const registerData = useSelector(state => state.register)

  // local variables
  const { sortedColKey, sortDirection } = registerData

  const getOptionName = (index, name) => {
    let selectedDatatype
    if(dataTypeSelected.dataType == 'linkedSheet'){
      selectedDatatype = dataTypeSelected.linkedDataType
    } else selectedDatatype = dataTypeSelected.dataType
    if (index == 6) {
      return isFreezed ? name[1] : name[0]
    } else if (index == 4 || index == 5) {
      let numberDatatypes = ['phoneNumber', 'number', 'date', 'time', 'rupee', 'reminder', 'formula', 'attachment', 'image', 'createdAt', 'unit']
      if (numberDatatypes.includes(selectedDatatype)) {
        return name[1]
      } else return name[0]
    } else return name
  }

  const getOptionIcon = (item) => {
    if(sortedColKey == colKey){
      if(item.index == 4 && sortDirection == 1 || item.index == 5 && sortDirection == 2) return <img src={item.iconSorted} className="h-4" />
    }
    return <img src={item.icon} className="h-4" />
  }

  const getCancelIcon = (item) => {
    if (sortedColKey == colKey && (item.index == 4 && sortDirection == 1 || item.index == 5 && sortDirection == 2))
      return <img src={cancelIcon} className=' h-5' />
  }

  // classes
  const container = `absolute drop-shadow-2xl transition-all bg-white delay-100 ease-in slide_down_2 mt-1 p-0.5 z-20 ${showOptionsBox ? 'opacity-100' : 'opacity-0 pointer-events-none'}`
  const option = `transition-all z-100 delay-50 ease-out first:py-1 first:border-b last:py-1 last:border-t`

  // styles
  const containerStyles = { top: colMenuPos.top + 40, left: colMenuPos.left, width: '204px', borderRadius: '8px', boxShadow: '0px 0px 8.86239px rgba(0, 0, 0, 0.22)' }
  const triangleStyles = {
    borderTop: '5px solid white',
    borderBottom: '5px solid transparent', borderLeft: '5px solid transparent', borderRight: '5px solid white',
    boxShadow: '1px -1.5px 2px rgba(0, 0, 0, 0.08)', transform: 'rotate(-45deg)', top: '-4px',
    width: 0, height: 0, right: colMenuPos.triangleRight? colMenuPos.triangleRight: ''
  }

  return (
    <div
      className={container}
      style={containerStyles}
    >

      {/* Triangle */}
      <div
        className='absolute right-2.5 z-10'
        style={triangleStyles}>
      </div>
      {/* Triangle */}

      <ul>
        {
          ColMenuOptions.map((item, i) => {
            return (
              <li
                key={`ColMenuOptions-${i}`}
                className={option}
              >
                <div
                  className={`
                          px-4 flex gap-4 items-center py-2 my-2 columnMenuItem 
                          ${(item.index == 4 && sortedColKey == colKey && sortDirection == 1) ? '' : ''} 
                          ${(item.index == 5 && sortedColKey == colKey && sortDirection == 2) ? '' : ''}
                        `}
                  onClick={() => { onOptionClick(item.index) }}>

                  {getOptionIcon(item)}

                  {getOptionName(item.index, item.name)}

                  <div onClick={(e) => {e.stopPropagation(); onOptionClick(8)}} className='absolute right-4 z-10'>{getCancelIcon(item)}</div>

                </div>
              </li>
            )
          })
        }
      </ul>

    </div>
  )
}

export default ColumnMenuOptions