import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//components
import LinkedColumnPopUp from '../../../../../PopUps/LinkedSheet/LinkedColumn/index.js'
import CustomInputDropdown from '../../../../../../../partials/CustomInputDropdown'

// slices
import { fetchRegisterDataAncestry } from '../../../../../../../slices/RegisterSlice'

//icons
import linkedIcon from '../../../../../../../images/datatype_icons/light/linkedText.png'
import EditMenu from './EditMenu'
import ImageList from './ImageList.js'

const LinkedSheet = ({ colkey, column, handleChange, formData, deleteEntry, shouldAllowNewEntry }) => {

  const dispatch = useDispatch()

  // redux variables
  const registerData = useSelector(state => state.register)

  // state variables
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [showLinkedColPopup, setShowLinkedColPopup] = useState(false)
  const [linkedColRegContent, setLinkedColRegContent] = useState({})
  const [loading, setLoading] = useState(true)

  // local variables
  const { isAdmin, hasBasicEditPermission, editPermissions, columns } = registerData
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colkey) === -1
  const value = formData[colkey]
  const { linkedRegisterId, linkedSheetId, linkedDataType, linkedColumnId } = column

  const linkedRowIdArray = formData[colkey + '_details'] && formData[colkey + '_details'].filter((el) => {
    return Object.keys(el).includes('linkedRowId')
  })
  const linkedRowId = linkedRowIdArray && linkedRowIdArray[0] && linkedRowIdArray[0].linkedRowId

  useEffect(() => {
    const fetchLinkedRegisterData = async () => {
      try {
        const { payload: result } = await dispatch(fetchRegisterDataAncestry({ registerId: linkedRegisterId, sheetId: linkedSheetId }))
        setLinkedColRegContent(result)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
    fetchLinkedRegisterData()
  }, []);

  const onFieldClick = () => {
    if (value == '') setShowLinkedColPopup(true)
  }

  const closeDropdown = () => {
    setDropdownOpen(false)
  }

  const saveRowData = (row) => {
    let linkedRowIdForApi = row['rowId']
    let keysAndValues = { [colkey]: row[linkedColumnId], [colkey + '_details']: [...row[linkedColumnId + '_details'], { 'linkedRowId': linkedRowIdForApi }] }
    // columns linked to same register
    const additionalColsToUpdate = registerData.columns.filter((col) => {
      return col.linkedSheetId == linkedSheetId && linkedColumnId != col.linkedColumnId
    })
    // Include keys & values of additional cols
    additionalColsToUpdate.forEach((col) => {
      keysAndValues = { ...keysAndValues, [`${col.key}`]: row[col.linkedColumnId], [`${col.key}_details`]: [...row[col.linkedColumnId + '_details'], { 'linkedRowId': linkedRowIdForApi }] }
    })
    handleChange(keysAndValues)
  }

  return (
    <div className="mb-6">

      <div className="colName">
        <img src={linkedIcon} className="field_icon" />
        <label htmlFor={column.name}>{column.name}</label>
      </div>

      {
        !canEdit && !shouldAllowNewEntry &&
        <>

          {/* if linked datatype is image and images exist */}
          <ImageList
            linkedDataType={linkedDataType}
            colkey={colkey}
            formData={formData}
            setShowLinkedColPopup={setShowLinkedColPopup}
            closeDropdown={closeDropdown}
            deleteEntry={deleteEntry}
            showMenu={!canEdit && !shouldAllowNewEntry}
            linkedRegisterRows={linkedColRegContent.rows}
            linkedRowId={linkedRowId}
            column={column}
            value={value}
          />
          {/* if linked datatype is image and images exist */}

          {
            (linkedDataType !== 'image' || (formData[colkey + '_details'] && formData[colkey + '_details'].length == 0)) &&
            <div className="field">{value}</div>
          }

        </>
      }

      {
        (shouldAllowNewEntry || canEdit) &&
        <>

          {/* if linked datatype is image and images exist */}
          <ImageList
            linkedDataType={linkedDataType}
            colkey={colkey}
            formData={formData}
            setShowLinkedColPopup={setShowLinkedColPopup}
            closeDropdown={closeDropdown}
            deleteEntry={deleteEntry}
            showMenu={shouldAllowNewEntry || canEdit}
            linkedRegisterRows={linkedColRegContent.rows}
            linkedRowId={linkedRowId}
            column={column}
            value={value}
          />
          {/* if linked datatype is image and images exist */}

          {
            (linkedDataType !== 'image' || (formData[colkey + '_details'] && formData[colkey + '_details'].length == 0)) &&
            <CustomInputDropdown
              fieldValue={value}
              inputType={`div`}
              container={`form`}
              inputClasses={`field`}
              inputStyles={{}}
              dropdownClasses={``}
              dropdownStyles={{}}
              buttonsInfo={{ showButtonsSection: false }}
              showRemoveIcon={false}
              showDropDownMenu={dropdownOpen && value != ''}
              setShowDropDownMenu={setDropdownOpen}
              onFieldClick={onFieldClick}
            >
              <EditMenu
                deleteEntry={deleteEntry}
                closeDropdown={closeDropdown}
                colkey={colkey}
                setShowLinkedColPopup={setShowLinkedColPopup}
                linkedRegisterRows={linkedColRegContent.rows}
                column={column}
                value={value}
                linkedRowId={linkedRowId}
              />
            </CustomInputDropdown>
          }

        </>
      }

      {
        showLinkedColPopup &&
        <LinkedColumnPopUp
          columnEl={column}
          onClose={() => { setShowLinkedColPopup(false); setDropdownOpen(false) }}
          saveRowData={saveRowData}
          value={value}
          linkedColRegContent={linkedColRegContent}
          loading={loading}
        />
      }

    </div>
  )
}

export default LinkedSheet