import { useDispatch, useSelector } from 'react-redux'
import React, { useRef, useState, useEffect } from 'react'
import { useHistory, useLocation, withRouter } from 'react-router-dom';

// slices
import { fetchRegisterData, fetchAllRegisterData, updateRegisterState, renamePage } from '../../../../../slices/RegisterSlice'
import { removeRegister } from '../../../../../slices/LeftSidebarSlice'

// componets
import SelectedSheetMenu from './SelectedSheetMenu'
import PagesTabMobile from './PagesTabMobile'
import SelectedSheetMenuMobile from './SelectedSheetMenuMobile'

// utils
import { registerInitialStateArr } from '../../../../../utils/Utils'

// images
import activePage from '../../../../../images/activePage.png'
import inactivePage from '../../../../../images/inactivePage.png'
import cyanDropdownArrow from '../../../../../images/cyanDropdownArrow.png'
import enableLeftScrollArrow from '../../../../../images/enableLeftScrollArrow.png'
import enableRightScrollArrow from '../../../../../images/enableRightScrollArrow.png'
import disableLeftScrollArrow from '../../../../../images/disableLeftScrollArrow.png'
import disableRightScrollArrow from '../../../../../images/disableRightScrollArrow.png'

const SheetTabs = ({ sheetTabsDivRef, showScrollArrows }) => {

  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  // router variables
  const history = useHistory()
  const location = useLocation()

  // ref variables
  const activeSheetTabRef = useRef({})

  // state variables 
  const [showSheetMenu, setShowSheetMenu] = useState(false)
  const [editActiveSheetName, setEditActiveSheetName] = useState(false)
  const [sheetNameBeingEditd, setSheetNameBeingEditd] = useState('')
  const [sheetsTabRefs, setSheetsTabRefs] = useState('')
  const [scrollLeftVal, setScrollLeftVal] = useState(0)

  // local variables
  const { sheetId, pages, registerId, nextSheetInfo } = registerData
  const { pathname } = location;
  const activeBusinessId = pathname.split('/')[2];

  useEffect(() => {
    if (nextSheetInfo) {
      if (nextSheetInfo.sheetId) {
        dispatch(fetchAllRegisterData({ registerId, sheetId: nextSheetInfo.sheetId }))
      } else {
        dispatch(removeRegister({ registerId }))
        dispatch(updateRegisterState(registerInitialStateArr))
        history.replace(`/community/${activeBusinessId}`)
      }
      dispatch(updateRegisterState([{ key: 'nextSheetInfo', value: null }]))
    }
  }, [nextSheetInfo])

  useEffect(() => {
    let refs = pages.reduce((acc, val, i) => {
      acc[i] = React.createRef();
      return acc;
    }, {});
    console.log(" refs ", refs)
    setSheetsTabRefs(refs)
  }, [pages])

  const onSheetNameClick = (sheetInfo) => {
    dispatch(fetchAllRegisterData({ registerId, sheetId: sheetInfo.sheetId }));
    setShowSheetMenu(false)
  }

  const togglePageMenu = () => {
    setShowSheetMenu(!showSheetMenu)
  }

  const toggleEditSheetName = (sheetInfo) => {
    setEditActiveSheetName(!editActiveSheetName)
    if (editActiveSheetName) setSheetNameBeingEditd('')
    else setSheetNameBeingEditd(sheetInfo.sheetName)
  }

  const saveNewSheetName = (sheetInfo, sheetIdx) => {
    toggleEditSheetName()
    if (sheetNameBeingEditd.trim().length === 0) return
    dispatch(renamePage({
      sheetId: sheetInfo.sheetId,
      name: sheetNameBeingEditd,
      idxOfSheetWhoseNameIsBeingEdited: sheetIdx
    }))
  }

  const onLeftArrowClick = () => {
    if (scrollLeftVal === 0) return
    let totalLeft = 0
    for (var key in sheetsTabRefs) {
      if (sheetsTabRefs[key].current.getBoundingClientRect().left < sheetTabsDivRef.current.getBoundingClientRect().left) {
        totalLeft += sheetsTabRefs[key].current.getBoundingClientRect().width
        if (totalLeft >= sheetTabsDivRef.current.clientWidth) {
          if (totalLeft > sheetTabsDivRef.current.clientWidth) totalLeft = totalLeft - (totalLeft - sheetTabsDivRef.current.clientWidth)
          break;
        }
      }
    }
    if (totalLeft > 0) {
      sheetTabsDivRef.current.scrollBy({ left: `-${totalLeft}`, behavior: 'smooth' });
    }
  }

  const onRightArrowClick = () => {
    if (sheetTabsDivRef.current.scrollWidth === sheetTabsDivRef.current.offsetWidth + scrollLeftVal) return
    let totalLeft = 0
    for (var key in sheetsTabRefs) {
      if (sheetsTabRefs[key].current.getBoundingClientRect().right > sheetTabsDivRef.current.getBoundingClientRect().right) { // find the tabs not visible
        totalLeft += sheetsTabRefs[key].current.getBoundingClientRect().width // keep on adding the width of not visible tabs
        if (totalLeft >= sheetTabsDivRef.current.clientWidth) { // the above addition is done untill the total added width is just greater than or equal to the scroll container width
          if (totalLeft > sheetTabsDivRef.current.clientWidth) totalLeft = totalLeft - (totalLeft - sheetTabsDivRef.current.clientWidth) // this is done to get the exact with of the scroll container as that is used as the scroll length
          break;
        }
      }
    }
    if (totalLeft > 0) {
      sheetTabsDivRef.current.scrollBy({ left: totalLeft, behavior: 'smooth' });
    }
  }

  const checkDisableArrows = (e) => {
    setScrollLeftVal(e.target.scrollLeft)
  }

  return (
    <div className="flex justify-center items-center w-full h-full">

      {/* Mobile - Pages Menu */}
      <div className='sm:hidden h-full'>
        <PagesTabMobile
          editActiveSheetName={editActiveSheetName}
          sheetNameBeingEditd={sheetNameBeingEditd}
          setSheetNameBeingEditd={setSheetNameBeingEditd}
          saveNewSheetName={saveNewSheetName}
          toggleEditSheetName={toggleEditSheetName}
          onSheetNameClick={onSheetNameClick}
        />
      </div>
      {/* Mobile - Pages Menu */}

      <div onScroll={checkDisableArrows} ref={sheetTabsDivRef} className={`hidden sm:grid grid-flow-col auto-cols-max overflow-x-scroll no-scrollbar w-full h-full`}>
        {
          pages.map((p, i) => {
            return (
              p.sheetId === sheetId ?
                // active page
                <div
                  ref={node => { if (sheetsTabRefs[i]) sheetsTabRefs[i].current = node; activeSheetTabRef.current = node }}
                  key={`activePageTab/${p.sheetId}`}
                  className={`flex items-center bg-white cursor-pointer pr-1 ${editActiveSheetName ? 'pl-1' : 'pl-4'}`}
                  style={{ minWidth: '90px', maxWidth: '160px', borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
                >
                  {
                    editActiveSheetName ?
                      <input
                        type="text"
                        autoFocus
                        style={{ fontSize: 12, height: 20, minWidth: 'inherit', maxWidth: "140px" }}
                        value={sheetNameBeingEditd}
                        onChange={(e) => setSheetNameBeingEditd(e.target.value)}
                        onBlur={() => saveNewSheetName(p, i)}
                        size={sheetNameBeingEditd.length}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") saveNewSheetName(p, i)
                          if (e.key === "Tab") toggleEditSheetName()
                        }}
                      />
                      :
                      <>
                        <img src={activePage} style={{ height: 14 }} className="mr-1" alt="Active Page" />
                        <p className="relative truncate pr-2 text-sm font-medium" style={{ color: "#545454", top: 1 }}>{p.sheetName}</p>
                        <img onClick={togglePageMenu} className="cursor-pointer" src={cyanDropdownArrow} style={{ height: 14 }} alt="Cyan Drop Down Arrow" />
                      </>
                  }
                  {
                    showSheetMenu &&
                    <SelectedSheetMenu
                      activeSheetTabRef={activeSheetTabRef}
                      setShowSheetMenu={setShowSheetMenu}
                      toggleEditSheetName={toggleEditSheetName}
                      sheetIdx={i}
                      sheetInfo={p}
                    />
                  }
                </div>
                :
                // inactive page
                <div ref={sheetsTabRefs[i]} key={`inActivePageTab/${p.sheetId}`} onClick={() => onSheetNameClick(p)} className="flex items-center cursor-pointer pr-1 pl-4" style={{ width: '100px' }}>
                  <img src={inactivePage} style={{ width: 14, height: 14 }} className="mr-1" alt="Inactive Page" />
                  <p className="relative truncate" style={{ color: "#B9D9FF", fontSize: 12, top: 1 }}>{p.sheetName}</p>
                </div>
            )
          })
        }
      </div>
      {
        showScrollArrows &&
        <div className="hidden sm:flex justify-center items-center ml-4 mr-1">
          <img onClick={onLeftArrowClick} className={`${scrollLeftVal === 0 ? "cursor-not-allowed" : "cursor-pointer"} mr-2`} src={scrollLeftVal === 0 ? disableLeftScrollArrow : enableLeftScrollArrow} style={{ width: '10px', height: '12px' }} alt="Cyan Left Scroll Button" />
          <img onClick={onRightArrowClick} className={`${sheetTabsDivRef.current.scrollWidth === sheetTabsDivRef.current.offsetWidth + scrollLeftVal ? "cursor-not-allowed" : "cursor-pointer"} ml-2`} src={sheetTabsDivRef.current.scrollWidth === sheetTabsDivRef.current.offsetWidth + scrollLeftVal ? disableRightScrollArrow : enableRightScrollArrow} style={{ width: '10px', height: '12px' }} alt="Cyan Right Scroll Button" />
        </div>
      }

      {/* 3 dot menu - selected sheet menu - mobile */}
      <SelectedSheetMenuMobile
        toggleEditSheetName={toggleEditSheetName}
      />
      {/* 3 dot menu - selected sheet menu - mobile */}
      
    </div>
  )
}

export default withRouter(SheetTabs)

