import { createPortal } from 'react-dom'

const PortalPopup = ({ children, onClose, containerClass, containerStyle}) => {
  return (
    createPortal(
      <div
        className={`child-in-center absolute h-screen top-0 bottom-0 left-0 w-full`}
        style={{ zIndex: '100' }}
      >
        <div
          className={`absolute h-screen top-0 bottom-0 left-0 w-full`}
          style={{ background: 'rgba(0,0,0,0.4)' }}
          onClick={onClose}
        >
        </div>

        <div
          className={`slide_down shadow-md transform transition-all duration-200 ease-out ${containerClass}`}
          style={containerStyle}
        >
          {children}
        </div>

      </div>,
      document.body
    )
  )
}

export default PortalPopup