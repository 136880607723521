import CreatePasswordContainer from "../../features/SignUp/Components/SignUpUsingEmail/CreatePasswordContainer"

const CreatePassword = () => {

  return (
    <div className="h-screen w-full" style={{ minHeight: '650px' }}>
      <CreatePasswordContainer />
    </div>
  )
}

export default CreatePassword