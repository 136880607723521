// import RegisterBanner from '../features/banner/RegisterBanner';
import RegisterSheet from '../../features/Register';
import React from 'react'


function Register () {


  return (
    <React.Fragment>
        <RegisterSheet />
    </React.Fragment>
  )
}

export default Register
