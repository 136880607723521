import { useSelector } from 'react-redux'

//images
import addIcon from '../../../../../images/right-sidebar/add.svg'
import dotsIcon from '../../../../../images/right-sidebar/3-dots.png'

const Participants = () => {

  //redux variables
  const rightSidebarData = useSelector(state => state.rightSidebar)

  // local variables
  const { teamMembers } = rightSidebarData
  const participantsCountStr = `${teamMembers.length} Participants`

  return (
    <div className="pt-4 ml-0.5 h-full">
      <div className='px-4 pt-3 h-full bg-white overflow-auto'>
        <div className='pb-2 border-b' style={{ color: '#23C558' }}>{participantsCountStr}</div>
        {/*
        <div className='flex py-2 gap-3 text-lg border-b cursor-pointer'>
          <img src={addIcon} className='h-8' />
          Add Participants
        </div>
        */}

        <ul>
          {teamMembers && teamMembers.map((member, i) => {
            return (
              <li key={i} className='group flex justify-between items-center border-b'>
                <div className='flex gap-3 py-2'>
                  <img className='h-8 w-8 rounded-full' src={member.profilePic} />
                  {member.name}
                </div>
                <div>
                  {/*<img src={dotsIcon} className='transform transition-all scale-100 delay-50 opacity-0 hover:scale-125 group-hover:opacity-100 w-4 cursor-pointer' />*/}
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default Participants