import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// components
import ContactSupport from '../ContactSupport'

// slices
import { verifyPassword, updateLoginInfoState } from '../../../../slices/LoginSlice'

// icons
import logo from '../../../../images/logo.png'
import happyFace from '../../../../images/login/happy-face.png'
import closeEye from '../../../../images/login/close-eye.png'
import openEye from '../../../../images/login/open-eye.png'
import { Redirect } from 'react-router-dom'

const ResetPasswordContainer = () => {

  // redux variales
  const dispatch = useDispatch()
  const loginInfo = useSelector(state => state.loginInfo)

  // router variables
  const searchParams = new URLSearchParams(window.location.search)

  // state vaiables
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [goToHomePage, setGoToHomePage] = useState(false)

  // local variables
  const { isLoading, error, errorMessage } = loginInfo
  const emailId = searchParams.get('emailId')
  const token = searchParams.get('token')
  const isDisabled = !password || !confirmPassword

  const onPasswordChange = (e) => {
    removeError()
    setPassword(e.target.value)
  }

  const onConfirmPasswordChange = (e) => {
    removeError()
    setConfirmPassword(e.target.value)
  }

  const togglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword)
  }

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(prevShowConfirmPassword => !prevShowConfirmPassword)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      dispatch(updateLoginInfoState([{ key: 'error', value: true }, { key: 'errorMessage', value: 'The passwords do not match' }]))
    } else if (password.length < 8) {
      dispatch(updateLoginInfoState([{ key: 'error', value: true }, { key: 'errorMessage', value: 'The password should be at least 8 characters long' }]))
    } else {
      dispatch(verifyPassword({ password, confirmPassword, emailId, token }))
    }
  }

  const onLogoClick = () => {
    removeError()
    setGoToHomePage(true)
  }

  const removeError = () => {
    if (error) {
      dispatch(updateLoginInfoState([{ key: 'error', value: false }, { key: 'errorMessage', value: null }]))
    }
  }

  // classes
  const containerClass = `overflow-hidden px-8 py-16 h-full flex flex-col justify-between text-center`
  const inputContainerClass = `relative h-14 w-80 max-w-full mx-auto flex items-center my-4`
  const inputClass = `rounded-2xl h-full w-full max-w-full pl-5 pr-10 bg-transparent grayPlaceholder font-medium`
  const eyeClass = `absolute right-4 w-5 cursor-pointer`

  // styles
  const containerStyle = { backgroundColor: '#F8F9FA', border: '1px solid #CED4DA', borderRadius: '40px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05)', width: '600px', maxWidth: '95%', height: '95%' }
  const buttonContainer = `child-in-center mx-auto rounded-2xl h-14 w-80 max-w-full gap-2 my-2 font-medium`

  // validate emailId and token
  if (!emailId || !token) {
    return <div>Invalid URL. Please check your email and try again.</div>
  }

  if (goToHomePage) {
    return (
      <Redirect to='/' />
    )
  }

  return (
    <div className={containerClass} style={containerStyle}>

      <div className=''><img src={logo} onClick={onLogoClick} className='mx-auto h-8 cursor-pointer' alt="wyse-logo" /></div>

      <div>
        <div className="font-bold text-3xl mb-3" style={{ color: '#22252A' }}>
          Hurray, You’re in!!
          <img src={happyFace} className='h-6 inline ml-2' />
        </div>
        <div className="font-medium my-2 px-0 sm:px-4 md:px-16 lg:px-24" style={{ color: '#6C757D' }}>
          Please change your password so that you don’t get locked outside unlike this time.
        </div>

        <form onSubmit={handleSubmit} className='my-12'>

          <div className={inputContainerClass}>
            <input
              type={showPassword ? 'text' : 'password'} value={password} onChange={onPasswordChange}
              required className={inputClass} style={{ border: '1px solid #ACB5BD' }} placeholder='Enter New Password'
            />
            <img
              onClick={togglePasswordVisibility}
              src={showPassword ? openEye : closeEye}
              className={eyeClass}
              loading="lazy"
            />
          </div>


          <div className={inputContainerClass}>
            <input
              type={showConfirmPassword ? 'text' : 'password'} value={confirmPassword} onChange={onConfirmPasswordChange}
              required className={inputClass} style={{ border: '1px solid #ACB5BD' }} placeholder='Confirm New Password'
            />
            <img
              onClick={toggleConfirmPasswordVisibility}
              src={showConfirmPassword ? openEye : closeEye}
              className={eyeClass}
              loading="lazy"
            />
          </div>


          {/* error msg */}
          {error && <p className="my-3 font-medium" style={{ color: '#F5291C', fontSize: '15px' }}><span>{errorMessage}</span></p>}
          {/* error msg */}

          <button type="submit" className={`${buttonContainer} ${isDisabled ? 'cursor-not-allowed' : ''} text-white my-6`} style={{ backgroundColor: isDisabled ? '#DDDDDD' : '#35A600' }}>
            {
              isLoading ? (
                <div className="animate-spin rounded-full h-7 w-7 border-b-2 border-gray-100 m-auto "></div>
              ) : (
                <span>Change Password</span>
              )
            }
          </button>

        </form>
      </div>

      <ContactSupport />

    </div>
  )
}

export default ResetPasswordContainer