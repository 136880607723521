import { useEffect } from "react"

const Dropdown = ({ children, closeDropdownCallback, containerClasses }) => {

  return (
    <div>
      <div className="fixed w-full h-full z-10 inset-0" onClick={closeDropdownCallback}></div>
      <div
        className={`slide_down_2 absolute bg-white left-0 top-full z-30 cursor-default ${containerClasses}`}
        style={{ filter: 'drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.08)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15))', borderRadius: '8px' }}
      >

        {children}
      </div>
    </div>

  )

}

export default Dropdown