// icons
import defaultIcon from '../../../../../../images/datatype_icons/light/text.png'

const Default = ({ colkey, column, handleChange, formData }) => {
  return (
    <div className="mb-6">
      <div className="colName">
      <img className="field_icon" src={defaultIcon} alt="" />
        <label htmlFor={column.name}>{column.name}</label>
      </div>
      <div className="field_container">
        <div className="field non_editable cursor-not-allowed">{formData[colkey]}</div>
      </div>

    </div>
  )
}

export default Default