import React from 'react';
import { useSelector } from 'react-redux'

// images
import welcomeBgdImg from '../../../../images/welcomeBgdImg.png'
import rkBookLogo from '../../../../images/rkBookLogo.png'

function WelcomeBanner() {

  //redux variables
  const leftSidebarData = useSelector(state => state.leftSidebar)
  const loginInfo = useSelector(state => state.loginInfo);

  //local variables
  const { registers } = leftSidebarData
  const { manifestJsonDetail, isBrandLoading } = loginInfo

  return (
    <div className="relative grid items-center place-items-center h-full">
      {/*Background image as the bhind layer starts*/}
      <img className="absolute" src={welcomeBgdImg} style={{ height: '70%', width: '60%' }} />
      {/*Background image as the bhind layer ends*/}

      {/*Welcome text with RK logo starts*/}
      {
        !isBrandLoading &&
        <div className="absolute flex flex-col  items-center " style={{ height: '70%', width: '60%', top: '28%' }}>
          <img className="mb-2" src={manifestJsonDetail ? manifestJsonDetail.icons[0].src : rkBookLogo} style={{ height: 110, width: 110 }} />
          <span className="text-lg md:text-2xl lg:text-3xl text-gray-800  mb-1">Welcome to {manifestJsonDetail ? manifestJsonDetail.short_name : 'RecordBook'}</span>
          <p className="text-sm md:text-xl lg:text-xl">Tap on your {registers && registers.length === 1 ? 'register' : 'registers'} to start:</p>
        </div>
      }

      {/*Welcome text with RK logo ends*/}
    </div>
  );
}

export default WelcomeBanner;
