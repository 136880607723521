
import { useDispatch, useSelector } from 'react-redux'

// slices
import { toggleDialogOpen, updateBody, updateHeaderText } from '../../../../../slices/DialogSlice'

// components
import AddPagePopUpBody from '../RegisterInfoHeader/AddPagePopUpBody'

// images
import addSheetCyan from '../../../../../images/addSheetCyan.png'

const AddSheetPlusBtn = () => {
  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  // local variables
  const { permissions } = registerData
  const isAdmin = permissions.includes("admin")

  const showAddNewPagePopUp = () => {
    if (!isAdmin) {
      alert("Permission Denied")
      return
    }
    dispatch(toggleDialogOpen(true))
    dispatch(updateBody(<AddPagePopUpBody closeDiaglog={() => dispatch(toggleDialogOpen(false))} />))
    dispatch(updateHeaderText('Add Page'))
  }


  return (
    <div className="flex justify-center items-center ml-2">
      <img onClick={showAddNewPagePopUp} className="cursor-pointer" src={addSheetCyan} style={{ height: 16 }} alt="Add Sheet Cyan Button" />
    </div>
  )
}

export default AddSheetPlusBtn