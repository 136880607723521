import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AttachmentDialog1 from './AttachmentDialog1'
import AttachmentDialog2 from './AttachmentDialog2'
import { toggleDialogOpen, updateBody, updateHeaderText } from '../../../../../../slices/DialogSlice';

// utils
import ErrorBoundary from '../../../../../../utils/ErrorBoundary'

const AttachmentEditor = ({ rowProperties, saveCurrentRowData, colIdx, columnEl }) => {
  // redux variables 
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  // local variables
  const colKey = columnEl.key
  const onClose = rowProperties.onClose
  const rowIndex = rowProperties.rowIdx
  const rowId = rowProperties.row.rowId
  const attachments = rowProperties.row[(colKey) + "_details"] ? rowProperties.row[(colKey) + "_details"] : []
  const columnName = registerData.columns[colIdx]? registerData.columns[colIdx].name: ''

  useEffect(() => {
    if (attachments.length) {
      dispatch(toggleDialogOpen(true))
      dispatch(updateHeaderText(columnName))
      dispatch(updateBody(<AttachmentDialog2 attachments={attachments} onAttachmentChange={onAttachmentChangeCallback} onAttachmentDelete={onAttachmentDeleteCallback} />))
    } else if (attachments.length === 0) {
      dispatch(toggleDialogOpen(true))
      dispatch(updateHeaderText(columnName))
      dispatch(updateBody(<AttachmentDialog1 onAttachmentChange={onAttachmentChangeCallback} />))
    }
  }, [])

  const onAttachmentChangeCallback = (attachmentArr) => {
    let newAttachmentArray = []
    attachmentArr.forEach((el) => {
      newAttachmentArray.push({ value: el.url })
    })
    saveCurrentRowData(newAttachmentArray, `${colKey}_details`, rowId, registerData.rows)
    dispatch(toggleDialogOpen(false))
    dispatch(updateHeaderText(''))
    onClose(true)
  }

  const onAttachmentDeleteCallback = (attachmentArr) => {
    let newAttachmentArray = []
    attachmentArr.forEach((el) => {
      newAttachmentArray.push({ value: el.url })
    })
    saveCurrentRowData(newAttachmentArray, `${colKey}_details`, rowId, registerData.rows)
  }

  return (
    <ErrorBoundary>
      <div className=" w-full h-full"></div>
    </ErrorBoundary>
  )
}

export default AttachmentEditor
