const EditContainer = ({ url, caption, inputTextKeyDown, urlOnchange, captionOnChange, showUrlError, showCaptionError }) => {
  return (
    <div className="flex flex-col gap-2 w-full h-full px-2 py-2" >
      <div className="flex flex-col grid-cols-1 space-y-2 justify-center w-full">
        Url:
        <input
          tabIndex="0"
          className="rounded placeholder-grey-500"
          style={{ ...styles.inputTxt, border: showUrlError ? '1px solid red' : 'none' }}
          type="text"
          placeholder="Enter Url here"
          value={url}
          onKeyDown={inputTextKeyDown}
          onChange={urlOnchange}
        />
        Caption:
        <input
          tabIndex="0"
          className="rounded placeholder-grey-500"
          style={{ ...styles.inputTxt, border: showCaptionError ? '1px solid red' : 'none' }}
          type="text"
          placeholder="Caption"
          value={caption}
          onKeyDown={inputTextKeyDown}
          onChange={captionOnChange}
        />
      </div>
    </div>
  )
}

export default EditContainer

const styles = {
  cancelBtn: { fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 'normal', fontSize: '12px', lineHeight: '24px', letterSpacing: '-0.006em', color: '#454545' },
  saveBtn: { backgroundColor: '#586C9E', fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 'normal', fontSize: '12px', lineHeight: '24px', letterSpacing: '-0.006em' },
  inputTxt: { height: '40%', fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 'normal', fontSize: '14px', lineHeight: '24px' }
}