import { useState } from 'react'
import { useSelector } from 'react-redux'

//components
import CustomDropdown from '../../../../../partials/CustomDropdown.js'

// images
import greySearchIcon from '../../../../../images/greySearchIcon.png'

const SelectColumnForFormula = ({ selectedColIdx, changeFormulaStrArr }) => {

  // redux variable
  const registerData = useSelector(state => state.register)

  // local variables
  const columnsStoreCpy = registerData.columns.filter(el => el.key !== '-1' && el.key !== 'addColPlusButton')
  const currentColKey = registerData.columns[selectedColIdx].key

  // state variables
  const [columns, setColumns] = useState(columnsStoreCpy)

  const onChange = (e) => {
    const searchedColumns = columns.filter(el => {
      const columnName = el.name.toLowerCase()
      const userSearchedName = e.target.value.toLowerCase()
      return columnName.includes(userSearchedName)
    })
    if (e.target.value.trim().length === 0) setColumns(columnsStoreCpy)
    else setColumns(searchedColumns)
  }

  const onColumnClick = (el) => {
    changeFormulaStrArr({
      string: el.name,
      type: 'column',
      key: currentColKey === el.key ? `$${el.key}` : el.key // if user selects
    })
  }

  return (
    <>
      <div className="sm:hidden">
        <MobileView onChange={onChange} columns={columns} onColumnClick={onColumnClick} currentColKey={currentColKey} />
      </div>
      <div className='hidden sm:block h-full'>
        <WebView onChange={onChange} columns={columns} onColumnClick={onColumnClick} currentColKey={currentColKey} />
      </div>
    </>
  )
}

export default SelectColumnForFormula

const MobileView = ({ onChange, columns, onColumnClick, currentColKey }) => {
  return (
    <div className="flex relative cursor-pointer my-3">
      <CustomDropdown
        menuContainerStyle={{ top: '100%', height: '14rem' }}
        dropdownContainerStyle={{border: '1px solid rgba(152, 171, 201, 0.3)', borderRadius: '38px', backgroundColor: '#FAFCFF', color:  '#999999', fontSize: '14px'}}
        items={columns}
        displayKey={'name'}
        onSelect={onColumnClick}
        allowSelectItem={'NONE'}
        noneText={'Select Column'}
      />
    </div>
  )
}

const WebView = ({ onChange, columns, onColumnClick, currentColKey }) => {
  return (
    <>
      <div className="flex items-center pt-4" style={{ height: '10%', color: '#576D9E', fontWeight: '400' }}>
        Select Column
      </div>
      <div className="flex  relative pt-2" style={{ height: '12%' }}>
        <span className="flex justify-center items-center" style={{ position: 'absolute', width: '10%', top: '30%' }}>
          <img src={greySearchIcon} />
        </span>
        <input type="text" onChange={onChange} placeholder="Search column" className="w-full" style={{ border: '1px solid #B6B6B6', borderRadius: 8, fontSize: 12, paddingLeft: 25, height: '25px' }} />
      </div>
      <div className="flex flex-col py-2 px-2 overflow-y-auto" style={{ backgroundColor: '#F5FAFF', height: '78%', borderRadius: 8 }}>
        {columns.map(el => (
          <span
            onClick={() => onColumnClick(el)}
            className="flex justify-center items-center w-full mb-1 cursor-pointer bg-white hover:bg-gray-300 p-2"
          >
            <p className="truncate">{currentColKey === el.key ? `${el.name} (previous row)` : el.name}</p>
          </span>
        ))}
      </div>
    </>
  )
}







