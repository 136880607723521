import { useDispatch, useSelector } from "react-redux"

//slices
import { fetchAllBusiness, toggleLeftSidebarOpen } from "../../../../slices/LeftSidebarSlice"
import { updateRegisterState } from "../../../../slices/RegisterSlice"

//icons and images
import logo from '../../../../images/left-sidebar/logo.svg'
import dropdownIcon from '../../../../images/left-sidebar/dropdown.png'

// utils
import { registerInitialStateArr } from '../../../../utils/Utils'

const Businesses = ({ history, businessDropdownOpen, setBusinessDropdownOpen }) => {

  // redux variables
  const dispatch = useDispatch()
  const leftSidebarData = useSelector(state => state.leftSidebar)
  const themeData = useSelector(state => state.theme)

  // local varibles
  const { activeBusinessId, activeBusinessName, businessList } = leftSidebarData
  const { darkGrey } = themeData

  const closeLeftSidebarOnBackButton = () => dispatch(toggleLeftSidebarOpen(false))

  const onCommunityNameClick = async (business) => {
    const businessId = business.businessId
    setBusinessDropdownOpen(false)
    history.push(`/community/${businessId}`)
    dispatch(updateRegisterState(registerInitialStateArr))
  }

  const onCommunityDropdownBtnClick = (e) => {
    e.stopPropagation() // this is done so that clickHandler for businessDropdownOpen is not called and is a temporaryFix
    if (businessList.length === 0) dispatch(fetchAllBusiness())
    setBusinessDropdownOpen(!businessDropdownOpen)
  }

  //classes
  const communityDropdownContainer = `businesses_scrollbar absolute bg-white rounded shadow-md top-3/4 mt-1 z-20 w-full max-h-96 overflow-auto border border-gray-300 transform transition-transform transition-opacity duration-200 ease-out ${businessDropdownOpen ? 'translate-y-0  opacity-100' : '-translate-y-2 opacity-0 pointer-events-none'}`
  const communityListItem = `border-b truncate text-center p-1 py-4 text-sm cursor-pointer`
  const communityDropdownBtn = `w-5 cursor-pointer transform transition-all delay-50 ease-in ${businessDropdownOpen ? 'rotate-180 rounded' : 'rotate-0'}`

  return (
    <div className="border-b bg-white h-full relative">

      <div className="flex items-center justify-between h-full p-4">
        {/* Close button starts */}
        <button
          className="absolute top-0.5 left-0.5 hidden xs:block lg:hidden text-gray-500 hover:text-gray-400"
          onClick={closeLeftSidebarOnBackButton}
        >
          <span className="sr-only">Close sidebar</span>
          <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
          </svg>
        </button>
        {/* Close button ends */}

        <div className="flex w-10/12 items-center gap-2">
          <img src={logo} className='h-8' />
          <h1 onClick={onCommunityDropdownBtnClick} className="grow truncate cursor-pointer font-semibold" style={{ color: darkGrey }}>{activeBusinessName}</h1>
        </div>
        <img onClick={onCommunityDropdownBtnClick} src={dropdownIcon} className={communityDropdownBtn} style={{backgroundColor: businessDropdownOpen? '#ECF0FE': ''}} />
      </div>


      {/* Community dropdown starts */}
      <div className={communityDropdownContainer}>
        <ul>
          {
            businessList.map((business, i) => {
              return (
                <li
                  key={`${business.name}-${i}`}
                  onClick={()=>onCommunityNameClick(business)}
                  className={`${communityListItem} ${business.businessId == activeBusinessId? 'selectedBusinessElement': 'businessElement'}`}
                >
                  {business.name}
                </li>
              )
            })
          }
        </ul>

      </div>
      {/* Community dropdown starts */}
    </div>
  )
}

export default Businesses