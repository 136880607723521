import React, { useEffect, useRef, useState } from 'react';
import ReactDOM, { createPortal } from 'react-dom';
import { useSelector } from 'react-redux'

// css
import './rowEditors.css';

// images
import deleteIcon from '../../../../../images/add-edit row dialog/form icons/delete.png';

// utils
import ErrorBoundary from '../../../../../utils/ErrorBoundary'

const SwitchEditor = ({ rowProperties, saveCurrentRowData, colIdx, columnEl }) => {
  // redux variables
  const registerData = useSelector(state => state.register)

  // local variables
  const colKey = columnEl.key
  const onClose = rowProperties.onClose
  const rowIndex = rowProperties.rowIdx
  const rowId = rowProperties.row.rowId
  const value = rowProperties.row[colKey]

  // state variables
  const [pos, setPos] = useState({})
  const [dropdownOpen, setDropdownOpen] = useState(false)

  // ref variables
  const dropdown = useRef(null)
  const dropdownTriggerBtnRef = useRef(null)

  useEffect(() => {
    // as soon as the editor is rendered dropdown should open
    setDropdownOpen(true);
    // Sets position of the dropdown in portal w.r.t the drop down trigger button
    // using its ref.
    // listeneing to table scroll represented by .rdg-light class
    document.querySelector(".rdg-light").addEventListener("scroll", onScroll)
    document.addEventListener('keydown', keyHandler);
    return () => {
      document.removeEventListener("scroll", onScroll)
      document.removeEventListener('keydown', keyHandler);
    }
  }, []);

  useEffect(() => {
    if (dropdownOpen) onScroll()
  }, [dropdownOpen])

  const keyHandler = (e) => {

    // tab key press
    if (e.keyCode == 9) {

      // 1) Initially when the cell is opened then the focus is on the cell having the class rdg-focus-sink.
      // 2) After tabbing on the last element of status dropdown we bring focus back to switch-editor-container-div,
      //    Hence as it has tabIndex value is -1 thus, automatically the focus shifs to the very next element having tabIndex 0,1 etc.
      //    Here the div to which the focus shifts is dropdownTriggerBtnRef having class sheetItem1 which consists the pre selected option.
      //
      //    So now when tab is pressed again the focus should shift to the first status option and for that we press tab.
      //    Now the focus shifts to switch-editor-dropdown-portal-container div which has tabIndex -1 so now, automatically the focus gets removed from it
      //    and shifts to the very next element with positive tabIndex ie., selection-one-dropdown-option-1 anchor tag.
      if (document.activeElement.classList.contains('rdg-focus-sink') || document.activeElement.classList.contains('sheetItem1')) {
        document.getElementById(`switch-editor-dropdown-portal-container`).focus();
      }

      // When the last element is focused by tabbing and we press tab again,
      // the focus should return back to the pre selected cell.
      // We shift the focus on switch-editor-container-div as it has tabIndex -1.
      // Due to this value of tab index the focus automatically moves to the very next element with a positive
      // tabIndex.
      if (document.activeElement.id === 'switch-editor-remove-entry-anchor') {
        document.getElementById('switch-editor-container-div').focus();
      }
    }
    if (!dropdownOpen || e.keyCode !== 27) return;
    setDropdownOpen(false);
  };

  const onScroll = () => {
    if (dropdown.current) {
      let totalDropdownHeight = 10
      dropdown.current.childNodes.forEach((el) => {
        totalDropdownHeight = totalDropdownHeight + parseInt(el.clientHeight)
      })
      let positionTop = 0
      if (dropdownTriggerBtnRef.current.getBoundingClientRect().bottom + dropdownTriggerBtnRef.current.clientHeight + totalDropdownHeight >= window.innerHeight) {
        positionTop = positionTop - totalDropdownHeight
      } else {
        positionTop = 40
      }
      const refValue = ReactDOM.findDOMNode(dropdownTriggerBtnRef.current)
      if (refValue) {
        setPos({
          right: refValue
            .getBoundingClientRect().right,
          left: refValue
            .getBoundingClientRect().left,
          top: refValue
            .getBoundingClientRect().top + positionTop,
          bottom: refValue
            .getBoundingClientRect().bottom,
        })
      }
    }

  }

  const handleClick = (e) => {
    e.preventDefault()

    // If a person clicks pre seleceted option, 
    // then we should close the dropdown (setDropdownOpen) and thus the editor ( onClose )
    setDropdownOpen(!dropdownOpen)
    if (dropdownOpen === true) onClose(true)
  }

  const handleOptionClick = (option) => {
    saveCurrentRowData(option, colKey, rowId, registerData.rows)
    setDropdownOpen(false)
  }

  // handles key events of the editor container div
  const handleContainerDivKeyDown = (e) => {

    // enter key press
    if (e.keyCode == 13) {

      // Trigger click of the active element on which the focus is.
      document.activeElement.click();
      onClose(true)
    }
  }

  return (
    <ErrorBoundary>
      <div className="w-full h-full" tabIndex="-1" id="switch-editor-container-div" onKeyDown={handleContainerDivKeyDown}>
        {/*Pre select value containing div starts here*/}
        <div ref={dropdownTriggerBtnRef} tabIndex="0" onClick={handleClick} className="sheetItem1 focus:bg-gray-600"><span className="px-2" style={{ backgroundColor: 'white' }}>{value || ''}</span></div>
        {/*Pre select value containing div ends here*/}
        {/*Portal starts here*/}
        {dropdownOpen && createPortal(
          <div
            ref={dropdown}
            id="switch-editor-dropdown-portal-container"
            tabIndex="-1"
            style={{
              top: pos.top,
              bottom: pos.bottom,
              right: pos.right,
              left: pos.left,
              fontSize: '12px',
              position: 'absolute',
              width: dropdownTriggerBtnRef.current.clientWidth
            }}
          >
            {/*Options in the form of anchor and non active button starts here*/}
            <a tabIndex="0" id={'selection-one-dropdown-option-0'} key={'selection-one-dropdown-option-0'} className="flex items-center gap-4 leading-8 cursor-pointer bg-white border-4" onClick={(e) => { e.preventDefault(); handleOptionClick('Yes') }}>
              <span tabIndex={-1} className="text-black w-full text-left pl-5 p-2 transition-colors delay-50 hover:bg-gray-600" >
                Yes
              </span>
            </a>
            <a tabIndex="0" id={'selection-one-dropdown-option-1'} key={'selection-one-dropdown-option-1'} className="flex items-center gap-4 leading-8 cursor-pointer bg-white border-4" onClick={(e) => { e.preventDefault(); handleOptionClick('No') }}>
              <span tabIndex={-1} className="text-black w-full text-left pl-5 p-2 transition-colors delay-50 hover:bg-gray-600" >
                No
              </span>
            </a>
            <a tabIndex="0" id={`switch-editor-remove-entry-anchor`} key="switch-editor-remove-entry-anchor" className="flex items-center gap-4 leading-8 cursor-pointer bg-white border-4" onClick={(e) => { e.preventDefault(); saveCurrentRowData('', colKey, rowId, registerData.rows); setDropdownOpen(false) }}>
              <button tabIndex={-1} className="text-black w-full flex pl-1 items-center transition-colors delay-50 hover:bg-gray-600" onClick={() => { }}>
                <img src={deleteIcon} className="h-5 pr-2" alt="" />Remove Entry
              </button>
            </a>
            {/*Options in the form of anchor and non active button ends here*/}
          </div>,
          document.body)}
        {/*Portal ends here*/}
      </div>
    </ErrorBoundary>
  )
}

export default SwitchEditor;