import { useEffect, useState } from 'react'
import axios from 'axios'

import imageIcon from '../images/dialog/image/ImageIconWhite.png'

const CustomProgressBar = ({
  url,
  files,
  communityId,
  getUploadedFilesClbk,
  progressDirection,
  liveUploadCountClbk,
  showFileInfo,
  outerContainerStyle,
  outerContainerClasses,
  progressBarStyle,

}) => {
  const [convertedFileArr, setConvertedFileArr] = useState([])
  const [uploadedFiles, setUploadedFiles] = useState([])

  const getStyles = (el) => {
    return {
      width: progressDirection === 'horizontal' ? `${el.uploadProgress}%` : '100%',
      height: progressDirection === 'vertical' ? `${el.uploadProgress}%` : '100%',
      ...progressBarStyle
    }
  }

  const uploadFunc = async () => {
    let duplicateFilesUploadedArr = [...convertedFileArr]
    let finishCallCount = 0;
    duplicateFilesUploadedArr.forEach(async (fileEl, index) => {
      let formaData = new FormData();
      formaData.append(url.includes('uploadImage') ? 'image' : 'attach', fileEl, `${fileEl.lastModified}`+fileEl.name)
      formaData.append('communityId', JSON.stringify(communityId ? communityId : ''))
      const response = await axios.post(url, formaData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress(progressEvent) {
          let uploadedFilesCopy = [...uploadedFiles]
          // Placed here as every time when progress of any file happens then
          // previous progress of other files should also be taken into account.
          uploadedFilesCopy[index].uploadProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
          setUploadedFiles(uploadedFilesCopy)
        }
      }).then((response) => {
        let uploadedFilesCopy = [...uploadedFiles]
        uploadedFilesCopy[index].url = response.data.message
        uploadedFilesCopy[index].success = response.data.success
        setUploadedFiles(uploadedFilesCopy)
        finishCallCount++
        if (liveUploadCountClbk) {
          liveUploadCountClbk(finishCallCount)
        }
        if (finishCallCount === duplicateFilesUploadedArr.length) {
          getUploadedFilesClbk(uploadedFiles)
          setConvertedFileArr([])
          setUploadedFiles([])
        }
      })
    })

  }

  useEffect(() => {
    if (files.length > 0) {
      let arr = []
      let uploadedFilesCopy = []
      Array.from(files).forEach((el, index) => {
        arr.push(el)
        uploadedFilesCopy.push({ uploadProgress: 0, url: '', success: false })
      })
      setConvertedFileArr(arr)
      setUploadedFiles(uploadedFilesCopy)
    }
  }, [files])

  useEffect(() => {
    uploadFunc()
  }, [convertedFileArr])

  useEffect(() => {

  }, [uploadedFiles])

  return (
    <>
      {uploadedFiles.map((el, index) => {
        return (
          <div className={outerContainerClasses} style={{ ...outerContainerStyle }}>
            {
              !showFileInfo &&
              <div className="flex items-center justify-center" style={getStyles(el)}>
                <span className="text-white" >{el.uploadProgress}%</span>
              </div>
            }
            {
              showFileInfo &&
              (
                <div className="flex flex-col w-full h-full relative">
                  <div className="flex h-full absolute" style={{ ...getStyles(el), zIndex: 2 }}>

                  </div>
                  <div className="flex w-full h-full absolute" style={{ backgroundColor: '#F4F4F4', zIndex: 1 }}>

                  </div>
                  <div className="flex items-center w-full h-full absolute" style={{ backgroundColor: 'transparent', zIndex: 3 }}>
                    <div className="flex gap-2 items-center text-white">
                      <img className="px-2" src={imageIcon} />
                      {files[index].name}
                    </div>
                  </div>
                </div>

              )
            }

          </div>
        )
      })}
    </>

  )
}

export default CustomProgressBar