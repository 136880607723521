import { useState, useEffect } from 'react'

// slices
import { resetToastDetails } from '../slices/ToastSlice'

// images
import successToast from '../images/successToast.png'
import errorToast from '../images/errorToast.png'
import greyClose from '../images/greyClose.png'
import { useDispatch, useSelector } from 'react-redux'

const Toast = () => {

  // redux variables
  const dispatch = useDispatch()
  const toastData = useSelector(state => state.toast)

  // state variables
  const [progressWidth, setProgressWidth] = useState('w-0')
  const [opacity, setOpacity] = useState('opacity-0')
  const [translateY, setTranslateY] = useState('translate-y-0')

  // local variables
  const { type, message, duration } = toastData
  const toastColour = type === 'success' ? "#4DB14F" : "#D81205"

  useEffect(() => {
    setProgressWidth('w-full')
    setOpacity('opacity-100')
    setTranslateY('translate-y-16')
    setTimeout(() => {
      dispatch(resetToastDetails())
    }, 4001)
  }, [])

  return (
    <div className={`flex-col flex bg-white fixed right-0 shadow-2xl ${opacity} transform ease-out duration-1000 ${translateY}`} style={{ zIndex: 101, width: '356px', height: '56px', borderRadius: 6 }}>
      <div class="flex relative items-center w-full p-2" style={{ height: '92%' }}>
        <div className="flex items-center">
          <img alt="success toast" src={type === "success" ? successToast : errorToast} style={{ width: '25px', height: '25px' }} />
          <p className="truncate pl-2 pr-2" style={{ color: toastColour }}>{message}</p>
        </div>
        <img onClick={() => dispatch(resetToastDetails())} className="relative cursor-pointer" alt="grey toast" src={greyClose} style={{ width: '15px', height: '15px', right: "-140px" }} />
      </div>
      <div className={`transition-all ease-out duration-4000 relative ${progressWidth}`} style={{ backgroundColor: toastColour, height: '8%' }}>
        &nbsp;
      </div>
    </div>
  )
}

export default Toast