import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { updateStatuses, fetchRegisterData, fetchAllRegisterData } from "../../../../slices/RegisterSlice"

//components
import StatusList from "./components/StatusList"
import Buttons from "./components/Buttons"

const EditStatus = ({ statusOptions, columnId }) => {

  const dispatch = useDispatch()

  //redux variables
  const registerData = useSelector( state => state.register)

  //state variables
  const [statuses, setStatuses] = useState([])

  //local variables
  const { registerId ,sheetId, sortDirection } = registerData

  //set default status values of 'new' and 'completed'
  useEffect(()=> {
    if( statusOptions.length == 0){
      setStatuses([
        {
          value: "New",
          color: "#FFB110",
          status: 1,
          booleanValue: true
        },
        {
          value: "Completed",
          color: "#2AB200",
          status: 100,
          booleanValue: false          
        }
      ])
    } else {
      setStatuses([...statusOptions])
    }
  },[])

  //status rename and/or color change
  const editStatuses = (newStatus) => {
    const newStatuses = [...statuses]
    const index = newStatus.status == 100? newStatuses.length-1 : newStatus.status-1
    newStatuses[index] = {...newStatus}
    setStatuses([...newStatuses])
  }

  const addStatus = (newStatus) => {
    const index = statuses[statuses.length-2].status
    statuses.splice(index, 0, {...newStatus, status: index+1, booleanValue: false})
  }

  const deleteStatus = (index) => {
    // remove status
    const filteredStatuses = statuses.filter((status, i)=>{
      return index != i
    })
    // 'status' prop of each status is updated
    const updatedStatuses = filteredStatuses.map((option, i)=>{
      if(i < index || option.status == 100) return option
      else return {...option, status: i+1}
    })
    setStatuses([...updatedStatuses]) 
  }

  //call api to update multioptions after changes are done, when submit button is clicked
  const onSubmit = async () => {
    const response = await dispatch(updateStatuses({sheetId, columnId, statusArray: statuses}))
    if (response.meta.requestStatus == "fulfilled") {
      if(sortDirection != 0){
        dispatch(fetchAllRegisterData({ registerId, sheetId }))
      }
    }
  }

  const classes = {
    container: `px-5`
  }

  const styles = {
    container: { width: '40rem', maxWidth: '100vw', height: '25rem', maxHeight: '100vh', background: '#E3F0FF' },
    statusListContainer: { height: '80%' }
  }

  return (
    <div className={classes.container} style={styles.container}>
      <StatusList statuses={statuses} editStatuses={editStatuses} addStatus={addStatus} deleteStatus={deleteStatus}/>
      <Buttons onSubmit={onSubmit}/>
    </div>
  )
}

export default EditStatus