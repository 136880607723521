import { useDispatch } from 'react-redux'

import { toggleRightSidebarOpen } from '../../../../../../slices/RightSidebarSlice'

//images and icons
import closeIcon from '../../../../../../images/right-sidebar/close.png'

const NotificationsHeader = () => {

  const dispatch = useDispatch()

  const onCloseButtonClick = () => {
    dispatch(toggleRightSidebarOpen({ rightSidebarOpen: false, body: null}))
  }

  const classes = {
    container: `text-white flex items-center gap-5 px-4`,
    closeIcon: `h-6 cursor-pointer scale-75 transform transition-transform delay-50 hover:scale-100`,
    text: `text-2xl`
  }

  const styles = {
    container: { height: '10vh', background: '#586C9D'}
  }
  return (
    <div className={classes.container} style={styles.container}>
      <img onClick={onCloseButtonClick} src={closeIcon} className={classes.closeIcon} />
      <span className={classes.text}>Notifications</span>
    </div>
  )
}

export default NotificationsHeader