import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

// public link

export const getCurrentLinks = createAsyncThunk('publicLinks', async ({ registerId, sheetId }) => {
  const response = await axios.get('/v3/register/publicLink/currentLinks?registerId=' + registerId + '&sheetId=' + sheetId)
  if (response.data.success) return response.data.data
})

export const enableRegisterLink = createAsyncThunk('enableRegisterLink', async ({ registerId }) => {
  const response = await axios.post('/v3/register/publicLink/enableRegisterLink', { registerId })
  if (response.data.success) return { data: response.data.data, registerId }
})

export const disableRegisterLink = createAsyncThunk('disableRegisterLink', async ({ registerId }) => {
  const response = await axios.post('/v3/register/publicLink/disableRegisterLink', { registerId })
  if (response.data.success) return { data: response.data.data, registerId }
})

export const enableFormLink = createAsyncThunk('enableFormLink', async ({ registerId, sheetId, columns }) => {
  const response = await axios.post('/v3/register/publicLink/enableFormLink', { registerId, sheetId, columns })
  if (response.data.success) return response.data.data
})

export const disableFormLink = createAsyncThunk('disableFormLink', async ({ registerId, sheetId }) => {
  const response = await axios.post('/v3/register/publicLink/disableFormLink', { registerId, sheetId })
  if (response.data.success) return response.data.data
})

// public link

export const dialogSlice = createSlice({

  name: 'dialog',

  initialState: {
    dialogOpen: false,
    showHeader: true,
    headerText: 'Header Text',
    showCloseIcon: true,
    showFooter: false,
    body: <div>body hi yo</div>,
    footer: null,
    colorDialogOpen: false,
    registerPublicLink: null,
    formPublicLink: null,
  },

  reducers: {
    toggleDialogOpen: (state, action) => {
      state.dialogOpen = action.payload
      if (action.payload === false) {
        state.headerText = ""
        state.showHeader = true
        state.showCloseIcon = false
        state.showFooter = false
        state.body = null
        state.footer = null
      }
    },
    updateBody: (state, action) => {
      state.body = action.payload
    },
    updateHeaderText: (state, action) => {
      state.headerText = action.payload
    },
    hideHeader: state => {
      state.showHeader = false
    },
    showFooter: state => {
      state.showFooter = true
    },
    removeFooter: state => {
      state.showFooter = false
    },
    updateFooter: (state, action) => {
      state.footer = action.payload
    },
    toggleColorDialogOpen: (state, action) => {
      state.colorDialogOpen = action.payload
    },
    updateDialogState: (state, action) => {
      action.payload.forEach((el) => {
        const key = el.key
        const value = el.value
        state[key] = value
      })
    },
  },

  extraReducers: {
    [enableRegisterLink.fulfilled]: ( state, action ) => {
      const link = action.payload.data.link
      state.registerPublicLink = link
    },
    [disableRegisterLink.fulfilled]: state => {
      state.registerPublicLink = null
    },
    [enableFormLink.fulfilled]: ( state, action) => {
      const link = action.payload.link
      state.formPublicLink = link
    },
    [disableFormLink.fulfilled]: ( state, action) => {
      state.formPublicLink = null
    },
    [getCurrentLinks.fulfilled]: ( state, action) => {
      let registerLink = action.payload.registerPublicLink
      let formLink = action.payload.formPublicLinks
      state.registerPublicLink = registerLink
      state.formPublicLink = formLink
    }
  }
})

export const { toggleDialogOpen, updateBody, updateHeaderText, hideHeader, showFooter, removeFooter, updateFooter, toggleColorDialogOpen, updateDialogState } = dialogSlice.actions

export default dialogSlice.reducer

