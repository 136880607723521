import { useSelector } from 'react-redux'
import LastRowCellFormatter from './LastRowCellFormatter'

// utils
import { getValidatedFormatterVal } from '../../Utils'

const UnitFormatter = ({ rowProperties, openEditorAfterSelect, colIdx, columnEl, unit }) => {
  // redux variables
  const registerData = useSelector(state => state.register)

  // local variables
  const colKey = columnEl.key
  const isCellSelected = rowProperties.isCellSelected
  const rowIdx = rowProperties.rowIdx
  const rowId = rowProperties.row.rowId
  const value = getValidatedFormatterVal(rowProperties.row[colKey])
  const filteredRows = registerData.filteredRows
  const isLastRow = filteredRows.length - 1 === rowIdx
  const searchQuery = registerData.searchQuery
  const shouldHighlight = searchQuery !== '' && (value.toString().toLowerCase()).includes(searchQuery.toLowerCase())

  const handleOpenEditor = () => {
    openEditorAfterSelect({ isCellSelected, rowIdx, colIdx: rowProperties.column.idx })
  }

  if (isLastRow) {
    return <LastRowCellFormatter
      rowProperties={rowProperties}
      openEditorAfterSelect={openEditorAfterSelect}
      colIdx={colIdx}
      colKey={colKey}
    />
  }
  return (
    <div
      id={`dataCell/${colKey}/${rowId}`}
      onClick={handleOpenEditor}
      className="flex justify-center items-center w-full h-full"
      style={{ backgroundColor: shouldHighlight ? '#ffd66e' : 'transparent' }}
    >
      {value} {value && unit}
    </div>
  )

};

export default UnitFormatter