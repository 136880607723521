const ProgressBar = ({ step }) => {
  let progress
  switch (step) {
    case 1:
      progress = 33.33;
      break;
    case 2:
      progress = 66.66;
      break;
    case 3:
      progress = 100;
      break;
    default:
      progress = 0;
      break;
  }

  return (
    <div className='flex justify-end flex-col items-end mr-2 sm:mr-5 lg:mr-10'>
      <div>
        <div className="font-medium text-xs mb-1">Step {step} of 3</div>
        <div style={{ width: '100px', height: '10px', borderRadius: '10px', backgroundColor: '#D9D9D9' }}>
          <div className="h-full" style={{ width: `${progress}%`, borderRadius: '10px', backgroundColor: '#35A600F0' }}></div>
        </div>
      </div>
    </div>
  )
}

export default ProgressBar