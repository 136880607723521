import { useSelector } from 'react-redux'
import LastRowCellFormatter from './LastRowCellFormatter'

// utils
import { getValidatedFormatterVal } from '../../Utils'

const LinkedSheetFormatter = ({ rowProperties, openEditorAfterSelect, colIdx, columnEl }) => {
  // redux variables
  const registerData = useSelector(state => state.register)

  // local variables
  const colKey = columnEl.key
  const linkedDataType = columnEl.linkedDataType
  const isCellSelected = rowProperties.isCellSelected
  const rowIdx = rowProperties.rowIdx
  const rowId = rowProperties.row.rowId
  const value = getValidatedFormatterVal(rowProperties.row[colKey])
  const imgs = rowProperties.row[colKey + "_details"] && rowProperties.row[colKey+'_details'].filter((el)=>{
    return !Object.keys(el).includes('linkedRowId') && el['value'] != ''
  })
  const filteredRows = registerData.filteredRows
  const isLastRow = filteredRows.length - 1 === rowIdx
  const searchQuery = registerData.searchQuery
  const shouldHighlight = searchQuery !== '' && (value.toString().toLowerCase()).includes(searchQuery.toLowerCase())

  const handleOpenEditor = () => {
    openEditorAfterSelect({ isCellSelected, rowIdx, colIdx: rowProperties.column.idx })
  }

  const extraImagesNumber = () => {
    const noOfImgs = imgs && imgs.length
    if (noOfImgs > 2) {
      return (
        <div>+ {noOfImgs - 2}</div>
      )
    }
  }

  if (isLastRow) {
    return <LastRowCellFormatter
      rowProperties={rowProperties}
      openEditorAfterSelect={openEditorAfterSelect}
      colIdx={colIdx}
      colKey={colKey}
    />
  }
  return (
    <div
      id={`dataCell/${colKey}/${rowId}`}
      className="sheetItem1"
      onClick={handleOpenEditor}
      style={{ backgroundColor: shouldHighlight ? '#ffd66e' : 'transparent' }}
    >
      {linkedDataType == 'image' ?
        <div>
          {
            imgs && imgs.length > 0 &&
            <div className="flex items-center justify-center gap-1 h-full pl-3 py-1" >
              {
                imgs.map((img, i) => {
                  if (i < 2) {
                    return (
                      <div key={`data-cell-image-editor-${i}`} className="flex justify-center" style={{ height: '100%', width: '2rem' }}>
                        <img src={img.value} style={{ height: '100%', width: '100%' }} className="rounded border border-gray-600 shadow-md" />
                      </div>
                    )
                  }
                })
              }
              {extraImagesNumber()}
            </div>
          }
        </div> :
        <p className="px-1 truncate">{value}</p>
      }

    </div>
  );
};

export default LinkedSheetFormatter