const TimeUnitSelector = ({ colkey, type, scrollTo, timeUnitArray }) => {

  return (
    <div
      id={`${type}_container_div_${colkey}`}
      className='overflow-auto no-scrollbar h-full w-full z-20'
      style={{ scrollSnapType: 'y mandatory' }}
    >
      <div style={{ height: '3rem' }}></div>
      {
        timeUnitArray.map((unit, i) => {
          return (
            <div
              id={`${type}_${i}_${colkey}`}
              onClick={(e) => { e.preventDefault(); scrollTo(type, i) }}
              className='flex items-center justify-center cursor-pointer z-100'
              style={{ height: '3rem', scrollSnapAlign: 'center' }}
            >
              {unit}
            </div>
          )
        })
      }
      <div style={{ height: '3rem' }}></div>
    </div>
  )
}

export default TimeUnitSelector