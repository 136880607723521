import cancelIcon from '../../../../images/add-edit row dialog/cancel.png'

const CancelButton = ({row, setFormData, setEdited}) =>{

  const cancel = () => {
    setFormData(row)
    setEdited(false)
  }

    return(
      <button 
        className="secondary_btn w-1/2 h-10" 
        onClick={cancel} 
      >
        {/* <img src={cancelIcon} className="w-4 h-4" alt=""/> */}
        <span>Cancel</span>
      </button>
    )
}

export default CancelButton