import { useDispatch, useSelector } from "react-redux"

import { updateLoginInfoState } from "../../../slices/LoginSlice"

const RedirectToLogin = ({ setRedirectToLogin }) => {

  const dispatch = useDispatch()
  const loginInfo = useSelector(state => state.loginInfo)
  const { error } = loginInfo

  const onClick = () => {
    if (error) {
      dispatch(updateLoginInfoState([{ key: 'error', value: false }, { key: 'errorMessage', value: null }]))
    }
    setRedirectToLogin(true)
  }

  return (
    <div className="font-medium mt-4" style={{ color: '#6C757D', fontSize: '15px' }}>
      Already Have an Account?&nbsp;
      <span onClick={onClick} style={{ color: '#0B98FF' }} className='cursor-pointer'>Login</span>
    </div>
  )
}

export default RedirectToLogin