import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// components
import AnimationSection from "../AnimationSection"
import ProgressBar from "../ProgressBar"
import Terms from "../Terms"

// slices
import { verifyPassword, updateLoginInfoState } from "../../../../slices/LoginSlice"

// icons
import closeEye from '../../../../images/login/close-eye.png'
import openEye from '../../../../images/login/open-eye.png'

const CreatePasswordContainer = () => {

  // redux variales
  const dispatch = useDispatch()
  const loginInfo = useSelector(state => state.loginInfo)

  // router variables
  const searchParams = new URLSearchParams(window.location.search)

  // state variables
  const [password, setPassord] = useState('')
  const [confirmPassword, setConfirmPassord] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const isDisabled = !password || !confirmPassword

  // local variables
  const { isLoading, error, errorMessage } = loginInfo
  const emailId = searchParams.get('emailId')
  const token = searchParams.get('token')

  const onPasswordChange = (e) => {
    removeError()
    setPassord(e.target.value)
  }

  const onConfirmPasswordChange = (e) => {
    removeError()
    setConfirmPassord(e.target.value)
  }

  const togglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword)
  }

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(prevShowConfirmPassword => !prevShowConfirmPassword)
  }

  const OnSubmit = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      dispatch(updateLoginInfoState([{ key: 'error', value: true }, { key: 'errorMessage', value: 'The passwords do not match' }]))
    } else if(password.length < 8){
      dispatch(updateLoginInfoState([{ key: 'error', value: true }, { key: 'errorMessage', value: 'The password should be at least 8 characters long' }]))
    } else {
      dispatch(verifyPassword({ password, confirmPassword, emailId, token }))
    }
  }

  const removeError = () => {
    if (error) {
      dispatch(updateLoginInfoState([{ key: 'error', value: false }, { key: 'errorMessage', value: null }]))
    }
  }

  //classes
  const container = `h-full w-full flex flex-col md:flex-row`
  const signUpContainer = `w-full md:w-7/12 md:h-full p-8 text-center`
  const inputContainerClass = `relative rounded-2xl my-5 max-w-full mx-auto flex items-center`
  const inputClass = `rounded-2xl w-full h-full pl-5 pr-10 bg-transparent grayPlaceholder font-medium`
  const buttonClass = `child-in-center mx-auto rounded-2xl max-w-full gap-2 my-10 font-medium text-white`
  const eyeClass = `absolute right-4 w-5 cursor-pointer`

  //styles
  const inputContainerStyle = { width: '26rem', height: '4.5rem' }
  const buttonStyle = { width: '26rem', height: '4.5rem', fontSize: '1.125rem' }

  // validate emailId and token
  if (!emailId || !token) {
    return <div className="h-full w-full child-in-center">Invalid URL. Please check your email and try again.</div>
  }

  return (
    <div className={container}>

      <AnimationSection />

      <div className={signUpContainer} style={{ backgroundColor: '#F8F9FA' }}>
        <ProgressBar step={3} />
        <div className="mt-16">

          <div className="font-bold text-3xl my-4" style={{color: '#22252A', fontSize: '2.44rem'}}>
            Hurray! You’re in!
          </div>

          <div className="font-medium" style={{ color: '#6C757D' }}>
            Set your password to make your account secure
          </div>

          <form onSubmit={OnSubmit} className="my-16">

            {/*   Password Field   */}
            <div className={inputContainerClass} style={inputContainerStyle}>
              <input
                value={password} onChange={onPasswordChange}
                type={showPassword ? 'text' : 'password'}
                className={`${inputClass}`} style={{border: '1px solid #ACB5BD', fontSize: '1.125rem'}}
                placeholder='Enter your password'
              />
              <img
                onClick={togglePasswordVisibility}
                src={showPassword ? openEye : closeEye}
                className={eyeClass}
                loading="lazy"
              />
            </div>
            {/*   Password Field   */}

            {/*   Confirm Password Field   */}
            <div className={inputContainerClass} style={inputContainerStyle}>
              <input
                value={confirmPassword} onChange={onConfirmPasswordChange}
                type={showConfirmPassword ? 'text' : 'password'}
                className={`${inputClass}`} style={{border: '1px solid #ACB5BD', fontSize: '1.125rem'}}
                placeholder='Confirm password' />
              <img
                onClick={toggleConfirmPasswordVisibility}
                src={showConfirmPassword ? openEye : closeEye}
                className={eyeClass}
                loading="lazy"
              />
            </div>
            {/*   Confirm Password Field   */}

            {/* error msg */}
            {error && <p className="my-10 font-medium" style={{ color: '#F5291C', fontSize: '15px', fontFamily: 'Inter' }}><span>{errorMessage}</span></p>}
            {/* error msg */}

            {/*   Submit Button   */}
            <button type="submit" className={`${buttonClass} ${isDisabled ? 'cursor-not-allowed' : ''}`} style={{ backgroundColor: isDisabled ? '#DDDDDD' : '#35A600', ...buttonStyle }}>
              {
                isLoading ? (
                  <div className="animate-spin rounded-full h-7 w-7 border-b-2 border-gray-100 m-auto "></div>
                ) : (
                  <span>Set Password</span>
                )
              }
            </button>
            {/*   Submit Button   */}

            {/* Terms */}
            {!isDisabled && <Terms />}

          </form>
        </div>
      </div>
    </div>
  )
}

export default CreatePasswordContainer