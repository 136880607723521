import { useSelector } from 'react-redux'
import LastRowCellFormatter from './LastRowCellFormatter'

// utils

// icons
import addImageIcon from '../../../../../images/column-icons/plus.png'

const ImageFormatter = ({ rowProperties, openEditorAfterSelect, colIdx, columnEl }) => {
  // redux variables
  const registerData = useSelector(state => state.register)

  // local variables
  const colKey = columnEl.key
  const rowIdx = rowProperties.rowIdx
  const rowId = rowProperties.row.rowId
  const id = `dataCell/${colKey}/${rowId}`
  const imgs = rowProperties.row[colKey + "_details"]
  const noOfImgs = imgs && imgs.length
  const isCellSelected = rowProperties.isCellSelected
  const filteredRows = registerData.filteredRows
  const isLastRow = filteredRows.length - 1 === rowIdx

  const extraImagesNumber = () => {
    if (noOfImgs > 2) {
      return (
        <div>+ {noOfImgs - 2}</div>
      )
    }
  }

  const handleOpenEditor = () => {
    openEditorAfterSelect({ isCellSelected, rowIdx, colIdx: rowProperties.column.idx })
  }

  if (isLastRow) {
    return <LastRowCellFormatter
      rowProperties={rowProperties}
      openEditorAfterSelect={openEditorAfterSelect}
      colIdx={colIdx}
      colKey={colKey}
    />
  }

  if (imgs && imgs.length > 0) {
    return (
      <div id={id} onClick={handleOpenEditor} className="flex items-center justify-center gap-1 h-full pl-3 py-1" >
        {
          imgs.map((img, i) => {
            if (i < 2) {
              return (
                <div key={`data-cell-image-formatter-${i}`} className="flex justify-center" style={{ height: '100%', width: '2rem' }}>
                  <img src={img.value} style={{ height: '100%', width: '100%' }} className="rounded border border-gray-600 shadow-md" />
                </div>
              )
            }
          })
        }
        {extraImagesNumber()}
      </div>
    )
  } else {
    return (
      <div id={id} onClick={handleOpenEditor} style={{ color: "#586C9E" }} className="flex justify-center items-center px-1 transition-opacity delay-50 opacity-0 hover:opacity-100">
        <div className="flex items-center gap-2">
          <img src={addImageIcon} alt="" />
          Click to Upload
          </div>
      </div>
    )
  }
};


export default ImageFormatter
