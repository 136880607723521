import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import RE_ImageDialog from './RE_ImageDialog'
import { toggleDialogOpen, updateBody, updateHeaderText } from '../../../../../../slices/DialogSlice';
import { openCarousel, loadSelectedRowInfo } from '../../../../../../slices/CarouselSlice';

// utils
import ErrorBoundary from '../../../../../../utils/ErrorBoundary'

const ImageEditor = ({ rowProperties, saveCurrentRowData, colIdx, columnEl }) => {
  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  // local variables
  const colKey = columnEl.key
  const onClose = rowProperties.onClose
  const rowId = rowProperties.row.rowId
  const imgs = rowProperties.row[(colKey) + "_details"] ? rowProperties.row[(colKey) + "_details"] : []
  const columnName = registerData.columns[colIdx] ? registerData.columns[colIdx].name : ''

  useEffect(() => {
    if (imgs.length) { // if images exists then open carousel
      dispatch(openCarousel(true))
      dispatch(loadSelectedRowInfo({ selectedRow: rowProperties, selectedColKey: colKey }))
      onClose(true)
    } else if (imgs.length === 0) { // if empty cell then open upload images dialog
      const cellProps = {
        columnName,
        rowNo: rowProperties.rowIdx+1
      }
      dispatch(toggleDialogOpen(true))
      dispatch(updateHeaderText(columnName))
      dispatch(updateBody(<RE_ImageDialog onImageChange={onImagesUploadCallback} cellProps={cellProps}/>))
    }
  }, [])

  const onImagesUploadCallback = (imageArr) => {
    let newImagesArray = []
    imageArr.forEach((el) => {
      newImagesArray.push({ value: el.url })
    })
    saveCurrentRowData(newImagesArray, `${colKey}_details`, rowId, registerData.rows)
    // resetting dialog after all the uploads
    dispatch(toggleDialogOpen(false))
    onClose(true)
  }

  return (
    <ErrorBoundary>
      <div className="w-full h-full"></div>
    </ErrorBoundary>
  )
}

export default ImageEditor
