import { useDispatch } from 'react-redux'

// slices
import { toggleDialogOpen, updateBody, updateHeaderText } from '../../../../../slices/DialogSlice'

// components
import CreateRegisterFromExcel from '../../CreateRegisterFromExcel'

// utils
import { allBusinessCategories } from '../../../../../utils/Utils'

// icons
import whitePlus from '../../../../../images/whitePlus.png'
import excelIcon2 from '../../../../../images/excelIcon2.png'

// thirdy party libraries
import XLSX from 'xlsx'

const EmptyRegisterBody = ({ selectCategory, goToCreateRegister, closeAddRegisterPopUp }) => {
	// redux variables
	const dispatch = useDispatch()

	const onImportExcel = (sheetsList, files) => {
		dispatch(toggleDialogOpen(true))
		dispatch(updateBody(<CreateRegisterFromExcel sheetsList={sheetsList} files={files} closeAddRegisterPopUp={closeAddRegisterPopUp} />))
		dispatch(updateHeaderText('Upload Excel File'))
	}

	const onFileChange = (e) => {
		let files = e.target.files
		const f = Array.from(files)[0]
		const excelFileName = f.name.split('.')[0]

		const reader = new FileReader()
		const rABS = !!reader.readAsBinaryString
		reader.onload = e => {
			/* Parse data */
			const bstr = e.target.result
			const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" })
			/* Get first worksheet  */
			let sheetsList = wb.SheetNames.map((el) => { return { name: el, excelFileName } })
			onImportExcel(sheetsList, files)
		}
		if (rABS) reader.readAsBinaryString(f)
		else reader.readAsArrayBuffer(f)
	}

	return (
		<div className="w-full h-full flex flex-col">
			{/* create new register and import excel file button container starts */}
			<div className="w-full flex flex-col md:flex-row justify-center items-center mt-2 lg:mt-0 px-5" style={{ height: '22%' }}>
				<div className="flex w-full justify-center lg:justify-end lg:pr-6 py-1 my-1 lg:py-0 h-12 sm:h-14">
					<div
						className="child-in-center gap-1 text-white cursor-pointer shadow-lg w-full sm:w-4/5"
						style={{ backgroundColor: '#044089', borderRadius: 8 }}
						onClick={goToCreateRegister}
					>
						<img className="relative h-4 right-0.5 lg:right-2 md:right-2 lg:text-base md:text-base text-xs" src={whitePlus} alt="white circular plus icon" />
						<p className={`truncate font-medium ${closeAddRegisterPopUp ? "lg:text-base" : "lg:text-lg"} md:text-base text-xs`} >Create New Register</p>
					</div>
				</div>
				<div className="flex w-full justify-center lg:justify-start lg:pl-6 py-1 lg:py-0 h-12 sm:h-14 my-1">
					<input
						id="btn/import/excel/addRegisterPopUp"
						type="file"
						onChange={onFileChange}
						onClick={(e) => { e.target.value = null }}
						accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
						hidden
						multiple
					/>
					<label
						tabIndex="0" htmlFor="btn/import/excel/addRegisterPopUp"
						className="child-in-center gap-1 text-white cursor-pointer shadow-lg w-full sm:w-4/5"
						style={{ backgroundColor: '#fff', borderRadius: 8, color: '#1F7244' }}
					>
						<img style={{ height: '1.5rem', right: 5 }} className="relative" src={excelIcon2} alt="import excel icon 2" />
						<p className={`${closeAddRegisterPopUp ? "lg:text-base" : "lg:text-lg"} md:text-base text-xs font-medium`} >Import Excel File</p>
					</label>
				</div>
			</div>
			{/* create new register and import excel file button container ends */}

			{/* choose from template division container starts */}
			<div className="w-full flex justify-center items-center font-semibold" style={{ height: '8%' }}>
				Or Choose a template
			</div>
			{/* choose from template division container ends */}

			{/* all categories container starts */}
			<div className={`w-full place-items-center overflow-hidden overflow-y-auto mb-4 pb-1 grid grid-cols-2 xs:grid-cols-3 md:grid-cols-4 ${closeAddRegisterPopUp ? "lg:grid-cols-4" : "lg:grid-cols-5"}`} style={{ height: '70%' }}>
				{
					allBusinessCategories.map(categoryEl =>
						<div onClick={() => selectCategory(categoryEl)} key={`categoryIcon/${categoryEl.type}`} className="flex justify-center items-center cursor-pointer mt-4 w-full h-28 sm:h-32 w-28 sm:w-32" style={{ height: 120, width: 120 }} >
							<div className="flex h-full flex-col justify-center items-center bg-white rounded-lg w-full" style={{boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)' }} >
								<img className="relative h-1/2" src={categoryEl.icon} alt="white circular plus icon" />
								<p className="p-2 text-center" style={{ color: '#454545', fontSize: '80%' }}>{categoryEl.name}</p>
							</div>
						</div>
					)
				}
			</div>
			{/* all categories container starts */}
		</div>
	)
}

export default EmptyRegisterBody