import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

// icons
import plusWhiteIcon from '../../../../../../images/plusWhite.png'

const AddNewEntryButton = ({ linkedRegisterId }) => {

  //redux variables
  const leftSidebarData = useSelector(state => state.leftSidebar)

  // router variables
  const history = useHistory()

  // local variables
  const { activeBusinessId } = leftSidebarData

  const onClick = () => {
    history.push(`/community/${activeBusinessId}/register/${linkedRegisterId}/?rowId=newEntry`)
  }

  return (
    <div className="child-in-center border-t sticky bottom-0 py-4">
      <button className="primary_btn px-8 py-2" onClick={onClick}>
        <img src={plusWhiteIcon} className='h-5' />ADD NEW ENTRY
      </button>
    </div>
  )
}

export default AddNewEntryButton