import { useEffect, useCallback, useState } from 'react'

const useContextMenu = () => {

  // state variables
  const [show, setShow] = useState(false)
  const [anchorPoints, setAnchorPoints] = useState({ x: 0, y: 0 })
  const [cellInfo, setCellInfo] = useState({ rowPosInArr: null })

  {/*
    // When user does right click on a cell in 'data-grid-div'
    // the "id" is picked from either the div ( firstLevelDivHasCellId ) in view or 
    // its current parent ( secondLevelDivHasCellId ).
    // If the id is of "data cell" or "serial number cell" 
    // then anchor points position and show "true" are given to the component using this hook.
  */}
  const handleContextMenu = useCallback((e) => {
    let rowInfo = {}
    const pathToId = e.composedPath()
    const secondLevelDivHasCellId = pathToId[1] && (pathToId[1].id.includes('dataCell') || pathToId[1].id.includes('serialNumbCell'))
    const firstLevelDivHasCellId = pathToId[0] && (pathToId[0].id.includes('dataCell') || pathToId[0].id.includes('serialNumbCell'))
    if (secondLevelDivHasCellId) {
      const idArray = pathToId[1].id.split('/')
      rowInfo = {
        cellType: idArray[0],
        columnId: idArray[1],
        rowId: idArray[2]
      }
    } else if (firstLevelDivHasCellId) {
      const idArray = pathToId[0].id.split('/')
      rowInfo = {
        cellType: idArray[0],
        columnId: idArray[1],
        rowId: idArray[2]
      }
    }
    if (firstLevelDivHasCellId || secondLevelDivHasCellId) {
      e.preventDefault();
      e.stopPropagation(); // done to disallow S.No cell selection on right click
      setAnchorPoints({
        x: e.x,
        y: window.innerHeight - e.y < 150 ? e.y - 150 : e.y // here 150 is the height of the menu
      })
      setCellInfo(rowInfo)
      setShow(true)
    }
  }, [setShow, setAnchorPoints])

  // when a normal click is done then if menu is visible
  // then it should be hidden
  const clickHandler = useCallback((e) => {
    if (e.target.id === 'delete_row_oprn' ||
      e.target.id === 'delete_row_modal_no_btn' ||
      e.target.alt === 'close dialog' ||
      e.target.id === 'dialog-backdrop') return
    if (show) setShow(false)
  }, [show])

  // initialise event listeners on "data-grid-div"
  // 1. right click ( handleContextMenu ) 
  // 2. click event ( clickHandler ) 
  useEffect(() => {
    const dataGridDivEl = document.getElementById('data-grid-div')
    document.addEventListener("click", clickHandler);
    if (dataGridDivEl) {
      // dataGridDivEl.addEventListener("contextmenu", handleContextMenu)
      dataGridDivEl.oncontextmenu = handleContextMenu;
    }
    return () => {
      document.removeEventListener("click", clickHandler);
      if (dataGridDivEl) {
        dataGridDivEl.removeEventListener("contextmenu", handleContextMenu)
      }
    }
  }, [show])

  // hook returns these
  return { anchorPoints, show, cellInfo }
}

export default useContextMenu;