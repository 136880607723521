import { useState, useRef, useEffect } from 'react'
import ReactDOM, { createPortal } from 'react-dom'

import dropdownGrayIcon from '../images/add-edit row dialog/drop-down.png'
import dropdownBlueIcon from '../images/header popup icons/drop down.png'
import deleteIcon from '../images/add-edit row dialog/form icons/delete.png'
import editIcon from '../images/add-edit row dialog/form icons/edit.png'
import crossIcon from '../images/add-edit row dialog/cross-gray.png'

const CustomInputDropdown = ({
  children,
  inputType,
  dropdownClasses,
  dropdownStyles,
  inputClasses,
  inputStyles,
  outerDivClasses,
  onFieldClick,
  fieldValue,
  inputValue,
  onInputValueChange,
  buttonsInfo,
  container,
  showRemoveIcon,
  onRemoveIconClick,
  showDropDownMenu,
  setShowDropDownMenu,
  isDisabled = false,
  onDropdownMenuClick = () => { }
}) => {

  // ref variables
  const dropdownRef = useRef(null)
  const dropdownTriggerBtnRef = useRef(null)

  // state variables
  const [ddStyle, setDropdownStyle] = useState({})

  const onButtonClick = (e) => {
    e.preventDefault()
    setShowDropDownMenu(!showDropDownMenu)
    onFieldClick()
  }

  useEffect(() => {
    const clickHandler = (e) => {
      if (
        dropdownTriggerBtnRef.current &&
        !dropdownTriggerBtnRef.current.contains(e.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target) &&
        showDropDownMenu
      ) {
        setShowDropDownMenu(false)
      }
    }
    if (showDropDownMenu) {
      updateDropdownStyle()
      document.addEventListener('click', clickHandler)
      document.querySelector(".rdg-light").addEventListener("scroll", updateDropdownStyle)
      if (container == 'form') document.querySelector("#form-data").addEventListener("scroll", updateDropdownStyle)
      window.addEventListener('resize', updateDropdownStyle)
    }
    return () => {
      document.removeEventListener('click', clickHandler)
      document.removeEventListener("scroll", updateDropdownStyle)
      window.removeEventListener('resize', updateDropdownStyle)
    }
  }, [showDropDownMenu])

  const updateDropdownStyle = () => {
    if (dropdownRef.current) {
      let data_grid_rect = document.querySelector(".rdg-light").getBoundingClientRect()
      let dropdownRect = dropdownRef.current.getBoundingClientRect()
      let triggerBtnRect = dropdownTriggerBtnRef.current.getBoundingClientRect()
      let totalDropdownHeight = dropdownRect.height
      let dropdownDownWidth = dropdownRect.width
      let positionTop = dropdownStyles && dropdownStyles.positionTop ? dropdownStyles.positionTop : 0
      let marginTop = dropdownStyles && dropdownStyles.marginTop ? dropdownStyles.marginTop : 0
      let marginRight = 0

      const openTowardsBottom = () => {
        positionTop = 40
      }

      const openTowardsTop = () => {
        positionTop = positionTop - totalDropdownHeight
        marginTop = marginTop * (-1)
      }

      const openTowardsLeft = () => {
        marginRight = triggerBtnRect.width - dropdownDownWidth
      }

      // Container = form
      if (container == 'form') {
        let formRect = document.getElementById("form-data").getBoundingClientRect()
        if (triggerBtnRect.bottom < formRect.top || triggerBtnRect.top > formRect.bottom) {
          setDropdownStyle({})
          return
        }
        if (triggerBtnRect.left + dropdownDownWidth >= window.innerWidth) openTowardsLeft()
        if (triggerBtnRect.bottom + totalDropdownHeight >= formRect.bottom) openTowardsTop()
        else openTowardsBottom()
      }
      // Container = form

      // Container = data-grid
      else if (container == 'grid') {
        if (triggerBtnRect.bottom + totalDropdownHeight >= data_grid_rect.bottom) openTowardsTop()
        else if (triggerBtnRect.bottom < data_grid_rect.top) {
          setDropdownStyle({})
          return
        }
        else openTowardsBottom()
        if (triggerBtnRect.left + dropdownDownWidth >= window.innerWidth) openTowardsLeft()
      }
      // Container = data-grid

      // Container = screen
      else if (container == 'screen') {
        if (triggerBtnRect.bottom + totalDropdownHeight >= window.innerHeight) openTowardsTop()
        else openTowardsBottom()
      }
      // Container = screen

      const refValue = ReactDOM.findDOMNode(dropdownTriggerBtnRef.current)
      if (refValue) {
        setDropdownStyle({
          left: triggerBtnRect.left + marginRight,
          top: triggerBtnRect.top + positionTop,
          width: triggerBtnRect.width,
          marginTop: marginTop
        })
      }
    }
  }

  return (
    <div className={`relative flex items-center ${outerDivClasses}`}>

      {/* Input */}
      {
        inputType == 'input' ?

          <>
            <input className={inputClasses} style={inputStyles} value={inputValue} onChange={onInputValueChange} />
            <img src={dropdownBlueIcon} className='drop-down-icon' />
          </>
          :

          <>
            <button
              ref={dropdownTriggerBtnRef}
              className={inputClasses}
              style={inputStyles}
              onClick={onButtonClick}
              disabled={isDisabled}
            >
              <div className='truncate'>{fieldValue}</div>
            </button>
            {
              showRemoveIcon && fieldValue ?
                <img src={crossIcon} className='cross_icon z-10' onClick={onRemoveIconClick} /> :
                <img src={dropdownBlueIcon} className={`drop-down-icon transform transition-transform delay-50 ${showDropDownMenu ? 'rotate-180' : 'rotate-0'}`} />
            }

          </>

      }
      {/* Input */}

      {/* Dropdown Portal */}
      {
        showDropDownMenu && createPortal(
          <div
            ref={dropdownRef}
            onClick={(e) => { onDropdownMenuClick(); e.preventDefault(); e.stopPropagation() }}
            className={`${dropdownClasses} bg-white slide_down_2`}
            style={{
              filter: 'drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.08)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15))',
              borderRadius: '8px', zIndex: '500', position: 'fixed',
              top: ddStyle.top,
              left: ddStyle.left,
              width: ddStyle.width,
              right: ddStyle.right,
              marginTop: ddStyle.marginTop,
              minWidth: dropdownStyles && dropdownStyles.minWidth
            }}
          >

            {children}

            {
              buttonsInfo.showButtonsSection && (
                <div className='py-2 px-4 flex gap-3 justify-end' style={{ backgroundColor: '#F9F9F9', borderRadius: '8px', borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}>

                  {
                    buttonsInfo.showCancelButton &&
                    <button onClick={() => buttonsInfo.onCancelButtonClick ? buttonsInfo.onCancelButtonClick() : setShowDropDownMenu(false)} className="tertiary_btn transition-all delay-50 px-4 py-1">Cancel</button>
                  }

                  {
                    buttonsInfo.showDeleteButton &&
                    <button onClick={buttonsInfo.onDeleteButtonClick} className="critical_btn px-4 py-1 transition-all delay-50">
                      <img src={deleteIcon} className='h-4' />Delete
                    </button>
                  }

                  {
                    buttonsInfo.showEditButton &&
                    <button onClick={buttonsInfo.onEditButtonClick} className="secondary_btn transition-all delay-50 px-5 py-1">
                      <img src={editIcon} className='h-4' />Edit
                    </button>
                  }

                  {
                    buttonsInfo.showSaveButton &&
                    <button onClick={buttonsInfo.onSaveButtonClick} className="secondary_btn transition-all delay-50 px-4 py-1">
                      Save
                    </button>
                  }

                </div>
              )
            }

          </div>
          , document.body)
      }
      {/* Dropdown Portal */}

    </div>
  )
}

export default CustomInputDropdown