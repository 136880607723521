import Header from "./Header"
import About from "./About"
import Participants from "./Participants"
import Themes from "./Themes"
import DeleteRegister from "./DeleteRegister"

const RegisterDetails = () => {
  return (
    <>
      <Header />
      <About />
      <Participants />
      {/*
        <Themes />
        <DeleteRegister />
        */}
    </>
  )
}

export default RegisterDetails