import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

//slices
import { logout } from '../../../../slices/LoginSlice'
import { updatePopNavigationHistory } from '../../../../slices/RouterSlice'

// components
import Transition from '../../../../utils/Transition'

// images
import blueProfileIcon from '../../../../images/blueProfileIcon.png'
import rightGreyArrow from '../../../../images/rightGreyArrow.png'
import helpCircleBlue from '../../../../images/helpCircleBlue.png'

const Profile = () => {
  const dispatch = useDispatch();

  //redux variables
  const leftSidebarData = useSelector(state => state.leftSidebar)

  //state variables
  const [dropdownOpen, setDropdownOpen] = useState(false);

  //ref variables
  const trigger = useRef(null)
  const dropdown = useRef(null)

  //local variables
  const userName = localStorage.getItem("name")
  const { registers, homePageDataFetched } = leftSidebarData
  const showWelcomeImage = registers.length === 0

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const signOut = () => {
    dispatch(logout())
    dispatch(updatePopNavigationHistory({
      type: 'empty',
      path: ''
    }))
  }

  return (
    <div className={`flex h-full bg-white border-gray-400 relative ${homePageDataFetched && showWelcomeImage ? 'border-t-0' : 'border-t'}`} >
      <div
        ref={trigger}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
        className="flex items-center justify-between cursor-pointer w-full px-6"
      >
        <div className='child-in-center'>
          <img src={blueProfileIcon} className="h-7" style={{ maxHeight: '90%' }} />
          <span className="px-3 text-base font-semibold" style={{color: '#545454'}} >{userName}</span>
        </div>
        <img src={rightGreyArrow} className="h-3" />
      </div>
      <Transition
        className="origin-bottom-right z-50 absolute bottom-full right-4 left-4 min-w-44 bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-gray-200">
            <div className="font-medium text-gray-800">{userName}</div>
            <div className="text-xs text-gray-500 italic">Administrator</div>
          </div>
          <ul>
            {/*<li>
              <Link
                className="font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1 px-3"
                to="/"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                Settings
              </Link>
            </li>*/}
            <li>
              <Link
                className="font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1 px-3"
                to="/"
                onClick={signOut}
              >
                Sign Out
              </Link>
            </li>
          </ul>
        </div>
      </Transition>
      <div className="flex items-center justify-end">
        {/* <img src={helpCircleBlue} className="cursor-pointer" style={{ width: 26, height: 26 }} /> */}
      </div>
      {/* className="justify-self-end" */}
    </div>
  )
}

export default Profile