import ResetPasswordContainer from "../../features/Login/Components/LoginByEmail/ResetPasswordContainer"

const ResetPassword = () => {

  // styles
  const containerStyles = { backgroundColor: '#EAECEF' }

  return (
    <div className="child-in-center h-screen w-full" style={containerStyles}>
      <ResetPasswordContainer />
    </div>
  )
}

export default ResetPassword