import React from 'react'

//components
import SignUpContainer from '../../features/SignUp'
import {
  GoogleReCaptchaProvider
} from 'react-google-recaptcha-v3'

const SignUp = () => {

  const classes = {
    container: 'h-screen w-full'
  }

  return (
    <div className={classes.container} style={{ minHeight: '650px' }}>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY_PRODUCTION}>
        <SignUpContainer />
      </GoogleReCaptchaProvider>
    </div>
  )
}

export default SignUp
