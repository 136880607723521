import { useState } from 'react'

// icons
import closeIcon from '../../../../../../images/close_gray_border.png'

const SearchAndClose = ({onClose, searchQuery, setSearchQuery, setQueryForFilter }) => {

  // state variables
  const [timeOutId, setTimeoutId] = useState(null)

  const onSearchChange = (e) => {
    const value = e.target.value
    setSearchQuery(value)
    if (timeOutId) {
      clearTimeout(timeOutId)
    }
    setTimeoutId(setTimeout(() => {
      setQueryForFilter(value)
    }, 200))
  }

  return (
    <div className="px-6 py-4 border-b flex items-center justify-between w-full">
      <input value={searchQuery} onChange={onSearchChange}
        placeholder='Search by any keyword'
        className='inputCommon text-lg pl-4 w-full'
        style={{ color: '#545454' }}
      />
      <div className="w-10 flex justify-end" style={{ flexShrink: '0' }}><img src={closeIcon} className='h-6 cursor-pointer' onClick={onClose} /></div>
    </div>
  )
}

export default SearchAndClose