import { useState, useEffect } from 'react'
import { useDispatch, useDuspatch, useSelector } from 'react-redux'
import { DataTypes } from '../../../../Register/Table/Utils'

//icons and images
import dropdownToggleIcon from '../../../../../images/dialog/share/down-toggle.png'
import { enableFormLink } from '../../../../../slices/DialogSlice'

const createCopy = (json) => {
  return JSON.parse(JSON.stringify(json))
}

const ColumnsMultiSelect = ({ toggle2on, colIdsArrForApi, setColIdsArrForApi }) => {

  const dispatch = useDispatch()

  //redux variables
  const register = useSelector(state => state.register)
  const theme = useSelector(state => state.theme)

  //state variables
  const [currentPageColumns, setCurrentPageColumns] = useState([])
  const [showDropdown, setShowDropdown] = useState(false)
  const [checkedAll, setCheckedAll] = useState(true)
  const [multiSelectText1, setMultiSelectText1] = useState('Select columns you want to share.')
  const [multiSelectText2, setMultiSelectText2] = useState('')

  //local variables
  const { columns, registerId, sheetId } = register
  const { primary1, dark_grey, red } = theme

  useEffect(() => {
    const columnsOnly = columns.filter(el => el.key !== '-1' && el.key !== 'addColPlusButton')
    //add checked property to each column
    const pageColumns = columnsOnly.map((col, i) => {
      return { ...col, checked: true }
    })
    setCurrentPageColumns(pageColumns)
    updateColArrayForApi(pageColumns)
  }, [columns])

  //update text in dropdown
  useEffect(() => {
    const selectedColumns = columns.filter((column, key) => {
      return colIdsArrForApi.includes(column.key)
    })
    if (selectedColumns.length > 0) {
      let firstColName = selectedColumns[0].name
      let noOfRemainingCols = selectedColumns.length - 1
      setMultiSelectText1(firstColName)
      let text2 = <span style={{ color: red }}>{`& ${noOfRemainingCols} more`}</span>
      if (selectedColumns.length == 1) setMultiSelectText2('')
      else setMultiSelectText2(text2)
    }
    if (selectedColumns.length == 0) {
      setMultiSelectText1('Select columns you want to share.')
      setMultiSelectText2('')
    }
  }, [colIdsArrForApi])
  //update text in dropdown

  const updateColArrayForApi = (columns) => {
    const colIdsArr = columns.filter((col) => {
      return col.checked
    }).map(col => {
      return col.key
    })
    setColIdsArrForApi(colIdsArr)
  }

  const onDoneButtonClick = async () => {
    if (colIdsArrForApi.length == 0) {
      alert('Please select at least one column')
      return
    }
    await dispatch(enableFormLink({ registerId, sheetId, columns: colIdsArrForApi }))
    setShowDropdown(false)
  }

  const toggleCheck = (index) => {
    let updatedColumns = createCopy(currentPageColumns)
    updatedColumns[index].checked = !updatedColumns[index].checked
    setCurrentPageColumns(updatedColumns)

    const areAllItemsChecked = updatedColumns.find(el => el.checked === false) ? false : true
    setCheckedAll(areAllItemsChecked)

    updateColArrayForApi(updatedColumns)
  }

  const toggleAll = (clearAll = false) => {
    const newToggleValue = clearAll ? false : !checkedAll
    setCheckedAll(newToggleValue)

    let itemsCopy = createCopy(currentPageColumns)
    itemsCopy.forEach(el => el.checked = newToggleValue)
    setCurrentPageColumns(itemsCopy)

    updateColArrayForApi(itemsCopy)
  }

  //column icons for list of columns
  const getColumnIcon = (datatype) => {
    let dataType = DataTypes.find((el, i) => {
      return el.dataType === datatype
    })
    if (!dataType) return
    return dataType.icon
  }

  const classes = {
    container: `${toggle2on ? '' : 'hidden'}`,
    dropdownContainer: `slide_down dropdown-container transform transition-all delay-200 ${showDropdown ? 'opacity-100 scale-100' : 'opacity-10 scale-0 hidden'}`,
    dropdownToggle: `dropdown-toggle`,
    dropdownList: `bg-white overflow-auto my-1 `,
    multiSelectItem: `form-check px-2 py-1 cursor-pointer`,
    dropdownToggleIcon: `transform transition-transform delay-50 ${showDropdown ? 'rotate-180' : 'rotate-0'}`
  }

  const styles = {
    dropdownList: { maxHeight: '7rem', color: dark_grey },
    formCheckInput: { border: '1.5px solid #35A600', borderRadius: '3px', height: '1.3rem', width: '1.3rem', outline: 'none', cursor: 'pointer' }
  }

  return (
    <div className={classes.container}>

      {/* Dropdown button */}
      <div className={classes.dropdownToggle} style={{ height: '39px', borderColor: primary1 }} onClick={() => setShowDropdown(!showDropdown)}>
        <span>{multiSelectText1} {multiSelectText2}</span>
        <img src={dropdownToggleIcon} className={classes.dropdownToggleIcon} />
      </div>
      {/* Dropdown button */}

      {/* Dropdown */}
      <div className={classes.dropdownContainer}>
        
        <div onClick={() => toggleAll()} className='form-check-select-all mb-2 cursor-pointer pr-2'>
          Select-All
          <input type="checkbox" checked={checkedAll} style={{ ...styles.formCheckInput, backgroundColor: checkedAll ? '#35A600' : '#fff', }} readOnly />
        </div>

        {/* List of columns */}
        <ul className={classes.dropdownList} style={styles.dropdownList}>
          {
            currentPageColumns && currentPageColumns.map((column, i) => {
              return (
                <li key={`${column.name}-${i}`} onClick={() => toggleCheck(i)} className={classes.multiSelectItem} style={styles.multiSelectItem}>
                  <div className='flex gap-3 items-center justify-center'>
                    <img src={getColumnIcon(column.dataType)} className='w-5' />
                    {column.name}
                  </div>
                  <input type="checkbox" style={{ ...styles.formCheckInput, backgroundColor: column.checked ? '#35A600' : '#fff' }} checked={column.checked} readOnly />
                </li>
              )
            })
          }
        </ul>
        {/* List of columns */}

        <div className='button button1-outline mt-2' onClick={onDoneButtonClick}>Done</div>
      </div>
      {/* Dropdown */}

    </div>
  )
}

export default ColumnsMultiSelect