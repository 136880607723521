import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';
import CustomProgressBar from '../../../../../../partials/CustomProgressBar'
import CustomThreeDotMenu from '../../../../../../partials/CustomThreeDotMenu';
import ThreeDotMenu from '../../../../../../partials/ThreeDotMenu';

// icons and images
import pdfIcon from '../../../../../../images/add-edit row dialog/pdf.png'
import attachmentIcon from '../../../../../../images/datatype_icons/light/attachment.png'
import attachment_drag_n_drop_image from '../../../../../../images/add-edit row dialog/attch_drag_n_drop.png'
import downloadIcon from '../../../../../../images/add-edit row dialog/download-new.png'
import deleteIcon from '../../../../../../images/add-edit row dialog/delete-new.png'

const Attachment = ({ colkey, column, handleChange, formData, edited, shouldAllowNewEntry }) => {
  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)
  const theme = useSelector(state => state.theme)

  // route variables
  const location = useLocation()

  // state variables
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [selectedDropdownId, setSelectedDropdownId] = useState(-1)
  const [uploadedFilesArrExp, setUploadedFilesArrExp] = useState([])

  // ref variables
  const fileInputRef = useRef(null)
  const dropdown = useRef(null)
  const trigger = useRef(null)
  const drop = useRef(null)

  // local variables
  const { pathname } = location;
  const communityId = pathname.split('/')[2];
  const index = `${colkey}_details`
  const attachmentsArray = formData[index]
  const { dark_grey, red } = theme
  const { isAdmin, hasBasicEditPermission, editPermissions, rowDetailsViewType } = registerData
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colkey) === -1

  useEffect(() => {
    if (uploadedFilesArrExp.length === 0) {
      drop.current && drop.current.addEventListener('dragover', handleDragOver);
      drop.current && drop.current.addEventListener('drop', onFileChange);
      return () => {
        drop.current && drop.current.removeEventListener('dragover', handleDragOver);
        drop.current && drop.current.removeEventListener('drop', onFileChange);
      };
    }
  }, [attachmentsArray])

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current || !trigger.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
      setSelectedDropdownId(-1);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const getFilename = (url) => {
    if (url) {
      let m = url.toString().match(/.*\/(.+?)\./);
      if (m && m.length > 1) {
        return m[1];
      }
    }
    return "";
  }

  const onAttachmentClick = (i) => {
    if (selectedDropdownId === -1 && !dropdownOpen) {
      setSelectedDropdownId(i)
      setDropdownOpen(true)
    } else {
      setSelectedDropdownId(-1)
      setDropdownOpen(false)
    }
  }

  const progressCallback = (uploadedFiles) => {
    setUploadedFilesArrExp([])
    let newFilesArray = [...attachmentsArray]
    uploadedFiles.forEach((el) => {
      newFilesArray.push({ value: el.url })
    })
    handleChange({ colkeyDetail: `${colkey}_details`, valueDetail: newFilesArray })
  }

  const onFileChange = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer || e.target;
    setUploadedFilesArrExp(files)
  }

  useEffect(() => {
    // "inputHasSomeFiles" needs to be checked as when cancelled is pressed everytime then we need to
    // clear the input type='file' as it wont allow repeated file select.
    const inputHasSomeFiles = fileInputRef.current && fileInputRef.current.value.length > 0
    if (!edited && inputHasSomeFiles) {
      fileInputRef.current.value = ''
    }
  }, [edited])

  const deleteAttachment = (index) => {
    const newAttachmentsArray = [...attachmentsArray]
    newAttachmentsArray.splice(index, 1)
    handleChange({ colkeyDetail: `${colkey}_details`, valueDetail: newAttachmentsArray })
  }

  return (
    <div className='mb-6'>

      {/* Hidden file selector starts */}
      <input ref={fileInputRef} type="file" onChange={onFileChange} id={`attch-btn-${colkey}`} hidden multiple />
      {/* Hidden file selector ends */}

      {/* ColName and Attachment count starts */}
      <div className={`flex  ${rowDetailsViewType == 'two'? 'flex-col': 'justify-between items-center'}`}>
        <div className='colName'>
          <img src={attachmentIcon} className="field_icon" />
          <label htmlFor={column.name}>{column.name}</label>
        </div>
        {
          attachmentsArray && attachmentsArray.length > 0 &&
          <span className={`text-sm whitespace-nowrap ${rowDetailsViewType == 'two'? 'ml-6 pl-1': ''}`} style={{ color: '#878787' }}>
            {attachmentsArray.length} Attachment{attachmentsArray.length > 1 ? 's' : ''}
          </span>
        }
      </div>
      {/* ColName and Attachment count starts */}

      {
        attachmentsArray && attachmentsArray.length > 0 ?
          <>
            {/* List of attachments */}
            <ul>
              {
                attachmentsArray.map((attch, i) => {
                  return (
                    <li className='px-1 xs:px-2 sm:px-6 py-3 border-b flex justify-between items-center' style={{ borderColor: '#E0E0E0' }}>
                      <div className='flex gap-2 lg:gap-4 xl:gap-6 items-center truncate'>
                        <img src={pdfIcon} className='h-5' />
                        {getFilename(attch.value)}
                      </div>
                      {
                        (canEdit || shouldAllowNewEntry) &&
                        <ThreeDotMenu color={'gray'} alignment={'vertical'}>
                          <a href={attch.value} target="_blank" className='threeDotListItem'>
                            <img src={downloadIcon} className='h-4' />
                            <span style={{ color: dark_grey }}>Download</span>
                          </a>
                          <div onClick={() => deleteAttachment(i)} className='threeDotListItem'>
                            <img src={deleteIcon} className='h-4' />
                            <span style={{ color: red }}>Delete</span>
                          </div>
                        </ThreeDotMenu>
                      }
                    </li>
                  )
                })
              }
            </ul>
            {/* List of attachments */}

            {/* Add New Image button */}
            {
              (canEdit || shouldAllowNewEntry) &&
              <div className='flex justify-center pt-6'>
                <button
                  onClick={(e) => { e.preventDefault(); fileInputRef.current.click() }}
                  className='secondary_btn transition-all delay-50 px-4 py-1'
                >
                  + Add New
                </button>
              </div>
            }
            {/* Add New Image button */}

          </>
          :

          // Drag n Drop
          (canEdit || shouldAllowNewEntry) ?
            <img ref={drop} src={attachment_drag_n_drop_image} onClick={() => fileInputRef.current.click()} className='w-full cursor-pointer' />
            :
            <div className="field truncate">No Attachments</div>

      }

      {
        uploadedFilesArrExp.length > 0 &&
        <CustomProgressBar
          url={'otherservice/uploadFile'}
          files={uploadedFilesArrExp}
          communityId={communityId}
          getUploadedFilesClbk={progressCallback}
          outerContainerStyle={{ height: '3rem', width: '100%' }}
          progressBarStyle={{ backgroundColor: '#C4C4C4' }}
          progressDirection='horizontal'
        />
      }
    </div>
  )
}

export default Attachment