//images
import editIcon from '../../../../../images/left-sidebar/edit-black.png'

const RegisterElement = ({ registerEl, selectedRegisterId, onEditIconClick, onRegisterFolderNameClick, hasParent }) => {
  const bg_blue_300_class = registerEl.registerId === selectedRegisterId ? 'selectedRegisterElement' : ''
  const invisible_class = registerEl.registerId === selectedRegisterId ? '' : 'xs:invisible'
  const padding_left_class = hasParent ? 'pl-8' : ''
  const classes = {
    container: `${bg_blue_300_class} p-5 registerElement`,
    subContainer: `${padding_left_class} flex truncate items-center`,
    avatar: "w-9 h-9 border rounded-full border-gray-300 bg-gray-300",
    registerName: `w-full text-black pl-4 font-semibold truncate`,
    editIcon: `${invisible_class} h-5`
  }
  const styles = {
    registerName: {fontSize: '1rem'}
  }

  return (
    <div
      key={`sidebar/registerElement/${registerEl.registerId}`}
      onClick={() => onRegisterFolderNameClick(registerEl)}
      className={classes.container}
    >
      <div className={classes.subContainer}>
        <img className={classes.avatar} src={registerEl.avatar} />
        <h1 className={classes.registerName} style={styles.registerName}>{registerEl.name}</h1>
        <img onClick={(e) => {
          e.stopPropagation() // is a temporaryFix
          onEditIconClick(registerEl.registerId)
        }} src={editIcon} className={classes.editIcon} />
      </div>
    </div>
  )
}

export default RegisterElement