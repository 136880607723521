import React, { useEffect, useState } from 'react';

// components
import DownloadPopup from '../popUps/Download'

// images
import downloadCyan from "../../../../images/downloadCyan.png"
import downloadMobile from "../../../../images/downloadMobile.png"

const Download = () => {

  // state variables
  const [downloadPopup, setDownloadPopup] = useState(false)

  return (
    <>
      <div onClick={() => { setDownloadPopup(true) }} className="child-in-center self-center justify-self-end shadow-lg px-2 mr-3 sm:mr-5 cursor-pointer" style={{ border: '1px solid #CAE2FF', borderRadius: 4, height: '32px', maxHeight: '90%' }}>

        <div className='hidden sm:flex justify-center items-center'>
          <span className="pr-1" style={{ color: '#CAE2FF', fontSize: 13 }}>Download</span>
          <img src={downloadCyan} alt="cyan download icon" style={{ width: 10, height: 11 }} />
        </div>

        {/* mobile */}
        <img src={downloadMobile} className='sm:hidden h-4' />
        {/* mobile */}

      </div>
      {downloadPopup && <DownloadPopup key="dd" closePopup={() => setDownloadPopup(false)} />}
    </>
  )
}

export default Download