import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//images
import noComments from '../../../../../../../../images/noComments.png'
import greyEdit from '../../../../../../../../images/greyEdit.png'
import darkGreyCancel from '../../../../../../../../images/darkGreyCancel.png'
import deleteIcon from '../../../../../../../../images/add-edit row dialog/delete-new.png'
import deleteIcon2 from '../../../../../../../../images/add-edit row dialog/delete_red_bg.png'

// slices
import { getComments, updateRowDetailState, deleteComment, setEditCommentIdx } from "../../../../../../../../slices/RowDetailSlice"
import { toggleDialogOpen, updateBody, updateHeaderText, hideHeader } from '../../../../../../../../slices/DialogSlice'

// components
import CustomThreeDotMenu from '../../../../../../../../partials/CustomThreeDotMenu'

const moment = require('moment');

const Comments = () => {

  // redux variable
  const dispatch = useDispatch()
  const rowDetailData = useSelector(state => state.rowDetail)
  const registerData = useSelector(state => state.register)
  const theme = useSelector(state => state.theme)

  // ref variables
  const comments_list_container_div = useRef(null)

  // local variables
  const { sheetId, rowIndex, rows, permissions } = registerData
  const { comments, editCommentIdx } = rowDetailData
  const { green, grey, dark_grey, red } = theme
  const deleteText = `Are you sure you want to Delete this Comment?`
  const rowId = rowIndex > -1 ? rows[rowIndex].rowId : ''

  useEffect(() => {
    if (rowIndex > -1) {
      dispatch(getComments({ sheetId, rowId: rows[rowIndex].rowId }))
    }
    return () => {
      dispatch(updateRowDetailState([{ key: "comments", value: [] }]))
    }
  }, [])

  useEffect(() => {
    if (comments_list_container_div.current) {
      let scrollHeight = comments_list_container_div.current.scrollHeight
      comments_list_container_div.current.scrollTo({ top: scrollHeight })
    }
  }, [comments])

  const handleEditBtnClick = (commentIdx) => {
    if (permissions.includes("admin")) {
      dispatch(setEditCommentIdx(commentIdx))
    } else {
      alert("Permission Denied")
    }
  }

  const handleDeleteButtonClick = (commentIdx) => {
    if (permissions.includes("admin")) {
      dispatch(hideHeader())
      dispatch(toggleDialogOpen(true))
      dispatch(updateBody(getDeleteBody(commentIdx)))
      dispatch(updateHeaderText('Delete Row?'))
    } else {
      alert("Permission Denied")
    }
  }

  const getDeleteBody = (commentIdx) => {
    const commentId = comments[commentIdx].commentId
    return (
      <div className="bg-white flex flex-col justify-center rounded-lg px-6" style={{ width: '25rem', maxWidth: '95vw' }}>
        <div className='flex items-start justify-center gap-3 mt-6 mb-3'>
          <img src={deleteIcon2} className='h-8' />
          <div className="text-lg text-center" style={{ color: dark_grey }}>{deleteText}</div>
        </div>
        {/* Buttons */}
        <div className="flex justify-end items-center gap-4 mt-3 mb-6">
          <button onClick={(e) => { e.preventDefault(); dispatch(toggleDialogOpen(false)) }} className="py-1 px-4" style={{ color: grey }}>Cancel</button>
          <button onClick={(e) => { e.preventDefault(); dispatch(deleteComment({ commentId, sheetId, rowId, commentIdx })); dispatch(toggleDialogOpen(false)) }} className="text-white rounded py-1 px-6" style={{ backgroundColor: red }}>Delete</button>
        </div>
        {/* Buttons */}
      </div>
    )
  }

  const listData = [
    { icon: greyEdit, label: 'Edit', onClick: handleEditBtnClick, showItem: true, style: { color: dark_grey }, iconClass: `h-4`, showLoader: false },
    { icon: deleteIcon, label: 'Delete', onClick: handleDeleteButtonClick, showItem: true, style: { color: red }, iconClass: `h-4`, showLoader: false }
  ]

  return (
    <>
      {
        comments.length === 0 ?
          <div className="child-in-center h-full">
            <img  style={{ width: "8rem", height: "8rem" }} src={noComments} alt="no comments" loading='lazy' />
          </div>
          :
          <div ref={comments_list_container_div} className="overflow-y-scroll no-scrollbar" style={{ height: "100%" }}>
            {rowIndex > -1 && comments.map((c, i) => {
              return (
                <div key={`comments-${i}`} className="flex flex-col">
                  <div className={`flex mt-2 pt-2 pb-4 pr-2 pl-2 cursor-pointer addCommentFocus ${editCommentIdx === i && 'commentBeingEditd'}`} style={{ borderBottomWidth: 0.5 }}>
                    {/*--------- User Profile Picture starts here ---------------*/}
                    <div className="flex justify-center" style={{ width: "20%" }}>
                      <div className="flex justify-center bg-gray-400 rounded-full overflow-hidden" style={{ height: "2.5rem", width: "2.5rem" }}>
                        <img style={{ height: "100%", width: "100%" }} src={c.userProfilePic} />
                      </div>
                    </div>
                    {/*--------- User Profile Picture ends here ---------------*/}

                    {/*--------- Username and comment text starts here ---------------*/}
                    <div className="relative flex flex-col" style={{ width: "80%" }}>
                      {/*--------- Three dots ---------------*/}
                      <div className="absolute flex justify-end items-end pb-1 right-0" style={{ width: "10%" }}>
                        <CustomThreeDotMenu showDropdown={true} color={'gray'} colorWhenOpen={'blue'} listData={listData} itemIdx={i} />
                      </div>
                      {/*--------- User name ---------------*/}
                      <div className="truncate pr-6" style={{ fontSize: "0.9rem", color: "#545454" }}>{c.userName}</div>
                      {/*--------- Comment Time ---------------*/}
                      <div className="flex justify-start items-center" style={{ color: "#B6B6B6", fontSize: "0.6rem" }}>
                        {moment(c.time).format('DD MMM.YY hh:mm a')}
                      </div>
                      {/*--------- Comment Text ---------------*/}
                      <p style={{ fontSize: "0.8rem", color: "#878787", paddingTop: "0.1rem", wordWrap: 'break-word' }} >{c.text}</p>
                    </div>
                  </div>
                </div>

              )
            })}
          </div>
      }
    </>
  )
}

export default Comments


