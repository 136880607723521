import { useState } from 'react'
import { useSelector } from "react-redux"

// components
import ErrorIcon from './Components/ErrorIcon'
import AppliedIndicator from './Components/AppliedIndicator'

// utils
import { getColumnIcon } from '../../../../../../../Register/Table/Utils'

//icons
import slNoIcon from '../../../../../../../../images/datatype_icons/dark/sl-no.png'

// updatedFilters used because the requirement is to remove filter indicator during selections as well.
const ColumnsList = ({ setSelectedfilterData, setSelectedCol, selectedCol, updatedFilters, errorColumns }) => {

  // redux variables
  const registerData = useSelector(state => state.register)

  // state variables
  const [searchQuery, setSearchQuery] = useState('')

  // local variables
  const { columns } = registerData
  const eligibleColumnsForFilter = ['String', 'date', 'rupee', 'number', 'phoneNumber', 'status', 'dropDown', 'switch', 'formula', 'unit', 'duration', 'reminder', 'time', 'label', 'createdAt', 'createdBy', 'mail', 'url', 'linkedSheet']
  const filterSearch = (el) => {
    return (
      (
        searchQuery.trim().length > 0 && el.name.toLowerCase().includes(searchQuery.toLowerCase())
      ) ||
      searchQuery.trim().length === 0
    )
  }

  const onColumnClick = (col) => {
    // if ensures that on same click col selection is not called, as it was not changing the selcted column but made 
    // filter data empty
    if (selectedCol.key !== col.key) {
      // very important to do as when column changes 
      // prior to that the filter data should be reset
      // otherwise the old value reaches the other column type
      // as first column type changes during which the other column settings ( range, between or whatever )
      // renders, and if we use selectedFilterData as a variable in useEffect to prefill the seleted value
      // then the type of value doesnt match
      // Eg: text filter selectedFilterData [a,b,c] was reaching date Range filter
      // (where date values are set on the basis of selectedFilterData initially)
      // hence the dtae picker was taking invalid values
      setSelectedfilterData([])
      setSelectedCol(col)
    }

  }

  return (
    <div className="sm:px-2 flex flex-col w-60">
      <div className='child-in-center flex-shrink-0' style={{ height: '4rem' }}>
        <input onChange={e => setSearchQuery(e.target.value)} placeholder="Search Column Name" className="inputCommon w-full pl-2 sm:pl-4 truncate" style={{ borderRadius: '38px' }} />
      </div>

      <div className="overflow-y-auto sm:px-2 mb-2 flex-grow-0" style={{ borderRadius: '4px', border: '0.5px solid #B6B6B6' }}>
        <ul style={{ fontWeight: '600', color: '#545454' }}>
          {
            columns.map((colEl) => {
              const isAnEligibleColumn = colEl.key !== 'addColPlusButton' && eligibleColumnsForFilter.indexOf(colEl.dataType) > -1
              const isSno = colEl.key === '-1'
              const fitsSearchCriteria = filterSearch(colEl)
              if ((isSno || isAnEligibleColumn) && fitsSearchCriteria)
                return (
                  <li
                    onClick={() => onColumnClick(colEl)}
                    className={`${selectedCol.key === colEl.key ? 'bg-gray-100' : ''} flex items-center relative border-b hover:bg-gray-100 pl-2 pr-2 sm:pr-0 py-3 transition-all delay-50 cursor-pointer`}
                  >
                    {
                      colEl.key == -1 ?
                        <img className="inline-block mr-2 sm:mr-4 w-5" src={slNoIcon} /> :
                        <img className="inline-block mr-2 sm:mr-4 w-5" src={getColumnIcon(colEl.dataType)} />
                    }

                    <span className='truncate pr-4'>{colEl.name}</span>
                    {
                      errorColumns.indexOf(colEl.key) > -1 ?
                        <ErrorIcon />
                        :
                        <AppliedIndicator colEl={colEl} updatedFilters={updatedFilters} />
                    }
                  </li>

                )
            })
          }
        </ul>
      </div>
    </div>
  )
}

export default ColumnsList

