const Terms = () => {

  const handlePrivacyPolicyClick = () => {
    window.open('https://www.rekord.in/mensa-internet-private-limited-privacy-policy', '_blank');
  }

  const handleTermsClick = () => {
    window.open('https://www.rekord.in/terms-of-use', '_blank');
  }

  return (
    <div style={{color: '#6C757D', fontSize: '8px'}} className="font-medium">
      By clicking the button above, you agree to 
      our <span onClick={handleTermsClick} className="underline cursor-pointer">Terms of Service</span> & <span onClick={handlePrivacyPolicyClick} className="underline cursor-pointer">Privacy Policy</span>
      
    </div>
  )
}

export default Terms