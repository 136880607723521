import { useState } from "react"

//components
import LoginByEmail from "./Components/LoginByEmail"
import LoginByPhoneNumber from "./Components/LoginByPhoneNumber"
import LoginByQR from "./Components/LoginByQR"

const LOGIN_METHODS = {
  EMAIL: "email",
  PHONE_NUMBER: "phone-number",
  QR_CODE: "qr-code",
}

const LoginContainer = () => {

  // state variables
  const [loginMethod, setLoginMethod] = useState(LOGIN_METHODS.EMAIL)
  const [loading, setLoading] = useState(false)

  const handleLoginMethodChange = (method) => {
    setLoading(true)
    setTimeout(() => {
      setLoginMethod(method)
      setLoading(false)
    }, 300)
  }

  const containerStyles = { backgroundColor: '#F8F9FA', border: '1px solid #CED4DA', borderRadius: '40px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05)', width: '600px', maxWidth: '95%', height: '95%' }

  const loginProps = { handleLoginMethodChange, LOGIN_METHODS }

  return (
    <div className={`overflow-hidden ${loginMethod == LOGIN_METHODS.EMAIL? '': 'p-8'}`} style={containerStyles}>

      {loading && <div className="h-full child-in-center"><div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-700 m-auto "></div></div>}

      {!loading && loginMethod === LOGIN_METHODS.EMAIL && (
        <LoginByEmail {...loginProps} />
      )}

      {!loading && loginMethod === LOGIN_METHODS.PHONE_NUMBER && (
        <LoginByPhoneNumber {...loginProps} />
      )}

      {!loading && loginMethod === LOGIN_METHODS.QR_CODE && (
        <LoginByQR {...loginProps} />
      )}

    </div>
  )
}

export default LoginContainer