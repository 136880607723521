import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

//icons
import grayIcon from '../images/3-dots-gray.png'

const ThreeDotMenu = ({ color, colorWhenOpen, alignment, direction, itemIdx, children }) => {

  //redux variables
  const theme = useSelector(state => state.theme)

  //state variables
  const [show, setShow] = useState(false)

  //local variables
  const { secondary } = theme

  useEffect(() => {
    const clickHandler = () => {
      if (show) setShow(false)
    }
    if (show) {
      document.addEventListener('click', clickHandler)
    }
    return () => document.removeEventListener('click', clickHandler)
  }, [show])

  const getIconSrc = () => {
    if (color == 'gray') return grayIcon
    return
  }

  // classes
  const container = `relative rounded transition-colors delay-50`
  const icon = `transform cursor-pointer ${alignment == 'vertical' ? 'rotate-90 h-5' : 'w-5'}`
  const menuContainer = `
    absolute slide_down_50 rounded w-40 bg-white
    ${alignment == 'vertical' ?
      `top-1/2 ${direction == 'right' ? 'left-3/4' : 'right-3/4'}` :
      `top-3/4 ${direction == 'right' ? 'left-1/2' : 'right-1/2'}`
    }
  `
  
  //styles
  const containerStyle = { backgroundColor: show ? secondary : '' }
  const menuContainerStyle = { boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }

  return (
    <div className={container} style={containerStyle}>

      {/* 3 dots */}
      <img src={getIconSrc()} onClick={(e) => { e.stopPropagation(); setShow(!show) }} className={icon} />
      {/* 3 dots */}

      {/* dropdown menu starts */}
      {
        show &&
        <div className={menuContainer} style={menuContainerStyle}>
          {children}
        </div>
      }
      {/* dropdown menu ends */}

    </div>
  )
}



export default ThreeDotMenu

