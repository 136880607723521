import noResultIcon from '../../../../../../images/linked_no_result.png'

const NoResults = () => {
  return (
    <div className="h-full child-in-center flex-col gap-5">
      <img src={noResultIcon} className='h-28' />
      <div className="font-medium" style={{color: '#545454'}}>No entries found for this column</div>
    </div>
  )
}

export default NoResults