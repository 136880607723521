import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux'

// utils
import ErrorBoundary from '../../../../../utils/ErrorBoundary'

//components
import CustomInputDropdown from '../../../../../partials/CustomInputDropdown'
import DropdownBody from '../../../Form/Components/RowDetailForm/Components/Duration/DropdownBody'

const DurationEditor = ({ rowProperties, saveCurrentRowData, colIdx, columnEl }) => {

  // redux variables
  const registerData = useSelector(state => state.register)

  //state variables
  const [componentDurationValue, setComponentDurationValue] = useState('')
  const [showDropDownMenu, setShowDropDownMenu] = useState(true)
  
  // local variables
  const colKey = columnEl.key
  const onClose = rowProperties.onClose
  const rowIndex = rowProperties.rowIdx
  const rowId = rowProperties.row.rowId
  const value = rowProperties.row[colKey]
  const { isAdmin, hasBasicEditPermission, editPermissions } = registerData
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colKey) === -1

  useEffect(() => {
    document.addEventListener('keydown', keyHandler);
    return () => {
      document.removeEventListener('keydown', keyHandler);
    }
  }, [])

  const keyHandler = (e) => {

    // tab key press
    if (e.keyCode == 9) {

      // When the last element is focused by tabbing and we press tab again,
      // the focus should return back to the pre selected cell.
      // We shift the focus on switch-editor-container-div as it has tabIndex -1.
      // Due to this value of tab index the focus automatically moves to the very next element with a positive
      // tabIndex.
      if (document.activeElement.id === 'duration-editor-remove-entry-anchor') {
        document.getElementById('duration-editor-container-div').focus();
      }
    }
    if (e.keyCode !== 27) return
  };

  // handles key events of the editor container div
  const handleContainerDivKeyDown = (e) => {
    // enter key press
    if (e.keyCode == 13) {
      // Trigger click of the active element on which the focus is.
      document.activeElement.click();
      onClose(true)
    }
  }

  const onContainerClick = (e) => {
    e.preventDefault()
    // If a person clicks pre seleceted option, 
    // then we should close the dropdown (setDropdownOpen) and thus the editor ( onClose )
    // if (dropdownOpen === true) onClose(true)
  }

  const onSaveButtonClick = () => {
    if(componentDurationValue.length == 0) return
    saveCurrentRowData(componentDurationValue, colKey, rowId, registerData.rows)
    onClose(true)
  }

  const onRemoveIconClick = (e) => {
    e.preventDefault()
    saveCurrentRowData('', colKey, rowId, registerData.rows)
  }

  return (
    <ErrorBoundary>
      <div className="w-full h-full" tabIndex="-1" id="duration-editor-container-div" onKeyDown={handleContainerDivKeyDown} onClick={onContainerClick}>
        <CustomInputDropdown
          fieldValue={value}
          inputType={`div`}
          container={`grid`}
          inputClasses={`w-full h-full`}
          inputStyles={{}}
          dropdownClasses={``}
          dropdownStyles={{ marginTop: 8, minWidth: '25rem' }}
          outerDivClasses={`w-full h-full`}
          buttonsInfo={{ showButtonsSection: true, showCancelButton: true, showSaveButton: canEdit && true, onSaveButtonClick, onCancelButtonClick:()=>onClose(true) }}
          showRemoveIcon={true}
          onRemoveIconClick={onRemoveIconClick}
          showDropDownMenu={showDropDownMenu}
          setShowDropDownMenu={setShowDropDownMenu}
          onFieldClick={()=>{}}
        >
          <DropdownBody
            setComponentDurationValue={setComponentDurationValue}
            initialFormValue={value}
            saveDurationData={onSaveButtonClick}
          />
        </CustomInputDropdown>

      </div>
    </ErrorBoundary>
  )
}

export default DurationEditor