import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom';

// slices
import { createRegister } from '../../../../slices/LeftSidebarSlice'

// components
import PopUp from '../../../../partials/Dialog/PopUp'
import Header from './Components/Header'
import CustomInputText from '../../../../partials/CustomInputText'
import CustomButton from '../../../../partials/CustomButton'

const CreateNewRegisterPopUp = ({ isOpen }) => {

	// router variables
	const history = useHistory()
	const location = useLocation()

	// redux variables
	const dispatch = useDispatch()
	const routerData = useSelector(state => state.routerStore)
	const leftSidebarData = useSelector(state => state.leftSidebar)

	// state variables
	const [isInternallyOpen, setIsInternallyOpen] = useState(isOpen)
	const [registerName, setRegisterName] = useState('')

	// local variables
	const { popUpNavigationHistory } = routerData// requirement says that if there are no businessList then pop up should not be allowed to close
	const { activeBusinessId } = leftSidebarData

	// close if the esc key is pressed- sidebar menu
	useEffect(() => {
		const keyHandler = ({ keyCode }) => {
			if (!isInternallyOpen || keyCode !== 27) return;
			closePopUp()
		};
		document.addEventListener('keydown', keyHandler);
		return () => document.removeEventListener('keydown', keyHandler);
	}, [isOpen]);

	const closePopUp = () => {
		history.push(location.pathname)
		setIsInternallyOpen(false)
	}

	const onRegisterNameChange = (e) => {
		setRegisterName(e.target.value)
	}

	const createNewRegister = () => {
		if (registerName.trim().length === 0) return
		closePopUp()
		dispatch(createRegister({ registerName, businessId: activeBusinessId }))
	}

	const classes = {
		header: "w-full text-white flex rounded-t py-1 px-5 text-3xl",
		body: "w-full h-full flex flex-col justify-center items-center",
		footer: "border-t w-full flex justify-end items-center p-2"
	}

	const styles = {
		header: { height: '25%', background: '#586C9E', fontSize: 18, fontStyle: 'normal', fontFamily: 'Sans-serif' },
		body: { height: '12rem', width: '22rem' }
	}

	if (isInternallyOpen) {
		return (
			<PopUp
				onClose={closePopUp}
				popUpContainerStyle={styles.body}
				body={(
					<div className="w-full h-full flex flex-col">
						<div
							className={classes.header}
							style={styles.header}
						>
							<Header closePopUp={closePopUp} />
						</div>
						{/*Body container starts here */}
						<div
							className={classes.body}
							style={{ height: '75%', backgroundColor: '#EEF3F5' }}
						>
							{/* Register name label and input box container starts  */}
							<div className="w-full h-2/3 flex justify-center items-center">
								<CustomInputText onChange={onRegisterNameChange} value={registerName} autoFocus={true} />
							</div>
							{/* Register name label and input box container ends  */}

							{/* Create Register button container starts  */}
							<div className="w-full h-1/3 flex justify-center">
								<CustomButton
									text={'CREATE'}
									disabled={registerName.trim().length === 0}
									containerStyle={{ width: '90%', height: '70%', borderRadius: 3, backgroundColor: registerName.trim().length === 0 ? '#a3afcc' : '#586C9E' }}
									onClick={createNewRegister}
								/>
							</div>
							{/* Create Register button container ends  */}
						</div>
						{/*Body container ends here */}
					</div>
				)}
			/>
		)
	} else {
		return <></>
	}

}

export default CreateNewRegisterPopUp