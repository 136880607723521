import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleDialogOpen, updateBody, updateHeaderText } from '../../../../../../slices/DialogSlice'
import MapDialog from '../../../../Table/Editors/DataCell/Location/MapDialog'
import Dropdown from '../../../../../../partials/Dropdown'

import locationIcon from '../../../../../../images/datatype_icons/light/location.png'
import editIcon from '../../../../../../images/add-edit row dialog/form icons/edit.png'
import deleteIcon from '../../../../../../images/add-edit row dialog/form icons/delete.png'
import directionIcon from '../../../../../../images/add-edit row dialog/form icons/direction.png'
import dropDownIcon from '../../../../../../images/add-edit row dialog/drop-down.png'

const Location = ({ colkey, column, handleChange, formData, deleteEntry }) => {

  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  // state variables
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [showMap, setShowMap] = useState(false)
  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)

  // ref variables
  const dropdown = useRef(null)
  const trigger = useRef(null)
  const map = useRef(null)

  // local variables
  const location = formData[colkey] && formData[colkey + '_details'] && formData[colkey + '_details'][0] ? (formData[colkey + '_details'][0].value ? formData[colkey + '_details'][0].value : '') : ''
  const { isAdmin, hasBasicEditPermission, editPermissions } = registerData
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colkey) === -1

  // close on click outside - dropdown
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current || !trigger.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close on click outside - map
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!map.current) return;
      if (!showMap || map.current.contains(target)) return;
      setShowMap(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  useEffect(() => {
    if (!dropdownOpen) {
      setLatitude(null)
      setLongitude(null)
    }
  }, [dropdownOpen])

  const onFieldClick = (e) => {
    e.preventDefault()
    if (location) {
      setDropdownOpen(!dropdownOpen)
      const index = `${colkey}_details`
      const locationObject = formData[index][0]
      if (locationObject) {
        // these checks in set methods are done incase of change of data types there is value and id key and no geoLocation key when the editor opens
        setLatitude(locationObject.geoLocation ? locationObject.geoLocation.lat : 0)
        setLongitude(locationObject.geoLocation ? locationObject.geoLocation.lng : 0)
      }
    } else if (canEdit) {
      dispatch(toggleDialogOpen(true))
      dispatch(updateHeaderText(column.name))
      dispatch(updateBody(<MapDialog saveLocationData={handleLocationChange} />))
    }
  }

  const onEditOptionClick = (e) => {
    e.preventDefault()
    const lat = parseFloat(latitude)
    const lng = parseFloat(longitude)
    const center = { lat, lng }
    dispatch(toggleDialogOpen(true))
    dispatch(updateHeaderText(column.name))
    dispatch(updateBody(<MapDialog location={location} center={center} saveLocationData={handleLocationChange} />))
  }

  const onDeleteOptionClick = (e) => {
    e.preventDefault()
    deleteEntry(colkey)
    setDropdownOpen(false)
  }

  const handleLocationChange = ({ address, latLng }) => {
    handleChange({ colkey, value: address, colkeyDetail: colkey + '_details', valueDetail: [{ value: address, geoLocation: latLng }] })
  }

  const closeDropdown = () => {
    setDropdownOpen(false)
  }

  //classes
  const list_item_class = `list_item gap-1 md:gap-2 lg:gap-4 font-semibold w-full transition-all delay-50 ease-in cursor-pointer whitespace-nowrap px-1 md:px-2 lg:px-5 py-2 rounded`

  return (

    <div className="mb-6">
      <div className="colName">
        <img src={locationIcon} className="field_icon" alt="" />
        <label htmlFor={column.name}>{column.name}</label>
      </div>

      <div className='relative flex items-center'>
        
        <div ref={trigger} onClick={onFieldClick} className="relative cursor-pointer field flex items-center overflow-hidden pr-8" >
          <span className="truncate">{location}</span>
          <img className={`drop-down-icon transform transition-transform delay-50 ${dropdownOpen ? 'rotate-180' : 'rotate-0'}`} src={dropDownIcon} />
        </div>

        {
          dropdownOpen &&
          <Dropdown showDropdown={dropdownOpen} closeDropdownCallback={closeDropdown} containerClasses={`mt-2 w-full`}>
            <ul className="py-2" style={{ fontSize: '14px' }}>

              <a href={`https://maps.google.com/?q=${latitude},${longitude}`} target="_blank">
                <li className={list_item_class}>
                  <img src={directionIcon} className='h-4 md:h-6' alt="" />
                  Get Direction
                </li>
              </a>

              {
                canEdit &&
                <li>
                  <button
                    onClick={onEditOptionClick}
                    className={list_item_class}
                  >
                    <img src={editIcon} className='h-4 md:h-6' alt="" />
                    Edit Location
                  </button>
                </li>
              }

              {
                canEdit &&
                <button className="w-full" onClick={onDeleteOptionClick}>
                  <li
                    className={list_item_class}
                    style={{ color: '#D81205' }}
                  >
                    <img src={deleteIcon} className='h-4 md:h-5 pr-1' alt="" />
                    Delete
                  </li>
                </button>
              }

            </ul>
          </Dropdown>
        }
      </div>
    </div>

  )
}

export default Location