const AddEntry = () => {
  return (
    <div
      onClick={() => document.getElementById('date-picker-portal-server').click()}
      className="hidden md:flex p-2 justify-center items-center rounded ml-4 cursor-pointer"
      style={{ backgroundColor: '#EAF1F8', color: "#044089", maxWidth: '30%' }}
    >
      <span className="pr-1 mb-1" style={{ fontSize: 18 }}>+</span>
      <p className="truncate" style={{ fontSize: 12 }}>Add entry</p>
    </div>
  )
}

export default AddEntry