const TextItem = ({ selectValue, text, itemIndex, selectedfilterData }) => {

  const styles = {
    formCheckInput: { border: '1.5px solid #35A600', borderRadius: '3px', height: '1.3rem', width: '1.3rem', outline: 'none', cursor: 'pointer' }
  }

  return (
    <li onClick={() => selectValue(text)} key={`${itemIndex}-filter-text`} className='form-check cursor-pointer pl-3 pr-1 py-1'>
      <span className="truncate">{text === 'NONE' ? 'Blank Value' : text}</span>
      <input
        type="checkbox"
        style={{ ...styles.formCheckInput, backgroundColor: selectedfilterData.indexOf(text) > -1 ? '#35A600' : '#fff' }}
        checked={selectedfilterData.indexOf(text) > -1} readOnly
      />
    </li>
  )
}

export default TextItem