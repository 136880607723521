
const Colors = ({ colors, onColorSelect }) => {
  return (
    <div className="grid grid-cols-4 gap-4">
      {
        colors.map( (color, i) => {
          return (
            <div
              onClick={()=> { onColorSelect(color) }} 
              className="h-8 w-20 rounded-sm border cursor-pointer" 
              style={{background: color}}
              key={i}
            >
            </div>  
          )      
        })  
      }
    </div>
  )
}

export default Colors