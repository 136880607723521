import { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { createPortal } from "react-dom"

//slices
import { resetDummyRowDetails, setDummyRowDetails } from "../slices/DummyRowSlice"

const DummyRow = () => {

  //redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)
  const dummyRowData = useSelector(state => state.dummyRow)

  //ref variables
  const dummyRowRef = useRef(null)

  //state variables
  const [opacity, setOpacity] = useState(`opacity-100`)
  const [translateY, setTranslateY] = useState('0')
  const [newColumns, setNewColumns] = useState([])
  const [sameRow, setSameRow] = useState(false)

  //local variables
  const { showDummyRow, animationStartSlNo, rowId } = dummyRowData
  const { columns, rows } = registerData

  useEffect(() => {
    if (showDummyRow) {
      setTimeout(() => {
        let row = rows.filter((row, i) => {
          return row.rowId == rowId
        })
        console.log('row:', row)
        let animationEndSlNo = row[0][-1]

        if (animationEndSlNo == '') return

        if (animationEndSlNo > animationStartSlNo) {
          setTranslateY((animationEndSlNo-animationStartSlNo)*40)
        } else if (animationEndSlNo < animationStartSlNo) {
          setTranslateY(-(animationStartSlNo - animationEndSlNo)*40)
        } else{
          setSameRow(true)
        }
        setOpacity(`opacity-90`)
      }, 5)

      setTimeout(() => {
        dispatch(resetDummyRowDetails())
      }, 3000)
    }
  }, [showDummyRow])

  useEffect(() => {
    let newColsFrozen = columns.filter((col, i) => {
      return col.isFreezed == true
    })
    let unfrozenCols = columns.filter((col, i) => {
      return col.isFreezed == false
    })
    let newCols = [...newColsFrozen, ...unfrozenCols]
    setNewColumns(newCols)
  }, [columns])

  return (
    <>
      {!sameRow &&
        createPortal(
          <div
            ref={dummyRowRef}
            className={`fixed flex bg-white transform transition-all ease-out duration-4000 ${opacity}`}
            style={{ height: '40px', top: 40 * animationStartSlNo, transform: `translate(0px, ${translateY}px)` }}
          >
            {newColumns && newColumns.map((col, i) => {
              if (col.dataType == 'staticBorderlessCell') return
              if (col.key == -1) {
                return (
                  <div style={{ width: 80, height: '100%', backgroundColor: 'gray' }} className='flex justify-center items-center border-2'></div>
                )
              } else {
                return (
                  <div style={{ width: col.width, height: '100%' }} className='flex justify-center items-center border-2'>{ }</div>
                )
              }
            })}

          </div>,
          document.querySelector(".rdg-light")
        )
      }
    </>
  )
}

export default DummyRow