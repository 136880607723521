const Footer = ({ save, clearAll, closeOnCancel }) => {
  return (
    <div className="flex py-2 px-2 sm:px-4 w-full">
      <div className="flex w-1/3">

        <div onClick={clearAll} className="secondary_btn transition-all delay-50 px-4 py-1 cursor-pointer">
          <span>Clear All</span>
        </div>
      </div>
      <div className="flex gap-3 w-2/3 justify-end">
        <div onClick={(e) => closeOnCancel(e)} className="tertiary_btn transition-all delay-50 px-4 py-1 cursor-pointer" style={{ width: '25%' }}>
          <span>Cancel</span>
        </div>
        <div onClick={save} className="primary_btn transition-all delay-50 px-4 py-1 cursor-pointer" style={{ width: '25%' }} >
          <span>Done</span>
        </div>
      </div>
    </div>
  )
}

export default Footer