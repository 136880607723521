import { useSelector } from 'react-redux'
import LastRowCellEditor from '../DataCell/LastRowCellEditor'
import UrlEditor from '../DataCell/UrlEditor'

const UrlEditorLayer = (props) => {
  // redux variables 
  const registerData = useSelector(state => state.register)

  // local variables
  const isAdmin = registerData.permissions.includes('admin')
  const canEdit = registerData.permissions.includes('edit') || isAdmin
  const onClose = props.rowProperties.onClose
  const rowIndex = props.rowProperties.rowIdx
  const filteredRows = registerData.filteredRows
  const isLastRow = filteredRows.length - 1 === rowIndex
  const colKey = props.columnEl.key

  if (isLastRow) {
    return <LastRowCellEditor {...props} />
  }
  return <UrlEditor {...props} />
}

export default UrlEditorLayer