import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import phone from '../../../../../../images/datatype_icons/light/phone.png'

import { getValidatedPhoneNumber } from '../../../../Table/Utils'

const PhoneNumber = ({ colkey, column, handleChange, formData, shouldAllowNewEntry }) => {

  // redux variables
  const registerData = useSelector(state => state.register)

  // state variables
  const [phNumber, setPhNumber] = useState('')

  // local variables
  const { isAdmin, hasBasicEditPermission, editPermissions } = registerData
  const canEdit = isAdmin || hasBasicEditPermission && editPermissions[0].detail.indexOf(colkey) === -1

  useEffect(() => {
    const numStr = formData[colkey]
    if (numStr) {
      if (numStr[0] === '+') {
        const numStr1 = numStr.slice(1)
        const num = parseInt(numStr1, 10)
        setPhNumber(getValidatedPhoneNumber(num))
      } else {
        const num = parseInt(numStr, 10)
        setPhNumber(getValidatedPhoneNumber(num))
      }
    } else {
      setPhNumber('')
    }
  }, [formData[colkey]])

  return (
    <div className="mb-6">
      <div className="colName">
        <img src={phone} className="field_icon" />
        <label htmlFor={column.name}>{column.name}</label>
      </div>

      {
        !canEdit && !shouldAllowNewEntry &&
        <div className="field">{phNumber}</div>
      }

      {
        (shouldAllowNewEntry || canEdit) &&
        <input
          name={colkey}
          type="number"
          // placeholder="Enter Phone Number"
          value={phNumber}
          onChange={(e) => handleChange({ colkey, value: e.target.value })}
          className="field focus:ring-transparent"
        />
      }
    </div>
  )
}

export default PhoneNumber