import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { createPortal } from 'react-dom'
import { useHistory } from 'react-router-dom'

// icons
import deleteIcon from '../../../../../../../images/add-edit row dialog/form icons/delete.png'
import goToLinkRegister from '../../../../../../../images/goToLinkRegister.png'
import edit from '../../../../../../../images/add-edit row dialog/form icons/edit.png'
import DeletePopup from '../../../../../PopUps/Delete'

const EditMenu = ({
  dropdown,
  dropdownOpen,
  pos,
  dataCellWidthTracker,
  setShowLinkedColPopup,
  rowId,
  colKey,
  saveCurrentRowData,
  linkedRegisterId,
  linkedColumnId,
  value,
  linkedRegisterRows,
  linkedRowId
}) => {

  //redux variables
  const registerData = useSelector(state => state.register)
  const leftSidebarData = useSelector(state => state.leftSidebar)

  // router variables
  const history = useHistory()

  // state variables
  const [showDeletePopup, setShowDeletePopup] = useState(false)

  // local variables
  const { activeBusinessId } = leftSidebarData

  // classes
  const list_item_container = `px-2 hover:bg-gray-200 transition-colors delay-50 focus:outline-none`
  const list_item = `flex items-center py-2 pl-2 border-b gap-3`

  const goToLinkedRecord = () => {
    let rowid
    linkedRegisterRows.forEach((row) => {
      if (row[linkedColumnId] == value) {
        rowid = row['rowId']
      }
    })
    if (linkedRowId) {
      history.push(`/community/${activeBusinessId}/register/${linkedRegisterId}?rowId=${linkedRowId}`)
    } else if (rowid) {
      history.push(`/community/${activeBusinessId}/register/${linkedRegisterId}?rowId=${rowid}`)
    }
  }

  return (
    <>
      {
        dropdownOpen && createPortal(
          <div
            ref={dropdown}
            id="linked-sheet-drop-down-portal-container"
            tabIndex="-1"
            className="slide_down_2 flex flex-col bg-white shadow-lg text-sm rounded overflow-hidden"
            style={{ top: pos.top, right: pos.right, left: pos.left, position: 'fixed', width: dataCellWidthTracker.current.clientWidth, minWidth: '12rem' }}
          >

            {/* edit */}
            <button className={list_item_container} onClick={(e) => setShowLinkedColPopup(true)} >
              <div className={list_item}>
                <img className="h-3.5" src={edit} />
                Edit
              </div>
            </button>
            {/* edit */}

            {/* go to linked record */}
            <button className={list_item_container} onClick={(e) => goToLinkedRecord()} >
              <div className={list_item}>
                <img className="h-3.5" src={goToLinkRegister} />
                Go To Linked Record
              </div>
            </button>
            {/* go to linked record */}

            {/* delete */}
            <button className={list_item_container} onClick={(e) => setShowDeletePopup(true)} >
              <div className={list_item}>
                <img className="h-3.5" src={deleteIcon} />
                <div style={{ color: '#FC1055' }}>
                  Delete
                </div>
              </div>
            </button>
            {/* delete */}

          </div>,
          document.body)
      }
      {
        showDeletePopup &&
        <DeletePopup
          closePopup={() => setShowDeletePopup(false)}
          headerText={`Are you sure you want to delete this entry`}
          subHeaderText={``}
          deleteBtnText={`Delete`}
          onConfirm={() => saveCurrentRowData('', colKey, rowId, registerData.rows)}
        />
      }
    </>
  )
}

export default EditMenu