const ContactSupport = () => {

  return (
    <div className="font-medium mt-4" style={{ color: '#6C757D', fontSize: '15px' }}>
      Facing any issues?&nbsp;
      <a href="mailto:abhishek@rekord.in" style={{ color: '#0B98FF' }} className='cursor-pointer'>Contact Support</a>
    </div>
  )
}

export default ContactSupport