import createdByIcon from '../../../../../../images/datatype_icons/light/created-by.png'

const CreatedBy = ({ colkey, column, handleChange, formData }) => {
  return (
    <div className="mb-6">
      <div className="colName">
        <img className="field_icon" src={createdByIcon} alt="" />
        <label htmlFor={column.name}>{column.name}</label>
      </div>
      <div className='field non_editable cursor-not-allowed'>{formData[colkey]}</div>
    </div>
  )
}

export default CreatedBy