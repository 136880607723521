import { useRef, useState, useEffect } from 'react'
import ReactDOM, { createPortal } from 'react-dom'
import { useSelector, useDispatch } from 'react-redux'

import { addNewColumn, sheetColumnMovePosition, sortRows } from '../../../../../slices/RegisterSlice'
import HeaderMenu from './HeaderMenu'

// utils
import { getDataTypeName, focusHighlightedCell } from '../../Utils'

// components
import LinkToAnotherRegisterPopup from "../../../PopUps/LinkedSheet/LinkToAnotherRegister"

// icons
import textIcon from '../../../../../images/datatype_icons/light/text.png'
import timeIcon from '../../../../../images/datatype_icons/light/time.png'
import formulaIcon from '../../../../../images/datatype_icons/light/formula.png'
import numberIcon from '../../../../../images/datatype_icons/light/number.png'
import dateIcon from '../../../../../images/datatype_icons/light/date.png'
import phoneIcon from '../../../../../images/datatype_icons/light/phone.png'
import dropdownIcon from '../../../../../images/datatype_icons/light/dropdown.png'
import photoIcon from '../../../../../images/datatype_icons/light/image.png'
import switchIcon from '../../../../../images/datatype_icons/light/switch.png'
import mapIcon from '../../../../../images/datatype_icons/light/location.png'
import mailIcon from '../../../../../images/datatype_icons/light/mail.png'
import rupeeIcon from '../../../../../images/datatype_icons/light/rupee.png'
import urlIcon from '../../../../../images/datatype_icons/light/url.png'
import attachmentIcon from '../../../../../images/datatype_icons/light/attachment.png'
import signatureIcon from '../../../../../images/datatype_icons/light/signature.png'
import statusIcon from '../../../../../images/datatype_icons/light/status.png'
import unitIcon from '../../../../../images/datatype_icons/light/unit.png'
import createdByIcon from '../../../../../images/datatype_icons/light/created-by.png'
import createdAtIcon from '../../../../../images/datatype_icons/light/created-at.png'
import reminderIcon from '../../../../../images/datatype_icons/light/reminder.png'
import scannerIcon from '../../../../../images/datatype_icons/light/scanner.png'
import durationIcon from '../../../../../images/datatype_icons/light/duration.png'
import labelIcon from '../../../../../images/datatype_icons/dark/label.png'
import menuDropDownIcon from '../../../../../images/column-icons/showMenu.png'
import linkedSheetIcon from '../../../../../images/column-icons/header-menu/linkedSheet.png'
import linkedTextIcon from '../../../../../images/datatype_icons/dark/linked/text.png'
import linkedDateIcon from '../../../../../images/datatype_icons/dark/linked/date.png'
import linkedImageIcon from '../../../../../images/datatype_icons/dark/linked/image.png'
import linkedCreatedAtIcon from '../../../../../images/datatype_icons/dark/linked/createdAt.png'
import linkedCreatedByIcon from '../../../../../images/datatype_icons/dark/linked/createdBy.png'
import linkedFormulaIcon from '../../../../../images/datatype_icons/dark/linked/formula.png'
import linkedLocationIcon from '../../../../../images/datatype_icons/dark/linked/location.png'
import linkedMailIcon from '../../../../../images/datatype_icons/dark/linked/mail.png'
import linkedNumberIcon from '../../../../../images/datatype_icons/dark/linked/number.png'
import linkedPhNoIcon from '../../../../../images/datatype_icons/dark/linked/phNo.png'
import linkedRupeeIcon from '../../../../../images/datatype_icons/dark/linked/rupee.png'
import linkedSelectOneIcon from '../../../../../images/datatype_icons/dark/linked/selectOne.png'
import linkedStatusIcon from '../../../../../images/datatype_icons/dark/linked/status.png'
import linkedUnitIcon from '../../../../../images/datatype_icons/dark/linked/unit.png'
import addIcon from '../../../../../images/plusWhite.png'

{/*JSX for column headers*/ }
const ColumnHeader = ({ columnInfo, isLastCol, colIdx }) => {
  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  //state variables
  const [showColMenu, setShowColMenu] = useState(false)
  const [colMenuPos, setColMenuPos] = useState({}) //for position of column-header-menu
  const [showLinkToAnotherRegisterPopup, setShowLinkToAnotherRegisterPopup] = useState(false)
  const [colName, setColumnName] = useState('')

  //ref variables
  const colMenuContainerRef = useRef(null)
  const colMenuTriggerBtnRef = useRef(null)
  const colHeaderContainerRef = useRef(null)

  // local variables

  const columnName = columnInfo.name
  const dataType = columnInfo.dataType
  const linkedDataType = columnInfo.dataType == 'linkedSheet' ? columnInfo.linkedDataType : ''
  const colKey = columnInfo.key
  const colWidth = columnInfo.width
  const sheetId = registerData.sheetId
  const preOpenColMenu = registerData.preOpenColMenu
  const isDataColumn = colKey !== '-1' && colKey !== 'addColPlusButton'
  const isAdmin = registerData.permissions.includes('admin')
  const columns = registerData.columns
  const { sortedColKey, sortDirection, rows } = registerData
  const isColumnFrozen = columnInfo.isFrozen

  let icon;
  if (dataType === "date") {
    icon = dateIcon
  } else if (dataType === "String") {
    icon = textIcon
  } else if (dataType === "number") {
    icon = numberIcon
  } else if (dataType === "phoneNumber") {
    icon = phoneIcon
  } else if (dataType === "dropDown") {
    icon = dropdownIcon
  } else if (dataType === "formula") {
    icon = formulaIcon
  } else if (dataType === "location") {
    icon = mapIcon
  } else if (dataType === "time") {
    icon = timeIcon
  } else if (dataType === "mail") {
    icon = mailIcon
  } else if (dataType === "switch") {
    icon = switchIcon
  } else if (dataType === "image") {
    icon = photoIcon
  } else if (dataType === "rupee") {
    icon = rupeeIcon
  } else if (dataType === "url") {
    icon = urlIcon
  } else if (dataType === "attachment") {
    icon = attachmentIcon
  } else if (dataType === "signature") {
    icon = signatureIcon
  } else if (dataType === "status") {
    icon = statusIcon
  } else if (dataType === "unit") {
    icon = unitIcon
  } else if (dataType === "createdBy") {
    icon = createdByIcon
  } else if (dataType === "createdAt") {
    icon = createdAtIcon
  } else if (dataType === "reminder") {
    icon = reminderIcon
  } else if (dataType === "scanner") {
    icon = scannerIcon
  } else if (dataType === "duration") {
    icon = durationIcon
  } else if (dataType === "label") {
    icon = labelIcon
  } else if (dataType === "linkedSheet") {
    switch (linkedDataType) {
      case 'date':
        icon = linkedDateIcon
        break
      case 'String':
        icon = linkedTextIcon
        break
      case 'image':
        icon = linkedImageIcon
        break
      case 'rupee':
        icon = linkedRupeeIcon
        break
      case 'phoneNumber':
        icon = linkedPhNoIcon
        break
      case 'number':
        icon = linkedNumberIcon
        break
      case 'formula':
        icon = linkedFormulaIcon
        break
      case 'location':
        icon = linkedLocationIcon
        break
      case 'dropDown':
        icon = linkedSelectOneIcon
        break
      case 'mail':
        icon = linkedMailIcon
        break
      case 'status':
        icon = linkedStatusIcon
        break
      case 'createdAt':
        icon = linkedCreatedAtIcon
        break
      case 'createdBy':
        icon = linkedCreatedByIcon
        break
      case 'unit':
        icon = linkedUnitIcon
        break
      default:
        icon = linkedSheetIcon
    }
  }

  // sets column menu position w.r.t column header container
  useEffect(() => {
    if (showColMenu) {
      let positionLeft
      let dataGridRect = document.querySelector('.rdg-light').getBoundingClientRect()
      let columnLeftPos = colHeaderContainerRef.current.getBoundingClientRect().left
      let columnRightPos = colHeaderContainerRef.current.getBoundingClientRect().right
      let colWidth = columnRightPos - columnLeftPos
      let triangleRight
      if (columnRightPos >= window.innerWidth) {
        positionLeft = window.innerWidth - 224
      } else if (columnRightPos - 204 < dataGridRect.left) { //Menu falls outside data grid towards left under normal positioning
        positionLeft = dataGridRect.left + 2
        triangleRight = positionLeft + 204 - columnRightPos + 10
      } else {
        positionLeft = colHeaderContainerRef.current.getBoundingClientRect().right - 206 //normal position
      }
      const refValue = colHeaderContainerRef.current
      if (refValue) {
        setColMenuPos({
          right: refValue
            .getBoundingClientRect().right,
          left: positionLeft,
          top: refValue
            .getBoundingClientRect().top,
          bottom: refValue
            .getBoundingClientRect().bottom,
          triangleRight: triangleRight
        })
      }
    } else setColMenuPos({})
  }, [showColMenu])

  // open column menu of the mentioned column
  // also confirming that update option 1 is mentioned in preOpenColMenu info
  useEffect(() => {
    if (preOpenColMenu &&
      preOpenColMenu.colIdx === colIdx &&
      preOpenColMenu.option !== null) {
      setShowColMenuFuncn(true)
    }
  }, [preOpenColMenu])

  useEffect(() => {
    if (sortedColKey == colKey) {
      dispatch(sortRows({ rows: rows, columnId: sortedColKey, direction: sortDirection, dataType: dataType == 'linkedSheet' ? linkedDataType : dataType }))
    }
  }, [sortedColKey, sortDirection])

  const openColEditMenu = (e) => {
    if (!isDataColumn) return
    e.preventDefault()
    setShowColMenuFuncn(!showColMenu)
  }

  const addColumn = () => {
    if (!isAdmin) {
      alert("Permission Denied")
      return
    }
    // const serialisedColName = 'Column ' + alphabetSet[colIdx - 1]
    const lastColumnIdx = registerData.columns.length - 1 // column whose update option should be opened
    const colIdBfrPlusCol = registerData.columns[lastColumnIdx - 1].key
    dispatch(addNewColumn({
      sheetId,
      leftColumnId: colIdBfrPlusCol,
      columnName: 'Text',
      dataType: 'String',
      preOpenColMenu: { colIdx: lastColumnIdx, option: 1 } // 1 for Update option
    }))
  }

  const setShowColMenuFuncn = (bool) => {
    // if column menu is being closed
    // also called from register slice when column is deleted
    if (!bool) focusHighlightedCell()
    setShowColMenu(bool)
  }

  // drgging should not be allowed for S.no, + button or frozen columns
  const onColDragStart = (e) => {
    e.dataTransfer.setData("colMovedInfo", JSON.stringify({ colKey, colIdx }));
  }

  const onColDrop = (e) => {
    e.preventDefault();
    if (colKey === '-1' || colKey === 'addColPlusButton' || isColumnFrozen) return
    const colMovedInfo = JSON.parse(e.dataTransfer.getData("colMovedInfo"))
    let newPositions = []
    colMovedInfo.newColIdx = colIdx
    columns.forEach((colEl) => {
      const isSerialNumbCol = colEl.key === '-1'
      const isLastCol = colEl.key === 'addColPlusButton'
      const colBeingMoved = colEl.key === colMovedInfo.colKey
      if (!(isSerialNumbCol || isLastCol || colBeingMoved)) {
        newPositions.push(colEl.key)
      }
    })
    // subtracted 1 because the colIdx was recievied from an array
    // which had S.No as the first el. newPositions being send to BE just has column keys relevant for BE.
    newPositions.splice(colIdx - 1, 0, colMovedInfo.colKey)
    dispatch(sheetColumnMovePosition({ sheetId, newPositions, colMovedInfo }))
    focusHighlightedCell()
  }

  const allowColDrop = (e) => {
    if (isColumnFrozen) return
    e.preventDefault();
  }

  return (
    <div
      id={`headerCell-${colKey}`}
      draggable={colKey !== '-1' && !isLastCol && !isColumnFrozen}
      onDrop={onColDrop}
      onDragOver={allowColDrop}
      onDragStart={onColDragStart}
      className={`h-full`}
      style={{ backgroundColor: sortedColKey == colKey ? "#EAF1F8" : (isLastCol ? "#044089" : "#F3F3F3") }}
      ref={colHeaderContainerRef}
      onContextMenu={openColEditMenu}
    >
      {
        isLastCol ?
          <button onClick={addColumn} className="w-full h-full flex justify-center items-center">
            <img src={addIcon} className="h-3/4" />
          </button>
          :
          <div
            onClick={openColEditMenu}
            className={`flex w-full justify-center items-center h-full cursor-pointer`}
          >
            {/*Header icon and heading container starts */}
            <div className="flex justify-center items-center w-full">
              {icon && <img className="flex w-6 h-full mr-1.5" src={icon} />}
              <p className={`truncate ${colKey != -1 ? 'pr-6' : ''}`} style={{ color: '#545454', fontSize: '0.95rem', fontWeight: '500' }}>{columnName}</p>
            </div>
            {/*Header icon and heading container ends */}

            {/*Header column dropdown icon starts*/}
            {
              colKey != -1 &&
              <img
                className="absolute right-3 w-3"
                ref={colMenuTriggerBtnRef}
                src={menuDropDownIcon}
              />
            }
            {/*Header column dropdown icon ends*/}
          </div>
      }
      {/*Header column dropdown colMenu container starts*/}
      {
        showColMenu &&
        createPortal(
          <div ref={colMenuContainerRef}>
            <HeaderMenu
              colHeaderContainerRef={colHeaderContainerRef}
              colMenuTriggerBtnRef={colMenuTriggerBtnRef}
              colMenuContainerRef={colMenuContainerRef}
              showColMenu={showColMenu}
              colMenuPos={colMenuPos}
              dataTypeSelected={{ columnName, icon, dataType, linkedDataType, name: getDataTypeName(dataType) }}
              setShowColMenu={setShowColMenuFuncn}
              colIdx={colIdx}
              preOpenColMenu={preOpenColMenu && preOpenColMenu.colIdx === colIdx ? preOpenColMenu : null}
              setShowLinkToAnotherRegisterPopup={setShowLinkToAnotherRegisterPopup}
              colName={colName}
              setColumnName={setColumnName}
              columnInfo={columnInfo}
            />
            {/*
              preOpenColMenu condition ensures that only newly added last column should
              be able to access this object.              
            */}
          </div>, document.body
        )
      }
      {/*Header column dropdown colMenu container ends*/}

      {/* Link to another register popup */}
      {
        showLinkToAnotherRegisterPopup &&
        <LinkToAnotherRegisterPopup
          setShowLinkToAnotherRegisterPopup={setShowLinkToAnotherRegisterPopup}
          colKey={colKey}
          colName={colName}
          columnInfo={columnInfo}
        />
      }

      {/* Link to another register popup */}
    </div>
  )
}

export default ColumnHeader