import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom';
import { useDropzone } from 'react-dropzone'

import { toggleDialogOpen } from '../../../../../../slices/DialogSlice'
import CustomProgressBar from '../../../../../../partials/CustomProgressBar'

import dragAndDropIcon from '../../../../../../images/dialog/image/drag.png'
import removeImageIcon from '../../../../../../images/dialog/image/removeImage.png'
import attachmentIcon from '../../../../../../images/dialog/attachment.png'

const AttachmentDialog1 = ({ onAttachmentChange }) => {
  // redux variables
  const dispatch = useDispatch()

  // route variables
  const location = useLocation()

  // state variables
  const [droppedAttachments, setDroppedAttachments] = useState([])
  const [uploadingAttachments, setUploadingAttachments] = useState(false)
  const [uploadedAttchsCount, setUploadedAttchsCount] = useState(0)

  // local variables
  const { pathname } = location;
  const communityId = pathname.split('/')[2];

  const onDrop = useCallback(acceptedFiles => {
    setDroppedAttachments((curr) => [...curr, ...acceptedFiles])
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const removeSelectedAttachment = (i) => {
    const currentAttachments = [...droppedAttachments]
    currentAttachments.splice(i, 1)
    setDroppedAttachments([...currentAttachments])
  }

  const onUploadClick = () => {
    setUploadingAttachments(true)
  }

  const getUploadedAttachmentsClbk = (uploadedFiles) => {
    onAttachmentChange(uploadedFiles)
    dispatch(toggleDialogOpen(false))
  }

  const liveUploadCountClbk = (count) => {
    setUploadedAttchsCount(count)
  }

  if (droppedAttachments.length === 0) {
    return ( // drag drop view
      <div className="w-full p-4 bg-white flex items-center flex-col justify-center" style={{ width: '40rem', maxWidth: '90vw' }}>
        <div className="text-center p-2 m-2 text-2xl font-bold">Upload your Attachment</div>
        <div
          className="flex flex-col gap-4 justify-center items-center w-full bg-gray-200 rounded py-16 border-dashed border-gray-400 border-2 cursor-pointer"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <img src={dragAndDropIcon} />
          <p>Drag and drop or browse to choose file</p>
        </div>
      </div>
    )
  } else { // attachments selected view
    return (
      <div className="w-full h-full flex flex-col justify-between bg-white" style={{ width: '40rem', maxWidth: '90vw' }}>
        <div className="overflow-auto" style={{ height: '45vh' }}>
          <div className="text-center p-4 text-xl">{uploadedAttchsCount}/{droppedAttachments.length} Selected Attachments</div>
          <ul className="py-2 overflow-auto" >
            {!uploadingAttachments && droppedAttachments.map((attch, i) => {
              return (
                <li key={i} className="px-4 my-2 mx-4 py-2 rounded flex items-center justify-between" style={{ background: '#F4F4F4' }}>
                  <div className="flex gap-2 items-center">
                    <img src={attachmentIcon} />
                    {attch.name}
                  </div>
                  <button onClick={() => { removeSelectedAttachment(i) }}><img className="h-5 w-5" src={removeImageIcon} /></button>
                </li>
              )
            })}
            {
              uploadingAttachments &&
              <CustomProgressBar
                url={'otherservice/uploadFile'}
                files={droppedAttachments}
                communityId={communityId}
                getUploadedFilesClbk={getUploadedAttachmentsClbk}
                progressDirection='horizontal'
                liveUploadCountClbk={liveUploadCountClbk}
                showFileInfo={true}
                outerContainerStyle={{ height: '3rem', width: '100%' }}
                outerContainerClasses={'px-2  py-1 rounded flex '}
                progressBarStyle={{ backgroundColor: '#586C9E' }}
              />
            }
          </ul>
        </div>
        {
          !uploadingAttachments && droppedAttachments.length > 0 && (
            <div className="w-full px-4 py-2 flex justify-end border-t border-gray-300">
              <div className="text-center cursor-pointer rounded border p-2 mx-2 shadow-md w-28" style={{ color: '#586C9E', borderColor: '#586C9E' }} {...getRootProps()} ><input {...getInputProps()} />Add More</div>
              <button className="rounded border p-2 mx-2 shadow-md w-28" style={{ color: 'white', background: '#586C9E' }} onClick={onUploadClick} >Upload</button>
            </div>
          )
        }
      </div>
    )
  }
}

export default AttachmentDialog1