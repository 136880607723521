import { useEffect, useState } from 'react';
import dropdownIcon from '../images/header popup icons/drop down.png'

{/********************************Notes*************************************
 * USAGE:
  <div className="flex relative h-2/5 bg-white cursor-pointer" style={{ border: '1px solid #C4C4C4', borderRadius: 5 }} >
    <CustomDropdown
      items={pages}
      displayKey={'pageName'}
      onSelect={onDropdownSelect}
    />
  </div>

  Properties: 
  1. allowSelectItem: if you wish to not show the selcted item on selection ( like in calculator we dont need to show selected column )
***********************************************************************/}

const CustomDropdown = ({ label, labelStyle, noneText, selectedText, items, displayKey, onSelect, preSelectedItem, menuContainerStyle, dropdownContainerStyle, dropdownContainerClass, allowSelectItem }) => {

  // state variables
  const [show, setShow] = useState(false)
  const [selectedItem, setSelectedItem] = useState(preSelectedItem ? preSelectedItem : { [displayKey]: noneText ? noneText : 'None' })

  useEffect(() => {
    const clickHandler = () => {
      if (show) setShow(false)
    }
    if (show) {
      document.addEventListener('click', clickHandler)
    }
    return () => document.removeEventListener('click', clickHandler)
  }, [show])

  const onItemClick = (e, itemInfo) => {
    e.stopPropagation()
    if (allowSelectItem !== 'NONE') setSelectedItem(itemInfo)
    onSelect(itemInfo)
    setShow(false)
  }

  return (
    <div className="relative flex w-full h-full items-center">
      {/* label container starts here */}
      {
        label && <span className="flex pb-1" style={{ ...labelStyle, position: 'absolute', fontSize: 12, color: '#586C9E', fontWeight: '400', fontFamily: 'Arial, Helvetica, sans-serif' }}>{label}</span>
      }
      {/* label container ends here */}

      {/* selected items container starts here */}
      <div tabIndex="0" onClick={() => setShow(!show)} className={`flex cursor-pointer w-full bg-white ${dropdownContainerClass}`} style={{ ...dropdownContainerStyle }}>
        <div className="flex w-10/12 items-center">
          <span className="flex pl-3" style={{ fontWeight: '400', fontFamily: 'Arial, Helvetica, sans-serif' }}>
            {selectedText ? selectedText : selectedItem[displayKey]}
          </span>
        </div>
        <div className="flex w-2/12 justify-center items-center">
          <img className="h-8" src={dropdownIcon} />
        </div>
      </div>
      {/* selected items container ends here */}

      {/* items container starts*/}
      {
        show &&
        <ul className="flex flex-col absolute bg-white w-full overflow-y-auto" style={{ ...menuContainerStyle, border: '1px solid #C4C4C4', borderRadius: 5 }}>
          {
            items.map((itemEl, itemIdx) =>
              <li key={`customDropdDownItem/${itemIdx}`} onClick={(e) => onItemClick(e, itemEl)} className="flex p-4 items-center w-full hover:bg-gray-300" style={{ height: '3rem' }}>
                {itemEl[displayKey]}
              </li>
            )
          }
        </ul>
      }
      {/* items container ends*/}
    </div>
  )
}

export default CustomDropdown