import { createSlice } from "@reduxjs/toolkit"
import { updateRegisterData } from "../RegisterSlice"

export const dummySlice = createSlice({
  name: 'dummyRow',
  initialState: {
    showDummyRow: false,
    animationStartSlNo: '',
    rowId: '',
    data: '',
    entryFrom: ''
  },
  reducers: {
    setDummyRowDetails: (state, action) => {
      state.slNoEnd = action.payload.slNoEnd
    },
    resetDummyRowDetails: state => {
      state.showDummyRow = false
      state.animationStartSlNo = ''
      state.data = ''
      state.entryFrom = ''
      state.rowId = ''
    }
  },
  extraReducers: {
    [updateRegisterData.fulfilled]: (state, action) => {
      state.showDummyRow = true
      let updatedResponse = action.payload.updatedResponse
      state.rowId = updatedResponse.uiUpdate.formData.rowId
      state.animationStartSlNo = updatedResponse.uiUpdate.rowIndex + 1
      state.data = action.payload.data
      state.entryFrom = action.payload.entryFrom
    }
  }
})

export const { setDummyRowDetails, resetDummyRowDetails} = dummySlice.actions

export default dummySlice.reducer