import { useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"

//slices
import { getOtp, verifyOtp, updateLoginInfoState } from "../../../../slices/LoginSlice"

//components
import ContactSupport from "../ContactSupport"
import BackButton from "../BackButton"

//libraries
import PhoneInput from 'react-phone-number-input'
import {
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

// icons
import logo from '../../../../images/logo.png'
import waveIcon from '../../../../images/login/wave.png'
import dropdownIcon from '../../../../images/login/dropdownGray.png'

const LoginByPhoneNumber = ({ handleLoginMethodChange, LOGIN_METHODS }) => {

  const { executeRecaptcha } = useGoogleReCaptcha();

  //redux variables
  const dispatch = useDispatch()
  const loginInfo = useSelector(state => state.loginInfo)

  // state variables
  const [phoneNumber, setPhoneNumber] = useState('')
  const [value, setValue] = useState('+91')
  const [otpInput1, setOtpInput1] = useState('')
  const [otpInput2, setOtpInput2] = useState('')
  const [otpInput3, setOtpInput3] = useState('')
  const [otpInput4, setOtpInput4] = useState('')
  const [countryCode, setCountryCode] = useState('')
  const [number, setNumber] = useState('')
  const [resendTimer, setResendTimer] = useState(0)
  const [otpSentMsg, setOtpSentMsg] = useState(null)

  // ref variables
  const otpInputRef = useRef(null)

  //local variables
  const { otpSent } = loginInfo
  let PhInputInput
  let phoneInputArrow
  let inputSelectDropdown = document.querySelector('.PhoneInputCountrySelect')
  let inputSelectDropdownContainer = document.querySelector('.PhoneInput')
  const isDisabled = loginInfo.otpSent ? !otpInput1 || !otpInput2 || !otpInput3 || !otpInput4 : !number

  useEffect(() => {
    if (otpSent) {
      otpInputRef.current.focus()
      setResendTimer(60);
      const timer = setInterval(() => {
        setResendTimer(prevTimer => {
          if (prevTimer > 0) return prevTimer - 1
          else {
            clearInterval(timer)
            return prevTimer
          }
        }
        )
      }, 1000)
      return () => clearInterval(timer);
    }
  }, [otpSent])

  // Resend OTP function
  const resendOtp = async () => {
    if (resendTimer === 0) {
      setOtpInput1('')
      setOtpInput2('')
      setOtpInput3('')
      setOtpInput4('')
      const token = await executeRecaptcha();
      dispatch(getOtp({ phoneNumber, token }));
      setOtpSentMsg(true)
      setTimeout(() => {
        setOtpSentMsg(false);
      }, 4000)
      setResendTimer(60);
    }
  }

  useEffect(() => {
    let n = parseInt(`${countryCode}${number}`, 10)
    if (number) {
      setPhoneNumber(n.toString())
    }
  }, [countryCode, number])

  useEffect(() => {
    PhInputInput = document.querySelector('.PhoneInputInput')
    phoneInputArrow = document.querySelector('.PhoneInputCountrySelectArrow')
    if (PhInputInput) {
      PhInputInput.style.display = 'none'
      setCountryCode(PhInputInput.value)
    }
    if (phoneInputArrow) {
      phoneInputArrow.style.display = 'none'
    }
    if (inputSelectDropdownContainer) {
      inputSelectDropdownContainer.style.height = '100%'
    }
    if (inputSelectDropdown) {
      inputSelectDropdown.style.width = '6rem'
      inputSelectDropdown.style.height = '100%'
    }
  })

  const handleReCaptchaVerify = async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha();
    getOtpDispatch(token)
  }

  const getOtpDispatch = (token) => {
    if (number.length === 0) {
      dispatch(updateLoginInfoState([
        {
          key: 'error',
          value: true
        },
        {
          key: 'errorMessage',
          value: 'Please enter phone number'
        }
      ]))
      return
    }
    if (value == '+91' && number.length != 10) {
      dispatch(updateLoginInfoState([
        {
          key: 'error',
          value: true
        },
        {
          key: 'errorMessage',
          value: 'Please enter a valid Phone Number'
        }
      ]))
      return
    }
    dispatch(getOtp({ phoneNumber, token }))
  }

  const verifyOtpDispatch = () => {
    let otp = otpInput1 + otpInput2 + otpInput3 + otpInput4
    if (isNaN(parseInt(otpInput1)) || isNaN(parseInt(otpInput2)) || isNaN(parseInt(otpInput3)) || isNaN(parseInt(otpInput4))) {
      dispatch(updateLoginInfoState([
        {
          key: 'error',
          value: true
        },
        {
          key: 'errorMessage',
          value: 'The OTP entered is invalid'
        }
      ]))
    } else if (otp.length) {
      dispatch(verifyOtp({ phoneNumber, otp }))
    }
  }

  const handleInputKeyUp = (e) => {
    if (e.key === 'Enter') {
      loginInfo.otpSent ? verifyOtpDispatch() : handleReCaptchaVerify()
    }
  }

  const handleOtpInputKeyUp = (e, current, next, previous) => {
    if (e.key === 'Enter') {
      loginInfo.otpSent ? verifyOtpDispatch() : handleReCaptchaVerify()
    }
    if (e.key === 'Backspace' && previous.length > 0) {
      document.getElementById(previous).focus()
    }

    let currentOtpInput = document.getElementById(current)
    if (currentOtpInput.value && next.length > 0) {
      document.getElementById(next).focus()
    }
  }

  const onBackButtonClick = () => {
    if (loginInfo.otpSent) {
      modifyPhNoIconClick()
    } else {
      handleLoginMethodChange(LOGIN_METHODS.EMAIL)
    }
  }

  const modifyPhNoIconClick = () => {
    dispatch(updateLoginInfoState([
      {
        key: 'otpSent',
        value: false
      }
    ]))
    setOtpInput1('')
    setOtpInput2('')
    setOtpInput3('')
    setOtpInput4('')
  }

  const onLogoClick = () => {
    window.location.reload();
  }

  const removeError = () => {
    if (loginInfo.error) {
      dispatch(updateLoginInfoState([{ key: 'error', value: false }, { key: 'errorMessage', value: null }]))
    }
  }

  const classes = {
    inputSection: `flex items-center flex-col ${loginInfo.otpSent ? '' : 'pb-16'}`,
    inputContainer: `h-14 my-2 w-80 max-w-full flex items-start rounded-2xl border`,
    phoneInputDropdown: `h-14 pl-4 flex justify-center items-center rounded-l-2xl`,
    countryCode: `h-14 flex items-center border-r`,
    inputPhNo: `phNoInput text-lg h-full pl-4 w-full border-none`,
    button: `rounded text-white shadow-sm hover:shadow-lg transition-all duration-100 ${loginInfo.otpSent ? '' : 'mt-5'}`,
    otpInput: `rounded-2xl border text-center text-2xl text-black font-medium`,
    buttonContainer: `child-in-center text-lg text-white font-medium mx-auto rounded-2xl h-14 w-80 max-w-full gap-2 my-5 shadow-sm hover:shadow-lg transition-all duration-100 ${isDisabled ? 'cursor-not-allowed' : ''}`
  }

  const styles = {
    otpInput: { height: 56, width: 56, backgroundColor: '#F8F9FA', borderColor: '#ACB5BD' }
  }

  return (
    <div className="h-full flex flex-col justify-between py-10 overflow-auto no-scrollbar">
      <div className='relative'>
        <BackButton onClick={onBackButtonClick} />
        <img src={logo} onClick={onLogoClick} className='mx-auto h-8 relative top-0.5 cursor-pointer' loading='lazy' />
        <div className="text-center mt-10">
          <div className="font-bold text-xl sm:text-2xl lg:text-3xl mb-2" style={{ color: '#22252A' }}>
            Welcome back
            <img src={waveIcon} className='h-6 inline ml-2' loading='lazy' />
          </div>
          <div className="font-medium" style={{ color: '#6C757D' }}>You’re just a step away, enter details and join your team</div>
        </div>
      </div>

      {/* input section */}
      <div className={`${classes.inputSection}`}>
        {
          loginInfo.otpSent ?
            <>
              {/* otp input */}
              <div className="flex gap-3 mb-4">
                <input ref={otpInputRef} id="firstOtp" value={otpInput1} onChange={(e) => { removeError(); setOtpInput1(e.target.value) }} className={classes.otpInput} style={styles.otpInput} maxLength={1} onKeyUp={(e) => handleOtpInputKeyUp(e, 'firstOtp', 'secondOtp', '')} />
                <input id="secondOtp" value={otpInput2} onChange={(e) => { removeError(); setOtpInput2(e.target.value) }} className={classes.otpInput} style={styles.otpInput} maxLength={1} onKeyUp={(e) => handleOtpInputKeyUp(e, 'secondOtp', 'thirdOtp', 'firstOtp')} />
                <input id="thirdOtp" value={otpInput3} onChange={(e) => { removeError(); setOtpInput3(e.target.value) }} className={classes.otpInput} style={styles.otpInput} maxLength={1} onKeyUp={(e) => handleOtpInputKeyUp(e, 'thirdOtp', 'fourthOtp', 'secondOtp')} />
                <input id="fourthOtp" value={otpInput4} onChange={(e) => { removeError(); setOtpInput4(e.target.value) }} className={classes.otpInput} style={styles.otpInput} maxLength={1} onKeyUp={(e) => handleOtpInputKeyUp(e, 'fourthOtp', '', 'thirdOtp')} />
              </div>
              {/* otp input */}

              <div className="mt-4 mb-10 font-medium relative" style={{ color: '#6C757D' }}>
                <span
                  onClick={resendOtp}
                  className={`${resendTimer == 0 ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                  style={{ color: resendTimer == '0' ? '#0B98FF' : '#ADB5BD' }}
                >
                  Resend OTP&nbsp;
                </span>
                {
                  resendTimer != 0 && <span>in {resendTimer}s</span>
                }
              </div>

              {
                otpSentMsg && <div className="font-medium" style={{ fontSize: '15px', color: '#35A600' }}>A new OTP has been sent.</div>
              }
            </>
            :
            <>
              {/* phone number input */}
              <div className={classes.inputContainer} style={{ border: '1px solid #ACB5BD' }}>
                <div className={classes.phoneInputDropdown} style={{ width: '2.5rem' }}>
                  <PhoneInput
                    international
                    defaultCountry="IN"
                    placeholder="Enter phone number"
                    value={value}
                    onChange={setValue}
                  />
                </div>
                <div className={classes.countryCode} style={{ minWidth: '3.5rem', borderColor: '#ACB5BD', color: '#6C757D' }}>
                  {countryCode}
                  <img src={dropdownIcon} className="w-4 ml-1" />
                </div>
                <input
                  value={number}
                  placeholder="Phone number"
                  onKeyUp={handleInputKeyUp}
                  onChange={e => { setNumber(e.target.value); removeError() }}
                  type="number"
                  className={classes.inputPhNo}
                  style={{ backgroundColor: '#F8F9FA', color: '#22252A', borderTopRightRadius: '1rem', borderBottomRightRadius: '1rem' }}
                />
              </div>
              {/* phone number input */}
            </>
        }

        {/* error msg */}
        {loginInfo.error && <p className="font-medium pl-3" style={{ fontSize: '15px', color: '#F5291C' }}><span> {loginInfo.errorMessage} </span></p>}
        {/* error msg */}

        <button
          onClick={loginInfo.otpSent ? verifyOtpDispatch : handleReCaptchaVerify}
          className={classes.buttonContainer}
          style={{ backgroundColor: isDisabled ? '#DDDDDD' : '#35A600' }}
          type="submit"
        >
          {
            loginInfo.isLoading ? (
              <div className="animate-spin rounded-full h-7 w-7 border-b-2 border-gray-100 m-auto "></div>
            ) : (
              <span >{loginInfo.otpSent ? 'Login' : 'Get OTP'}</span>
            )
          }
        </button>
      </div>
      {/* input section */}

      <div className="child-in-center"><ContactSupport /></div>

    </div>

  )
}

export default LoginByPhoneNumber