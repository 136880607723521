import { useSelector } from 'react-redux'
import LastRowCellFormatter from './LastRowCellFormatter'

const SignatureFormatter = ({ rowProperties, openEditorAfterSelect, colIdx, columnEl }) => {
  // redux variables
  const registerData = useSelector(state => state.register)

  // local variables
  const colKey = columnEl.key
  const rowIdx = rowProperties.rowIdx
  const rowId = rowProperties.row.rowId
  const images = rowProperties.row[`${colKey}_details`]
  const filteredRows = registerData.filteredRows
  const isLastRow = filteredRows.length - 1 === rowIdx

  if (isLastRow) {
    return <LastRowCellFormatter
      rowProperties={rowProperties}
      openEditorAfterSelect={openEditorAfterSelect}
      colIdx={colIdx}
      colKey={colKey}
    />
  }

  return (
    <div
      id={`dataCell/${colKey}/${rowId}`}
      className="flex w-full h-full items-center justify-center"
    >
      {
        images && images.length > 0 && <img src={images[0].value} style={{ height: '92%', width: '95%' }} />
      }
    </div>
  )
};

export default SignatureFormatter