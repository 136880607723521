import { useDispatch } from 'react-redux'

// slices
import { toggleLeftSidebarOpen } from '../../../../slices/LeftSidebarSlice'

// images
import whiteHamburger from '../../../../images/whiteHamburger.png'
import mobileBackIcon from '../../../../images/arrowLeftWhite.png'

const Hamburger = () => {
  // redux variables
  const dispatch = useDispatch()

  const onHamburgerIconClick = (e) => {
    e.stopPropagation(); // so that LeftSideBarComponent Click event listener can be blocked causing the sidebar to close again
    dispatch(toggleLeftSidebarOpen(true))
  }

  return (
    <>
      <img className='hidden xs:block sm:block md:block relative top-2' onClick={onHamburgerIconClick} src={whiteHamburger} alt="hamburger icon" style={{ width: 22, height: 22 }} />
      <img src={mobileBackIcon} className='block xs:hidden h-4 cursor-pointer relative top-4' onClick={onHamburgerIconClick} />
    </>
  )
}

export default Hamburger