import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//components
import SharePopupBody from './SharePopupBody'

//slices
import { toggleDialogOpen, updateHeaderText, updateBody, getCurrentLinks } from '../../../../../slices/DialogSlice'

//icons and images
import whiteShare from '../../../../../images/whiteShare.png'
import shareMobile from '../../../../../images/shareMobile.png'

const Share = () => {

  const dispatch = useDispatch()

  //redux variables
  const registerData = useSelector(state => state.register)

  //local variables
  const { registerId, sheetId } = registerData

  useEffect(() => {
    dispatch(getCurrentLinks({ registerId, sheetId }))
  }, [registerId, sheetId])

  const onShareIconClick = () => {
    dispatch(toggleDialogOpen(true))
    dispatch(updateHeaderText('Share'))
    dispatch(updateBody(<SharePopupBody />))
  }

  return (
    <div
      onClick={onShareIconClick}
      className="flex justify-center items-center shadow-lg px-2 mr-5 cursor-pointer"
      style={{ border: '1px solid #CAE2FF', borderRadius: 4, height: '32px', maxHeight: '90%' }}
    >

      <div className='hidden sm:flex justify-center items-center'>
        <span className="pr-1" style={{ color: '#CAE2FF', fontSize: 13 }}>Share</span>
        <img src={whiteShare} alt="whiteShare" style={{ height: 11 }} />
      </div>

      {/* mobile */}
      <img src={shareMobile} className='sm:hidden h-4' />
      {/* mobile */}

    </div>
  )
}

export default Share