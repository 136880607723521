import { useSelector } from 'react-redux'
import LastRowCellFormatter from './LastRowCellFormatter'

// utils
import { getValidatedFormatterVal } from '../../Utils'

const TextFormatter = ({ rowProperties, openEditorAfterSelect, colIdx, columnEl }) => {
  // redux variables
  const registerData = useSelector(state => state.register)

  // local variables
  const colKey = columnEl.key
  const rowIdx = rowProperties.rowIdx
  const rowId = rowProperties.row.rowId
  const value = getValidatedFormatterVal(rowProperties.row[colKey])
  const filteredRows = registerData.filteredRows
  const isLastRow = filteredRows.length - 1 === rowIdx
  const searchQuery = registerData.searchQuery
  const shouldHighlight = searchQuery !== '' && (value.toString().toLowerCase()).includes(searchQuery.toLowerCase())

  if (isLastRow) {
    return <LastRowCellFormatter
      rowProperties={rowProperties}
      openEditorAfterSelect={openEditorAfterSelect}
      colIdx={colIdx}
      colKey={colKey}
    />
  }
  return (
    <div
      id={`dataCell/${colKey}/${rowId}`}
      className="sheetItem1"
      style={{ backgroundColor: shouldHighlight ? '#ffd66e' : 'transparent' }}
    >
      <p className="px-1 truncate">{value}</p>
    </div>
  );
};

export default TextFormatter