import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// slices
import { setActiveActivityTab } from '../../../../../slices/RowDetailSlice'

//components
import CommentsSection from './Components/CommentsSection'
import HistorySection from './Components/HistorySection'

const Activties = () => {
  // redux variables
  const dispatch = useDispatch()
  const rowDetailData = useSelector(state => state.rowDetail)

  // local variables
  const { activeActivityTab, comments } = rowDetailData

  //--------------- functions ----------------------

  const onHistoryCommentClick = (tabToBeActivated) => {
    dispatch(setActiveActivityTab(tabToBeActivated))
  }

  return (
    <>
      <div className="hidden sm:flex" style={{ height: "7%" }}>
        {/*------Comments tab heading -------*/}
        <span onClick={() => onHistoryCommentClick("comments")} className="flex flex-col justify-center items-center w-1/2 cursor-pointer" style={{ color: activeActivityTab === "comments" ? "#173B67" : "#B6B6B6" }}>
          Comments
          {
            activeActivityTab === "comments" &&
            <span style={{ borderBottom: '3px solid #173B67', width: '50%', height: '2%', borderRadius: '25px' }} >&nbsp;</span>
          }
        </span>
        {/*------History tab heading -------*/}
        <span onClick={() => onHistoryCommentClick("history")} className="flex flex-col justify-center items-center w-1/2 cursor-pointer" style={{ color: activeActivityTab === "history" ? "#173B67" : "#B6B6B6" }}>
          History
          {
            activeActivityTab === "history" &&
            <span style={{ borderBottom: '3px solid #173B67', width: '50%', height: '2%', borderRadius: '25px' }} >&nbsp;</span>
          }
        </span>
      </div>
      {
        activeActivityTab === "comments" ?
          // comments count
          <div className="flex justify-end items-center pl-2 pr-2" style={{ height: "3%", fontSize: '0.7rem' }}>
            <span style={{ color: "#B6B6B6" }}>{comments.length === 0 ? '' : comments.length + ' comments'}</span>
          </div>
          :
          // today/all members tabs
          // <div className="flex items-center pl-2 pr-2" style={{ height: "3%", fontSize: '0.8rem' }}>
          //   <span className="flex justify-start items-center w-1/2" style={{ color: "#545454" }}>Today</span>
          //   <span className="flex justify-end items-center w-1/2" style={{ color: "#B6B6B6" }}>All Members</span>
          // </div>
          <></>
      }
      {/*------ CommentsSection / HistorySection -------*/}
      <div className={`${activeActivityTab === "comments" ? 'px-2' : 'pl-2'}`} style={{ height: "90%" }}>
        {
          activeActivityTab === "comments" ? <CommentsSection /> : <HistorySection />
        }
      </div>
    </>
  )
}

export default Activties