import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// slices
import { fetchFolderContentCopy } from "../../../../../../slices/LeftSidebarSlice";

const FolderElement = ({ folderEl: { folderId, name, avatar }, index, setSelectedRegister, setShowRegisterMenu }) => {

  // redux variables
  const dispatch = useDispatch()
  const leftSidebarData = useSelector(state => state.leftSidebar)

  // state variables
  const [showRegisters, setShowRegisters] = useState(false)
  const [folderContentLoading, setFolderContentLoading] = useState(false)
  const [selectedFolderData, setSelectedFolderData] = useState([])

  // local variables
  const { activeBusinessId } = leftSidebarData

  const onFolderElClick = async () => {
    setShowRegisters(!showRegisters)
    if (selectedFolderData.length == 0) {
      try {
        setFolderContentLoading(true)
        const result = await dispatch(fetchFolderContentCopy({ businessId: activeBusinessId, folderId: folderId }))
        setSelectedFolderData(result?.payload?.data?.registers ?? [])
      } catch (error) {
        console.log(error)
      }
      finally {
        setFolderContentLoading(false)
      }
    }
  }

  return (
    <>

      {/* Folder Name */}
      <li
        key={`${name} ${index}`}
        onClick={onFolderElClick}
        className='list-none px-6 cursor-pointer hover:bg-gray-100 transition-colors delay-50'
        style={{ color: '#174184' }}>
        <div className="border-b py-3 flex items-center gap-3 font-semibold">
          <img src={avatar} className='h-8' />
          {name}
        </div>
      </li>
      {/* Folder Name */}

      {/* Loader for reisters inside folder */}
      {
        showRegisters && folderContentLoading &&
        <div className="child-in-center py-2"><div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-600"></div></div>
      }
      {/* Loader for registers inside folder */}

      {/* Registers inside folder */}
      {
        showRegisters && !folderContentLoading &&
        selectedFolderData.map((reg, i) => {
          return (
            <li
              key={`${reg.name} ${i}`}
              onClick={() => { setSelectedRegister(reg); setShowRegisterMenu(false) }}
              className='list-none pl-10 pr-6 cursor-pointer hover:bg-gray-100 transition-colors delay-50'

              style={{ color: '#174184' }}>
              <div className="border-b py-3 flex items-center gap-3 font-semibold">
                <img src={reg.avatar} className='h-8' />
                {reg.name}
              </div>
            </li>
          )
        })
      }
      {/* Registers inside folder */}

    </>
  )
}

export default FolderElement