import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { column } from 'mathjs'
import { translate } from 'tailwindcss/defaultTheme'
import { getFilteredRows, getSortedRows, getRowsAfterDeletion, addPlusBtnCol, addNewRows, getNextSheetId, registerInitialStateObj } from '../../utils/Utils'

export const fetchRegisterData = createAsyncThunk('group/sheetDetail', async ({ registerId, sheetId, date, page }) => {
  const response = await axios.get('/v3/register/largeData/registerContent', { params: { registerId, sheetId, date, page } })
  if (response.data.success) return response.data.data
  else return { error: response.data.message }
})

// fetch register data with pagination
export const fetchAllRegisterData = createAsyncThunk('group/allSheetDetail', async ({ registerId, sheetId, date }) => {
  console.log('********** staging updated *************')
  let page = 1
  let previousData = {}
  let fetchPages = true
  let calculateRow
  while (fetchPages) {
    const response = await axios.get('/v3/register/largeData/registerContent', { params: { registerId, sheetId, date, page } })
    //const response2 = await axios.get('/v3/register/largeData/registerContent', { params: { registerId, sheetId, date, page: 2 } })
    //console.log('respose of fetch all data:', response.data.data)
    if (response.data.error) {
      fetchPages = false
      return { error: response.error }
    }
    if (response.data.data.rows.length === 0 || response.data.data.rows.length === 1) {
      fetchPages = false
    }
    if (page === 1 && fetchPages) {
      // First API call, set all data
      previousData = { ...response.data.data }
      calculateRow = previousData.rows.find(row => row.rowId === 'CALCULATE_ROW')
      previousData.rows = previousData.rows.filter(row => row.rowId !== 'CALCULATE_ROW')
      // find calculate row and remove it and add it in the end
    } else if (fetchPages) {
      // Subsequent API calls, update rows array only
      const rowsToAdd = response.data.data.rows.filter(row => row.rowId !== 'CALCULATE_ROW')
      previousData.rows = [...previousData.rows, ...rowsToAdd]
    }
    page++
  }
  //console.log('previous data:', previousData, calculateRow)
  previousData.rows = [...previousData.rows, calculateRow]
  return previousData
})
// fetch register data with pagination

export const updateRegisterData = createAsyncThunk('register/updateData', async ({ data, rowIndex, formData, entryFrom }) => {
  const response = await axios.post('/v3/sheet/largeData/updateData/', { data, entryFrom })
  let updatedResponse = { uiUpdate: { formData, rowIndex }, beUpdate: {} }
  if (response.data.success) {
    updatedResponse.beUpdate = response.data.data
  }
  return { updatedResponse, data, entryFrom }
})

export const fetchRegisterPages = createAsyncThunk('group/recordBookPages', async ({ registerId }) => {
  const response = await axios.get('/v3/register/allSheets', { params: { registerId } })
  if (response.data.success) return response.data.data
  else return { error: response.data }
})


//api call to add row
export const addEmptyRowToSheet = createAsyncThunk('add/row', async ({ sheetId, topRowId, count, bottomRowId, idxToBeScrolled }) => {
  const response = await axios.post('/v3/sheet/row/largeData/addEmptyRows', { sheetId, count, topRowId, bottomRowId })
  return { data: response.data.data, count, topRowId, idxToBeScrolled }
})

//api call to download pdf
export const pdfDownload = createAsyncThunk('download/pdf', async ({ sheetId, communityId, name, selectedRowIds, date }) => {
  let apiParamObj = { sheetId }
  if (date) apiParamObj['date'] = date // specific case of date page
  if (selectedRowIds.length > 0) {
    if (selectedRowIds.length === 1) {
      apiParamObj.rowId = selectedRowIds[0]
    } else {
      apiParamObj.selectedRowIds = selectedRowIds
    }
  }

  // https://devcomm.rekord.in/v3/sheet/largeData/exportPdfTemplate
  const response = await axios.post(
    'https://comm.rekord.in/v3/sheet/largeData/exportPdfTemplate',
    apiParamObj
  )
  if (response.data) {
    const url = response.data.data.file
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${name}.pdf`); //or any other extension
    document.body.appendChild(link);
    link.click();
  }
})

//api call to download excel
export const excelDownload = createAsyncThunk('download/excel', async ({ sheetId, communityId, name, selectedRowIds, date }) => {
  let apiParamObj = { sheetId }
  if (date) apiParamObj['date'] = date // specific case of date page
  if (selectedRowIds.length > 0) {
    if (selectedRowIds.length === 1) {
      apiParamObj.rowId = selectedRowIds[0]
    } else {
      apiParamObj.selectedRowIds = selectedRowIds
    }
  }
  //https://devcomm.rekord.in/v3/sheet/largeData/recordSheetExportToExcel
  const response = await axios.post(
    'https://comm.rekord.in/v3/sheet/largeData/recordSheetExportToExcel',
    apiParamObj
  )
  if (response.data) {
    const url = response.data.data.file
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${name}.pdf`); //or any other extension
    document.body.appendChild(link);
    link.click();
  }
})

//api call to update width of a column
export const updateWidth = createAsyncThunk('update/col-width', async ({ sheetId, columnWebLength, columnId }) => {
  const response = await axios.post('/loggedapiv2/updateSheetColumnLengthWeb', { sheetId, columnWebLength, columnId })
  return response.data.data
})

//Select one api calls
//to delete an option
export const deleteSelectOneOption = createAsyncThunk('select-one/delete', async ({ sheetId, columnId, option, optionsArray }) => {
  const response = await axios.post('/loggedapiv2/removeSheetColumnMultiOptions', { sheetId, columnId, option })
  return { data: response.data.data, columnId, optionsArray }
})

//to rename an option
export const renameSelectOneOption = createAsyncThunk('select-one/rename', async ({ sheetId, columnId, oldOption, option, color, optionsArray }) => {
  const response = await axios.post('/loggedapiv2/renameSheetColumnMultiOptions', { sheetId, columnId, oldOption, option, color })
  return { data: response.data.data, columnId, optionsArray }
})

//to add an option
export const addSelectOneOption = createAsyncThunk('select-one/add', async ({ sheetId, columnId, option, optionsArray }) => {
  const response = await axios.post('/loggedapiv2/addSheetColumnMultiOptions', { sheetId, columnId, option })
  return { data: response.data.data, columnId, optionsArray }
})

//api call to delete row
export const deleteRow = createAsyncThunk('register/deleteRow', async ({ rowId, sheetId }) => {
  const response = await axios.post('/v3/sheet/row/largeData/deleteRow', { rowId, sheetId })
  if (response.data.success) return { rowId }
})

export const addNewColumn = createAsyncThunk('register/addNewColumn', async ({ sheetId, leftColumnId, columnName, dataType, preOpenColMenu }) => {
  const response = await axios.post('/v3/sheet/column/largeData/add', { sheetId, leftColumnId, columnName, dataType })
  if (response.data.success) {
    return { data: response.data.data, preOpenColMenu }
  }
  return { data: response.data.data, preOpenColMenu }
})

export const updateColumn = createAsyncThunk('register/updateColumn', async ({ sheetId, columnId, columnName, dataType, linkedDataType, linkedSheetId, linkedColumnId, linkedRegisterId, columnDescription }) => {
  const response = await axios.post('/v3/sheet/column/largeData/edit', { sheetId, columnId, columnName, dataType, linkedDataType, linkedSheetId, linkedColumnId, linkedRegisterId, columnDescription })
  if (response.data.success) {
    return { data: response.data.data, linkedData: { linkedDataType, linkedSheetId, linkedColumnId, linkedRegisterId, columnDescription } }
  }
  return { data: response.data.data, linkedData: { linkedDataType, linkedSheetId, linkedColumnId, linkedRegisterId, columnDescription } }
})

export const sheetColumnDelete = createAsyncThunk('register/sheetColumnDelete', async ({ sheetId, columnId }) => {
  const response = await axios.post('/v3/sheet/column/largeData/delete', { sheetId, columnId })
  if (response.data.success) {
    return columnId
  }
  return columnId
})

export const sheetColumnFreeze = createAsyncThunk('register/sheetColumnFreeze', async ({ sheetId, columnId, isFreezed }) => {
  const response = await axios.post('/loggedapiv2/sheetColumnFreezeWeb', { sheetId, columnId, isFreezed })
  if (response.data.success) {
    return { columnId, isFreezed }
  }
  return { columnId, isFreezed }
})

export const sheetColumnMovePosition = createAsyncThunk('register/sheetColumnMovePosition', async ({ sheetId, newPositions, colMovedInfo }) => {
  const response = await axios.post('/v3/sheet/column/largeData/moveColumnPosition', { sheetId, newPositions })
  if (response.data.success) {
    return { colMovedInfo }
  }
  return { colMovedInfo }
})

export const sheetColumnDefaultFormula = createAsyncThunk('register/sheetColumnDefaultFormula', async ({ sheetId, columnId, defaultRowFormula }) => {
  const response = await axios.post('/loggedapiv2/sheetColumnDefaultFormula', { sheetId, columnId, defaultRowFormula })
  if (response.data.success) { }
  return {}
})

export const renamePage = createAsyncThunk('register/renamePage', async ({ sheetId, idxOfSheetWhoseNameIsBeingEdited, name }) => {
  const response = await axios.post('v3/sheet/rename', { sheetId, name })
  if (response.data.success) {
    return { idxOfSheetWhoseNameIsBeingEdited, newPageName: name }
  }
  return { idxOfSheetWhoseNameIsBeingEdited, newPageName: name }
})

export const deletePage = createAsyncThunk('register/deletePage', async ({ sheetId, sheetBeingDeltdIdx, registerId }) => {
  const response = await axios.post('/v3/sheet/delete', { sheetId, registerId })
  if (response.data.success) {
    return { sheetBeingDeltdIdx }
  }
  return { sheetBeingDeltdIdx }
})

export const getAllColumns = createAsyncThunk('register/currentPageColumns', async ({ sheetId }) => {
  const response = await axios.get('/loggedapiv2/currentPageColumns?sheetId=' + sheetId)
  if (response.data.success) {
    return { data: response.data.data }
  }
  return { data: response.data.data }
})

export const uploadExcel = createAsyncThunk('register/uploadExcel', async ({
  file, currentSheetId, registerId, businessId, newSheetName, excelSheetName }) => {
  let formaData = new FormData();
  formaData.append('file', file)
  formaData.append('currentSheetId', currentSheetId)
  formaData.append('registerId', registerId)
  formaData.append('businessId', businessId)
  formaData.append('newSheetName', newSheetName)
  formaData.append('excelSheetName', excelSheetName)
  const response = await axios.post('/v3/upload/excelAsPage', formaData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  if (response.data.success) {
    return { data: response.data.data }
  }
  return { data: response.data.data }
})

export const addNewPage = createAsyncThunk('register/addNewPage', async ({ currentSheetId, registerId, businessId, newSheetName, retainColumns }) => {
  const response = await axios.post('/v3/register/largeData/addSheet', { currentSheetId, registerId, businessId, newSheetName, retainColumns })
  if (response.data.success) {
    return { data: response.data.data }
  }
  return { data: response.data.data }
})

export const updateStatuses = createAsyncThunk('status/update', async ({ sheetId, columnId, statusArray }) => {
  const response = await axios.post('/v3/sheet/columnType/updateStatus', { sheetId, columnId, statusArray })
  return { data: response.data.data, statusArray, columnId }
})

export const updateColumnFormula = createAsyncThunk('register/sheet/columnType/updateFormula', async ({ columnId, sheetId, formulaArray }) => {
  const response = await axios.post('/v3/sheet/columnType/largeData/updateFormula', { columnId, sheetId, formulaArray })
  if (response.data.success) {
    return { data: response.data.data, columnId }
  }
  return { data: response.data.data, columnId }
})

// Sorting save
export const saveSorting = createAsyncThunk('register/sheet/saveSorting', async ({ registerId, sheetId, sorts }) => {
  const response = await axios.post('/v3/sheet/saveSorting', { registerId, sheetId, sorts })
  return { data: response.data, sheetId, sorts }
})
// Sorting save

//IIIT delhi
export const addDataByQr = createAsyncThunk('register/sheet/addDataByQR', async ({ sheetId, qrValue }) => {
  const response = await axios.post('/v3/sheet/addDataByQR', { sheetId, qrValue })
  return response.data
})
//IIIT delhi

export const saveFilters = createAsyncThunk('/v3/sheet/saveFilters?language=en', async ({ sheetId, filters }) => {
  const response = await axios.post('/v3/sheet/saveFilters?language=en', { sheetId, filters })
  if (response.data.success) {
    return { filters }
  }
  return { errorMessage: response.data.data }
})

export const saveRowDetailsViewType = createAsyncThunk('/v3/sheet/saveRowDetailsLayout', async ({ sheetId, rowDetailsViewType }) => {
  const response = await axios.post('/v3/sheet/rowDetailsViewTypeUpdate', { sheetId, rowDetailsViewType })
  if (response.data.success) {
    return { type: rowDetailsViewType }
  }
  return response.data
})

// copies of register content and register pages api
export const fetchRegisterDataAncestry = createAsyncThunk('group/sheetDetail/copy', async ({ registerId, sheetId }) => {
  const response = await axios.get('/v3/register/largeData/registerContent', { params: { registerId, sheetId } })
  if (response.data.success) return response.data.data
  else return { error: response.data.message }
})

export const fetchRegisterPagesAncestry = createAsyncThunk('group/recordBookPages/copy', async ({ registerId }) => {
  const response = await axios.get('/v3/register/allSheets', { params: { registerId } })
  if (response.data.success) return response.data.data
  else return { error: response.data }
})
// copies of register content and register pages api

export const registerSlice = createSlice({
  name: 'sidebar',

  initialState: {
    // registerContent api JSON schema
    ...registerInitialStateObj,
    // unclassified
    status: "",
    rowIndex: -1,
    pages: [],
    uploadedImagesArr: [],
    areImagesUploading: false,
    calculateLastRow: false,
    searchQuery: '',
    showFullScreenRegisterLoader: false,
    preOpenColMenu: null,
    rowDeleteCompleted: false,
    rowAddCompletedInfo: null,
    deletedColumnInfo: {},
    pageColumns: [],
    newSheetInfo: null,
    nextSheetInfo: null,
    columnFormulaUpdated: false,
    dataAddition: '',
    qrNewEntryData: null,
    successMessage: null,
    isAdmin: false,
    hasBasicEditPermission: false,
    // loaders
    isLoading: false,
    isLoadingPdf: false,
    isLoadingExcel: false,
    fetchRegisterDataLoader: false,
    rowCrudLoader: false,
    columnCrudLoader: false,
    // error
    error: null,
    errorMessage: null,
  },

  reducers: {
    togglePdfButtonLoader: state => {
      state.isLoadingPdf = !state.isLoadingPdf
    },
    toggleExcelButtonLoader: state => {
      state.isLoadingExcel = !state.isLoadingExcel
    },
    setRowIndex: (state, action) => {
      state.rowIndex = action.payload
    },
    setRowDeleteCompleted: (state, action) => {
      state.rowDeleteCompleted = action.payload
    },
    updateRegisterLocally: (state, action) => {
      state.rows[action.payload.rowIndex] = action.payload.formData
      state.filteredRows = getFilteredRows(state.searchQuery, state.rows, state.filters, state.columns)
      state.calculateLastRow = false
    },
    updateSearchQuery: (state, action) => {
      state.searchQuery = action.payload
      state.filteredRows = getFilteredRows(state.searchQuery, state.rows, state.filters, state.columns)
    },
    setPreOpenColMenu: (state, action) => {
      state.preOpenColMenu = action.payload
    },
    sortRows: (state, action) => {
      state.columnCrudLoader = true
      const { direction, rows, dataType } = action.payload
      const colId = action.payload.columnId
      state.sortedColKey = colId
      state.sortDirection = direction
      state.rows = getSortedRows(rows, colId, direction, dataType) //direction: { 1 : A to Z, 2 : Z to A }
      state.filteredRows = getFilteredRows(state.searchQuery, state.rows, state.filters, state.columns)
      state.columnCrudLoader = false
    },
    updateRegisterState: (state, action) => {
      action.payload.forEach((el) => {
        const key = el.key
        const value = el.value
        state[key] = value
      })
    }
  },

  extraReducers: {
    [fetchAllRegisterData.pending]: (state, action) => {
      state.showFullScreenRegisterLoader = true
      state.fetchRegisterDataLoader = true
    },
    [fetchAllRegisterData.fulfilled]: (state, action) => {
      state.showFullScreenRegisterLoader = false
      state.fetchRegisterDataLoader = false
      let p = action.payload
      if (p.error) { // invalid register
        Object.keys(registerInitialStateObj).forEach(key => {
          state[key] = registerInitialStateObj[key]
        })
        return
      }
      state.registerId = p.registerId
      state.editPermissions = p.editPermissions
      state.groupId = p.registerId
      state.activeSheetName = p.activeSheetName
      state.registerName = p.registerName
      state.noOfMembers = p.noOfMembers
      state.registerAvatar = p.registerAvatar
      state.name = p.name
      state.permissions = p.permissions
      state.rows = p.rows
      state.filteredRows = getFilteredRows(state.searchQuery, state.rows, p.filters, p.columns)
      state.sheetId = p.sheetId
      state.columns = addPlusBtnCol(p.columns)
      state.dataAddition = p.dataAddition
      state.groupDataBy = p.groupDataBy
      state.filters = p.filters
      state.rowDetailsViewType = p.rowDetailsViewType
      if (state.sortedColKey != '') { state.sortedColKey = '' }
      if (state.sortDirection != 0) { state.sortDirection = 0 }
      // permissions calculator
      state.isAdmin = p.permissions.includes('admin')
      state.hasBasicEditPermission = p.permissions.includes('edit')
      if (p.sorts && p.sorts.length > 0) {
        state.sortedColKey = p.sorts[0].columnId
        let sortDirection = p.sorts[0].sortBy == 'ASCENDING' ? 1 : 2
        state.sortDirection = sortDirection
      }
    },
    [fetchAllRegisterData.rejected]: (state, action) => {
      state.showFullScreenRegisterLoader = false
      state.fetchRegisterDataLoader = false
      state.errorMessage = action.error.message
    },
    [fetchRegisterPages.fulfilled]: (state, action) => {
      const error = action.payload.error
      if (!error) {
        state.status = ""
        const sheets = action.payload.sheets
        state.pages = sheets
      } else {
        state.errorMessage = error
      }
    },
    [addEmptyRowToSheet.pending]: state => {
      state.rowCrudLoader = true
    },
    [addEmptyRowToSheet.fulfilled]: (state, action) => {
      const newRowIds = action.payload.data.newRowIds // type array
      const topRowId = action.payload.topRowId
      const idxToBeScrolled = action.payload.idxToBeScrolled
      state.rows = addNewRows(state.rows, newRowIds, topRowId, state.columns)
      state.filteredRows = getFilteredRows(state.searchQuery, state.rows, state.filters, state.columns)
      state.searchQuery = ''
      state.rowCrudLoader = false
      state.rowAddCompletedInfo = { idxToBeScrolled }
    },
    [addEmptyRowToSheet.rejected]: state => {
      state.rowCrudLoader = false
    },
    [updateRegisterData.pending]: (state, action) => {
      state.isLoading = true
    },
    [updateRegisterData.fulfilled]: (state, action) => {
      let { updatedResponse } = action.payload
      const beUpdate = updatedResponse.beUpdate
      const uiUpdate = updatedResponse.uiUpdate
      const idxToBeScrolled = updatedResponse.idxToBeScrolled ? idxToBeScrolled : -1
      const isNewRowAtFE = uiUpdate.formData.rowId === 'LAST_0'
      const newRowId = beUpdate.afterUpdate[0] ? beUpdate.afterUpdate[0].newRowId : uiUpdate.formData.rowId
      if (beUpdate.message) state.successMessage = beUpdate.message
      if (uiUpdate.formData.rowId !== newRowId) {
        uiUpdate.formData.rowId = newRowId
      }
      if (isNewRowAtFE) {
        state.rowAddCompletedInfo = { idxToBeScrolled }
      }
      state.isLoading = false
      state.rowIndex = -1
      state.calculateLastRow = true
      if (isNewRowAtFE) { // it should be added in the existing rows with S.No config
        state.rows.splice(state.rows.length - 1, 0, uiUpdate.formData)
        state.rows[state.rows.length - 2]['-1'] = (state.rows.length - 1).toString()
      } else { // it should be updated in the existing rows
        state.rows[uiUpdate.rowIndex] = uiUpdate.formData
      }
      if (state.sortedColKey && state.sortedColKey !== '') {
        state.rows = getSortedRows(state.rows, state.sortedColKey, state.sortDirection, null, state.columns)
      }
      state.filteredRows = getFilteredRows(state.searchQuery, state.rows, state.filters, state.columns)

    },
    [updateRegisterData.rejected]: (state, action) => {
      state.isLoading = false
      state.rowIndex = -1
    },
    [deleteRow.pending]: state => {
      state.rowCrudLoader = true
    },
    [deleteRow.fulfilled]: (state, action) => {
      state.rowCrudLoader = false
      state.rows = getRowsAfterDeletion(state.rows, action.payload.rowId)
      state.filteredRows = getFilteredRows(state.searchQuery, state.rows, state.filters, state.columns)
      state.calculateLastRow = true
      state.rowDeleteCompleted = true
    },
    [deleteRow.rejected]: state => {
      state.rowCrudLoader = false
    },
    [updateColumn.pending]: state => {
      state.columnCrudLoader = true
    },
    [updateColumn.fulfilled]: (state, action) => {
      const columnDetails = action.payload.data
      const { linkedColumnId, linkedDataType, linkedRegisterId, linkedSheetId, columnDescription } = action.payload.linkedData
      state.columnCrudLoader = false
      state.columns.forEach((el) => {
        if (el.key === columnDetails.columnId) {
          el.name = columnDetails.value
          el.dataType = columnDetails.dataType
          el.key = columnDetails.columnId
          el.formula = columnDetails.formula
          el.isFreezed = columnDetails.isFreezed
          el.isRequired = columnDetails.isRequired
          el.multiOptions = columnDetails.multiOptions
          el.properties = columnDetails.properties
          el.width = columnDetails.width
          if (linkedRegisterId) {
            el.linkedColumnId = linkedColumnId
            el.linkedDataType = linkedDataType
            el.linkedRegisterId = linkedRegisterId
            el.linkedSheetId = linkedSheetId
            el.description = columnDescription
          }
        }
      })
    },
    [updateColumn.rejected]: (state, action) => {
      state.columnCrudLoader = false
    },
    [addNewColumn.pending]: state => {
      state.columnCrudLoader = true
    },
    [addNewColumn.fulfilled]: (state, action) => {
      let columnDetails = action.payload.data
      state.preOpenColMenu = action.payload.preOpenColMenu // linked with ColumnHeader
      columnDetails['key'] = columnDetails.columnId
      columnDetails['name'] = columnDetails.value
      state.columnCrudLoader = false
      state.columns.splice(columnDetails.index + 1, 0, columnDetails);

    },
    [addNewColumn.rejected]: (state, action) => {
      state.columnCrudLoader = false
    },
    [sheetColumnDelete.pending]: state => {
      state.columnCrudLoader = true
    },
    [sheetColumnDelete.fulfilled]: (state, action) => {
      let columnId = action.payload
      const colIdxToBeDeleted = state.columns.findIndex(el => el.key === columnId)
      state.columnCrudLoader = false
      state.columns.splice(colIdxToBeDeleted, 1);
      state.deletedColumnInfo = { idx: colIdxToBeDeleted, columnId }
    },
    [sheetColumnDelete.rejected]: (state, action) => {
      state.columnCrudLoader = false
    },
    [sheetColumnFreeze.pending]: state => {
      state.columnCrudLoader = true
    },
    [sheetColumnFreeze.fulfilled]: (state, action) => {
      let columnId = action.payload.columnId
      const frozenColumnIdx = state.columns.findIndex(el => el.key === columnId)
      state.columnCrudLoader = false
      state.columns[frozenColumnIdx].isFreezed = action.payload.isFreezed
    },
    [sheetColumnFreeze.rejected]: state => {
      state.columnCrudLoader = false
    },
    [deleteSelectOneOption.pending]: state => {
      state.isLoading = true
    },
    [deleteSelectOneOption.fulfilled]: (state, action) => {
      const { columnId, optionsArray } = action.payload
      state.columns.forEach((el) => {
        if (el.key == columnId) {
          el.multiOptions = optionsArray
        }
      })
      state.isLoading = false
    },
    [deleteSelectOneOption.rejected]: state => {
      state.isLoading = false
    },
    [renameSelectOneOption.pending]: state => {
      state.isLoading = true
    },
    [renameSelectOneOption.fulfilled]: (state, action) => {
      const { optionsArray, columnId } = action.payload
      state.columns.forEach((el) => {
        if (el.key == columnId) {
          el.multiOptions = optionsArray
        }
      })
      state.isLoading = false
    },
    [renameSelectOneOption.rejected]: state => {
      state.isLoading = false
    },
    [addSelectOneOption.pending]: state => {
      state.isLoading = true
    },
    [addSelectOneOption.fulfilled]: (state, action) => {
      const { optionsArray, columnId } = action.payload
      state.columns.forEach((el) => {
        if (el.key == columnId) {
          el.multiOptions = optionsArray
        }
      })
      state.isLoading = false
    },
    [addSelectOneOption.rejected]: state => {
      state.isLoading = false
    },
    [sheetColumnMovePosition.pending]: state => {
      state.columnCrudLoader = true
    },
    [sheetColumnMovePosition.fulfilled]: (state, action) => {
      const colMovedInfo = action.payload.colMovedInfo
      const columnToBeMoved = state.columns[colMovedInfo.colIdx]
      state.columns.splice(colMovedInfo.colIdx, 1)
      state.columns.splice(colMovedInfo.newColIdx, 0, columnToBeMoved)
      state.columnCrudLoader = false
    },
    [sheetColumnMovePosition.rejected]: state => {
      state.columnCrudLoader = false
    },
    [sheetColumnDefaultFormula.pending]: state => {
      state.columnCrudLoader = true
    },
    [sheetColumnDefaultFormula.fulfilled]: state => {
      state.columnCrudLoader = false
    },
    [sheetColumnDefaultFormula.rejected]: state => {
      state.columnCrudLoader = false
    },
    [renamePage.pending]: state => {
      state.isLoading = true
    },
    [renamePage.fulfilled]: (state, action) => {
      const idxOfSheetWhoseNameIsBeingEdited = action.payload.idxOfSheetWhoseNameIsBeingEdited
      const newPageName = action.payload.newPageName
      state.pages[idxOfSheetWhoseNameIsBeingEdited].sheetName = newPageName
      // update registerData at UI for Sheet dropdown button
      if (state.sheetId === state.pages[idxOfSheetWhoseNameIsBeingEdited].sheetId) {
        state.activeSheetName = newPageName
      }
      state.isLoading = false
    },
    [renamePage.rejected]: state => {
      state.isLoading = false
    },
    [deletePage.pending]: state => {
      state.isLoading = true
    },
    [deletePage.fulfilled]: (state, action) => {
      state.isLoading = false

      const sheetBeingDeltdIdx = action.payload.sheetBeingDeltdIdx
      state.pages.splice(sheetBeingDeltdIdx, 1)

      const sheetId = getNextSheetId(sheetBeingDeltdIdx, state.pages)
      state.nextSheetInfo = { sheetId }
    },
    [deletePage.rejected]: state => {
      state.isLoading = false
    },
    [getAllColumns.pending]: state => {
      state.isLoading = true
    },
    [getAllColumns.fulfilled]: (state, action) => {
      state.pageColumns = action.payload.data
      state.isLoading = false
    },
    [getAllColumns.rejected]: state => {
      state.isLoading = false
    },
    [uploadExcel.pending]: state => {
      state.isLoading = true
    },
    [uploadExcel.fulfilled]: (state, action) => {
      state.newSheetInfo = action.payload.data
      state.isLoading = false
    },
    [uploadExcel.rejected]: state => {
      state.isLoading = false
    },
    [addNewPage.pending]: state => {
      state.isLoading = true
    },
    [addNewPage.fulfilled]: (state, action) => {
      state.newSheetInfo = action.payload.data
      state.isLoading = false
    },
    [addNewPage.rejected]: state => {
      state.isLoading = false
    },
    [updateColumnFormula.pending]: state => {
      state.isLoading = true
    },
    [updateColumnFormula.fulfilled]: (state, action) => {
      const { columnId } = action.payload
      const { newFormula } = action.payload.data
      state.columnFormulaUpdated = true
      state.isLoading = false
      state.columns.forEach((el) => {
        if (el.key === columnId) {
          el.dataType = 'formula'
          el.formula = newFormula
        }
      })
    },
    [updateColumnFormula.rejected]: state => {
      state.isLoading = false
    },
    [updateStatuses.pending]: state => {
      state.isLoading = true
    },
    [updateStatuses.fulfilled]: (state, action) => {
      const { statusArray, columnId } = action.payload
      state.columns.forEach((el) => {
        if (el.key === columnId) {
          el.dataType = 'status'
          el.multiOptions = statusArray
        }
      })
      state.isLoading = false
    },
    [updateStatuses.rejected]: state => {
      state.isLoading = false
    },
    [addDataByQr.fulfilled]: (state, action) => {
      const success = action.payload.success
      const data = action.payload.data
      if (success) {
        state.qrNewEntryData = data.newEntryData
        state.successMessage = data.message
      } else {
        state.errorMessage = data.message
      }
    },
    [addDataByQr.rejected]: (state, action) => {

    },
    [saveSorting.pending]: state => {
      state.showFullScreenRegisterLoader = true
    },
    [saveSorting.fulfilled]: (state, action) => {
      state.showFullScreenRegisterLoader = false
      let columnId
      let sortDirection
      if (action.payload.sorts.length == 0) {
        columnId = ''
        sortDirection = 0
      } else {
        columnId = action.payload.sorts[0].columnId
        sortDirection = action.payload.sorts[0].sortBy == "ASCENDING" ? 1 : 2
      }
      state.sortedColKey = columnId
      state.sortDirection = sortDirection
    },
    [saveSorting.rejected]: state => {
      state.showFullScreenRegisterLoader = false
    },
    [saveFilters.pending]: state => {
      state.isLoading = true
    },
    [saveFilters.fulfilled]: (state, action) => {
      const errorMessage = action.payload.errorMessage
      const updatedFilters = action.payload.filters
      if (updatedFilters) {
        state.filters = updatedFilters
        state.filteredRows = getFilteredRows(state.searchQuery, state.rows, state.filters, state.columns)
      } else if (errorMessage) {
        state.errorMessage = errorMessage
      }
      state.isLoading = false
    },
    [saveFilters.rejected]: state => {
      state.isLoading = false
    },
    [saveRowDetailsViewType.fulfilled]: (state, action) => {
      state.rowDetailsViewType = action.payload.type
    }
  }
})

export const { togglePdfButtonLoader, toggleExcelButtonLoader, loadImages, setRowIndex, updateRegisterLocally, updateSearchQuery, setPreOpenColMenu, sortRows, setRowDeleteCompleted, setSortedColKey, setSortDirection, updateRegisterState } = registerSlice.actions

export default registerSlice.reducer

