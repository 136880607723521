import createdAtIcon from '../../../../../../images/datatype_icons/light/created-at.png'

const CreatedAt = ({ colkey, column, handleChange, formData }) => {
  return (
    <div className="mb-6">
      <div className="colName">
        <img className="field_icon" src={createdAtIcon} alt="" />
        <label htmlFor={column.name}>{column.name}</label>
      </div>
      <div className="field non_editable cursor-not-allowed">{formData[colkey]}</div>
    </div>
  )
}

export default CreatedAt