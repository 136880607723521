import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

// components
import RegisterInfo from './Components/RegisterInfo'
import Share from './Components/Share'
import Download from './Components/Download'
import RegisterTools from './Components/RegisterTools/index'
import SheetsHeader from './Components/SheetsHeader'
import Hamburger from './Components/Hamburger'

// css
import './index.css'

const Header = () => {
  // redux variables
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  // local variables
  const location = useLocation()
  const { fetchRegisterDataLoader, columnCrudLoader, rowCrudLoader, isLoading } = registerData

  // state variables
  const [showRegisterTools, setShowRegisterTools] = useState(false)

  useEffect(() => {
    if (location.pathname.includes('register') && !showRegisterTools) {
      setShowRegisterTools(true)
    } else if (!location.pathname.includes('register') && showRegisterTools) {
      setShowRegisterTools(false)
    }
  }, [location.pathname])

  const isRegisterLoading = () => {
    return fetchRegisterDataLoader || columnCrudLoader || rowCrudLoader || isLoading
  }

  return (
    <header className={` visible ${registerData.sheetId === "" && "lg:hidden"} h-full sticky flex`} style={{ backgroundColor: "#044089" }}>
      <div className="lg:hidden flex items-center pl-2 absolute" >
        <Hamburger />
      </div>
      {
        showRegisterTools && registerData.sheetId !== "" &&
        <div className="flex flex-col w-full" >

          {/* Segment 1 starts */}
          <div className="flex w-full py-2" style={{ height: '40%' }}>
            <div className="grid grid-cols-2 w-full">
              <RegisterInfo />
              <div className='flex items-center place-self-end h-full'>
                <Download />
                <Share />
              </div>
            </div>

          </div>
          {/* Segment 1 ends */}

          {/* Segment 2 starts */}
          <div className="flex relative w-full" style={{ height: '22%', zIndex: 2 }} >
            <SheetsHeader />
          </div>
          {/* Segment 2 ends */}

          {/* Segment 3 starts */}
          <div className="relative flex w-full bg-white py-2" style={{ height: '38%', zIndex: 1 }}>
            <RegisterTools />
          </div>
          {/* Segment 3 ends */}

        </div>
      }

    </header>
  );
}

export default Header;

