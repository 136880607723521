import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//slices
import { enableRegisterLink, disableRegisterLink, enableFormLink, getCurrentLinks, disableFormLink } from '../../../../../slices/DialogSlice'
import { setToastDetails } from '../../../../../slices/ToastSlice'

//components
import ColumnsMultiSelect from './ColumnsMultiSelect'

// utils
import { Mixpanel } from '../../../../../partials/Mixpanel'

//slices

//icons and images
import copyLinkBtn from '../../../../../images/dialog/share/copy-link.png'
import linkCopiedGif from '../../../../../images/dialog/share/link-copied.gif'

const SharePopupBody = () => {

  const dispatch = useDispatch()

  //redux variables
  const theme = useSelector(state => state.theme)
  const registerData = useSelector(state => state.register)
  const dialogData = useSelector(state => state.dialog)

  //state variables
  const [toggle1on, setToggle1on] = useState(false)
  const [toggle2on, setToggle2on] = useState(false)
  const [colIdsArrForApi, setColIdsArrForApi] = useState([])
  const [isRegisterLinkCopied, setIsRegisterLinkCopied] = useState(false)
  const [isFormLinkCopied, setIsFormLinkCopied] = useState(false)

  //local variables
  const { dark_grey } = theme
  const { registerId, sheetId } = registerData
  const { registerPublicLink, formPublicLink } = dialogData

  const data = {
    HeaderText1: `Share register by public link`,
    BodyText1: `This register is shareable via public link to view.`,
    HeaderText2: `Add new entry by public form`,
    BodyText2: `Forms created through individual rows, can be sent out to 
    anyone to fill.`,
  }
  const textColor = '#B7B6B6'

  useEffect(() => {
    if (registerPublicLink) {
      setToggle1on(true)
    }
    if (formPublicLink) {
      setToggle2on(true)
    }
  }, [])

  const onToggle1change = ({ isToggleOn }) => {
    setToggle1on(!toggle1on)
    if (!isToggleOn) {
      dispatch(enableRegisterLink({ registerId }))
    } else {
      dispatch(disableRegisterLink({ registerId }))
    }
  }

  const onToggle2change = async ({ isToggleOn }) => {
    setToggle2on(!toggle2on)
    if (!isToggleOn) {
      dispatch(enableFormLink({ registerId, sheetId, columns: colIdsArrForApi }))
    } else {
      dispatch(disableFormLink({ registerId, sheetId }))
    }
  }

  const onButton1Click = () => {
    if (registerPublicLink) {
      // dispatch(setToastDetails({ message: 'Link Copied !', type: 'success' }))
      copyTextToClipboard(registerPublicLink)
        .then(() => {
          setIsRegisterLinkCopied(true);
          setTimeout(() => {
            setIsRegisterLinkCopied(false);
          }, 2000);
          Mixpanel.track('Public Link Shared Web');
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }

  const onButton2Click = () => {
    if (formPublicLink) {
      // dispatch(setToastDetails({ message: 'Link Copied !', type: 'success' }))
      copyTextToClipboard(formPublicLink)
        .then(() => {
          setIsFormLinkCopied(true);
          setTimeout(() => {
            setIsFormLinkCopied(false);
          }, 2000);
          Mixpanel.track('Export Public form Shared');
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }

  const copyTextToClipboard = async (text) => {
    return await navigator.clipboard.writeText(text);
  }

  const classes = {
    toggleMainContainer: `bg-white p-4 rounded-lg border`,
    toggleSubContainer: `flex justify-between items-center gap-6`,
    headertext: `text-xl transition-colors delay-50 ease-in`,
    bodyText: `text-xs`,
    copyLinkBtn: `button button1 mt-5`,
    copyLinkBtn1: `${toggle1on ? '' : 'hidden'}`,
    copyLinkBtn2: `${toggle2on ? '' : 'hidden'}`
  }

  const styles = {
    headerText1: { color: toggle1on? dark_grey : '#B6B6B6' },
    headerText2: { color: toggle2on? dark_grey : '#B6B6B6' },
    bodyText: { color: textColor },
    toggle1Container: { borderColor: toggle1on ? '#044089' : '' },
    toggle2Container: { borderColor: toggle2on ? '#044089' : '' }
  }

  return (
    <div className='p-6 overflow-y-auto' style={{ width: '35rem', minHeight: '50vh', maxWidth: '95vw', maxHeight: '80vh', backgroundColor: '#F1F8FF' }}>

      {/* PUBLIC REGISTER SECTION */}
      <div className={`${classes.toggleMainContainer} mb-4`}>
        <div className={classes.toggleSubContainer}>

          <div>
            <div className={classes.headertext} style={styles.headerText1}>{data.HeaderText1}</div>
            <div className={classes.bodyText} style={styles.bodyText}>{data.BodyText1}</div>
          </div>

          {/* Toggle 1 */}
          <label className="flex items-center cursor-pointer" >
            <div className="relative">
              <input type="checkbox" className="sr-only" onChange={() => onToggle1change({ isToggleOn: toggle1on })} checked={toggle1on} role="switch" />
              <div className="block w-10 h-6 rounded-full toggle-container" style={styles.toggle1Container}></div>
              <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
            </div>
          </label>
          {/* Toggle 1 */}

        </div>

        {/* Public register link copy button */}
        <div className={`${classes.copyLinkBtn} ${classes.copyLinkBtn1}`} onClick={onButton1Click}>
          {
            registerPublicLink ? (
              <>
                <img src={copyLinkBtn} />
                <div>Copy Link</div>
              </>
            ) : (
              <div className="animate-spin loader loader-button1"></div>
            )
          }
        </div>
        {/* Public register link copy button */}
        {isRegisterLinkCopied && <div className='flex justify-center mt-2'><img src={linkCopiedGif} className="h-8"/></div>}
        
      </div>
      {/* PUBLIC REGISTER SECTION */}

      {/* PUBLIC FORM SECTION */}
      <div className={classes.toggleMainContainer}>
        <div className={classes.toggleSubContainer}>

          <div>
            <div className={classes.headertext} style={styles.headerText2}>{data.HeaderText2}</div>
            <div className={classes.bodyText} style={styles.bodyText}>{data.BodyText2}</div>
          </div>

          {/* Toggle 2 */}
          <label className="flex items-center cursor-pointer" >
            <div className="relative">
              <input type="checkbox" className="sr-only" onChange={() => onToggle2change({ isToggleOn: toggle2on })} checked={toggle2on} role="switch" />
              <div className="block w-10 h-6 rounded-full toggle-container" style={styles.toggle2Container}></div>
              <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
            </div>
          </label>
          {/* Toggle 2 */}

        </div>

        <ColumnsMultiSelect toggle2on={toggle2on} colIdsArrForApi={colIdsArrForApi} setColIdsArrForApi={setColIdsArrForApi} />

        {/* Public form link copy button */}
        <div className={`${classes.copyLinkBtn} ${classes.copyLinkBtn2}`} onClick={onButton2Click}>
          {
            formPublicLink ? (
              <>
                <img src={copyLinkBtn} />
                {
                  isFormLinkCopied ?
                    <div>Copied</div>
                    :
                    <div>Copy Link</div>
                }

              </>
            ) : (
              <div className="animate-spin loader loader-button1"></div>
            )
          }
        </div>
        
        {isFormLinkCopied && <div className='flex justify-center mt-2'><img src={linkCopiedGif} className="h-8"/></div>}
        {/* Public form link copy button */}

      </div>
      {/* PUBLIC FORM SECTION */}

    </div>
  )
}

export default SharePopupBody