import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// slices
import { saveFilters } from '../../../../../../slices/RegisterSlice'

// components
import DropContainer from '../../../../../../partials/DropContainer'
import ColumnsList from './Components/ColumnsList'
import Filters from './Components/Filters'
import Footer from './Components/Footer'

import './style.css'

const Filter = () => {

  // redux
  const dispatch = useDispatch()
  const registerData = useSelector(state => state.register)

  // ref
  const filterBtnRef = useRef(null)

  // state
  const [showFilterSettings, setShowFilterSettings] = useState(false)
  const [selectedCol, setSelectedCol] = useState({})
  const [selectedfilterData, setSelectedfilterData] = useState([])
  const [updatedFilters, setUpdatedFilters] = useState([])
  const [errorColumns, setErrorColumns] = useState([])

  

  // local
  const { filters, columns, sheetId } = registerData
  const filtersExists = filters.length > 0
  // console.log('updated filters:', updatedFilters, filters)
  useEffect(() => {
    const isSno = selectedCol.key === '-1'
    updatedFilters.forEach((el) => {
      if (el.columnId === selectedCol.key) { // HANDLED SNO is at FE columnId is added as -1 at change filters function
        setSelectedfilterData(el.filters)
      } else if (!el.columnId && isSno) { // !el.columnId if received from BE HANDLED SNO 
        setSelectedfilterData(el.filters)
      }
    })
  }, [selectedCol, updatedFilters])

  useEffect(() => {
    if (showFilterSettings) {
      setUpdatedFilters(filters)
      if (filters.length > 0) { // open the settings part if filter is applied
        let firstFilter = filters[0]
        // the second part of blow conditional value sustitution is done as 
        //  HANDLED SNO pre selection because the data from BE wouldnt have columnId for serial number filter
        let columnWithFilter = firstFilter.columnId ? columns.find(el => el.key === firstFilter.columnId) : columns[0]
        setSelectedCol(columnWithFilter)
      }
    }
    else {
      // reset
      setUpdatedFilters([])
      setSelectedCol([])
      setSelectedfilterData([])
      setErrorColumns([])
    }
  }, [showFilterSettings])

  const toggleFilterSettings = () => {
    setShowFilterSettings(!showFilterSettings)
  }

  const save = () => {
    if (!getErrorsInColumns()) {
      const hasSettingsChanged = JSON.stringify(updatedFilters) !== JSON.stringify(filters)
      if (hasSettingsChanged) {
        dispatch(saveFilters({ sheetId, filters: updatedFilters }))
      }
      setShowFilterSettings(false)
    }
  }

  const getErrorsInColumns = () => {
    let errors = []
    let foundError = false
    updatedFilters.forEach(filterEl => {
      const firstVal = filterEl.filters[1]
      const secondVal = filterEl.filters[2]
      const isBetweenFilter = filterEl.filters.indexOf('BETWEEN') > -1
      const isCustomFilter = filterEl.filters.indexOf('CUSTOM') > -1
      const isGreaterThanFilter = filterEl.filters.indexOf('>') > -1
      const isLessThanFilter = filterEl.filters.indexOf('<') > -1
      if (isBetweenFilter && (!!!firstVal || !!!secondVal)) {
        foundError = true
        errors.push(filterEl.columnId ? filterEl.columnId : '-1')
      } else if ((isCustomFilter || isGreaterThanFilter || isLessThanFilter) && !!!firstVal) {
        foundError = true
        errors.push(filterEl.columnId ? filterEl.columnId : '-1')
      }
    })
    setErrorColumns(errors)
    console.log(" errors ", errors, foundError)
    return foundError
  }

  const clearAll = () => {
    dispatch(saveFilters({ sheetId, filters: [] }))
    setShowFilterSettings(false)
  }

  const changeFilterSettings = (newfilterData) => { // add empty, update, delete
    let updatedFiltersCopy = JSON.parse(JSON.stringify(updatedFilters))
    let idx = updatedFilters.findIndex(el => {
      return (
        el.columnId === selectedCol.key || // if during FE selection serial number key exists
        (
          !el.columnId && el._id && selectedCol.key === '-1' // HANDLED SNO if from BE, serial number key doesnt exists
        )
      )
    }) // handled Sno
    if (idx > -1) { // delete and update
      if (newfilterData.length === 0) {
        updateErrorColumns() // when a person unchecks setting then error should be removed
        updatedFiltersCopy.splice(idx, 1)
        setSelectedfilterData([])
      } // delete and clear selectedFilterData
      else updatedFiltersCopy[idx].filters = newfilterData // update
      // updatedFiltersCopy[idx].filters = newfilterData
    } else if (idx == -1) { // add
      updatedFiltersCopy.push({
        columnId: selectedCol.key ? selectedCol.key : '', // HANDLED SNO as when at FE Sno is selcted and its filter is set then columnId is pushed as -1
        filters: newfilterData
      })
    }
    // setSelectedfilterData(newfilterData)
    setUpdatedFilters(updatedFiltersCopy)
  }

  const updateErrorColumns = () => {
    const indexInErrorColumns = errorColumns.indexOf(selectedCol.key)
    if (indexInErrorColumns > -1) {
      let errorColumnsCopy = [...errorColumns]
      errorColumnsCopy.splice(indexInErrorColumns, 1)
      setErrorColumns(errorColumnsCopy)
    }
  }

  const closeOnCancel = (e) => {
    // stopPropagation to stop closeDropContainer from calling save function
    // as save was required to be called on outside click and the dropdown calls 
    // when anything closes it. So either the Cancel button ref needs to be passed on to not allow save to be called 
    // or just stop the propagation method.
    e.stopPropagation()
    setShowFilterSettings(false)
  }

  return (
    <div className="flex ml-2 md:ml-4 justify-center items-center relative p-1">
      {/* -------- Filter Button starts -----------*/}
      <div
        onClick={toggleFilterSettings}
        ref={filterBtnRef}
        className={`${filters.length > 0 ? 'filterBtnContainerHover' : 'filterBtnContainer'} flex justify-center items-center py-1 px-2 cursor-pointer transition-all delay-50`}
        style={{ backgroundColor: showFilterSettings ? '#B9D9FF' : '' }}
      >
        <div className={`${filters.length > 0 ? 'filterIconClassHover' : 'filterIconClass'}`} />
        <span className={`${filters.length > 0 ? 'filterTextHover' : 'filterText'} ml-2`} style={{ color: showFilterSettings ? '#174184' : '' }}>Filter</span>
        {
          filters.length > 0 &&
          <div className="flex text-white rounded-full justify-center items-center ml-1" style={{ width: '1.2rem', height: '1.2rem', backgroundColor: '#044089', fontSize: 12 }}>{filters.length}</div>
        }

      </div>
      {/* -------- Filter Button ends -----------*/}
      {/* --------------------------- Menu Container starts */}
      {
        showFilterSettings &&
        <DropContainer btnRef={filterBtnRef} closeDropClosureUpdate={updatedFilters} closeDropContainer={save}>
          <div className="flex pl-2 pr-2" style={{ minHeight: '13rem', maxHeight: '65vh', fontSize: '0.81rem' }}>
            <ColumnsList
              errorColumns={errorColumns}
              updatedFilters={updatedFilters}
              setSelectedfilterData={setSelectedfilterData}
              selectedCol={selectedCol}
              setSelectedCol={setSelectedCol}
            />
            {
              (filtersExists || selectedCol.name) &&
              <Filters
                errorColumns={errorColumns}
                selectedfilterData={selectedfilterData}
                changeFilterSettings={changeFilterSettings}
                selectedCol={selectedCol}
              />
            }
          </div>
          {
            (filtersExists || selectedCol.name) &&
            <Footer
              save={save}
              clearAll={clearAll}
              updatedFilters={updatedFilters}
              closeOnCancel={closeOnCancel}
            />
          }
        </DropContainer>
      }
      {/* ----------------------------  Menu Container ends */}
    </div>
  )
}

export default Filter