import { useState, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux"
import DatePicker from "react-datepicker"
import moment from 'moment'

//slices
import { fetchRegisterData, fetchAllRegisterData } from "../../../../../slices/RegisterSlice"

//icons and images
import calendarIcon from '../../../../../images/header/calendar.png'

const CalendarTab = () => {

  //redux variables
  const dispatch = useDispatch()
  const registerData = useSelector( state => state.register)

  //state variables
  const [startDate, setStartDate] = useState(new Date())

  //local variables
  const { registerId, sheetId } = registerData

  const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="flex justify-center items-center gap-2 px-4"
      onClick={onClick}
      ref={ref}
      style={{ fontSize: '0.8rem' }}
    >
      <img src={calendarIcon} className="h-4" />
      {value}
    </button>
  ));

  const calendarContainer = ({ className, children }) => {
    return (
      <div className={`${className} shadow-lg slide_down`}>
        <div className="w-full bg-white pl-4 py-2" style={{ color: '#576D9E', fontSize: '1.33rem' }}>
          Calendar
        </div>
        <div style={{ position: "relative", fontSize: '1rem' }} className="">{children}</div>
      </div>
    );
  }

  const onDateChange = (date) => {
    setStartDate(date)
    const formattedDate = moment(date).format('YYYY-MM-DD')
    dispatch(fetchAllRegisterData({registerId, sheetId, date: formattedDate}))
  }

  return (
    <div className="bg-white flex items-center rounded-t">
      <DatePicker
        selected={startDate}
        onChange={(date) => onDateChange(date)}
        customInput={<CustomDateInput />}
        calendarContainer={calendarContainer}
        dateFormat="dd-MM-yyyy"
        showYearDropdown
        popperModifiers={[
          {
            name: "preventOverflow",
            options: {
              rootBoundary: "viewport",
              tether: false,
              altAxis: true,
            },
          },
        ]}
      />
    </div>


  )
}

export default CalendarTab