import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect } from 'react-router-dom'

// 3rd party
import QRCode from 'qrcode.react'

// slices
import { loginWithQR } from "../../../../slices/LoginSlice"

//components
import BackButton from "../BackButton"
import RedirectToSignUp from "../RedirectToSignup"
import QrInfoMenu from "./QrInfoMenu"

// icons
import logo from '../../../../images/logo.png'
import waveIcon from '../../../../images/login/wave.png'

const LoginByQR = ({ handleLoginMethodChange, LOGIN_METHODS }) => {

  //redux variables
  const dispatch = useDispatch()
  const loginInfo = useSelector(state => state.loginInfo)

  // state variables
  const [redirectToSignup, setRedirectToSignup] = useState(false)

  //local variables
  const { qrToken } = loginInfo
  let loginWithQrEverySecond

  useEffect(async () => {
    if (qrToken) {
      loginWithQrEverySecond = setInterval(async () => {
        dispatch(loginWithQR({ qrToken }))
        if (localStorage.getItem('token')) clearInterval(loginWithQrEverySecond)
      }, 1000)
    }
  }, [qrToken])

  // classes
  const qrContainer = `flex justify-center items-center`

  if (redirectToSignup) {
    return <Redirect to='/SignUp' />
  }

  return (
    <div className="h-full flex-col flex justify-between text-center py-6 overflow-auto no-scrollbar">

      <div className="" style={{}}>
        <div className='relative'>
          <BackButton onClick={() => handleLoginMethodChange(LOGIN_METHODS.EMAIL)} />
          <img src={logo} onClick={() => window.location.reload()} className='mx-auto h-8 relative top-0.5 cursor-pointer' loading='lazy' />
        </div>
      </div>


      <div className="child-in-center flex-col justify-between gap-10">
        <div>
          <div className="font-bold text-xl sm:text-2xl lg:text-3xl pb-3" style={{color: '#22252A'}}>
            Welcome back
            <img src={waveIcon} className='h-6 inline ml-2 cursor-pointer' loading='lazy' />
          </div>
          <div className="font-medium mt-4 child-in-center gap-2" style={{ color: '#6C757D' }}>Login with QR code<QrInfoMenu /></div>
        </div>
        {
          qrToken ?
            <div className={qrContainer}>
              <QRCode
                value={qrToken}
                size={220}
                style={{ zIndex: 2 }}
              />
            </div> :
            // loader
            <div className="animate-spin rounded-full h-7 w-7 border-b-2 border-gray-100 m-auto "></div>
          // loader
        }
        <div className="font-medium pb-3" style={{ color: '#6C757D' }}>Scan the QR code with your<br /> RecordBook App to Login</div>
      </div>

      <div className="">
        <RedirectToSignUp setRedirectToSignup={setRedirectToSignup} />
      </div>

    </div>

  )
}

export default LoginByQR