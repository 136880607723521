const PopUp = ({ popUpContainerStyle, popUpContainerClass, body, onClose }) => {

	const classes = {
		container: `flex justify-center items-center fixed h-screen top-0 bottom-0 left-0 w-full ${true ? 'opacity-100' : 'opacity-0 pointer-events-none'}`,
		backdropDiv: `flex justify-center items-center absolute h-screen top-0 bottom-0 left-0 w-full ${true ? 'opacity-100' : 'opacity-0 pointer-events-none'}`,
		subContainer: `flex flex-col justify-center items-center rounded bg-white shadow-md transform transition-all duration-200 ease-out ${true ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0 pointer-events-none'} `,
	}

	return (
		<div className={classes.container} style={{ zIndex: '100' }} >
			{/* backdrop container starts */}
			<div
				id="dialog-backdrop"
				className={classes.backdropDiv}
				style={{ background: 'rgba(0,0,0,0.4)' }}
				onClick={onClose}
			>
			</div>
			{/* backdrop container ends */}

			{/* Dialog content starts */}
			<div
				className={`${classes.subContainer} ${popUpContainerClass}`}
				style={{ ...popUpContainerStyle }}
			>
				{body}
			</div>
			{/* Dialog content ends */}

		</div>
	)

}

export default PopUp